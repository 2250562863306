define('jason-frontend/components/invoice-assign-user-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      changeGlobalUser: function changeGlobalUser(invoiceId, user, type) {
        this.sendAction('changeGlobalUser', invoiceId, user, type);
      }
    }
  });

});
define('jason-frontend/templates/compositeproducts/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 10
                },
                "end": {
                  "line": 23,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","button-rework context");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/template-dark.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode(" Dokument hinzufügen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element33 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element33);
              return morphs;
            },
            statements: [
              ["element","action",["addDoc",["get","model.id",["loc",[null,[20,33],[20,41]]]]],[],["loc",[null,[20,15],[20,43]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 24,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/product-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode(" Produkt hinzufügen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/service-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode(" Leistung hinzufügen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/template-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode(" Vorlage hinzufügen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element34 = dom.childAt(fragment, [1]);
            var element35 = dom.childAt(fragment, [3]);
            var element36 = dom.childAt(fragment, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element34);
            morphs[1] = dom.createElementMorph(element35);
            morphs[2] = dom.createElementMorph(element36);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["element","action",["addProduct",["get","model.id",["loc",[null,[10,35],[10,43]]]]],[],["loc",[null,[10,13],[10,45]]]],
            ["element","action",["addService",["get","model.id",["loc",[null,[13,35],[13,43]]]]],[],["loc",[null,[13,13],[13,45]]]],
            ["element","action",["addTemplate",["get","model.id",["loc",[null,[16,36],[16,44]]]]],[],["loc",[null,[16,13],[16,46]]]],
            ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[19,20],[19,24]]]],"therapy"],[],["loc",[null,[19,16],[19,35]]]]],[],0,null,["loc",[null,[19,10],[23,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 8
                },
                "end": {
                  "line": 28,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","button-rework context primary");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Maßnahme erstellen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element32 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element32);
              return morphs;
            },
            statements: [
              ["element","action",["addFutureAction",["get","model.id",["loc",[null,[25,40],[25,48]]]]],[],["loc",[null,[25,13],[25,50]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 8
                  },
                  "end": {
                    "line": 32,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","button-rework context primary");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("TO-DO anlegen");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element31 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element31);
                return morphs;
              },
              statements: [
                ["element","action",["editTodo"],[],["loc",[null,[29,13],[29,34]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 8
                },
                "end": {
                  "line": 32,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[28,22],[28,31]]]],"todo"],[],["loc",[null,[28,18],[28,39]]]]],[],0,null,["loc",[null,[28,8],[32,8]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 32,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[24,22],[24,31]]]],"future"],[],["loc",[null,[24,18],[24,41]]]]],[],0,1,["loc",[null,[24,8],[32,8]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[9,18],[9,27]]]],"masterdata"],[],["loc",[null,[9,14],[9,41]]]]],[],0,1,["loc",[null,[9,8],[32,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 4
            },
            "end": {
              "line": 54,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-targeting");
          var el2 = dom.createTextNode("Targeting");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element30 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element30, 'class');
          morphs[1] = dom.createElementMorph(element30);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[52,74],[52,83]]]],"targeting"],[],["loc",[null,[52,70],[52,96]]]],"is-active",""],[],["loc",[null,[52,49],[52,113]]]]]]],
          ["element","action",["selectTab","targeting"],[],["loc",[null,[53,31],[53,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 58,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-treatment");
          var el2 = dom.createTextNode("Behandlung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element29, 'class');
          morphs[1] = dom.createElementMorph(element29);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[56,53],[56,62]]]],"treatment"],[],["loc",[null,[56,49],[56,75]]]],"is-active",""],[],["loc",[null,[56,28],[56,92]]]]]]],
          ["element","action",["selectTab","treatment"],[],["loc",[null,[57,31],[57,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 4
            },
            "end": {
              "line": 62,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-future");
          var el2 = dom.createTextNode("Zukunft");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element28, 'class');
          morphs[1] = dom.createElementMorph(element28);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[60,53],[60,62]]]],"future"],[],["loc",[null,[60,49],[60,72]]]],"is-active",""],[],["loc",[null,[60,28],[60,89]]]]]]],
          ["element","action",["selectTab","future"],[],["loc",[null,[60,110],[61,30]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 4
            },
            "end": {
              "line": 66,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-todo");
          var el2 = dom.createTextNode("TO-DO");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element27, 'class');
          morphs[1] = dom.createElementMorph(element27);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[64,53],[64,62]]]],"todo"],[],["loc",[null,[64,49],[64,70]]]],"is-active",""],[],["loc",[null,[64,28],[64,87]]]]]]],
          ["element","action",["selectTab","todo"],[],["loc",[null,[64,106],[65,28]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 63
              },
              "end": {
                "line": 94,
                "column": 60
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mt10 mb10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[94,42],[94,55]]]]
          ],
          locals: ["item"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 92,
              "column": 55
            },
            "end": {
              "line": 96,
              "column": 27
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.patientCategories",["loc",[null,[93,71],[93,94]]]]],[],0,null,["loc",[null,[93,63],[94,69]]]],
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[94,81],[95,118]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 27
            },
            "end": {
              "line": 97,
              "column": 99
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  auswählen ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[97,28],[97,99]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 105,
                "column": 63
              },
              "end": {
                "line": 106,
                "column": 60
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mt10 mb10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[106,42],[106,55]]]]
          ],
          locals: ["item"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 66
            },
            "end": {
              "line": 108,
              "column": 27
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.introductionReasons",["loc",[null,[105,71],[105,96]]]]],[],0,null,["loc",[null,[105,63],[106,69]]]],
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[106,81],[107,118]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 27
            },
            "end": {
              "line": 109,
              "column": 100
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[109,19],[109,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 63
              },
              "end": {
                "line": 118,
                "column": 60
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mt10 mb10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[118,42],[118,55]]]]
          ],
          locals: ["item"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 116,
              "column": 56
            },
            "end": {
              "line": 120,
              "column": 27
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.symptoms",["loc",[null,[117,71],[117,85]]]]],[],0,null,["loc",[null,[117,63],[118,69]]]],
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[118,81],[119,118]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 27
            },
            "end": {
              "line": 121,
              "column": 100
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[121,19],[121,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 128,
                "column": 63
              },
              "end": {
                "line": 129,
                "column": 60
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mt10 mb10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[129,42],[129,55]]]]
          ],
          locals: ["item"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 127,
              "column": 119
            },
            "end": {
              "line": 131,
              "column": 27
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.diagnosis",["loc",[null,[128,71],[128,86]]]]],[],0,null,["loc",[null,[128,63],[129,69]]]],
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[129,81],[130,118]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 27
            },
            "end": {
              "line": 132,
              "column": 100
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" auswählen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[132,19],[132,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 157,
                "column": 16
              },
              "end": {
                "line": 165,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element23, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element23, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[158,69],[158,77]]]]]]],
            ["content","entry.title",["loc",[null,[162,24],[162,39]]]],
            ["content","entry.description",["loc",[null,[163,38],[163,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 174,
                "column": 164
              },
              "end": {
                "line": 175,
                "column": 206
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(element22,0,0);
            morphs[1] = dom.createMorphAt(element22,2,2);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[175,81],[175,124]]]]],[],["loc",[null,[175,59],[175,127]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[175,128],[175,199]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 175,
                "column": 206
              },
              "end": {
                "line": 176,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[176,28],[176,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 182,
                "column": 155
              },
              "end": {
                "line": 183,
                "column": 185
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[183,69],[183,103]]]]],[],["loc",[null,[183,47],[183,106]]]],
            ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[183,114],[183,185]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 183,
                "column": 185
              },
              "end": {
                "line": 184,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[184,28],[184,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 151,
              "column": 8
            },
            "end": {
              "line": 188,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Anamnese");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableMedicalHistory");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Vorstellungsgründe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Administrative Tätigkeiten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [3]);
          var element25 = dom.childAt(element24, [1, 3, 1]);
          var element26 = dom.childAt(element24, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element25);
          morphs[2] = dom.createMorphAt(element25,0,0);
          morphs[3] = dom.createElementMorph(element26);
          morphs[4] = dom.createMorphAt(element26,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.medicalHistoryEntries",["loc",[null,[157,24],[157,65]]]]],[],0,null,["loc",[null,[157,16],[165,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","selectedTreatmentValues.introductionReasons",["loc",[null,[174,97],[174,140]]]],"Vorstellungsgründe"],[],["loc",[null,[174,34],[174,163]]]],
          ["block","if",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[174,170],[174,213]]]]],[],1,2,["loc",[null,[174,164],[176,106]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","selectedTreatmentValues.adminTasks",["loc",[null,[182,89],[182,123]]]],"Administrative Tätigkeiten"],[],["loc",[null,[182,34],[182,154]]]],
          ["block","if",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[182,161],[182,195]]]]],[],3,4,["loc",[null,[182,155],[184,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 204,
                "column": 16
              },
              "end": {
                "line": 212,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element19, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element19, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[205,69],[205,77]]]]]]],
            ["content","entry.title",["loc",[null,[209,24],[209,39]]]],
            ["content","entry.description",["loc",[null,[210,38],[210,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 133
              },
              "end": {
                "line": 221,
                "column": 350
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.symptoms",["loc",[null,[221,236],[221,268]]]]],[],["loc",[null,[221,214],[221,271]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[221,279],[221,350]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 350
              },
              "end": {
                "line": 222,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[222,28],[222,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 229,
                  "column": 144
                },
                "end": {
                  "line": 229,
                  "column": 363
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[229,242],[229,281]]]]],[],["loc",[null,[229,220],[229,284]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[229,292],[229,363]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 229,
                  "column": 363
                },
                "end": {
                  "line": 230,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[230,30],[230,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 225,
                "column": 12
              },
              "end": {
                "line": 233,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createTextNode("Diagnostische Untersuchungen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","pick-list");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element18);
            morphs[1] = dom.createMorphAt(element18,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","un","Diagnostische Untersuchungen",["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[229,102],[229,141]]]]],[],["loc",[null,[229,36],[229,143]]]],
            ["block","if",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[229,150],[229,189]]]]],[],0,1,["loc",[null,[229,144],[230,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 198,
              "column": 8
            },
            "end": {
              "line": 235,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Symptome");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableSymptoms");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Klinische Symptomatik");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [3]);
          var element21 = dom.childAt(element20, [1, 3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element21);
          morphs[2] = dom.createMorphAt(element21,0,0);
          morphs[3] = dom.createMorphAt(element20,3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.symptomsEntries",["loc",[null,[204,24],[204,59]]]]],[],0,null,["loc",[null,[204,16],[212,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","symptoms",["get","selectedTreatmentValues.symptoms",["loc",[null,[221,87],[221,119]]]],"Symptome"],[],["loc",[null,[221,34],[221,132]]]],
          ["block","if",[["get","selectedTreatmentValues.symptoms",["loc",[null,[221,139],[221,171]]]]],[],1,2,["loc",[null,[221,133],[222,106]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[225,18],[225,26]]]]],[],3,null,["loc",[null,[225,12],[233,19]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 252,
                "column": 16
              },
              "end": {
                "line": 260,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element14, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element14, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[253,69],[253,77]]]]]]],
            ["content","entry.title",["loc",[null,[257,24],[257,39]]]],
            ["content","entry.description",["loc",[null,[258,38],[258,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 269,
                "column": 153
              },
              "end": {
                "line": 269,
                "column": 386
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[269,264],[269,304]]]]],[],["loc",[null,[269,242],[269,307]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[269,315],[269,386]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 269,
                "column": 386
              },
              "end": {
                "line": 270,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[270,28],[270,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 276,
                "column": 161
              },
              "end": {
                "line": 276,
                "column": 404
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[276,277],[276,322]]]]],[],["loc",[null,[276,255],[276,325]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[276,333],[276,404]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 276,
                "column": 404
              },
              "end": {
                "line": 277,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[277,28],[277,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 247,
              "column": 8
            },
            "end": {
              "line": 281,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Diagnose");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableDiagnoses");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Verdachtsdiagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Finale Diagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [3]);
          var element16 = dom.childAt(element15, [1, 3, 1]);
          var element17 = dom.childAt(element15, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element16);
          morphs[2] = dom.createMorphAt(element16,0,0);
          morphs[3] = dom.createElementMorph(element17);
          morphs[4] = dom.createMorphAt(element17,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.diagnosesEntries",["loc",[null,[252,24],[252,60]]]]],[],0,null,["loc",[null,[252,16],[260,25]]]],
          ["element","action",["selectMultistageTreatmentDetail","diagnoses",["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[269,89],[269,129]]]],"Verdachtsdiagnosen"],[],["loc",[null,[269,34],[269,152]]]],
          ["block","if",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[269,159],[269,199]]]]],[],1,2,["loc",[null,[269,153],[270,106]]]],
          ["element","action",["selectMultistageTreatmentDetail","finalDiagnoses",["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[276,94],[276,139]]]],"Finale Diagnosen"],[],["loc",[null,[276,34],[276,160]]]],
          ["block","if",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[276,167],[276,212]]]]],[],3,4,["loc",[null,[276,161],[277,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 298,
                "column": 16
              },
              "end": {
                "line": 306,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element13, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element13, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[299,69],[299,77]]]]]]],
            ["content","entry.title",["loc",[null,[303,24],[303,39]]]],
            ["content","entry.description",["loc",[null,[304,38],[304,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 315,
                  "column": 144
                },
                "end": {
                  "line": 316,
                  "column": 191
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[316,70],[316,110]]]]],[],["loc",[null,[316,49],[316,112]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[316,120],[316,191]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 316,
                  "column": 191
                },
                "end": {
                  "line": 317,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[317,30],[317,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 310,
                "column": 10
              },
              "end": {
                "line": 321,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-24/24@tablet l-8/24@desk gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            var el4 = dom.createTextNode("Therapeutische Behandlungen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pick-list");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","cursor");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element12);
            morphs[1] = dom.createMorphAt(element12,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","tb","Therapeutische Behandlungen",["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[315,101],[315,141]]]]],[],["loc",[null,[315,36],[315,143]]]],
            ["block","if",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[315,150],[315,190]]]]],[],0,1,["loc",[null,[315,144],[317,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 293,
              "column": 8
            },
            "end": {
              "line": 322,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Therapie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableTherapies");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.therapiesEntries",["loc",[null,[298,24],[298,60]]]]],[],0,null,["loc",[null,[298,16],[306,25]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[310,16],[310,24]]]]],[],1,null,["loc",[null,[310,10],[321,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 339,
                "column": 16
              },
              "end": {
                "line": 347,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element11, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element11, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[340,69],[340,77]]]]]]],
            ["content","entry.title",["loc",[null,[344,24],[344,39]]]],
            ["content","entry.description",["loc",[null,[345,38],[345,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 334,
              "column": 8
            },
            "end": {
              "line": 351,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Beratung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableReports");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.reportEntries",["loc",[null,[339,24],[339,57]]]]],[],0,null,["loc",[null,[339,16],[347,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 370,
              "column": 14
            },
            "end": {
              "line": 378,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sortable-item fixed");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","delete");
          dom.setAttribute(el2,"onclick","this.parentNode.remove()");
          var el3 = dom.createTextNode("\n                ×\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","sub");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element10, 'data-module-id');
          morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element10, [5]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[371,67],[371,75]]]]]]],
          ["content","entry.title",["loc",[null,[375,22],[375,37]]]],
          ["content","entry.description",["loc",[null,[376,36],[376,57]]]]
        ],
        locals: ["entry"],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 383,
              "column": 12
            },
            "end": {
              "line": 391,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sortable-item draggable");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","delete");
          dom.setAttribute(el2,"onclick","this.parentNode.remove()");
          var el3 = dom.createTextNode("\n                ×\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","sub");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element9, 'data-module-id');
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element9, [5]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[384,69],[384,77]]]]]]],
          ["content","entry.title",["loc",[null,[388,20],[388,35]]]],
          ["content","entry.description",["loc",[null,[389,34],[389,55]]]]
        ],
        locals: ["entry"],
        templates: []
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 409,
                "column": 22
              },
              "end": {
                "line": 411,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      Alle ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" um ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Uhr, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Wiederholungen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            return morphs;
          },
          statements: [
            ["content","item.dueTimeUnitInterval",["loc",[null,[410,27],[410,55]]]],
            ["content","item.dueTimeUnit.name",["loc",[null,[410,56],[410,81]]]],
            ["content","item.time",["loc",[null,[410,85],[410,98]]]],
            ["content","item.dueTimeUnitIterations",["loc",[null,[410,104],[410,134]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 411,
                "column": 22
              },
              "end": {
                "line": 413,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        Nach ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" um ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Uhr\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            return morphs;
          },
          statements: [
            ["content","item.dueTimeUnitInterval",["loc",[null,[412,29],[412,57]]]],
            ["content","item.dueTimeUnit.name",["loc",[null,[412,58],[412,83]]]],
            ["content","item.time",["loc",[null,[412,87],[412,100]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 405,
              "column": 14
            },
            "end": {
              "line": 428,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Fälligkeit");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Titel");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell buttons-2 table__cell--action l-6/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [5]);
          var element8 = dom.childAt(element7, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
          morphs[3] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[409,32],[409,43]]]],"repeat"],[],["loc",[null,[409,28],[409,53]]]]],[],0,1,["loc",[null,[409,22],[413,29]]]],
          ["content","item.name",["loc",[null,[417,26],[417,39]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[421,100],[421,104]]]]],[],[]],"confirmed","deleteTodo","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[421,22],[421,180]]]],
          ["element","action",["editTodo",["get","item.id",["loc",[null,[423,43],[423,50]]]]],[],["loc",[null,[423,23],[423,52]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 455,
                  "column": 10
                },
                "end": {
                  "line": 463,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","sortable-item draggable");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","delete");
              dom.setAttribute(el2,"onclick","this.parentNode.remove()");
              var el3 = dom.createTextNode("\n                ×\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h2");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","sub");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element2, 'data-module-id');
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[456,67],[456,75]]]]]]],
              ["content","entry.title",["loc",[null,[460,18],[460,33]]]],
              ["content","entry.description",["loc",[null,[461,32],[461,53]]]]
            ],
            locals: ["entry"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 453,
                "column": 6
              },
              "end": {
                "line": 468,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class"," scrollable");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","block small mb-30");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Tipp:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Ziehen Sie die Textbausteine an die gewünschte Stelle");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","allItems",["loc",[null,[455,18],[455,26]]]]],[],0,null,["loc",[null,[455,10],[463,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 469,
                  "column": 10
                },
                "end": {
                  "line": 470,
                  "column": 51
                }
              },
              "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            Derzeit keine Textbausteine hinterlegt.");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 468,
                "column": 6
              },
              "end": {
                "line": 471,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","link-to",["templates",["subexpr","query-params",[],["selectTab","textmodules"],["loc",[null,[469,33],[469,71]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[469,10],[470,63]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 444,
              "column": 0
            },
            "end": {
              "line": 474,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","editorSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box inner wizzard");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Textbausteine");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          morphs[2] = dom.createMorphAt(element5,5,5);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[446,9],[446,40]]]],
          ["inline","search-box",[],["classNames","w-100","filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[451,51],[451,61]]]]],[],[]],"placeHolder","Textbaustein suchen","search","searchTextModules"],["loc",[null,[451,8],[451,124]]]],
          ["block","if",[["get","allItems",["loc",[null,[453,12],[453,20]]]]],[],0,1,["loc",[null,[453,6],[471,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child22 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 475,
                "column": 2
              },
              "end": {
                "line": 479,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[477,63],[477,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 474,
              "column": 0
            },
            "end": {
              "line": 480,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[475,12],[475,21]]]],"treatment"],[],["loc",[null,[475,8],[475,34]]]]],[],0,null,["loc",[null,[475,2],[479,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child23 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 484,
                "column": 4
              },
              "end": {
                "line": 486,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","button-prev",["loc",[null,[485,6],[485,21]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 483,
              "column": 2
            },
            "end": {
              "line": 487,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.therapyplan"],["class","icon-button icon-button--small"],0,null,["loc",[null,[484,4],[486,16]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 487,
              "column": 2
            },
            "end": {
              "line": 489,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          dom.setAttribute(el1,"onclick","history.back()");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["content","button-prev",["loc",[null,[488,71],[488,86]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 490,
              "column": 0
            },
            "end": {
              "line": 494,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success input");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [
          ["element","action",["saveTextModules"],[],["loc",[null,[491,7],[491,35]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 494,
              "column": 0
            },
            "end": {
              "line": 498,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success input");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSubmit"],[],["loc",[null,[495,5],[495,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 511,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/compositeproducts/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Paket bearbeiten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/qr-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("QR-Code drucken");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","compositeProductEdit");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","card mb-0 min-h-130px customer-card");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"class","mb0 d-flex align-items-center");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt-0 mb-0");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-master-data");
        var el4 = dom.createTextNode("Stammdaten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs__body");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-targeting");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4,"class","is-highlighted-alternative");
        var el5 = dom.createTextNode("Die ausgewählten Filter führen zu einer Vorauswahl beim Hinzufügen zu einer Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","etigaFeedEditForm");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","admin-form grid form-grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 pr10");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Alter");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","inline-input-list");
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("von ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("bis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode(" Jahre");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n         ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 pl20");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Gewicht");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","inline-input-list");
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("von ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("bis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode(" kg");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","mb5 title");
        var el8 = dom.createTextNode("Spezies");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","mb5 title");
        var el8 = dom.createTextNode("Vorstellungsgründe");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","mb5 title");
        var el8 = dom.createTextNode("Symptome");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","mb5 title");
        var el8 = dom.createTextNode("Diagnose");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-treatment");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4,"class","is-highlighted-alternative");
        var el5 = dom.createTextNode("Die ausgewählten Textbausteine werden beim Hinzufügen zu einer Behandlung automatisch ergänzt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","toggle-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Anamnese");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","header-toggle");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","switchAnamnese");
        dom.setAttribute(el7,"class","switch");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","toggle-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Symptome");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","header-toggle");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","switchSymptoms");
        dom.setAttribute(el7,"class","switch");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","toggle-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Diagnosen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","header-toggle");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","switchDiagnose");
        dom.setAttribute(el7,"class","switch");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","toggle-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Therapien");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","header-toggle");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","switchTherapy");
        dom.setAttribute(el7,"class","switch");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","toggle-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Beratung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","header-toggle");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","switchReport");
        dom.setAttribute(el7,"class","switch");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-master-data");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","productEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-textmodules");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4,"class","is-highlighted-alternative");
        var el5 = dom.createTextNode("Die ausgewählten Textbausteine werden beim Hinzufügen dieses Pakets zu einer Behandlung automatisch im Behandlungsbericht ergänzt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","grid wizzard equal");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 main");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Ausgewählt");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card");
        dom.setAttribute(el6,"id","sortable");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 sidebar");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Verfügbar");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-todo");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4,"class","is-highlighted-alternative ps-10");
        var el5 = dom.createTextNode("Beim Hinzufügen des Therapieplans in einer Behandlung werden die TO-DOs erzeugt und der Tierhalterin in der etiga-App zur Erledigung angezeigt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-10/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Fälligkeit");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-10/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Titel");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-future");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","buttons action-icons clearfix");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element37 = dom.childAt(fragment, [0]);
        var element38 = dom.childAt(element37, [1]);
        var element39 = dom.childAt(element38, [3]);
        var element40 = dom.childAt(element39, [1]);
        var element41 = dom.childAt(fragment, [2]);
        var element42 = dom.childAt(element41, [1, 1, 1]);
        var element43 = dom.childAt(element41, [3]);
        var element44 = dom.childAt(element43, [1]);
        var element45 = dom.childAt(element41, [5]);
        var element46 = dom.childAt(element45, [1]);
        var element47 = dom.childAt(element46, [3]);
        var element48 = dom.childAt(element47, [1]);
        var element49 = dom.childAt(element48, [1, 3]);
        var element50 = dom.childAt(element48, [3, 3]);
        var element51 = dom.childAt(element48, [5, 3, 1]);
        var element52 = dom.childAt(element48, [7, 3, 1]);
        var element53 = dom.childAt(element48, [9, 3, 1]);
        var element54 = dom.childAt(element48, [11, 3, 1]);
        var element55 = dom.childAt(element45, [3]);
        var element56 = dom.childAt(element45, [5]);
        var element57 = dom.childAt(element56, [1]);
        var element58 = dom.childAt(element45, [7]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element45, [9]);
        var element61 = dom.childAt(element45, [11]);
        var element62 = dom.childAt(fragment, [6]);
        var morphs = new Array(56);
        morphs[0] = dom.createAttrMorph(element37, 'class');
        morphs[1] = dom.createAttrMorph(element38, 'class');
        morphs[2] = dom.createElementMorph(element40);
        morphs[3] = dom.createMorphAt(element39,3,3);
        morphs[4] = dom.createAttrMorph(element41, 'class');
        morphs[5] = dom.createMorphAt(dom.childAt(element42, [1]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element42, [3]),0,0);
        morphs[7] = dom.createAttrMorph(element44, 'class');
        morphs[8] = dom.createElementMorph(element44);
        morphs[9] = dom.createMorphAt(element43,3,3);
        morphs[10] = dom.createMorphAt(element43,4,4);
        morphs[11] = dom.createMorphAt(element43,5,5);
        morphs[12] = dom.createMorphAt(element43,6,6);
        morphs[13] = dom.createAttrMorph(element46, 'class');
        morphs[14] = dom.createElementMorph(element47);
        morphs[15] = dom.createMorphAt(element49,2,2);
        morphs[16] = dom.createMorphAt(element49,6,6);
        morphs[17] = dom.createMorphAt(element50,2,2);
        morphs[18] = dom.createMorphAt(element50,6,6);
        morphs[19] = dom.createElementMorph(element51);
        morphs[20] = dom.createMorphAt(element51,0,0);
        morphs[21] = dom.createElementMorph(element52);
        morphs[22] = dom.createMorphAt(element52,0,0);
        morphs[23] = dom.createElementMorph(element53);
        morphs[24] = dom.createMorphAt(element53,0,0);
        morphs[25] = dom.createElementMorph(element54);
        morphs[26] = dom.createMorphAt(element54,0,0);
        morphs[27] = dom.createAttrMorph(element55, 'class');
        morphs[28] = dom.createMorphAt(dom.childAt(element55, [3, 1, 3]),1,1);
        morphs[29] = dom.createMorphAt(element55,5,5);
        morphs[30] = dom.createMorphAt(dom.childAt(element55, [7, 1, 3]),1,1);
        morphs[31] = dom.createMorphAt(element55,9,9);
        morphs[32] = dom.createMorphAt(dom.childAt(element55, [11, 1, 3]),1,1);
        morphs[33] = dom.createMorphAt(element55,13,13);
        morphs[34] = dom.createMorphAt(dom.childAt(element55, [15, 1, 3]),1,1);
        morphs[35] = dom.createMorphAt(element55,17,17);
        morphs[36] = dom.createMorphAt(dom.childAt(element55, [19, 1, 3]),1,1);
        morphs[37] = dom.createMorphAt(element55,21,21);
        morphs[38] = dom.createAttrMorph(element56, 'class');
        morphs[39] = dom.createElementMorph(element57);
        morphs[40] = dom.createMorphAt(element57,1,1);
        morphs[41] = dom.createAttrMorph(element58, 'class');
        morphs[42] = dom.createMorphAt(dom.childAt(element59, [1, 3]),1,1);
        morphs[43] = dom.createMorphAt(dom.childAt(element59, [3]),3,3);
        morphs[44] = dom.createAttrMorph(element60, 'class');
        morphs[45] = dom.createMorphAt(dom.childAt(element60, [3, 1, 3]),1,1);
        morphs[46] = dom.createAttrMorph(element61, 'class');
        morphs[47] = dom.createMorphAt(element61,1,1);
        morphs[48] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[49] = dom.createMorphAt(element62,1,1);
        morphs[50] = dom.createMorphAt(element62,2,2);
        morphs[51] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[52] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[53] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[54] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[55] = dom.createMorphAt(fragment,16,16,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]," ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[1,104],[1,113]]]],"treatment"],[],["loc",[null,[1,100],[1,126]]]],["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,147],[1,160]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,127],[1,190]]]],""],[],["loc",[null,[1,79],[1,195]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[2,72],[2,85]]]]],[],["loc",[null,[2,67],[2,86]]]],"24","18"],[],["loc",[null,[2,46],[2,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["printQr",["get","model.id",["loc",[null,[5,28],[5,36]]]]],[],["loc",[null,[5,9],[5,38]]]],
        ["block","if",[["get","model.editable",["loc",[null,[8,12],[8,26]]]]],[],0,null,["loc",[null,[8,6],[33,13]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[38,48],[38,61]]]]],[],["loc",[null,[38,43],[38,62]]]],"24","18"],[],["loc",[null,[38,22],[38,74]]]],"/24@desk ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[38,104],[38,117]]]],"sidebar-active",""],[],["loc",[null,[38,83],[38,139]]]]]]],
        ["content","model.name",["loc",[null,[42,50],[42,64]]]],
        ["content","model.category.name",["loc",[null,[43,31],[43,54]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[49,51],[49,60]]]],"masterdata"],[],["loc",[null,[49,47],[49,74]]]],"is-active",""],[],["loc",[null,[49,26],[49,91]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[50,31],[50,66]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[51,14],[51,18]]]],"therapy"],[],["loc",[null,[51,10],[51,29]]]]],[],1,null,["loc",[null,[51,4],[54,11]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[55,10],[55,35]]]]],[],2,null,["loc",[null,[55,4],[58,11]]]],
        ["block","if",[["subexpr","has-permission",["crud_treatment_future_blueprints"],[],["loc",[null,[59,10],[59,61]]]]],[],3,null,["loc",[null,[59,4],[62,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[63,14],[63,18]]]],"therapy"],[],["loc",[null,[63,10],[63,29]]]]],[],4,null,["loc",[null,[63,4],[66,11]]]],
        ["attribute","class",["concat",["card-content tabs__content article-wizzard ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[70,85],[70,94]]]],"targeting"],[],["loc",[null,[70,81],[70,107]]]],"is-active",""],[],["loc",[null,[70,60],[70,124]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[74,38],[74,67]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.ageFrom",["loc",[null,[78,77],[78,90]]]]],[],[]],"class","gui-input","name","ageFrom"],["loc",[null,[78,63],[79,87]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.ageTo",["loc",[null,[80,28],[80,39]]]]],[],[]],"class","gui-input","name","ageTo"],["loc",[null,[79,105],[80,72]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.weightFrom",["loc",[null,[84,77],[84,93]]]]],[],[]],"class","gui-input","name","weightFrom"],["loc",[null,[84,63],[85,90]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.weightTo",["loc",[null,[86,28],[86,42]]]]],[],[]],"class","gui-input","name","weightTo"],["loc",[null,[85,108],[86,78]]]],
        ["element","action",["selectMultistageTreatmentDetail","patientCategories",["get","model.patientCategories",["loc",[null,[91,97],[91,120]]]],"Spezies"],[],["loc",[null,[91,34],[92,54]]]],
        ["block","if",[["get","model.patientCategories",["loc",[null,[92,61],[92,84]]]]],[],5,6,["loc",[null,[92,55],[97,106]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","introductionReasons",["get","model.introductionReasons",["loc",[null,[103,98],[103,123]]]],"Vorstellungsgründe"],[],["loc",[null,[103,34],[104,65]]]],
        ["block","if",[["get","model.introductionReasons",["loc",[null,[104,72],[104,97]]]]],[],7,8,["loc",[null,[104,66],[109,107]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","symptoms",["get","model.symptoms",["loc",[null,[115,87],[115,101]]]],"Symptome"],[],["loc",[null,[115,34],[116,55]]]],
        ["block","if",[["get","model.symptoms",["loc",[null,[116,62],[116,76]]]]],[],9,10,["loc",[null,[116,56],[121,107]]]],
        ["element","action",["selectMultistageTreatmentDetail","diagnoses",["get","model.diagnosis",["loc",[null,[127,89],[127,104]]]],"Diagnosen"],[],["loc",[null,[127,34],[127,118]]]],
        ["block","if",[["get","model.diagnosis",["loc",[null,[127,125],[127,140]]]]],[],11,12,["loc",[null,[127,119],[132,107]]]],
        ["attribute","class",["concat",["card-content tabs__content article-wizzard ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[138,85],[138,94]]]],"treatment"],[],["loc",[null,[138,81],[138,107]]]],"is-active",""],[],["loc",[null,[138,60],[138,124]]]]]]],
        ["inline","input",[],["type","checkbox","id","switchAnamnese","classNames","switch","name","switchAnamnese","checked",["subexpr","@mut",[["get","showAnamnese",["loc",[null,[146,108],[146,120]]]]],[],[]]],["loc",[null,[146,14],[146,122]]]],
        ["block","if",[["get","showAnamnese",["loc",[null,[151,14],[151,26]]]]],[],13,null,["loc",[null,[151,8],[188,15]]]],
        ["inline","input",[],["type","checkbox","id","switchSymptoms","classNames","switch","name","switchSymptoms","checked",["subexpr","@mut",[["get","showSymptoms",["loc",[null,[193,108],[193,120]]]]],[],[]]],["loc",[null,[193,14],[193,122]]]],
        ["block","if",[["get","showSymptoms",["loc",[null,[198,14],[198,26]]]]],[],14,null,["loc",[null,[198,8],[235,15]]]],
        ["inline","input",[],["type","checkbox","id","switchDiagnose","classNames","switch","name","switchDiagnose","checked",["subexpr","@mut",[["get","showDiagnoses",["loc",[null,[241,108],[241,121]]]]],[],[]]],["loc",[null,[241,14],[241,123]]]],
        ["block","if",[["get","showDiagnoses",["loc",[null,[247,14],[247,27]]]]],[],15,null,["loc",[null,[247,8],[281,15]]]],
        ["inline","input",[],["type","checkbox","id","switchTherapy","classNames","switch","name","switchTherapy","checked",["subexpr","@mut",[["get","showTherapies",["loc",[null,[287,106],[287,119]]]]],[],[]]],["loc",[null,[287,14],[287,121]]]],
        ["block","if",[["get","showTherapies",["loc",[null,[293,14],[293,27]]]]],[],16,null,["loc",[null,[293,8],[322,15]]]],
        ["inline","input",[],["type","checkbox","id","switchReport","classNames","switch","name","switchReport","checked",["subexpr","@mut",[["get","showReport",["loc",[null,[328,104],[328,114]]]]],[],[]]],["loc",[null,[328,14],[328,116]]]],
        ["block","if",[["get","showReport",["loc",[null,[334,14],[334,24]]]]],[],17,null,["loc",[null,[334,8],[351,15]]]],
        ["attribute","class",["concat",["tabs__content  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[353,57],[353,66]]]],"masterdata"],[],["loc",[null,[353,53],[353,80]]]],"is-active",""],[],["loc",[null,[353,32],[353,97]]]]]]],
        ["element","action",["save",["get","referer",["loc",[null,[354,66],[354,73]]]],["get","refererInvoiceId",["loc",[null,[354,74],[354,90]]]],["get","refererPatientId",["loc",[null,[354,91],[354,107]]]]],["on","submit"],["loc",[null,[354,50],[354,121]]]],
        ["inline","composite-products-edit-form",[],["type",["subexpr","@mut",[["get","type",["loc",[null,[355,46],[355,50]]]]],[],[]],"history",["subexpr","@mut",[["get","history",["loc",[null,[355,59],[355,66]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[356,47],[356,52]]]]],[],[]],"moveUp","moveUp","moveDown","moveDown","mode","edit","editable",["subexpr","@mut",[["get","model.editable",["loc",[null,[358,62],[358,76]]]]],[],[]],"taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[358,83],[358,88]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[358,100],[358,110]]]]],[],[]],"toAddTemplate","toAddTemplate","toAddProduct","toAddProduct","toAddService","toAddService","actionReceiver","editForm"],["loc",[null,[355,10],[359,37]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[362,69],[362,78]]]],"textmodules"],[],["loc",[null,[362,65],[362,93]]]],"is-active",""],[],["loc",[null,[362,44],[362,110]]]]]]],
        ["block","each",[["get","selectedModules.entries",["loc",[null,[370,22],[370,45]]]]],[],18,null,["loc",[null,[370,14],[378,23]]]],
        ["block","each",[["get","allItems",["loc",[null,[383,20],[383,28]]]]],[],19,null,["loc",[null,[383,12],[391,21]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[395,56],[395,65]]]],"todo"],[],["loc",[null,[395,52],[395,73]]]],"is-active",""],[],["loc",[null,[395,31],[395,90]]]]]]],
        ["block","each",[["get","items",["loc",[null,[405,22],[405,27]]]]],[],20,null,["loc",[null,[405,14],[428,23]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[433,56],[433,65]]]],"future"],[],["loc",[null,[433,52],[433,75]]]],"is-active",""],[],["loc",[null,[433,31],[433,92]]]]]]],
        ["inline","future-blueprint-panel",[],["edit","editEntry","delete","deleteEntry","items",["subexpr","@mut",[["get","futureItems",["loc",[null,[438,16],[438,27]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[439,16],[439,21]]]]],[],[]]],["loc",[null,[435,8],[439,23]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[444,6],[444,19]]]]],[],21,22,["loc",[null,[444,0],[480,7]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[483,12],[483,16]]]],"therapy"],[],["loc",[null,[483,8],[483,27]]]]],[],23,24,["loc",[null,[483,2],[489,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[490,10],[490,19]]]],"treatment"],[],["loc",[null,[490,6],[490,32]]]]],[],25,26,["loc",[null,[490,0],[498,9]]]],
        ["inline","treatment-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[502,17],[502,37]]]]],[],[]]],["loc",[null,[501,0],[502,39]]]],
        ["inline","edit-todo-panel",[],["timeUnits",["subexpr","@mut",[["get","timeUnits",["loc",[null,[503,28],[503,37]]]]],[],[]],"confirm","storeTodo","article",["subexpr","@mut",[["get","model",["loc",[null,[503,66],[503,71]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","editTodoPanel",["loc",[null,[504,17],[504,30]]]]],[],[]]],["loc",[null,[503,0],[504,32]]]],
        ["inline","treatment-multistage-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentMultiStageSelectPanel",["loc",[null,[506,17],[506,47]]]]],[],[]]],["loc",[null,[505,0],[506,49]]]],
        ["inline","treatment-select-service-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectServicePanel",["loc",[null,[508,17],[508,44]]]]],[],[]]],["loc",[null,[507,0],[508,46]]]],
        ["inline","document-upload-panel",[],["type","therapy","uploadDone","reload","actionReceiver",["subexpr","@mut",[["get","documentUploadPanel",["loc",[null,[509,74],[509,93]]]]],[],[]]],["loc",[null,[509,0],[509,95]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26]
    };
  }()));

});
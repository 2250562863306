define('jason-frontend/templates/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","card u-center");
          dom.setAttribute(el1,"id","errorContainer");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","top-header");
          var el3 = dom.createElement("h1");
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/assistant.svg");
          dom.setAttribute(el4,"style","margin-right: 7px");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        Selbst-Registrierung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","error");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","ms-0 w-100 context button-rework");
          var el3 = dom.createTextNode("Schließen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element44 = dom.childAt(fragment, [1]);
          var element45 = dom.childAt(element44, [5]);
          var morphs = new Array(2);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element44, [3]),0,0);
          morphs[1] = dom.createElementMorph(element45);
          return morphs;
        },
        statements: [
          ["content","errorMsg",["loc",[null,[22,23],[22,37]]]],
          ["element","action",["close"],[],["loc",[null,[23,55],[23,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 8
                },
                "end": {
                  "line": 42,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Klicken Sie auf den folgenden Link um die eltiga® App zu installieren\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","http://onelink.to/etiga");
              dom.setAttribute(el2,"class","is-highlighted clickable ");
              var el3 = dom.createTextNode("Jetzt Smartphone-App downloaden.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","pl10 pr10");
              dom.setAttribute(el1,"src","assets/images/etiga-app-qr.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","card u-center");
            dom.setAttribute(el1,"id","successContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","top-header");
            var el3 = dom.createElement("h1");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/assistant.svg");
            dom.setAttribute(el4,"style","margin-right: 7px");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          Selbst-Registrierung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/checkmark.svg");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Aufnahme erfolgreich");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/roger_neu.jpeg");
            dom.setAttribute(el2,"class","roger");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"class","context button default mt20");
            var el3 = dom.createTextNode("Schließen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element42 = dom.childAt(fragment, [1]);
            var element43 = dom.childAt(element42, [9]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element42,7,7);
            morphs[1] = dom.createElementMorph(element43);
            return morphs;
          },
          statements: [
            ["block","if",[["get","etigaActivated",["loc",[null,[35,14],[35,28]]]]],[],0,null,["loc",[null,[35,8],[42,15]]]],
            ["element","action",["close"],[],["loc",[null,[45,52],[45,70]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 12
                },
                "end": {
                  "line": 58,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element34 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element34, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",[["get","mandant.logo.fullUrl",["loc",[null,[57,26],[57,46]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 59
                  },
                  "end": {
                    "line": 158,
                    "column": 107
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","truncate",[["get","patient.name",["loc",[null,[158,90],[158,102]]]],20],[],["loc",[null,[158,79],[158,107]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 107
                  },
                  "end": {
                    "line": 158,
                    "column": 119
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Tier");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 219,
                      "column": 29
                    },
                    "end": {
                      "line": 220,
                      "column": 191
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("abbr");
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"class","max-h-20px");
                  dom.setAttribute(el2,"src","assets/images/icons/rework/trash-dark.svg");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element25 = dom.childAt(fragment, [2]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createElementMorph(element25);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","patient.digitalRace.name",["loc",[null,[219,56],[219,84]]]],
                  ["element","action",["resetRace",["get","patient",["loc",[null,[220,100],[220,107]]]]],[],["loc",[null,[219,91],[220,109]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 220,
                      "column": 191
                    },
                    "end": {
                      "line": 221,
                      "column": 64
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","cursor");
                  var el2 = dom.createTextNode("Bitte wählen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 215,
                    "column": 14
                  },
                  "end": {
                    "line": 226,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-12/24@desk l-24/24 gutter");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","form-field-rework");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("label");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","d-block");
                var el5 = dom.createTextNode("Rasse");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element26 = dom.childAt(fragment, [1, 1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element26);
                morphs[1] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                return morphs;
              },
              statements: [
                ["element","action",["openSelectPicker",["get","patient",["loc",[null,[218,53],[218,60]]]],"Rasse wählen"],[],["loc",[null,[218,25],[218,77]]]],
                ["block","if",[["get","patient.digitalRace",["loc",[null,[219,35],[219,54]]]]],[],0,1,["loc",[null,[219,29],[221,71]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 269,
                      "column": 14
                    },
                    "end": {
                      "line": 290,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-12/24@desk l-24/24 gutter");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","form-field-rework");
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("label");
                  dom.setAttribute(el3,"class","select-placeholder");
                  var el4 = dom.createTextNode("Versicherung");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-12/24@desk l-24/24 gutter");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","form-field-rework");
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("label");
                  dom.setAttribute(el3,"for","insuranceNumber");
                  dom.setAttribute(el3,"class","placeholder");
                  var el4 = dom.createTextNode("Versicherungs-Nr");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","select-2",[],["content",["subexpr","@mut",[["get","insurers",["loc",[null,[274,30],[274,38]]]]],[],[]],"value",["subexpr","@mut",[["get","patient.insurer",["loc",[null,[275,28],[275,43]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","patientInsurer","placeholder","Bitte wählen"],["loc",[null,[273,20],[281,22]]]],
                  ["inline","input",[],["value",["subexpr","@mut",[["get","patient.insuranceNumber",["loc",[null,[286,34],[286,57]]]]],[],[]],"name","insuranceNumber","placeholder",""],["loc",[null,[286,20],[286,97]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 241,
                    "column": 12
                  },
                  "end": {
                    "line": 292,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","grid gutter-fix");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("label");
                dom.setAttribute(el4,"for","chipId");
                dom.setAttribute(el4,"class","placeholder");
                var el5 = dom.createTextNode("Chip-Nr");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("label");
                dom.setAttribute(el4,"for","color");
                dom.setAttribute(el4,"class","placeholder");
                var el5 = dom.createTextNode("Farbe");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter pt-5");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework");
                var el4 = dom.createTextNode("\n                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class"," d-flex align-items-center");
                var el5 = dom.createTextNode("\n                      ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
                var el6 = dom.createTextNode("\n                        ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                        ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("label");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                      ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("label");
                dom.setAttribute(el5,"for","");
                dom.setAttribute(el5,"class","checkbox-label");
                var el6 = dom.createTextNode("Versichert");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element22 = dom.childAt(fragment, [1]);
                var element23 = dom.childAt(element22, [5, 1, 1, 1]);
                var element24 = dom.childAt(element23, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element22, [1, 1]),1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element22, [3, 1]),1,1);
                morphs[2] = dom.createMorphAt(element23,1,1);
                morphs[3] = dom.createAttrMorph(element24, 'for');
                morphs[4] = dom.createMorphAt(element22,7,7);
                return morphs;
              },
              statements: [
                ["inline","input",[],["value",["subexpr","@mut",[["get","patient.chipId",["loc",[null,[247,32],[247,46]]]]],[],[]],"name","chipId","placeholder",""],["loc",[null,[247,18],[247,77]]]],
                ["inline","input",[],["value",["subexpr","@mut",[["get","patient.color",["loc",[null,[253,32],[253,45]]]]],[],[]],"name","color","placeholder",""],["loc",[null,[253,18],[253,75]]]],
                ["inline","input",[],["type","checkbox","id",["subexpr","concat",["insured",["get","patient.uid",["loc",[null,[261,69],[261,80]]]]],[],["loc",[null,[261,51],[261,81]]]],"checked",["subexpr","@mut",[["get","patient.insured",["loc",[null,[262,40],[262,55]]]]],[],[]]],["loc",[null,[261,24],[262,57]]]],
                ["attribute","for",["concat",[["subexpr","concat",["insured",["get","patient.uid",["loc",[null,[263,55],[263,66]]]]],[],["loc",[null,[263,36],[263,68]]]]]]],
                ["block","if",[["get","patient.insured",["loc",[null,[269,20],[269,35]]]]],[],0,null,["loc",[null,[269,14],[290,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 308,
                      "column": 16
                    },
                    "end": {
                      "line": 313,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","form-field-rework");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","horsePassNumber");
                  dom.setAttribute(el2,"class","placeholder");
                  var el3 = dom.createTextNode("Pferdepass-Nr");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","input",[],["value",["subexpr","@mut",[["get","patient.horsePassNumber",["loc",[null,[310,34],[310,57]]]]],[],[]],"name","horsePassNumber","placeholder",""],["loc",[null,[310,20],[310,97]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 293,
                    "column": 12
                  },
                  "end": {
                    "line": 346,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","grid gutter-fix");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter pt-5");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework mb-0");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class"," d-flex align-items-center");
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("label");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                    ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("label");
                dom.setAttribute(el5,"for","");
                dom.setAttribute(el5,"class","checkbox-label");
                var el6 = dom.createTextNode("Pferdepass");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                  ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter pt-5");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class"," d-flex align-items-center");
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("label");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                    ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("label");
                dom.setAttribute(el5,"for","");
                dom.setAttribute(el5,"class","checkbox-label");
                var el6 = dom.createTextNode("Zur Schlachtung bestimmt");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                  ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter pt-5");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework ");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class"," d-flex align-items-center");
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                      ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("label");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                    ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("label");
                dom.setAttribute(el5,"for","");
                dom.setAttribute(el5,"class","checkbox-label");
                var el6 = dom.createTextNode("Abgabebeleg");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                  ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-24/24 gutter");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","form-field-rework");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("label");
                dom.setAttribute(el4,"for","stableAddress");
                dom.setAttribute(el4,"class","placeholder");
                var el5 = dom.createTextNode("Stalladresse");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(element15, [1, 1, 1, 1]);
                var element17 = dom.childAt(element16, [3]);
                var element18 = dom.childAt(element15, [5, 1, 1, 1]);
                var element19 = dom.childAt(element18, [3]);
                var element20 = dom.childAt(element15, [7, 1, 1, 1]);
                var element21 = dom.childAt(element20, [3]);
                var morphs = new Array(8);
                morphs[0] = dom.createMorphAt(element16,1,1);
                morphs[1] = dom.createAttrMorph(element17, 'for');
                morphs[2] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
                morphs[3] = dom.createMorphAt(element18,1,1);
                morphs[4] = dom.createAttrMorph(element19, 'for');
                morphs[5] = dom.createMorphAt(element20,1,1);
                morphs[6] = dom.createAttrMorph(element21, 'for');
                morphs[7] = dom.createMorphAt(dom.childAt(element15, [9, 1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","input",[],["type","checkbox","id",["subexpr","concat",["horsePass",["get","patient.uid",["loc",[null,[299,69],[299,80]]]]],[],["loc",[null,[299,49],[299,81]]]],"checked",["subexpr","@mut",[["get","patient.horsePass",["loc",[null,[300,38],[300,55]]]]],[],[]]],["loc",[null,[299,22],[300,57]]]],
                ["attribute","for",["concat",[["subexpr","concat",["horsePass",["get","patient.uid",["loc",[null,[301,55],[301,66]]]]],[],["loc",[null,[301,34],[301,68]]]]]]],
                ["block","if",[["get","patient.horsePass",["loc",[null,[308,22],[308,39]]]]],[],0,null,["loc",[null,[308,16],[313,23]]]],
                ["inline","input",[],["type","checkbox","id",["subexpr","concat",["slaugther",["get","patient.uid",["loc",[null,[319,69],[319,80]]]]],[],["loc",[null,[319,49],[319,81]]]],"checked",["subexpr","@mut",[["get","patient.slaugther",["loc",[null,[320,38],[320,55]]]]],[],[]]],["loc",[null,[319,22],[320,57]]]],
                ["attribute","for",["concat",[["subexpr","concat",["slaugther",["get","patient.uid",["loc",[null,[321,55],[321,66]]]]],[],["loc",[null,[321,34],[321,68]]]]]]],
                ["inline","input",[],["type","checkbox","id",["subexpr","concat",["handoverDocument",["get","patient.uid",["loc",[null,[331,76],[331,87]]]]],[],["loc",[null,[331,49],[331,88]]]],"checked",["subexpr","@mut",[["get","patient.handoverDocument",["loc",[null,[332,38],[332,62]]]]],[],[]]],["loc",[null,[331,22],[332,64]]]],
                ["attribute","for",["concat",[["subexpr","concat",["handoverDocument",["get","patient.uid",["loc",[null,[333,62],[333,73]]]]],[],["loc",[null,[333,34],[333,75]]]]]]],
                ["inline","input",[],["value",["subexpr","@mut",[["get","patient.stableAddress",["loc",[null,[341,32],[341,53]]]]],[],[]],"name","stableAddress","placeholder",""],["loc",[null,[341,18],[341,91]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 354,
                      "column": 25
                    },
                    "end": {
                      "line": 356,
                      "column": 25
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                           ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","cursor info-badge info-badge-small");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","value.name",["loc",[null,[355,77],[355,91]]]]
                ],
                locals: ["value"],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 353,
                    "column": 18
                  },
                  "end": {
                    "line": 357,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","is-highlighted");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element14,1,1);
                morphs[1] = dom.createMorphAt(element14,3,3);
                return morphs;
              },
              statements: [
                ["block","each",[["get","patient.introductionReasons",["loc",[null,[354,33],[354,60]]]]],[],0,null,["loc",[null,[354,25],[356,34]]]],
                ["inline","icon-plus",[],["color","#999999","classNames","mt2 inline-block vertical-middle"],["loc",[null,[357,20],[357,95]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 357,
                    "column": 102
                  },
                  "end": {
                    "line": 358,
                    "column": 105
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                    Auswählen ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","icon-plus",[],["color","#999999","classNames","mt2 inline-block vertical-middle"],["loc",[null,[358,30],[358,105]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 366,
                      "column": 16
                    },
                    "end": {
                      "line": 373,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","imageFrame");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3,"class","img");
                  dom.setAttribute(el3,"src","#");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","button context reset");
                  var el3 = dom.createTextNode("Foto verwerfen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element11);
                  return morphs;
                },
                statements: [
                  ["element","action",["retakePicture",["get","patient",["loc",[null,[371,77],[371,84]]]]],[],["loc",[null,[371,52],[371,86]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 373,
                      "column": 16
                    },
                    "end": {
                      "line": 379,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","imageFrame");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","activatePicture");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3,"src","assets/images/icons/camera.svg");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1, 1, 1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element10);
                  return morphs;
                },
                statements: [
                  ["element","action",["uploadFile",["get","patient",["loc",[null,[376,86],[376,93]]]]],[],["loc",[null,[376,64],[376,95]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 362,
                    "column": 12
                  },
                  "end": {
                    "line": 381,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-24/24 gutter");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("input");
                dom.setAttribute(el2,"type","file");
                dom.setAttribute(el2,"class","fileUpload");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [1]);
                var element13 = dom.childAt(element12, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element12, 'id');
                morphs[1] = dom.createAttrMorph(element13, 'id');
                morphs[2] = dom.createElementMorph(element13);
                morphs[3] = dom.createMorphAt(element12,3,3);
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",[["get","patient.uid",["loc",[null,[363,52],[363,63]]]]]]],
                ["attribute","id",["concat",[["get","patient.uid",["loc",[null,[364,41],[364,52]]]],"fileUpload"]]],
                ["element","action",["renderPreview",["get","patient",["loc",[null,[364,110],[364,117]]]]],["on","change"],["loc",[null,[364,85],[365,107]]]],
                ["block","if",[["get","patient.pictureTaken",["loc",[null,[366,22],[366,42]]]]],[],0,1,["loc",[null,[366,16],[379,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 153,
                  "column": 10
                },
                "end": {
                  "line": 383,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","separator");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1,"class","d-flex align-items-center");
              var el2 = dom.createElement("span");
              var el3 = dom.createElement("b");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","delete ml-auto");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3,"class","cursor button-rework context delete");
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Tier entfernen");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","grid gutter-fix");
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-24/24 gutter");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","form-field-rework");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"for","patientName");
              dom.setAttribute(el4,"class","placeholder required");
              var el5 = dom.createTextNode("Name");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","form-field-rework");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"class","select-placeholder");
              var el5 = dom.createTextNode("Geschlecht");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter pt-5");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","form-field-rework");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class"," d-flex align-items-center");
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
              var el6 = dom.createTextNode("\n                      ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                      ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("label");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                    ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("label");
              dom.setAttribute(el5,"for","");
              dom.setAttribute(el5,"class","checkbox-label");
              var el6 = dom.createTextNode("Kastriert");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","form-field-rework");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"class","select-placeholder");
              var el5 = dom.createTextNode("Tierart");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","form-field-rework");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class"," d-flex align-items-center");
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("label");
              dom.setAttribute(el5,"for","patient-birthday");
              dom.setAttribute(el5,"class","placeholder");
              var el6 = dom.createTextNode("Geburtstag");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5,"src","assets/images/icons/rework/calendar-light-dark.svg");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                  ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-24/24 gutter mb20 introduction");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("Vorstellungsgründe");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","pick-list");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","cursor");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element27 = dom.childAt(fragment, [3]);
              var element28 = dom.childAt(element27, [2, 1]);
              var element29 = dom.childAt(fragment, [5]);
              var element30 = dom.childAt(element29, [5, 1, 1, 1]);
              var element31 = dom.childAt(element30, [3]);
              var element32 = dom.childAt(element29, [11, 1, 1]);
              var element33 = dom.childAt(fragment, [10, 3, 1]);
              var morphs = new Array(15);
              morphs[0] = dom.createMorphAt(dom.childAt(element27, [0, 0]),0,0);
              morphs[1] = dom.createElementMorph(element28);
              morphs[2] = dom.createMorphAt(dom.childAt(element29, [1, 1]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element29, [3, 1]),3,3);
              morphs[4] = dom.createMorphAt(element30,1,1);
              morphs[5] = dom.createAttrMorph(element31, 'for');
              morphs[6] = dom.createMorphAt(dom.childAt(element29, [7, 1]),3,3);
              morphs[7] = dom.createMorphAt(element29,9,9);
              morphs[8] = dom.createMorphAt(element32,1,1);
              morphs[9] = dom.createMorphAt(element32,7,7);
              morphs[10] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[11] = dom.createMorphAt(fragment,8,8,contextualElement);
              morphs[12] = dom.createElementMorph(element33);
              morphs[13] = dom.createMorphAt(element33,1,1);
              morphs[14] = dom.createMorphAt(fragment,12,12,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["get","patient.name",["loc",[null,[158,65],[158,77]]]]],[],0,1,["loc",[null,[158,59],[158,126]]]],
              ["element","action",["removePatient",["get","patient",["loc",[null,[160,49],[160,56]]]]],[],["loc",[null,[160,24],[160,58]]]],
              ["inline","input",[],["value",["subexpr","@mut",[["get","patient.name",["loc",[null,[169,32],[169,44]]]]],[],[]],"name","patientName","placeholder",""],["loc",[null,[169,18],[169,80]]]],
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientGenders",["loc",[null,[178,28],[178,42]]]]],[],[]],"value",["subexpr","@mut",[["get","patient.gender",["loc",[null,[179,26],[179,40]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","searchEnabled",false,"allowClear",false,"name","patientGender","placeholder","Bitte wählen"],["loc",[null,[177,18],[186,20]]]],
              ["inline","input",[],["type","checkbox","id",["subexpr","concat",["castrated",["get","patient.uid",["loc",[null,[193,69],[193,80]]]]],[],["loc",[null,[193,49],[193,81]]]],"checked",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[194,38],[194,55]]]]],[],[]]],["loc",[null,[193,22],[194,57]]]],
              ["attribute","for",["concat",[["subexpr","concat",["castrated",["get","patient.uid",["loc",[null,[195,55],[195,66]]]]],[],["loc",[null,[195,34],[195,68]]]]]]],
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[205,28],[205,45]]]]],[],[]],"value",["subexpr","@mut",[["get","patient.category",["loc",[null,[206,26],[206,42]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","patientCategory","placeholder","Bitte wählen"],["loc",[null,[204,18],[212,20]]]],
              ["block","if",[["get","patient.category",["loc",[null,[215,20],[215,36]]]]],[],2,null,["loc",[null,[215,14],[226,21]]]],
              ["inline","input",[],["placeholder","","class","admissionDatepicker input--editable no-readonly-style","value",["subexpr","@mut",[["get","patient.birthday",["loc",[null,[230,111],[230,127]]]]],[],[]],"name","patient-birthday","readonly","readonly"],["loc",[null,[230,20],[231,73]]]],
              ["inline","etiga-change-status",[],["attr","birthday","model",["subexpr","@mut",[["get","model",["loc",[null,[234,64],[234,69]]]]],[],[]],"open","openLogbook","classNames",""],["loc",[null,[234,20],[234,104]]]],
              ["block","if",[["subexpr","or",[["subexpr","eq",[["get","patient.category.id",["loc",[null,[242,22],[242,41]]]],"hu"],[],["loc",[null,[242,18],[242,47]]]],["subexpr","eq",[["get","patient.category.id",["loc",[null,[242,52],[242,71]]]],"hund"],[],["loc",[null,[242,48],[242,79]]]],["subexpr","eq",[["get","patient.category.id",["loc",[null,[242,84],[242,103]]]],"katze"],[],["loc",[null,[242,80],[242,112]]]],["subexpr","eq",[["get","patient.category.id",["loc",[null,[242,117],[242,136]]]],"ka"],[],["loc",[null,[242,113],[242,142]]]]],[],["loc",[null,[242,14],[242,143]]]]],[],3,null,["loc",[null,[241,12],[292,19]]]],
              ["block","if",[["subexpr","eq",[["get","patient.category.id",["loc",[null,[293,22],[293,41]]]],"pferd"],[],["loc",[null,[293,18],[293,50]]]]],[],4,null,["loc",[null,[293,12],[346,19]]]],
              ["element","action",["selectTreatmentDetailInfoMulti","introductionReason","Vorstellungsgründe",["get","patient",["loc",[null,[352,43],[352,50]]]]],[],["loc",[null,[351,34],[352,52]]]],
              ["block","if",[["get","patient.introductionReasons",["loc",[null,[353,24],[353,51]]]]],[],5,6,["loc",[null,[353,18],[358,112]]]],
              ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[362,18],[362,26]]]]],[],7,null,["loc",[null,[362,12],[381,19]]]]
            ],
            locals: ["patient"],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 392,
                    "column": 16
                  },
                  "end": {
                    "line": 394,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("em");
                var el2 = dom.createTextNode("*");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 405,
                      "column": 20
                    },
                    "end": {
                      "line": 408,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted block");
                  dom.setAttribute(el1,"target","_blank");
                  var el2 = dom.createTextNode("Dokument\n                        ansehen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element5);
                  return morphs;
                },
                statements: [
                  ["element","action",["getConsentDocument",["get","consent",["loc",[null,[406,55],[406,62]]]]],[],["loc",[null,[406,25],[406,64]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 409,
                      "column": 20
                    },
                    "end": {
                      "line": 411,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted block");
                  dom.setAttribute(el1,"target","_blank");
                  var el2 = dom.createTextNode("Dokument ansehen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element4, 'href');
                  return morphs;
                },
                statements: [
                  ["attribute","href",["concat",[["get","consent.webUrl",["loc",[null,[410,33],[410,47]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 397,
                    "column": 14
                  },
                  "end": {
                    "line": 414,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","checkbox-custom");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var element7 = dom.childAt(element6, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(element6,1,1);
                morphs[1] = dom.createAttrMorph(element7, 'for');
                morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
                morphs[3] = dom.createMorphAt(element7,3,3);
                morphs[4] = dom.createMorphAt(element7,4,4);
                return morphs;
              },
              statements: [
                ["inline","input",[],["type","checkbox","id",["subexpr","@mut",[["get","consent.id",["loc",[null,[400,29],[400,39]]]]],[],[]],"name",["subexpr","@mut",[["get","consent.id",["loc",[null,[401,31],[401,41]]]]],[],[]],"checked",["subexpr","@mut",[["get","consent.checked",["loc",[null,[402,34],[402,49]]]]],[],[]]],["loc",[null,[399,18],[402,51]]]],
                ["attribute","for",["get","consent.id",["loc",[null,[403,31],[403,41]]]]],
                ["content","consent.message",["loc",[null,[404,25],[404,44]]]],
                ["block","if",[["get","consent.uploadUrl",["loc",[null,[405,26],[405,43]]]]],[],0,null,["loc",[null,[405,20],[408,27]]]],
                ["block","if",[["get","consent.webUrl",["loc",[null,[409,26],[409,40]]]]],[],1,null,["loc",[null,[409,20],[411,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 417,
                      "column": 18
                    },
                    "end": {
                      "line": 420,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted clickable block");
                  dom.setAttribute(el1,"target","_blank");
                  var el2 = dom.createTextNode("Dokument\n                      ansehen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element2);
                  return morphs;
                },
                statements: [
                  ["element","action",["getConsentDocument",["get","consent",["loc",[null,[418,53],[418,60]]]]],[],["loc",[null,[418,23],[418,62]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 421,
                      "column": 18
                    },
                    "end": {
                      "line": 424,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted clickable block");
                  dom.setAttribute(el1,"target","_blank");
                  var el2 = dom.createTextNode("Dokument\n                      ansehen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element1, 'href');
                  return morphs;
                },
                statements: [
                  ["attribute","href",["concat",[["get","consent.webUrl",["loc",[null,[422,31],[422,45]]]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 414,
                    "column": 14
                  },
                  "end": {
                    "line": 426,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","consentFormat");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                morphs[1] = dom.createMorphAt(element3,3,3);
                morphs[2] = dom.createMorphAt(element3,4,4);
                return morphs;
              },
              statements: [
                ["content","consent.message",["loc",[null,[416,23],[416,42]]]],
                ["block","if",[["get","consent.uploadUrl",["loc",[null,[417,24],[417,41]]]]],[],0,null,["loc",[null,[417,18],[420,25]]]],
                ["block","if",[["get","consent.webUrl",["loc",[null,[421,24],[421,38]]]]],[],1,null,["loc",[null,[421,18],[424,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 389,
                  "column": 10
                },
                "end": {
                  "line": 428,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","mb10 col l-24/24 gutter  pt-15 sf-checkbox mt10");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","block mb-10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element9,0,0);
              morphs[1] = dom.createMorphAt(element9,2,2);
              morphs[2] = dom.createMorphAt(element8,3,3);
              return morphs;
            },
            statements: [
              ["content","consent.title",["loc",[null,[391,41],[391,58]]]],
              ["block","if",[["get","consent.mandatoryConsent",["loc",[null,[392,22],[392,46]]]]],[],0,null,["loc",[null,[392,16],[394,23]]]],
              ["block","if",[["get","consent.renderAsCheck",["loc",[null,[397,20],[397,41]]]]],[],1,2,["loc",[null,[397,14],[426,21]]]]
            ],
            locals: ["consent"],
            templates: [child0, child1, child2]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 429,
                  "column": 10
                },
                "end": {
                  "line": 433,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-24/24 text-center mb10 mt10");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","loading-animation",["loc",[null,[431,14],[431,35]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 435,
                  "column": 10
                },
                "end": {
                  "line": 442,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-12/24@phone gutter mt40");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2,"class","button-rework context default");
              var el3 = dom.createTextNode("Abbrechen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-12/24@phone gutter mt40");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2,"type","submit");
              dom.setAttribute(el2,"class","button-rework context primary");
              var el3 = dom.createTextNode("Speichern");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["reset"],[],["loc",[null,[437,60],[437,78]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 445,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","card");
            dom.setAttribute(el1,"id","formContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","top-header");
            var el3 = dom.createElement("h1");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/assistant.svg");
            dom.setAttribute(el4,"style","margin-right: 7px");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          Selbst-Registrierung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"id","progressBar");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","bar");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","header grid");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-24/24 l-8/24@desk");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-24/24 l-16/24@desk");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","like-h1");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(", ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("form");
            dom.setAttribute(el2,"class","grid");
            dom.setAttribute(el2,"method","post");
            dom.setAttribute(el2,"id","admissionForm");
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h2");
            var el4 = dom.createTextNode("Aufnahmeformular ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            dom.setAttribute(el4,"class","sub ps-0 mb-20 mt-20");
            var el5 = dom.createTextNode("Bitte füllen Sie das Aufnahmeformular möglichst\n            vollständig aus. Ihre Tierarztpraxis setzt die Elektronische Tiergesundheitsakte ein.");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","separator");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            var el4 = dom.createElement("b");
            var el5 = dom.createTextNode("Tierhalter:in");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","grid gutter-fix");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"class","select-placeholder");
            var el7 = dom.createTextNode("Kategorie");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"class","select-placeholder");
            var el7 = dom.createTextNode("Titel");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","firstname");
            dom.setAttribute(el6,"class","placeholder required");
            var el7 = dom.createTextNode("Vorname");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","lastname");
            dom.setAttribute(el6,"class","placeholder required");
            var el7 = dom.createTextNode("Nachname");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","street");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("Straße");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","postalCode");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("PLZ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","town");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("Stadt");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","phone");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("Telefon");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("img");
            dom.setAttribute(el6,"src","assets/images/icons/rework/phone.svg");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","email");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("E-Mail");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("img");
            dom.setAttribute(el6,"src","assets/images/icons/rework/email.svg");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-24/24 gutter mb30");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"class","button-rework dark");
            var el5 = dom.createTextNode("Weiteres Tier hinzufügen");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element35 = dom.childAt(fragment, [1]);
            var element36 = dom.childAt(element35, [5]);
            var element37 = dom.childAt(element36, [3]);
            var element38 = dom.childAt(element37, [3]);
            var element39 = dom.childAt(element35, [7]);
            var element40 = dom.childAt(element39, [7]);
            var element41 = dom.childAt(element39, [11, 1]);
            var morphs = new Array(20);
            morphs[0] = dom.createMorphAt(dom.childAt(element36, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
            morphs[2] = dom.createMorphAt(element38,0,0);
            morphs[3] = dom.createMorphAt(element38,2,2);
            morphs[4] = dom.createMorphAt(element38,4,4);
            morphs[5] = dom.createElementMorph(element39);
            morphs[6] = dom.createMorphAt(dom.childAt(element40, [1, 1]),3,3);
            morphs[7] = dom.createMorphAt(dom.childAt(element40, [3, 1]),3,3);
            morphs[8] = dom.createMorphAt(dom.childAt(element40, [5, 1]),1,1);
            morphs[9] = dom.createMorphAt(dom.childAt(element40, [7, 1]),1,1);
            morphs[10] = dom.createMorphAt(dom.childAt(element40, [9, 1]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element40, [11, 1]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element40, [13, 1]),1,1);
            morphs[13] = dom.createMorphAt(dom.childAt(element40, [15, 1]),1,1);
            morphs[14] = dom.createMorphAt(dom.childAt(element40, [17, 1]),1,1);
            morphs[15] = dom.createMorphAt(element39,9,9);
            morphs[16] = dom.createElementMorph(element41);
            morphs[17] = dom.createMorphAt(element39,13,13);
            morphs[18] = dom.createMorphAt(element39,14,14);
            morphs[19] = dom.createMorphAt(element39,16,16);
            return morphs;
          },
          statements: [
            ["block","if",[["get","mandant.logo",["loc",[null,[56,18],[56,30]]]]],[],0,null,["loc",[null,[56,12],[58,19]]]],
            ["content","mandant.name",["loc",[null,[61,33],[61,49]]]],
            ["content","mandant.street",["loc",[null,[62,15],[62,33]]]],
            ["content","mandant.postalcode",["loc",[null,[62,35],[62,57]]]],
            ["content","mandant.town",["loc",[null,[62,58],[62,74]]]],
            ["element","action",["confirm"],["on","submit"],["loc",[null,[66,60],[66,92]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","genders",["loc",[null,[79,26],[79,33]]]]],[],[]],"value",["subexpr","@mut",[["get","model.customer.gender",["loc",[null,[80,24],[80,45]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"searchEnabled",false,"name","gender","placeholder","Bitte wählen"],["loc",[null,[78,16],[87,18]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","titles",["loc",[null,[94,26],[94,32]]]]],[],[]],"value",["subexpr","@mut",[["get","model.customer.title",["loc",[null,[95,24],[95,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","searchEnabled",false,"allowClear",false,"name","title","placeholder","Bitte wählen"],["loc",[null,[93,16],[102,18]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.firstname",["loc",[null,[109,30],[109,54]]]]],[],[]],"name","firstname","placeholder",""],["loc",[null,[109,16],[109,88]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.lastname",["loc",[null,[115,30],[115,53]]]]],[],[]],"name","lastname","placeholder",""],["loc",[null,[115,16],[115,86]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.street",["loc",[null,[121,30],[121,51]]]]],[],[]],"name","street","placeholder",""],["loc",[null,[121,16],[121,82]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.postalCode",["loc",[null,[127,30],[127,55]]]]],[],[]],"name","postalCode","placeholder",""],["loc",[null,[127,16],[127,90]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.town",["loc",[null,[133,30],[133,49]]]]],[],[]],"name","town","placeholder",""],["loc",[null,[133,16],[133,78]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.phone",["loc",[null,[139,30],[139,50]]]]],[],[]],"name","phone","placeholder",""],["loc",[null,[139,16],[139,80]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","model.customer.email",["loc",[null,[146,30],[146,50]]]]],[],[]],"name","email","placeholder",""],["loc",[null,[146,16],[146,80]]]],
            ["block","each",[["get","model.patients",["loc",[null,[153,18],[153,32]]]]],[],1,null,["loc",[null,[153,10],[383,19]]]],
            ["element","action",["addPatient"],[],["loc",[null,[386,47],[386,70]]]],
            ["block","each",[["get","consents",["loc",[null,[389,18],[389,26]]]]],[],2,null,["loc",[null,[389,10],[428,19]]]],
            ["block","if",[["get","loading",["loc",[null,[429,16],[429,23]]]]],[],3,null,["loc",[null,[429,10],[433,17]]]],
            ["block","unless",[["get","loading",["loc",[null,[435,20],[435,27]]]]],[],4,null,["loc",[null,[435,10],[442,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 2
            },
            "end": {
              "line": 446,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","successMessage",["loc",[null,[27,10],[27,24]]]]],[],0,1,["loc",[null,[27,4],[445,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 490,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  html, body {\n    height: 100%;\n  }\n\n  html, body > .ember-view {\n    height: 100%;\n  }\n\n  body {\n    background-position: center center;\n    background-size: cover;\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","publicAdmission");
        dom.setAttribute(el1,"class","admission");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('body').delegate('.admissionDatepicker', \"focusin\", function () {\n      $(this).daterangepicker({\n        singleDatePicker: true,\n        showDropdowns: true,\n        \"locale\": {\n          \"format\": \"DD.MM.YYYY\",\n          \"daysOfWeek\": [\n            \"So\",\n            \"Mo\",\n            \"Di\",\n            \"Mi\",\n            \"Do\",\n            \"Fr\",\n            \"Sa\"\n          ],\n          \"monthNames\": [\n            \"Jänner\",\n            \"Februar\",\n            \"März\",\n            \"April\",\n            \"Mai\",\n            \"Juni\",\n            \"Juli\",\n            \"August\",\n            \"September\",\n            \"Oktober\",\n            \"November\",\n            \"Dezember\"\n          ],\n          \"firstDay\": 1\n        },\n      });\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["content","style",["loc",[null,[13,4],[13,13]]]],
        ["block","if",[["get","error",["loc",[null,[18,8],[18,13]]]]],[],0,1,["loc",[null,[18,2],[446,9]]]],
        ["inline","simple-select",[],["select","changeRace","actionReceiver",["subexpr","@mut",[["get","simpleSelectPanel",["loc",[null,[449,51],[449,68]]]]],[],[]]],["loc",[null,[449,0],[449,70]]]],
        ["inline","treatment-select-panel",[],["confirm","selectIntroductionReasons","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[451,40],[451,60]]]]],[],[]]],["loc",[null,[450,0],[451,62]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('jason-frontend/templates/components/customer-saldo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 20,
                "column": 112
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","transaction.invoiceCode",["loc",[null,[20,85],[20,112]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 18
                    },
                    "end": {
                      "line": 30,
                      "column": 100
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","reference.code",["loc",[null,[30,82],[30,100]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 16
                  },
                  "end": {
                    "line": 31,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small label label-danger");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","reference.id",["loc",[null,[30,45],[30,57]]]]],["class","is-highlighted"],0,null,["loc",[null,[30,18],[30,112]]]],
                ["inline","button-text",[],["content","Mahnung","text","M"],["loc",[null,[30,152],[30,194]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 18
                    },
                    "end": {
                      "line": 33,
                      "column": 105
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("#");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","reference.code",["loc",[null,[33,87],[33,105]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 117
                    },
                    "end": {
                      "line": 33,
                      "column": 181
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(", ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 16
                  },
                  "end": {
                    "line": 34,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["block","link-to",["transactions.show",["get","reference.id",["loc",[null,[33,49],[33,61]]]]],["class","is-highlighted"],0,null,["loc",[null,[33,18],[33,117]]]],
                ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[33,131],[33,136]]]],["subexpr","minus",[["get","transaction.references.length",["loc",[null,[33,144],[33,173]]]],1],[],["loc",[null,[33,137],[33,176]]]]],[],["loc",[null,[33,127],[33,177]]]]],[],1,null,["loc",[null,[33,117],[33,192]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 39
                    },
                    "end": {
                      "line": 36,
                      "column": 103
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(", ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 16
                  },
                  "end": {
                    "line": 37,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  UE-");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["content","reference.code",["loc",[null,[36,21],[36,39]]]],
                ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[36,53],[36,58]]]],["subexpr","minus",[["get","transaction.references.length",["loc",[null,[36,66],[36,95]]]],1],[],["loc",[null,[36,59],[36,98]]]]],[],["loc",[null,[36,49],[36,99]]]]],[],0,null,["loc",[null,[36,39],[36,114]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 14
                },
                "end": {
                  "line": 38,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","reference.type",["loc",[null,[29,26],[29,40]]]],"invoice"],[],["loc",[null,[29,22],[29,51]]]]],[],0,null,["loc",[null,[29,16],[31,23]]]],
              ["block","if",[["subexpr","eq",[["get","reference.type",["loc",[null,[32,26],[32,40]]]],"transaction"],[],["loc",[null,[32,22],[32,55]]]]],[],1,null,["loc",[null,[32,16],[34,23]]]],
              ["block","if",[["subexpr","eq",[["get","reference.type",["loc",[null,[35,26],[35,40]]]],"banktransfer"],[],["loc",[null,[35,22],[35,56]]]]],[],2,null,["loc",[null,[35,16],[37,23]]]]
            ],
            locals: ["reference","index"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 12
              },
              "end": {
                "line": 39,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","transaction.references",["loc",[null,[28,22],[28,44]]]]],[],0,null,["loc",[null,[28,14],[38,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 16
                  },
                  "end": {
                    "line": 61,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/rework/cashdesk.svg");
                dom.setAttribute(el1,"title","Bezahlen");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 14
                },
                "end": {
                  "line": 62,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["cash.in",["get","transaction.invoiceId",["loc",[null,[59,37],[59,58]]]]],["classNames","icon-button icon-button--success list-action-square"],0,null,["loc",[null,[59,16],[61,28]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 16
                    },
                    "end": {
                      "line": 65,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/cashdesk.svg");
                  dom.setAttribute(el1,"title","Überweisen");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 14
                  },
                  "end": {
                    "line": 66,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.banktransfer",["get","transaction.invoiceId",["loc",[null,[63,51],[63,72]]]]],["classNames","icon-button--success list-action-square"],0,null,["loc",[null,[63,16],[65,28]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 14
                },
                "end": {
                  "line": 66,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","banktransferEnabled",["loc",[null,[62,24],[62,43]]]]],[],0,null,["loc",[null,[62,14],[66,14]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 12
              },
              "end": {
                "line": 67,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","show-cashdesk-action",[],[],["loc",[null,[58,25],[58,47]]]],["subexpr","has-permission",["c_payments"],[],["loc",[null,[58,48],[58,77]]]]],[],["loc",[null,[58,20],[58,78]]]]],[],0,1,["loc",[null,[58,14],[66,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 70,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-15/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","Rechnung");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(",");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","Referenzen");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-9/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","Re-Betrag");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Bezahlt");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Offen");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Guthaben");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Saldo");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [6]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [8, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [10, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [12, 1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [14, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [16, 1]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element0, [18]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","transaction.created",["loc",[null,[17,38],[17,57]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[17,24],[17,80]]]],
          ["block","link-to",["invoices.show",["get","transaction.invoiceId",["loc",[null,[20,39],[20,60]]]]],["class","is-highlighted"],0,null,["loc",[null,[20,12],[20,124]]]],
          ["block","if",[["get","transaction.references",["loc",[null,[27,18],[27,40]]]]],[],1,null,["loc",[null,[27,12],[39,19]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.invoiceSum",["loc",[null,[42,44],[42,66]]]]],[],[]]],["loc",[null,[42,20],[42,68]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.invoicePaid",["loc",[null,[45,44],[45,67]]]]],[],[]]],["loc",[null,[45,20],[45,69]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.invoiceOpen",["loc",[null,[48,44],[48,67]]]]],[],[]]],["loc",[null,[48,20],[48,69]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.invoiceDeposit",["loc",[null,[51,44],[51,70]]]]],[],[]]],["loc",[null,[51,20],[51,72]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.invoiceBalance",["loc",[null,[54,44],[54,70]]]]],[],[]]],["loc",[null,[54,20],[54,72]]]],
          ["block","if",[["subexpr","gt",[["get","transaction.invoiceOpen",["loc",[null,[57,22],[57,45]]]],0],[],["loc",[null,[57,18],[57,48]]]]],[],2,null,["loc",[null,[57,12],[67,19]]]]
        ],
        locals: ["transaction"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/customer-saldo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table table--large");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__head");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Datum");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-2/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Re-Nr");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-2/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Referenzen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-2/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Re-Betrag");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Bezahlt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Offen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Guthaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","table__title title");
        var el5 = dom.createTextNode("Saldo");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell l-3/24");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                            ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__body");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table__footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n              ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell ps-20 l-9/24 l-15/24@desk");
        dom.setAttribute(el3,"data-label","Status");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("Saldo");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right  l-9/24 l-3/24@desk");
        dom.setAttribute(el3,"data-label","Saldo");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createElement("b");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n                ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__cell table__cell--right  l-9/24 l-3/24@desk");
        dom.setAttribute(el3,"data-label","Status");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createElement("b");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [5]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [5, 1, 0]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [8, 1, 0]),0,0);
        return morphs;
      },
      statements: [
        ["block","each",[["get","deposits",["loc",[null,[14,14],[14,22]]]]],[],0,null,["loc",[null,[14,6],[70,15]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[79,41],[79,51]]]]],[],[]]],["loc",[null,[79,17],[79,53]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[83,41],[83,51]]]]],[],[]]],["loc",[null,[83,17],[83,53]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
define('jason-frontend/templates/customers/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/merge-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Kund:in zusammenführen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1,"id","customersVcardExportForm");
          dom.setAttribute(el1,"method","post");
          dom.setAttribute(el1,"action","api/customers/exportVcard");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","mandant_id");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","token");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","filterId");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","button-rework context");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/download-dark.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("vCard herunterladen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [1]);
          var element30 = dom.childAt(fragment, [3]);
          var element31 = dom.childAt(element30, [1]);
          var element32 = dom.childAt(element30, [5]);
          var element33 = dom.childAt(element30, [7]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element29);
          morphs[1] = dom.createAttrMorph(element31, 'value');
          morphs[2] = dom.createAttrMorph(element32, 'value');
          morphs[3] = dom.createElementMorph(element33);
          return morphs;
        },
        statements: [
          ["element","action",["openMergeCustomer"],[],["loc",[null,[6,11],[6,41]]]],
          ["attribute","value",["concat",[["get","mandantId",["loc",[null,[11,58],[11,67]]]]]]],
          ["attribute","value",["concat",[["get","model.id",["loc",[null,[13,56],[13,64]]]]]]],
          ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[14,48],[14,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 8
              },
              "end": {
                "line": 26,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/download-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Ordnerinhalt herunterladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element27 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element27);
            return morphs;
          },
          statements: [
            ["element","action",["downloadFolder"],[],["loc",[null,[22,13],[22,40]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 30,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element28);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","filterName",["loc",[null,[21,19],[21,29]]]]],[],["loc",[null,[21,14],[21,30]]]]],[],0,null,["loc",[null,[21,8],[26,15]]]],
          ["element","action",["openDocumentUpload"],[],["loc",[null,[27,11],[27,42]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 6
            },
            "end": {
              "line": 35,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tier anlegen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element26 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element26);
          return morphs;
        },
        statements: [
          ["element","action",["showCreatePatient"],[],["loc",[null,[32,11],[32,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 6
            },
            "end": {
              "line": 40,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context  primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element25);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAppointment"],[],["loc",[null,[37,11],[37,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 51,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context primary");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Guthaben ausbezahlen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element23);
            morphs[1] = dom.createMorphAt(element23,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["payoutDeposit",["get","model",["loc",[null,[47,38],[47,43]]]]],[],["loc",[null,[47,13],[47,45]]]],
            ["inline","button-cash",[],["color","#fff","size","32","showStroke",false],["loc",[null,[48,12],[48,67]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 6
            },
            "end": {
              "line": 52,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/printer-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldoübersicht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element24);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["print",["get","model",["loc",[null,[42,28],[42,33]]]]],[],["loc",[null,[42,11],[42,35]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[46,18],[46,28]]]],0],[],["loc",[null,[46,14],[46,31]]]]],[],0,null,["loc",[null,[46,8],[51,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 6
            },
            "end": {
              "line": 57,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Dokument hochladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element22);
          return morphs;
        },
        statements: [
          ["element","action",["showCreateConsent"],[],["loc",[null,[54,11],[54,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 4
            },
            "end": {
              "line": 67,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","gender-icon",[],["maxWidth","max-w-70px","classNames","owner-gender me-20","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.gender.id",["loc",[null,[66,121],[66,136]]]]],[],[]],"content",""],["loc",[null,[66,6],[66,149]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 29
            },
            "end": {
              "line": 70,
              "column": 67
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(", ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","model.street",["loc",[null,[70,49],[70,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 136
                },
                "end": {
                  "line": 76,
                  "column": 166
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("en");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 36
              },
              "end": {
                "line": 76,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Ausgangsrechnung");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","invoiceMeta.total_records",["loc",[null,[76,90],[76,119]]]],
            ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[76,146],[76,159]]]],1],[],["loc",[null,[76,142],[76,162]]]]],[],0,null,["loc",[null,[76,136],[76,173]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 6
            },
            "end": {
              "line": 79,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge cursor-pointer me-5");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["cash.choose"],["classNames","is-highlighted"],0,null,["loc",[null,[76,36],[76,185]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 6
            },
            "end": {
              "line": 86,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge me-5");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Guthaben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element21, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element21, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","depositSum",["loc",[null,[82,48],[82,58]]]],0],[],["loc",[null,[82,44],[82,61]]]],"is-negative","positive"],[],["loc",[null,[82,23],[82,88]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[83,63],[83,73]]]]],[],[]],"classNames","inline-block"],["loc",[null,[83,39],[83,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 111,
              "column": 8
            },
            "end": {
              "line": 113,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","gender-icon",[],["maxWidth","max-w-40px","classNames","owner-gender me-20","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.gender.id",["loc",[null,[112,125],[112,140]]]]],[],[]],"content",""],["loc",[null,[112,10],[112,153]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 33
            },
            "end": {
              "line": 117,
              "column": 71
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(", ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","model.street",["loc",[null,[117,53],[117,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 136
                },
                "end": {
                  "line": 125,
                  "column": 166
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("en");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 125,
                "column": 36
              },
              "end": {
                "line": 125,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Ausgangsrechnung");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","invoiceMeta.total_records",["loc",[null,[125,90],[125,119]]]],
            ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[125,146],[125,159]]]],1],[],["loc",[null,[125,142],[125,162]]]]],[],0,null,["loc",[null,[125,136],[125,173]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 6
            },
            "end": {
              "line": 128,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge cursor-pointer me-5");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["cash.choose"],["classNames","is-highlighted"],0,null,["loc",[null,[125,36],[125,185]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 129,
              "column": 6
            },
            "end": {
              "line": 135,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge me-5");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Guthaben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element20, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element20, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","depositSum",["loc",[null,[131,48],[131,58]]]],0],[],["loc",[null,[131,44],[131,61]]]],"is-negative","positive"],[],["loc",[null,[131,23],[131,88]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[132,63],[132,73]]]]],[],[]],"classNames","inline-block"],["loc",[null,[132,39],[132,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 147,
              "column": 4
            },
            "end": {
              "line": 152,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-accounting");
          var el2 = dom.createTextNode("Verrechnung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-warning");
          var el2 = dom.createTextNode("Mahnwesen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-saldo");
          var el2 = dom.createTextNode("Saldo\n        Informationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(fragment, [3]);
          var element19 = dom.childAt(fragment, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createElementMorph(element17);
          morphs[2] = dom.createAttrMorph(element18, 'class');
          morphs[3] = dom.createElementMorph(element18);
          morphs[4] = dom.createAttrMorph(element19, 'class');
          morphs[5] = dom.createElementMorph(element19);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[148,53],[148,62]]]],"accounting"],[],["loc",[null,[148,49],[148,76]]]],"is-active",""],[],["loc",[null,[148,28],[148,93]]]]]]],
          ["element","action",["selectTab","accounting"],[],["loc",[null,[148,118],[148,153]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[149,53],[149,62]]]],"warning"],[],["loc",[null,[149,49],[149,73]]]],"is-active",""],[],["loc",[null,[149,28],[149,90]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[149,112],[149,144]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[150,53],[150,62]]]],"saldo"],[],["loc",[null,[150,49],[150,71]]]],"is-active",""],[],["loc",[null,[150,28],[150,88]]]]]]],
          ["element","action",["selectTab","saldo"],[],["loc",[null,[150,108],[150,138]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 156,
              "column": 4
            },
            "end": {
              "line": 158,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-appointments");
          var el2 = dom.createTextNode("Termine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element16, 'class');
          morphs[1] = dom.createElementMorph(element16);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[157,53],[157,62]]]],"appointments"],[],["loc",[null,[157,49],[157,78]]]],"is-active",""],[],["loc",[null,[157,28],[157,95]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[157,122],[157,159]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 348,
                "column": 26
              },
              "end": {
                "line": 358,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","select-placeholder");
            var el2 = dom.createTextNode("Produktkategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","productCategories",["loc",[null,[351,46],[351,63]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.productCategory",["loc",[null,[352,44],[352,69]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[350,30],[357,32]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 359,
                "column": 26
              },
              "end": {
                "line": 369,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","select-placeholder");
            var el2 = dom.createTextNode("Leistungskategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[362,46],[362,63]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.serviceCategory",["loc",[null,[363,44],[363,69]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[361,30],[368,32]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 380,
                "column": 26
              },
              "end": {
                "line": 385,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["element","action",["addReduction",["get","reduction",["loc",[null,[381,55],[381,64]]]]],[],["loc",[null,[381,31],[381,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 386,
                "column": 26
              },
              "end": {
                "line": 391,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/trash.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [
            ["element","action",["removeReduction",["get","reduction",["loc",[null,[387,58],[387,67]]]]],[],["loc",[null,[387,31],[387,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 319,
              "column": 18
            },
            "end": {
              "line": 394,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Patient(en)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Typ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-4/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-reduction");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Rabatt in %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-2/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","line-action-buttons");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [5, 1]);
          var element15 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(element14,1,1);
          morphs[3] = dom.createMorphAt(element14,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),1,1);
          morphs[5] = dom.createMorphAt(element15,1,1);
          morphs[6] = dom.createMorphAt(element15,2,2);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","model.patients",["loc",[null,[324,36],[324,50]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.patient",["loc",[null,[325,34],[325,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[323,26],[330,28]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","customerReductionTypes",["loc",[null,[337,34],[337,56]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.type",["loc",[null,[338,32],[338,46]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[336,24],[343,26]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[348,36],[348,53]]]],"product"],[],["loc",[null,[348,32],[348,64]]]]],[],0,null,["loc",[null,[348,26],[358,33]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[359,36],[359,53]]]],"service"],[],["loc",[null,[359,32],[359,64]]]]],[],1,null,["loc",[null,[359,26],[369,33]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","reduction.reduction",["loc",[null,[374,55],[374,74]]]]],[],[]],"name","user-reduction"],["loc",[null,[374,26],[374,98]]]],
          ["block","if",[["subexpr","eq",[["get","reduction",["loc",[null,[380,36],[380,45]]]],["get","model.reductions.lastObject",["loc",[null,[380,46],[380,73]]]]],[],["loc",[null,[380,32],[380,74]]]]],[],2,null,["loc",[null,[380,26],[385,33]]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","reduction",["loc",[null,[386,41],[386,50]]]],["get","model.reductions.firstObject",["loc",[null,[386,51],[386,79]]]]],[],["loc",[null,[386,37],[386,80]]]]],[],["loc",[null,[386,32],[386,81]]]]],[],3,null,["loc",[null,[386,26],[391,33]]]]
        ],
        locals: ["reduction"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 498,
                "column": 26
              },
              "end": {
                "line": 500,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[499,28],[499,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 511,
                      "column": 40
                    },
                    "end": {
                      "line": 518,
                      "column": 143
                    }
                  },
                  "moduleName": "jason-frontend/templates/customers/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",32,"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[513,120],[513,139]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[514,118],[514,142]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patient.etigaActive",["loc",[null,[515,123],[515,142]]]]],[],[]],"evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[516,115],[516,127]]]]],[],[]],"content","","classNames","animal-icon"],["loc",[null,[512,48],[518,136]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 520,
                      "column": 40
                    },
                    "end": {
                      "line": 522,
                      "column": 125
                    }
                  },
                  "moduleName": "jason-frontend/templates/customers/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-died",[],["size","16","useConfirm",false,"color","#3B6182","showStroke",false,"content","verstorben"],["loc",[null,[521,42],[522,125]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 508,
                    "column": 34
                  },
                  "end": {
                    "line": 526,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","selectable-patient animal-card patient");
                var el2 = dom.createTextNode("\n                                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","d-flex min-h-30px align-items-center");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n\n                                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element7,1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
                morphs[2] = dom.createMorphAt(element7,5,5);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patient.category",["loc",[null,[511,46],[511,62]]]]],[],0,null,["loc",[null,[511,40],[518,150]]]],
                ["content","patient.name",["loc",[null,[519,46],[519,62]]]],
                ["block","if",[["get","patient.dead",["loc",[null,[520,46],[520,58]]]]],[],1,null,["loc",[null,[520,40],[522,132]]]]
              ],
              locals: ["patient"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 501,
                  "column": 28
                },
                "end": {
                  "line": 540,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-3/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24 l-13/24@desk");
              dom.setAttribute(el2,"data-label","Leistungsempfänger");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--right@desk table__cell--name l-12/24 l-24/24@tablet l-4/24@desk");
              dom.setAttribute(el2,"data-label","Summe brutto");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-3");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [7]);
              var element11 = dom.childAt(element10, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element8, [5, 1]),0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element10, [1]),1,1);
              morphs[5] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","invoice.created",["loc",[null,[504,54],[504,69]]]],"DD. MM. YYYY"],[],["loc",[null,[504,40],[504,86]]]],
              ["inline","time-format",[["get","invoice.created",["loc",[null,[505,66],[505,81]]]],"HH:mm"],[],["loc",[null,[505,52],[505,91]]]],
              ["block","each",[["get","invoice.selectedPatients",["loc",[null,[508,42],[508,66]]]]],[],0,null,["loc",[null,[508,34],[526,43]]]],
              ["inline","format-currency",[],["value",["subexpr","@mut",[["get","invoice.total",["loc",[null,[529,64],[529,77]]]]],[],[]]],["loc",[null,[529,40],[529,79]]]],
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","invoice",["loc",[null,[533,101],[533,108]]]]],[],[]],"confirmed","closeInvoice","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[533,36],[533,194]]]],
              ["element","action",["selectInvoice",["get","invoice.id",["loc",[null,[535,62],[535,72]]]]],[],["loc",[null,[535,37],[535,74]]]]
            ],
            locals: ["invoice"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 500,
                "column": 26
              },
              "end": {
                "line": 541,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","invoices",["loc",[null,[501,36],[501,44]]]]],[],0,null,["loc",[null,[501,28],[540,37]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 485,
              "column": 20
            },
            "end": {
              "line": 545,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper-rework");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table-header");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Ausgangsrechnungen (Entwürfe)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table grid table--large");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Datum");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-13/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Leistungsempfänger");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--right l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Summe brutto");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showLoading",["loc",[null,[498,32],[498,43]]]]],[],0,1,["loc",[null,[498,26],[541,33]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 564,
              "column": 22
            },
            "end": {
              "line": 564,
              "column": 242
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [0]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createAttrMorph(element6, 'style');
          morphs[2] = dom.createAttrMorph(element6, 'data-id');
          morphs[3] = dom.createElementMorph(element6);
          morphs[4] = dom.createMorphAt(element6,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[564,98],[564,110]]]],"selected",""],[],["loc",[null,[564,77],[564,126]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[564,155],[564,164]]]]]]],
          ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[564,179],[564,185]]]]]]],
          ["element","action",["selectSearchTag",["get","tag",["loc",[null,[564,216],[564,219]]]]],[],["loc",[null,[564,189],[564,221]]]],
          ["content","tag.name",["loc",[null,[564,222],[564,234]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 605,
                  "column": 32
                },
                "end": {
                  "line": 607,
                  "column": 32
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","max-w-30px");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element4, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/icons/",["get","patient.gender.id",["loc",[null,[606,85],[606,102]]]],".svg"]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 610,
                  "column": 34
                },
                "end": {
                  "line": 610,
                  "column": 252
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["eltigaActive",["subexpr","@mut",[["get","patient.etigaActive",["loc",[null,[610,85],[610,104]]]]],[],[]],"evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[610,110],[610,122]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[610,158],[610,177]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[610,186],[610,210]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[610,229],[610,250]]]]],[],[]]],["loc",[null,[610,58],[610,252]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 615,
                    "column": 32
                  },
                  "end": {
                    "line": 615,
                    "column": 150
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[615,52],[615,150]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 613,
                  "column": 30
                },
                "end": {
                  "line": 616,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.name",["loc",[null,[614,32],[614,48]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[615,38],[615,50]]]]],[],0,null,["loc",[null,[615,32],[615,157]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 617,
                    "column": 32
                  },
                  "end": {
                    "line": 617,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","patient.name",["loc",[null,[617,71],[617,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 618,
                    "column": 32
                  },
                  "end": {
                    "line": 618,
                    "column": 150
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[618,52],[618,150]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 616,
                  "column": 30
                },
                "end": {
                  "line": 619,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[617,59],[617,69]]]]],[],0,null,["loc",[null,[617,32],[617,99]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[618,38],[618,50]]]]],[],1,null,["loc",[null,[618,32],[618,157]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 624,
                  "column": 28
                },
                "end": {
                  "line": 624,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.digitalRace.name",["loc",[null,[624,55],[624,83]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 624,
                  "column": 83
                },
                "end": {
                  "line": 624,
                  "column": 107
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[624,91],[624,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 628,
                  "column": 28
                },
                "end": {
                  "line": 632,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undeletePatient",["get","patient.id",["loc",[null,[629,120],[629,130]]]]],[],["loc",[null,[629,93],[629,132]]]],
              ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[630,32],[630,128]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 637,
                    "column": 30
                  },
                  "end": {
                    "line": 639,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 632,
                  "column": 28
                },
                "end": {
                  "line": 643,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/barcode.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm-id",[],["color","#fff","showStroke",false,"itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[634,101],[634,111]]]]],[],[]],"confirmed","deletePatient","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[634,32],[635,113]]]],
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[637,57],[637,67]]]]],["classNames","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[637,30],[639,42]]]],
              ["element","action",["selectBarcode",["get","patient",["loc",[null,[640,58],[640,65]]]]],[],["loc",[null,[640,33],[640,67]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 601,
                "column": 26
              },
              "end": {
                "line": 646,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 table__cell--center l-6/24@tablet l-1/24@desk ps-5");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Art");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","patient-view");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Spezies");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [2, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [3, 1]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [4]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [6]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.gender",["loc",[null,[605,38],[605,52]]]]],[],0,null,["loc",[null,[605,32],[607,39]]]],
            ["block","if",[["get","patient.category",["loc",[null,[610,40],[610,56]]]]],[],1,null,["loc",[null,[610,34],[610,259]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[613,36],[613,51]]]]],[],2,3,["loc",[null,[613,30],[619,37]]]],
            ["content","patient.category.name",["loc",[null,[622,28],[622,53]]]],
            ["block","if",[["get","patient.digitalRace",["loc",[null,[624,34],[624,53]]]]],[],4,5,["loc",[null,[624,28],[624,114]]]],
            ["content","patient.color",["loc",[null,[626,28],[626,45]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[628,34],[628,49]]]]],[],6,7,["loc",[null,[628,28],[643,35]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 600,
              "column": 24
            },
            "end": {
              "line": 647,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[601,41],[601,53]]]]],[],["loc",[null,[601,36],[601,54]]]],["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","quickFilter",["loc",[null,[601,68],[601,79]]]],"all"],[],["loc",[null,[601,64],[601,86]]]],["subexpr","eq",[["get","quickFilter",["loc",[null,[601,91],[601,102]]]],"deleted"],[],["loc",[null,[601,87],[601,113]]]]],[],["loc",[null,[601,60],[601,114]]]],["get","patient.dead",["loc",[null,[601,115],[601,127]]]]],[],["loc",[null,[601,55],[601,128]]]]],[],["loc",[null,[601,32],[601,129]]]]],[],0,null,["loc",[null,[601,26],[646,33]]]]
        ],
        locals: ["patient"],
        templates: [child0]
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 660,
              "column": 18
            },
            "end": {
              "line": 671,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-consent");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Status aus vorherigem Praxismanagementsystem");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient-consent");
          var el4 = dom.createTextNode("DSGVO akzeptiert");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled",true,"type","checkbox","name","patient.consent","id","patient-consent","checked",true],["loc",[null,[665,24],[665,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 683,
              "column": 6
            },
            "end": {
              "line": 687,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[684,11],[684,38]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[685,10],[685,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 688,
              "column": 6
            },
            "end": {
              "line": 692,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[690,44],[690,52]]]]],[],[]],"confirmed","delete","size",52,"showStroke",false,"color","#fff","text","Wollen Sie diese/n Kunde/in wirklich löschen?"],["loc",[null,[690,10],[690,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 693,
              "column": 6
            },
            "end": {
              "line": 697,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[694,13],[694,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 720,
              "column": 0
            },
            "end": {
              "line": 722,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-forward-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[721,42],[721,62]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[721,2],[721,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 778,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar floading-header sticky  pb-5");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kundenkartei");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","navBackgroundWrapper");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","u-hide@tablet card p-relative card mt-50 mb-0 min-h-130px patient-card customer-card");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex flex-row align-items-center");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","mb0 d-flex align-items-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","mt-0 mb-0");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ml-auto d-flex align-items-center u-hide@tablet");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","topcard-badge me-5");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createElement("b");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Saldo");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center mt-15");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button-rework info ms-0");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/rework/graph-light-dark.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Umsätze");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button-rework info ms-0");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"src","assets/images/icons/rework/contact-light-dark.svg");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Kontaktdaten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","floating-header patient-mini-card card-rework");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex align-items-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex flex-column");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","fs-2 fw-bold d-flex align-items-center");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","mt-0 mb-0");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml-auto d-flex align-items-center u-hide@tablet");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","topcard-badge me-5");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","textBelow");
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" Saldo");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-master-data");
        var el4 = dom.createTextNode("Stammdaten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-reminder");
        var el4 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-documents");
        var el4 = dom.createTextNode("Dokumente");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-patients");
        var el4 = dom.createTextNode("Tiere");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-correspondance");
        var el4 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-consents");
        var el4 = dom.createTextNode("Einverständniserklärungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","customerDetails");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","customerEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-accounting");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Zahlungsinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","select-placeholder");
        var el10 = dom.createTextNode("Zahlungsmoral");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-sued");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-sued");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Gesperrt weil Nichtzahler");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rechnungsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-addition");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Briefzusatz");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-differentInvoice");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-differentInvoice");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Alternative\n                            Rechnungsadresse?");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-firstname");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Vorname");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-lastname");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Nachname");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","select-placeholder");
        var el10 = dom.createTextNode("Titel");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-invoiceStreet");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Straße/Hausnummer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-postalcode");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("PLZ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-town");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Ort");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-uid");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("UID");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rechnungskonditionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoiceConditions");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Konditionen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom checkbox-primary field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-sued");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-reductionToPurchasePrice");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Rabatt zum Einkaufspreis");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Bankverbindung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-iban");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Iban");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-bic");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("BIC");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rabattinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gutter-fix grid");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Anfahrtsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-driveNotice");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-driveStreet");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Straße");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-drivePostalcode");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("PLZ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-distanceKm");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-distanceMinutes");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Entfernung in Minuten");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Kundenspezifische Mahnzeiten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-10");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning1");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis 1. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-10");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning2");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis 2. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-30");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-nopayer");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis Kunde als Nichtzahler markiert wird (nach dem Verschicken der 2. Mahnung).  (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","is-highlighted");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Tipp:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Um das Mahnwesen für diesen Kunden zu deaktivieren muss in den entsprechenden Feldern ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("0");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" eingetragen werden.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 d-flex align-items-center");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework mb-0 me-20");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Auswahl");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Standardeinstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-saldo");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper-rework");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table-header");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h2");
        var el9 = dom.createTextNode("Saldoübersicht");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-medias");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-documents");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-content min-h-60px mb-20");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex mt-10");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ps-30 ml-auto pt-10 quick-filter");
        dom.setAttribute(el8,"id","tagFilters");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-patients");
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper-rework");
        var el7 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter-wrapper");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","quick-filter");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        var el10 = dom.createTextNode("Filter");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Verstorbene ausblenden");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--large");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head with-header");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Name");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Spezies");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Rasse");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Farbe");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-appointments");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-correspondance");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-consents");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n\n    setTimeout(function () {\n      $('.floating-header-wrapper').attr('style', 'width: ' + $('#customerDetails').width() + 'px !important');\n    }, 300);\n\n    $(window).resize(function () {\n      if ($('body').hasClass('floating-header-active')) {\n        $('.floating-header-wrapper').attr('style', 'width: ' + $('#customerDetails').width() + 'px !important');\n      }\n    });\n\n    $('.datetimepicker').on('apply.daterangepicker', function (ev, picker) {\n      $(this).val(picker.startDate.format('DD. MM. YYYY'));\n      $(this).focus();\n    });\n    $('.datetimepicker').daterangepicker({\n      singleDatePicker: true,\n      autoUpdateInput: false,\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element34 = dom.childAt(fragment, [0, 1, 3]);
        var element35 = dom.childAt(fragment, [4]);
        var element36 = dom.childAt(element35, [1]);
        var element37 = dom.childAt(element36, [3]);
        var element38 = dom.childAt(element37, [1]);
        var element39 = dom.childAt(element37, [3]);
        var element40 = dom.childAt(element36, [5]);
        var element41 = dom.childAt(element40, [4, 1]);
        var element42 = dom.childAt(element35, [3]);
        var element43 = dom.childAt(element42, [1]);
        var element44 = dom.childAt(element42, [3]);
        var element45 = dom.childAt(fragment, [6]);
        var element46 = dom.childAt(element45, [1, 1]);
        var element47 = dom.childAt(element46, [1]);
        var element48 = dom.childAt(element47, [3]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element48, [3]);
        var element51 = dom.childAt(element46, [3]);
        var element52 = dom.childAt(element51, [4, 1]);
        var element53 = dom.childAt(element45, [3]);
        var element54 = dom.childAt(element53, [1]);
        var element55 = dom.childAt(element53, [5]);
        var element56 = dom.childAt(element53, [7]);
        var element57 = dom.childAt(element53, [9]);
        var element58 = dom.childAt(element53, [13]);
        var element59 = dom.childAt(element53, [15]);
        var element60 = dom.childAt(fragment, [8]);
        var element61 = dom.childAt(element60, [1, 1, 1]);
        var element62 = dom.childAt(element61, [1]);
        var element63 = dom.childAt(element61, [3]);
        var element64 = dom.childAt(element63, [3]);
        var element65 = dom.childAt(element63, [7]);
        var element66 = dom.childAt(element65, [5]);
        var element67 = dom.childAt(element65, [7]);
        var element68 = dom.childAt(element65, [11]);
        var element69 = dom.childAt(element65, [13]);
        var element70 = dom.childAt(element65, [15]);
        var element71 = dom.childAt(element65, [17]);
        var element72 = dom.childAt(element63, [11]);
        var element73 = dom.childAt(element63, [15]);
        var element74 = dom.childAt(element63, [23]);
        var element75 = dom.childAt(element61, [5]);
        var element76 = dom.childAt(element75, [3]);
        var element77 = dom.childAt(element75, [5]);
        var element78 = dom.childAt(element75, [7]);
        var element79 = dom.childAt(element61, [7]);
        var element80 = dom.childAt(element79, [3]);
        var element81 = dom.childAt(element61, [9]);
        var element82 = dom.childAt(element61, [11]);
        var element83 = dom.childAt(element61, [13]);
        var element84 = dom.childAt(element83, [1, 1]);
        var element85 = dom.childAt(element61, [15]);
        var element86 = dom.childAt(element85, [1]);
        var element87 = dom.childAt(element86, [1, 1]);
        var element88 = dom.childAt(element87, [3]);
        var element89 = dom.childAt(element87, [5]);
        var element90 = dom.childAt(element87, [7]);
        var element91 = dom.childAt(element61, [17]);
        var element92 = dom.childAt(element61, [19]);
        var element93 = dom.childAt(element61, [21]);
        var element94 = dom.childAt(element60, [3]);
        var morphs = new Array(128);
        morphs[0] = dom.createMorphAt(element34,1,1);
        morphs[1] = dom.createMorphAt(element34,2,2);
        morphs[2] = dom.createMorphAt(element34,3,3);
        morphs[3] = dom.createMorphAt(element34,4,4);
        morphs[4] = dom.createMorphAt(element34,5,5);
        morphs[5] = dom.createMorphAt(element34,6,6);
        morphs[6] = dom.createMorphAt(element36,1,1);
        morphs[7] = dom.createMorphAt(element38,0,0);
        morphs[8] = dom.createMorphAt(element38,2,2);
        morphs[9] = dom.createMorphAt(element38,4,4);
        morphs[10] = dom.createMorphAt(element39,0,0);
        morphs[11] = dom.createMorphAt(element39,1,1);
        morphs[12] = dom.createMorphAt(element39,3,3);
        morphs[13] = dom.createMorphAt(element40,1,1);
        morphs[14] = dom.createMorphAt(element40,2,2);
        morphs[15] = dom.createAttrMorph(element41, 'class');
        morphs[16] = dom.createMorphAt(dom.childAt(element41, [1, 0]),0,0);
        morphs[17] = dom.createMorphAt(element40,6,6);
        morphs[18] = dom.createElementMorph(element43);
        morphs[19] = dom.createElementMorph(element44);
        morphs[20] = dom.createAttrMorph(element45, 'class');
        morphs[21] = dom.createMorphAt(element47,1,1);
        morphs[22] = dom.createMorphAt(element49,0,0);
        morphs[23] = dom.createMorphAt(element49,2,2);
        morphs[24] = dom.createMorphAt(element49,4,4);
        morphs[25] = dom.createMorphAt(element50,0,0);
        morphs[26] = dom.createMorphAt(element50,1,1);
        morphs[27] = dom.createMorphAt(element50,3,3);
        morphs[28] = dom.createMorphAt(element51,1,1);
        morphs[29] = dom.createMorphAt(element51,2,2);
        morphs[30] = dom.createAttrMorph(element52, 'class');
        morphs[31] = dom.createMorphAt(dom.childAt(element52, [1, 0]),0,0);
        morphs[32] = dom.createMorphAt(element51,6,6);
        morphs[33] = dom.createAttrMorph(element54, 'class');
        morphs[34] = dom.createElementMorph(element54);
        morphs[35] = dom.createMorphAt(element53,3,3);
        morphs[36] = dom.createAttrMorph(element55, 'class');
        morphs[37] = dom.createElementMorph(element55);
        morphs[38] = dom.createAttrMorph(element56, 'class');
        morphs[39] = dom.createElementMorph(element56);
        morphs[40] = dom.createAttrMorph(element57, 'class');
        morphs[41] = dom.createElementMorph(element57);
        morphs[42] = dom.createMorphAt(element53,11,11);
        morphs[43] = dom.createAttrMorph(element58, 'class');
        morphs[44] = dom.createElementMorph(element58);
        morphs[45] = dom.createAttrMorph(element59, 'class');
        morphs[46] = dom.createElementMorph(element59);
        morphs[47] = dom.createElementMorph(element61);
        morphs[48] = dom.createAttrMorph(element62, 'class');
        morphs[49] = dom.createMorphAt(element62,1,1);
        morphs[50] = dom.createAttrMorph(element63, 'class');
        morphs[51] = dom.createMorphAt(dom.childAt(element64, [1, 1]),3,3);
        morphs[52] = dom.createMorphAt(dom.childAt(element64, [3, 1, 1, 1]),1,1);
        morphs[53] = dom.createMorphAt(dom.childAt(element65, [1, 1]),1,1);
        morphs[54] = dom.createMorphAt(dom.childAt(element65, [3, 1, 1, 1]),1,1);
        morphs[55] = dom.createAttrMorph(element66, 'class');
        morphs[56] = dom.createMorphAt(dom.childAt(element66, [1]),1,1);
        morphs[57] = dom.createAttrMorph(element67, 'class');
        morphs[58] = dom.createMorphAt(dom.childAt(element67, [1]),1,1);
        morphs[59] = dom.createMorphAt(dom.childAt(element65, [9, 1]),3,3);
        morphs[60] = dom.createAttrMorph(element68, 'class');
        morphs[61] = dom.createMorphAt(dom.childAt(element68, [1]),1,1);
        morphs[62] = dom.createAttrMorph(element69, 'class');
        morphs[63] = dom.createMorphAt(dom.childAt(element69, [1]),1,1);
        morphs[64] = dom.createAttrMorph(element70, 'class');
        morphs[65] = dom.createMorphAt(dom.childAt(element70, [1]),1,1);
        morphs[66] = dom.createAttrMorph(element71, 'class');
        morphs[67] = dom.createMorphAt(dom.childAt(element71, [1]),1,1);
        morphs[68] = dom.createMorphAt(dom.childAt(element72, [1, 1]),1,1);
        morphs[69] = dom.createMorphAt(dom.childAt(element72, [3, 1, 1, 1]),1,1);
        morphs[70] = dom.createMorphAt(dom.childAt(element73, [1, 1]),1,1);
        morphs[71] = dom.createMorphAt(dom.childAt(element73, [3, 1]),1,1);
        morphs[72] = dom.createMorphAt(dom.childAt(element63, [19]),1,1);
        morphs[73] = dom.createMorphAt(dom.childAt(element74, [1, 1]),1,1);
        morphs[74] = dom.createMorphAt(dom.childAt(element74, [3, 1]),1,1);
        morphs[75] = dom.createMorphAt(dom.childAt(element74, [5, 1]),1,1);
        morphs[76] = dom.createMorphAt(dom.childAt(element74, [7, 1]),1,1);
        morphs[77] = dom.createMorphAt(dom.childAt(element74, [9, 1]),1,1);
        morphs[78] = dom.createAttrMorph(element75, 'class');
        morphs[79] = dom.createMorphAt(dom.childAt(element76, [1]),1,1);
        morphs[80] = dom.createMorphAt(dom.childAt(element76, [3, 1]),0,0);
        morphs[81] = dom.createMorphAt(dom.childAt(element77, [1]),1,1);
        morphs[82] = dom.createMorphAt(dom.childAt(element77, [3, 1]),0,0);
        morphs[83] = dom.createMorphAt(dom.childAt(element78, [1]),1,1);
        morphs[84] = dom.createMorphAt(dom.childAt(element78, [3, 1]),0,0);
        morphs[85] = dom.createAttrMorph(element79, 'class');
        morphs[86] = dom.createMorphAt(dom.childAt(element80, [1]),3,3);
        morphs[87] = dom.createMorphAt(dom.childAt(element80, [3, 1]),0,0);
        morphs[88] = dom.createAttrMorph(element81, 'class');
        morphs[89] = dom.createMorphAt(element81,1,1);
        morphs[90] = dom.createMorphAt(dom.childAt(element81, [3]),3,3);
        morphs[91] = dom.createAttrMorph(element82, 'class');
        morphs[92] = dom.createMorphAt(element82,1,1);
        morphs[93] = dom.createAttrMorph(element83, 'class');
        morphs[94] = dom.createMorphAt(element84,1,1);
        morphs[95] = dom.createMorphAt(dom.childAt(element84, [3]),1,1);
        morphs[96] = dom.createMorphAt(element83,3,3);
        morphs[97] = dom.createAttrMorph(element85, 'class');
        morphs[98] = dom.createAttrMorph(element88, 'class');
        morphs[99] = dom.createElementMorph(element88);
        morphs[100] = dom.createAttrMorph(element89, 'class');
        morphs[101] = dom.createElementMorph(element89);
        morphs[102] = dom.createAttrMorph(element90, 'class');
        morphs[103] = dom.createElementMorph(element90);
        morphs[104] = dom.createMorphAt(dom.childAt(element86, [3, 3]),1,1);
        morphs[105] = dom.createAttrMorph(element91, 'class');
        morphs[106] = dom.createMorphAt(element91,1,1);
        morphs[107] = dom.createAttrMorph(element92, 'class');
        morphs[108] = dom.createMorphAt(element92,1,1);
        morphs[109] = dom.createAttrMorph(element93, 'class');
        morphs[110] = dom.createMorphAt(element93,1,1);
        morphs[111] = dom.createMorphAt(element93,3,3);
        morphs[112] = dom.createMorphAt(dom.childAt(element94, [1]),1,1);
        morphs[113] = dom.createMorphAt(element94,3,3);
        morphs[114] = dom.createMorphAt(element94,4,4);
        morphs[115] = dom.createMorphAt(element94,5,5);
        morphs[116] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[117] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[118] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[119] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[120] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[121] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[122] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[123] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[124] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[125] = dom.createMorphAt(fragment,29,29,contextualElement);
        morphs[126] = dom.createMorphAt(fragment,31,31,contextualElement);
        morphs[127] = dom.createMorphAt(fragment,33,33,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[5,16],[5,25]]]],"masterdata"],[],["loc",[null,[5,12],[5,39]]]]],[],0,null,["loc",[null,[5,6],[19,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[20,16],[20,25]]]],"documents"],[],["loc",[null,[20,12],[20,38]]]]],[],1,null,["loc",[null,[20,6],[30,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[31,16],[31,25]]]],"patients"],[],["loc",[null,[31,12],[31,37]]]]],[],2,null,["loc",[null,[31,6],[35,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[36,16],[36,25]]]],"appointments"],[],["loc",[null,[36,12],[36,41]]]]],[],3,null,["loc",[null,[36,6],[40,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[41,16],[41,25]]]],"saldo"],[],["loc",[null,[41,12],[41,34]]]]],[],4,null,["loc",[null,[41,6],[52,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[53,16],[53,25]]]],"consents"],[],["loc",[null,[53,12],[53,37]]]]],[],5,null,["loc",[null,[53,6],[57,13]]]],
        ["block","if",[["get","model.gender",["loc",[null,[65,10],[65,22]]]]],[],6,null,["loc",[null,[65,4],[67,11]]]],
        ["content","model.firstname",["loc",[null,[69,48],[69,67]]]],
        ["content","model.lastname",["loc",[null,[69,68],[69,86]]]],
        ["inline","eltiga-customer-badge",[],["customer",["subexpr","@mut",[["get","model",["loc",[null,[69,120],[69,125]]]]],[],[]],"iconClass","max-h-30px"],["loc",[null,[69,87],[69,150]]]],
        ["block","if",[["get","model.street",["loc",[null,[70,35],[70,47]]]]],[],7,null,["loc",[null,[70,29],[70,74]]]],
        ["content","model.postalCode",["loc",[null,[70,74],[70,94]]]],
        ["content","model.town",["loc",[null,[70,95],[70,109]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[73,16],[73,29]]]],0],[],["loc",[null,[73,12],[73,32]]]]],[],8,null,["loc",[null,[73,6],[79,13]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[80,16],[80,26]]]],0],[],["loc",[null,[80,12],[80,29]]]]],[],9,null,["loc",[null,[80,6],[86,13]]]],
        ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[88,46],[88,56]]]],0],[],["loc",[null,[88,42],[88,59]]]],"is-negative","positive"],[],["loc",[null,[88,21],[88,86]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[89,61],[89,71]]]]],[],[]],"classNames","inline-block"],["loc",[null,[89,37],[89,99]]]],
        ["inline","payment-moral-icon",[],["content",["subexpr","get-payment-moral-name",[["get","model",["loc",[null,[92,59],[92,64]]]],true],[],["loc",[null,[92,35],[92,70]]]],"moral",["subexpr","get-payment-moral",[["get","model",["loc",[null,[92,96],[92,101]]]],true],[],["loc",[null,[92,77],[92,107]]]]],["loc",[null,[92,6],[92,109]]]],
        ["element","action",["openRevenue",["get","model.id",["loc",[null,[96,30],[96,38]]]]],[],["loc",[null,[96,7],[96,40]]]],
        ["element","action",["openContactInfo",["get","model",["loc",[null,[100,34],[100,39]]]]],[],["loc",[null,[100,7],[100,41]]]],
        ["attribute","class",["concat",["floating-header-wrapper col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[107,68],[107,81]]]]],[],["loc",[null,[107,63],[107,82]]]],"24","18"],[],["loc",[null,[107,42],[107,94]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["get","model.gender",["loc",[null,[111,14],[111,26]]]]],[],10,null,["loc",[null,[111,8],[113,15]]]],
        ["content","model.firstname",["loc",[null,[115,61],[115,80]]]],
        ["content","model.lastname",["loc",[null,[115,81],[115,99]]]],
        ["inline","eltiga-customer-badge",[],["customer",["subexpr","@mut",[["get","model",["loc",[null,[115,133],[115,138]]]]],[],[]],"iconClass","max-h-30px"],["loc",[null,[115,100],[115,163]]]],
        ["block","if",[["get","model.street",["loc",[null,[117,39],[117,51]]]]],[],11,null,["loc",[null,[117,33],[117,78]]]],
        ["content","model.postalCode",["loc",[null,[117,78],[117,98]]]],
        ["content","model.town",["loc",[null,[117,99],[117,113]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[122,16],[122,29]]]],0],[],["loc",[null,[122,12],[122,32]]]]],[],12,null,["loc",[null,[122,6],[128,13]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[129,16],[129,26]]]],0],[],["loc",[null,[129,12],[129,29]]]]],[],13,null,["loc",[null,[129,6],[135,13]]]],
        ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[137,46],[137,56]]]],0],[],["loc",[null,[137,42],[137,59]]]],"is-negative","positive"],[],["loc",[null,[137,21],[137,86]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[138,61],[138,71]]]]],[],[]],"classNames","inline-block"],["loc",[null,[138,37],[138,99]]]],
        ["inline","payment-moral-icon",[],["content",["subexpr","get-payment-moral-name",[["get","model",["loc",[null,[141,59],[141,64]]]],true],[],["loc",[null,[141,35],[141,70]]]],"moral",["subexpr","get-payment-moral",[["get","model",["loc",[null,[141,96],[141,101]]]],true],[],["loc",[null,[141,77],[141,107]]]]],["loc",[null,[141,6],[141,109]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[146,51],[146,60]]]],"masterdata"],[],["loc",[null,[146,47],[146,74]]]],"is-active",""],[],["loc",[null,[146,26],[146,91]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[146,117],[146,152]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[147,10],[147,23]]]]],[],14,null,["loc",[null,[147,4],[152,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[153,51],[153,60]]]],"reminder"],[],["loc",[null,[153,47],[153,72]]]],"is-active",""],[],["loc",[null,[153,26],[153,89]]]]]]],
        ["element","action",["selectTab","reminder"],[],["loc",[null,[153,112],[153,145]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[154,51],[154,60]]]],"documents"],[],["loc",[null,[154,47],[154,73]]]],"is-active",""],[],["loc",[null,[154,26],[154,90]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[154,114],[154,148]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[155,51],[155,60]]]],"patients"],[],["loc",[null,[155,47],[155,72]]]],"is-active",""],[],["loc",[null,[155,26],[155,89]]]]]]],
        ["element","action",["selectTab","patients"],[],["loc",[null,[155,112],[155,145]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[156,10],[156,33]]]]],[],15,null,["loc",[null,[156,4],[158,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[159,51],[159,60]]]],"correspondance"],[],["loc",[null,[159,47],[159,78]]]],"is-active",""],[],["loc",[null,[159,26],[159,95]]]]]]],
        ["element","action",["selectTab","correspondance"],[],["loc",[null,[159,124],[159,163]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[160,51],[160,60]]]],"consents"],[],["loc",[null,[160,47],[160,72]]]],"is-active",""],[],["loc",[null,[160,26],[160,89]]]]]]],
        ["element","action",["selectTab","consents"],[],["loc",[null,[160,112],[160,145]]]],
        ["element","action",["save",["get","referer",["loc",[null,[168,71],[168,78]]]],["get","refererInvoiceId",["loc",[null,[168,79],[168,95]]]]],["on","submit"],["loc",[null,[168,55],[168,109]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[169,79],[169,88]]]],"masterdata"],[],["loc",[null,[169,75],[169,102]]]],"is-active",""],[],["loc",[null,[169,54],[169,119]]]]]]],
        ["inline","customers-edit-form",[],["edit",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[170,56],[170,61]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[170,70],[170,77]]]]],[],[]],"customerSalutations",["subexpr","@mut",[["get","customerSalutations",["loc",[null,[170,98],[170,117]]]]],[],[]],"countries",["subexpr","@mut",[["get","countries",["loc",[null,[170,128],[170,137]]]]],[],[]],"titles",["subexpr","@mut",[["get","titles",["loc",[null,[170,145],[170,151]]]]],[],[]],"setInputDirty","setInputDirty","addEmail","addEmail","removeEmail","removeEmail","addPhone","addPhone","removePhone","removePhone","addFax","addFax","removeFax","removeFax"],["loc",[null,[170,18],[170,313]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[172,79],[172,88]]]],"accounting"],[],["loc",[null,[172,75],[172,102]]]],"is-active",""],[],["loc",[null,[172,54],[172,119]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","paymentMorals",["loc",[null,[180,34],[180,47]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedPaymentMoral",["loc",[null,[181,32],[181,52]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen","name","user-payment-moral"],["loc",[null,[179,24],[187,26]]]],
        ["inline","input",[],["type","checkbox","name","user-sued","id","user-sued","checked",["subexpr","@mut",[["get","model.sued",["loc",[null,[194,92],[194,102]]]]],[],[]]],["loc",[null,[194,28],[194,104]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceAddition",["loc",[null,[208,53],[208,74]]]]],[],[]],"name","user-invoice-addition"],["loc",[null,[208,24],[208,105]]]],
        ["inline","input",[],["type","checkbox","name","user-differentInvoice","id","user-differentInvoice","checked",["subexpr","@mut",[["get","model.differentInvoice",["loc",[null,[216,116],[216,138]]]]],[],[]]],["loc",[null,[216,28],[216,140]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[224,83],[224,105]]]],"","hidden"],[],["loc",[null,[224,62],[224,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceFirstname",["loc",[null,[226,53],[226,75]]]]],[],[]],"name","user-invoice-firstname"],["loc",[null,[226,24],[226,107]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[230,83],[230,105]]]],"","hidden"],[],["loc",[null,[230,62],[230,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceLastname",["loc",[null,[232,53],[232,74]]]]],[],[]],"name","user-invoice-lastname"],["loc",[null,[232,24],[232,105]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","titles",["loc",[null,[240,34],[240,40]]]]],[],[]],"value",["subexpr","@mut",[["get","model.invoiceTitle",["loc",[null,[241,32],[241,50]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen","name","user-payment-moral"],["loc",[null,[239,24],[247,26]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[250,83],[250,105]]]],"","hidden"],[],["loc",[null,[250,62],[250,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceStreet",["loc",[null,[252,53],[252,72]]]]],[],[]],"name","user-invoice-invoiceStreet"],["loc",[null,[252,24],[252,108]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[256,83],[256,105]]]],"","hidden"],[],["loc",[null,[256,62],[256,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoicePostalcode",["loc",[null,[258,53],[258,76]]]]],[],[]],"name","user-invoice-postalcode"],["loc",[null,[258,24],[258,109]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[262,83],[262,105]]]],"","hidden"],[],["loc",[null,[262,62],[262,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceTown",["loc",[null,[264,53],[264,70]]]]],[],[]],"name","user-invoice-town"],["loc",[null,[264,24],[264,97]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[268,83],[268,105]]]],"","hidden"],[],["loc",[null,[268,62],[268,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceUid",["loc",[null,[270,53],[270,69]]]]],[],[]],"name","user-invoice-uid"],["loc",[null,[270,24],[270,95]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceConditions",["loc",[null,[282,53],[282,76]]]]],[],[]],"name","user-invoiceConditions"],["loc",[null,[282,24],[282,108]]]],
        ["inline","input",[],["type","checkbox","name","user-reductionToPurchasePrice","id","user-reductionToPurchasePrice","checked",["subexpr","@mut",[["get","model.reductionToPurchasePrice",["loc",[null,[290,132],[290,162]]]]],[],[]]],["loc",[null,[290,28],[290,164]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.iban",["loc",[null,[304,53],[304,63]]]]],[],[]],"name","user-iban"],["loc",[null,[304,24],[304,82]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.bic",["loc",[null,[310,53],[310,62]]]]],[],[]],"name","user-iban"],["loc",[null,[310,24],[310,81]]]],
        ["block","each",[["get","model.reductions",["loc",[null,[319,26],[319,42]]]]],[],16,null,["loc",[null,[319,18],[394,27]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.driveNotice",["loc",[null,[402,53],[402,70]]]]],[],[]],"name","user-driveNotice"],["loc",[null,[402,24],[402,96]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.driveStreet",["loc",[null,[408,53],[408,70]]]]],[],[]],"name","user-driveStreet"],["loc",[null,[408,24],[408,96]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.drivePostalcode",["loc",[null,[414,53],[414,74]]]]],[],[]],"name","user-drivePostalcode"],["loc",[null,[414,24],[414,104]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.distanceKm",["loc",[null,[420,53],[420,69]]]]],[],[]],"name","user-distanceKm"],["loc",[null,[420,24],[420,94]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.distanceMinutes",["loc",[null,[426,53],[426,74]]]]],[],[]],"name","user-distanceMinutes"],["loc",[null,[426,24],[426,104]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[432,79],[432,88]]]],"warning"],[],["loc",[null,[432,75],[432,99]]]],"is-active",""],[],["loc",[null,[432,54],[432,116]]]]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning1",["loc",[null,[437,70],[437,92]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-warning1"],["loc",[null,[437,22],[437,158]]]],
        ["content","model.defaultDaysTillWarning1",["loc",[null,[440,105],[440,138]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning2",["loc",[null,[445,70],[445,92]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-warning2"],["loc",[null,[445,22],[445,158]]]],
        ["content","model.defaultDaysTillWarning2",["loc",[null,[448,105],[448,138]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillNopayer",["loc",[null,[453,70],[453,91]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-nopayer"],["loc",[null,[453,22],[453,156]]]],
        ["content","model.defaultDaysTillNopayer",["loc",[null,[456,153],[456,185]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[462,79],[462,88]]]],"reminder"],[],["loc",[null,[462,75],[462,100]]]],"is-active",""],[],["loc",[null,[462,54],[462,117]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[469,32],[469,47]]]]],[],[]],"value",["subexpr","@mut",[["get","model.reminderSetting",["loc",[null,[470,30],[470,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Standardeinstellungen beibehalten","name","customer.reminderSetting"],["loc",[null,[468,22],[477,24]]]],
        ["content","model.defaultReminderSetting",["loc",[null,[479,50],[479,82]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[483,66],[483,75]]]],"saldo"],[],["loc",[null,[483,62],[483,84]]]],"is-active",""],[],["loc",[null,[483,41],[483,101]]]]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[485,30],[485,43]]]],0],[],["loc",[null,[485,26],[485,46]]]]],[],17,null,["loc",[null,[485,20],[545,25]]]],
        ["inline","customer-saldo",[],["deposits",["subexpr","@mut",[["get","deposits",["loc",[null,[551,46],[551,54]]]]],[],[]],"balanceSum",["subexpr","@mut",[["get","balanceSum",["loc",[null,[551,66],[551,76]]]]],[],[]],"depositSum",["subexpr","@mut",[["get","depositSum",["loc",[null,[551,88],[551,98]]]]],[],[]],"banktransferEnabled",["subexpr","@mut",[["get","banktransferEnabled",["loc",[null,[552,57],[552,76]]]]],[],[]]],["loc",[null,[551,20],[552,78]]]],
        ["attribute","class",["concat",["tabs__content  card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[555,78],[555,87]]]],"medias"],[],["loc",[null,[555,74],[555,97]]]],"is-active",""],[],["loc",[null,[555,53],[555,114]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[556,41],[556,47]]]]],[],[]],"deleteMedia","deleteMedia","editable",true,"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[556,18],[556,158]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[558,64],[558,73]]]],"documents"],[],["loc",[null,[558,60],[558,86]]]],"is-active",""],[],["loc",[null,[558,39],[558,103]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[562,44],[562,54]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[562,20],[562,110]]]],
        ["block","each",[["get","tags",["loc",[null,[564,30],[564,34]]]]],[],18,null,["loc",[null,[564,22],[564,251]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[574,33],[574,47]]]]],[],[]]],["loc",[null,[569,16],[574,49]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[576,66],[576,75]]]],"patients"],[],["loc",[null,[576,62],[576,87]]]],"is-active",""],[],["loc",[null,[576,41],[576,104]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[583,79],[583,90]]]],"all"],[],["loc",[null,[583,75],[583,97]]]],"selected",""],[],["loc",[null,[583,54],[583,114]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[583,116],[583,152]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[584,79],[584,90]]]],"hideDead"],[],["loc",[null,[584,75],[584,102]]]],"selected",""],[],["loc",[null,[584,54],[584,119]]]]]]],
        ["element","action",["toggleQuickFilter","hideDead"],[],["loc",[null,[584,121],[584,162]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[585,79],[585,90]]]],"deleted"],[],["loc",[null,[585,75],[585,101]]]],"selected",""],[],["loc",[null,[585,54],[585,118]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[585,120],[585,160]]]],
        ["block","each",[["get","patientList",["loc",[null,[600,32],[600,43]]]]],[],19,null,["loc",[null,[600,24],[647,33]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[652,64],[652,73]]]],"appointments"],[],["loc",[null,[652,60],[652,89]]]],"is-active",""],[],["loc",[null,[652,39],[652,106]]]]]]],
        ["inline","patient-appointments",[],["startTreatment","startTreatment","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[653,203],[653,215]]]]],[],[]]],["loc",[null,[653,16],[653,217]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[655,66],[655,75]]]],"correspondance"],[],["loc",[null,[655,62],[655,93]]]],"is-active",""],[],["loc",[null,[655,41],[655,110]]]]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[656,50],[656,64]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[656,73],[656,80]]]]],[],[]]],["loc",[null,[656,18],[656,82]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[658,66],[658,75]]]],"consents"],[],["loc",[null,[658,62],[658,87]]]],"is-active",""],[],["loc",[null,[658,41],[658,104]]]]]]],
        ["block","if",[["get","model.consent",["loc",[null,[660,24],[660,37]]]]],[],20,null,["loc",[null,[660,18],[671,25]]]],
        ["inline","customer-consents",[],["actionReceiver",["subexpr","@mut",[["get","customerConsentsComponent",["loc",[null,[673,53],[673,78]]]]],[],[]]],["loc",[null,[673,18],[673,80]]]],
        ["content","button-prev",["loc",[null,[680,10],[680,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[683,16],[683,25]]]],"medias"],[],["loc",[null,[683,12],[683,35]]]]],[],21,null,["loc",[null,[683,6],[687,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[688,16],[688,25]]]],"masterdata"],[],["loc",[null,[688,12],[688,39]]]]],[],22,null,["loc",[null,[688,6],[692,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[693,20],[693,29]]]],"masterdata"],[],["loc",[null,[693,16],[693,43]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[693,48],[693,57]]]],"warning"],[],["loc",[null,[693,44],[693,68]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[693,73],[693,82]]]],"accounting"],[],["loc",[null,[693,69],[693,96]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[693,101],[693,110]]]],"reminder"],[],["loc",[null,[693,97],[693,122]]]]],[],["loc",[null,[693,12],[693,123]]]]],[],23,null,["loc",[null,[693,6],[697,13]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[701,37],[701,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[701,0],[701,76]]]],
        ["inline","customer-payout-deposit-panel",[],["actionReceiver",["subexpr","@mut",[["get","depositPanel",["loc",[null,[704,17],[704,29]]]]],[],[]],"totalDeposit",["subexpr","@mut",[["get","depositSum",["loc",[null,[705,15],[705,25]]]]],[],[]],"payout","payout","actionReceiver",["subexpr","@mut",[["get","customerPayoutDepositPanel",["loc",[null,[707,17],[707,43]]]]],[],[]]],["loc",[null,[703,0],[707,45]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[711,73],[711,98]]]]],[],[]]],["loc",[null,[711,0],[711,100]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[712,40],[712,60]]]]],[],[]]],["loc",[null,[712,0],[712,62]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[713,40],[713,60]]]]],[],[]]],["loc",[null,[713,0],[713,62]]]],
        ["inline","consent-upload-panel",[],["uploadDone","consentUploadDone","actionReceiver",["subexpr","@mut",[["get","consentUploadPanel",["loc",[null,[714,69],[714,87]]]]],[],[]]],["loc",[null,[714,0],[714,89]]]],
        ["inline","add-appointment-panel",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[716,32],[716,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[716,51],[716,61]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[716,72],[716,81]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[716,88],[716,93]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[716,103],[716,111]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[716,118],[716,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[716,166],[716,185]]]]],[],[]]],["loc",[null,[716,0],[716,187]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[718,36],[718,46]]]]],[],[]],"customer",["subexpr","@mut",[["get","model",["loc",[null,[718,56],[718,61]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[718,78],[718,93]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[718,0],[718,123]]]],
        ["block","if",[["subexpr","not",[["get","newMode",["loc",[null,[720,11],[720,18]]]]],[],["loc",[null,[720,6],[720,19]]]]],[],24,null,["loc",[null,[720,0],[722,7]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[724,41],[724,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[724,74],[724,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[724,134],[724,152]]]]],[],[]]],["loc",[null,[724,0],[724,154]]]],
        ["inline","merge-customer-panel",[],["gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","mergeCustomerPanel",["loc",[null,[725,66],[725,84]]]]],[],[]]],["loc",[null,[725,0],[725,86]]]],
        ["inline","contact-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","contactInfoPanel",["loc",[null,[726,36],[726,52]]]]],[],[]]],["loc",[null,[726,0],[726,54]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24]
    };
  }()));

});
define('jason-frontend/templates/components/edit-treatment-infos-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 177
            },
            "end": {
              "line": 70,
              "column": 295
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"id","update_introReasons");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'data-ids');
          morphs[1] = dom.createUnsafeMorphAt(element1,0,0);
          morphs[2] = dom.createMorphAt(element1,2,2);
          return morphs;
        },
        statements: [
          ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patientInfo.info.introductionReasons",["loc",[null,[70,115],[70,151]]]]],[],["loc",[null,[70,96],[70,153]]]]]]],
          ["inline","print-list-of-name",[["get","patientInfo.info.introductionReasons",["loc",[null,[70,177],[70,213]]]]],[],["loc",[null,[70,155],[70,216]]]],
          ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[70,217],[70,288]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 295
            },
            "end": {
              "line": 71,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    auswählen ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[71,30],[71,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 167
            },
            "end": {
              "line": 79,
              "column": 263
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"id","update_adminTasks");
          dom.setAttribute(el1,"class","is-highlighted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'data-ids');
          morphs[1] = dom.createUnsafeMorphAt(element0,0,0);
          morphs[2] = dom.createMorphAt(element0,2,2);
          return morphs;
        },
        statements: [
          ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patientInfo.info.adminTasks",["loc",[null,[79,101],[79,128]]]]],[],["loc",[null,[79,82],[79,130]]]]]]],
          ["inline","print-list-of-name",[["get","patientInfo.info.adminTasks",["loc",[null,[79,154],[79,181]]]]],[],["loc",[null,[79,132],[79,184]]]],
          ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[79,185],[79,256]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 263
            },
            "end": {
              "line": 80,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    auswählen ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[80,30],[80,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 110,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-treatment-infos-panel");
        dom.setAttribute(el1,"class","popup-basic small--heading popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editTreatmentInfosForm");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                Check-In bearbeiten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p-20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mt-0 d-flex align-items-center");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","cursor");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid gutter-fix");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-8/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        dom.setAttribute(el7,"id","update_wishDoctor");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Tierärzt:in");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-8/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        dom.setAttribute(el7,"id","update_wishAssistant");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Assistent:in");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-8/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        dom.setAttribute(el7,"id","update_room");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Behandlungslokation");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-8/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea-wrapper");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Anamnese");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Vorstellungsgründe ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Administrative Tätigkeiten");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"body\").delegate(\"#editTreatmentInfosForm textarea\", \"keyup\", function() {\n      $(this).height(30);\n      $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    });\n\n    $(\"body\").delegate(\"input.readonly-hack\", \"focusin\", function() {\n      $(this).removeAttr('readonly');\n    });\n\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [2]);
        var element6 = dom.childAt(element3, [3]);
        var element7 = dom.childAt(element6, [7, 1]);
        var element8 = dom.childAt(element6, [9]);
        var element9 = dom.childAt(element8, [3, 1]);
        var element10 = dom.childAt(element6, [11, 3, 1]);
        var element11 = dom.childAt(element2, [5, 1]);
        var morphs = new Array(16);
        morphs[0] = dom.createMorphAt(element4,0,0);
        morphs[1] = dom.createElementMorph(element5);
        morphs[2] = dom.createMorphAt(element5,0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element6, [1, 1]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [3, 1]),3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [5, 1]),3,3);
        morphs[6] = dom.createAttrMorph(element7, 'id');
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
        morphs[9] = dom.createElementMorph(element9);
        morphs[10] = dom.createMorphAt(element9,0,0);
        morphs[11] = dom.createElementMorph(element10);
        morphs[12] = dom.createMorphAt(element10,0,0);
        morphs[13] = dom.createElementMorph(element11);
        morphs[14] = dom.createMorphAt(element11,1,1);
        morphs[15] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","animal-icon",[],["color","#999999","animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[10,91],[10,122]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[10,131],[10,167]]]]],[],[]],"size","32","classNames","me-10","content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[10,205],[10,238]]]]],[],[]]],["loc",[null,[10,51],[10,240]]]],
        ["element","action",["gotoPatient",["get","patientInfo.patient.id",["loc",[null,[10,282],[10,304]]]]],[],["loc",[null,[10,259],[10,306]]]],
        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[10,326],[10,350]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[10,359],[10,381]]]]],[],[]]],["loc",[null,[10,307],[10,383]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[17,28],[17,35]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.doctor",["loc",[null,[18,26],[18,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","wish-doctor","placeholder","Bitte wählen"],["loc",[null,[16,18],[24,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[31,28],[31,38]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.assistant",["loc",[null,[32,26],[32,47]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","wishAssistant","placeholder","Bitte wählen"],["loc",[null,[30,18],[38,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[45,28],[45,33]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.room",["loc",[null,[46,26],[46,42]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","treatment-room","placeholder","Bitte wählen"],["loc",[null,[44,18],[52,20]]]],
        ["attribute","id",["concat",[["get","patientInfo.patient.id",["loc",[null,[57,53],[57,75]]]],"_anamnese"]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","patientInfo.info.medicalHistory",["loc",[null,[59,46],[59,77]]]]],[],[]],"class","","name","anamnese","placeholder",""],["loc",[null,[59,20],[60,47]]]],
        ["inline","button-etiga-small",[],["content","","classNames","pb2 vertical-middle inline-block","size","18","width",34],["loc",[null,[67,55],[67,153]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","introductionReasons",["get","patientInfo.info.introductionReasons",["loc",[null,[69,100],[69,136]]]],"Vorstellungsgründe",["get","patientInfo.info",["loc",[null,[69,158],[69,174]]]]],[],["loc",[null,[69,36],[69,176]]]],
        ["block","if",[["get","patientInfo.info.introductionReasons",["loc",[null,[69,183],[69,219]]]]],[],0,1,["loc",[null,[69,177],[71,108]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","patientInfo.info.adminTasks",["loc",[null,[78,91],[78,118]]]],"Administrative Tätigkeiten",["get","patientInfo.info",["loc",[null,[78,148],[78,164]]]]],[],["loc",[null,[78,36],[78,166]]]],
        ["block","if",[["get","patientInfo.info.adminTasks",["loc",[null,[78,173],[78,200]]]]],[],2,3,["loc",[null,[78,167],[80,108]]]],
        ["element","action",["save"],[],["loc",[null,[86,11],[86,28]]]],
        ["inline","button-save",[],["size","40","color","#ffffff","content","Speichern"],["loc",[null,[87,10],[87,71]]]],
        ["inline","treatment-select-panel",[],["prefix","update","confirm","updateSelectsUpdatePanel","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanelUpdate",["loc",[null,[109,91],[109,117]]]]],[],[]]],["loc",[null,[109,0],[109,119]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
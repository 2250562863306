define('jason-frontend/templates/components/documents-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 7,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/go-back.svg");
            dom.setAttribute(el2,"class","mr10");
            dom.setAttribute(el2,"style","width: 20px");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element39 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element39);
            return morphs;
          },
          statements: [
            ["element","action",["gotoFolder",["get","upFolder",["loc",[null,[6,50],[6,58]]]]],[],["loc",[null,[6,28],[6,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 12
              },
              "end": {
                "line": 16,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","crumb-trail folder");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","is-highlighted cursor");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element38 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element38);
            morphs[1] = dom.createMorphAt(element38,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["gotoFolderNonModel",["get","bread",["loc",[null,[14,79],[14,84]]]]],[],["loc",[null,[14,49],[14,86]]]],
            ["content","bread.name",["loc",[null,[14,87],[14,101]]]]
          ],
          locals: ["bread"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 19,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","breadcrumbs mb-10");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","nav");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/go-forward.svg");
          dom.setAttribute(el3,"class","mr10");
          dom.setAttribute(el3,"style","width: 20px");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/folder.svg");
          dom.setAttribute(el3,"class","mr10");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element40 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element40, [1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element40, [3]),3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["get","upFolder",["loc",[null,[5,14],[5,22]]]]],[],0,null,["loc",[null,[5,8],[7,15]]]],
          ["block","each",[["get","breads",["loc",[null,[12,20],[12,26]]]]],[],1,null,["loc",[null,[12,12],[16,21]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 16
            },
            "end": {
              "line": 26,
              "column": 184
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell table__cell--center l-4/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createTextNode("Erstellt");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element37 = dom.childAt(fragment, [1, 0, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element37);
          return morphs;
        },
        statements: [
          ["element","action",["setSort","created"],[],["loc",[null,[26,98],[26,128]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 28,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-4/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Benutzer:in");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 10
            },
            "end": {
              "line": 39,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","is-highlighted cursor");
          var el5 = dom.createTextNode("Suche zurücksetzen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element33 = dom.childAt(fragment, [1, 1]);
          var element34 = dom.childAt(element33, [1]);
          var element35 = dom.childAt(element34, [0]);
          var element36 = dom.childAt(element34, [2]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element33, 'class');
          morphs[1] = dom.createElementMorph(element35);
          morphs[2] = dom.createMorphAt(element35,0,0);
          morphs[3] = dom.createElementMorph(element36);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__cell l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[35,90],[35,99]]]],"15","12"],[],["loc",[null,[35,69],[35,111]]]],"/24@desk"]]],
          ["element","action",["resetSearch"],[],["loc",[null,[36,42],[36,66]]]],
          ["inline","button-prev",[],["color","000","classNames","vertical-middle inline-block"],["loc",[null,[36,67],[36,136]]]],
          ["element","action",["resetSearch"],[],["loc",[null,[36,174],[36,198]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 20
                  },
                  "end": {
                    "line": 47,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element27 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element27);
                morphs[1] = dom.createMorphAt(element27,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["gotoFolder",["get","document",["loc",[null,[46,95],[46,103]]]]],[],["loc",[null,[46,73],[46,105]]]],
                ["inline","button-prev",[],["color","000","classNames","vertical-middle inline-block"],["loc",[null,[46,106],[46,175]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 98
                    },
                    "end": {
                      "line": 47,
                      "column": 203
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","badge badge-highlighted-alternative");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","document.fileCount",["loc",[null,[47,174],[47,196]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 18
                  },
                  "end": {
                    "line": 48,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/folder.svg");
                dom.setAttribute(el1,"class","mr10 vertical-bottom");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["block","if",[["get","document.fileCount",["loc",[null,[47,104],[47,122]]]]],[],0,null,["loc",[null,[47,98],[47,210]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 16
                },
                "end": {
                  "line": 49,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted cursor");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element28 = dom.childAt(fragment, [1]);
              var element29 = dom.childAt(element28, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element28,1,1);
              morphs[1] = dom.createElementMorph(element29);
              morphs[2] = dom.createMorphAt(element29,0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","document.name",["loc",[null,[46,30],[46,43]]]],"zurück"],[],["loc",[null,[46,26],[46,53]]]]],[],0,1,["loc",[null,[46,20],[48,25]]]],
              ["element","action",["gotoFolder",["get","document",["loc",[null,[48,81],[48,89]]]]],[],["loc",[null,[48,59],[48,91]]]],
              ["content","document.name",["loc",[null,[48,92],[48,109]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 16
                  },
                  "end": {
                    "line": 51,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/some-files.svg");
                dom.setAttribute(el2,"style","width: 24px");
                dom.setAttribute(el2,"class","vertical-bottom mr10");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","is-highlighted cursor");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element26 = dom.childAt(fragment, [1, 2]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element26);
                morphs[1] = dom.createMorphAt(element26,0,0);
                return morphs;
              },
              statements: [
                ["element","action",["gotoFolder",["get","document",["loc",[null,[50,183],[50,191]]]]],[],["loc",[null,[50,161],[50,193]]]],
                ["content","document.name",["loc",[null,[50,194],[50,211]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 16
                    },
                    "end": {
                      "line": 53,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/some-files.svg");
                  dom.setAttribute(el2,"style","width: 24px");
                  dom.setAttribute(el2,"class","vertical-bottom mr10");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","is-highlighted cursor");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element25 = dom.childAt(fragment, [1, 2]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element25);
                  morphs[1] = dom.createMorphAt(element25,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openContainerPreview",["get","document",["loc",[null,[52,186],[52,194]]]]],[],["loc",[null,[52,154],[52,196]]]],
                  ["content","document.name",["loc",[null,[52,197],[52,214]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 16
                    },
                    "end": {
                      "line": 55,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","media-item",[],["withLinks",true,"smallView",["subexpr","@mut",[["get","smallView",["loc",[null,[54,56],[54,65]]]]],[],[]],"downloadVetconnect","downloadVetconnect","preview","preview","downloadLaboklinRequest","downloadLaboklinRequest","downloadLabResultIntern","downloadLabResultIntern","downloadDeliveryNote","downloadDeliveryNote","downloadOrder","downloadOrder","downloadInvoice","downloadInvoice","download","downloadFile","document",["subexpr","@mut",[["get","document",["loc",[null,[54,365],[54,373]]]]],[],[]]],["loc",[null,[54,18],[54,375]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 16
                  },
                  "end": {
                    "line": 55,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","document.containerObject",["loc",[null,[51,26],[51,50]]]]],[],0,1,["loc",[null,[51,16],[55,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 16
                },
                "end": {
                  "line": 55,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[49,30],[49,43]]]],"all"],[],["loc",[null,[49,26],[49,50]]]]],[],0,1,["loc",[null,[49,16],[55,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 14
                },
                "end": {
                  "line": 58,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","document-description");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","document.description",["loc",[null,[57,51],[57,75]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 77
                  },
                  "end": {
                    "line": 61,
                    "column": 228
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","cursor info-badge info-badge-very-small mr5");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element23 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element23, 'style');
                morphs[1] = dom.createMorphAt(element23,0,0);
                return morphs;
              },
              statements: [
                ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[61,195],[61,204]]]]]]],
                ["content","tag.name",["loc",[null,[61,208],[61,220]]]]
              ],
              locals: ["tag"],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 238
                  },
                  "end": {
                    "line": 61,
                    "column": 359
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Tag hinzufügen ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","icon-plus",[],["size",24,"color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[61,280],[61,359]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 359
                  },
                  "end": {
                    "line": 61,
                    "column": 450
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","icon-plus",[],["size",24,"color","#999999","classNames","inline-block vertical-middle pt3"],["loc",[null,[61,367],[61,450]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 18
                },
                "end": {
                  "line": 63,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","document-description");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","cursor");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element24 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element24);
              morphs[1] = dom.createMorphAt(element24,0,0);
              morphs[2] = dom.createMorphAt(element24,2,2);
              return morphs;
            },
            statements: [
              ["element","action",["openTagPanel",["get","document",["loc",[null,[61,66],[61,74]]]]],[],["loc",[null,[61,42],[61,76]]]],
              ["block","each",[["get","document.tags",["loc",[null,[61,85],[61,98]]]]],[],0,null,["loc",[null,[61,77],[61,237]]]],
              ["block","if",[["subexpr","not",[["get","document.tags",["loc",[null,[61,249],[61,262]]]]],[],["loc",[null,[61,244],[61,263]]]]],[],1,2,["loc",[null,[61,238],[61,457]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 20
                },
                "end": {
                  "line": 66,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell table__cell--center l-12/24@phone l-12/24@tablet l-4/24@desk");
              dom.setAttribute(el1,"data-label","Erstellt");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","document.created",["loc",[null,[65,34],[65,50]]]],"DD.MM.YYYY HH:mm:ss"],[],["loc",[null,[65,20],[65,74]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 20
                  },
                  "end": {
                    "line": 69,
                    "column": 58
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("System");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 58
                    },
                    "end": {
                      "line": 69,
                      "column": 104
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("App-BenutzerIn");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 69,
                        "column": 112
                      },
                      "end": {
                        "line": 69,
                        "column": 236
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","document.user.firstname",["loc",[null,[69,182],[69,209]]]],
                    ["content","document.user.lastname",["loc",[null,[69,210],[69,236]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 104
                    },
                    "end": {
                      "line": 69,
                      "column": 248
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["users.edit",["get","document.user.id",["loc",[null,[69,136],[69,152]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[69,112],[69,248]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 58
                  },
                  "end": {
                    "line": 69,
                    "column": 248
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","document.appEditable",["loc",[null,[69,68],[69,88]]]]],[],0,1,["loc",[null,[69,58],[69,248]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 14
                },
                "end": {
                  "line": 70,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell l-12/24@phone l-12/24@tablet l-4/24@desk");
              dom.setAttribute(el1,"data-label","BenutzerIn");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","document.containerObject",["loc",[null,[69,26],[69,50]]]]],[],0,1,["loc",[null,[69,20],[69,255]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 74,
                      "column": 16
                    },
                    "end": {
                      "line": 78,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element19 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element19);
                  return morphs;
                },
                statements: [
                  ["element","action",["editDocument",["get","document.internalId",["loc",[null,[75,45],[75,64]]]]],[],["loc",[null,[75,21],[75,66]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 96,
                        "column": 18
                      },
                      "end": {
                        "line": 100,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--eltiga list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element18 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element18);
                    morphs[1] = dom.createMorphAt(element18,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["share",["get","document.internalId",["loc",[null,[97,40],[97,59]]]],false],[],["loc",[null,[97,23],[97,67]]]],
                    ["inline","button-etiga-small",[],["color","white","content","eltiga Freigabe beenden","size",24,"classNames","active"],["loc",[null,[98,22],[98,120]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 100,
                        "column": 18
                      },
                      "end": {
                        "line": 104,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element17 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element17);
                    morphs[1] = dom.createMorphAt(element17,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["share",["get","document.internalId",["loc",[null,[101,40],[101,59]]]],true],[],["loc",[null,[101,23],[101,66]]]],
                    ["inline","button-etiga-small",[],["content","Für eltiga freigeben","color","white","size",24],["loc",[null,[102,22],[102,97]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 95,
                      "column": 16
                    },
                    "end": {
                      "line": 105,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","document.shared",["loc",[null,[96,24],[96,39]]]]],[],0,1,["loc",[null,[96,18],[104,25]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 14
                  },
                  "end": {
                    "line": 106,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/share.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","sep");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/dropdown-arrow.svg");
                dom.setAttribute(el3,"class","dropdown-arrow");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                        Per Email verschicken\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                        Herunterladen\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element20 = dom.childAt(fragment, [2, 3]);
                var element21 = dom.childAt(element20, [1]);
                var element22 = dom.childAt(element20, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createElementMorph(element21);
                morphs[2] = dom.createElementMorph(element22);
                morphs[3] = dom.createMorphAt(fragment,4,4,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["subexpr","not",[["get","document.appEditable",["loc",[null,[74,32],[74,52]]]]],[],["loc",[null,[74,27],[74,53]]]],["subexpr","not",[["get","smallView",["loc",[null,[74,59],[74,68]]]]],[],["loc",[null,[74,54],[74,69]]]]],[],["loc",[null,[74,22],[74,70]]]]],[],0,null,["loc",[null,[74,16],[78,23]]]],
                ["element","action",["openDocumentForwardPanel",["get","document.internalId",["loc",[null,[87,61],[87,80]]]]],[],["loc",[null,[87,25],[87,82]]]],
                ["element","action",["downloadFile",["get","document.internalId",["loc",[null,[90,49],[90,68]]]]],[],["loc",[null,[90,25],[90,70]]]],
                ["block","if",[["subexpr","and",[["get","document.etiga",["loc",[null,[95,27],[95,41]]]],["subexpr","not",[["get","document.appEditable",["loc",[null,[95,47],[95,67]]]]],[],["loc",[null,[95,42],[95,68]]]]],[],["loc",[null,[95,22],[95,69]]]]],[],1,null,["loc",[null,[95,16],[105,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 107,
                    "column": 14
                  },
                  "end": {
                    "line": 111,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element16);
                return morphs;
              },
              statements: [
                ["element","action",["downloadLaboklinRequest",["get","document.internalId",["loc",[null,[108,54],[108,73]]]]],[],["loc",[null,[108,19],[108,75]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 14
                  },
                  "end": {
                    "line": 128,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/share.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","sep");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/dropdown-arrow.svg");
                dom.setAttribute(el3,"class","dropdown-arrow");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Per Email verschicken\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Herunterladen\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1, 3]);
                var element14 = dom.childAt(element13, [1]);
                var element15 = dom.childAt(element13, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element14);
                morphs[1] = dom.createElementMorph(element15);
                return morphs;
              },
              statements: [
                ["element","action",["forwardLab",["get","document.internalId",["loc",[null,[120,45],[120,64]]]]],[],["loc",[null,[120,23],[120,66]]]],
                ["element","action",["downloadVetconnect",["get","document.internalId",["loc",[null,[123,53],[123,72]]]]],[],["loc",[null,[123,23],[123,74]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 129,
                    "column": 14
                  },
                  "end": {
                    "line": 145,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/share.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","sep");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/dropdown-arrow.svg");
                dom.setAttribute(el3,"class","dropdown-arrow");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Per Email verschicken\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Herunterladen\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1, 3]);
                var element11 = dom.childAt(element10, [1]);
                var element12 = dom.childAt(element10, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element11);
                morphs[1] = dom.createElementMorph(element12);
                return morphs;
              },
              statements: [
                ["element","action",["forwardLab",["get","document.internalId",["loc",[null,[137,45],[137,64]]]]],[],["loc",[null,[137,23],[137,66]]]],
                ["element","action",["downloadLabResultIntern",["get","document.internalId",["loc",[null,[140,58],[140,77]]]]],[],["loc",[null,[140,23],[140,79]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 146,
                    "column": 14
                  },
                  "end": {
                    "line": 162,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/share.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","sep");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/dropdown-arrow.svg");
                dom.setAttribute(el3,"class","dropdown-arrow");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Per Email verschicken\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Herunterladen\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1, 3]);
                var element8 = dom.childAt(element7, [1]);
                var element9 = dom.childAt(element7, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element8);
                morphs[1] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [
                ["element","action",["forwardLab",["get","document.internalId",["loc",[null,[154,45],[154,64]]]]],[],["loc",[null,[154,23],[154,66]]]],
                ["element","action",["downloadLabResultLaboklin",["get","document.internalId",["loc",[null,[157,60],[157,79]]]]],[],["loc",[null,[157,23],[157,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 164,
                      "column": 16
                    },
                    "end": {
                      "line": 168,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default  list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element6);
                  morphs[1] = dom.createMorphAt(element6,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["showLabForApp",["get","document.internalId",["loc",[null,[165,46],[165,65]]]]],[],["loc",[null,[165,21],[165,67]]]],
                  ["inline","button-unhide-app",[],["size",24,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[166,20],[166,135]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 168,
                      "column": 16
                    },
                    "end": {
                      "line": 172,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--eltiga list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element5);
                  morphs[1] = dom.createMorphAt(element5,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["hideLabForApp",["get","document.internalId",["loc",[null,[169,46],[169,65]]]]],[],["loc",[null,[169,21],[169,67]]]],
                  ["inline","button-unhide-app",[],["size",24,"showStroke",false,"innerColor","#fff","color","#fff","content","in eltiga App sichtbar"],["loc",[null,[170,20],[170,130]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 163,
                    "column": 14
                  },
                  "end": {
                    "line": 173,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","document.hideApp",["loc",[null,[164,22],[164,38]]]]],[],0,1,["loc",[null,[164,16],[172,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 174,
                    "column": 14
                  },
                  "end": {
                    "line": 178,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element4);
                return morphs;
              },
              statements: [
                ["element","action",["downloadOrder",["get","document.internalId",["loc",[null,[175,44],[175,63]]]]],[],["loc",[null,[175,19],[175,65]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 179,
                    "column": 14
                  },
                  "end": {
                    "line": 183,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element3);
                return morphs;
              },
              statements: [
                ["element","action",["downloadDeliveryNote",["get","document.internalId",["loc",[null,[180,51],[180,70]]]]],[],["loc",[null,[180,19],[180,72]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 184,
                    "column": 14
                  },
                  "end": {
                    "line": 188,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [
                ["element","action",["downloadInvoice",["get","document.internalId",["loc",[null,[185,46],[185,65]]]]],[],["loc",[null,[185,19],[185,67]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 12
                },
                "end": {
                  "line": 189,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(9);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,6,6,contextualElement);
              morphs[7] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[8] = dom.createMorphAt(fragment,8,8,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[73,24],[73,37]]]],"file"],[],["loc",[null,[73,20],[73,45]]]]],[],0,null,["loc",[null,[73,14],[106,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[107,24],[107,37]]]],"labLaboklin"],[],["loc",[null,[107,20],[107,52]]]]],[],1,null,["loc",[null,[107,14],[111,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[112,24],[112,37]]]],"vetconnect"],[],["loc",[null,[112,20],[112,51]]]]],[],2,null,["loc",[null,[112,14],[128,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[129,24],[129,37]]]],"labResultIntern"],[],["loc",[null,[129,20],[129,56]]]]],[],3,null,["loc",[null,[129,14],[145,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[146,24],[146,37]]]],"labResultLaboklin"],[],["loc",[null,[146,20],[146,58]]]]],[],4,null,["loc",[null,[146,14],[162,21]]]],
              ["block","if",[["subexpr","or",[["subexpr","eq",[["get","document.type",["loc",[null,[163,28],[163,41]]]],"vetconnect"],[],["loc",[null,[163,24],[163,55]]]],["subexpr","eq",[["get","document.type",["loc",[null,[163,60],[163,73]]]],"labResultIntern"],[],["loc",[null,[163,56],[163,92]]]],["subexpr","eq",[["get","document.type",["loc",[null,[163,97],[163,110]]]],"labResultLaboklin"],[],["loc",[null,[163,93],[163,131]]]]],[],["loc",[null,[163,20],[163,132]]]]],[],5,null,["loc",[null,[163,14],[173,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[174,24],[174,37]]]],"order"],[],["loc",[null,[174,20],[174,46]]]]],[],6,null,["loc",[null,[174,14],[178,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[179,24],[179,37]]]],"deliverynote"],[],["loc",[null,[179,20],[179,53]]]]],[],7,null,["loc",[null,[179,14],[183,21]]]],
              ["block","if",[["subexpr","eq",[["get","document.type",["loc",[null,[184,24],[184,37]]]],"invoice"],[],["loc",[null,[184,20],[184,48]]]]],[],8,null,["loc",[null,[184,14],[188,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 12
              },
              "end": {
                "line": 192,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [1]);
            var element31 = dom.childAt(element30, [1]);
            var element32 = dom.childAt(element30, [6]);
            var morphs = new Array(8);
            morphs[0] = dom.createAttrMorph(element31, 'class');
            morphs[1] = dom.createMorphAt(element31,1,1);
            morphs[2] = dom.createMorphAt(element31,2,2);
            morphs[3] = dom.createMorphAt(element31,3,3);
            morphs[4] = dom.createMorphAt(element30,2,2);
            morphs[5] = dom.createMorphAt(element30,4,4);
            morphs[6] = dom.createAttrMorph(element32, 'class');
            morphs[7] = dom.createMorphAt(element32,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["table__cell l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[43,90],[43,99]]]],"15","11"],[],["loc",[null,[43,69],[43,111]]]],"/24@desk"]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","document.type",["loc",[null,[44,30],[44,43]]]],"up"],[],["loc",[null,[44,26],[44,49]]]],["subexpr","eq",[["get","document.type",["loc",[null,[44,54],[44,67]]]],"folder"],[],["loc",[null,[44,50],[44,77]]]]],[],["loc",[null,[44,22],[44,78]]]]],[],0,1,["loc",[null,[44,16],[55,23]]]],
            ["block","if",[["subexpr","and",[["get","document.description",["loc",[null,[56,25],[56,45]]]],["subexpr","not",[["get","smallView",["loc",[null,[56,51],[56,60]]]]],[],["loc",[null,[56,46],[56,61]]]]],[],["loc",[null,[56,20],[56,62]]]]],[],2,null,["loc",[null,[56,14],[58,21]]]],
            ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","or",[["subexpr","eq",[["get","document.type",["loc",[null,[59,42],[59,55]]]],"all"],[],["loc",[null,[59,38],[59,62]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,67],[59,80]]]],"up"],[],["loc",[null,[59,63],[59,86]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,91],[59,104]]]],"vetconnect"],[],["loc",[null,[59,87],[59,118]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,123],[59,136]]]],"labResultIntern"],[],["loc",[null,[59,119],[59,155]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,160],[59,173]]]],"labIntern"],[],["loc",[null,[59,156],[59,186]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,191],[59,204]]]],"labResultLaboklin"],[],["loc",[null,[59,187],[59,225]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,231],[59,244]]]],"labLaboklin"],[],["loc",[null,[59,227],[59,259]]]],["subexpr","eq",[["get","document.type",["loc",[null,[59,264],[59,277]]]],"folder"],[],["loc",[null,[59,260],[59,287]]]]],[],["loc",[null,[59,34],[59,288]]]]],[],["loc",[null,[59,29],[59,289]]]],["subexpr","not",[["get","smallView",["loc",[null,[59,295],[59,304]]]]],[],["loc",[null,[59,290],[59,305]]]]],[],["loc",[null,[59,24],[59,306]]]]],[],3,null,["loc",[null,[59,18],[63,25]]]],
            ["block","if",[["subexpr","not",[["get","smallView",["loc",[null,[64,31],[64,40]]]]],[],["loc",[null,[64,26],[64,41]]]]],[],4,null,["loc",[null,[64,20],[66,25]]]],
            ["block","if",[["subexpr","not",[["get","smallView",["loc",[null,[67,25],[67,34]]]]],[],["loc",[null,[67,20],[67,35]]]]],[],5,null,["loc",[null,[67,14],[70,25]]]],
            ["attribute","class",["concat",["table__cell table__cell--action l-6/24@phone l-6/24@tablet l-",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[71,108],[71,117]]]],"9","5"],[],["loc",[null,[71,87],[71,127]]]],"/24@desk buttons-3 ",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[71,167],[71,176]]]],"pe-5",""],[],["loc",[null,[71,146],[71,188]]]]]]],
            ["block","if",[["get","showActionButtons",["loc",[null,[72,18],[72,35]]]]],[],6,null,["loc",[null,[72,12],[189,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 10
            },
            "end": {
              "line": 193,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","unless",[["subexpr","and",[["get","showBreadcrumbs",["loc",[null,[41,27],[41,42]]]],["subexpr","eq",[["get","document.internalType",["loc",[null,[41,47],[41,68]]]],"up"],[],["loc",[null,[41,43],[41,74]]]]],[],["loc",[null,[41,22],[41,75]]]]],[],0,null,["loc",[null,[41,12],[192,23]]]]
        ],
        locals: ["document"],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 194,
              "column": 10
            },
            "end": {
              "line": 198,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Keine Einträge gefunden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 201,
              "column": 6
            },
            "end": {
              "line": 205,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[202,39],[202,48]]]],"","pagination-wrapper is-fixed"],[],["loc",[null,[202,18],[202,83]]]]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","model",["loc",[null,[203,31],[203,36]]]]],[],[]],"classNames",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[203,68],[203,77]]]],"small",""],[],["loc",[null,[203,48],[203,89]]]],"numPagesToShow",7],["loc",[null,[203,8],[203,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 207,
              "column": 4
            },
            "end": {
              "line": 211,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[208,39],[208,48]]]],"","pagination-wrapper is-fixed"],[],["loc",[null,[208,18],[208,83]]]]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","model",["loc",[null,[209,31],[209,36]]]]],[],[]],"classNames",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[209,68],[209,77]]]],"small",""],[],["loc",[null,[209,48],[209,89]]]],"numPagesToShow",7],["loc",[null,[209,8],[209,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 214,
              "column": 0
            },
            "end": {
              "line": 218,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-upload-panel",[],["uploadDone","reloadDocumentsPanel","actionReceiver",["subexpr","@mut",[["get","documentUploadPanel",["loc",[null,[215,75],[215,94]]]]],[],[]]],["loc",[null,[215,2],[215,96]]]],
          ["inline","document-edit-panel",[],["prefix",["subexpr","@mut",[["get","prefix",["loc",[null,[216,31],[216,37]]]]],[],[]],"save","saveDocument","delete","delete","reload","editDocument","refreshView","applyFilter","actionReceiver",["subexpr","@mut",[["get","documentEditPanel",["loc",[null,[216,137],[216,154]]]]],[],[]]],["loc",[null,[216,2],[216,156]]]],
          ["inline","document-tag-panel",[],["prefix",["subexpr","@mut",[["get","prefix",["loc",[null,[217,30],[217,36]]]]],[],[]],"saveTags","saveTags","actionReceiver",["subexpr","@mut",[["get","documentTagPanel",["loc",[null,[217,72],[217,88]]]]],[],[]]],["loc",[null,[217,2],[217,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 223,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/documents-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("form");
        dom.setAttribute(el1,"class","documentsForm u-mb0");
        dom.setAttribute(el1,"method","post");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n           ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"class","cursor is-highlighted");
        var el8 = dom.createTextNode("Name");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element41 = dom.childAt(fragment, [1]);
        var element42 = dom.childAt(element41, [3]);
        var element43 = dom.childAt(element42, [1]);
        var element44 = dom.childAt(element43, [1]);
        var element45 = dom.childAt(element44, [1]);
        var element46 = dom.childAt(element45, [1, 0]);
        var element47 = dom.childAt(element44, [6]);
        var element48 = dom.childAt(element43, [3]);
        var morphs = new Array(18);
        morphs[0] = dom.createElementMorph(element41);
        morphs[1] = dom.createMorphAt(element41,1,1);
        morphs[2] = dom.createAttrMorph(element42, 'class');
        morphs[3] = dom.createAttrMorph(element43, 'class');
        morphs[4] = dom.createAttrMorph(element45, 'class');
        morphs[5] = dom.createElementMorph(element46);
        morphs[6] = dom.createMorphAt(element44,2,2);
        morphs[7] = dom.createMorphAt(element44,4,4);
        morphs[8] = dom.createAttrMorph(element47, 'class');
        morphs[9] = dom.createMorphAt(element48,1,1);
        morphs[10] = dom.createMorphAt(element48,2,2);
        morphs[11] = dom.createMorphAt(element48,3,3);
        morphs[12] = dom.createMorphAt(element42,3,3);
        morphs[13] = dom.createMorphAt(element41,5,5);
        morphs[14] = dom.createMorphAt(fragment,3,3,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,9,9,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["applyFilter"],["on","submit"],["loc",[null,[1,50],[1,86]]]],
        ["block","if",[["get","showBreadcrumbs",["loc",[null,[2,10],[2,25]]]]],[],0,null,["loc",[null,[2,4],[19,11]]]],
        ["attribute","class",["concat",["table-wrapper-rework ",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[20,58],[20,67]]]],"small",""],[],["loc",[null,[20,37],[20,80]]]]," mb0  with-default-empty-line"]]],
        ["attribute","class",["concat",["table grid table--large ",["subexpr","css-bool-evaluator",[["get","useHover",["loc",[null,[21,63],[21,71]]]],"","no-hover"],[],["loc",[null,[21,42],[21,87]]]]]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[23,57],[23,66]]]],"15","11"],[],["loc",[null,[23,36],[23,78]]]],"/24"]]],
        ["element","action",["setSort","name"],[],["loc",[null,[24,47],[24,74]]]],
        ["block","if",[["subexpr","not",[["get","smallView",["loc",[null,[25,27],[25,36]]]]],[],["loc",[null,[25,22],[25,37]]]]],[],1,null,["loc",[null,[25,16],[26,191]]]],
        ["block","if",[["subexpr","not",[["get","smallView",["loc",[null,[27,21],[27,30]]]]],[],["loc",[null,[27,16],[27,31]]]]],[],2,null,["loc",[null,[27,10],[28,108]]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","smallView",["loc",[null,[29,57],[29,66]]]],"8","4"],[],["loc",[null,[29,36],[29,76]]]],"/24"]]],
        ["block","if",[["get","filterName",["loc",[null,[33,16],[33,26]]]]],[],3,null,["loc",[null,[33,10],[39,17]]]],
        ["block","each",[["get","model",["loc",[null,[40,18],[40,23]]]]],[],4,null,["loc",[null,[40,10],[193,19]]]],
        ["block","if",[["subexpr","list-empty",[["get","model",["loc",[null,[194,28],[194,33]]]]],[],["loc",[null,[194,16],[194,34]]]]],[],5,null,["loc",[null,[194,10],[198,17]]]],
        ["block","unless",[["get","smallView",["loc",[null,[201,16],[201,25]]]]],[],6,null,["loc",[null,[201,6],[205,17]]]],
        ["block","if",[["get","smallView",["loc",[null,[207,10],[207,19]]]]],[],7,null,["loc",[null,[207,4],[211,11]]]],
        ["block","if",[["get","loadPanels",["loc",[null,[214,6],[214,16]]]]],[],8,null,["loc",[null,[214,0],[218,7]]]],
        ["inline","document-forward-panel",[],["prefix",["subexpr","@mut",[["get","prefix",["loc",[null,[220,32],[220,38]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[220,54],[220,74]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[220,0],[220,103]]]],
        ["inline","document-preview-panel",[],["prefix",["subexpr","@mut",[["get","prefix",["loc",[null,[221,32],[221,38]]]]],[],[]],"download","downloadFile","actionReceiver",["subexpr","@mut",[["get","documentPreviewPanel",["loc",[null,[221,78],[221,98]]]]],[],[]]],["loc",[null,[221,0],[221,100]]]],
        ["inline","document-archive-preview-panel",[],["prefix",["subexpr","@mut",[["get","prefix",["loc",[null,[222,40],[222,46]]]]],[],[]],"download","downloadFileContainer","actionReceiver",["subexpr","@mut",[["get","documentArchivePreviewPanel",["loc",[null,[222,95],[222,122]]]]],[],[]]],["loc",[null,[222,0],[222,124]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  }()));

});
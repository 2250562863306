define('jason-frontend/templates/components/copy-treatment-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 26,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","d-flex flex-column");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("Auf andere Tiere im Stall");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","small");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(element7, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createElementMorph(element7);
            morphs[2] = dom.createAttrMorph(element8, 'src');
            morphs[3] = dom.createMorphAt(dom.childAt(element7, [3, 3]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["d-flex align-items-center option-to-select cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedMode",["loc",[null,[17,97],[17,109]]]],"stable"],[],["loc",[null,[17,93],[17,119]]]],"selected",""],[],["loc",[null,[17,72],[18,108]]]]]]],
            ["element","action",["selectMode","stable"],[],["loc",[null,[18,110],[19,35]]]],
            ["attribute","src",["concat",["assets/images/icons/stable",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedMode",["loc",[null,[20,73],[20,85]]]],"stable"],[],["loc",[null,[20,69],[20,95]]]],"-white",""],[],["loc",[null,[20,48],[20,109]]]],".svg"]]],
            ["content","copyData.stable.name",["loc",[null,[23,34],[23,58]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 28,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body p25");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","d-flex flex-column");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Auf andere Tiere der Tierbesitzer:in");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","small");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [1]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element10, [3, 3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element10, 'class');
          morphs[1] = dom.createElementMorph(element10);
          morphs[2] = dom.createAttrMorph(element11, 'src');
          morphs[3] = dom.createMorphAt(element12,0,0);
          morphs[4] = dom.createMorphAt(element12,2,2);
          morphs[5] = dom.createMorphAt(element9,3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["d-flex align-items-center option-to-select cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedMode",["loc",[null,[7,95],[7,107]]]],"owner"],[],["loc",[null,[7,91],[7,116]]]],"selected",""],[],["loc",[null,[7,70],[8,106]]]]]]],
          ["element","action",["selectMode","owner"],[],["loc",[null,[8,108],[9,32]]]],
          ["attribute","src",["concat",["assets/images/icons/petowner",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedMode",["loc",[null,[10,73],[10,85]]]],"owner"],[],["loc",[null,[10,69],[10,94]]]],"-white",""],[],["loc",[null,[10,48],[10,108]]]],".svg"]]],
          ["content","copyData.owner.firstname",["loc",[null,[13,32],[13,60]]]],
          ["content","copyData.owner.lastname",["loc",[null,[13,61],[13,88]]]],
          ["block","if",[["get","copyData.stable",["loc",[null,[16,14],[16,29]]]]],[],0,null,["loc",[null,[16,8],[26,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 45,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","d-flex flex-column");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","small");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createMorphAt(element4,1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["d-flex align-items-center option-to-select cursor ",["subexpr","css-bool-evaluator",[["get","patient.selected",["loc",[null,[33,90],[33,106]]]],"selected",""],[],["loc",[null,[33,69],[34,94]]]]]]],
            ["element","action",["selectAnimal",["get","patient",["loc",[null,[35,27],[35,34]]]]],[],["loc",[null,[34,96],[35,36]]]],
            ["inline","animal-icon",[],["maxWidth","max-w-70px","eltigaActive",["subexpr","and",[["subexpr","is-etiga-active",[],[],["loc",[null,[37,66],[37,83]]]],["get","patient.category.etigaActive",["loc",[null,[37,84],[37,112]]]]],[],["loc",[null,[37,61],[37,113]]]],"classNames","patient-gender me-20","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[38,70],[38,89]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.category.digitalRace.icon",["loc",[null,[38,98],[38,131]]]]],[],[]],"size","55","color","#fff","content",""],["loc",[null,[37,12],[39,51]]]],
            ["content","patient.name",["loc",[null,[41,20],[41,36]]]],
            ["content","patient.category.name",["loc",[null,[42,34],[42,59]]]]
          ],
          locals: ["patient"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 48,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          Keine passenden Tiere gefunden\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 4
            },
            "end": {
              "line": 50,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-body p25");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element6,1,1);
          morphs[1] = dom.createMorphAt(element6,2,2);
          return morphs;
        },
        statements: [
          ["block","each",[["get","animals",["loc",[null,[31,16],[31,23]]]]],[],0,null,["loc",[null,[31,8],[45,17]]]],
          ["block","unless",[["get","hasAnimals",["loc",[null,[46,18],[46,28]]]]],[],1,null,["loc",[null,[46,8],[48,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 6
            },
            "end": {
              "line": 59,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["cancel"],[],["loc",[null,[53,50],[53,69]]]],
          ["content","button-prev",["loc",[null,[54,10],[54,25]]]],
          ["element","action",["next"],[],["loc",[null,[56,11],[56,28]]]],
          ["inline","button-next",[],["size","52","showStroke",false,"color","#ffffff","content",""],["loc",[null,[57,10],[57,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 8
              },
              "end": {
                "line": 68,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--success");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["choose"],[],["loc",[null,[65,13],[65,32]]]],
            ["inline","button-next",[],["size","52","showStroke",false,"color","#ffffff","content",""],["loc",[null,[66,12],[66,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 6
            },
            "end": {
              "line": 69,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["back"],[],["loc",[null,[61,50],[61,67]]]],
          ["content","button-prev",["loc",[null,[62,10],[62,25]]]],
          ["block","if",[["get","hasAnimalsSelected",["loc",[null,[64,14],[64,32]]]]],[],0,null,["loc",[null,[64,8],[68,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/copy-treatment-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-copy-treatment-panel");
        dom.setAttribute(el1,"class","popup-basic popup-mid admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Behandlung kopieren");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-footer text-right");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [0, 1]);
        var element14 = dom.childAt(element13, [6]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element13,3,3);
        morphs[1] = dom.createMorphAt(element13,4,4);
        morphs[2] = dom.createMorphAt(element14,1,1);
        morphs[3] = dom.createMorphAt(element14,2,2);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[5,14],[5,25]]]],"options"],[],["loc",[null,[5,10],[5,36]]]]],[],0,null,["loc",[null,[5,4],[28,11]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[29,14],[29,25]]]],"animals"],[],["loc",[null,[29,10],[29,36]]]]],[],1,null,["loc",[null,[29,4],[50,11]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[52,16],[52,27]]]],"options"],[],["loc",[null,[52,12],[52,38]]]]],[],2,null,["loc",[null,[52,6],[59,13]]]],
        ["block","if",[["subexpr","eq",[["get","currentView",["loc",[null,[60,16],[60,27]]]],"animals"],[],["loc",[null,[60,12],[60,38]]]]],[],3,null,["loc",[null,[60,6],[69,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
define('jason-frontend/templates/welcome/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 61
            },
            "end": {
              "line": 22,
              "column": 113
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Kunden demnächst");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 61
            },
            "end": {
              "line": 27,
              "column": 113
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Tiere auf Station");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 87,
              "column": 4
            },
            "end": {
              "line": 115,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-24/24 l-12/24@tablet  l-6/24@desk gutter kpi-tile");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card u-text--center mb20");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card-header h-90px align-items-start border-0 pt-5");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","card-title d-flex flex-column");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-center cursor");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"style","font-size: 2.2rem !important;");
          dom.setAttribute(el6,"class","fw-bold");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","padding-left: 20px;font-size: 1rem");
          var el7 = dom.createTextNode("€ Umsatz heute");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card-body");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex flex-stack cursor");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","text-gray-700 fw-semibold fs-5 me-2");
          var el6 = dom.createTextNode("Laufende Woche");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-center");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","text-gray-900 fw-bolder fs-4");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","separator separator-dashed my-3");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex flex-stack cursor");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","text-gray-700 fw-semibold fs-5 me-2");
          var el6 = dom.createTextNode("Laufendes Monat");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","d-flex align-items-center");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","text-gray-900 fw-bolder fs-4");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","hint");
          var el5 = dom.createTextNode("Umsätze Netto");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","updated");
          var el5 = dom.createTextNode("Stand: ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1, 1]);
          var element19 = dom.childAt(element18, [1, 1, 1]);
          var element20 = dom.childAt(element18, [3]);
          var element21 = dom.childAt(element20, [1]);
          var element22 = dom.childAt(element20, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
          morphs[2] = dom.createElementMorph(element21);
          morphs[3] = dom.createMorphAt(dom.childAt(element21, [3, 0]),0,0);
          morphs[4] = dom.createElementMorph(element22);
          morphs[5] = dom.createMorphAt(dom.childAt(element22, [3, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element20, [9]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[92,58],[92,89]]]],
          ["inline","format-number-german",[["get","revenueData.today",["loc",[null,[93,98],[93,115]]]]],[],["loc",[null,[93,75],[93,117]]]],
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[99,48],[99,79]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","revenueData.week",["loc",[null,[101,118],[101,134]]]]],[],[]],"class","inline"],["loc",[null,[101,94],[101,151]]]],
          ["element","action",["gotoSalesDashboard"],[],["loc",[null,[104,48],[104,79]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","revenueData.month",["loc",[null,[106,118],[106,135]]]]],[],[]],"class","inline"],["loc",[null,[106,94],[106,152]]]],
          ["inline","time-format",[["get","revenueData.lastUpdate",["loc",[null,[110,52],[110,74]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[110,38],[110,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 8
            },
            "end": {
              "line": 122,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","text-center mt20 mb20");
          var el2 = dom.createTextNode("Derzeit keine Daten verfügbar");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 131,
                "column": 8
              },
              "end": {
                "line": 133,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","text-center mt20 mb20");
            var el2 = dom.createTextNode("Derzeit keine Daten verfügbar");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 0
            },
            "end": {
              "line": 137,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 l-12/24@desk gutter ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card mb20");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","fw-bold");
          var el4 = dom.createTextNode("Umsätze");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","small mb10");
          var el4 = dom.createTextNode("Monatsvergleich, netto");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","revenueBarChart");
          dom.setAttribute(el3,"style","height: 200px; margin: 0 auto");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),5,5);
          return morphs;
        },
        statements: [
          ["block","if",[["get","hideRevenueChart",["loc",[null,[131,14],[131,30]]]]],[],0,null,["loc",[null,[131,8],[133,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 169,
                "column": 32
              },
              "end": {
                "line": 169,
                "column": 160
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[169,106],[169,133]]]],
            ["content","item.customer.lastname",["loc",[null,[169,134],[169,160]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 174,
                  "column": 36
                },
                "end": {
                  "line": 174,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[174,113],[174,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 173,
                "column": 28
              },
              "end": {
                "line": 175,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[174,63],[174,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[174,36],[174,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 179,
                "column": 26
              },
              "end": {
                "line": 183,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/admission.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element16);
            return morphs;
          },
          statements: [
            ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[180,126],[180,142]]]],["get","patientInfo.patient.id",["loc",[null,[180,143],[180,165]]]]],[],["loc",[null,[180,98],[180,167]]]]
          ],
          locals: ["patientInfo"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 20
            },
            "end": {
              "line": 186,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element17, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element17, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element17, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element17, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[166,46],[166,58]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[166,32],[166,81]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[169,60],[169,76]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[169,32],[169,172]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[173,36],[173,58]]]]],[],1,null,["loc",[null,[173,28],[175,37]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[179,34],[179,56]]]]],[],2,null,["loc",[null,[179,26],[183,35]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 187,
              "column": 20
            },
            "end": {
              "line": 191,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 212,
                "column": 30
              },
              "end": {
                "line": 212,
                "column": 155
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[212,101],[212,128]]]],
            ["content","item.customer.lastname",["loc",[null,[212,129],[212,155]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 217,
                  "column": 36
                },
                "end": {
                  "line": 217,
                  "column": 136
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[217,110],[217,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 216,
                "column": 28
              },
              "end": {
                "line": 218,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient",["loc",[null,[217,63],[217,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[217,36],[217,148]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 206,
              "column": 18
            },
            "end": {
              "line": 224,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element15, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element15, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element15, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[209,44],[209,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[209,30],[209,79]]]],
          ["block","link-to",["customers.edit",["get","item.customer",["loc",[null,[212,58],[212,71]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[212,30],[212,167]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[216,36],[216,58]]]]],[],1,null,["loc",[null,[216,28],[218,37]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 225,
              "column": 18
            },
            "end": {
              "line": 229,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 250,
                "column": 32
              },
              "end": {
                "line": 250,
                "column": 160
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[250,106],[250,133]]]],
            ["content","item.customer.lastname",["loc",[null,[250,134],[250,160]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 255,
                  "column": 36
                },
                "end": {
                  "line": 255,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[255,113],[255,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 28
              },
              "end": {
                "line": 256,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[255,63],[255,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[255,36],[255,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 261,
                  "column": 28
                },
                "end": {
                  "line": 263,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 260,
                "column": 26
              },
              "end": {
                "line": 264,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["practicemgmt.treatment-details",["get","treatment.id",["loc",[null,[261,72],[261,84]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[261,85],[261,120]]]]],["class","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[261,28],[263,40]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 244,
              "column": 20
            },
            "end": {
              "line": 267,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element14, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element14, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element14, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[247,46],[247,58]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[247,32],[247,81]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[250,60],[250,76]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[250,32],[250,172]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[254,36],[254,58]]]]],[],1,null,["loc",[null,[254,28],[256,37]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[260,34],[260,56]]]]],[],2,null,["loc",[null,[260,26],[264,35]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 268,
              "column": 20
            },
            "end": {
              "line": 272,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 293,
                "column": 30
              },
              "end": {
                "line": 293,
                "column": 158
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.customer.firstname",["loc",[null,[293,104],[293,131]]]],
            ["content","item.customer.lastname",["loc",[null,[293,132],[293,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 298,
                  "column": 36
                },
                "end": {
                  "line": 298,
                  "column": 139
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.patient.name",["loc",[null,[298,113],[298,139]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 297,
                "column": 28
              },
              "end": {
                "line": 299,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","treatment.patient.id",["loc",[null,[298,63],[298,83]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[298,36],[298,151]]]]
          ],
          locals: ["treatment"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 287,
              "column": 18
            },
            "end": {
              "line": 305,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Aufgenommen");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Tier");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-2");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element13, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[290,44],[290,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[290,30],[290,79]]]],
          ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[293,58],[293,74]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[293,30],[293,170]]]],
          ["block","each",[["get","item.treatmentPatients",["loc",[null,[297,36],[297,58]]]]],[],1,null,["loc",[null,[297,28],[299,37]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 18
            },
            "end": {
              "line": 310,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 348,
                  "column": 55
                },
                "end": {
                  "line": 348,
                  "column": 195
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customerData.firstname",["loc",[null,[348,133],[348,164]]]],
              ["content","item.customerData.lastname",["loc",[null,[348,165],[348,195]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 348,
                "column": 30
              },
              "end": {
                "line": 348,
                "column": 207
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customerData.id",["loc",[null,[348,83],[348,103]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[348,55],[348,207]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 348,
                "column": 207
              },
              "end": {
                "line": 348,
                "column": 225
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Barverkauf");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 354,
                "column": 24
              },
              "end": {
                "line": 357,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 342,
              "column": 18
            },
            "end": {
              "line": 360,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Summe");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element12, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element12, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[345,44],[345,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[345,30],[345,79]]]],
          ["block","if",[["get","item.customerData",["loc",[null,[348,36],[348,53]]]]],[],0,1,["loc",[null,[348,30],[348,232]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.totalGross",["loc",[null,[351,75],[351,90]]]]],[],[]]],["loc",[null,[351,31],[351,92]]]],
          ["block","link-to",["invoices.create",["get","item.id",["loc",[null,[354,53],[354,60]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[354,61],[354,88]]]]],["class","icon-button icon-button--default list-action-square"],2,null,["loc",[null,[354,24],[357,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 361,
              "column": 18
            },
            "end": {
              "line": 365,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 390,
                  "column": 51
                },
                "end": {
                  "line": 390,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[390,125],[390,152]]]],
              ["content","item.customer.lastname",["loc",[null,[390,153],[390,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 390,
                "column": 30
              },
              "end": {
                "line": 390,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[390,79],[390,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[390,51],[390,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 390,
                "column": 191
              },
              "end": {
                "line": 390,
                "column": 209
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Barverkauf");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 396,
                "column": 24
              },
              "end": {
                "line": 399,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 381,
              "column": 18
            },
            "end": {
              "line": 402,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Re-NR");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-12/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Summe");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk buttons-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element11, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element11, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element11, [9]),1,1);
          return morphs;
        },
        statements: [
          ["content","item.code",["loc",[null,[384,30],[384,43]]]],
          ["inline","time-format",[["get","item.created",["loc",[null,[387,44],[387,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[387,30],[387,79]]]],
          ["block","if",[["get","item.customer",["loc",[null,[390,36],[390,49]]]]],[],0,1,["loc",[null,[390,30],[390,216]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.total",["loc",[null,[393,74],[393,84]]]]],[],[]]],["loc",[null,[393,30],[393,86]]]],
          ["block","link-to",["invoices.show",["get","item.id",["loc",[null,[396,51],[396,58]]]]],["class","icon-button icon-button--default list-action-square"],2,null,["loc",[null,[396,24],[399,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 403,
              "column": 18
            },
            "end": {
              "line": 407,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 text-center");
          var el3 = dom.createTextNode("Kein Eintrag vorhanden");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 418,
              "column": 30
            },
            "end": {
              "line": 418,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Erinnerungen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 439,
              "column": 18
            },
            "end": {
              "line": 445,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center 24/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Keine Erinnerungen gefunden");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 451,
                    "column": 71
                  },
                  "end": {
                    "line": 453,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small is-important");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[452,86],[452,104]]]],"seit"],[],["loc",[null,[452,58],[452,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 453,
                    "column": 34
                  },
                  "end": {
                    "line": 454,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[453,91],[453,109]]]],"in"],[],["loc",[null,[453,63],[453,116]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 451,
                  "column": 44
                },
                "end": {
                  "line": 454,
                  "column": 39
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.overdue",["loc",[null,[451,77],[451,89]]]]],[],0,1,["loc",[null,[451,71],[454,39]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 450,
                "column": 101
              },
              "end": {
                "line": 454,
                "column": 46
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","item.executed",["loc",[null,[451,55],[451,68]]]]],[],["loc",[null,[451,50],[451,69]]]]],[],0,null,["loc",[null,[451,44],[454,46]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 457,
                "column": 28
              },
              "end": {
                "line": 458,
                "column": 109
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-warning",[],["size","25","showStroke",true,"color","#EF5F4E","content","Fälligkeit bereits überschritten"],["loc",[null,[457,48],[458,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 459,
                "column": 30
              },
              "end": {
                "line": 461,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Serie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 461,
                "column": 24
              },
              "end": {
                "line": 463,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Einmalig");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 468,
                    "column": 29
                  },
                  "end": {
                    "line": 470,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                               ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-small");
                var el2 = dom.createTextNode("verschickt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 470,
                    "column": 29
                  },
                  "end": {
                    "line": 472,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                               ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-small");
                var el2 = dom.createTextNode("offen");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 467,
                  "column": 27
                },
                "end": {
                  "line": 474,
                  "column": 71
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                             ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub block small mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                               / ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mal");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [2]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,2,2);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.finished",["loc",[null,[468,35],[468,48]]]]],[],0,1,["loc",[null,[468,29],[472,36]]]],
              ["content","item.timeUnitIterationsDone",["loc",[null,[473,63],[473,94]]]],
              ["content","item.timeUnitIterations",["loc",[null,[474,33],[474,60]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 474,
                  "column": 71
                },
                "end": {
                  "line": 476,
                  "column": 27
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                             ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge info-badge-small");
              var el2 = dom.createTextNode("offen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 466,
                "column": 31
              },
              "end": {
                "line": 477,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[467,37],[467,53]]]],"multitime"],[],["loc",[null,[467,33],[467,66]]]]],[],0,1,["loc",[null,[467,27],[476,34]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 477,
                "column": 25
              },
              "end": {
                "line": 478,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 482,
                  "column": 51
                },
                "end": {
                  "line": 482,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[482,125],[482,152]]]],
              ["content","item.customer.lastname",["loc",[null,[482,153],[482,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 482,
                "column": 30
              },
              "end": {
                "line": 482,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[482,79],[482,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[482,51],[482,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 483,
                  "column": 64
                },
                "end": {
                  "line": 483,
                  "column": 157
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.patient.name",["loc",[null,[483,136],[483,157]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 483,
                "column": 44
              },
              "end": {
                "line": 483,
                "column": 169
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[483,91],[483,106]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[483,64],[483,169]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 446,
              "column": 18
            },
            "end": {
              "line": 486,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-5/24@desk");
          dom.setAttribute(el2,"data-label","Fällig");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-12/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-6/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24  l-9/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element7, [3]);
          var element10 = dom.childAt(element7, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
          morphs[2] = dom.createMorphAt(element8,4,4);
          morphs[3] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element7, [5, 1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.nextExecution",["loc",[null,[449,44],[449,62]]]],"DD. MM. YYYY"],[],["loc",[null,[449,30],[449,79]]]],
          ["inline","time-format",[["get","item.nextExecution",["loc",[null,[450,62],[450,80]]]],"HH:mm"],[],["loc",[null,[450,48],[450,90]]]],
          ["block","if",[["get","item.nextExecution",["loc",[null,[451,24],[451,42]]]]],[],0,null,["loc",[null,[450,101],[454,53]]]],
          ["block","if",[["get","item.overdue",["loc",[null,[457,34],[457,46]]]]],[],1,null,["loc",[null,[457,28],[458,116]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[459,40],[459,56]]]],"multitime"],[],["loc",[null,[459,36],[459,69]]]]],[],2,3,["loc",[null,[459,30],[463,31]]]],
          ["block","if",[["get","item.executed",["loc",[null,[466,37],[466,50]]]]],[],4,5,["loc",[null,[466,31],[478,32]]]],
          ["block","if",[["get","item.customer",["loc",[null,[482,36],[482,49]]]]],[],6,null,["loc",[null,[482,30],[482,198]]]],
          ["block","if",[["get","item.patient",["loc",[null,[483,50],[483,62]]]]],[],7,null,["loc",[null,[483,44],[483,176]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 501,
              "column": 18
            },
            "end": {
              "line": 507,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center 24/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Keine Erinnerungen gefunden");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 513,
                    "column": 71
                  },
                  "end": {
                    "line": 515,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small is-important");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[514,86],[514,104]]]],"seit"],[],["loc",[null,[514,58],[514,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 515,
                    "column": 34
                  },
                  "end": {
                    "line": 516,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/welcome/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        )");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-days-between-improved",[["get","item.nextExecution",["loc",[null,[515,91],[515,109]]]],"in"],[],["loc",[null,[515,63],[515,116]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 513,
                  "column": 44
                },
                "end": {
                  "line": 516,
                  "column": 39
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.overdue",["loc",[null,[513,77],[513,89]]]]],[],0,1,["loc",[null,[513,71],[516,39]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 512,
                "column": 101
              },
              "end": {
                "line": 516,
                "column": 46
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","item.executed",["loc",[null,[513,55],[513,68]]]]],[],["loc",[null,[513,50],[513,69]]]]],[],0,null,["loc",[null,[513,44],[516,46]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 517,
                "column": 24
              },
              "end": {
                "line": 518,
                "column": 107
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("Serie, alle ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element1,1,1);
            morphs[1] = dom.createMorphAt(element1,3,3);
            return morphs;
          },
          statements: [
            ["content","item.timeUnitInterval",["loc",[null,[518,52],[518,77]]]],
            ["content","item.timeUnit.name",["loc",[null,[518,78],[518,100]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 521,
                "column": 28
              },
              "end": {
                "line": 522,
                "column": 109
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-warning",[],["size","25","showStroke",true,"color","#EF5F4E","content","Fälligkeit bereits überschritten"],["loc",[null,[521,48],[522,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 523,
                "column": 30
              },
              "end": {
                "line": 525,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Serie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 525,
                "column": 24
              },
              "end": {
                "line": 527,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("Einmalig");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 532,
                  "column": 27
                },
                "end": {
                  "line": 533,
                  "column": 69
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub block small mt5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                             / ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mal");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0,0,0);
              morphs[1] = dom.createMorphAt(element0,2,2);
              return morphs;
            },
            statements: [
              ["content","item.timeUnitIterationsDone",["loc",[null,[532,102],[532,133]]]],
              ["content","item.timeUnitIterations",["loc",[null,[533,31],[533,58]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 530,
                "column": 31
              },
              "end": {
                "line": 534,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("verschickt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[532,37],[532,53]]]],"multitime"],[],["loc",[null,[532,33],[532,66]]]]],[],0,null,["loc",[null,[532,27],[533,76]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 534,
                "column": 25
              },
              "end": {
                "line": 535,
                "column": 25
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-small");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                         ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 538,
                  "column": 51
                },
                "end": {
                  "line": 538,
                  "column": 179
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[538,125],[538,152]]]],
              ["content","item.customer.lastname",["loc",[null,[538,153],[538,179]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 538,
                "column": 30
              },
              "end": {
                "line": 538,
                "column": 191
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[538,79],[538,95]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[538,51],[538,191]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 539,
                  "column": 64
                },
                "end": {
                  "line": 539,
                  "column": 157
                }
              },
              "moduleName": "jason-frontend/templates/welcome/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.patient.name",["loc",[null,[539,136],[539,157]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 539,
                "column": 44
              },
              "end": {
                "line": 539,
                "column": 169
              }
            },
            "moduleName": "jason-frontend/templates/welcome/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[539,91],[539,106]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[539,64],[539,169]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 508,
              "column": 18
            },
            "end": {
              "line": 542,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/welcome/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-5/24@desk");
          dom.setAttribute(el2,"data-label","Versendet");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-12/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--center l-6/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                         ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24  l-9/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","block");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [7]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[2] = dom.createMorphAt(element3,4,4);
          morphs[3] = dom.createMorphAt(element3,6,6);
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [5, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.lastExecution",["loc",[null,[511,44],[511,62]]]],"DD. MM. YYYY"],[],["loc",[null,[511,30],[511,79]]]],
          ["inline","time-format",[["get","item.lastExecution",["loc",[null,[512,62],[512,80]]]],"HH:mm"],[],["loc",[null,[512,48],[512,90]]]],
          ["block","if",[["get","item.nextExecution",["loc",[null,[513,24],[513,42]]]]],[],0,null,["loc",[null,[512,101],[516,53]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType.id",["loc",[null,[517,34],[517,53]]]],"multitime"],[],["loc",[null,[517,30],[517,66]]]]],[],1,null,["loc",[null,[517,24],[518,114]]]],
          ["block","if",[["get","item.overdue",["loc",[null,[521,34],[521,46]]]]],[],2,null,["loc",[null,[521,28],[522,116]]]],
          ["block","if",[["subexpr","eq",[["get","item.triggerType",["loc",[null,[523,40],[523,56]]]],"multitime"],[],["loc",[null,[523,36],[523,69]]]]],[],3,4,["loc",[null,[523,30],[527,31]]]],
          ["block","if",[["get","item.executed",["loc",[null,[530,37],[530,50]]]]],[],5,6,["loc",[null,[530,31],[535,32]]]],
          ["block","if",[["get","item.customer",["loc",[null,[538,36],[538,49]]]]],[],7,null,["loc",[null,[538,30],[538,198]]]],
          ["block","if",[["get","item.patient",["loc",[null,[539,50],[539,62]]]]],[],8,null,["loc",[null,[539,44],[539,176]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 568,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/welcome/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Dashboard");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","welcomeDashboard");
        dom.setAttribute(el1,"class","dashboard");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","fw-bold");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("in Praxis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-5 me-2");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-5 me-2");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center cursor");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","fw-bold");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("Behandlungen heute");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack cursor");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-4 me-2");
        var el8 = dom.createTextNode("Laufende Woche");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-5");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack cursor");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-4 me-2");
        var el8 = dom.createTextNode("Laufendes Monat");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-5");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","updated");
        var el7 = dom.createTextNode("Stand: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-header h-90px align-items-start border-0 pt-5");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-title d-flex flex-column");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center cursor");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 1rem;padding-right: 10px");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"style","font-size: 2.2rem !important;");
        dom.setAttribute(el8,"class","fw-bold");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"style","padding-left: 20px;font-size: 1rem");
        var el9 = dom.createTextNode("Kundentermine heute");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-5 me-2 cursor");
        var el8 = dom.createTextNode("Laufende Woche");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","separator separator-dashed my-3");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-stack");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","text-gray-700 fw-semibold fs-5 me-2 cursor");
        var el8 = dom.createTextNode("Laufendes Monat");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","text-gray-900 fw-bolder fs-4");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","updated");
        var el7 = dom.createTextNode("Stand: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card mb20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","fw-bold");
        var el6 = dom.createTextNode("Kunden");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Monatsvergleich");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"id","customerBarChart");
        dom.setAttribute(el5,"style","height: 200px; margin: 0 auto");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","fw-bold");
        var el6 = dom.createTextNode("In meiner Praxis");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","rework-content tabs-rework-wrapper grid mt-5");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-practice-upcoming");
        var el8 = dom.createTextNode("Demnächst");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-practice-waiting");
        var el8 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-practice-treatment");
        var el8 = dom.createTextNode("Behandlung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-practice-payment");
        var el8 = dom.createTextNode("Bezahlen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt20 mb-0");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-upcoming");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Termin");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Tier");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-2/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-waiting");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Tier");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-2/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-treatment");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Tier");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-2/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-practice-payment");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Tier");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-2/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","fw-bold");
        var el6 = dom.createTextNode("Ausgangsrechnung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","rework-content tabs-rework-wrapper grid mt-5");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-invoice-open");
        var el8 = dom.createTextNode("Offen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-invoice-recent");
        var el8 = dom.createTextNode("Kürzlich abgeschlossen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt-30 mb-0");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-invoice-open");
        var el8 = dom.createTextNode("\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Datum");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-8/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--right l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Summe");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-4/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-invoice-recent");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        dom.setAttribute(el9,"style","overflow-y: auto;max-height: 450px;");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-4/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("RE-Nr");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Datum");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--right l-4/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Summe");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-4/24");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 l-12/24@desk gutter ");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","fw-bold");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","small mb10");
        var el6 = dom.createTextNode("Übersicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","rework-content tabs-rework-wrapper grid mt-5");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-reminder-past");
        var el8 = dom.createTextNode("Kürzlich versendet");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-reminder-upcoming");
        var el8 = dom.createTextNode("Demnächst");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body mt-30 mb-0");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-reminder-upcoming");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center l-5/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Fällig");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center l-3/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Typ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Status");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-9/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-reminder-past");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table-wrapper-rework mb-0");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table grid");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__head");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center l-5/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Versendet");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center  l-3/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Typ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell table__cell--center l-6/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Status");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","table__cell l-9/24");
        var el12 = dom.createElement("span");
        dom.setAttribute(el12,"class","table__title title");
        var el13 = dom.createTextNode("Kund:in");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__body");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element23 = dom.childAt(fragment, [2, 1]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(element25, [3]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element26, [5]);
        var element29 = dom.childAt(element23, [3]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element30, [1, 1, 1]);
        var element32 = dom.childAt(element30, [3]);
        var element33 = dom.childAt(element32, [1]);
        var element34 = dom.childAt(element32, [5]);
        var element35 = dom.childAt(element23, [5]);
        var element36 = dom.childAt(element35, [1]);
        var element37 = dom.childAt(element36, [1, 1, 1]);
        var element38 = dom.childAt(element36, [3]);
        var element39 = dom.childAt(element38, [1]);
        var element40 = dom.childAt(element38, [5]);
        var element41 = dom.childAt(element23, [9]);
        var element42 = dom.childAt(element23, [13, 1, 5]);
        var element43 = dom.childAt(element42, [1]);
        var element44 = dom.childAt(element43, [1]);
        var element45 = dom.childAt(element43, [3]);
        var element46 = dom.childAt(element43, [5]);
        var element47 = dom.childAt(element43, [7]);
        var element48 = dom.childAt(element42, [3]);
        var element49 = dom.childAt(element48, [1]);
        var element50 = dom.childAt(element49, [1, 1, 3]);
        var element51 = dom.childAt(element48, [3]);
        var element52 = dom.childAt(element51, [1, 1, 3]);
        var element53 = dom.childAt(element48, [5]);
        var element54 = dom.childAt(element53, [1, 1, 3]);
        var element55 = dom.childAt(element48, [7]);
        var element56 = dom.childAt(element55, [1, 1, 3]);
        var element57 = dom.childAt(element23, [15, 1, 5]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element58, [1]);
        var element60 = dom.childAt(element58, [3]);
        var element61 = dom.childAt(element57, [3]);
        var element62 = dom.childAt(element61, [1]);
        var element63 = dom.childAt(element62, [1, 1, 3]);
        var element64 = dom.childAt(element61, [3]);
        var element65 = dom.childAt(element64, [1, 1, 3]);
        var element66 = dom.childAt(element23, [17, 1]);
        var element67 = dom.childAt(element66, [5]);
        var element68 = dom.childAt(element67, [1]);
        var element69 = dom.childAt(element68, [1]);
        var element70 = dom.childAt(element68, [3]);
        var element71 = dom.childAt(element67, [3]);
        var element72 = dom.childAt(element71, [1]);
        var element73 = dom.childAt(element72, [1, 1, 3]);
        var element74 = dom.childAt(element71, [3]);
        var element75 = dom.childAt(element74, [1, 1, 3]);
        var morphs = new Array(68);
        morphs[0] = dom.createAttrMorph(element24, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element25, [1, 1, 1, 3]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element27, [3, 0]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element28, [3, 0]),0,0);
        morphs[6] = dom.createAttrMorph(element29, 'class');
        morphs[7] = dom.createElementMorph(element31);
        morphs[8] = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
        morphs[9] = dom.createElementMorph(element33);
        morphs[10] = dom.createMorphAt(dom.childAt(element33, [3, 0]),0,0);
        morphs[11] = dom.createElementMorph(element34);
        morphs[12] = dom.createMorphAt(dom.childAt(element34, [3, 0]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element32, [7]),1,1);
        morphs[14] = dom.createAttrMorph(element35, 'class');
        morphs[15] = dom.createElementMorph(element37);
        morphs[16] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
        morphs[17] = dom.createElementMorph(element39);
        morphs[18] = dom.createMorphAt(dom.childAt(element39, [3, 0]),0,0);
        morphs[19] = dom.createElementMorph(element40);
        morphs[20] = dom.createMorphAt(dom.childAt(element40, [3, 0]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element38, [7]),1,1);
        morphs[22] = dom.createMorphAt(element23,7,7);
        morphs[23] = dom.createAttrMorph(element41, 'class');
        morphs[24] = dom.createMorphAt(dom.childAt(element41, [1]),5,5);
        morphs[25] = dom.createMorphAt(element23,11,11);
        morphs[26] = dom.createAttrMorph(element44, 'class');
        morphs[27] = dom.createElementMorph(element44);
        morphs[28] = dom.createAttrMorph(element45, 'class');
        morphs[29] = dom.createElementMorph(element45);
        morphs[30] = dom.createAttrMorph(element46, 'class');
        morphs[31] = dom.createElementMorph(element46);
        morphs[32] = dom.createAttrMorph(element47, 'class');
        morphs[33] = dom.createElementMorph(element47);
        morphs[34] = dom.createAttrMorph(element49, 'class');
        morphs[35] = dom.createMorphAt(element50,1,1);
        morphs[36] = dom.createMorphAt(element50,2,2);
        morphs[37] = dom.createAttrMorph(element51, 'class');
        morphs[38] = dom.createMorphAt(element52,1,1);
        morphs[39] = dom.createMorphAt(element52,2,2);
        morphs[40] = dom.createAttrMorph(element53, 'class');
        morphs[41] = dom.createMorphAt(element54,1,1);
        morphs[42] = dom.createMorphAt(element54,2,2);
        morphs[43] = dom.createAttrMorph(element55, 'class');
        morphs[44] = dom.createMorphAt(element56,1,1);
        morphs[45] = dom.createMorphAt(element56,2,2);
        morphs[46] = dom.createAttrMorph(element59, 'class');
        morphs[47] = dom.createElementMorph(element59);
        morphs[48] = dom.createAttrMorph(element60, 'class');
        morphs[49] = dom.createElementMorph(element60);
        morphs[50] = dom.createAttrMorph(element62, 'class');
        morphs[51] = dom.createMorphAt(element63,1,1);
        morphs[52] = dom.createMorphAt(element63,2,2);
        morphs[53] = dom.createAttrMorph(element64, 'class');
        morphs[54] = dom.createMorphAt(element65,1,1);
        morphs[55] = dom.createMorphAt(element65,2,2);
        morphs[56] = dom.createMorphAt(dom.childAt(element66, [1]),0,0);
        morphs[57] = dom.createAttrMorph(element69, 'class');
        morphs[58] = dom.createElementMorph(element69);
        morphs[59] = dom.createAttrMorph(element70, 'class');
        morphs[60] = dom.createElementMorph(element70);
        morphs[61] = dom.createAttrMorph(element72, 'class');
        morphs[62] = dom.createMorphAt(element73,1,1);
        morphs[63] = dom.createMorphAt(element73,2,2);
        morphs[64] = dom.createAttrMorph(element74, 'class');
        morphs[65] = dom.createMorphAt(element75,1,1);
        morphs[66] = dom.createMorphAt(element75,2,2);
        morphs[67] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["col l-24/24 l-12/24@tablet l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[9,71],[9,84]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[9,85],[9,121]]]]],[],["loc",[null,[9,66],[9,122]]]],"6","8"],[],["loc",[null,[9,45],[9,132]]]],"/24@desk gutter kpi-tile"]]],
        ["content","liveData.inPractice",["loc",[null,[15,74],[15,97]]]],
        ["block","link-to",["appointments.calendar"],[],0,null,["loc",[null,[22,61],[22,125]]]],
        ["content","liveData.upcoming",["loc",[null,[23,94],[23,115]]]],
        ["block","link-to",["practicemgmt.station"],[],1,null,["loc",[null,[27,61],[27,125]]]],
        ["content","liveData.inStation",["loc",[null,[28,94],[28,116]]]],
        ["attribute","class",["concat",["col  l-24/24 l-12/24@tablet  l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[33,73],[33,86]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[33,87],[33,123]]]]],[],["loc",[null,[33,68],[33,124]]]],"6","8"],[],["loc",[null,[33,47],[33,134]]]],"/24@desk gutter  kpi-tile"]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[37,58],[37,91]]]],
        ["content","treatmentData.today",["loc",[null,[39,74],[39,97]]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[45,48],[45,81]]]],
        ["content","treatmentData.week",["loc",[null,[47,94],[47,116]]]],
        ["element","action",["gotoTreatmentJournal"],[],["loc",[null,[50,48],[50,81]]]],
        ["content","treatmentData.month",["loc",[null,[52,94],[52,117]]]],
        ["inline","time-format",[["get","treatmentData.lastUpdate",["loc",[null,[55,52],[55,76]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[55,38],[55,99]]]],
        ["attribute","class",["concat",["col  l-24/24 l-12/24@tablet  l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[60,73],[60,86]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[60,87],[60,123]]]]],[],["loc",[null,[60,68],[60,124]]]],"6","8"],[],["loc",[null,[60,47],[60,134]]]],"/24@desk gutter kpi-tile "]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[64,58],[64,83]]]],
        ["content","appointmentData.today",["loc",[null,[66,74],[66,99]]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[72,41],[72,66]]]],
        ["content","appointmentData.week",["loc",[null,[74,94],[74,118]]]],
        ["element","action",["gotoCalendar"],[],["loc",[null,[77,41],[77,66]]]],
        ["content","appointmentData.month",["loc",[null,[79,94],[79,119]]]],
        ["inline","time-format",[["get","appointmentData.lastUpdate",["loc",[null,[82,52],[82,78]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[82,38],[82,101]]]],
        ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[87,15],[87,28]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[87,29],[87,65]]]]],[],["loc",[null,[87,10],[87,66]]]]],[],2,null,["loc",[null,[87,4],[115,11]]]],
        ["attribute","class",["concat",["col l-24/24 l-",["subexpr","css-bool-evaluator",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[116,56],[116,69]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[116,70],[116,106]]]]],[],["loc",[null,[116,51],[116,107]]]],"12","24"],[],["loc",[null,[116,30],[116,119]]]],"/24@desk gutter"]]],
        ["block","if",[["get","hideCustomerChart",["loc",[null,[120,14],[120,31]]]]],[],3,null,["loc",[null,[120,8],[122,15]]]],
        ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[126,11],[126,24]]]],["subexpr","has-permission",["r_sale_dashboards"],[],["loc",[null,[126,25],[126,61]]]]],[],["loc",[null,[126,6],[126,62]]]]],[],4,null,["loc",[null,[126,0],[137,7]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[146,61],[146,80]]]],"upcoming"],[],["loc",[null,[146,57],[146,92]]]],"is-active",""],[],["loc",[null,[146,36],[146,109]]]]]]],
        ["element","action",["selectTabPractice","upcoming"],[],["loc",[null,[146,141],[146,182]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[147,61],[147,80]]]],"in_waitingroom"],[],["loc",[null,[147,57],[147,98]]]],"is-active",""],[],["loc",[null,[147,36],[147,115]]]]]]],
        ["element","action",["selectTabPractice","in_waitingroom"],[],["loc",[null,[147,146],[147,193]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[148,61],[148,80]]]],"in_treatment"],[],["loc",[null,[148,57],[148,96]]]],"is-active",""],[],["loc",[null,[148,36],[148,113]]]]]]],
        ["element","action",["selectTabPractice","in_treatment"],[],["loc",[null,[148,146],[148,191]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[149,61],[149,80]]]],"ready_for_payment"],[],["loc",[null,[149,57],[149,101]]]],"is-active",""],[],["loc",[null,[149,36],[149,118]]]]]]],
        ["element","action",["selectTabPractice","ready_for_payment"],[],["loc",[null,[149,149],[149,199]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[153,72],[153,91]]]],"upcoming"],[],["loc",[null,[153,68],[153,103]]]],"is-active",""],[],["loc",[null,[153,47],[153,120]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[163,28],[163,41]]]]],[],5,null,["loc",[null,[163,20],[186,29]]]],
        ["block","if",[["subexpr","list-empty",[["get","practiceItems",["loc",[null,[187,38],[187,51]]]]],[],["loc",[null,[187,26],[187,52]]]]],[],6,null,["loc",[null,[187,20],[191,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[196,70],[196,89]]]],"in_waitingroom"],[],["loc",[null,[196,66],[196,107]]]],"is-active",""],[],["loc",[null,[196,45],[196,124]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[206,26],[206,39]]]]],[],7,null,["loc",[null,[206,18],[224,27]]]],
        ["block","if",[["subexpr","list-empty",[["get","practiceItems",["loc",[null,[225,36],[225,49]]]]],[],["loc",[null,[225,24],[225,50]]]]],[],8,null,["loc",[null,[225,18],[229,25]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[234,72],[234,91]]]],"in_treatment"],[],["loc",[null,[234,68],[234,107]]]],"is-active",""],[],["loc",[null,[234,47],[234,124]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[244,28],[244,41]]]]],[],9,null,["loc",[null,[244,20],[267,29]]]],
        ["block","if",[["subexpr","list-empty",[["get","practiceItems",["loc",[null,[268,38],[268,51]]]]],[],["loc",[null,[268,26],[268,52]]]]],[],10,null,["loc",[null,[268,20],[272,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabPractice",["loc",[null,[277,70],[277,89]]]],"ready_for_payment"],[],["loc",[null,[277,66],[277,110]]]],"is-active",""],[],["loc",[null,[277,45],[277,127]]]]]]],
        ["block","each",[["get","practiceItems",["loc",[null,[287,26],[287,39]]]]],[],11,null,["loc",[null,[287,18],[305,27]]]],
        ["block","if",[["subexpr","list-empty",[["get","practiceItems",["loc",[null,[306,36],[306,49]]]]],[],["loc",[null,[306,24],[306,50]]]]],[],12,null,["loc",[null,[306,18],[310,25]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[327,61],[327,80]]]],"open"],[],["loc",[null,[327,57],[327,88]]]],"is-active",""],[],["loc",[null,[327,36],[327,105]]]]]]],
        ["element","action",["selectTabInvoices","open"],[],["loc",[null,[327,132],[327,169]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[328,61],[328,80]]]],"recent"],[],["loc",[null,[328,57],[328,90]]]],"is-active",""],[],["loc",[null,[328,36],[328,107]]]]]]],
        ["element","action",["selectTabInvoices","recent"],[],["loc",[null,[328,136],[328,175]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[331,70],[331,89]]]],"open"],[],["loc",[null,[331,66],[331,97]]]],"is-active",""],[],["loc",[null,[331,45],[331,114]]]]]]],
        ["block","each",[["get","invoiceItems",["loc",[null,[342,26],[342,38]]]]],[],13,null,["loc",[null,[342,18],[360,27]]]],
        ["block","if",[["subexpr","list-empty",[["get","invoiceItems",["loc",[null,[361,36],[361,48]]]]],[],["loc",[null,[361,24],[361,49]]]]],[],14,null,["loc",[null,[361,18],[365,25]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabInvoices",["loc",[null,[370,70],[370,89]]]],"recent"],[],["loc",[null,[370,66],[370,99]]]],"is-active",""],[],["loc",[null,[370,45],[370,116]]]]]]],
        ["block","each",[["get","invoiceItems",["loc",[null,[381,26],[381,38]]]]],[],15,null,["loc",[null,[381,18],[402,27]]]],
        ["block","if",[["subexpr","list-empty",[["get","invoiceItems",["loc",[null,[403,36],[403,48]]]]],[],["loc",[null,[403,24],[403,49]]]]],[],16,null,["loc",[null,[403,18],[407,25]]]],
        ["block","link-to",["reminders"],[],17,null,["loc",[null,[418,30],[418,78]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[424,61],[424,81]]]],"past"],[],["loc",[null,[424,57],[424,89]]]],"is-active",""],[],["loc",[null,[424,36],[424,106]]]]]]],
        ["element","action",["selectTabReminders","past"],[],["loc",[null,[424,134],[424,172]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[425,61],[425,81]]]],"upcoming"],[],["loc",[null,[425,57],[425,93]]]],"is-active",""],[],["loc",[null,[425,36],[425,110]]]]]]],
        ["element","action",["selectTabReminders","upcoming"],[],["loc",[null,[425,142],[425,184]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[429,70],[429,90]]]],"upcoming"],[],["loc",[null,[429,66],[429,102]]]],"is-active",""],[],["loc",[null,[429,45],[429,119]]]]]]],
        ["block","if",[["subexpr","eq",[["get","reminderItems.meta.total_records",["loc",[null,[439,28],[439,60]]]],0],[],["loc",[null,[439,24],[439,63]]]]],[],18,null,["loc",[null,[439,18],[445,25]]]],
        ["block","each",[["get","reminderItems",["loc",[null,[446,26],[446,39]]]]],[],19,null,["loc",[null,[446,18],[486,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTabReminders",["loc",[null,[491,70],[491,90]]]],"past"],[],["loc",[null,[491,66],[491,98]]]],"is-active",""],[],["loc",[null,[491,45],[491,115]]]]]]],
        ["block","if",[["subexpr","eq",[["get","reminderItems.meta.total_records",["loc",[null,[501,28],[501,60]]]],0],[],["loc",[null,[501,24],[501,63]]]]],[],20,null,["loc",[null,[501,18],[507,25]]]],
        ["block","each",[["get","reminderItems",["loc",[null,[508,26],[508,39]]]]],[],21,null,["loc",[null,[508,18],[542,27]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[560,8],[560,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[561,10],[561,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[562,13],[562,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[563,17],[563,36]]]]],[],[]]],["loc",[null,[556,0],[563,38]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
    };
  }()));

});
define('jason-frontend/routes/customers/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, RouteMixin, API, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('customer', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("gendersAll", this.store.find('gender'));

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant' }));
      controller.set("model", model);

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      setTimeout(function () {
        $('form.search-box input[type=text]').focus();
      }, 500);
    },
    applyFilter: function applyFilter(searchString) {
      var filterDeleted = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
      var filterStable = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterPatient = controller.get('filterPatient'),
          filterChip = controller.get('filterChip'),
          filterContact = controller.get('filterContact'),
          filterAddress = controller.get('filterAddress'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('customer', {
        filterName: filterName,
        filterPatient: filterPatient,
        filterChip: filterChip,
        filterContact: filterContact,
        filterAddress: filterAddress,
        filterDeleted: filterDeleted ? "true" : "false",
        filterStable: filterStable ? "true" : "false",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 20
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      resetSearch: function resetSearch() {
        this.get('controller').set('filterName', '');
        this.get('controller').set('filterContact', '');
        this.get('controller').set('filterChip', '');
        this.get('controller').set('filterAddress', '');
        this.get('controller').set('filterPatient', '');
        this.applyFilter();
      },
      triggerSearch: function triggerSearch() {
        this.applyFilter();
      },
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      toWaitingRoom: function toWaitingRoom() {
        this.transitionTo('practicemgmt.admission');
      },
      toTreatment: function toTreatment(id) {
        this.transitionTo('practicemgmt.treatment-details', id);
      },
      gotoDetails: function gotoDetails(id) {
        this.transitionTo('customers.edit', id);
      },
      gotoPatient: function gotoPatient(id, tab) {
        if (tab) {
          if (is_journal_enabled.isJournalEnabled() && tab === 'history') {
            tab = 'activities';
          }
          this.transitionTo('patients.edit', id, { queryParams: { selectTab: tab } });
        } else {
          this.transitionTo('patients.edit', id);
        }
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      toggleDead: function toggleDead(show) {
        this.get('controller').set('showDead', show);
      },
      applyFilter: function applyFilter(searchString, filterDeleted, filterStable) {
        this.applyFilter(searchString, filterDeleted, filterStable);
      },
      'delete': function _delete(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('customer', id).then(function (rec) {
          rec.destroyRecord();
        });
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          customerId: customerId,
          category: patientData.category,
          gender: patientData.gender,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function (error) {});
      }
    }
  });

});
define('jason-frontend/templates/components/users-edit-form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 4
            },
            "end": {
              "line": 61,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","cloudinary-tag",[["get","tmpUserImage",["loc",[null,[60,23],[60,35]]]]],["width",300,"height",300,"crop","fill","class","rounded"],["loc",[null,[60,6],[60,86]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","cloudinary-tag",[["get","model.picture.cdnId",["loc",[null,[62,23],[62,42]]]]],["width",300,"height",300,"crop","fill","class","rounded"],["loc",[null,[62,6],[62,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 4
            },
            "end": {
              "line": 63,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.picture",["loc",[null,[61,14],[61,27]]]]],[],0,null,["loc",[null,[61,4],[63,4]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 62
            },
            "end": {
              "line": 86,
              "column": 83
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Neues");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 88,
              "column": 4
            },
            "end": {
              "line": 90,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","small mt5 is-highlighted-alternative");
          var el2 = dom.createTextNode("Bitte nur ausfüllen, wenn Sie das bestehende Passwort überschreiben wollen!");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 82
              },
              "end": {
                "line": 146,
                "column": 138
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Profil");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 0
            },
            "end": {
              "line": 148,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Registrierkassa");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Die vorausgewählte RegistrierkassaKann jede/r Benutzer:in über das persönliche ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" auswählen.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
          return morphs;
        },
        statements: [
          ["block","link-to",["profile"],["classNames","is-highlighted"],0,null,["loc",[null,[146,82],[146,150]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 149,
              "column": 0
            },
            "end": {
              "line": 170,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Elektronische Hausapotheke");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid gutter-fix");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class"," d-flex align-items-center");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","select-placeholder");
          var el6 = dom.createTextNode("Vorauswahl");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1, 1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","inventoryWarehouses",["loc",[null,[158,18],[158,37]]]]],[],[]],"value",["subexpr","@mut",[["get","model.primaryInventoryWarehouse",["loc",[null,[159,16],[159,47]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle Lager summieren"],["loc",[null,[157,8],[165,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 192,
                "column": 6
              },
              "end": {
                "line": 194,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","colorTile");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'style');
            return morphs;
          },
          statements: [
            ["attribute","style",["concat",["background-color:",["get","model.color",["loc",[null,[193,58],[193,69]]]]," "]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 6
              },
              "end": {
                "line": 196,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","colorTile calColor83");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 172,
              "column": 0
            },
            "end": {
              "line": 204,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Praxismanagement");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-role");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Rolle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pt10");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col pt30 l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header-toggle");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","useRoleInCashDesk");
          dom.setAttribute(el4,"class","switch");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","description");
          var el4 = dom.createTextNode("\n        In Verrechnung automatisch zuweisen?\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","name");
          dom.setAttribute(el3,"class","field-label title required");
          var el4 = dom.createTextNode("Farbe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","mt30 mb0");
          var el4 = dom.createTextNode("Die Farbe und Rolle der Mitarbeiter:in kann im ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"target","_blank");
          dom.setAttribute(el4,"class","is-highlighted-alternative");
          dom.setAttribute(el4,"href","https://portal.vetnative.com/users/list");
          var el5 = dom.createTextNode("VetNative\n        Portal");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" definiert werden.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 3]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 1]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),3,3);
          return morphs;
        },
        statements: [
          ["content","model.userRole.name",["loc",[null,[178,26],[178,49]]]],
          ["inline","input",[],["type","checkbox","id","useRoleInCashDesk","classNames","switch","name","useRoleInCashDesk","checked",["subexpr","@mut",[["get","model.useRoleInCashDesk",["loc",[null,[182,108],[182,131]]]]],[],[]]],["loc",[null,[182,8],[182,133]]]],
          ["block","if",[["get","model.colorHex",["loc",[null,[192,12],[192,26]]]]],[],0,1,["loc",[null,[192,6],[196,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 228,
              "column": 18
            },
            "end": {
              "line": 230,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    required: true,\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 270,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Stammdaten");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid gutter-fix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","grid gutter-fix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24 gutter");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," d-flex align-items-center");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","select-placeholder");
        var el8 = dom.createTextNode("Anrede");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24 gutter");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," d-flex align-items-center");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","select-placeholder");
        var el8 = dom.createTextNode("Titel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","grid gutter-fix");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","user-firstname");
        dom.setAttribute(el6,"class","placeholder required");
        var el7 = dom.createTextNode("Vorname");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","user-lastname");
        dom.setAttribute(el6,"class","placeholder required");
        var el7 = dom.createTextNode("Nachname");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form-field-rework");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-phone");
        dom.setAttribute(el4,"class","placeholder");
        var el5 = dom.createTextNode("Telefon");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex justify-content-center");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","context-buttons-onsite mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Bild hochladen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Anmeldung");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid gutter-fix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form-field-rework");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-username");
        dom.setAttribute(el4,"class","placeholder required");
        var el5 = dom.createTextNode("Vorname");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form-field-rework");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-password");
        dom.setAttribute(el4,"class","placeholder required");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Passwort");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-toggle");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","userActive");
        dom.setAttribute(el4,"class","switch");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","description");
        var el4 = dom.createTextNode("\n      Ist aktiv?\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small");
        var el4 = dom.createTextNode("\n      Das neue Passwort muss die folgenden Kriterien erfüllen:\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"style","margin-top:10px;margin-bottom:0");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 8 Zeichen lang");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 1 Klein- und Großbuchstabe");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 1 Zahl");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Erlaubte Sonderzeichen: @ * _ - . ! #");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Sicherheit");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid gutter-fix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex align-items-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","me-10");
        var el5 = dom.createTextNode("Automatisch Abmelden nach");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework max-w-70px mb-0");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-username");
        dom.setAttribute(el5,"class","placeholder");
        var el6 = dom.createTextNode("Minuten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ms-10");
        var el5 = dom.createTextNode("(0 Minuten für ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("Deaktivierung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" dieser Funktion)");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-toggle");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","showInactivity");
        dom.setAttribute(el4,"class","switch");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","description");
        var el4 = dom.createTextNode("\n      Warnhinweis bei bevorstehender Abmeldung anzeigen?\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function () {\n        $(\"#userEdit\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'user-firstname': {\n                    required: true\n                },\n                'user-gender': {\n                    required: true\n                },\n                'user-lastname': {\n                    required: true\n                },\n                'user-username': {\n                    email: true,\n                },\n                'user-password': {\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("                    pwcheck: true\n                },\n                'user-phone': {\n                    pattern: /^\\+?[\\s\\d]+$/\n                },\n            },\n            messages: {\n              password: {\n                pwcheck: \"Das Passwort entspricht nicht den Kriterien!\"\n              }\n            },\n            highlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function (error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n\n        $.validator.addMethod(\"pwcheck\",\n          function(value, element) {\n            if(!value) {\n              return true;\n            }\n            return /^[A-Za-z0-9\\d=!\\-@._*#]*$/.(value) // consists of only these\n              && /[a-z]/.(value) // has a lowercase letter\n              && /[A-Z]/.(value) // has a lowercase letter\n              && /\\d/.(value) // has a digit\n              && value.length >= 8  // at least 8\n          });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element2, [3]);
        var element7 = dom.childAt(element6, [3, 1, 1]);
        var element8 = dom.childAt(fragment, [6]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(fragment, [12]);
        var morphs = new Array(19);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1, 1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1, 1]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [1, 1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [3, 1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
        morphs[6] = dom.createElementMorph(element7);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [1, 1]),1,1);
        morphs[8] = dom.createMorphAt(element10,1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
        morphs[10] = dom.createMorphAt(element9,3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(fragment, [8, 1, 1]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element11, [1, 1, 3]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element11, [3, 1]),1,1);
        morphs[14] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[18] = dom.createMorphAt(dom.childAt(fragment, [21]),1,1);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","genders",["loc",[null,[11,22],[11,29]]]]],[],[]],"value",["subexpr","@mut",[["get","model.gender",["loc",[null,[12,20],[12,32]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen"],["loc",[null,[10,12],[17,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","titles",["loc",[null,[26,22],[26,28]]]]],[],[]],"value",["subexpr","@mut",[["get","model.title",["loc",[null,[27,20],[27,31]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen"],["loc",[null,[25,12],[32,14]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.firstname",["loc",[null,[40,39],[40,54]]]]],[],[]],"name","user-firstname","autocomplete","off"],["loc",[null,[40,10],[40,97]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.lastname",["loc",[null,[46,39],[46,53]]]]],[],[]],"name","user-lastname","autocomplete","off"],["loc",[null,[46,10],[46,95]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.phone",["loc",[null,[52,35],[52,46]]]]],[],[]],"name","user-phone","autocomplete","off","title","Nur Nummern und optionales + erlaubt"],["loc",[null,[52,6],[53,60]]]],
        ["block","if",[["get","tmpUserImage",["loc",[null,[59,11],[59,23]]]]],[],0,1,["loc",[null,[59,4],[63,11]]]],
        ["element","action",["showImagePanel"],[],["loc",[null,[68,11],[68,38]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.username",["loc",[null,[79,35],[79,49]]]]],[],[]],"name","user-username","autocomplete","off","title","Bitte geben Sie eine gültige Email-Adresse an"],["loc",[null,[79,6],[79,145]]]],
        ["inline","input",[],["type","password","placeholder","","value",["subexpr","@mut",[["get","model.password",["loc",[null,[85,51],[85,65]]]]],[],[]],"name","user-password","autocomplete","off","title","Das Passwort entspricht nicht den Kriterien"],["loc",[null,[85,6],[85,159]]]],
        ["block","if",[["get","editMode",["loc",[null,[86,68],[86,76]]]]],[],2,null,["loc",[null,[86,62],[86,90]]]],
        ["block","if",[["get","editMode",["loc",[null,[88,10],[88,18]]]]],[],3,null,["loc",[null,[88,4],[90,11]]]],
        ["inline","input",[],["type","checkbox","id","userActive","classNames","switch","name","userActive","checked",["subexpr","@mut",[["get","model.active",["loc",[null,[98,92],[98,104]]]]],[],[]]],["loc",[null,[98,6],[98,106]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.maxInactivityTime",["loc",[null,[126,37],[126,60]]]]],[],[]],"name","user-username","autocomplete","off","classNames","text-center"],["loc",[null,[126,8],[126,127]]]],
        ["inline","input",[],["type","checkbox","id","showInactivity","classNames","switch","name","showInactivity","checked",["subexpr","@mut",[["get","model.showInactivityLabel",["loc",[null,[134,100],[134,125]]]]],[],[]]],["loc",[null,[134,6],[134,127]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-at-least",["essential"],[],["loc",[null,[143,11],[143,36]]]],["subexpr","has-permission",["c_payments"],[],["loc",[null,[143,37],[143,66]]]]],[],["loc",[null,[143,6],[143,67]]]]],[],4,null,["loc",[null,[143,0],[148,7]]]],
        ["block","if",[["subexpr","has-permission",["ehapo"],[],["loc",[null,[149,6],[149,30]]]]],[],5,null,["loc",[null,[149,0],[170,7]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[172,6],[172,34]]]]],[],6,null,["loc",[null,[172,0],[204,7]]]],
        ["inline","image-upload-and-select-panel",[],["insertImage","imageChanged","actionReceiver",["subexpr","@mut",[["get","imageUploadAndSelectPanel",["loc",[null,[206,74],[206,99]]]]],[],[]]],["loc",[null,[206,0],[206,101]]]],
        ["block","unless",[["get","editMode",["loc",[null,[228,28],[228,36]]]]],[],7,null,["loc",[null,[228,18],[230,29]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});
define('jason-frontend/controllers/purchases/supplierdetails', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Mein Lieferant",
    breadCrumbPath: "purchases.supplierdetails",
    selectTab: "masterdata"
  });

});
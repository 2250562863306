define('jason-frontend/controllers/resourcemgmt/index', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    weekNumber: moment['default']().week(),
    year: moment['default']().format("YYYY"),
    stickyHeader: true,
    myStickyOptionsTableHead: {
      topSpacing: 240,
      zIndex: 9
    },
    years: [{
      id: moment['default']().subtract(1, 'year').format('YYYY'), name: moment['default']().subtract(1, 'year').format('YYYY')
    }, {
      id: moment['default']().format('YYYY'), name: moment['default']().format('YYYY')
    }, {
      id: moment['default']().add(1, 'year').format('YYYY'), name: moment['default']().add(1, 'year').format('YYYY')
    }],
    watchWeeks: (function () {
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('weekNumber'),
    watchYears: (function () {
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('year'),
    watchRole: (function () {
      this.send('roleUpdated');
    }).observes('role'),
    watchSwitchToDate: (function () {
      this.send('gotoDate', this.get('switchToDate'));
    }).observes('switchToDate')
  });

});
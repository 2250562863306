define('jason-frontend/templates/components/patient-activities', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 6
            },
            "end": {
              "line": 115,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element84 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element84, 'class');
          morphs[1] = dom.createElementMorph(element84);
          morphs[2] = dom.createMorphAt(element84,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[113,61],[113,73]]]],"selected",""],[],["loc",[null,[113,40],[113,89]]]]]]],
          ["element","action",["toggleTag",["get","tag",["loc",[null,[114,100],[114,103]]]]],[],["loc",[null,[113,91],[114,105]]]],
          ["content","tag.name",["loc",[null,[114,106],[114,118]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 200,
                  "column": 8
                },
                "end": {
                  "line": 216,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","form-field-rework");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3,"class","select-placeholder");
              var el4 = dom.createTextNode("Tier");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","patients",["loc",[null,[205,24],[205,32]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[206,22],[206,35]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"name","task-patient","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[204,14],[213,16]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 231,
                  "column": 8
                },
                "end": {
                  "line": 247,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","form-field-rework");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3,"class","select-placeholder");
              var el4 = dom.createTextNode("Stall");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","stables",["loc",[null,[236,24],[236,31]]]]],[],[]],"value",["subexpr","@mut",[["get","filterStable",["loc",[null,[237,22],[237,34]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[235,14],[244,16]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 179,
                "column": 6
              },
              "end": {
                "line": 248,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter");
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Kund:in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 l-6/24@desk gutter");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Spezies");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterCustomer",["loc",[null,[185,20],[185,34]]]]],[],[]],"optionLabelPath","fullName","placeholder","Alle Kunden","allowClear",true,"typeaheadSearchingText","Suche Kunde","typeaheadNoMatchesText","Kein Kunden gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle","query","queryCustomer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[184,12],[197,14]]]],
            ["block","if",[["subexpr","and",[["get","filterCustomer",["loc",[null,[200,19],[200,33]]]],["get","patients",["loc",[null,[200,34],[200,42]]]]],[],["loc",[null,[200,14],[200,43]]]]],[],0,null,["loc",[null,[200,8],[216,15]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[221,22],[221,39]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatientCategory",["loc",[null,[222,20],[222,41]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","placeholder","Alle","allowClear",true,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[220,12],[228,14]]]],
            ["block","if",[["subexpr","uses-stable-feature",[],[],["loc",[null,[231,14],[231,35]]]]],[],1,null,["loc",[null,[231,8],[247,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 4
            },
            "end": {
              "line": 249,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Ärzt:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Assistent:in");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Behandlungs-Lokation");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk gutter mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Produkt/Leistung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[123,18],[123,25]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDoctor",["loc",[null,[124,16],[124,28]]]]],[],[]],"optionValuePath","id","optionLabelPath","shortName","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[122,8],[130,10]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[137,18],[137,28]]]]],[],[]],"value",["subexpr","@mut",[["get","filterAssistant",["loc",[null,[138,16],[138,31]]]]],[],[]],"optionValuePath","id","optionLabelPath","shortName","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[136,8],[144,10]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[151,18],[151,23]]]]],[],[]],"value",["subexpr","@mut",[["get","filterRoom",["loc",[null,[152,16],[152,26]]]]],[],[]],"optionValuePath","id","optionLabelPath","name","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[150,8],[158,10]]]],
          ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterArticle",["loc",[null,[165,16],[165,29]]]]],[],[]],"optionLabelPath","name","placeholder","Alle Produkte/Leistungen","allowClear",true,"typeaheadSearchingText","Suche ","typeaheadNoMatchesText","Kein Treffer für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryArticle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[164,8],[176,10]]]],
          ["block","if",[["get","showPatientInfo",["loc",[null,[179,12],[179,27]]]]],[],0,null,["loc",[null,[179,6],[248,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 254,
              "column": 4
            },
            "end": {
              "line": 256,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[255,5],[255,26]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 274,
                  "column": 16
                },
                "end": {
                  "line": 276,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element75 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element75, 'class');
              morphs[1] = dom.createMorphAt(element75,0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["info-badge info-badge-very-small mr5 tag-",["get","tag.name",["loc",[null,[275,75],[275,83]]]]]]],
              ["content","tag.name",["loc",[null,[275,87],[275,99]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 278,
                    "column": 18
                  },
                  "end": {
                    "line": 280,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-idexx");
                var el2 = dom.createTextNode("IDEXX");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 280,
                      "column": 18
                    },
                    "end": {
                      "line": 282,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-laboklin");
                  var el2 = dom.createTextNode("Laboklin");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 282,
                        "column": 18
                      },
                      "end": {
                        "line": 284,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("label");
                    dom.setAttribute(el1,"class","info-badge info-badge-very-small lab-intern");
                    var el2 = dom.createTextNode("Labor Intern");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 282,
                      "column": 18
                    },
                    "end": {
                      "line": 284,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[282,28],[282,51]]]]],[],0,null,["loc",[null,[282,18],[284,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 280,
                    "column": 18
                  },
                  "end": {
                    "line": 284,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[280,32],[280,49]]]],"laboklin"],[],["loc",[null,[280,28],[280,61]]]]],[],0,1,["loc",[null,[280,18],[284,18]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 277,
                  "column": 16
                },
                "end": {
                  "line": 285,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[278,28],[278,45]]]],"vetconnect"],[],["loc",[null,[278,24],[278,59]]]]],[],0,1,["loc",[null,[278,18],[284,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 296,
                      "column": 20
                    },
                    "end": {
                      "line": 300,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" \n                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element73 = dom.childAt(fragment, [1]);
                  var element74 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element73,0,0);
                  morphs[1] = dom.createMorphAt(element73,2,2);
                  morphs[2] = dom.createAttrMorph(element74, 'title');
                  morphs[3] = dom.createAttrMorph(element74, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element74, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.doctor.lastname",["loc",[null,[297,51],[297,83]]]],
                  ["content","item.payload.doctor.firstname",["loc",[null,[297,84],[297,117]]]],
                  ["attribute","title",["concat",[["get","item.payload.doctor.shortName",["loc",[null,[298,36],[298,65]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.doctor.color",["loc",[null,[298,155],[298,180]]]]," !important;"]]],
                  ["content","item.payload.doctor.capitals",["loc",[null,[299,48],[299,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 302,
                      "column": 18
                    },
                    "end": {
                      "line": 302,
                      "column": 77
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("und");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 304,
                      "column": 24
                    },
                    "end": {
                      "line": 309,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ml0 tooltipstered symbol symbol-circle symbol-40px");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element71 = dom.childAt(fragment, [1]);
                  var element72 = dom.childAt(fragment, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element71,0,0);
                  morphs[1] = dom.createMorphAt(element71,2,2);
                  morphs[2] = dom.createAttrMorph(element72, 'title');
                  morphs[3] = dom.createAttrMorph(element72, 'style');
                  morphs[4] = dom.createMorphAt(dom.childAt(element72, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.assistent.lastname",["loc",[null,[305,55],[305,90]]]],
                  ["content","item.payload.assistent.firstname",["loc",[null,[305,91],[305,127]]]],
                  ["attribute","title",["concat",[["get","item.payload.assistent.shortName",["loc",[null,[305,155],[305,187]]]]]]],
                  ["attribute","style",["concat",["background-color: ",["get","item.payload.assistent.color",["loc",[null,[305,277],[305,305]]]]," !important;"]]],
                  ["content","item.payload.assistent.capitals",["loc",[null,[306,52],[306,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 293,
                    "column": 16
                  },
                  "end": {
                    "line": 311,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  durch\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center ml0 inline-block mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-group flex-center inline-block ml0 mb0 mt0 mr0");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.doctor",["loc",[null,[296,26],[296,45]]]]],[],0,null,["loc",[null,[296,20],[300,27]]]],
                ["block","if",[["subexpr","and",[["get","item.payload.assistent",["loc",[null,[302,29],[302,51]]]],["get","item.payload.doctor",["loc",[null,[302,52],[302,71]]]]],[],["loc",[null,[302,24],[302,72]]]]],[],1,null,["loc",[null,[302,18],[302,84]]]],
                ["block","if",[["get","item.payload.assistent",["loc",[null,[304,30],[304,52]]]]],[],2,null,["loc",[null,[304,24],[309,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 312,
                    "column": 16
                  },
                  "end": {
                    "line": 312,
                    "column": 111
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" durch ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","ml5 is-highlighted");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","item.eltigaMandant",["loc",[null,[312,82],[312,104]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 313,
                    "column": 16
                  },
                  "end": {
                    "line": 315,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","ember-view");
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","label label-warning");
                var el3 = dom.createTextNode("In Behandlung");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 315,
                      "column": 16
                    },
                    "end": {
                      "line": 317,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ember-view");
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","label label-warning");
                  var el3 = dom.createTextNode("Im Wartezimmer");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 317,
                        "column": 16
                      },
                      "end": {
                        "line": 319,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","ember-view");
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","label label-warning");
                    var el3 = dom.createTextNode("Auf Station");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 319,
                          "column": 16
                        },
                        "end": {
                          "line": 321,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","ember-view");
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2,"class","label label-warning");
                      var el3 = dom.createTextNode("Zur Abrechnung");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 321,
                          "column": 16
                        },
                        "end": {
                          "line": 323,
                          "column": 16
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","ember-view");
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2,"class","label label-success");
                      var el3 = dom.createTextNode("Abgeschlossen");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 319,
                        "column": 16
                      },
                      "end": {
                        "line": 323,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[319,30],[319,49]]]],"ready_for_payment"],[],["loc",[null,[319,26],[319,70]]]]],[],0,1,["loc",[null,[319,16],[323,16]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 317,
                      "column": 16
                    },
                    "end": {
                      "line": 323,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[317,30],[317,49]]]],"in_station"],[],["loc",[null,[317,26],[317,63]]]]],[],0,1,["loc",[null,[317,16],[323,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 16
                  },
                  "end": {
                    "line": 323,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[315,30],[315,49]]]],"in_waitingroom"],[],["loc",[null,[315,26],[315,67]]]]],[],0,1,["loc",[null,[315,16],[323,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 291,
                  "column": 14
                },
                "end": {
                  "line": 325,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                Behandlung\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.payload.assistent",["loc",[null,[293,26],[293,48]]]],["get","item.payload.doctor",["loc",[null,[293,49],[293,68]]]]],[],["loc",[null,[293,22],[293,69]]]]],[],0,null,["loc",[null,[293,16],[311,23]]]],
              ["block","if",[["get","item.eltigaMandant",["loc",[null,[312,22],[312,40]]]]],[],1,null,["loc",[null,[312,16],[312,118]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[313,26],[313,45]]]],"in_treatment"],[],["loc",[null,[313,22],[313,61]]]]],[],2,3,["loc",[null,[313,16],[323,23]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 326,
                      "column": 16
                    },
                    "end": {
                      "line": 326,
                      "column": 64
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.title",["loc",[null,[326,42],[326,64]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 326,
                      "column": 64
                    },
                    "end": {
                      "line": 326,
                      "column": 90
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Stationsaufenthalt");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 326,
                      "column": 240
                    },
                    "end": {
                      "line": 326,
                      "column": 326
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label label-success");
                  var el2 = dom.createTextNode("Stationsaufenthalt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 326,
                      "column": 326
                    },
                    "end": {
                      "line": 326,
                      "column": 386
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label label-warning");
                  var el2 = dom.createTextNode("Auf Station");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 325,
                    "column": 14
                  },
                  "end": {
                    "line": 327,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","ember-view");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.title",["loc",[null,[326,22],[326,40]]]]],[],0,1,["loc",[null,[326,16],[326,97]]]],
                ["inline","time-format",[["get","item.payload.from",["loc",[null,[326,112],[326,129]]]],"DD. MM. YYYY HH:MM"],[],["loc",[null,[326,98],[326,152]]]],
                ["inline","time-format",[["get","item.payload.to",["loc",[null,[326,173],[326,188]]]],"DD. MM. YYYY HH:MM"],[],["loc",[null,[326,159],[326,211]]]],
                ["block","if",[["get","item.payload.closed",["loc",[null,[326,246],[326,265]]]]],[],2,3,["loc",[null,[326,240],[326,393]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 327,
                      "column": 14
                    },
                    "end": {
                      "line": 329,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                Erinnerung\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 329,
                        "column": 14
                      },
                      "end": {
                        "line": 331,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                Notiz\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 331,
                          "column": 14
                        },
                        "end": {
                          "line": 333,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                Labor\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 333,
                            "column": 14
                          },
                          "end": {
                            "line": 335,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                Bildgebung\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 335,
                              "column": 14
                            },
                            "end": {
                              "line": 337,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                Rechnung\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 337,
                                "column": 14
                              },
                              "end": {
                                "line": 339,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                Impfung\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 339,
                                  "column": 14
                                },
                                "end": {
                                  "line": 341,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                E-Mail Weiterleitung\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 341,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 343,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                eltiga Nachricht\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          var child1 = (function() {
                            var child0 = (function() {
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 343,
                                      "column": 14
                                    },
                                    "end": {
                                      "line": 345,
                                      "column": 14
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("                Dokument\n");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes() { return []; },
                                statements: [

                                ],
                                locals: [],
                                templates: []
                              };
                            }());
                            var child1 = (function() {
                              var child0 = (function() {
                                return {
                                  meta: {
                                    "revision": "Ember@1.13.5",
                                    "loc": {
                                      "source": null,
                                      "start": {
                                        "line": 345,
                                        "column": 14
                                      },
                                      "end": {
                                        "line": 347,
                                        "column": 14
                                      }
                                    },
                                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                                  },
                                  arity: 0,
                                  cachedFragment: null,
                                  hasRendered: false,
                                  buildFragment: function buildFragment(dom) {
                                    var el0 = dom.createDocumentFragment();
                                    var el1 = dom.createTextNode("                Termin\n              ");
                                    dom.appendChild(el0, el1);
                                    return el0;
                                  },
                                  buildRenderNodes: function buildRenderNodes() { return []; },
                                  statements: [

                                  ],
                                  locals: [],
                                  templates: []
                                };
                              }());
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 345,
                                      "column": 14
                                    },
                                    "end": {
                                      "line": 347,
                                      "column": 14
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createComment("");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                  var morphs = new Array(1);
                                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                  dom.insertBoundary(fragment, 0);
                                  dom.insertBoundary(fragment, null);
                                  return morphs;
                                },
                                statements: [
                                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[345,28],[345,37]]]],"app"],[],["loc",[null,[345,24],[345,44]]]]],[],0,null,["loc",[null,[345,14],[347,14]]]]
                                ],
                                locals: [],
                                templates: [child0]
                              };
                            }());
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 343,
                                    "column": 14
                                  },
                                  "end": {
                                    "line": 347,
                                    "column": 14
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                dom.insertBoundary(fragment, 0);
                                dom.insertBoundary(fragment, null);
                                return morphs;
                              },
                              statements: [
                                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[343,28],[343,37]]]],"doc"],[],["loc",[null,[343,24],[343,44]]]]],[],0,1,["loc",[null,[343,14],[347,14]]]]
                              ],
                              locals: [],
                              templates: [child0, child1]
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 341,
                                  "column": 14
                                },
                                "end": {
                                  "line": 347,
                                  "column": 14
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[341,28],[341,37]]]],"com"],[],["loc",[null,[341,24],[341,44]]]]],[],0,1,["loc",[null,[341,14],[347,14]]]]
                            ],
                            locals: [],
                            templates: [child0, child1]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 339,
                                "column": 14
                              },
                              "end": {
                                "line": 347,
                                "column": 14
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[339,28],[339,37]]]],"mail"],[],["loc",[null,[339,24],[339,45]]]]],[],0,1,["loc",[null,[339,14],[347,14]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 337,
                              "column": 14
                            },
                            "end": {
                              "line": 347,
                              "column": 14
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[337,28],[337,37]]]],"vacc"],[],["loc",[null,[337,24],[337,45]]]]],[],0,1,["loc",[null,[337,14],[347,14]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 335,
                            "column": 14
                          },
                          "end": {
                            "line": 347,
                            "column": 14
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[335,28],[335,37]]]],"invoice"],[],["loc",[null,[335,24],[335,48]]]]],[],0,1,["loc",[null,[335,14],[347,14]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 333,
                          "column": 14
                        },
                        "end": {
                          "line": 347,
                          "column": 14
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[333,28],[333,37]]]],"img"],[],["loc",[null,[333,24],[333,44]]]]],[],0,1,["loc",[null,[333,14],[347,14]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 331,
                        "column": 14
                      },
                      "end": {
                        "line": 347,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[331,28],[331,37]]]],"lab"],[],["loc",[null,[331,24],[331,44]]]]],[],0,1,["loc",[null,[331,14],[347,14]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 329,
                      "column": 14
                    },
                    "end": {
                      "line": 347,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[329,28],[329,37]]]],"not"],[],["loc",[null,[329,24],[329,44]]]]],[],0,1,["loc",[null,[329,14],[347,14]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 327,
                    "column": 14
                  },
                  "end": {
                    "line": 347,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[327,28],[327,37]]]],"rem"],[],["loc",[null,[327,24],[327,44]]]]],[],0,1,["loc",[null,[327,14],[347,14]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 325,
                  "column": 14
                },
                "end": {
                  "line": 347,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[325,28],[325,37]]]],"station"],[],["loc",[null,[325,24],[325,48]]]]],[],0,1,["loc",[null,[325,14],[347,14]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 350,
                    "column": 84
                  },
                  "end": {
                    "line": 350,
                    "column": 236
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.customer.firstname",["loc",[null,[350,166],[350,201]]]],
                ["content","item.patient.customer.lastname",["loc",[null,[350,202],[350,236]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 350,
                    "column": 253
                  },
                  "end": {
                    "line": 350,
                    "column": 346
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.patient.name",["loc",[null,[350,325],[350,346]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 350,
                    "column": 359
                  },
                  "end": {
                    "line": 350,
                    "column": 457
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
                dom.setAttribute(el1,"style","max-height: 18px");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 349,
                  "column": 12
                },
                "end": {
                  "line": 351,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","mtm10 mb10 d-flex align-items-center flex-gap-5");
              var el2 = dom.createTextNode("Kund:in: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" mit ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element70 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element70,1,1);
              morphs[1] = dom.createMorphAt(element70,3,3);
              morphs[2] = dom.createMorphAt(element70,5,5);
              return morphs;
            },
            statements: [
              ["block","link-to",["customers.edit",["get","item.patient.customer.id",["loc",[null,[350,112],[350,136]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[350,84],[350,248]]]],
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[350,280],[350,295]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[350,253],[350,358]]]],
              ["block","if",[["get","item.patient.etigaActive",["loc",[null,[350,365],[350,389]]]]],[],2,null,["loc",[null,[350,359],[350,464]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 356,
                    "column": 14
                  },
                  "end": {
                    "line": 358,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Kund:in\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 359,
                    "column": 14
                  },
                  "end": {
                    "line": 361,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 362,
                    "column": 14
                  },
                  "end": {
                    "line": 364,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                Von Praxis\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 354,
                  "column": 10
                },
                "end": {
                  "line": 369,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element68 = dom.childAt(fragment, [1]);
              var element69 = dom.childAt(fragment, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element68, 'class');
              morphs[1] = dom.createMorphAt(element68,1,1);
              morphs[2] = dom.createMorphAt(element68,2,2);
              morphs[3] = dom.createMorphAt(element68,3,3);
              morphs[4] = dom.createAttrMorph(element69, 'class');
              morphs[5] = dom.createUnsafeMorphAt(element69,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["message-sender mt20 ",["get","item.title",["loc",[null,[355,46],[355,56]]]]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[356,24],[356,34]]]],"msg_incoming"],[],["loc",[null,[356,20],[356,50]]]]],[],0,null,["loc",[null,[356,14],[358,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[359,24],[359,34]]]],"reminder"],[],["loc",[null,[359,20],[359,46]]]]],[],1,null,["loc",[null,[359,14],[361,21]]]],
              ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[362,24],[362,34]]]],"msg_outgoing"],[],["loc",[null,[362,20],[362,50]]]]],[],2,null,["loc",[null,[362,14],[364,21]]]],
              ["attribute","class",["concat",["message ",["get","item.title",["loc",[null,[366,34],[366,44]]]]]]],
              ["content","item.payload.text",["loc",[null,[367,14],[367,37]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 375,
                    "column": 12
                  },
                  "end": {
                    "line": 380,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element66 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element66);
                return morphs;
              },
              statements: [
                ["element","action",["openApp",["get","item.referenceId",["loc",[null,[376,36],[376,52]]]]],[],["loc",[null,[376,17],[376,54]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 370,
                  "column": 10
                },
                "end": {
                  "line": 382,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element67 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element67, [1]),1,1);
              morphs[1] = dom.createMorphAt(element67,3,3);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[373,16],[373,39]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[375,22],[375,30]]]]],[],0,null,["loc",[null,[375,12],[380,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 388,
                    "column": 12
                  },
                  "end": {
                    "line": 393,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element64 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element64);
                return morphs;
              },
              statements: [
                ["element","action",["editNote",["get","item.referenceId",["loc",[null,[389,37],[389,53]]]],["get","item.patient.id",["loc",[null,[389,54],[389,69]]]]],[],["loc",[null,[389,17],[389,71]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 383,
                  "column": 10
                },
                "end": {
                  "line": 395,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element65 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element65, [1]),1,1);
              morphs[1] = dom.createMorphAt(element65,3,3);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[386,16],[386,39]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[388,22],[388,30]]]]],[],0,null,["loc",[null,[388,12],[393,25]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child8 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 396,
                  "column": 10
                },
                "end": {
                  "line": 402,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[399,16],[399,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 403,
                  "column": 10
                },
                "end": {
                  "line": 409,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[406,16],[406,39]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child10 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 416,
                    "column": 18
                  },
                  "end": {
                    "line": 418,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label label-danger");
                var el2 = dom.createTextNode("Storniert");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 418,
                    "column": 18
                  },
                  "end": {
                    "line": 420,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","invoice-paymentstatus",[],["paymentStatus",["subexpr","@mut",[["get","item.payload.paymentStatus",["loc",[null,[419,58],[419,84]]]]],[],[]]],["loc",[null,[419,20],[419,86]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 425,
                      "column": 20
                    },
                    "end": {
                      "line": 427,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","entry.patient",["loc",[null,[426,25],[426,42]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 433,
                          "column": 57
                        },
                        "end": {
                          "line": 434,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[434,97],[434,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 435,
                          "column": 28
                        },
                        "end": {
                          "line": 435,
                          "column": 255
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","button-rework selected workflow");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[435,192],[435,219]]]],"Verabreicht","Angewandt"],[],["loc",[null,[435,172],[435,246]]]]],["loc",[null,[435,128],[435,248]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 431,
                        "column": 26
                      },
                      "end": {
                        "line": 440,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element58 = dom.childAt(fragment, [1]);
                    var element59 = dom.childAt(element58, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element59,0,0);
                    morphs[1] = dom.createMorphAt(element59,2,2);
                    morphs[2] = dom.createMorphAt(element58,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element58, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[433,34],[433,54]]]],
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[433,84],[433,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[433,57],[434,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[435,34],[435,49]]]]],[],1,null,["loc",[null,[435,28],[435,262]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[438,54],[438,67]]]]],[],[]]],["loc",[null,[437,34],[438,69]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 429,
                      "column": 24
                    },
                    "end": {
                      "line": 441,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Leistungen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.services",["loc",[null,[431,34],[431,48]]]]],[],0,null,["loc",[null,[431,26],[440,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 446,
                          "column": 57
                        },
                        "end": {
                          "line": 447,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[447,97],[447,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 448,
                          "column": 30
                        },
                        "end": {
                          "line": 448,
                          "column": 257
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","button-rework selected workflow");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[448,194],[448,221]]]],"Verabreicht","Angewandt"],[],["loc",[null,[448,174],[448,248]]]]],["loc",[null,[448,130],[448,250]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 444,
                        "column": 26
                      },
                      "end": {
                        "line": 453,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element56 = dom.childAt(fragment, [1]);
                    var element57 = dom.childAt(element56, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element57,0,0);
                    morphs[1] = dom.createMorphAt(element57,2,2);
                    morphs[2] = dom.createMorphAt(element56,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element56, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[446,34],[446,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[446,84],[446,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[446,57],[447,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[448,36],[448,51]]]]],[],1,null,["loc",[null,[448,30],[448,264]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[451,58],[451,71]]]]],[],[]]],["loc",[null,[450,36],[451,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 442,
                      "column": 24
                    },
                    "end": {
                      "line": 454,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Produkte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.products",["loc",[null,[444,34],[444,48]]]]],[],0,null,["loc",[null,[444,26],[453,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child3 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 459,
                          "column": 57
                        },
                        "end": {
                          "line": 460,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[460,97],[460,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 461,
                          "column": 30
                        },
                        "end": {
                          "line": 461,
                          "column": 257
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","button-rework selected workflow");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[461,194],[461,221]]]],"Verabreicht","Angewandt"],[],["loc",[null,[461,174],[461,248]]]]],["loc",[null,[461,130],[461,250]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 457,
                        "column": 26
                      },
                      "end": {
                        "line": 466,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element54 = dom.childAt(fragment, [1]);
                    var element55 = dom.childAt(element54, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element55,0,0);
                    morphs[1] = dom.createMorphAt(element55,2,2);
                    morphs[2] = dom.createMorphAt(element54,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element54, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[459,34],[459,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[459,84],[459,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[459,57],[460,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[461,36],[461,51]]]]],[],1,null,["loc",[null,[461,30],[461,264]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[464,58],[464,71]]]]],[],[]]],["loc",[null,[463,36],[464,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 455,
                      "column": 24
                    },
                    "end": {
                      "line": 467,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Medikamente:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.meds",["loc",[null,[457,34],[457,44]]]]],[],0,null,["loc",[null,[457,26],[466,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child4 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 472,
                          "column": 57
                        },
                        "end": {
                          "line": 473,
                          "column": 113
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[473,97],[473,113]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 474,
                          "column": 30
                        },
                        "end": {
                          "line": 474,
                          "column": 257
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","ml5 mr5");
                      var el2 = dom.createTextNode("-");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","button-rework selected workflow");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[474,194],[474,221]]]],"Verabreicht","Angewandt"],[],["loc",[null,[474,174],[474,248]]]]],["loc",[null,[474,130],[474,250]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 470,
                        "column": 26
                      },
                      "end": {
                        "line": 479,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" x ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","ml5 mr5");
                    var el3 = dom.createTextNode("-");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element52 = dom.childAt(fragment, [1]);
                    var element53 = dom.childAt(element52, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element53,0,0);
                    morphs[1] = dom.createMorphAt(element53,2,2);
                    morphs[2] = dom.createMorphAt(element52,3,3);
                    morphs[3] = dom.createMorphAt(dom.childAt(element52, [7]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","article.quantity",["loc",[null,[472,34],[472,54]]]],
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[472,84],[472,94]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[472,57],[473,125]]]],
                    ["block","if",[["get","article.applied",["loc",[null,[474,36],[474,51]]]]],[],1,null,["loc",[null,[474,30],[474,264]]]],
                    ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","article.price",["loc",[null,[477,58],[477,71]]]]],[],[]]],["loc",[null,[476,36],[477,73]]]]
                  ],
                  locals: ["article"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 468,
                      "column": 24
                    },
                    "end": {
                      "line": 480,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Verrechnete Ernährung:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.feeding",["loc",[null,[470,34],[470,47]]]]],[],0,null,["loc",[null,[470,26],[479,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child5 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 484,
                          "column": 28
                        },
                        "end": {
                          "line": 489,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element51 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element51);
                      morphs[1] = dom.createMorphAt(element51,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[487,89],[487,105]]]],["get","template.id",["loc",[null,[487,106],[487,117]]]],"invoice",["get","entry.patientId",["loc",[null,[487,128],[487,143]]]]],[],["loc",[null,[487,65],[487,145]]]],
                      ["content","template.name",["loc",[null,[487,146],[487,163]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 483,
                        "column": 26
                      },
                      "end": {
                        "line": 490,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[484,34],[484,47]]]]],[],0,null,["loc",[null,[484,28],[489,35]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 481,
                      "column": 24
                    },
                    "end": {
                      "line": 491,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","entry.templates",["loc",[null,[483,34],[483,49]]]]],[],0,null,["loc",[null,[483,26],[490,35]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 424,
                    "column": 18
                  },
                  "end": {
                    "line": 493,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","pl5 pt5 pb10");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element60 = dom.childAt(fragment, [2]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(element60,1,1);
                morphs[2] = dom.createMorphAt(element60,2,2);
                morphs[3] = dom.createMorphAt(element60,3,3);
                morphs[4] = dom.createMorphAt(element60,4,4);
                morphs[5] = dom.createMorphAt(element60,5,5);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.patientId",["loc",[null,[425,26],[425,41]]]]],[],0,null,["loc",[null,[425,20],[427,27]]]],
                ["block","if",[["get","entry.services",["loc",[null,[429,30],[429,44]]]]],[],1,null,["loc",[null,[429,24],[441,31]]]],
                ["block","if",[["get","entry.products",["loc",[null,[442,30],[442,44]]]]],[],2,null,["loc",[null,[442,24],[454,31]]]],
                ["block","if",[["get","entry.meds",["loc",[null,[455,30],[455,40]]]]],[],3,null,["loc",[null,[455,24],[467,31]]]],
                ["block","if",[["get","entry.feeding",["loc",[null,[468,30],[468,43]]]]],[],4,null,["loc",[null,[468,24],[480,31]]]],
                ["block","if",[["get","entry.templates",["loc",[null,[481,30],[481,45]]]]],[],5,null,["loc",[null,[481,24],[491,31]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3, child4, child5]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 497,
                      "column": 14
                    },
                    "end": {
                      "line": 500,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 496,
                    "column": 0
                  },
                  "end": {
                    "line": 501,
                    "column": 0
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[497,41],[497,57]]]]],["classNames","icon-button icon-button--default list-action-square line-action align-items-center"],0,null,["loc",[null,[497,14],[500,26]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 410,
                  "column": 10
                },
                "end": {
                  "line": 503,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","d-flex align-items-center");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createElement("b");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(",");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","mr10");
              var el5 = dom.createTextNode("Gesamtsumme: ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" EUR");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element61 = dom.childAt(fragment, [1]);
              var element62 = dom.childAt(element61, [1]);
              var element63 = dom.childAt(element62, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element63, [1, 0]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element63, [3]),1,1);
              morphs[2] = dom.createMorphAt(element63,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element62, [3]),1,1);
              morphs[4] = dom.createMorphAt(element61,3,3);
              return morphs;
            },
            statements: [
              ["content","item.payload.code",["loc",[null,[414,40],[414,61]]]],
              ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","item.payload.total",["loc",[null,[415,94],[415,112]]]]],[],[]]],["loc",[null,[415,50],[415,114]]]],
              ["block","if",[["subexpr","eq",[["get","item.payload.status",["loc",[null,[416,28],[416,47]]]],"canceled"],[],["loc",[null,[416,24],[416,59]]]]],[],0,1,["loc",[null,[416,18],[420,25]]]],
              ["block","each",[["get","item.payload.entries",["loc",[null,[424,26],[424,46]]]]],[],2,null,["loc",[null,[424,18],[493,27]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[496,10],[496,18]]]]],[],3,null,["loc",[null,[496,0],[501,11]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 508,
                      "column": 18
                    },
                    "end": {
                      "line": 510,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Eine eltiga App Benutzer:in hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" geteilt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element48 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element48);
                  morphs[1] = dom.createMorphAt(dom.childAt(element48, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[509,122],[509,138]]]]],[],["loc",[null,[509,98],[509,140]]]],
                  ["content","item.payload.filename",["loc",[null,[509,144],[509,169]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 510,
                      "column": 18
                    },
                    "end": {
                      "line": 512,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    Die Praxis hat das Dokument ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","is-highlighted cursor");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" hinzugefügt.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element47 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element47);
                  morphs[1] = dom.createMorphAt(dom.childAt(element47, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[511,105],[511,121]]]]],[],["loc",[null,[511,81],[511,123]]]],
                  ["content","item.payload.filename",["loc",[null,[511,127],[511,152]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 507,
                    "column": 16
                  },
                  "end": {
                    "line": 513,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.ctx",["loc",[null,[508,28],[508,44]]]],"eltiga"],[],["loc",[null,[508,24],[508,54]]]]],[],0,1,["loc",[null,[508,18],[512,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 513,
                    "column": 16
                  },
                  "end": {
                    "line": 515,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","item.payload.text",["loc",[null,[514,18],[514,41]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 504,
                  "column": 10
                },
                "end": {
                  "line": 522,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square line-action align-items-center");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"src","assets/images/icons/rework/view.svg");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element49 = dom.childAt(fragment, [1]);
              var element50 = dom.childAt(element49, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element49, [1]),1,1);
              morphs[1] = dom.createElementMorph(element50);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.filename",["loc",[null,[507,22],[507,43]]]]],[],0,1,["loc",[null,[507,16],[515,23]]]],
              ["element","action",["openDocument",["get","item.referenceId",["loc",[null,[517,41],[517,57]]]],["get","item.patient.id",["loc",[null,[517,58],[517,73]]]]],[],["loc",[null,[517,17],[517,75]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 523,
                  "column": 10
                },
                "end": {
                  "line": 531,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 22px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/vacc.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[528,16],[528,37]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 538,
                    "column": 16
                  },
                  "end": {
                    "line": 539,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 539,
                      "column": 16
                    },
                    "end": {
                      "line": 540,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 540,
                        "column": 16
                      },
                      "end": {
                        "line": 541,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 541,
                        "column": 16
                      },
                      "end": {
                        "line": 543,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","item.payload.orderTypeString",["loc",[null,[542,18],[542,50]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 540,
                      "column": 16
                    },
                    "end": {
                      "line": 543,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.payload.privateLab",["loc",[null,[540,26],[540,49]]]]],[],0,1,["loc",[null,[540,16],[543,16]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 539,
                    "column": 16
                  },
                  "end": {
                    "line": 543,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[539,30],[539,47]]]],"laboklin"],[],["loc",[null,[539,26],[539,59]]]]],[],0,1,["loc",[null,[539,16],[543,16]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 543,
                    "column": 24
                  },
                  "end": {
                    "line": 543,
                    "column": 132
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","block small");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","laboklin-reporttype",[],["type",["subexpr","@mut",[["get","form.reportType",["loc",[null,[543,108],[543,123]]]]],[],[]]],["loc",[null,[543,81],[543,125]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 548,
                    "column": 12
                  },
                  "end": {
                    "line": 553,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/trash.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element45 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element45);
                return morphs;
              },
              statements: [
                ["element","action",["openLab",["get","item.referenceId",["loc",[null,[549,36],[549,52]]]],["get","item.patient.id",["loc",[null,[549,53],[549,68]]]]],[],["loc",[null,[549,17],[549,70]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 532,
                  "column": 10
                },
                "end": {
                  "line": 555,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/lab.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element46 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element46,3,3);
              morphs[1] = dom.createMorphAt(element46,5,5);
              morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element46, [7]),1,1);
              morphs[3] = dom.createMorphAt(element46,9,9);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.payload.type",["loc",[null,[538,26],[538,43]]]],"vetconnect"],[],["loc",[null,[538,22],[538,57]]]]],[],0,1,["loc",[null,[538,16],[543,23]]]],
              ["block","if",[["get","item.payload.reportType",["loc",[null,[543,30],[543,53]]]]],[],2,null,["loc",[null,[543,24],[543,139]]]],
              ["content","item.payload.text",["loc",[null,[546,16],[546,39]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[548,22],[548,30]]]]],[],3,null,["loc",[null,[548,12],[553,25]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 556,
                  "column": 10
                },
                "end": {
                  "line": 568,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center img detail-box");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","mr10");
              dom.setAttribute(el2,"alt","");
              dom.setAttribute(el2,"style","width: 30px;");
              dom.setAttribute(el2,"src","assets/images/icons/history/img.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ms-1 fw-semibold");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","icon-button icon-button--default list-action-square line-action");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"src","assets/images/icons/rework/trash.svg");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element43 = dom.childAt(fragment, [1]);
              var element44 = dom.childAt(element43, [5]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element43, [3]),1,1);
              morphs[1] = dom.createElementMorph(element44);
              return morphs;
            },
            statements: [
              ["content","item.payload.text",["loc",[null,[561,16],[561,37]]]],
              ["element","action",["openLab",["get","item.referenceId",["loc",[null,[563,38],[563,54]]]]],[],["loc",[null,[563,19],[563,56]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 572,
                    "column": 16
                  },
                  "end": {
                    "line": 574,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Anamnese:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.medicalHistory",["loc",[null,[573,65],[573,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 575,
                    "column": 16
                  },
                  "end": {
                    "line": 577,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Symptome:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.sypmtoms",["loc",[null,[576,65],[576,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 578,
                    "column": 16
                  },
                  "end": {
                    "line": 580,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Diagnose:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.diagnosis",["loc",[null,[579,65],[579,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 581,
                    "column": 16
                  },
                  "end": {
                    "line": 583,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Therapie:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.therapy",["loc",[null,[582,65],[582,91]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 584,
                    "column": 16
                  },
                  "end": {
                    "line": 586,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Beratung:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.report",["loc",[null,[585,65],[585,90]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 589,
                      "column": 20
                    },
                    "end": {
                      "line": 589,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"style","font-style: italic;font-weight:normal");
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\"");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","item.payload.ratingComment",["loc",[null,[589,107],[589,137]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 587,
                    "column": 16
                  },
                  "end": {
                    "line": 591,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Feedback durch Kund:in:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element40 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element40,1,1);
                morphs[1] = dom.createMorphAt(element40,3,3);
                return morphs;
              },
              statements: [
                ["inline","rating-status",[],["rating",["subexpr","@mut",[["get","item.payload.rating",["loc",[null,[588,102],[588,121]]]]],[],[]],"classNames","inline-block"],["loc",[null,[588,79],[588,149]]]],
                ["block","if",[["get","item.payload.ratingComment",["loc",[null,[589,26],[589,52]]]]],[],0,null,["loc",[null,[589,20],[589,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 592,
                    "column": 16
                  },
                  "end": {
                    "line": 594,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Tierärztliche Leistungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.manualTreatment",["loc",[null,[593,81],[593,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 599,
                        "column": 49
                      },
                      "end": {
                        "line": 599,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[599,74],[599,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 599,
                          "column": 98
                        },
                        "end": {
                          "line": 600,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[600,138],[600,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 599,
                        "column": 90
                      },
                      "end": {
                        "line": 600,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[599,125],[599,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[599,98],[600,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 601,
                        "column": 24
                      },
                      "end": {
                        "line": 601,
                        "column": 251
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","button-rework selected workflow");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[601,188],[601,215]]]],"Verabreicht","Angewandt"],[],["loc",[null,[601,168],[601,242]]]]],["loc",[null,[601,124],[601,244]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 602,
                        "column": 24
                      },
                      "end": {
                        "line": 603,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element37 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element37);
                    morphs[1] = dom.createMorphAt(element37,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[602,121],[602,135]]]],["get","article.id",["loc",[null,[602,136],[602,146]]]]],[],["loc",[null,[602,93],[602,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[602,149],[602,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[602,283],[602,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 597,
                      "column": 18
                    },
                    "end": {
                      "line": 604,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element38 = dom.childAt(fragment, [1]);
                  var element39 = dom.childAt(element38, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element39,0,0);
                  morphs[1] = dom.createMorphAt(element39,2,2);
                  morphs[2] = dom.createMorphAt(element38,3,3);
                  morphs[3] = dom.createMorphAt(element38,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[599,26],[599,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[599,55],[599,72]]]]],[],0,1,["loc",[null,[599,49],[600,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[601,30],[601,45]]]]],[],2,null,["loc",[null,[601,24],[601,258]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[602,30],[602,44]]]]],[],3,null,["loc",[null,[602,24],[603,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 595,
                    "column": 16
                  },
                  "end": {
                    "line": 605,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Leistungen:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.services",["loc",[null,[597,26],[597,47]]]]],[],0,null,["loc",[null,[597,18],[604,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child8 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 610,
                        "column": 49
                      },
                      "end": {
                        "line": 610,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[610,74],[610,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 610,
                          "column": 98
                        },
                        "end": {
                          "line": 611,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[611,138],[611,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 610,
                        "column": 90
                      },
                      "end": {
                        "line": 611,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[610,125],[610,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[610,98],[611,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 612,
                        "column": 22
                      },
                      "end": {
                        "line": 612,
                        "column": 249
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","button-rework selected workflow");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[612,186],[612,213]]]],"Verabreicht","Angewandt"],[],["loc",[null,[612,166],[612,240]]]]],["loc",[null,[612,122],[612,242]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 613,
                        "column": 22
                      },
                      "end": {
                        "line": 614,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element34 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element34);
                    morphs[1] = dom.createMorphAt(element34,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[613,119],[613,133]]]],["get","article.id",["loc",[null,[613,134],[613,144]]]]],[],["loc",[null,[613,91],[613,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[613,147],[613,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[613,281],[613,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 608,
                      "column": 18
                    },
                    "end": {
                      "line": 615,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element35 = dom.childAt(fragment, [1]);
                  var element36 = dom.childAt(element35, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element36,0,0);
                  morphs[1] = dom.createMorphAt(element36,2,2);
                  morphs[2] = dom.createMorphAt(element35,3,3);
                  morphs[3] = dom.createMorphAt(element35,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[610,26],[610,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[610,55],[610,72]]]]],[],0,1,["loc",[null,[610,49],[611,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[612,28],[612,43]]]]],[],2,null,["loc",[null,[612,22],[612,256]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[613,28],[613,42]]]]],[],3,null,["loc",[null,[613,22],[614,25]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 606,
                    "column": 16
                  },
                  "end": {
                    "line": 616,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Produkte:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.products",["loc",[null,[608,26],[608,47]]]]],[],0,null,["loc",[null,[608,18],[615,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child9 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 621,
                        "column": 49
                      },
                      "end": {
                        "line": 621,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[621,74],[621,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 621,
                          "column": 98
                        },
                        "end": {
                          "line": 622,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[622,138],[622,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 621,
                        "column": 90
                      },
                      "end": {
                        "line": 622,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[621,125],[621,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[621,98],[622,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 623,
                        "column": 22
                      },
                      "end": {
                        "line": 623,
                        "column": 249
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","button-rework selected workflow");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[623,186],[623,213]]]],"Verabreicht","Angewandt"],[],["loc",[null,[623,166],[623,240]]]]],["loc",[null,[623,122],[623,242]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 624,
                        "column": 22
                      },
                      "end": {
                        "line": 625,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element31 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element31);
                    morphs[1] = dom.createMorphAt(element31,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[624,119],[624,133]]]],["get","article.id",["loc",[null,[624,134],[624,144]]]]],[],["loc",[null,[624,91],[624,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[624,147],[624,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[624,281],[624,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 619,
                      "column": 18
                    },
                    "end": {
                      "line": 626,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element32 = dom.childAt(fragment, [1]);
                  var element33 = dom.childAt(element32, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element33,0,0);
                  morphs[1] = dom.createMorphAt(element33,2,2);
                  morphs[2] = dom.createMorphAt(element32,3,3);
                  morphs[3] = dom.createMorphAt(element32,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[621,26],[621,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[621,55],[621,72]]]]],[],0,1,["loc",[null,[621,49],[622,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[623,28],[623,43]]]]],[],2,null,["loc",[null,[623,22],[623,256]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[624,28],[624,42]]]]],[],3,null,["loc",[null,[624,22],[625,29]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 617,
                    "column": 16
                  },
                  "end": {
                    "line": 627,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Medikamente:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.meds",["loc",[null,[619,26],[619,43]]]]],[],0,null,["loc",[null,[619,18],[626,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child10 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 632,
                        "column": 49
                      },
                      "end": {
                        "line": 632,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[632,74],[632,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 632,
                          "column": 98
                        },
                        "end": {
                          "line": 633,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[633,138],[633,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 632,
                        "column": 90
                      },
                      "end": {
                        "line": 633,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[632,125],[632,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[632,98],[633,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 634,
                        "column": 24
                      },
                      "end": {
                        "line": 634,
                        "column": 251
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","button-rework selected workflow");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[634,188],[634,215]]]],"Verabreicht","Angewandt"],[],["loc",[null,[634,168],[634,242]]]]],["loc",[null,[634,124],[634,244]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 635,
                        "column": 24
                      },
                      "end": {
                        "line": 638,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element28 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element28);
                    morphs[1] = dom.createMorphAt(element28,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[635,121],[635,135]]]],["get","article.id",["loc",[null,[635,136],[635,146]]]]],[],["loc",[null,[635,93],[635,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[635,149],[635,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[635,283],[635,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 630,
                      "column": 18
                    },
                    "end": {
                      "line": 639,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element29 = dom.childAt(fragment, [1]);
                  var element30 = dom.childAt(element29, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element30,0,0);
                  morphs[1] = dom.createMorphAt(element30,2,2);
                  morphs[2] = dom.createMorphAt(element29,3,3);
                  morphs[3] = dom.createMorphAt(element29,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[632,26],[632,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[632,55],[632,72]]]]],[],0,1,["loc",[null,[632,49],[633,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[634,30],[634,45]]]]],[],2,null,["loc",[null,[634,24],[634,258]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[635,30],[635,44]]]]],[],3,null,["loc",[null,[635,24],[638,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 628,
                    "column": 16
                  },
                  "end": {
                    "line": 640,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Ernährung:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.feeding",["loc",[null,[630,26],[630,46]]]]],[],0,null,["loc",[null,[630,18],[639,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child11 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 645,
                          "column": 22
                        },
                        "end": {
                          "line": 651,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element27 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element27);
                      morphs[1] = dom.createMorphAt(element27,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[648,83],[648,99]]]],["get","template.id",["loc",[null,[649,68],[649,79]]]],"treatment",["get","item.patient.id",["loc",[null,[649,92],[649,107]]]]],[],["loc",[null,[648,59],[649,109]]]],
                      ["content","template.name",["loc",[null,[649,110],[649,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 644,
                        "column": 20
                      },
                      "end": {
                        "line": 652,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[645,28],[645,41]]]]],[],0,null,["loc",[null,[645,22],[651,29]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 642,
                      "column": 18
                    },
                    "end": {
                      "line": 653,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.templates",["loc",[null,[644,28],[644,50]]]]],[],0,null,["loc",[null,[644,20],[652,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 660,
                          "column": 81
                        },
                        "end": {
                          "line": 660,
                          "column": 115
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","report.name",["loc",[null,[660,100],[660,115]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 660,
                          "column": 115
                        },
                        "end": {
                          "line": 660,
                          "column": 133
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Kein Titel");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 656,
                        "column": 20
                      },
                      "end": {
                        "line": 662,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                    dom.setAttribute(el2,"class","mr5");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element26 = dom.childAt(fragment, [1, 3]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element26);
                    morphs[1] = dom.createMorphAt(element26,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoReport",["get","item.referenceId",["loc",[null,[659,81],[659,97]]]],["get","report.id",["loc",[null,[660,68],[660,77]]]]],[],["loc",[null,[659,59],[660,79]]]],
                    ["block","if",[["get","report.name",["loc",[null,[660,87],[660,98]]]]],[],0,1,["loc",[null,[660,81],[660,140]]]]
                  ],
                  locals: ["report"],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 654,
                      "column": 18
                    },
                    "end": {
                      "line": 663,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Berichte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.reports",["loc",[null,[656,28],[656,48]]]]],[],0,null,["loc",[null,[656,20],[662,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 668,
                          "column": 97
                        },
                        "end": {
                          "line": 668,
                          "column": 131
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","visit.title",["loc",[null,[668,116],[668,131]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 668,
                          "column": 131
                        },
                        "end": {
                          "line": 668,
                          "column": 157
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Stationsaufenthalt");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 668,
                          "column": 216
                        },
                        "end": {
                          "line": 668,
                          "column": 282
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode(" - ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" Uhr");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","time-format",[["get","visit.to",["loc",[null,[668,249],[668,257]]]],"DD.MM.YYYY HH:MM"],[],["loc",[null,[668,235],[668,278]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 666,
                        "column": 20
                      },
                      "end": {
                        "line": 670,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","is-highlighted cursor");
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" (");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" Uhr ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element25 = dom.childAt(fragment, [1, 1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createElementMorph(element25);
                    morphs[1] = dom.createMorphAt(element25,1,1);
                    morphs[2] = dom.createMorphAt(element25,3,3);
                    morphs[3] = dom.createMorphAt(element25,5,5);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["gotoVisitDetails",["get","visit.id",["loc",[null,[668,85],[668,93]]]]],[],["loc",[null,[668,57],[668,95]]]],
                    ["block","if",[["get","visit.title",["loc",[null,[668,103],[668,114]]]]],[],0,1,["loc",[null,[668,97],[668,164]]]],
                    ["inline","time-format",[["get","visit.from",["loc",[null,[668,180],[668,190]]]],"DD.MM.YYYY HH:MM"],[],["loc",[null,[668,166],[668,211]]]],
                    ["block","if",[["get","visit.to",["loc",[null,[668,222],[668,230]]]]],[],2,null,["loc",[null,[668,216],[668,289]]]]
                  ],
                  locals: ["visit"],
                  templates: [child0, child1, child2]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 664,
                      "column": 18
                    },
                    "end": {
                      "line": 671,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Stationsaufenthalte:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.visits",["loc",[null,[666,28],[666,47]]]]],[],0,null,["loc",[null,[666,20],[670,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 641,
                    "column": 16
                  },
                  "end": {
                    "line": 672,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.templates",["loc",[null,[642,24],[642,46]]]]],[],0,null,["loc",[null,[642,18],[653,25]]]],
                ["block","if",[["get","item.payload.reports",["loc",[null,[654,24],[654,44]]]]],[],1,null,["loc",[null,[654,18],[663,25]]]],
                ["block","if",[["get","item.payload.visits",["loc",[null,[664,24],[664,43]]]]],[],2,null,["loc",[null,[664,18],[671,25]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child12 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 677,
                          "column": 18
                        },
                        "end": {
                          "line": 684,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","exp icon-button list-action-square line-action");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element23 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element23, 'style');
                      morphs[1] = dom.createMorphAt(element23,1,1);
                      return morphs;
                    },
                    statements: [
                      ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[678,58],[678,66]]]],"145px","100px"],[],["loc",[null,[678,37],[678,84]]]]]]],
                      ["inline","button-hide-app-with-confirm",[],["confirmed","hideTreatment","size","28","refId",["subexpr","@mut",[["get","item.referenceId",["loc",[null,[680,59],[680,75]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App Benutzer:innen wirklich ausblenden?"],["loc",[null,[679,22],[682,140]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 685,
                            "column": 20
                          },
                          "end": {
                            "line": 691,
                            "column": 20
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                        var el2 = dom.createTextNode("\n                        ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element22 = dom.childAt(fragment, [1]);
                        var morphs = new Array(3);
                        morphs[0] = dom.createAttrMorph(element22, 'style');
                        morphs[1] = dom.createElementMorph(element22);
                        morphs[2] = dom.createMorphAt(element22,1,1);
                        return morphs;
                      },
                      statements: [
                        ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[686,60],[686,68]]]],"145px","100px"],[],["loc",[null,[686,39],[686,86]]]]]]],
                        ["element","action",["unhideTreatment",["get","item.referenceId",["loc",[null,[686,115],[686,131]]]]],[],["loc",[null,[686,88],[686,133]]]],
                        ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[688,24],[689,82]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 691,
                            "column": 20
                          },
                          "end": {
                            "line": 697,
                            "column": 20
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square line-action");
                        var el2 = dom.createTextNode("\n                        ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element21 = dom.childAt(fragment, [1]);
                        var morphs = new Array(3);
                        morphs[0] = dom.createAttrMorph(element21, 'style');
                        morphs[1] = dom.createElementMorph(element21);
                        morphs[2] = dom.createMorphAt(element21,1,1);
                        return morphs;
                      },
                      statements: [
                        ["attribute","style",["concat",["right: ",["subexpr","css-bool-evaluator",[["get","showCopy",["loc",[null,[692,60],[692,68]]]],"145px","100px"],[],["loc",[null,[692,39],[692,86]]]]]]],
                        ["element","action",["unhideTreatmentNotAllowed",["get","item.referenceId",["loc",[null,[692,125],[692,141]]]]],[],["loc",[null,[692,88],[692,143]]]],
                        ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In eltiga App ausgeblendet"],["loc",[null,[694,24],[695,82]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 684,
                          "column": 18
                        },
                        "end": {
                          "line": 699,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","item.payload.eltigaShareable",["loc",[null,[685,26],[685,54]]]]],[],0,1,["loc",[null,[685,20],[697,27]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 701,
                          "column": 18
                        },
                        "end": {
                          "line": 706,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square line-action exp");
                      dom.setAttribute(el1,"content","Kopieren");
                      dom.setAttribute(el1,"style","right: 100px;");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element20 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element20);
                      morphs[1] = dom.createMorphAt(element20,1,1);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["copy",["get","item.referenceId",["loc",[null,[702,39],[702,55]]]]],[],["loc",[null,[702,23],[702,57]]]],
                      ["inline","button-copy-list",[],["showStroke",false,"size","42","color","#fff","classNames",""],["loc",[null,[704,22],[704,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 676,
                        "column": 16
                      },
                      "end": {
                        "line": 718,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                    dom.setAttribute(el1,"style","right: 55px;");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp line-action");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element24 = dom.childAt(fragment, [4]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                    morphs[2] = dom.createElementMorph(element24);
                    morphs[3] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","item.payload.eltigaShared",["loc",[null,[677,24],[677,49]]]]],[],0,1,["loc",[null,[677,18],[699,25]]]],
                    ["block","if",[["get","showCopy",["loc",[null,[701,24],[701,32]]]]],[],2,null,["loc",[null,[701,18],[706,25]]]],
                    ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[708,46],[708,62]]]]],[],["loc",[null,[708,21],[708,64]]]],
                    ["inline","button-delete-with-confirm-full",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[714,103],[714,107]]]]],[],[]],"confirmed","deleteTreatment","text","Wollen Sie diese Behandlung wirklich löschen?"],["loc",[null,[714,20],[716,103]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 675,
                      "column": 14
                    },
                    "end": {
                      "line": 719,
                      "column": 14
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","unless",[["get","item.payload.eltigaRecord",["loc",[null,[676,26],[676,51]]]]],[],0,null,["loc",[null,[676,16],[718,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 674,
                    "column": 12
                  },
                  "end": {
                    "line": 720,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","unless",[["get","item.eltigaRecord",["loc",[null,[675,24],[675,41]]]]],[],0,null,["loc",[null,[675,14],[719,25]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child13 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 732,
                      "column": 20
                    },
                    "end": {
                      "line": 736,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","dropdown-item");
                  var el2 = dom.createTextNode("\n                        Bericht erstellen\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element17 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element17);
                  return morphs;
                },
                statements: [
                  ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[733,54],[733,70]]]]],[],["loc",[null,[733,25],[733,72]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 736,
                        "column": 20
                      },
                      "end": {
                        "line": 740,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","dropdown-item");
                    var el2 = dom.createTextNode("\n                        Bericht(e) bearbeiten\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element16 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element16);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openReportChooser",["get","item.referenceId",["loc",[null,[737,54],[737,70]]]]],[],["loc",[null,[737,25],[737,72]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 736,
                      "column": 20
                    },
                    "end": {
                      "line": 740,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","gt",[["get","item.payload.reportCount",["loc",[null,[736,34],[736,58]]]],0],[],["loc",[null,[736,30],[736,61]]]]],[],0,null,["loc",[null,[736,20],[740,20]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 722,
                    "column": 14
                  },
                  "end": {
                    "line": 743,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dropdown");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ml5 icon-button icon-button--default list-action-square dropdown");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-haspopup","true");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                 ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createTextNode("Mehr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/more.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"aria-labelledby","dropdownMenuButton");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","dropdown-item");
                var el4 = dom.createTextNode("\n                      Bearbeiten\n                    ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                  ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element18 = dom.childAt(fragment, [1, 3]);
                var element19 = dom.childAt(element18, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element19);
                morphs[1] = dom.createMorphAt(element18,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["editTreatment",["get","item.referenceId",["loc",[null,[729,48],[729,64]]]]],[],["loc",[null,[729,23],[729,66]]]],
                ["block","if",[["subexpr","eq",[["get","item.payload.reportCount",["loc",[null,[732,30],[732,54]]]],0],[],["loc",[null,[732,26],[732,57]]]]],[],0,1,["loc",[null,[732,20],[740,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 569,
                  "column": 10
                },
                "end": {
                  "line": 748,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element41 = dom.childAt(fragment, [1]);
              var element42 = dom.childAt(element41, [1]);
              var morphs = new Array(14);
              morphs[0] = dom.createMorphAt(element42,1,1);
              morphs[1] = dom.createMorphAt(element42,2,2);
              morphs[2] = dom.createMorphAt(element42,3,3);
              morphs[3] = dom.createMorphAt(element42,4,4);
              morphs[4] = dom.createMorphAt(element42,5,5);
              morphs[5] = dom.createMorphAt(element42,6,6);
              morphs[6] = dom.createMorphAt(element42,7,7);
              morphs[7] = dom.createMorphAt(element42,8,8);
              morphs[8] = dom.createMorphAt(element42,9,9);
              morphs[9] = dom.createMorphAt(element42,10,10);
              morphs[10] = dom.createMorphAt(element42,11,11);
              morphs[11] = dom.createMorphAt(element42,12,12);
              morphs[12] = dom.createMorphAt(element41,3,3);
              morphs[13] = dom.createMorphAt(element41,5,5);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.medicalHistory",["loc",[null,[572,22],[572,49]]]]],[],0,null,["loc",[null,[572,16],[574,23]]]],
              ["block","if",[["get","item.payload.sypmtoms",["loc",[null,[575,22],[575,43]]]]],[],1,null,["loc",[null,[575,16],[577,23]]]],
              ["block","if",[["get","item.payload.diagnosis",["loc",[null,[578,22],[578,44]]]]],[],2,null,["loc",[null,[578,16],[580,23]]]],
              ["block","if",[["get","item.payload.therapy",["loc",[null,[581,22],[581,42]]]]],[],3,null,["loc",[null,[581,16],[583,23]]]],
              ["block","if",[["get","item.payload.report",["loc",[null,[584,22],[584,41]]]]],[],4,null,["loc",[null,[584,16],[586,23]]]],
              ["block","if",[["get","item.payload.rating",["loc",[null,[587,22],[587,41]]]]],[],5,null,["loc",[null,[587,16],[591,23]]]],
              ["block","if",[["get","item.payload.manualTreatment",["loc",[null,[592,22],[592,50]]]]],[],6,null,["loc",[null,[592,16],[594,23]]]],
              ["block","if",[["get","item.payload.services",["loc",[null,[595,22],[595,43]]]]],[],7,null,["loc",[null,[595,16],[605,23]]]],
              ["block","if",[["get","item.payload.products",["loc",[null,[606,22],[606,43]]]]],[],8,null,["loc",[null,[606,16],[616,23]]]],
              ["block","if",[["get","item.payload.meds",["loc",[null,[617,22],[617,39]]]]],[],9,null,["loc",[null,[617,16],[627,23]]]],
              ["block","if",[["get","item.payload.feeding",["loc",[null,[628,22],[628,42]]]]],[],10,null,["loc",[null,[628,16],[640,23]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[641,26],[641,43]]]]],[],11,null,["loc",[null,[641,16],[672,27]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[674,22],[674,30]]]]],[],12,null,["loc",[null,[674,12],[720,25]]]],
              ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[722,20],[722,28]]]]],[],13,null,["loc",[null,[722,14],[743,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13]
          };
        }());
        var child16 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 752,
                    "column": 16
                  },
                  "end": {
                    "line": 754,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","textDetails");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Dokumentation:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.payload.documentation",["loc",[null,[753,70],[753,102]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 760,
                        "column": 49
                      },
                      "end": {
                        "line": 760,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[760,74],[760,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 760,
                          "column": 98
                        },
                        "end": {
                          "line": 761,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[761,138],[761,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 760,
                        "column": 90
                      },
                      "end": {
                        "line": 761,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["services.edit",["get","article.id",["loc",[null,[760,125],[760,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[760,98],[761,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 762,
                        "column": 24
                      },
                      "end": {
                        "line": 762,
                        "column": 198
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[762,142],[762,169]]]],"Verabreicht","Angewandt"],[],["loc",[null,[762,122],[762,196]]]]],["loc",[null,[762,78],[762,198]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 763,
                        "column": 24
                      },
                      "end": {
                        "line": 764,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element11 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element11);
                    morphs[1] = dom.createMorphAt(element11,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[763,121],[763,135]]]],["get","article.id",["loc",[null,[763,136],[763,146]]]]],[],["loc",[null,[763,93],[763,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[763,149],[763,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[763,283],[763,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 758,
                      "column": 18
                    },
                    "end": {
                      "line": 765,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var element13 = dom.childAt(element12, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element13,0,0);
                  morphs[1] = dom.createMorphAt(element13,2,2);
                  morphs[2] = dom.createMorphAt(element12,3,3);
                  morphs[3] = dom.createMorphAt(element12,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[760,26],[760,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[760,55],[760,72]]]]],[],0,1,["loc",[null,[760,49],[761,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[762,30],[762,45]]]]],[],2,null,["loc",[null,[762,24],[762,205]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[763,30],[763,44]]]]],[],3,null,["loc",[null,[763,24],[764,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 756,
                    "column": 16
                  },
                  "end": {
                    "line": 766,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Leistungen:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.services",["loc",[null,[758,26],[758,47]]]]],[],0,null,["loc",[null,[758,18],[765,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 771,
                        "column": 49
                      },
                      "end": {
                        "line": 771,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[771,74],[771,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 771,
                          "column": 98
                        },
                        "end": {
                          "line": 772,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[772,138],[772,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 771,
                        "column": 90
                      },
                      "end": {
                        "line": 772,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[771,125],[771,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[771,98],[772,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 773,
                        "column": 22
                      },
                      "end": {
                        "line": 773,
                        "column": 196
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[773,140],[773,167]]]],"Verabreicht","Angewandt"],[],["loc",[null,[773,120],[773,194]]]]],["loc",[null,[773,76],[773,196]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 774,
                        "column": 22
                      },
                      "end": {
                        "line": 775,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element8);
                    morphs[1] = dom.createMorphAt(element8,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[774,119],[774,133]]]],["get","article.id",["loc",[null,[774,134],[774,144]]]]],[],["loc",[null,[774,91],[774,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[774,147],[774,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[774,281],[774,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 769,
                      "column": 18
                    },
                    "end": {
                      "line": 776,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var element10 = dom.childAt(element9, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element10,0,0);
                  morphs[1] = dom.createMorphAt(element10,2,2);
                  morphs[2] = dom.createMorphAt(element9,3,3);
                  morphs[3] = dom.createMorphAt(element9,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[771,26],[771,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[771,55],[771,72]]]]],[],0,1,["loc",[null,[771,49],[772,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[773,28],[773,43]]]]],[],2,null,["loc",[null,[773,22],[773,203]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[774,28],[774,42]]]]],[],3,null,["loc",[null,[774,22],[775,25]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 767,
                    "column": 16
                  },
                  "end": {
                    "line": 777,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Produkte:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.products",["loc",[null,[769,26],[769,47]]]]],[],0,null,["loc",[null,[769,18],[776,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 782,
                        "column": 49
                      },
                      "end": {
                        "line": 782,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[782,74],[782,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 782,
                          "column": 98
                        },
                        "end": {
                          "line": 783,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[783,138],[783,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 782,
                        "column": 90
                      },
                      "end": {
                        "line": 783,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[782,125],[782,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[782,98],[783,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 784,
                        "column": 22
                      },
                      "end": {
                        "line": 784,
                        "column": 196
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[784,140],[784,167]]]],"Verabreicht","Angewandt"],[],["loc",[null,[784,120],[784,194]]]]],["loc",[null,[784,76],[784,196]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 785,
                        "column": 22
                      },
                      "end": {
                        "line": 786,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element5);
                    morphs[1] = dom.createMorphAt(element5,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[785,119],[785,133]]]],["get","article.id",["loc",[null,[785,134],[785,144]]]]],[],["loc",[null,[785,91],[785,146]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[785,147],[785,270]]]],
                    ["content","article.dosingInfo",["loc",[null,[785,281],[785,303]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 780,
                      "column": 18
                    },
                    "end": {
                      "line": 787,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var element7 = dom.childAt(element6, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element7,0,0);
                  morphs[1] = dom.createMorphAt(element7,2,2);
                  morphs[2] = dom.createMorphAt(element6,3,3);
                  morphs[3] = dom.createMorphAt(element6,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[782,26],[782,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[782,55],[782,72]]]]],[],0,1,["loc",[null,[782,49],[783,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[784,28],[784,43]]]]],[],2,null,["loc",[null,[784,22],[784,203]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[785,28],[785,42]]]]],[],3,null,["loc",[null,[785,22],[786,29]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 778,
                    "column": 16
                  },
                  "end": {
                    "line": 788,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Medikamente:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.meds",["loc",[null,[780,26],[780,43]]]]],[],0,null,["loc",[null,[780,18],[787,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 793,
                        "column": 49
                      },
                      "end": {
                        "line": 793,
                        "column": 90
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","article.name",["loc",[null,[793,74],[793,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 793,
                          "column": 98
                        },
                        "end": {
                          "line": 794,
                          "column": 154
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","article.name",["loc",[null,[794,138],[794,154]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 793,
                        "column": 90
                      },
                      "end": {
                        "line": 794,
                        "column": 166
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["products.edit",["get","article.id",["loc",[null,[793,125],[793,135]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[793,98],[794,166]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 795,
                        "column": 24
                      },
                      "end": {
                        "line": 795,
                        "column": 198
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-applied-selected",[],["size","28","content",["subexpr","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[795,142],[795,169]]]],"Verabreicht","Angewandt"],[],["loc",[null,[795,122],[795,196]]]]],["loc",[null,[795,78],[795,198]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 796,
                        "column": 24
                      },
                      "end": {
                        "line": 799,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","ml5 mr5");
                    var el2 = dom.createTextNode("-");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","mr5");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n\n\n                        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [3]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element2);
                    morphs[1] = dom.createMorphAt(element2,0,0);
                    morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openSlopingInfos",["get","article.lineId",["loc",[null,[796,121],[796,135]]]],["get","article.id",["loc",[null,[796,136],[796,146]]]]],[],["loc",[null,[796,93],[796,148]]]],
                    ["inline","button-sloping",[],["color","#3B6182","size","28","content","Dosierungsinformation bearbeiten","selected",true,"cssClasses","selected"],["loc",[null,[796,149],[796,272]]]],
                    ["content","article.dosingInfo",["loc",[null,[796,283],[796,305]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 791,
                      "column": 18
                    },
                    "end": {
                      "line": 800,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" x ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var element4 = dom.childAt(element3, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element4,0,0);
                  morphs[1] = dom.createMorphAt(element4,2,2);
                  morphs[2] = dom.createMorphAt(element3,3,3);
                  morphs[3] = dom.createMorphAt(element3,5,5);
                  return morphs;
                },
                statements: [
                  ["content","article.quantity",["loc",[null,[793,26],[793,46]]]],
                  ["block","if",[["get","item.eltigaRecord",["loc",[null,[793,55],[793,72]]]]],[],0,1,["loc",[null,[793,49],[794,173]]]],
                  ["block","if",[["get","article.applied",["loc",[null,[795,30],[795,45]]]]],[],2,null,["loc",[null,[795,24],[795,205]]]],
                  ["block","if",[["get","article.dosing",["loc",[null,[796,30],[796,44]]]]],[],3,null,["loc",[null,[796,24],[799,31]]]]
                ],
                locals: ["article"],
                templates: [child0, child1, child2, child3]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 789,
                    "column": 16
                  },
                  "end": {
                    "line": 801,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","block mb5 mt10 small");
                var el2 = dom.createTextNode("Erhaltene Ernährung:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.payload.feeding",["loc",[null,[791,26],[791,46]]]]],[],0,null,["loc",[null,[791,18],[800,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 806,
                          "column": 22
                        },
                        "end": {
                          "line": 812,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","align-items-center mb5 mt5");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("img");
                      dom.setAttribute(el2,"src","assets/images/icons/filetypes/pdf.svg");
                      dom.setAttribute(el2,"class","mr5");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","is-highlighted cursor");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [1, 3]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element1);
                      morphs[1] = dom.createMorphAt(element1,0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["gotoTemplate",["get","item.referenceId",["loc",[null,[809,83],[809,99]]]],["get","template.id",["loc",[null,[810,68],[810,79]]]],"treatment",["get","item.patient.id",["loc",[null,[810,92],[810,107]]]]],[],["loc",[null,[809,59],[810,109]]]],
                      ["content","template.name",["loc",[null,[810,110],[810,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 805,
                        "column": 20
                      },
                      "end": {
                        "line": 813,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","template.name",["loc",[null,[806,28],[806,41]]]]],[],0,null,["loc",[null,[806,22],[812,29]]]]
                  ],
                  locals: ["template"],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 803,
                      "column": 18
                    },
                    "end": {
                      "line": 814,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","block mb5 mt10 small");
                  var el2 = dom.createTextNode("Vorlagen:");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","each",[["get","item.payload.templates",["loc",[null,[805,28],[805,50]]]]],[],0,null,["loc",[null,[805,20],[813,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 802,
                    "column": 16
                  },
                  "end": {
                    "line": 815,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.payload.templates",["loc",[null,[803,24],[803,46]]]]],[],0,null,["loc",[null,[803,18],[814,25]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 817,
                    "column": 14
                  },
                  "end": {
                    "line": 822,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square exp line-action");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["gotoVisitDetails",["get","item.referenceId",["loc",[null,[818,47],[818,63]]]]],[],["loc",[null,[818,19],[818,65]]]],
                ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[820,18],[820,73]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 749,
                  "column": 10
                },
                "end": {
                  "line": 826,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","d-flex align-items-center detail-box treatment");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var element15 = dom.childAt(element14, [1]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(element15,1,1);
              morphs[1] = dom.createMorphAt(element15,3,3);
              morphs[2] = dom.createMorphAt(element15,4,4);
              morphs[3] = dom.createMorphAt(element15,5,5);
              morphs[4] = dom.createMorphAt(element15,6,6);
              morphs[5] = dom.createMorphAt(element15,7,7);
              morphs[6] = dom.createMorphAt(element14,3,3);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.payload.documentation",["loc",[null,[752,22],[752,48]]]]],[],0,null,["loc",[null,[752,16],[754,23]]]],
              ["block","if",[["get","item.payload.services",["loc",[null,[756,22],[756,43]]]]],[],1,null,["loc",[null,[756,16],[766,23]]]],
              ["block","if",[["get","item.payload.products",["loc",[null,[767,22],[767,43]]]]],[],2,null,["loc",[null,[767,16],[777,23]]]],
              ["block","if",[["get","item.payload.meds",["loc",[null,[778,22],[778,39]]]]],[],3,null,["loc",[null,[778,16],[788,23]]]],
              ["block","if",[["get","item.payload.feeding",["loc",[null,[789,22],[789,42]]]]],[],4,null,["loc",[null,[789,16],[801,23]]]],
              ["block","unless",[["get","item.eltigaRecord",["loc",[null,[802,26],[802,43]]]]],[],5,null,["loc",[null,[802,16],[815,27]]]],
              ["block","unless",[["get","readOnly",["loc",[null,[817,24],[817,32]]]]],[],6,null,["loc",[null,[817,14],[822,25]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 258,
                "column": 4
              },
              "end": {
                "line": 830,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","timeline-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-line");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pe-3 mb10");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","d-flex align-items-center mt-1 fs-6");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","me-2 fs-4");
            var el6 = dom.createElement("b");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(", ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                um ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                Uhr");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","tags ml20");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","headline mt5 flex-center flex-gap-5");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element76 = dom.childAt(fragment, [1]);
            var element77 = dom.childAt(element76, [3]);
            var element78 = dom.childAt(element77, [1, 1]);
            var element79 = dom.childAt(element76, [5]);
            var element80 = dom.childAt(element79, [1]);
            var element81 = dom.childAt(element80, [1]);
            var element82 = dom.childAt(element81, [1, 0]);
            var element83 = dom.childAt(element81, [3]);
            var morphs = new Array(22);
            morphs[0] = dom.createAttrMorph(element77, 'class');
            morphs[1] = dom.createAttrMorph(element78, 'src');
            morphs[2] = dom.createAttrMorph(element79, 'class');
            morphs[3] = dom.createMorphAt(element82,0,0);
            morphs[4] = dom.createMorphAt(element82,2,2);
            morphs[5] = dom.createMorphAt(element82,4,4);
            morphs[6] = dom.createMorphAt(element83,1,1);
            morphs[7] = dom.createMorphAt(element83,2,2);
            morphs[8] = dom.createMorphAt(dom.childAt(element80, [3]),1,1);
            morphs[9] = dom.createMorphAt(element80,5,5);
            morphs[10] = dom.createMorphAt(element79,3,3);
            morphs[11] = dom.createMorphAt(element79,4,4);
            morphs[12] = dom.createMorphAt(element79,5,5);
            morphs[13] = dom.createMorphAt(element79,6,6);
            morphs[14] = dom.createMorphAt(element79,7,7);
            morphs[15] = dom.createMorphAt(element79,8,8);
            morphs[16] = dom.createMorphAt(element79,9,9);
            morphs[17] = dom.createMorphAt(element79,10,10);
            morphs[18] = dom.createMorphAt(element79,11,11);
            morphs[19] = dom.createMorphAt(element79,12,12);
            morphs[20] = dom.createMorphAt(element79,13,13);
            morphs[21] = dom.createMorphAt(element79,14,14);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["timeline-icon color-",["get","item.type",["loc",[null,[262,42],[262,51]]]]]]],
            ["attribute","src",["concat",["/assets/images/icons/rework/",["get","item.type",["loc",[null,[264,52],[264,61]]]],"-dark.svg"]]],
            ["attribute","class",["concat",["timeline-content ",["get","item.type",["loc",[null,[267,39],[267,48]]]]," mb-10 mt-n1"]]],
            ["inline","get-weekday",[["get","item.date",["loc",[null,[270,54],[270,63]]]]],[],["loc",[null,[270,40],[270,65]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[270,81],[270,90]]]],"DD. MM. YYYY"],[],["loc",[null,[270,67],[270,107]]]],
            ["inline","time-format",[["get","item.date",["loc",[null,[271,33],[271,42]]]],"HH:mm"],[],["loc",[null,[271,19],[271,52]]]],
            ["block","each",[["get","item.tags",["loc",[null,[274,24],[274,33]]]]],[],0,null,["loc",[null,[274,16],[276,25]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[277,26],[277,35]]]],"lab"],[],["loc",[null,[277,22],[277,42]]]]],[],1,null,["loc",[null,[277,16],[285,23]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[291,24],[291,33]]]],"treat"],[],["loc",[null,[291,20],[291,42]]]]],[],2,3,["loc",[null,[291,14],[347,21]]]],
            ["block","if",[["subexpr","and",[["get","item.patient",["loc",[null,[349,23],[349,35]]]],["get","showPatientInfo",["loc",[null,[349,36],[349,51]]]]],[],["loc",[null,[349,18],[349,52]]]]],[],4,null,["loc",[null,[349,12],[351,19]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[354,20],[354,29]]]],"com"],[],["loc",[null,[354,16],[354,36]]]]],[],5,null,["loc",[null,[354,10],[369,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[370,20],[370,29]]]],"app"],[],["loc",[null,[370,16],[370,36]]]]],[],6,null,["loc",[null,[370,10],[382,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[383,20],[383,29]]]],"not"],[],["loc",[null,[383,16],[383,36]]]]],[],7,null,["loc",[null,[383,10],[395,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[396,20],[396,29]]]],"rem"],[],["loc",[null,[396,16],[396,36]]]]],[],8,null,["loc",[null,[396,10],[402,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[403,20],[403,29]]]],"mail"],[],["loc",[null,[403,16],[403,37]]]]],[],9,null,["loc",[null,[403,10],[409,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[410,20],[410,29]]]],"invoice"],[],["loc",[null,[410,16],[410,40]]]]],[],10,null,["loc",[null,[410,10],[503,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[504,20],[504,29]]]],"doc"],[],["loc",[null,[504,16],[504,36]]]]],[],11,null,["loc",[null,[504,10],[522,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[523,20],[523,29]]]],"vacc"],[],["loc",[null,[523,16],[523,37]]]]],[],12,null,["loc",[null,[523,10],[531,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[532,20],[532,29]]]],"lab"],[],["loc",[null,[532,16],[532,36]]]]],[],13,null,["loc",[null,[532,10],[555,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[556,20],[556,29]]]],"img"],[],["loc",[null,[556,16],[556,36]]]]],[],14,null,["loc",[null,[556,10],[568,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[569,20],[569,29]]]],"treat"],[],["loc",[null,[569,16],[569,38]]]]],[],15,null,["loc",[null,[569,10],[748,17]]]],
            ["block","if",[["subexpr","eq",[["get","item.type",["loc",[null,[749,20],[749,29]]]],"station"],[],["loc",[null,[749,16],[749,40]]]]],[],16,null,["loc",[null,[749,10],[826,17]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 256,
              "column": 4
            },
            "end": {
              "line": 834,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","items",["loc",[null,[258,12],[258,17]]]]],[],0,null,["loc",[null,[258,4],[830,13]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[832,29],[832,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[832,6],[832,53]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 907,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/patient-activities.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","patientActivityWrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","mb30 mt20 grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter standalone mb-20 ms-0");
        dom.setAttribute(el3,"style","margin-right: -10px");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"style","padding-right: 9px");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","");
        dom.setAttribute(el6,"style","line-height: 30px");
        var el7 = dom.createTextNode("Alle");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small  l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/treat-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Behandlungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/invoice-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Rechnungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/appointment-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Termine");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/reminder-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Erinnerungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/note-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Notizen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/template-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Dokumente");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/lab-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Labor");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/picture-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Bildgebung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/mail-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        E-Mail");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/chat-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        eltiga Chat");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col gutter--small l-12/24 l-6/24@desk  l-4/24@desk-large");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","/assets/images/icons/rework/vacc-dark.svg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        Impfungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-7/24@desk-wide l-12/24@desk l-24/24 gutter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework mb-0");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","placeholder");
        var el7 = dom.createTextNode("Zeitraum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","switchFiltersJournal");
        dom.setAttribute(el6,"class","filter-toggle-rework");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/rework/filter.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter ms-0 me-0 mt-10 text-right col l-17/24@desk-wide l-12/24@desk l-24/24 pl5");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","timeline");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('body').delegate('#pickDateJournal', \"focusin\", function() {\n      $(this).daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Alle\": [moment('2016-01-01T00:00:00.000'), moment()],\n        \"Heute\": [moment().startOf('day'), moment().endOf('day')],\n        \"Gestern\": [moment().startOf('day').subtract(1, 'days'), moment().subtract(1, 'days').endOf('day')],\n        \"Diese Woche\": [moment().startOf('isoWeek'), moment().startOf('isoWeek').add(7,'days')],\n        \"Letzten 7 Tage\": [moment().subtract(6, 'days'), moment()],\n        \"Dieses Monat\": [moment().startOf('month'), moment().endOf('month')],\n        \"Letzten 30 Tage\": [moment().subtract(30, 'days'), moment()],\n        \"Letzten 6 Monate\": [moment().subtract(6, 'months'), moment()],\n        \"Dieses Jahr\": [moment().startOf('year'), moment()],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY HH:mm\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false,\n      timePicker: true,\n      timePicker24Hour: true\n      });\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element85 = dom.childAt(fragment, [0]);
        var element86 = dom.childAt(element85, [1]);
        var element87 = dom.childAt(element86, [1]);
        var element88 = dom.childAt(element87, [1, 1]);
        var element89 = dom.childAt(element87, [3, 1]);
        var element90 = dom.childAt(element87, [5, 1]);
        var element91 = dom.childAt(element87, [7, 1]);
        var element92 = dom.childAt(element87, [9, 1]);
        var element93 = dom.childAt(element87, [11, 1]);
        var element94 = dom.childAt(element87, [13, 1]);
        var element95 = dom.childAt(element87, [15, 1]);
        var element96 = dom.childAt(element87, [17, 1]);
        var element97 = dom.childAt(element87, [19, 1]);
        var element98 = dom.childAt(element87, [21, 1]);
        var element99 = dom.childAt(element87, [23, 1]);
        var element100 = dom.childAt(element86, [3, 1]);
        var morphs = new Array(32);
        morphs[0] = dom.createAttrMorph(element88, 'class');
        morphs[1] = dom.createElementMorph(element88);
        morphs[2] = dom.createAttrMorph(element89, 'class');
        morphs[3] = dom.createElementMorph(element89);
        morphs[4] = dom.createAttrMorph(element90, 'class');
        morphs[5] = dom.createElementMorph(element90);
        morphs[6] = dom.createAttrMorph(element91, 'class');
        morphs[7] = dom.createElementMorph(element91);
        morphs[8] = dom.createAttrMorph(element92, 'class');
        morphs[9] = dom.createElementMorph(element92);
        morphs[10] = dom.createAttrMorph(element93, 'class');
        morphs[11] = dom.createElementMorph(element93);
        morphs[12] = dom.createAttrMorph(element94, 'class');
        morphs[13] = dom.createElementMorph(element94);
        morphs[14] = dom.createAttrMorph(element95, 'class');
        morphs[15] = dom.createElementMorph(element95);
        morphs[16] = dom.createAttrMorph(element96, 'class');
        morphs[17] = dom.createElementMorph(element96);
        morphs[18] = dom.createAttrMorph(element97, 'class');
        morphs[19] = dom.createElementMorph(element97);
        morphs[20] = dom.createAttrMorph(element98, 'class');
        morphs[21] = dom.createElementMorph(element98);
        morphs[22] = dom.createAttrMorph(element99, 'class');
        morphs[23] = dom.createElementMorph(element99);
        morphs[24] = dom.createMorphAt(dom.childAt(element100, [1]),1,1);
        morphs[25] = dom.createMorphAt(dom.childAt(element100, [3]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element86, [5]),1,1);
        morphs[27] = dom.createMorphAt(element86,7,7);
        morphs[28] = dom.createMorphAt(dom.childAt(element85, [3]),1,1);
        morphs[29] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[30] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[31] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["selectable-item text-center align-items-center color-all ",["subexpr","css-bool-evaluator",[["get","typeAllSelected",["loc",[null,[6,98],[6,113]]]],"selected-style",""],[],["loc",[null,[6,77],[7,91]]]]]]],
        ["element","action",["toggleTypeFilter","all"],[],["loc",[null,[7,93],[8,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-treat ",["subexpr","css-bool-evaluator",[["get","typeTreatmentSelected",["loc",[null,[11,88],[11,109]]]],"selected-style",""],[],["loc",[null,[11,67],[12,110]]]]]]],
        ["element","action",["toggleTypeFilter","treat"],[],["loc",[null,[12,112],[13,36]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-invoice ",["subexpr","css-bool-evaluator",[["get","typeInvoiceSelected",["loc",[null,[18,90],[18,109]]]],"selected-style",""],[],["loc",[null,[18,69],[20,95]]]]]]],
        ["element","action",["toggleTypeFilter","invoice"],[],["loc",[null,[20,97],[21,38]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-app ",["subexpr","css-bool-evaluator",[["get","typeAppSelected",["loc",[null,[26,86],[26,101]]]],"selected-style",""],[],["loc",[null,[26,65],[27,91]]]]]]],
        ["element","action",["toggleTypeFilter","app"],[],["loc",[null,[27,93],[28,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-rem ",["subexpr","css-bool-evaluator",[["get","typeRemSelected",["loc",[null,[33,86],[33,101]]]],"selected-style",""],[],["loc",[null,[33,65],[34,91]]]]]]],
        ["element","action",["toggleTypeFilter","rem"],[],["loc",[null,[34,93],[35,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-not ",["subexpr","css-bool-evaluator",[["get","typeNotSelected",["loc",[null,[40,86],[40,101]]]],"selected-style",""],[],["loc",[null,[40,65],[41,91]]]]]]],
        ["element","action",["toggleTypeFilter","not"],[],["loc",[null,[41,93],[42,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-doc ",["subexpr","css-bool-evaluator",[["get","typeDocSelected",["loc",[null,[47,86],[47,101]]]],"selected-style",""],[],["loc",[null,[47,65],[48,91]]]]]]],
        ["element","action",["toggleTypeFilter","doc"],[],["loc",[null,[48,93],[49,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-lab ",["subexpr","css-bool-evaluator",[["get","typeLabSelected",["loc",[null,[54,86],[54,101]]]],"selected-style",""],[],["loc",[null,[54,65],[55,91]]]]]]],
        ["element","action",["toggleTypeFilter","lab"],[],["loc",[null,[55,93],[56,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-img ",["subexpr","css-bool-evaluator",[["get","typeImgSelected",["loc",[null,[61,86],[61,101]]]],"selected-style",""],[],["loc",[null,[61,65],[62,91]]]]]]],
        ["element","action",["toggleTypeFilter","img"],[],["loc",[null,[62,93],[63,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-mail ",["subexpr","css-bool-evaluator",[["get","typeMailSelected",["loc",[null,[68,87],[68,103]]]],"selected-style",""],[],["loc",[null,[68,66],[69,92]]]]]]],
        ["element","action",["toggleTypeFilter","mail"],[],["loc",[null,[69,94],[70,35]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-com ",["subexpr","css-bool-evaluator",[["get","typeComSelected",["loc",[null,[75,86],[75,101]]]],"selected-style",""],[],["loc",[null,[75,65],[76,91]]]]]]],
        ["element","action",["toggleTypeFilter","com"],[],["loc",[null,[76,93],[77,34]]]],
        ["attribute","class",["concat",["selectable-item align-items-center color-vacc ",["subexpr","css-bool-evaluator",[["get","typeVaccSelected",["loc",[null,[82,87],[82,103]]]],"selected-style",""],[],["loc",[null,[82,66],[83,92]]]]]]],
        ["element","action",["toggleTypeFilter","vacc"],[],["loc",[null,[83,94],[84,35]]]],
        ["inline","input",[],["id","pickDateJournal","value",["subexpr","@mut",[["get","pickDateJournal",["loc",[null,[93,45],[93,60]]]]],[],[]],"class","","name","pickDateJournal"],["loc",[null,[93,10],[94,43]]]],
        ["inline","input",[],["type","checkbox","id","switchFiltersJournal","classNames","switch","name","switchFiltersJournal","checked",["subexpr","@mut",[["get","showFilters",["loc",[null,[100,26],[100,37]]]]],[],[]]],["loc",[null,[99,10],[100,39]]]],
        ["block","each",[["get","availableTags",["loc",[null,[112,14],[112,27]]]]],[],0,null,["loc",[null,[112,6],[115,15]]]],
        ["block","if",[["get","showFilters",["loc",[null,[118,10],[118,21]]]]],[],1,null,["loc",[null,[118,4],[249,11]]]],
        ["block","if",[["get","loading",["loc",[null,[254,10],[254,17]]]]],[],2,3,["loc",[null,[254,4],[834,11]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","openWizard","openReportWizard","delete","deleteReport","prefix","-journal","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooserActivities",["loc",[null,[843,17],[843,56]]]]],[],[]]],["loc",[null,[838,0],[843,58]]]],
        ["inline","treatment-report-wizzard-journal",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzardJournal",["loc",[null,[847,17],[847,46]]]]],[],[]]],["loc",[null,[845,0],[847,48]]]],
        ["content","eltiga-sharenotallowed-panel",["loc",[null,[849,0],[849,32]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
define('jason-frontend/routes/sale/etigacampaigns', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('patientGenders', this.store.find('patient-gender'));

      this.applyFilter();
    },
    actions: {
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      submitEtiga: function submitEtiga(id) {
        var self = this;
        var controller = this.get('controller');

        setTimeout(function () {
          self.applyFilter();
        }, 2000);

        $.ajax({
          url: "/api/etigaCampaigns/" + id + "/submit",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Kampagne', 'wird verarbeitet');
        }, function (error) {});
      },
      'delete': function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/etigaCampaigns/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Kampagne', 'erfolgreich gelöscht');
          self.applyFilter();
        }, function (error) {
          jason.notifiction.notifiy('Kampagne', 'erfolgreich gelöscht');
          self.applyFilter();
        });
      },
      save: function save(item) {
        var self = this;
        var controller = this.get('controller');
        $.ajax({
          url: "/api/etigaCampaigns",
          method: "POST",
          data: JSON.stringify(item),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Kampagne', 'erfolgreich gespeichert');
          controller.get('editEtigaCampaignPanel').send('updateId', data.etigaCampaign.id);
          self.applyFilter();
        }, function (error) {});
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterPatientCategory = controller.get('filterPatientCategory'),
          filterPatientGender = controller.get('filterGender'),
          filterAge = controller.get('filterAge'),
          filterAgeTo = controller.get('filterAgeTo'),
          filterName = controller.get('filterName'),
          self = this;

      controller.set('showLoading', true);

      this.findPaged('etigaCampaign', {
        filterPatientCategory: filterPatientCategory,
        filterPatientGender: filterPatientGender,
        filterAge: filterAge,
        filterAgeTo: filterAgeTo,
        filterName: filterName,
        per_page: 10
      }).then(function (data) {
        var meta = data.meta;
        controller.set('model', data);
        controller.set('showLoading', false);
      });
    }
  });

});
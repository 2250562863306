define('jason-frontend/templates/purchases/add-product', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-field-rework");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select-placeholder");
            var el3 = dom.createTextNode("Firma");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[30,24],[30,33]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[31,22],[31,36]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionValuePath","id","optionLabelPath","name","allowClear",["subexpr","@mut",[["get","falses",["loc",[null,[35,27],[35,33]]]]],[],[]]],["loc",[null,[29,14],[36,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-field-rework");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select-placeholder");
            var el3 = dom.createTextNode("Kategorie");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","partnerCategories",["loc",[null,[43,24],[43,41]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedPartnerCategoryFilter",["loc",[null,[44,22],[44,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[42,14],[49,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","div-horizontal");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-lane grid");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Hauptkategorie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),3,3);
          morphs[1] = dom.createMorphAt(element11,3,3);
          morphs[2] = dom.createMorphAt(element11,4,4);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[18,20],[18,30]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilter",["loc",[null,[19,18],[19,40]]]]],[],[]],"optionLabelPath","name","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[17,10],[24,12]]]],
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[26,19],[26,32]]]]],[],["loc",[null,[26,14],[26,33]]]]],[],0,null,["loc",[null,[26,8],[38,15]]]],
          ["block","if",[["get","partnerCategoriesThere",["loc",[null,[39,12],[39,34]]]]],[],1,null,["loc",[null,[39,6],[51,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 0
                },
                "end": {
                  "line": 67,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Produkte\n  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element10, 'class');
              morphs[1] = dom.createMorphAt(element10,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",[" pt40 pr20 u-text--right col l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","partnerCategoriesThere",["loc",[null,[64,95],[64,117]]]],"6","12"],[],["loc",[null,[64,74],[64,129]]]],"/24@desk gutter like-h3 u-text--right"]]],
              ["inline","format-number-german-dots",[["get","totalEntries",["loc",[null,[65,32],[65,44]]]]],[],["loc",[null,[65,4],[65,46]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 0
                },
                "end": {
                  "line": 71,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Produkte\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element9, 'class');
              morphs[1] = dom.createMorphAt(element9,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["pt40 pr20 u-text--right col l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","partnerCategoriesThere",["loc",[null,[68,96],[68,118]]]],"12","18"],[],["loc",[null,[68,75],[68,131]]]],"/24@desk gutter like-h3 u-text--right"]]],
              ["inline","format-number-german-dots",[["get","totalEntries",["loc",[null,[69,34],[69,46]]]]],[],["loc",[null,[69,6],[69,48]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 0
              },
              "end": {
                "line": 73,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-box grid");
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[63,11],[63,24]]]]],[],["loc",[null,[63,6],[63,25]]]]],[],0,1,["loc",[null,[63,0],[71,7]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 2
            },
            "end": {
              "line": 74,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[60,6],[60,17]]]]],[],0,null,["loc",[null,[60,0],[73,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 2
            },
            "end": {
              "line": 93,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[92,4],[92,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 10
              },
              "end": {
                "line": 111,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","purchase-product-list",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[108,54],[108,71]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[108,80],[108,87]]]]],[],[]],"orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[108,96],[108,103]]]]],[],[]],"showAdd2cart",["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[109,54],[109,67]]]]],[],["loc",[null,[109,49],[109,68]]]],"toggleFavorite","toggleFavorite","add","addToCart","checkLaboklinPanel","checkLaboklinPanel"],["loc",[null,[108,12],[110,77]]]]
          ],
          locals: ["product"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 95,
              "column": 2
            },
            "end": {
              "line": 115,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper-rework");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table grid table--small product-list-purchase");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-9/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--center l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("VPE");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--center l-2/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("MwSt");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--right l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("EK-Preis");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          dom.setAttribute(el3,"id","product-list");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[107,18],[107,23]]]]],[],0,null,["loc",[null,[107,10],[111,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 8
              },
              "end": {
                "line": 119,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","purchase-product-cell",[],["sidebarActive",["subexpr","@mut",[["get","sidebarActive",["loc",[null,[118,48],[118,61]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[118,70],[118,77]]]]],[],[]],"orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[118,86],[118,93]]]]],[],[]],"showAdd2cart",["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[118,112],[118,125]]]]],[],["loc",[null,[118,107],[118,126]]]],"toggleFavorite","toggleFavorite","click","gotoDetail","add","addToCart","checkLaboklinPanel","checkLaboklinPanel"],["loc",[null,[118,10],[118,236]]]]
          ],
          locals: ["product"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 2
            },
            "end": {
              "line": 121,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid gutter-fix");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[117,16],[117,21]]]]],[],0,null,["loc",[null,[117,8],[119,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 6
              },
              "end": {
                "line": 131,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","button-prev",["loc",[null,[130,8],[130,23]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 127,
              "column": 2
            },
            "end": {
              "line": 133,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","action-icons");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","link-to",["purchases.choose"],["class","icon-button icon-button--small"],0,null,["loc",[null,[129,6],[131,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 2
            },
            "end": {
              "line": 139,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","action-icons");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--success");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element8);
          morphs[1] = dom.createMorphAt(element8,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openPznCheckPanel",["get","order.id",["loc",[null,[135,79],[135,87]]]]],[],["loc",[null,[135,50],[135,89]]]],
          ["inline","button-add-entry",[],["content","Produkt anlegen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[136,8],[136,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 147,
                "column": 6
              },
              "end": {
                "line": 149,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge private");
            var el2 = dom.createTextNode("Privat");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 149,
                  "column": 6
                },
                "end": {
                  "line": 151,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge lab");
              var el2 = dom.createTextNode("Labor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 151,
                  "column": 6
                },
                "end": {
                  "line": 153,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge practice");
              var el2 = dom.createTextNode("Praxis");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 6
              },
              "end": {
                "line": 153,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","order.laboklinOrder",["loc",[null,[149,16],[149,35]]]]],[],0,1,["loc",[null,[149,6],[153,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 141,
              "column": 3
            },
            "end": {
              "line": 177,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","basket-quickinfos");
          dom.setAttribute(el2,"class","grid");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-16/24");
          var el4 = dom.createTextNode("\n        Artikel ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","badge");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        Warenwert netto\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-8/24 u-text--right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","cartButtons");
          dom.setAttribute(el2,"class","col l-24/24");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-14/24");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context");
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Warenkorb wechseln");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-10/24");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context primary");
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Warenkorb");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","gotoCart");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el3,"style","width: 18px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" zum Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [7]);
          var element4 = dom.childAt(element0, [9]);
          var element5 = dom.childAt(element4, [1, 1]);
          var element6 = dom.childAt(element4, [3, 1]);
          var element7 = dom.childAt(element0, [13]);
          var morphs = new Array(9);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [1, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]),2,2);
          morphs[5] = dom.createElementMorph(element5);
          morphs[6] = dom.createElementMorph(element6);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [11]),1,1);
          morphs[8] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[143,9],[143,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[144,41],[144,49]]]]],[],["loc",[null,[144,23],[144,51]]]],
          ["block","if",[["get","order.privateOrder",["loc",[null,[147,12],[147,30]]]]],[],0,1,["loc",[null,[147,6],[153,13]]]],
          ["content","order.entryCount",["loc",[null,[157,36],[157,56]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","order.totalNet",["loc",[null,[161,37],[161,51]]]]],[],[]],"classNames","currency ml5"],["loc",[null,[161,13],[161,79]]]],
          ["element","action",["gotoCarts"],[],["loc",[null,[166,16],[166,38]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[169,34],[169,42]]]]],[],["loc",[null,[169,14],[169,44]]]],
          ["inline","mini-cart",[],["model",["subexpr","@mut",[["get","order",["loc",[null,[173,24],[173,29]]]]],[],[]],"delete","deleteCartEntry","toCart","gotoCart","toProduct","toProduct","actionReceiver",["subexpr","@mut",[["get","minicart",["loc",[null,[173,110],[173,118]]]]],[],[]]],["loc",[null,[173,6],[173,120]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[175,43],[175,51]]]]],[],["loc",[null,[175,23],[175,53]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 178,
                "column": 2
              },
              "end": {
                "line": 182,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[180,63],[180,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 177,
              "column": 0
            },
            "end": {
              "line": 183,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[178,13],[178,26]]]]],[],["loc",[null,[178,8],[178,27]]]]],[],0,null,["loc",[null,[178,2],[182,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 200,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Artikel suchen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","purchase-product-search");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 quick-filter-wrapper rework-content mt-0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter mb-0");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Filter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterAll");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon favs");
        dom.setAttribute(el4,"for","filterFavs");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"class","me-10");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Favoriten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","viewChanger");
        dom.setAttribute(el3,"class","d-flex justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/view-grid.svg");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/view-list.svg");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n    });\n    $('aside.side-bar').css('min-height', $(window).height()-80);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(fragment, [2]);
        var element16 = dom.childAt(element15, [3, 1]);
        var element17 = dom.childAt(element16, [9, 0]);
        var element18 = dom.childAt(element15, [5, 1]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(element18, [3]);
        var morphs = new Array(22);
        morphs[0] = dom.createAttrMorph(element12, 'class');
        morphs[1] = dom.createMorphAt(element14,1,1);
        morphs[2] = dom.createMorphAt(element14,3,3);
        morphs[3] = dom.createMorphAt(element13,3,3);
        morphs[4] = dom.createAttrMorph(element15, 'class');
        morphs[5] = dom.createMorphAt(element15,1,1);
        morphs[6] = dom.createMorphAt(element16,3,3);
        morphs[7] = dom.createMorphAt(element16,7,7);
        morphs[8] = dom.createAttrMorph(element17, 'src');
        morphs[9] = dom.createAttrMorph(element19, 'class');
        morphs[10] = dom.createElementMorph(element19);
        morphs[11] = dom.createAttrMorph(element20, 'class');
        morphs[12] = dom.createElementMorph(element20);
        morphs[13] = dom.createMorphAt(element15,7,7);
        morphs[14] = dom.createMorphAt(element15,9,9);
        morphs[15] = dom.createMorphAt(dom.childAt(element15, [11]),1,1);
        morphs[16] = dom.createMorphAt(element15,13,13);
        morphs[17] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky purchase with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,78],[1,89]]]],"with-filters",""],[],["loc",[null,[1,57],[1,109]]]],"  ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,132],[1,145]]]],"sidebar",""],[],["loc",[null,[1,111],[1,160]]]],"  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[1,194],[1,207]]]]],[],["loc",[null,[1,189],[1,208]]]],"24","18"],[],["loc",[null,[1,168],[1,220]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[8,30],[8,40]]]]],[],[]],"placeHolder","Artikel suchen ...","search","applyFilter"],["loc",[null,[8,6],[8,96]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[9,31],[9,42]]]]],[],[]]],["loc",[null,[9,6],[9,44]]]],
        ["block","if",[["get","showFilters",["loc",[null,[11,10],[11,21]]]]],[],0,null,["loc",[null,[11,4],[53,11]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[58,77],[58,90]]]]],[],["loc",[null,[58,72],[58,91]]]],"24","18"],[],["loc",[null,[58,51],[58,103]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[58,162],[58,175]]]],"sidebar-active",""],[],["loc",[null,[58,141],[58,197]]]]," rework-content add-product add-purchase-product"]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[59,8],[59,16]]]]],[],1,null,["loc",[null,[59,2],[74,7]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[78,70],[78,79]]]]],[],[]]],["loc",[null,[78,6],[78,81]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[80,72],[80,82]]]]],[],[]]],["loc",[null,[80,6],[80,84]]]],
        ["attribute","src",["concat",["assets/images/icons/rework/favorite",["subexpr","css-bool-evaluator",[["get","filterFavs",["loc",[null,[81,148],[81,158]]]],"-white",""],[],["loc",[null,[81,127],[81,172]]]],".svg"]]],
        ["attribute","class",["concat",["btn-",["subexpr","css-bool-evaluator",[["get","list",["loc",[null,[86,46],[86,50]]]],"default","selected"],[],["loc",[null,[86,25],[86,73]]]]," button-rework context default icon-only"]]],
        ["element","action",["changeView",false],[],["loc",[null,[86,115],[86,144]]]],
        ["attribute","class",["concat",["btn-",["subexpr","css-bool-evaluator",[["get","list",["loc",[null,[87,46],[87,50]]]],"selected","default"],[],["loc",[null,[87,25],[87,73]]]]," ms-5 button-rework context default icon-only"]]],
        ["element","action",["changeView",true],[],["loc",[null,[87,120],[87,148]]]],
        ["block","if",[["get","showLoading",["loc",[null,[91,8],[91,19]]]]],[],2,null,["loc",[null,[91,2],[93,9]]]],
        ["block","if",[["get","list",["loc",[null,[95,8],[95,12]]]]],[],3,4,["loc",[null,[95,2],[121,9]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[124,27],[124,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[124,4],[124,53]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[127,18],[127,31]]]]],[],["loc",[null,[127,13],[127,32]]]],["subexpr","not",[["subexpr","is-jason-network",[],[],["loc",[null,[127,38],[127,56]]]]],[],["loc",[null,[127,33],[127,57]]]]],[],["loc",[null,[127,8],[127,58]]]]],[],5,6,["loc",[null,[127,2],[139,9]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[141,19],[141,32]]]]],[],["loc",[null,[141,14],[141,33]]]],["get","sidebarActive",["loc",[null,[141,34],[141,47]]]]],[],["loc",[null,[141,9],[141,48]]]]],[],7,8,["loc",[null,[141,3],[183,7]]]],
        ["inline","purchase-order-laboklin-chooser",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[185,40],[185,45]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[185,54],[185,62]]]]],[],[]],"data",["subexpr","@mut",[["get","order.laboklinDatas",["loc",[null,[185,68],[185,87]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[185,98],[185,108]]]]],[],[]],"addNew","newLaboklinForm","addProduct","addProduct"],["loc",[null,[185,0],[185,159]]]],
        ["inline","purchase-order-laboklin-panel",[],["form",["subexpr","@mut",[["get","laboklinForm",["loc",[null,[186,37],[186,49]]]]],[],[]],"model",["subexpr","@mut",[["get","model.id",["loc",[null,[186,56],[186,64]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[186,73],[186,81]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[186,92],[186,102]]]]],[],[]],"confirm","addToCart"],["loc",[null,[186,0],[186,124]]]],
        ["inline","edit-purchase-product-panel",[],["save","saveProduct","actionReceiver",["subexpr","@mut",[["get","editPurchaseProductPanel",["loc",[null,[187,64],[187,88]]]]],[],[]]],["loc",[null,[187,0],[187,90]]]],
        ["inline","create-product-pzn-panel",[],["save","checkPzn","toCreateProduct","toCreateProduct","actionReceiver",["subexpr","@mut",[["get","checkPzn",["loc",[null,[188,92],[188,100]]]]],[],[]]],["loc",[null,[188,0],[188,102]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  }()));

});
define('jason-frontend/api', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var API = {
    stickyTopSpacingCollapsed: 323,
    stickyTopSpacing: 244,
    token: null,
    downloadToken: null,
    user: null,
    userId: null,
    picture: null,
    theme: null,
    mandant: null,
    hostUrl: null,
    supplierId: null,
    permissions: null,
    maxInactivityTime: null,
    externalCashregister: null,
    etigaActive: null,
    calWeekdays: null,
    calDefaultSlotDuration: null,
    showInactivityLabel: null,
    primaryCashRegisterId: null,
    enforceTreatmentDate: null,
    primaryWarehouseId: null,
    laboklinTreatment: null,
    ehapoCheckTreatment: null,
    ehapoCheckCashdesk: null,
    showEhapoStock: null,
    useAutodosing: null,
    showUserBadges: null,
    bookingApp: null,
    stationExtended: false,
    expiredToken: null,
    debugUser: null,
    useFavorites: null,
    mandantType: null,
    supplier: null,
    hidePrices: null,
    jumpFuture: null,
    preselectFavorites: false,
    operatingCountry: null,
    stayInCalendar: null,
    defaultAppointmentType: null,
    sendMessagesCalendar: null,
    landingPage: null,
    patientJournalEnabled: null,

    getToken: function getToken() {
      return localStorage.token;
    },

    getDownloadToken: function getDownloadToken() {
      return localStorage.downloadToken;
    },

    getPrimaryCashRegisterId: function getPrimaryCashRegisterId() {
      return localStorage.primaryCashRegisterId;
    },
    getPrimaryWarehouseId: function getPrimaryWarehouseId() {
      return localStorage.primaryWarehouseId;
    },

    getUser: function getUser() {
      return localStorage.user;
    },

    getUserId: function getUserId() {
      return localStorage.userId;
    },
    getDefaultDoctorId: function getDefaultDoctorId() {
      return localStorage.defaultDoctorId;
    },
    getDefaultAssistantId: function getDefaultAssistantId() {
      return localStorage.defaultAssistantId;
    },
    getDefaultRoomId: function getDefaultRoomId() {
      return localStorage.defaultRoomId;
    },
    getTheme: function getTheme() {
      return localStorage.theme;
    },
    getDefaultStationId: function getDefaultStationId() {
      return localStorage.defaultStationId;
    },
    getRemoteAssetPath: function getRemoteAssetPath() {
      return localStorage.remoteAssetPath;
    },
    getDefaultAppointmentType: function getDefaultAppointmentType() {
      return localStorage.defaultAppointmentType;
    },
    isEnforceTreatmentDate: function isEnforceTreatmentDate() {
      return localStorage.enforceTreatmentDate;
    },
    isAutoChangeTreatmentDate: function isAutoChangeTreatmentDate() {
      return localStorage.autoChangeTreatmentDate;
    },
    getCalWeekdays: function getCalWeekdays() {
      return localStorage.calWeekdays;
    },
    hasBookingApp: function hasBookingApp() {
      return localStorage.bookingApp === 'true';
    },
    isStayInCalendar: function isStayInCalendar() {
      if (localStorage.stayInCalendar === 'undefined') {
        return false;
      }
      return localStorage.stayInCalendar === 'true';
    },
    isStationExtendedEnabled: function isStationExtendedEnabled() {
      if (localStorage.stationExtended === 'undefined') {
        return false;
      }
      return localStorage.stationExtended === 'true';
    },
    isSendMessagesCalendar: function isSendMessagesCalendar() {
      if (localStorage.sendMessagesCalendar === 'undefined') {
        return false;
      }
      return localStorage.sendMessagesCalendar === 'true';
    },
    hasPicture: function hasPicture() {
      return localStorage.picture.length;
    },

    isSupplier: function isSupplier() {
      if (localStorage.supplier === 'undefined') {
        return false;
      }
      return localStorage.supplier === 'true';
    },
    isDebugUser: function isDebugUser() {
      if (localStorage.debugUser === 'undefined') {
        return false;
      }
      return localStorage.debugUser === 'true';
    },

    isLaboklinTreatmentAllowed: function isLaboklinTreatmentAllowed() {
      if (localStorage.laboklinTreatment === 'undefined') {
        return false;
      }
      return localStorage.laboklinTreatment === 'true';
    },

    isInventoryActive: function isInventoryActive() {
      if (localStorage.inventoryActive === 'undefined') {
        return false;
      }
      return localStorage.inventoryActive === 'true';
    },

    isEtigaActive: function isEtigaActive() {
      if (localStorage.etigaActive === 'undefined') {
        return false;
      }
      return localStorage.etigaActive === 'true';
    },
    isDeliveryNoteActive: function isDeliveryNoteActive() {
      if (localStorage.deliveryNoteActive === 'undefined') {
        return false;
      }
      return localStorage.deliveryNoteActive === 'true';
    },

    isHidePrices: function isHidePrices() {
      if (localStorage.hidePrices === 'undefined') {
        return false;
      }
      return localStorage.hidePrices === 'true';
    },

    isJumpFuture: function isJumpFuture() {
      if (localStorage.jumpFuture === 'undefined') {
        return false;
      }
      return localStorage.jumpFuture === 'true';
    },
    isExternalCashregister: function isExternalCashregister() {
      if (localStorage.externalCashregister === 'undefined') {
        return false;
      }
      return localStorage.externalCashregister === 'true';
    },
    isPatientJournalEnabled: function isPatientJournalEnabled() {
      if (localStorage.patientJournalEnabled === 'undefined') {
        return false;
      }
      return localStorage.patientJournalEnabled === 'true';
    },

    isCashbackActive: function isCashbackActive() {
      if (localStorage.cashbackActive === 'undefined') {
        return false;
      }
      return localStorage.cashbackActive === 'true';
    },

    getPictureCdnId: function getPictureCdnId() {
      return localStorage.picture;
    },
    getShowUserBadges: function getShowUserBadges() {
      return localStorage.showUserBadges;
    },
    getMandantType: function getMandantType() {
      return localStorage.mandantType;
    },
    getOperatingCountry: function getOperatingCountry() {
      return localStorage.operatingCountry;
    },
    getStationRedirectAfter: function getStationRedirectAfter() {
      return localStorage.stationRedirectAfter;
    },
    getTopLevelDomain: function getTopLevelDomain() {
      return this.getOperatingCountry() === 'AT' ? 'at' : 'eu';
    },

    getSupplierId: function getSupplierId() {
      return localStorage.supplierId;
    },

    getMandant: function getMandant() {
      return localStorage.mandant;
    },
    getCalCategory: function getCalCategory() {
      return localStorage.calCategory;
    },
    getLandingPage: function getLandingPage() {
      return localStorage.landingPage;
    },
    getCalDefaultSlotDuration: function getCalDefaultSlotDuration() {
      var duration = localStorage.calDefaultSlotDuration;
      if (duration) {
        return duration;
      }
      return 15;
    },

    getMaxInactivityTime: function getMaxInactivityTime() {
      return localStorage.maxInactivityTime;
    },

    getEhapoCheckCashdesk: function getEhapoCheckCashdesk() {
      return localStorage.ehapoCheckCashdesk;
    },
    getExpiringToken: function getExpiringToken() {
      return localStorage.expiredToken;
    },
    isUseAutodosing: function isUseAutodosing() {
      return localStorage.useAutodosing === 'true';
    },
    isUseFavorites: function isUseFavorites() {
      return localStorage.useFavorites === 'true';
    },
    isPreselectFavorites: function isPreselectFavorites() {
      return localStorage.preselectFavorites === 'true';
    },
    getEhapoCheckTreatment: function getEhapoCheckTreatment() {
      return localStorage.ehapoCheckTreatment;
    },

    isShowInactivityLabel: function isShowInactivityLabel() {
      return localStorage.showInactivityLabel;
    },

    hasPermission: function hasPermission(permission) {
      return localStorage.permissions.indexOf(permission) > -1;
    },

    setToken: function setToken(token) {
      localStorage.token = token;
      this.token = token;
    },

    setDownloadToken: function setDownloadToken(downloadToken) {
      localStorage.downloadToken = downloadToken;
      this.downloadToken = downloadToken;
    },
    setStationExtendedEnabled: function setStationExtendedEnabled(value) {
      localStorage.stationExtended = value;
      this.stationExtended = value;
    },

    setOperatingCountry: function setOperatingCountry(operatingCountry) {
      localStorage.operatingCountry = operatingCountry;
      this.operatingCountry = operatingCountry;
    },

    setMandantType: function setMandantType(mandantType) {
      localStorage.mandantType = mandantType;
      this.mandantType = mandantType;
    },
    setLandingPage: function setLandingPage(landingPage) {
      localStorage.landingPage = landingPage;
      this.landingPage = landingPage;
    },

    setPrimaryCashRegisterId: function setPrimaryCashRegisterId(primaryCashRegisterId) {
      localStorage.primaryCashRegisterId = primaryCashRegisterId;
      this.primaryCashRegisterId = primaryCashRegisterId;
    },

    setPrimaryWarehouseId: function setPrimaryWarehouseId(primaryWarehouseId) {
      localStorage.primaryWarehouseId = primaryWarehouseId;
      this.primaryWarehouseId = primaryWarehouseId;
    },

    setLaboklinTreatment: function setLaboklinTreatment(laboklinTreatment) {
      localStorage.laboklinTreatment = laboklinTreatment;
      this.laboklinTreatment = laboklinTreatment;
    },

    setJumpFuture: function setJumpFuture(jumpFuture) {
      localStorage.jumpFuture = jumpFuture;
      this.jumpFuture = jumpFuture;
    },
    setRemoteAssetPath: function setRemoteAssetPath(remoteAssetPath) {
      localStorage.remoteAssetPath = remoteAssetPath;
      this.remoteAssetPath = remoteAssetPath;
    },

    setStayInCalendar: function setStayInCalendar(stayInCalendar) {
      localStorage.stayInCalendar = stayInCalendar;
      this.stayInCalendar = stayInCalendar;
    },
    setExternalCashregister: function setExternalCashregister(externalCashregister) {
      localStorage.externalCashregister = externalCashregister;
      this.externalCashregister = externalCashregister;
    },
    setPatientJournalEnabled: function setPatientJournalEnabled(patientJournalEnabled) {
      localStorage.patientJournalEnabled = patientJournalEnabled;
      this.externalCashregister = patientJournalEnabled;
    },

    setDefaultData: function setDefaultData(defData) {
      localStorage.defaultAppointmentType = defData.defaultAppointmentType;
      this.defaultAppointmentType = defData.defaultAppointmentType;
      localStorage.defaultDoctorId = defData.defaultDoctorId;
      this.defaultDoctorId = defData.defaultDoctorId;
      localStorage.defaultAssistantId = defData.defaultAssistantId;
      this.defaultAssistantId = defData.defaultAssistantId;
      localStorage.defaultRoomId = defData.defaultRoomId;
      this.defaultRoomId = defData.defaultRoomId;
      localStorage.defaultStationId = defData.defaultStationId;
      this.defaultStationId = defData.defaultStationId;
      localStorage.sendMessagesCalendar = defData.sendMessagesCalendar;
      this.sendMessagesCalendar = defData.sendMessagesCalendar;
    },

    setInventoryActive: function setInventoryActive(inventoryActive) {
      localStorage.inventoryActive = inventoryActive;
      this.laboklinTreatment = inventoryActive;
    },
    setTheme: function setTheme(theme) {
      this.theme = theme;
      localStorage.theme = theme;
    },

    setCashbackActive: function setCashbackActive(cashbackActive) {
      localStorage.cashbackActive = cashbackActive;
      this.cashbackActive = cashbackActive;
    },

    setHidePrices: function setHidePrices(hidePrices) {
      localStorage.hidePrices = hidePrices;
      this.hidePrices = hidePrices;
    },

    setEtigaActive: function setEtigaActive(etigaActive) {
      localStorage.etigaActive = etigaActive;
      this.etigaActive = etigaActive;
    },
    setDeliveryNoteActive: function setDeliveryNoteActive(deliveryNoteActive) {
      localStorage.deliveryNoteActive = deliveryNoteActive;
      this.deliveryNoteActive = deliveryNoteActive;
    },
    setUseAutodosing: function setUseAutodosing(useAutodosing) {
      localStorage.useAutodosing = useAutodosing;
      this.useAutodosing = useAutodosing;
    },
    setUseFavorites: function setUseFavorites(useFavorites) {
      localStorage.useFavorites = useFavorites;
      this.useFavorites = useFavorites;
    },
    setPreselectFavorites: function setPreselectFavorites(preselectFavorites) {
      localStorage.preselectFavorites = preselectFavorites;
      this.preselectFavorites = preselectFavorites;
    },
    setEhapoCheckTreatment: function setEhapoCheckTreatment(ehapoCheckTreatment) {
      localStorage.ehapoCheckTreatment = ehapoCheckTreatment;
      this.ehapoCheckTreatment = ehapoCheckTreatment;
    },

    setEhapoCheckCashdesk: function setEhapoCheckCashdesk(ehapoCheckCashdesk) {
      localStorage.ehapoCheckCashdesk = ehapoCheckCashdesk;
      this.ehapoCheckCashdesk = ehapoCheckCashdesk;
    },

    setEhapoShowStock: function setEhapoShowStock(ehapoShowStock) {
      localStorage.ehapoShowStock = ehapoShowStock;
      this.ehapoShowStock = ehapoShowStock;
    },

    setMandant: function setMandant(mandant) {
      localStorage.mandant = mandant;
      this.mandant = mandant;
    },
    setCalDefaultSlotDuration: function setCalDefaultSlotDuration(calDefaultSlotDuration) {
      localStorage.calDefaultSlotDuration = calDefaultSlotDuration;
      this.calDefaultSlotDuration = calDefaultSlotDuration;
    },
    setStationRedirectAfter: function setStationRedirectAfter(stationRedirectAfter) {
      localStorage.stationRedirectAfter = stationRedirectAfter;
      this.stationRedirectAfter = stationRedirectAfter;
    },
    setShowUserBadges: function setShowUserBadges(showUserBadges) {
      localStorage.showUserBadges = showUserBadges;
      this.showUserBadges = showUserBadges;
    },
    setCalCategory: function setCalCategory(calCategory) {
      localStorage.calCategory = calCategory;
      this.calCategory = calCategory;
    },

    setDebugUser: function setDebugUser(debugUser) {
      localStorage.debugUser = debugUser;
      this.debugUser = debugUser;
    },

    setSupplierId: function setSupplierId(supplierId) {
      localStorage.supplierId = supplierId;
      this.supplierId = supplierId;
    },

    setMaxInactivityTime: function setMaxInactivityTime(time) {
      localStorage.maxInactivityTime = time;
      this.maxInactivityTime = time;
    },

    setSupplier: function setSupplier(isSupplier) {
      localStorage.supplier = isSupplier;
      this.supplier = isSupplier;
    },

    setShowInactivityLabel: function setShowInactivityLabel(show) {
      localStorage.showInactivityLabel = show;
      this.showInactivityLabel = show;
    },
    setEnforceTreatmentDate: function setEnforceTreatmentDate(enforceTreatmentDate) {
      localStorage.enforceTreatmentDate = enforceTreatmentDate;
      this.enforceTreatmentDate = enforceTreatmentDate;
    },
    setAutoChangeTreatmentDate: function setAutoChangeTreatmentDate(autoChangeTreatmentDate) {
      localStorage.autoChangeTreatmentDate = autoChangeTreatmentDate;
      this.autoChangeTreatmentDate = autoChangeTreatmentDate;
    },

    setPermissions: function setPermissions(permissions) {
      localStorage.permissions = permissions;
      this.permissions = permissions;
    },

    resetToken: function resetToken() {
      localStorage.expiredToken = null;
      this.expiredToken = null;
    },

    setExpiringToken: function setExpiringToken(token) {
      localStorage.expiredToken = token;
      this.expiredToken = token;
    },

    setBookingApp: function setBookingApp(bookingApp) {
      localStorage.bookingApp = bookingApp;
      this.bookingApp = bookingApp;
    },
    setCalWeekdays: function setCalWeekdays(calWeekdays) {
      localStorage.calWeekdays = calWeekdays;
      this.calWeekdays = calWeekdays;
    },

    setCurrentUser: function setCurrentUser(user) {
      var userString = user.firstname + ' ' + user.lastname;
      localStorage.user = userString;
      localStorage.userId = user.id;

      if (user.picture) {
        localStorage.picture = user.picture.cdnId;
        this.picture = user.picture.cdnId;
      }

      this.user = userString;
      this.userId = user.id;
    },

    isLoggedIn: function isLoggedIn() {
      return localStorage.token != null;
    },

    processLogin: function processLogin(data, ref) {
      if (data.passwordExpired) {
        ref.setExpiringToken(data.expiredToken);
        ref.setCurrentUser(data.user);
      } else {
        ref.setToken(data.token);
        ref.setDownloadToken(data.downloadToken);
        ref.setCurrentUser(data.user);
        ref.setMandant(data.mandantId);
        ref.setMaxInactivityTime(data.maxInactivityTime);
        ref.setPrimaryCashRegisterId(data.primaryCashRegisterId);
        ref.setShowInactivityLabel(data.showInactivityLabel);
        ref.setPermissions(data.permissions['@items']);
        ref.setSupplier(data.supplier);
        ref.setSupplierId(data.supplierId);
        ref.setCashbackActive(data.cashbackActive);
        ref.setEtigaActive(data.etigaActive);
        ref.setDeliveryNoteActive(data.deliveryNoteActive);
        ref.setLaboklinTreatment(data.laboklinTreatment);
        ref.setInventoryActive(data.inventoryActive);
        ref.setEhapoCheckCashdesk(data.ehapoCheckCashdesk);
        ref.setEhapoCheckTreatment(data.ehapoCheckTreatment);
        ref.setEhapoShowStock(data.showEhapoStock);
        ref.setHidePrices(data.hidePrices);
        ref.setUseAutodosing(data.ehapoUseAutodosing);
        ref.setUseFavorites(data.ehapoUseFavorites);
        ref.setPreselectFavorites(data.preselectFavorites);
        ref.setJumpFuture(data.jumpFuture);
        ref.setOperatingCountry(data.operatingCountry);
        ref.setStayInCalendar(data.stayInCalendar);
        ref.setMandantType(data.mandantType);
        ref.setRemoteAssetPath(data.remoteAssetPath);
        ref.setBookingApp(data.bookingApp);
        ref.setDebugUser(data.debugUser);
        ref.setPrimaryWarehouseId(data.primaryWarehouseId);
        ref.setTheme(data.theme);
        ref.setExternalCashregister(data.externalCashregister);
        ref.setPatientJournalEnabled(data.patientJournalEnabled);
        ref.setCalWeekdays(data.calWeekdays);
        ref.setCalDefaultSlotDuration(data.calDefaultSlotDuration);
        ref.setShowUserBadges(data.showUserBadges);
        ref.setLandingPage(data.landingPage);
        ref.setStationRedirectAfter(data.stationRedirectAfter);
        ref.setStationExtendedEnabled(data.stationExtended);
        ref.setEnforceTreatmentDate(data.enforceTreatmentDate);
        ref.setAutoChangeTreatmentDate(data.autoChangeTreatmentDate);

        if (data.defaultData) {
          ref.setDefaultData(data.defaultData);
        }
      }
    },
    login: function login(username, password) {
      var self = this;
      var payload = {
        username: username,
        password: password
      };

      var deferred = jQuery.post('/api/login', payload).then(function (data) {
        self.processLogin(data, self);
        return data;
      }, function (error) {
        return { status: error.statusText, message: error.responseText };
      });
      return Ember['default'].RSVP.resolve(deferred);
    },

    logout: function logout() {
      localStorage.clear();
    }

  };
  exports['default'] = API;

});
define('jason-frontend/templates/appointments/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 8
            },
            "end": {
              "line": 150,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/appointments/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Patient");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","patients",["loc",[null,[139,22],[139,30]]]]],[],[]],"value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[140,20],[140,33]]]]],[],[]],"optionLabelPath","name","allowClear",true,"disabled",["subexpr","not",[["get","patients",["loc",[null,[143,28],[143,36]]]]],[],["loc",[null,[143,23],[143,37]]]],"name","task-patient","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[138,12],[147,14]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 168,
              "column": 4
            },
            "end": {
              "line": 170,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/appointments/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[169,6],[169,27]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 170,
              "column": 4
            },
            "end": {
              "line": 172,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/appointments/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","patient-appointments",[],["stickyHeader",false,"filtersShown",["subexpr","@mut",[["get","filtersShown",["loc",[null,[171,61],[171,73]]]]],[],[]],"startTreatment","startTreatment","referer","index","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","items",["subexpr","@mut",[["get","model",["loc",[null,[171,216],[171,221]]]]],[],[]]],["loc",[null,[171,6],[171,223]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 244,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/appointments/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Termine");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/download-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("CSV herunterladen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Termin anlegen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework");
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","grid gutter-fix");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","placeholder");
        var el7 = dom.createTextNode("Zeitraum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/time-dark.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Terminart / Kategorie");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Resource");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Behandlungslokation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Station");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Arzt");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Assistent/in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter mb-10");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","form-field-rework");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","select-placeholder");
        var el7 = dom.createTextNode("Kunde");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","quick-filter-wrapper");
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","quick-filter");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","title");
        var el4 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Alle");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterFuture");
        var el4 = dom.createElement("img");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          Nur zukünftige Termine");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n\n\n$(function() {    //\n    $('#pickDate').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Heute\": [moment(), moment()],\n        \"Morgen\": [moment().add(1, 'days'), moment().add(1, 'days')],\n        \"Gestern\": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],\n        \"Diese Woche\": [moment().startOf('isoWeek'), moment().startOf('isoWeek').add(7,'days')],\n        \"Nächste Woche\": [moment().startOf('isoWeek').add(7, 'days'), moment().startOf('isoWeek').add(14, 'days')],\n        \"Letzten 7 Tage\": [moment().subtract(6, 'days'), moment()],\n        \"Dieses Monat\": [moment().startOf('month'), moment().endOf('month')],\n        \"Nächstes Monat\": [moment().startOf('month').add(1, 'month'), moment().add(1, 'month').endOf('month')],\n        \"Letztes Monat\": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false\n    });\n  });\n\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1, 3]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element0, [3, 1]);
        var element5 = dom.childAt(fragment, [3, 1]);
        var element6 = dom.childAt(element5, [9, 0]);
        var morphs = new Array(19);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [1, 1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3, 1]),3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [5, 1]),3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [7, 1]),3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [9, 1]),3,3);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [11, 1]),3,3);
        morphs[9] = dom.createMorphAt(dom.childAt(element4, [13, 1]),3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element4, [15, 1]),3,3);
        morphs[11] = dom.createMorphAt(element4,17,17);
        morphs[12] = dom.createMorphAt(element5,3,3);
        morphs[13] = dom.createMorphAt(element5,7,7);
        morphs[14] = dom.createAttrMorph(element6, 'src');
        morphs[15] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,11,11,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[3,62],[3,73]]]],"with-filters",""],[],["loc",[null,[3,41],[3,93]]]]]]],
        ["element","action",["downloadAppointmentCsv"],[],["loc",[null,[7,9],[7,44]]]],
        ["element","action",["openNewAppointment"],[],["loc",[null,[10,9],[10,40]]]],
        ["inline","input",[],["id","pickDate","value",["subexpr","@mut",[["get","pickDate",["loc",[null,[21,40],[21,48]]]]],[],[]],"class","input input--date-picker","name","pickDate","placeholder",""],["loc",[null,[21,12],[21,115]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[31,22],[31,50]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[32,20],[32,34]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"searchEnabled",false,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[30,12],[39,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","resources",["loc",[null,[47,22],[47,31]]]]],[],[]],"value",["subexpr","@mut",[["get","filterResource",["loc",[null,[48,20],[48,34]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[46,12],[54,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[61,22],[61,27]]]]],[],[]],"value",["subexpr","@mut",[["get","filterRoom",["loc",[null,[62,20],[62,30]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[60,12],[68,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","stations",["loc",[null,[75,22],[75,30]]]]],[],[]],"value",["subexpr","@mut",[["get","filterStation",["loc",[null,[76,20],[76,33]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[74,12],[82,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[89,22],[89,29]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDoctor",["loc",[null,[90,20],[90,32]]]]],[],[]],"optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","searchEnabled",false,"allowClear",false,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[88,12],[97,14]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[104,22],[104,32]]]]],[],[]],"value",["subexpr","@mut",[["get","filterAssistant",["loc",[null,[105,20],[105,35]]]]],[],[]],"optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","searchEnabled",false,"allowClear",false,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[103,12],[112,14]]]],
        ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterCustomer",["loc",[null,[119,20],[119,34]]]]],[],[]],"optionLabelPath","fullName","placeholder","Alle Kunden","allowClear",true,"typeaheadSearchingText","Suche Kunde","typeaheadNoMatchesText","Kein Kunden gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryCustomer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[118,12],[130,14]]]],
        ["block","if",[["get","patients",["loc",[null,[133,14],[133,22]]]]],[],0,null,["loc",[null,[133,8],[150,15]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[161,72],[161,81]]]]],[],[]]],["loc",[null,[161,8],[161,83]]]],
        ["inline","input",[],["type","checkbox","id","filterFuture","name","filterFuture","checked",["subexpr","@mut",[["get","fromNow",["loc",[null,[163,78],[163,85]]]]],[],[]]],["loc",[null,[163,8],[163,87]]]],
        ["attribute","src",["concat",["assets/images/icons/rework/time",["subexpr","css-bool-evaluator",[["get","fromNow",["loc",[null,[164,129],[164,136]]]],"-white","-dark"],[],["loc",[null,[164,108],[164,155]]]],".svg"]]],
        ["block","if",[["get","showLoading",["loc",[null,[168,10],[168,21]]]]],[],1,2,["loc",[null,[168,4],[172,11]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[176,40],[176,60]]]]],[],[]]],["loc",[null,[176,0],[176,62]]]],
        ["inline","add-appointment-panel",[],["etigaCategories",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[177,40],[177,55]]]]],[],[]],"reloadPatients","reloadPatients","resources",["subexpr","@mut",[["get","resources",["loc",[null,[177,98],[177,107]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[177,117],[177,125]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[177,132],[177,137]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[177,146],[177,153]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[177,165],[177,175]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[177,182],[177,187]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[177,230],[177,249]]]]],[],[]]],["loc",[null,[177,0],[177,252]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[183,8],[183,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[184,10],[184,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[185,13],[185,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[186,17],[186,36]]]]],[],[]]],["loc",[null,[179,0],[186,38]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
define('jason-frontend/templates/components/stable-select-customer-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 16
              },
              "end": {
                "line": 18,
                "column": 80
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["size",32,"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[16,72],[16,91]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[17,62],[17,86]]]]],[],[]],"evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[17,92],[17,104]]]]],[],[]],"content","","classNames","animal-icon"],["loc",[null,[16,40],[18,80]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 22,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","ml5 vertical-top inline-block","size","18","width",34],["loc",[null,[21,14],[21,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 12
              },
              "end": {
                "line": 25,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"class","button__icon__last");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-died",[],["size","16","useConfirm",false,"color","#3B6182","showStroke",false,"content","verstorben"],["loc",[null,[24,44],[25,80]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 8
            },
            "end": {
              "line": 30,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","part-button");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [8]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element0, 'title');
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createAttrMorph(element0, 'data-category');
          morphs[3] = dom.createMorphAt(element0,1,1);
          morphs[4] = dom.createMorphAt(element0,3,3);
          morphs[5] = dom.createMorphAt(element0,5,5);
          morphs[6] = dom.createMorphAt(element0,6,6);
          morphs[7] = dom.createElementMorph(element1);
          morphs[8] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["attribute","title",["concat",["Besitzer:in ",["get","patient.customer.firstname",["loc",[null,[13,38],[13,64]]]]," ",["get","patient.customer.lastname",["loc",[null,[13,69],[13,94]]]]]]],
          ["attribute","class",["concat",["d-flex align-items-center tooltipstered mr5 selectable-item ",["subexpr","css-bool-evaluator",[["get","patient.category.id",["loc",[null,[15,19],[15,38]]]],"has-icon",""],[],["loc",[null,[14,84],[15,54]]]]," patient"]]],
          ["attribute","data-category",["concat",[["get","patient.category.id",["loc",[null,[15,81],[15,100]]]]]]],
          ["block","if",[["get","patient.category",["loc",[null,[16,22],[16,38]]]]],[],0,null,["loc",[null,[16,16],[18,87]]]],
          ["content","patient.name",["loc",[null,[19,12],[19,28]]]],
          ["block","if",[["get","patient.etigaActive",["loc",[null,[20,18],[20,37]]]]],[],1,null,["loc",[null,[20,12],[22,19]]]],
          ["block","if",[["get","patient.dead",["loc",[null,[23,18],[23,30]]]]],[],2,null,["loc",[null,[23,12],[25,91]]]],
          ["element","action",["removePatient",["get","stable.id",["loc",[null,[26,63],[26,72]]]],["get","patient.id",["loc",[null,[26,73],[26,83]]]]],["bubbles",false],["loc",[null,[26,38],[26,99]]]],
          ["inline","button-delete",[],["showStroke",false,"color","#fff","size",26,"content","Tier aus Stall entfernen"],["loc",[null,[27,14],[27,104]]]]
        ],
        locals: ["patient"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/stable-select-customer-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-stable-select-customer-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-extremely-large mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("Stall: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","mt0");
        var el5 = dom.createTextNode("Folgende Tiere sind derzeit eingestellt:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        dom.setAttribute(el4,"style","flex-wrap: wrap");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        var el5 = dom.createTextNode("Weitere Tiere hinzufügen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","filter-lane grid");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","form-field-rework");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","placeholder");
        var el8 = dom.createTextNode("Kund:in suchen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/rework/search.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","form-field-rework");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","placeholder");
        var el8 = dom.createTextNode("Tiername suchen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/rework/search.svg");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [7]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element5, [5]);
        var element7 = dom.childAt(element4, [3, 1]);
        var element8 = dom.childAt(element7, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        morphs[2] = dom.createMorphAt(element5,1,1);
        morphs[3] = dom.createElementMorph(element6);
        morphs[4] = dom.createMorphAt(element7,1,1);
        morphs[5] = dom.createElementMorph(element8);
        morphs[6] = dom.createMorphAt(element3,9,9);
        return morphs;
      },
      statements: [
        ["content","stable.name",["loc",[null,[5,39],[5,54]]]],
        ["block","each",[["get","stable.patients",["loc",[null,[12,16],[12,31]]]]],[],0,null,["loc",[null,[12,8],[30,17]]]],
        ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterName",["loc",[null,[38,48],[38,58]]]]],[],[]],"class","rework","placeholder","","name","search-customer","autocomplete","off"],["loc",[null,[38,12],[39,40]]]],
        ["element","action",["triggerSearch"],[],["loc",[null,[41,20],[41,46]]]],
        ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[48,48],[48,61]]]]],[],[]],"class","rework","placeholder","","name","search-patient","autocomplete","off"],["loc",[null,[48,12],[49,40]]]],
        ["element","action",["triggerSearch"],[],["loc",[null,[51,20],[51,46]]]],
        ["inline","customer-searchform",[],["showCrud",false,"selectablePatients",false,"showQuickFilters",false,"hideContacts",true,"showFilter",false,"showSearch",false,"reference","stable","selectPatient","selectPatient","filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[60,39],[60,49]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[60,56],[60,61]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[60,70],[60,77]]]]],[],[]],"selectCustomer","selectCustomer","applyFilter","applyFilter"],["loc",[null,[58,6],[61,55]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
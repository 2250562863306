define('jason-frontend/components/treatment-info-icon', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 32,
    side: 'top',
    timer: 0,
    contentAsHTML: true,
    showStroke: false,
    content: '',
    theme: 'tooltipster-punk'
  });

});
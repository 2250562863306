define('jason-frontend/routes/services/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterDistributor = controller.get('filterDistributor'),
          filterFavorites = controller.get('filterFavs'),
          filterTiny = controller.get('filterTiny'),
          filterHorse = controller.get('filterHorse'),
          filterUse = controller.get('filterUse'),
          filterId = null;

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (filterDistributor) {
        filterDistributor = filterDistributor.id;
      }

      if (selectedCategoryFilters) {
        filterId = selectedCategoryFilters.id;
      }

      var tags = new Array();
      if (controller.get('articleTags')) {
        $.each(controller.get('articleTags').content, function () {
          if ($(this)[0].record.get('selected')) {
            tags.push($(this)[0].record.id);
          }
        });
      }

      this.findPaged('service', {
        filterName: filterName,
        selectedCategoryFilters: filterId,
        filterSupplier: filterSupplier,
        filterDistributor: filterDistributor,
        filterFavorites: filterFavorites,
        filterMandant: true,
        filterHorse: filterHorse,
        filterTiny: filterTiny,
        filterUse: filterUse,
        sortDir: sortDir,
        filterTags: tags.toString(),
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    toggleServiceFavoriteIntern: function toggleServiceFavoriteIntern(id, add) {
      var controller = this.get('controller');

      if (add) {
        $.ajax({
          url: "/api/services/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/services/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    actions: {
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      selectTag: function selectTag(tag) {
        var currentValue = tag.get('selected');
        Ember['default'].set(tag, 'selected', !currentValue);
        this.applyFilter();
      },
      'delete': function _delete(id) {
        this.get('store').find('service', id).then(function (rec) {
          rec.destroyRecord();
          jason.notifiction.notifiy('Leistung entfernt', '');
        });
      },
      toggleServiceFavorite: function toggleServiceFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.toggleServiceFavoriteIntern(product.id, add);
      },
      filterFavs: function filterFavs() {
        if (this.controller.get('filterFavs')) {
          this.controller.set('filterFavs', false);
        } else {
          this.controller.set('filterFavs', true);
        }
        this.applyFilter();
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      }
    },
    setupController: function setupController(controller, model) {
      Ember['default'].$.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.serviceCategory);
        controller.set('selectedCategoryFilters', null);
      });

      this.store.find('productDistributor', { service: true, onlyProvided: true }).then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      controller.set('filterFavorites', false);
      controller.set('mandantId', API['default'].getMandant());

      if (API['default'].isPreselectFavorites()) {
        setTimeout(function () {
          controller.set('filterFavorites', true);
        }, 500);
      }

      controller.set('model', model);
      controller.set('filterName', null);

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        controller.set('articleTags', items);
        items.forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
      });

      this.store.unloadAll('productSupplier');

      this.store.find('productSupplier', { service: true }).then(function (items) {
        controller.set('suppliers', items.sortBy('name'));
      });

      this.applyFilter();
    }
  });

});
define('jason-frontend/models/treatment-offer-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    position: DS['default'].attr(''),
    total: DS['default'].attr('number'),
    price: DS['default'].attr('number'),
    priceNet: DS['default'].attr(''),
    day: DS['default'].attr(''),
    quantity: DS['default'].attr('number'),
    visitId: DS['default'].attr(''),
    doctor: DS['default'].belongsTo('user'),
    assistant: DS['default'].belongsTo('user'),
    articleText: DS['default'].attr('string'),
    productCategory: DS['default'].attr('string'),
    serviceCategory: DS['default'].attr('string'),
    articleType: DS['default'].attr('string'),
    applied: DS['default'].attr('boolean'),
    infinitySloping: DS['default'].attr('boolean'),
    article: DS['default'].belongsTo('article'),
    movements: DS['default'].hasMany('inventory-stock-movement'),
    unitQuantity: DS['default'].attr('number'),
    tax: DS['default'].belongsTo('tax'),
    absoluteDiscount: DS['default'].attr('boolean'),
    discount: DS['default'].attr('number'),
    laboklin: DS['default'].attr('boolean'),
    hideEhapo: DS['default'].attr('boolean'),
    fullyBooked: DS['default'].attr('boolean'),
    partlyBooked: DS['default'].attr('boolean'),
    alreadyBooked: DS['default'].attr('number'),
    templateInstanceId: DS['default'].attr('number'),
    media: DS['default'].attr(''),
    templateInstanceTouched: DS['default'].attr('boolean'),
    digitalFormTouched: DS['default'].attr('boolean'),
    type: DS['default'].attr(''),
    gotFactor: DS['default'].attr(''),
    unit: DS['default'].attr(),
    subText: DS['default'].attr('string'),
    laboklinFormId: DS['default'].attr('number'),
    laboklinFormStatusId: DS['default'].attr('string'),
    xrayEntryId: DS['default'].attr('number'),
    xrayStatusId: DS['default'].attr('string'),
    slopingInfo: DS['default'].attr('string')
  });

});
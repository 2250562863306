define('jason-frontend/controllers/purchases/choose', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    filterOrderStatus: "exported",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    selectTab: 'current',
    showLoading: false,
    showFilters: true,
    perPage: 20,
    actions: {
      test: function test() {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-form-laboklin-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    filterOrderStatusList: [{ value: "An Lieferant übermittelt", id: "exported" }, { value: "Abgeschlossen", id: "finished" }, { value: "Teil storniert/retourniert", id: "partly_canceled" }, { value: "Storniert/Retourniert", id: "canceled" }, { value: "Übermittlungsfehler", id: "sending_error" }],
    watchFilterOrderStatus: (function () {
      this.send('applyFilter');
    }).observes('filterOrderStatus'),
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('pickDate')
  });

});
define('jason-frontend/routes/practicemgmt/admission', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      var self = this;
      controller.set("model", model);
      controller.set("gendersAll", this.store.find('gender'));
      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant' }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('selectedResourceFilterCount', 0);
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('users', this.store.find('user', { per_page: 100, addAll: false, addNotAssigned: true }));
      this.updateUserSettings();
      this.applyFilter();
      this.loadInvoiceData();
    },

    loadInvoiceData: function loadInvoiceData() {
      var controller = this.get('controller');
      Ember['default'].$.ajax({
        url: "/api/invoices?context=open&perPage=1",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('invoiceMeta', data.meta);
      }, function (data) {});
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');
      this.findPaged('treatment', {
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('waitingroomItems', entries.meta.waiting);
        controller.set('treatmentItems', entries.meta.treatment);
        controller.set('paymentItems', entries.meta.payment);
        controller.set('stationItems', entries.meta.station);
      });

      $.ajax({
        url: "/api/treatments/state/upcoming",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('upcomingItems', data.treatment);
      }, function (error) {});
    },
    toggleResource: function toggleResource(user) {
      var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var controller = this.get('controller');
      if (controller.get('selectedUsers').contains(user.id)) {
        controller.get('selectedUsers').splice(controller.get('selectedUsers').indexOf(user.id), 1);
        Ember['default'].set(user, 'selected', false);
      } else {
        controller.get('selectedUsers').push(user.id);
        Ember['default'].set(user, 'selected', !Ember['default'].get(user, 'selected'));
      }

      this.get('controller').set('changeTrigger', new Date());
      this.get('controller').set('selectedResourceFilterCount', controller.get('selectedUsers').length);
      if (save) {
        this.saveUserSettings();
      }
    },
    toggleResourceIntern: function toggleResourceIntern(userId) {
      var self = this;
      this.get('controller').get('users').forEach(function (user) {
        if (parseInt(user.id) === parseInt(userId)) {
          self.toggleResource(user, false);
        }
      });
    },
    deselectUser: function deselectUser(userId) {
      var self = this;
      this.get('controller').get('users').forEach(function (user) {
        if (parseInt(user.id) === parseInt(userId)) {
          self.deselectResource(user);
        }
      });
    },
    deselectResource: function deselectResource(user) {
      var controller = this.get('controller');
      controller.get('selectedUsers').splice(controller.get('selectedUsers').indexOf(user.id), 1);
      Ember['default'].set(user, 'selected', false);
      this.get('controller').set('changeTrigger', new Date());
      this.get('controller').set('selectedResourceFilterCount', controller.get('selectedUsers').length);
    },
    toggleStation: function toggleStation(station) {
      var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      if (station.get('selected')) {
        Ember['default'].set(station, 'selected', false);
      } else {
        Ember['default'].set(station, 'selected', true);
      }
      this.get('controller').set('changeTrigger', new Date());
      if (save) {
        this.saveUserSettings();
      }
    },
    toggleStationLoad: function toggleStationLoad(stationId) {
      var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var self = this;
      this.get('controller').get('stations').forEach(function (station) {
        if (parseInt(station.id) === parseInt(stationId)) {
          self.toggleStation(station, false);
        }
      });
    },
    toggleQuickfilter: function toggleQuickfilter(filter) {
      var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var controller = this.get('controller');
      if (filter === 'all') {
        controller.set('showAll', true);
        controller.set('showAdmission', false);
        controller.set('showWaiting', false);
        controller.set('showTreatment', false);
        controller.set('showPayment', false);
        controller.set('showStation', false);
      } else if (filter === 'admission') {
        controller.set('showAll', false);
        if (controller.get('showAdmission')) {
          controller.set('showAdmission', false);
        } else {
          controller.set('showAdmission', true);
        }
      } else if (filter === 'waiting') {
        controller.set('showAll', false);
        if (controller.get('showWaiting')) {
          controller.set('showWaiting', false);
        } else {
          controller.set('showWaiting', true);
        }
      } else if (filter === 'treatment') {
        controller.set('showAll', false);
        if (controller.get('showTreatment')) {
          controller.set('showTreatment', false);
        } else {
          controller.set('showTreatment', true);
        }
      } else if (filter === 'payment') {
        controller.set('showAll', false);
        if (controller.get('showPayment')) {
          controller.set('showPayment', false);
        } else {
          controller.set('showPayment', true);
        }
      } else if (filter === 'station') {
        controller.set('showAll', false);
        if (controller.get('showStation')) {
          controller.set('showStation', false);
        } else {
          controller.set('showStation', true);
        }
      }
      if (!controller.get('showStation') && !controller.get('showPayment') && !controller.get('showTreatment') && !controller.get('showWaiting') && !controller.get('showAdmission') && !controller.get('showAll')) {
        controller.set('showAll', true);
      }
      if (save) {
        this.saveUserSettings();
      }
    },
    updateState: function updateState(_updateState) {
      var self = this;

      $.ajax({
        url: "/api/treatments/updateState",
        method: "POST",
        data: JSON.stringify(_updateState),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (_updateState.newState === 'in_treatment') {
          self.transitionTo('practicemgmt.treatment-details', _updateState.treatmentPatientId, { queryParams: { entry: "true" } });
        } else if (_updateState.close == true) {
          if (_updateState.invoiceId) {
            self.transitionTo('invoices.create', _updateState.invoiceId);
          } else {
            self.transitionTo('cash.choose');
          }
        } else {
          //self.refresh();
        }
        self.applyFilter();
      });
    },
    updateUserSettings: function updateUserSettings() {
      var self = this;
      $.ajax({
        url: "/api/users/overviewSettings",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        console.log('loaded');
        var settings = data.userCalSettings;
        var view = settings.overviewViewId;
        self.changeView(view);
        var filters = settings.overviewFilters;
        var stations = settings.overviewStations;
        var users = settings.overviewUsers;

        if (filters && filters.length) {
          filters.forEach(function (filter) {
            self.toggleQuickfilter(filter, false);
          });
        }
        if (stations && stations.length) {
          setTimeout(function () {
            stations.forEach(function (filter) {
              self.toggleStationLoad(filter, false);
            });
          }, 300);
        }
        if (users && users.length) {
          setTimeout(function () {
            self.get('controller').get('users').forEach(function (user) {
              self.deselectResource(user);
            });
            users.forEach(function (filter) {
              self.toggleResourceIntern(filter, false);
            });
            self.get('controller').set('changeTrigger', new Date());
          }, 500);
        }
      }, function (data) {});
    },
    saveUserSettings: function saveUserSettings() {
      var self = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        self.saveUserSettingsIntern();
      }, 2000);
    },
    saveUserSettingsIntern: function saveUserSettingsIntern() {
      var controller = this.get('controller');

      var stations = Array();
      var filters = Array();
      controller.get('stations').forEach(function (station) {
        if (station.get('selected') === true) {
          stations.push(station.id);
        }
      });

      if (controller.get('showAdmission') === true) {
        filters.push('admission');
      }
      if (controller.get('showWaiting') === true) {
        filters.push('waiting');
      }
      if (controller.get('showTreatment') === true) {
        filters.push('treatment');
      }
      if (controller.get('showPayment') === true) {
        filters.push('payment');
      }
      if (controller.get('showStation') === true) {
        filters.push('station');
      }

      var data = {
        overviewViewId: controller.get('mainView'),
        overviewFilters: filters,
        overviewStations: stations,
        overviewUsers: controller.get('selectedUsers')
      };

      $.ajax({
        url: "/api/users/overviewSettings",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data2) {}, function (data2) {});
    },
    changeView: function changeView(viewId) {
      if (viewId === 'tiles' || viewId === 'list' || viewId === 'splitted') {
        this.get('controller').set('mainView', viewId);
      }
      setTimeout(function () {
        $('.tooltipstered').tooltipster({
          theme: 'tooltipster-punk',
          delay: 0,
          side: 'top',
          touchDevices: false
        });
      }, 500);
    },
    actions: {
      resetResourceFilters: function resetResourceFilters() {
        var self = this;
        this.get('controller').get('users').forEach(function (user) {
          self.deselectResource(user);
        });
      },
      toggleResource: function toggleResource(user) {
        this.toggleResource(user);
      },
      toggleStation: function toggleStation(station) {
        var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        if (station.get('selected')) {
          Ember['default'].set(station, 'selected', false);
        } else {
          Ember['default'].set(station, 'selected', true);
        }
        this.get('controller').set('changeTrigger', new Date());
        if (save) {
          this.saveUserSettings();
        }
      },
      toggleStationLoad: function toggleStationLoad(stationId) {
        var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        var self = this;
        this.get('controller').get('stations').forEach(function (station) {
          if (parseInt(station.id) === parseInt(stationId)) {
            self.toggleStation(station, false);
          }
        });
      },
      changeView: function changeView(viewId) {
        this.changeView(viewId);
        this.saveUserSettings();
      },
      saveDate: function saveDate(treatment, newTime) {

        var data = {
          id: treatment.id,
          time: newTime
        };

        $.ajax({
          url: "/api/treatmentPatients/changeTime",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function (error) {});
        Ember['default'].set(treatment, 'time', newTime);
      },
      admissionPatient: function admissionPatient(customerId, patientId, appointmentId) {

        var self = this;
        var patients = [patientId];
        var controller = this.get('controller');

        Ember['default'].$.ajax({
          url: "/api/patientAppointments/" + appointmentId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (appointment) {
          controller.get('startTreatmentPanel').send('load', customerId, patients, appointment.patientAppointment);
        }, function (data) {});

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toggleQuickfilter: function toggleQuickfilter(filter) {
        var save = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        this.toggleQuickfilter(filter, save);
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment, appId) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;
        treatment.appointmentId = appId.id;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.applyFilter();
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      saveEditTreatmentInfo: function saveEditTreatmentInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/quick",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (result) {
          self.applyFilter();
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      movePatientToStationSimple: function movePatientToStationSimple(id, data) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          stationId: data.stationId,
          newState: 'in_station'
        };
        this.updateState(updateState);
      },
      movePatientToStationExtended: function movePatientToStationExtended(id, data) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          cageId: data.cageId,
          newState: 'in_station'
        };
        this.updateState(updateState, 'practicemgmt.station');
      },
      openStationVisit: function openStationVisit(data) {
        console.log(data);
        try {
          if (data.get('currentVisit')) {
            this.transitionTo('practicemgmt.treatment-visit-details', data.get('currentVisit').id);
          }
        } catch (e) {
          if (data.currentVisit) {
            this.transitionTo('practicemgmt.treatment-visit-details', data.currentVisit.id);
          }
        }
      },
      abort: function abort(id) {
        var self = this,
            updateState = {
          treatmentPatientId: id,
          newState: 'closed',
          hardAbort: true
        };
        this.updateState(updateState);
      },
      movePatientToWaitingRoom: function movePatientToWaitingRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_waitingroom'
        };
        this.updateState(updateState);
      },
      movePatientToTreatmentRoom: function movePatientToTreatmentRoom(infoId) {
        var self = this,
            updateState = {
          treatmentPatientId: infoId,
          newState: 'in_treatment'
        };
        this.updateState(updateState);
      },
      selectCustomerForTreatment: function selectCustomerForTreatment() {
        this.transitionTo('practicemgmt.admission-select-customer');
      },
      exportInvoice: function exportInvoice(id, exportMode, exportInvoiceId, customerId, additionalTreatments) {
        var suffix = '';

        if (exportMode === 'new') {
          suffix = '?selectedInvoiceId=0';
        } else if (exportMode === 'newStable') {
          suffix = '?selectedInvoiceId=0&receiver=stableOwner';
        } else if (exportMode === 'composite') {
          if (exportInvoiceId === undefined) {
            exportInvoiceId = 0;
          }
          suffix = '?selectedInvoiceId=' + exportInvoiceId;
        } else if (exportMode === 'newStableCustomer') {
          if (!exportInvoiceId) {
            exportInvoiceId = 0;
          }
          suffix = '?selectedInvoiceId=' + exportInvoiceId + '&receiver=stableCustomer&selectedCustomerId=' + customerId;
        }

        if (additionalTreatments && additionalTreatments.length) {
          if (suffix.length === 0) {
            suffix = suffix += "?additionalTreatments=" + additionalTreatments.join(',');
          } else {
            suffix = suffix += "&additionalTreatments=" + additionalTreatments.join(',');
          }
        }
        var self = this,
            deferred = $.ajax({
          url: "/api/treatmentOffers/" + id + "/checkout/" + suffix,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.create', data, { queryParams: { entry: "true" } });
          jason.notifiction.notifiy('In Ausgangsrechnung übertragen', '');
        }, function (error) {
          jason.notifiction.error('Fehler', 'Behandlung wurde bereits übergeben');
        });
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          customerId: customerId,
          birthdayFormatted: patientData.birthdayFormatted,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.patientCategory) {
          newPatient.category = {
            id: patientData.patientCategory
          };
        }
        if (patientData.patientGender) {
          newPatient.gender = {
            id: patientData.patientGender
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        }, function (error) {});
      },
      setCustomer: function setCustomer(model, invoiceId, customerName, patients) {},
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      create: function create(invoiceId, customerData, patientData) {
        var controller = this.get('controller');

        var newCustomer = this.store.createRecord('customer', {
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          genderId: customerData.genderId,
          email: customerData.email,
          street: customerData.street,
          postalCode: customerData.postalCode,
          town: customerData.town,
          phone: customerData.phone
        });

        newCustomer.save().then(function (storedCustomer) {

          if (patientData) {

            var newPatient = {
              name: patientData.name,
              customerId: storedCustomer.id,
              birthdayFormatted: patientData.birthdayFormatted,
              castrated: patientData.castrated,
              chipId: patientData.chipId
            };

            if (patientData.patientCategory) {
              newPatient.category = {
                id: patientData.patientCategory
              };
            }
            if (patientData.patientGender) {
              newPatient.gender = {
                id: patientData.patientGender
              };
            }

            $.ajax({
              url: "/api/patients",
              method: "POST",
              data: JSON.stringify(newPatient),
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              var patients = Array();
              patients.push(data.patient.id);
            }, function (error) {});
          } else {}
        });
      }
    }

  });

});
define('jason-frontend/helpers/get-payment-moral-name', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPaymentMoralName = getPaymentMoralName;

  function getPaymentMoralName(params) {
    var customer = params[0],
        useModel = params[1];

    try {
      if (customer) {

        if (useModel && customer.get('sued') || !useModel && customer.sued) {
          return 'Gesperrt, weil nichtzahler';
        }
        if (useModel && customer.get('paymentMoral') || !useModel && customer.paymentMoral) {
          return useModel ? customer.get('paymentMoral').get('name') : customer.paymentMoral.name;
        }
      }
    } catch (e) {
      console.log(e);
    }

    return '';
  }

  exports['default'] = Ember['default'].Helper.helper(getPaymentMoralName);

});
define('jason-frontend/controllers/cash/inlay', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Einzahlung",
    breadCrumbPath: "cash.in",
    selectedPaymentType: "cash",
    showInvoicePanel: true,
    showBankTransferPanel: false,
    selectedType: "in",
    selectedInvoiceId: null,
    selectedInvoice: null,
    maxPaymentAmount: null,
    selectedCashRegister: null,
    transaction: null,
    bankingAccount: null,
    showLoading: false,
    documentDate: moment['default']().format("DD. MM. YYYY"),
    notice: null,
    paymentTypeList: [{ value: "Bar", id: "cash" }, { value: "EC-Karte", id: "cashcard" }, { value: "Kreditkarte", id: "creditcard" }, { value: "Überweisung", id: "banktransfer" }, { value: "Gutschein", id: "voucher" }],
    step: 'payment',
    actions: {
      toggleReference: function toggleReference(show) {
        this.set('showRefOutgoing', show);
      },
      setPaymentType: function setPaymentType(paymentType) {
        this.set('selectedPaymentType', paymentType);
        this.set('showBankTransferPanel', paymentType === 'banktransfer');
      },
      setType: function setType(type) {
        this.set('selectedType', type);
        this.set('showInvoicePanel', type === 'in');

        if (type === 'inlay') {
          this.set('selectedPaymentType', 'cash');
        }
      }
    }
  });

});
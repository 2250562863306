define('jason-frontend/templates/components/select-inventory-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 52,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1,"id","checkNumberForm");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","box");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-12/24 gutter pr40");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","sum-table mb-0");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("Auszubuchende Menge");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("- Bereits ausgebucht");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createTextNode("- ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","sum-table__col col l-16/24 border-top");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createElement("b");
            var el8 = dom.createTextNode("Noch auszubuchen");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","u-text--right sum-table__col col l-8/24 border-top");
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","");
            var el7 = dom.createElement("b");
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode(" ");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-12/24 gutter grid pt20");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-18/24");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","form-field-rework mb-0");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            dom.setAttribute(el6,"for","user-phone3");
            dom.setAttribute(el6,"class","placeholder");
            var el7 = dom.createTextNode("QR-Code scannen");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-6/24 ps-10");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5,"type","submit");
            dom.setAttribute(el5,"class","icon-button icon-button--success");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("img");
            dom.setAttribute(el6,"src","assets/images/icons/rework/select.svg");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var element21 = dom.childAt(element20, [1]);
            var element22 = dom.childAt(element21, [1, 1]);
            var element23 = dom.childAt(element22, [3, 1]);
            var element24 = dom.childAt(element22, [7, 1]);
            var element25 = dom.childAt(element22, [11, 1, 0]);
            var morphs = new Array(8);
            morphs[0] = dom.createElementMorph(element20);
            morphs[1] = dom.createMorphAt(element23,0,0);
            morphs[2] = dom.createMorphAt(element23,2,2);
            morphs[3] = dom.createMorphAt(element24,1,1);
            morphs[4] = dom.createMorphAt(element24,3,3);
            morphs[5] = dom.createMorphAt(element25,0,0);
            morphs[6] = dom.createMorphAt(element25,2,2);
            morphs[7] = dom.createMorphAt(dom.childAt(element21, [3, 1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["element","action",["confirm",["get","id",["loc",[null,[12,33],[12,35]]]]],["on","submit"],["loc",[null,[12,14],[12,49]]]],
            ["inline","format-number-german",[["get","necessary",["loc",[null,[20,56],[20,65]]]]],[],["loc",[null,[20,33],[20,67]]]],
            ["content","baseUnit",["loc",[null,[20,68],[20,80]]]],
            ["inline","format-number-german",[["get","alreadyBooked",["loc",[null,[26,58],[26,71]]]]],[],["loc",[null,[26,35],[26,73]]]],
            ["content","baseUnit",["loc",[null,[26,74],[26,86]]]],
            ["inline","format-number-german",[["subexpr","minus",[["get","necessary",["loc",[null,[32,66],[32,75]]]],["get","alreadyBooked",["loc",[null,[32,76],[32,89]]]]],[],["loc",[null,[32,59],[32,90]]]]],[],["loc",[null,[32,36],[32,92]]]],
            ["content","baseUnit",["loc",[null,[32,93],[32,105]]]],
            ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","stockIdInput",["loc",[null,[39,45],[39,57]]]]],[],[]],"autofocus",true,"id","stockIdInput","name","stockIdInput"],["loc",[null,[39,16],[39,112]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 144
                  },
                  "end": {
                    "line": 73,
                    "column": 482
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small green");
                var el2 = dom.createTextNode("insgesamt ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("1 ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" = ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(fragment, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(element15,1,1);
                morphs[1] = dom.createMorphAt(element15,3,3);
                morphs[2] = dom.createMorphAt(element16,1,1);
                morphs[3] = dom.createMorphAt(element16,3,3);
                morphs[4] = dom.createMorphAt(element16,5,5);
                return morphs;
              },
              statements: [
                ["inline","format-number-german",[["subexpr","multiply",[["get","item.conversionFactor",["loc",[null,[73,301],[73,322]]]],["subexpr","positive",[["get","item.amount",["loc",[null,[73,333],[73,344]]]]],[],["loc",[null,[73,323],[73,345]]]]],[],["loc",[null,[73,291],[73,346]]]]],[],["loc",[null,[73,268],[73,348]]]],
                ["content","baseUnit",["loc",[null,[73,349],[73,361]]]],
                ["content","item.unit.name",["loc",[null,[73,395],[73,413]]]],
                ["inline","format-number-german",[["get","item.conversionFactor",["loc",[null,[73,439],[73,460]]]]],[],["loc",[null,[73,416],[73,462]]]],
                ["content","baseUnit",["loc",[null,[73,463],[73,475]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 74,
                    "column": 85
                  },
                  "end": {
                    "line": 74,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" / ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","item.type",["loc",[null,[74,102],[74,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 74,
                    "column": 125
                  },
                  "end": {
                    "line": 74,
                    "column": 161
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.subType",["loc",[null,[74,145],[74,161]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 18
                  },
                  "end": {
                    "line": 84,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","item",["loc",[null,[82,84],[82,88]]]]],[],[]],"content","Warenausgabe stornieren","confirmed","removeMovement","text","Wollen Sie diesen Warenausgang wirklich stornieren?"],["loc",[null,[82,20],[82,210]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 12
                },
                "end": {
                  "line": 87,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24");
              dom.setAttribute(el2,"data-label","Inventar-Nr");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-5/24");
              dom.setAttribute(el2,"data-label","Menge");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--center l-5/24");
              dom.setAttribute(el2,"data-label","typ");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Ausgebucht");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Lager");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24 table__cell--action");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var element18 = dom.childAt(element17, [5]);
              var element19 = dom.childAt(element17, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
              morphs[2] = dom.createMorphAt(element18,0,0);
              morphs[3] = dom.createMorphAt(element18,2,2);
              morphs[4] = dom.createMorphAt(element18,3,3);
              morphs[5] = dom.createMorphAt(element19,0,0);
              morphs[6] = dom.createMorphAt(element19,1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element17, [9]),1,1);
              morphs[8] = dom.createMorphAt(dom.childAt(element17, [11]),0,0);
              morphs[9] = dom.createMorphAt(dom.childAt(element17, [13]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.stockId",["loc",[null,[71,73],[71,89]]]],
              ["inline","show-movement-type",[],["value",["subexpr","@mut",[["get","item.amount",["loc",[null,[72,89],[72,100]]]]],[],[]],"classNames","movement--type","size",26],["loc",[null,[72,62],[72,138]]]],
              ["inline","format-number-german-3",[["get","item.amount",["loc",[null,[73,112],[73,123]]]]],[],["loc",[null,[73,87],[73,125]]]],
              ["content","item.unit.name",["loc",[null,[73,126],[73,144]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[73,164],[73,172]]]],["get","item.unit.name",["loc",[null,[73,173],[73,187]]]]],[],["loc",[null,[73,160],[73,188]]]]],[],["loc",[null,[73,155],[73,189]]]],["subexpr","not",[["subexpr","eq",[["get","item.conversionFactor",["loc",[null,[73,199],[73,220]]]],1],[],["loc",[null,[73,195],[73,223]]]]],[],["loc",[null,[73,190],[73,224]]]]],[],["loc",[null,[73,150],[73,225]]]]],[],0,null,["loc",[null,[73,144],[73,489]]]],
              ["block","if",[["get","item.type",["loc",[null,[74,91],[74,100]]]]],[],1,null,["loc",[null,[74,85],[74,125]]]],
              ["block","if",[["get","item.subType",["loc",[null,[74,131],[74,143]]]]],[],2,null,["loc",[null,[74,125],[74,168]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[76,32],[76,44]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[76,18],[76,67]]]],
              ["content","item.warehouse",["loc",[null,[78,67],[78,85]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.wasCancelled",["loc",[null,[80,34],[80,51]]]]],[],["loc",[null,[80,29],[80,52]]]],["subexpr","not",[["subexpr","eq",[["get","item.subType",["loc",[null,[80,62],[80,74]]]],"Storno"],[],["loc",[null,[80,58],[80,84]]]]],[],["loc",[null,[80,53],[80,85]]]]],[],["loc",[null,[80,24],[80,86]]]]],[],3,null,["loc",[null,[80,18],[84,25]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 91,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("Verbuchte Ausgaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table table--large");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-1/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Menge");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell table__cell--center l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Typ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Datum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lager");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-1/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","connectedMovements",["loc",[null,[69,20],[69,38]]]]],[],0,null,["loc",[null,[69,12],[87,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 111,
                      "column": 130
                    },
                    "end": {
                      "line": 111,
                      "column": 246
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.unit.name",["loc",[null,[111,166],[111,184]]]],
                  ["inline","format-number-german",[["get","item.conversionFactor",["loc",[null,[111,210],[111,231]]]]],[],["loc",[null,[111,187],[111,233]]]],
                  ["content","baseUnit",["loc",[null,[111,234],[111,246]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 111,
                      "column": 246
                    },
                    "end": {
                      "line": 111,
                      "column": 414
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("1 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" = ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(6);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                  morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
                  morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
                  morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","baseUnit",["loc",[null,[111,254],[111,266]]]],
                  ["inline","multiply",[["get","item.conversionFactor",["loc",[null,[111,280],[111,301]]]],-1],[],["loc",[null,[111,269],[111,306]]]],
                  ["content","item.unit.name",["loc",[null,[111,307],[111,325]]]],
                  ["content","item.unit.name",["loc",[null,[111,332],[111,350]]]],
                  ["inline","divide",[1,["subexpr","multiply",[["get","item.conversionFactor",["loc",[null,[111,374],[111,395]]]],-1],[],["loc",[null,[111,364],[111,399]]]]],[],["loc",[null,[111,353],[111,401]]]],
                  ["content","baseUnit",["loc",[null,[111,402],[111,414]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 20
                  },
                  "end": {
                    "line": 111,
                    "column": 428
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small");
                var el2 = dom.createTextNode("1 ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","item.conversionFactor",["loc",[null,[111,140],[111,161]]]],0],[],["loc",[null,[111,136],[111,164]]]]],[],0,1,["loc",[null,[111,130],[111,421]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 14
                },
                "end": {
                  "line": 130,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24");
              dom.setAttribute(el2,"data-label","Nr");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24");
              dom.setAttribute(el2,"data-label","Verfügbar");
              var el3 = dom.createElement("b");
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("/");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-3/24");
              dom.setAttribute(el2,"data-label","Lager");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24 break-word text-center");
              dom.setAttribute(el2,"data-label","Chargen-Nr");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24");
              dom.setAttribute(el2,"data-label","Ablaufdatum");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24");
              dom.setAttribute(el2,"data-label","Lagerplatz");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button list-action-square");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/rework/select.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var element12 = dom.childAt(element11, [3]);
              var element13 = dom.childAt(element12, [0, 0]);
              var element14 = dom.childAt(element11, [13, 1]);
              var morphs = new Array(11);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
              morphs[1] = dom.createAttrMorph(element13, 'class');
              morphs[2] = dom.createMorphAt(element13,0,0);
              morphs[3] = dom.createMorphAt(element13,2,2);
              morphs[4] = dom.createMorphAt(element12,2,2);
              morphs[5] = dom.createMorphAt(element12,4,4);
              morphs[6] = dom.createMorphAt(dom.childAt(element11, [5]),1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element11, [7]),1,1);
              morphs[8] = dom.createMorphAt(dom.childAt(element11, [9]),1,1);
              morphs[9] = dom.createMorphAt(dom.childAt(element11, [11]),1,1);
              morphs[10] = dom.createElementMorph(element14);
              return morphs;
            },
            statements: [
              ["content","item.id",["loc",[null,[109,66],[109,77]]]],
              ["attribute","class",["concat",[["subexpr","get-stock-color",[["get","item.flatStock",["loc",[null,[110,107],[110,121]]]],["get","item.packageQuantity",["loc",[null,[110,122],[110,142]]]]],[],["loc",[null,[110,89],[110,144]]]]]]],
              ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[110,169],[110,183]]]]],[],["loc",[null,[110,146],[110,185]]]],
              ["inline","format-number-german",[["get","item.packageQuantity",["loc",[null,[110,209],[110,229]]]]],[],["loc",[null,[110,186],[110,231]]]],
              ["content","item.unit.name",["loc",[null,[110,243],[110,261]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[111,40],[111,48]]]],["get","item.unit.name",["loc",[null,[111,49],[111,63]]]]],[],["loc",[null,[111,36],[111,64]]]]],[],["loc",[null,[111,31],[111,65]]]],["subexpr","not",[["subexpr","eq",[["get","item.conversionFactor",["loc",[null,[111,75],[111,96]]]],1],[],["loc",[null,[111,71],[111,99]]]]],[],["loc",[null,[111,66],[111,100]]]]],[],["loc",[null,[111,26],[111,101]]]]],[],0,null,["loc",[null,[111,20],[111,435]]]],
              ["content","item.warehouseName",["loc",[null,[113,20],[113,42]]]],
              ["content","item.batchNumber",["loc",[null,[116,20],[116,40]]]],
              ["inline","time-format",[["get","item.expiryDate",["loc",[null,[119,34],[119,49]]]],"DD. MM. YYYY"],[],["loc",[null,[119,20],[119,66]]]],
              ["content","item.locationInfo",["loc",[null,[122,20],[122,41]]]],
              ["element","action",["copy",["get","item.id",["loc",[null,[125,78],[125,85]]]]],[],["loc",[null,[125,62],[125,87]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 8
              },
              "end": {
                "line": 133,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("Verfügbare Bestände");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table table--large");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__head");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-2/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Verfügbar");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-3/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lager");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-5/24 text-center");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Chargen-Nr");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Ablaufdatum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__cell l-4/24");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","table__title title");
            var el5 = dom.createTextNode("Lagerplatz");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","items",["loc",[null,[107,22],[107,27]]]]],[],0,null,["loc",[null,[107,14],[130,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 135,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ps-20 pe-20");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element26 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(element26,1,1);
          morphs[2] = dom.createMorphAt(element26,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","fullyBooked",["loc",[null,[10,17],[10,28]]]]],[],["loc",[null,[10,12],[10,29]]]]],[],0,null,["loc",[null,[10,6],[52,13]]]],
          ["block","if",[["subexpr","gt",[["subexpr","get-size",[["get","connectedMovements",["loc",[null,[55,26],[55,44]]]]],[],["loc",[null,[55,16],[55,45]]]],0],[],["loc",[null,[55,12],[55,48]]]]],[],1,null,["loc",[null,[55,6],[91,13]]]],
          ["block","if",[["subexpr","and",[["subexpr","gt",[["subexpr","get-size",[["get","items",["loc",[null,[93,33],[93,38]]]]],[],["loc",[null,[93,23],[93,39]]]],0],[],["loc",[null,[93,19],[93,42]]]],["subexpr","not",[["get","fullyBooked",["loc",[null,[93,48],[93,59]]]]],[],["loc",[null,[93,43],[93,60]]]]],[],["loc",[null,[93,14],[93,61]]]]],[],2,null,["loc",[null,[93,8],[133,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 196,
                  "column": 20
                },
                "end": {
                  "line": 198,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      1 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","selectedStock.unit.name",["loc",[null,[197,24],[197,51]]]],
              ["inline","format-number-german",[["get","selectedStock.conversionFactor",["loc",[null,[197,77],[197,107]]]]],[],["loc",[null,[197,54],[197,109]]]],
              ["content","baseUnit",["loc",[null,[197,110],[197,122]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 198,
                  "column": 20
                },
                "end": {
                  "line": 202,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      1 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" = ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("1  =  <br/>");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","green");
              var el2 = dom.createTextNode("insgesamt ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [10]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[3] = dom.createMorphAt(element0,1,1);
              morphs[4] = dom.createMorphAt(element0,3,3);
              return morphs;
            },
            statements: [
              ["content","baseUnit",["loc",[null,[199,24],[199,36]]]],
              ["inline","multiply",[-1,["get","selectedStock.conversionFactor",["loc",[null,[199,53],[199,83]]]]],[],["loc",[null,[199,39],[199,85]]]],
              ["content","selectedStock.unit.name",["loc",[null,[199,86],[199,113]]]],
              ["inline","multiply",[["subexpr","divide",[1,["subexpr","multiply",[["get","selectedStock.conversionFactor",["loc",[null,[201,83],[201,113]]]],-1],[],["loc",[null,[201,73],[201,117]]]]],[],["loc",[null,[201,63],[201,118]]]],["get","outgoing",["loc",[null,[201,119],[201,127]]]]],[],["loc",[null,[201,52],[201,129]]]],
              ["content","baseUnit",["loc",[null,[201,130],[201,142]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 193,
                "column": 16
              },
              "end": {
                "line": 204,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","gt",[["get","selectedStock.conversionFactor",["loc",[null,[196,30],[196,60]]]],0],[],["loc",[null,[196,26],[196,63]]]]],[],0,1,["loc",[null,[196,20],[202,27]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 136,
              "column": 6
            },
            "end": {
              "line": 219,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box grid p-30");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Inventar-Nr");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Produktname");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Lager");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n               ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Lagerplatz");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n               ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("VPE");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Verfügbar");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field mb0");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","new-not-title");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Offene Menge");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field mb0");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","outgoing");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Auszubuchende Menge");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col l-8/24");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","form-field-rework mb-0");
          var el7 = dom.createTextNode("\n                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","outgoing");
          dom.setAttribute(el7,"class","placeholder");
          var el8 = dom.createTextNode("Menge");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col l-16/24 pt10 pl10");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","bar");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--small");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--success");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/select.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [9, 1]);
          var element4 = dom.childAt(element2, [11, 1]);
          var element5 = dom.childAt(element2, [13, 1]);
          var element6 = dom.childAt(element2, [15, 1]);
          var element7 = dom.childAt(element6, [5]);
          var element8 = dom.childAt(element1, [3]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element8, [3]);
          var morphs = new Array(17);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [5, 1]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [7, 1]),3,3);
          morphs[5] = dom.createMorphAt(element3,3,3);
          morphs[6] = dom.createMorphAt(element3,5,5);
          morphs[7] = dom.createMorphAt(element4,3,3);
          morphs[8] = dom.createMorphAt(element4,5,5);
          morphs[9] = dom.createMorphAt(element5,3,3);
          morphs[10] = dom.createMorphAt(element5,5,5);
          morphs[11] = dom.createMorphAt(dom.childAt(element6, [3, 1]),1,1);
          morphs[12] = dom.createMorphAt(element7,1,1);
          morphs[13] = dom.createMorphAt(element7,3,3);
          morphs[14] = dom.createElementMorph(element9);
          morphs[15] = dom.createMorphAt(element9,1,1);
          morphs[16] = dom.createElementMorph(element10);
          return morphs;
        },
        statements: [
          ["element","action",["confirmOut"],["on","submit"],["loc",[null,[138,12],[138,47]]]],
          ["content","selectedStock.id",["loc",[null,[143,14],[143,34]]]],
          ["content","selectedStock.product.name",["loc",[null,[149,14],[149,44]]]],
          ["content","selectedStock.warehouseName",["loc",[null,[155,15],[155,46]]]],
          ["content","selectedStock.locationInfo",["loc",[null,[161,15],[161,45]]]],
          ["inline","format-number-german",[["get","selectedStock.packageQuantity",["loc",[null,[167,37],[167,66]]]]],[],["loc",[null,[167,14],[167,68]]]],
          ["content","selectedStock.unit.name",["loc",[null,[167,69],[167,96]]]],
          ["inline","format-number-german",[["get","selectedStock.flatStock",["loc",[null,[173,37],[173,60]]]]],[],["loc",[null,[173,14],[173,62]]]],
          ["content","selectedStock.unit.name",["loc",[null,[173,63],[173,90]]]],
          ["inline","format-number-german",[["subexpr","minus",[["get","necessary",["loc",[null,[179,44],[179,53]]]],["get","alreadyBooked",["loc",[null,[179,54],[179,67]]]]],[],["loc",[null,[179,37],[179,68]]]]],[],["loc",[null,[179,14],[179,70]]]],
          ["content","baseUnit",["loc",[null,[179,71],[179,83]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","outgoing",["loc",[null,[187,47],[187,55]]]]],[],[]],"autofocus",true,"name","outgoing","id","outgoing"],["loc",[null,[187,18],[187,102]]]],
          ["content","selectedStock.unit.name",["loc",[null,[192,14],[192,41]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","baseUnit",["loc",[null,[193,36],[193,44]]]],["get","selectedStock.unit.name",["loc",[null,[193,45],[193,68]]]]],[],["loc",[null,[193,32],[193,69]]]]],[],["loc",[null,[193,27],[193,70]]]],["subexpr","not",[["subexpr","eq",[["get","selectedStock.conversionFactor",["loc",[null,[193,80],[193,110]]]],1],[],["loc",[null,[193,76],[193,113]]]]],[],["loc",[null,[193,71],[193,114]]]]],[],["loc",[null,[193,22],[193,115]]]]],[],0,null,["loc",[null,[193,16],[204,23]]]],
          ["element","action",["showLoad"],[],["loc",[null,[211,52],[211,73]]]],
          ["content","button-prev",["loc",[null,[212,12],[212,27]]]],
          ["element","action",["confirmOut"],[],["loc",[null,[214,13],[214,36]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 232,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/select-inventory-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-select-inventory-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n      $(document).on(\"keyup\", \"#stockIdInput\", function() {\n          var value = $(this).val();\n          if(value.endsWith(\"QR\")) {\n              $('#checkNumberForm').submit();\n          }\n      });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element27 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element27, [1, 1]),1,1);
        morphs[1] = dom.createMorphAt(element27,3,3);
        morphs[2] = dom.createMorphAt(element27,4,4);
        return morphs;
      },
      statements: [
        ["inline","truncate",[["get","productName",["loc",[null,[5,27],[5,38]]]],100],[],["loc",[null,[5,16],[5,44]]]],
        ["block","if",[["get","showInput",["loc",[null,[8,12],[8,21]]]]],[],0,null,["loc",[null,[8,6],[135,13]]]],
        ["block","if",[["get","showDetails",["loc",[null,[136,12],[136,23]]]]],[],1,null,["loc",[null,[136,6],[219,13]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('jason-frontend/templates/resourcemgmt/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 2
            },
            "end": {
              "line": 66,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticky");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createTextNode("Mitarbeiter:in");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element19, [3]),0,0);
          morphs[1] = dom.createUnsafeMorphAt(dom.childAt(element19, [5]),0,0);
          morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element19, [7]),0,0);
          morphs[3] = dom.createUnsafeMorphAt(dom.childAt(element19, [9]),0,0);
          morphs[4] = dom.createUnsafeMorphAt(dom.childAt(element19, [11]),0,0);
          morphs[5] = dom.createUnsafeMorphAt(dom.childAt(element19, [13]),0,0);
          morphs[6] = dom.createUnsafeMorphAt(dom.childAt(element19, [15]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.dates.0",["loc",[null,[58,62],[58,83]]]],
          ["content","model.dates.1",["loc",[null,[59,62],[59,83]]]],
          ["content","model.dates.2",["loc",[null,[60,62],[60,83]]]],
          ["content","model.dates.3",["loc",[null,[61,62],[61,83]]]],
          ["content","model.dates.4",["loc",[null,[62,62],[62,83]]]],
          ["content","model.dates.5",["loc",[null,[63,62],[63,83]]]],
          ["content","model.dates.6",["loc",[null,[64,63],[64,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 8
            },
            "end": {
              "line": 74,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center mb10 mt10");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[72,12],[72,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 14
                },
                "end": {
                  "line": 84,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","tooltipstered symbol symbol-circle symbol-40px");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'style');
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[80,30],[80,52]]]]]]],
              ["attribute","style",["concat",["background-color: ",["get","userRow.user.color",["loc",[null,[80,138],[80,156]]]]," !important;"]]],
              ["content","userRow.user.capitals",["loc",[null,[81,44],[81,69]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 14
                },
                "end": {
                  "line": 89,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[85,30],[85,52]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","userRow.user.selected",["loc",[null,[85,98],[85,119]]]],"selected",""],[],["loc",[null,[85,77],[85,135]]]]," symbol symbol-circle symbol-40px ",["get","userRow.user.color",["loc",[null,[85,171],[85,189]]]]]]],
              ["content","userRow.user.capitals",["loc",[null,[86,44],[86,69]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 12
                },
                "end": {
                  "line": 92,
                  "column": 98
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","userRow.user.userRole.name",["loc",[null,[92,61],[92,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 45
                  },
                  "end": {
                    "line": 99,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[99,68],[99,80]]]],
                ["content","day.to",["loc",[null,[99,83],[99,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 12
                },
                "end": {
                  "line": 99,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[99,55],[99,66]]]]],[],0,null,["loc",[null,[99,45],[99,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 46
                  },
                  "end": {
                    "line": 106,
                    "column": 103
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[106,69],[106,81]]]],
                ["content","day.to",["loc",[null,[106,84],[106,94]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 12
                },
                "end": {
                  "line": 106,
                  "column": 114
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[106,56],[106,67]]]]],[],0,null,["loc",[null,[106,46],[106,114]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 113,
                    "column": 48
                  },
                  "end": {
                    "line": 113,
                    "column": 105
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[113,71],[113,83]]]],
                ["content","day.to",["loc",[null,[113,86],[113,96]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 113,
                  "column": 12
                },
                "end": {
                  "line": 113,
                  "column": 116
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[113,58],[113,69]]]]],[],0,null,["loc",[null,[113,48],[113,116]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 47
                  },
                  "end": {
                    "line": 120,
                    "column": 104
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[120,70],[120,82]]]],
                ["content","day.to",["loc",[null,[120,85],[120,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 12
                },
                "end": {
                  "line": 120,
                  "column": 115
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[120,57],[120,68]]]]],[],0,null,["loc",[null,[120,47],[120,115]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 127,
                    "column": 45
                  },
                  "end": {
                    "line": 127,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[127,68],[127,80]]]],
                ["content","day.to",["loc",[null,[127,83],[127,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 127,
                  "column": 12
                },
                "end": {
                  "line": 127,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[127,55],[127,66]]]]],[],0,null,["loc",[null,[127,45],[127,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 47
                  },
                  "end": {
                    "line": 134,
                    "column": 104
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[134,70],[134,82]]]],
                ["content","day.to",["loc",[null,[134,85],[134,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 134,
                  "column": 12
                },
                "end": {
                  "line": 134,
                  "column": 115
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[134,57],[134,68]]]]],[],0,null,["loc",[null,[134,47],[134,115]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 141,
                    "column": 45
                  },
                  "end": {
                    "line": 141,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[141,68],[141,80]]]],
                ["content","day.to",["loc",[null,[141,83],[141,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 141,
                  "column": 12
                },
                "end": {
                  "line": 141,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[141,55],[141,66]]]]],[],0,null,["loc",[null,[141,45],[141,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 149,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            dom.setAttribute(el2,"data-label","Mitarbeiter:in");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","symbol-group symbol-hover block mb0");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Montag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Dienstag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Mittwoch");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Donnerstag");
            var el3 = dom.createTextNode("\n             ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Freitag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Samstag");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Sonntag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework icon-only context default");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell--right table__cell l-2/24 table__cell--action text-center");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","button-rework default icon-only context");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/copy-white.svg");
            dom.setAttribute(el4,"title","Wochenplanung kopieren");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element2, [5]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element2, [7]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element2, [9]);
            var element11 = dom.childAt(element10, [1]);
            var element12 = dom.childAt(element2, [11]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element2, [13]);
            var element15 = dom.childAt(element14, [1]);
            var element16 = dom.childAt(element2, [15]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element2, [17, 1]);
            var morphs = new Array(27);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
            morphs[2] = dom.createMorphAt(element3,3,3);
            morphs[3] = dom.createMorphAt(element3,5,5);
            morphs[4] = dom.createMorphAt(element3,7,7);
            morphs[5] = dom.createElementMorph(element5);
            morphs[6] = dom.createMorphAt(element5,1,1);
            morphs[7] = dom.createMorphAt(element4,5,5);
            morphs[8] = dom.createElementMorph(element7);
            morphs[9] = dom.createMorphAt(element7,1,1);
            morphs[10] = dom.createMorphAt(element6,5,5);
            morphs[11] = dom.createElementMorph(element9);
            morphs[12] = dom.createMorphAt(element9,1,1);
            morphs[13] = dom.createMorphAt(element8,5,5);
            morphs[14] = dom.createElementMorph(element11);
            morphs[15] = dom.createMorphAt(element11,1,1);
            morphs[16] = dom.createMorphAt(element10,5,5);
            morphs[17] = dom.createElementMorph(element13);
            morphs[18] = dom.createMorphAt(element13,1,1);
            morphs[19] = dom.createMorphAt(element12,5,5);
            morphs[20] = dom.createElementMorph(element15);
            morphs[21] = dom.createMorphAt(element15,1,1);
            morphs[22] = dom.createMorphAt(element14,5,5);
            morphs[23] = dom.createElementMorph(element17);
            morphs[24] = dom.createMorphAt(element17,1,1);
            morphs[25] = dom.createMorphAt(element16,5,5);
            morphs[26] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","userRow.user.userRole",["loc",[null,[76,52],[76,73]]]],["get","userRow.user.userRole.id",["loc",[null,[76,74],[76,98]]]],""],[],["loc",[null,[76,31],[76,103]]]]]]],
            ["block","if",[["get","userRow.user.colorHex",["loc",[null,[79,20],[79,41]]]]],[],0,1,["loc",[null,[79,14],[89,21]]]],
            ["content","userRow.user.firstname",["loc",[null,[91,12],[91,38]]]],
            ["content","userRow.user.lastname",["loc",[null,[91,39],[91,64]]]],
            ["block","if",[["get","userRow.user.userRole",["loc",[null,[92,18],[92,39]]]]],[],2,null,["loc",[null,[92,12],[92,105]]]],
            ["element","action",["openEdit",["get","userRow.monday",["loc",[null,[95,88],[95,102]]]]],[],["loc",[null,[95,68],[95,104]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[96,14],[96,80]]]],
            ["block","each",[["get","userRow.monday",["loc",[null,[99,20],[99,34]]]]],[],3,null,["loc",[null,[99,12],[99,122]]]],
            ["element","action",["openEdit",["get","userRow.tuesday",["loc",[null,[102,88],[102,103]]]]],[],["loc",[null,[102,68],[102,105]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[103,14],[103,80]]]],
            ["block","each",[["get","userRow.tuesday",["loc",[null,[106,20],[106,35]]]]],[],4,null,["loc",[null,[106,12],[106,123]]]],
            ["element","action",["openEdit",["get","userRow.wednesday",["loc",[null,[109,88],[109,105]]]]],[],["loc",[null,[109,68],[109,107]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[110,14],[110,80]]]],
            ["block","each",[["get","userRow.wednesday",["loc",[null,[113,20],[113,37]]]]],[],5,null,["loc",[null,[113,12],[113,125]]]],
            ["element","action",["openEdit",["get","userRow.thursday",["loc",[null,[116,89],[116,105]]]]],[],["loc",[null,[116,69],[116,107]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[117,14],[117,80]]]],
            ["block","each",[["get","userRow.thursday",["loc",[null,[120,20],[120,36]]]]],[],6,null,["loc",[null,[120,12],[120,124]]]],
            ["element","action",["openEdit",["get","userRow.friday",["loc",[null,[123,88],[123,102]]]]],[],["loc",[null,[123,68],[123,104]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[124,14],[124,80]]]],
            ["block","each",[["get","userRow.friday",["loc",[null,[127,20],[127,34]]]]],[],7,null,["loc",[null,[127,12],[127,122]]]],
            ["element","action",["openEdit",["get","userRow.saturday",["loc",[null,[130,90],[130,106]]]]],[],["loc",[null,[130,70],[130,108]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[131,14],[131,80]]]],
            ["block","each",[["get","userRow.saturday",["loc",[null,[134,20],[134,36]]]]],[],8,null,["loc",[null,[134,12],[134,124]]]],
            ["element","action",["openEdit",["get","userRow.sunday",["loc",[null,[137,88],[137,102]]]]],[],["loc",[null,[137,68],[137,104]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[138,14],[138,80]]]],
            ["block","each",[["get","userRow.sunday",["loc",[null,[141,20],[141,34]]]]],[],9,null,["loc",[null,[141,12],[141,122]]]],
            ["element","action",["openCopyPanel",["get","userRow.user",["loc",[null,[144,45],[144,57]]]]],[],["loc",[null,[144,20],[144,60]]]]
          ],
          locals: ["userRow"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 8
            },
            "end": {
              "line": 150,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.users",["loc",[null,[75,16],[75,27]]]]],[],0,null,["loc",[null,[75,8],[149,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 202,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Dienstplan für KW");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/copy-white.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Wochenplanung auf andere KW übertragen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","filter-lane grid");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","dateFrom");
        dom.setAttribute(el5,"class","placeholder");
        var el6 = dom.createTextNode("KW");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Jahr");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Rolle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper-rework");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table box no-box@phone grid table--large");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $('#miniCalendarTrigger').daterangepicker({\n      \"singleDatePicker\": true,\n      \"showISOWeekNumbers\": true,\n      \"locale\": {\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      // \"autoApply\": true,\n    });\n    $('#miniCalendarTrigger').on('apply.daterangepicker', function(ev, picker) {\n      picker.show();\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0]);
        var element21 = dom.childAt(element20, [1, 3, 1]);
        var element22 = dom.childAt(element20, [3, 1]);
        var element23 = dom.childAt(element22, [1]);
        var element24 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element20, 'class');
        morphs[1] = dom.createElementMorph(element21);
        morphs[2] = dom.createElementMorph(element23);
        morphs[3] = dom.createMorphAt(element23,1,1);
        morphs[4] = dom.createMorphAt(element22,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element22, [5]),3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element22, [7]),3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element24, [1]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element24, [3]),1,1);
        morphs[9] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[10] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["element","action",["openCopyPanel"],[],["loc",[null,[5,9],[5,36]]]],
        ["element","action",["openMiniCalendar"],[],["loc",[null,[14,37],[14,66]]]],
        ["inline","input",[],["type","text","placeholder","","id","weekNumber","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","weekNumber",["loc",[null,[15,103],[15,113]]]]],[],[]],"name","weekNumber"],["loc",[null,[15,8],[16,35]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","switchToDate",["loc",[null,[20,32],[20,44]]]]],[],[]],"id","miniCalendarTrigger"],["loc",[null,[20,6],[20,71]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","years",["loc",[null,[25,18],[25,23]]]]],[],[]],"value",["subexpr","@mut",[["get","year",["loc",[null,[26,16],[26,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[24,8],[32,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","userRoles",["loc",[null,[38,18],[38,27]]]]],[],[]],"value",["subexpr","@mut",[["get","role",["loc",[null,[39,16],[39,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",true,"placeholder","Alle Rollen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[37,8],[46,10]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[55,30],[55,54]]]]],[],[]]],0,null,["loc",[null,[55,2],[66,23]]]],
        ["block","if",[["get","loading",["loc",[null,[70,14],[70,21]]]]],[],1,2,["loc",[null,[70,8],[150,15]]]],
        ["inline","edit-resource-day-panel",[],["actionReceiver",["subexpr","@mut",[["get","editResourceDayPanel",["loc",[null,[155,41],[155,61]]]]],[],[]],"save","save"],["loc",[null,[155,0],[155,75]]]],
        ["inline","copy-resource-panel",[],["actionReceiver",["subexpr","@mut",[["get","copyResourcePanel",["loc",[null,[156,37],[156,54]]]]],[],[]]],["loc",[null,[156,0],[156,56]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
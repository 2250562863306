define('jason-frontend/templates/components/media-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 10
              },
              "end": {
                "line": 43,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/media-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box col l-12/24");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","pickFile");
            dom.setAttribute(el2,"class","select--inline input-element");
            dom.setAttribute(el2,"id","pickFile");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","title mb20");
            var el4 = dom.createTextNode("Datei auswählen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box col l-12/24");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","title");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Hinweise");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            var el4 = dom.createElement("li");
            var el5 = dom.createTextNode("Die max. Dateigröße beträgt 10 MByte");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","file-upload",[],["uploadDone","uploadDone"],["loc",[null,[37,14],[37,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 46,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/media-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","box pb0");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box col l-12/24");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","mediaCategory");
          dom.setAttribute(el3,"class","select--inline input-element");
          dom.setAttribute(el3,"id","mediaCategory");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          var el5 = dom.createTextNode("Kategorie");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box col l-12/24 mt10");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","textarea textarea--inline clearfix pl10");
          dom.setAttribute(el3,"id","mediaName");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","title");
          var el5 = dom.createTextNode("Name");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","textarea__body ml0");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element9, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3, 1, 3]),1,1);
          morphs[2] = dom.createMorphAt(element9,5,5);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[16,24],[16,39]]]]],[],[]],"value",["subexpr","@mut",[["get","mediaCategory",["loc",[null,[17,22],[17,35]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","name","wish-doctor","placeholder","bitte wählen"],["loc",[null,[15,14],[22,16]]]],
          ["inline","input",[],["name","name","class","input input--editable input--full-width","placeholder",""],["loc",[null,[29,12],[29,96]]]],
          ["block","if",[["get","showUploadButton",["loc",[null,[33,16],[33,32]]]]],[],0,null,["loc",[null,[33,10],[43,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 12
              },
              "end": {
                "line": 84,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/media-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 ");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","small");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","small");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" MByte");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--action ");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/download.svg");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor input input--action");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/email-white.svg");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [7]);
            var element3 = dom.childAt(element1, [9]);
            var element4 = dom.childAt(element3, [3]);
            var element5 = dom.childAt(element3, [5]);
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [0]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [2]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
            morphs[6] = dom.createElementMorph(element4);
            morphs[7] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[67,32],[67,44]]]],"DD. MM. YYYY"],[],["loc",[null,[67,18],[67,61]]]],
            ["content","item.name",["loc",[null,[69,48],[69,61]]]],
            ["content","item.category.name",["loc",[null,[70,49],[70,71]]]],
            ["content","item.realfilename",["loc",[null,[71,68],[71,89]]]],
            ["inline","format-number-german",[["get","item.size",["loc",[null,[71,144],[71,153]]]]],[],["loc",[null,[71,121],[71,155]]]],
            ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[74,54],[74,58]]]]],[],[]],"size","32","confirmed","delete","content","Dokument löschen","text","Wollen Sie dieses Dokument wirklich löschen?"],["loc",[null,[74,20],[74,168]]]],
            ["element","action",["downloadFile",["get","item.id",["loc",[null,[76,45],[76,52]]]]],[],["loc",[null,[76,21],[76,54]]]],
            ["element","action",["forwardEmail",["get","item.id",["loc",[null,[79,45],[79,52]]]]],[],["loc",[null,[79,21],[79,54]]]]
          ],
          locals: ["item"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 6
            },
            "end": {
              "line": 87,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/media-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mt20 col l-12/24");
          var el2 = dom.createTextNode("Gespeicherte Dokumente");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mt20 col l-12/24");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button float-right");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-3/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","clickable is-highlighted");
          var el6 = dom.createTextNode("Datum");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-6/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Name");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-6/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Kategorie");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-6/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Ursprünglicher Dateiname");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-3/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [3, 1]);
          var element7 = dom.childAt(fragment, [5]);
          var element8 = dom.childAt(element7, [1, 1, 0, 0]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6,1,1);
          morphs[2] = dom.createElementMorph(element8);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[50,11],[50,38]]]],
          ["inline","button-add-entry",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[51,10],[51,108]]]],
          ["element","action",["sortable",["get","model.patient.id",["loc",[null,[57,133],[57,149]]]]],[],["loc",[null,[57,113],[57,151]]]],
          ["block","each",[["get","items",["loc",[null,[64,20],[64,25]]]]],[],0,null,["loc",[null,[64,12],[84,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 4
            },
            "end": {
              "line": 97,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/media-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","bar");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--small");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showList"],[],["loc",[null,[93,48],[93,69]]]],
          ["content","button-prev",["loc",[null,[94,8],[94,23]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 99,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/media-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-media-panel");
        dom.setAttribute(el1,"class","popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Dokumente");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element10, 'data-id');
        morphs[1] = dom.createAttrMorph(element10, 'data-patient-id');
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [1, 3]),1,1);
        morphs[3] = dom.createMorphAt(element10,3,3);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","model.id",["loc",[null,[1,115],[1,123]]]]]]],
        ["attribute","data-patient-id",["concat",[["get","model.patient.id",["loc",[null,[1,146],[1,162]]]]]]],
        ["block","if",[["get","showUploadForm",["loc",[null,[9,12],[9,26]]]]],[],0,1,["loc",[null,[9,6],[87,13]]]],
        ["block","if",[["get","showUploadForm",["loc",[null,[91,10],[91,24]]]]],[],2,null,["loc",[null,[91,4],[97,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
define('jason-frontend/templates/components/customer-etiga', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 40
              },
              "end": {
                "line": 6,
                "column": 78
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Nicht mit eltiga verknüpft");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 86
                },
                "end": {
                  "line": 7,
                  "column": 69
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-success");
              var el2 = dom.createTextNode("Mit eltiga verknüpft");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 69
                },
                "end": {
                  "line": 8,
                  "column": 78
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label label-unselected");
              var el2 = dom.createTextNode("Nicht mit eltiga verknüpft");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 78
              },
              "end": {
                "line": 8,
                "column": 85
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.etigaActive",["loc",[null,[6,92],[6,111]]]]],[],0,1,["loc",[null,[6,86],[8,85]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 41
              },
              "end": {
                "line": 12,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-disconnect-user",[],["item",["subexpr","@mut",[["get","patient",["loc",[null,[10,38],[10,45]]]]],[],[]],"confirmed","disconnectEtiga","color","#3B6182","size","32","showStroke",false,"content","","text","Wollen Sie Ihre eigenen Stammdaten wirklich von eltiga trennen?"],["loc",[null,[10,8],[11,116]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 8
                },
                "end": {
                  "line": 18,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","ml10 label inline-block button verticaltop button purple-style");
              var el2 = dom.createTextNode("Mit eltiga\n            verknüpfen\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element12);
              return morphs;
            },
            statements: [
              ["element","action",["chooseEtigaPanel",["get","patient.id",["loc",[null,[15,93],[15,103]]]]],[],["loc",[null,[14,84],[15,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 10
                  },
                  "end": {
                    "line": 23,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","ml10 label inline-block purple-style button verticaltop");
                var el2 = dom.createTextNode("Mit\n              eltiga verknüpfen\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element11);
                return morphs;
              },
              statements: [
                ["element","action",["activateEtiga",["get","patient",["loc",[null,[20,104],[20,111]]]]],[],["loc",[null,[20,79],[20,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 8
                },
                "end": {
                  "line": 24,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","has-permission",["etiga"],[],["loc",[null,[19,16],[19,40]]]]],[],0,null,["loc",[null,[19,10],[23,17]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 25,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.etigaMaster",["loc",[null,[13,14],[13,33]]]]],[],0,1,["loc",[null,[13,8],[24,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 40
              },
              "end": {
                "line": 29,
                "column": 73
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createTextNode("Eigene Daten freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 73
              },
              "end": {
                "line": 30,
                "column": 83
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Keine eigenen Daten freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 8
              },
              "end": {
                "line": 37,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/share-data-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Daten freigeben");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element10);
            return morphs;
          },
          statements: [
            ["element","action",["addEtigaShare"],[],["loc",[null,[33,43],[33,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 40
              },
              "end": {
                "line": 42,
                "column": 75
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createTextNode("Daten für mich freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 75
              },
              "end": {
                "line": 43,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-unselected");
            var el2 = dom.createTextNode("Keine Daten für mich freigegeben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 91
                  },
                  "end": {
                    "line": 55,
                    "column": 121
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("von");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 121
                  },
                  "end": {
                    "line": 55,
                    "column": 132
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("für");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 18
                    },
                    "end": {
                      "line": 70,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","entry.mandantName",["loc",[null,[68,22],[68,43]]]]
                ],
                locals: ["entry"],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 74,
                        "column": 20
                      },
                      "end": {
                        "line": 87,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","form-field-rework mb-0");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("label");
                    dom.setAttribute(el2,"class","select-placeholder");
                    var el3 = dom.createTextNode("Auswahl");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
                    return morphs;
                  },
                  statements: [
                    ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesListReadWriteOnly",["loc",[null,[78,34],[78,66]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionOwner",["loc",[null,[79,32],[79,53]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false],["loc",[null,[77,24],[85,26]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 88,
                            "column": 22
                          },
                          "end": {
                            "line": 88,
                            "column": 121
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission label");
                        var el2 = dom.createTextNode("Keine");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 89,
                            "column": 22
                          },
                          "end": {
                            "line": 89,
                            "column": 126
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission read label");
                        var el2 = dom.createTextNode("Lesen");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 90,
                            "column": 22
                          },
                          "end": {
                            "line": 90,
                            "column": 133
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission write label");
                        var el2 = dom.createTextNode("Bearbeiten");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 87,
                          "column": 20
                        },
                        "end": {
                          "line": 91,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(3);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                      morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[88,32],[88,56]]]],"none"],[],["loc",[null,[88,28],[88,64]]]]],[],0,null,["loc",[null,[88,22],[88,128]]]],
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[89,32],[89,56]]]],"read"],[],["loc",[null,[89,28],[89,64]]]]],[],1,null,["loc",[null,[89,22],[89,133]]]],
                      ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[90,32],[90,56]]]],"write"],[],["loc",[null,[90,28],[90,65]]]]],[],2,null,["loc",[null,[90,22],[90,140]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 91,
                          "column": 20
                        },
                        "end": {
                          "line": 104,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","form-field-rework");
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("label");
                      dom.setAttribute(el2,"class","select-placeholder");
                      var el3 = dom.createTextNode("Auswahl");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                    ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
                      return morphs;
                    },
                    statements: [
                      ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesList",["loc",[null,[95,34],[95,53]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionPractice",["loc",[null,[96,32],[96,56]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false],["loc",[null,[94,24],[102,26]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 87,
                        "column": 20
                      },
                      "end": {
                        "line": 104,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","or",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[87,38],[87,53]]]],4],[],["loc",[null,[87,34],[87,56]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[87,61],[87,76]]]],"masterData"],[],["loc",[null,[87,57],[87,90]]]]],[],["loc",[null,[87,30],[87,91]]]]],[],0,1,["loc",[null,[87,20],[104,20]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 18
                    },
                    "end": {
                      "line": 105,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[74,30],[74,45]]]],"masterData"],[],["loc",[null,[74,26],[74,59]]]]],[],0,1,["loc",[null,[74,20],[104,27]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 110,
                        "column": 22
                      },
                      "end": {
                        "line": 112,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.date",["loc",[null,[111,24],[111,38]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 116,
                          "column": 24
                        },
                        "end": {
                          "line": 118,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-copy-permissions",[],["reference",["subexpr","@mut",[["get","entry.mandantId",["loc",[null,[117,62],[117,77]]]]],[],[]],"type","prim","content","Datum für alle Berechtigungen übernehmen","color","#ADCCD4","size",26,"copyPermissions","expandDate","classNames","icon-button list-action-square exp"],["loc",[null,[117,26],[117,243]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 112,
                        "column": 22
                      },
                      "end": {
                        "line": 119,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [3]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createElementMorph(element7);
                    morphs[2] = dom.createMorphAt(element7,1,1);
                    morphs[3] = dom.createMorphAt(fragment,5,5,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","input",[],["value",["subexpr","@mut",[["get","entry.date",["loc",[null,[113,38],[113,48]]]]],[],[]],"class",["subexpr","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[113,64],[113,79]]]],"customerData"],[],["loc",[null,[113,60],[113,95]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[113,104],[113,115]]]]," mandant",["get","entry.mandantId",["loc",[null,[113,126],[113,141]]]]," prim js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[113,96],[113,213]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[113,222],[113,233]]]]," mandant",["get","entry.mandantId",["loc",[null,[113,244],[113,259]]]]," js-date-picker input date-picker__input fixed-datepicker-width"],[],["loc",[null,[113,214],[113,326]]]]],[],["loc",[null,[113,56],[113,327]]]],"name","entry-date"],["loc",[null,[113,24],[113,347]]]],
                    ["element","action",["deleteDate",["get","entry",["loc",[null,[114,49],[114,54]]]]],[],["loc",[null,[114,27],[114,56]]]],
                    ["inline","button-delete-list",[],["size","26","classNames","cursor inline-block delete-etiga-permission"],["loc",[null,[115,26],[115,115]]]],
                    ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[116,34],[116,49]]]],"customerData"],[],["loc",[null,[116,30],[116,65]]]]],[],0,null,["loc",[null,[116,24],[118,31]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 18
                    },
                    "end": {
                      "line": 121,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","readOnly",["loc",[null,[110,28],[110,36]]]]],[],0,1,["loc",[null,[110,22],[119,29]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 14
                  },
                  "end": {
                    "line": 124,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Datentyp");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                dom.setAttribute(el2,"data-label","Freigegeben für");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                dom.setAttribute(el2,"data-label","Berechtigung");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Daten seit");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element8, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element8, [1, 1]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
                morphs[4] = dom.createMorphAt(dom.childAt(element8, [7]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["table__row ",["get","item.permission",["loc",[null,[61,41],[61,56]]]]]]],
                ["inline","get-permission-title",[["get","item.permission",["loc",[null,[63,47],[63,62]]]]],[],["loc",[null,[63,24],[63,64]]]],
                ["block","each",[["get","item.entries",["loc",[null,[66,26],[66,38]]]]],[],0,null,["loc",[null,[66,18],[70,27]]]],
                ["block","each",[["get","item.entries",["loc",[null,[73,26],[73,38]]]]],[],1,null,["loc",[null,[73,18],[105,27]]]],
                ["block","each",[["get","item.entries",["loc",[null,[108,26],[108,38]]]]],[],2,null,["loc",[null,[108,18],[121,27]]]]
              ],
              locals: ["item"],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 4
                },
                "end": {
                  "line": 128,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table-wrapper-rework");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table grid table--large");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__head");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-6/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Datentyp");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-8/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Freigegeben ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-4/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Berechtigung");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-6/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Daten seit");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__body");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1, 3, 0]),1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","patient.etigaMaster",["loc",[null,[55,97],[55,116]]]]],[],0,1,["loc",[null,[55,91],[55,139]]]],
              ["block","each",[["get","permissions.masterEntries",["loc",[null,[60,22],[60,47]]]]],[],2,null,["loc",[null,[60,14],[124,23]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 145,
                      "column": 18
                    },
                    "end": {
                      "line": 149,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","line");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","entry.mandantName",["loc",[null,[147,22],[147,43]]]]
                ],
                locals: ["entry"],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 154,
                        "column": 20
                      },
                      "end": {
                        "line": 156,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","label-etiga-permission label");
                    var el2 = dom.createTextNode("Keine");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 156,
                          "column": 20
                        },
                        "end": {
                          "line": 158,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 158,
                            "column": 20
                          },
                          "end": {
                            "line": 160,
                            "column": 20
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","label-etiga-permission read label");
                        var el2 = dom.createTextNode("Lesen");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 160,
                              "column": 20
                            },
                            "end": {
                              "line": 173,
                              "column": 20
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                      ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","form-field-rework mb-0");
                          var el2 = dom.createTextNode("\n                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("label");
                          dom.setAttribute(el2,"class","select-placeholder");
                          var el3 = dom.createTextNode("Auswahl");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createComment("");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
                          return morphs;
                        },
                        statements: [
                          ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesListReadWriteOnly",["loc",[null,[164,34],[164,66]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionOwner",["loc",[null,[165,32],[165,53]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false],["loc",[null,[163,24],[171,26]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 174,
                                  "column": 22
                                },
                                "end": {
                                  "line": 174,
                                  "column": 121
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission label");
                              var el2 = dom.createTextNode("Keine");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 175,
                                  "column": 22
                                },
                                "end": {
                                  "line": 175,
                                  "column": 126
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission read label");
                              var el2 = dom.createTextNode("Lesen");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child2 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 176,
                                  "column": 22
                                },
                                "end": {
                                  "line": 176,
                                  "column": 133
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1,"class","label-etiga-permission write label");
                              var el2 = dom.createTextNode("Bearbeiten");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 173,
                                "column": 20
                              },
                              "end": {
                                "line": 177,
                                "column": 20
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                      ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                      ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                      ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(3);
                            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[174,32],[174,56]]]],"none"],[],["loc",[null,[174,28],[174,64]]]]],[],0,null,["loc",[null,[174,22],[174,128]]]],
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[175,32],[175,56]]]],"read"],[],["loc",[null,[175,28],[175,64]]]]],[],1,null,["loc",[null,[175,22],[175,133]]]],
                            ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[176,32],[176,56]]]],"write"],[],["loc",[null,[176,28],[176,65]]]]],[],2,null,["loc",[null,[176,22],[176,140]]]]
                          ],
                          locals: [],
                          templates: [child0, child1, child2]
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 177,
                                "column": 20
                              },
                              "end": {
                                "line": 190,
                                "column": 20
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                      ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","form-field-rework mb-0");
                            var el2 = dom.createTextNode("\n                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("label");
                            dom.setAttribute(el2,"class","select-placeholder");
                            var el3 = dom.createTextNode("Auswahl");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createComment("");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n                    ");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
                            return morphs;
                          },
                          statements: [
                            ["inline","select-2",[],["content",["subexpr","@mut",[["get","permissionTypesList",["loc",[null,[181,34],[181,53]]]]],[],[]],"value",["subexpr","@mut",[["get","entry.permissionPractice",["loc",[null,[182,32],[182,56]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","searchEnabled",false,"optionLabelPath","value","optionValuePath","id","allowClear",false],["loc",[null,[180,24],[188,26]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 173,
                              "column": 20
                            },
                            "end": {
                              "line": 190,
                              "column": 20
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[173,34],[173,49]]]],4],[],["loc",[null,[173,30],[173,52]]]]],[],0,1,["loc",[null,[173,20],[190,20]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 160,
                            "column": 20
                          },
                          "end": {
                            "line": 190,
                            "column": 20
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[160,39],[160,54]]]],4],[],["loc",[null,[160,35],[160,57]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[160,62],[160,77]]]],"addWeight"],[],["loc",[null,[160,58],[160,90]]]]],[],["loc",[null,[160,30],[160,91]]]]],[],0,1,["loc",[null,[160,20],[190,20]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 158,
                          "column": 20
                        },
                        "end": {
                          "line": 190,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[158,34],[158,49]]]],"addDocs"],[],["loc",[null,[158,30],[158,60]]]]],[],0,1,["loc",[null,[158,20],[190,20]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 156,
                        "column": 20
                      },
                      "end": {
                        "line": 190,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[156,34],[156,49]]]],"addCorr"],[],["loc",[null,[156,30],[156,60]]]]],[],0,1,["loc",[null,[156,20],[190,20]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 152,
                      "column": 18
                    },
                    "end": {
                      "line": 191,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[154,35],[154,50]]]],4],[],["loc",[null,[154,31],[154,53]]]],["subexpr","or",[["subexpr","eq",[["get","item.permission",["loc",[null,[154,62],[154,77]]]],"addAnam"],[],["loc",[null,[154,58],[154,88]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[154,93],[154,108]]]],"addSymp"],[],["loc",[null,[154,89],[154,119]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[154,124],[154,139]]]],"addThera"],[],["loc",[null,[154,120],[154,151]]]]],[],["loc",[null,[154,54],[154,152]]]]],[],["loc",[null,[154,26],[154,153]]]]],[],0,1,["loc",[null,[154,20],[190,27]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 195,
                        "column": 22
                      },
                      "end": {
                        "line": 197,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 197,
                          "column": 22
                        },
                        "end": {
                          "line": 199,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 200,
                            "column": 24
                          },
                          "end": {
                            "line": 202,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["content","entry.date",["loc",[null,[201,26],[201,40]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 210,
                              "column": 28
                            },
                            "end": {
                              "line": 212,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["inline","button-copy-permissions",[],["reference",["subexpr","@mut",[["get","entry.mandantId",["loc",[null,[211,66],[211,81]]]]],[],[]],"type","prim2","content","Datum für alle Berechtigungen übernehmen","color","#ADCCD4","size",26,"copyPermissions","expandDate","classNames","icon-button icon-button--default list-action-square"],["loc",[null,[211,30],[211,265]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 202,
                            "column": 24
                          },
                          "end": {
                            "line": 214,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        var el2 = dom.createTextNode("\n                            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2,"class","form-field-rework mb-0");
                        var el3 = dom.createTextNode("\n                              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("label");
                        dom.setAttribute(el3,"class","placeholder");
                        var el4 = dom.createTextNode("Daten seit");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                              ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("img");
                        dom.setAttribute(el3,"src","assets/images/icons/rework/calendar-light-dark.svg");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                            ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                            ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("a");
                        dom.setAttribute(el2,"class","ms-10 icon-button icon-button--delete list-action-square");
                        var el3 = dom.createElement("img");
                        dom.setAttribute(el3,"src","assets/images/icons/rework/trash.svg");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("                          ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element4 = dom.childAt(fragment, [1]);
                        var element5 = dom.childAt(element4, [3]);
                        var morphs = new Array(4);
                        morphs[0] = dom.createAttrMorph(element4, 'class');
                        morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
                        morphs[2] = dom.createElementMorph(element5);
                        morphs[3] = dom.createMorphAt(element4,5,5);
                        return morphs;
                      },
                      statements: [
                        ["attribute","class",["concat",["d-flex align-items-center ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","item.permission",["loc",[null,[203,89],[203,104]]]],"addDiag"],[],["loc",[null,[203,85],[203,115]]]],"","hidden"],[],["loc",[null,[203,64],[203,129]]]]]]],
                        ["inline","input",[],["value",["subexpr","@mut",[["get","entry.date",["loc",[null,[205,44],[205,54]]]]],[],[]],"class",["subexpr","if",[["subexpr","eq",[["get","item.permission",["loc",[null,[205,69],[205,84]]]],"addDiag"],[],["loc",[null,[205,65],[205,95]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[205,104],[205,115]]]]," mandant",["get","entry.mandantId",["loc",[null,[205,126],[205,141]]]]," prim2 js-date-picker"],[],["loc",[null,[205,96],[205,166]]]],["subexpr","concat",[["get","entry.title",["loc",[null,[205,176],[205,187]]]]," mandant",["get","entry.mandantId",["loc",[null,[205,198],[205,213]]]]," js-date-picker"],[],["loc",[null,[205,167],[205,232]]]]],[],["loc",[null,[205,61],[205,233]]]],"name","entry-date"],["loc",[null,[205,30],[205,253]]]],
                        ["element","action",["deleteDate",["get","entry",["loc",[null,[209,118],[209,123]]]]],[],["loc",[null,[209,96],[209,125]]]],
                        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[210,43],[210,58]]]],4],[],["loc",[null,[210,39],[210,61]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[210,66],[210,81]]]],"addDiag"],[],["loc",[null,[210,62],[210,92]]]]],[],["loc",[null,[210,34],[210,93]]]]],[],0,null,["loc",[null,[210,28],[212,35]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 199,
                          "column": 22
                        },
                        "end": {
                          "line": 215,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","readOnly",["loc",[null,[200,30],[200,38]]]]],[],0,1,["loc",[null,[200,24],[214,31]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 197,
                        "column": 22
                      },
                      "end": {
                        "line": 215,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[197,41],[197,56]]]],4],[],["loc",[null,[197,37],[197,59]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[197,64],[197,79]]]],"addWeight"],[],["loc",[null,[197,60],[197,92]]]]],[],["loc",[null,[197,32],[197,93]]]]],[],0,1,["loc",[null,[197,22],[215,22]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 194,
                      "column": 18
                    },
                    "end": {
                      "line": 216,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["subexpr","eq",[["get","entry.mandantId",["loc",[null,[195,37],[195,52]]]],4],[],["loc",[null,[195,33],[195,55]]]],["subexpr","or",[["subexpr","eq",[["get","item.permission",["loc",[null,[195,64],[195,79]]]],"addAnam"],[],["loc",[null,[195,60],[195,90]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[195,95],[195,110]]]],"addSymp"],[],["loc",[null,[195,91],[195,121]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[195,126],[195,141]]]],"addCorr"],[],["loc",[null,[195,122],[195,152]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[195,157],[195,172]]]],"addThera"],[],["loc",[null,[195,153],[195,184]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[195,189],[195,204]]]],"addLabor"],[],["loc",[null,[195,185],[195,216]]]],["subexpr","eq",[["get","item.permission",["loc",[null,[195,221],[195,236]]]],"addDocs"],[],["loc",[null,[195,217],[195,247]]]]],[],["loc",[null,[195,56],[195,248]]]]],[],["loc",[null,[195,28],[195,249]]]]],[],0,1,["loc",[null,[195,22],[215,29]]]]
                ],
                locals: ["entry"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 139,
                    "column": 14
                  },
                  "end": {
                    "line": 219,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Datentyp");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                dom.setAttribute(el2,"data-label","Freigegeben für");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                dom.setAttribute(el2,"data-label","Berechtigung");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                dom.setAttribute(el2,"data-label","Daten seit");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
                return morphs;
              },
              statements: [
                ["inline","get-permission-title",[["get","item.permission",["loc",[null,[142,47],[142,62]]]]],[],["loc",[null,[142,24],[142,64]]]],
                ["block","each",[["get","item.entries",["loc",[null,[145,26],[145,38]]]]],[],0,null,["loc",[null,[145,18],[149,27]]]],
                ["block","each",[["get","item.entries",["loc",[null,[152,26],[152,38]]]]],[],1,null,["loc",[null,[152,18],[191,27]]]],
                ["block","each",[["get","item.entries",["loc",[null,[194,26],[194,38]]]]],[],2,null,["loc",[null,[194,18],[216,27]]]]
              ],
              locals: ["item"],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 129,
                  "column": 8
                },
                "end": {
                  "line": 223,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table-wrapper-rework");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table grid table--large");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__head");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-6/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Datentyp");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-8/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Freigegeben");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-4/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Berechtigung");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","table__cell l-6/24");
              var el5 = dom.createElement("span");
              dom.setAttribute(el5,"class","table__title title");
              var el6 = dom.createTextNode("Daten seit");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__body");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","permissions.additionalEntries",["loc",[null,[139,22],[139,51]]]]],[],0,null,["loc",[null,[139,14],[219,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 223,
                  "column": 8
                },
                "end": {
                  "line": 225,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ps-10");
              var el2 = dom.createTextNode("Derzeit keine Freigabe eingerichtet");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 247,
                        "column": 16
                      },
                      "end": {
                        "line": 251,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.mandantName",["loc",[null,[249,20],[249,41]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 256,
                          "column": 20
                        },
                        "end": {
                          "line": 256,
                          "column": 119
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission label");
                      var el2 = dom.createTextNode("Keine");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 257,
                          "column": 20
                        },
                        "end": {
                          "line": 257,
                          "column": 124
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 258,
                          "column": 20
                        },
                        "end": {
                          "line": 258,
                          "column": 131
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission write label");
                      var el2 = dom.createTextNode("Bearbeiten");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 254,
                        "column": 16
                      },
                      "end": {
                        "line": 260,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element2,1,1);
                    morphs[1] = dom.createMorphAt(element2,3,3);
                    morphs[2] = dom.createMorphAt(element2,5,5);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[256,30],[256,54]]]],"none"],[],["loc",[null,[256,26],[256,62]]]]],[],0,null,["loc",[null,[256,20],[256,126]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[257,30],[257,54]]]],"read"],[],["loc",[null,[257,26],[257,62]]]]],[],1,null,["loc",[null,[257,20],[257,131]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[258,30],[258,54]]]],"write"],[],["loc",[null,[258,26],[258,63]]]]],[],2,null,["loc",[null,[258,20],[258,138]]]]
                  ],
                  locals: ["entry"],
                  templates: [child0, child1, child2]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 263,
                        "column": 16
                      },
                      "end": {
                        "line": 265,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","item.date",["loc",[null,[264,36],[264,49]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 241,
                      "column": 12
                    },
                    "end": {
                      "line": 268,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                  dom.setAttribute(el2,"data-label","Datentyp");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                  dom.setAttribute(el2,"data-label","Freigegeben für");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Berechtigung");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Daten seit");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element3, 'class');
                  morphs[1] = dom.createMorphAt(dom.childAt(element3, [1, 1]),0,0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["table__row ",["get","item.permission",["loc",[null,[242,39],[242,54]]]]]]],
                  ["inline","get-permission-title",[["get","item.permission",["loc",[null,[244,45],[244,60]]]]],[],["loc",[null,[244,22],[244,62]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[247,24],[247,36]]]]],[],0,null,["loc",[null,[247,16],[251,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[254,24],[254,36]]]]],[],1,null,["loc",[null,[254,16],[260,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[263,24],[263,36]]]]],[],2,null,["loc",[null,[263,16],[265,25]]]]
                ],
                locals: ["item"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 230,
                    "column": 6
                  },
                  "end": {
                    "line": 272,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Folgende Daten des ausgewählten Patienten wurden von einer anderen Praxis für Sie freigegeben:");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table-wrapper-rework");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table grid table--large hidden");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__head");
                var el4 = dom.createTextNode("\n            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-6/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Datentyp");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-8/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Freigegeben von");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-4/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Berechtigung");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-4/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Daten seit");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__body");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("          ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","myPermissions.masterEntries",["loc",[null,[241,20],[241,47]]]]],[],0,null,["loc",[null,[241,12],[268,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 272,
                    "column": 6
                  },
                  "end": {
                    "line": 274,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","pt20 pl20");
                var el2 = dom.createTextNode("Derzeit wurden keine eltiga Daten zu diesem Patienten für Sie freigegeben");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 291,
                        "column": 16
                      },
                      "end": {
                        "line": 295,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.mandantName",["loc",[null,[293,20],[293,41]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 300,
                          "column": 20
                        },
                        "end": {
                          "line": 300,
                          "column": 119
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission label");
                      var el2 = dom.createTextNode("Keine");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 301,
                          "column": 20
                        },
                        "end": {
                          "line": 301,
                          "column": 124
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission read label");
                      var el2 = dom.createTextNode("Lesen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 302,
                          "column": 20
                        },
                        "end": {
                          "line": 302,
                          "column": 131
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","label-etiga-permission write label");
                      var el2 = dom.createTextNode("Bearbeiten");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 298,
                        "column": 16
                      },
                      "end": {
                        "line": 304,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element0,1,1);
                    morphs[1] = dom.createMorphAt(element0,3,3);
                    morphs[2] = dom.createMorphAt(element0,5,5);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[300,30],[300,54]]]],"none"],[],["loc",[null,[300,26],[300,62]]]]],[],0,null,["loc",[null,[300,20],[300,126]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[301,30],[301,54]]]],"read"],[],["loc",[null,[301,26],[301,62]]]]],[],1,null,["loc",[null,[301,20],[301,131]]]],
                    ["block","if",[["subexpr","eq",[["get","entry.permissionPractice",["loc",[null,[302,30],[302,54]]]],"write"],[],["loc",[null,[302,26],[302,63]]]]],[],2,null,["loc",[null,[302,20],[302,138]]]]
                  ],
                  locals: ["entry"],
                  templates: [child0, child1, child2]
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 307,
                        "column": 16
                      },
                      "end": {
                        "line": 309,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","line");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","item.date",["loc",[null,[308,36],[308,49]]]]
                  ],
                  locals: ["entry"],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 285,
                      "column": 12
                    },
                    "end": {
                      "line": 312,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","table__row");
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-6/24@desk");
                  dom.setAttribute(el2,"data-label","Datentyp");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-8/24@desk");
                  dom.setAttribute(el2,"data-label","Freigegeben für");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Berechtigung");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","table__cell l-4/24@desk");
                  dom.setAttribute(el2,"data-label","Daten seit");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]),0,0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","get-permission-title",[["get","item.permission",["loc",[null,[288,45],[288,60]]]]],[],["loc",[null,[288,22],[288,62]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[291,24],[291,36]]]]],[],0,null,["loc",[null,[291,16],[295,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[298,24],[298,36]]]]],[],1,null,["loc",[null,[298,16],[304,25]]]],
                  ["block","each",[["get","item.entries",["loc",[null,[307,24],[307,36]]]]],[],2,null,["loc",[null,[307,16],[309,25]]]]
                ],
                locals: ["item"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 275,
                    "column": 6
                  },
                  "end": {
                    "line": 316,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table-wrapper-rework");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table table--large grid");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__head");
                var el4 = dom.createTextNode("\n            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-6/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Datentyp");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-8/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Freigegeben von");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-4/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Berechtigung");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4,"class","table__cell l-4/24");
                var el5 = dom.createElement("span");
                dom.setAttribute(el5,"class","table__title title");
                var el6 = dom.createTextNode("Daten seit");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("\n      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","table__body");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("          ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","myPermissions.additionalEntries",["loc",[null,[285,20],[285,51]]]]],[],0,null,["loc",[null,[285,12],[312,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 228,
                  "column": 4
                },
                "end": {
                  "line": 317,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","list-not-empty",[["get","myPermissions.masterEntries",["loc",[null,[230,28],[230,55]]]]],[],["loc",[null,[230,12],[230,56]]]]],[],0,1,["loc",[null,[230,6],[274,13]]]],
              ["block","if",[["subexpr","list-not-empty",[["get","myPermissions.additionalEntries",["loc",[null,[275,28],[275,59]]]]],[],["loc",[null,[275,12],[275,60]]]]],[],2,null,["loc",[null,[275,6],[316,13]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 318,
                  "column": 4
                },
                "end": {
                  "line": 320,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","js-datepicker-script",["loc",[null,[319,6],[319,30]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 2
              },
              "end": {
                "line": 321,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,4,4,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","list-not-empty",[["get","permissions.masterEntries",["loc",[null,[50,31],[50,56]]]]],[],["loc",[null,[50,15],[50,57]]]]],[],["loc",[null,[50,10],[50,58]]]]],[],0,null,["loc",[null,[50,4],[128,15]]]],
            ["block","if",[["subexpr","list-not-empty",[["get","permissions.additionalEntries",["loc",[null,[129,30],[129,59]]]]],[],["loc",[null,[129,14],[129,60]]]]],[],1,2,["loc",[null,[129,8],[225,15]]]],
            ["block","if",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[228,15],[228,34]]]]],[],["loc",[null,[228,10],[228,35]]]]],[],3,null,["loc",[null,[228,4],[317,11]]]],
            ["block","if",[["subexpr","and",[["get","permissions.master",["loc",[null,[318,15],[318,33]]]],["get","permissions.additional",["loc",[null,[318,34],[318,56]]]]],[],["loc",[null,[318,10],[318,57]]]]],[],4,null,["loc",[null,[318,4],[320,11]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 323,
                  "column": 4
                },
                "end": {
                  "line": 325,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("<p>Die Freigabe der Daten des ausgewählten Patienten für die elektronische Tiergesundheitsakte wurde nicht aktiviert.</p>");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 321,
                "column": 2
              },
              "end": {
                "line": 326,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","patient.etigaActive",["loc",[null,[323,15],[323,34]]]]],[],["loc",[null,[323,10],[323,35]]]]],[],0,null,["loc",[null,[323,4],[325,11]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 333,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class"," card-content");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","grid");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb30 pt10 l-8/24");
          var el4 = dom.createTextNode("Verknüpfung mit eigenen Stammdaten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb30 pt10 l-8/24");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb30  l-8/24 pt2");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb10 pt10 l-8/24");
          var el4 = dom.createTextNode("Freigabe der eigenen Daten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb10 pt10 l-8/24");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb30  l-8/24");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb10 pt10 l-8/24");
          var el4 = dom.createTextNode("Freigabe von Daten für mich");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb10 pt10 l-8/24");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col mb30  l-8/24");
          var el4 = dom.createTextNode("\n\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [5]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element13, [9]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element13, [11]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element13, [15]),0,0);
          morphs[5] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[6] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","patient.etigaMaster",["loc",[null,[5,46],[5,65]]]]],[],0,1,["loc",[null,[5,40],[8,92]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[9,57],[9,76]]]]],[],["loc",[null,[9,52],[9,77]]]],["get","patient.etigaActive",["loc",[null,[9,78],[9,97]]]]],[],["loc",[null,[9,47],[9,98]]]]],[],2,3,["loc",[null,[9,41],[25,13]]]],
          ["block","if",[["subexpr","and",[["get","patient.etigaActive",["loc",[null,[28,13],[28,32]]]],["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[28,38],[28,57]]]]],[],["loc",[null,[28,33],[28,58]]]],["subexpr","list-not-empty",[["get","permissions.additionalEntries",["loc",[null,[28,75],[28,104]]]]],[],["loc",[null,[28,59],[28,105]]]]],[],["loc",[null,[28,8],[28,106]]]]],[],4,5,["loc",[null,[27,40],[30,90]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","patient.etigaMaster",["loc",[null,[32,24],[32,43]]]]],[],["loc",[null,[32,19],[32,44]]]],["get","patient.etigaActive",["loc",[null,[32,45],[32,64]]]]],[],["loc",[null,[32,14],[32,65]]]]],[],6,null,["loc",[null,[32,8],[37,15]]]],
          ["block","if",[["subexpr","or",[["get","patient.etigaMaster",["loc",[null,[41,12],[41,31]]]],["subexpr","list-not-empty",[["get","myPermissions.additionalEntries",["loc",[null,[41,48],[41,79]]]]],[],["loc",[null,[41,32],[41,80]]]]],[],["loc",[null,[41,8],[41,81]]]]],[],7,8,["loc",[null,[40,40],[43,91]]]],
          ["block","if",[["get","patient.etigaActive",["loc",[null,[49,8],[49,27]]]]],[],9,10,["loc",[null,[49,2],[326,9]]]],
          ["inline","etiga-app-permission-panel",[],["submitCredentials","submitCredentials","downloadEtigaQr","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","etigaAppPermissionPanel",["loc",[null,[331,19],[331,42]]]]],[],[]]],["loc",[null,[328,2],[331,44]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 333,
              "column": 0
            },
            "end": {
              "line": 335,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 336,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/customer-etiga.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[1,1],[],["loc",[null,[1,6],[1,14]]]]],[],0,1,["loc",[null,[1,0],[335,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
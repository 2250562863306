define('jason-frontend/templates/components/add-appointment-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 30
              },
              "end": {
                "line": 7,
                "column": 53
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Serie");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 53
              },
              "end": {
                "line": 7,
                "column": 67
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Termin");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 16
            },
            "end": {
              "line": 7,
              "column": 85
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" bearbeiten");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","editSeries",["loc",[null,[7,36],[7,46]]]]],[],0,1,["loc",[null,[7,30],[7,74]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 85
            },
            "end": {
              "line": 7,
              "column": 105
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 10
            },
            "end": {
              "line": 17,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-details");
          var el2 = dom.createTextNode("Details");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element21, 'class');
          morphs[1] = dom.createElementMorph(element21);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[15,61],[15,72]]]],"details"],[],["loc",[null,[15,57],[15,83]]]],"is-active",""],[],["loc",[null,[15,36],[15,100]]]]]]],
          ["element","action",["selectTab","details"],[],["loc",[null,[15,122],[16,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 16
              },
              "end": {
                "line": 54,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-6/24 gutter radio-custom radio-primary mb30");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","appTypeEtiga");
            var el3 = dom.createTextNode(" Smartphone App Terminslot");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","radio-button",[],["value","etiga","name","appType","radioId","appTypeEtiga","groupValue",["subexpr","@mut",[["get","app.appType",["loc",[null,[50,33],[50,44]]]]],[],[]]],["loc",[null,[46,20],[51,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 14
            },
            "end": {
              "line": 58,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter radio-custom radio-primary mb10");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","appTypeCust");
          var el3 = dom.createTextNode("Kundentermin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter radio-custom radio-primary mb30");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","appTypeUser");
          var el3 = dom.createTextNode("Mitarbeitertermin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-5/24 text-right");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(element19,1,1);
          morphs[2] = dom.createElementMorph(element20);
          morphs[3] = dom.createMorphAt(element20,1,1);
          morphs[4] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["changeToCustomer"],["bubbles",true],["loc",[null,[26,79],[26,121]]]],
          ["inline","radio-button",[],["value","cust","name","appType","radioId","appTypeCust","groupValue",["subexpr","@mut",[["get","app.appType",["loc",[null,[31,31],[31,42]]]]],[],[]]],["loc",[null,[27,18],[32,20]]]],
          ["element","action",["changeToUser"],["bubbles",true],["loc",[null,[35,79],[35,117]]]],
          ["inline","radio-button",[],["value","user","name","appType","radioId","appTypeUser","groupValue",["subexpr","@mut",[["get","app.appType",["loc",[null,[40,31],[40,42]]]]],[],[]]],["loc",[null,[36,18],[41,20]]]],
          ["block","if",[["subexpr","not",[["subexpr","has-booking-app",[],[],["loc",[null,[44,27],[44,44]]]]],[],["loc",[null,[44,22],[44,45]]]]],[],0,null,["loc",[null,[44,16],[54,23]]]],
          ["inline","appointment-status",[],["status",["subexpr","@mut",[["get","app.appStatus",["loc",[null,[56,46],[56,59]]]]],[],[]]],["loc",[null,[56,18],[56,61]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 71
              },
              "end": {
                "line": 64,
                "column": 118
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Titel / Anamnese");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 118
              },
              "end": {
                "line": 64,
                "column": 131
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Titel");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 14
            },
            "end": {
              "line": 67,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 gutter");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","appTitle");
          dom.setAttribute(el3,"class","placeholder required");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element18,1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","app.title",["loc",[null,[63,49],[63,58]]]]],[],[]],"name","appTitle"],["loc",[null,[63,20],[63,76]]]],
          ["block","if",[["subexpr","eq",[["get","app.appType",["loc",[null,[64,81],[64,92]]]],"cust"],[],["loc",[null,[64,77],[64,100]]]]],[],0,1,["loc",[null,[64,71],[64,138]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 14
            },
            "end": {
              "line": 171,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter field-datepicker");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","startDate");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Datum Bis");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/calendar-light-dark.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["placeholder","","id","endDate","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","app.dateEnd",["loc",[null,[166,100],[166,111]]]]],[],[]],"name","endDate"],["loc",[null,[166,20],[166,128]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 173,
              "column": 10
            },
            "end": {
              "line": 190,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 gutter");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Erinnerung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/notification-light-dark.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","reminders",["loc",[null,[178,26],[178,35]]]]],[],[]],"value",["subexpr","@mut",[["get","app.reminder",["loc",[null,[179,24],[179,36]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",true,"name","reminders","placeholder","Keine Erinnerung","cssClass","custom-select2 newStyle tuned"],["loc",[null,[177,16],[186,18]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 208,
                "column": 14
              },
              "end": {
                "line": 216,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field-datepicker");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","startDate");
            dom.setAttribute(el3,"class","placeholder");
            var el4 = dom.createTextNode("Letzte Wiederholung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/rework/calendar-light-dark.svg");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","input",[],["placeholder","","id","seriesEnd","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","app.seriesEnd",["loc",[null,[211,102],[211,115]]]]],[],[]],"name","seriesEnd"],["loc",[null,[211,20],[211,134]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 192,
              "column": 10
            },
            "end": {
              "line": 218,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Wiederholung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/repeat.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element17, [1]),3,3);
          morphs[2] = dom.createMorphAt(element17,3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["col l-24/24 gutter ",["subexpr","css-bool-evaluator",[["get","app.seriesInterval",["loc",[null,[193,64],[193,82]]]],"seriesExpanded",""],[],["loc",[null,[193,43],[193,104]]]]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","intervals",["loc",[null,[197,26],[197,35]]]]],[],[]],"value",["subexpr","@mut",[["get","app.seriesInterval",["loc",[null,[198,24],[198,42]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",true,"name","seriesInterval","placeholder","Keine Wiederholung","cssClass","custom-select2 newStyle tuned"],["loc",[null,[196,16],[205,18]]]],
          ["block","if",[["get","app.seriesInterval",["loc",[null,[208,20],[208,38]]]]],[],0,null,["loc",[null,[208,14],[216,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 260,
                "column": 12
              },
              "end": {
                "line": 279,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-12/24 gutter");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Kunde/in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","app.customer",["loc",[null,[265,26],[265,38]]]]],[],[]],"optionLabelPath","fullName","placeholder","Bitte Kunden wählen","allowClear",true,"typeaheadSearchingText","Suche Kunde","typeaheadNoMatchesText","Kein Kunden gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryCustomer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[264,18],[276,20]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 299,
                    "column": 18
                  },
                  "end": {
                    "line": 303,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"style","margin-top:7px");
                dom.setAttribute(el1,"class","button-rework icon-button list-action-square success icon-button--success");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element10);
                morphs[1] = dom.createMorphAt(element10,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["gotoTreatment",["get","app.treatmentPatientId",["loc",[null,[300,153],[300,175]]]]],[],["loc",[null,[300,128],[300,177]]]],
                ["inline","button-in-treatment",[],["size",40,"color","white","showStroke",false,"content","Zur Behandlung"],["loc",[null,[301,22],[301,109]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 303,
                    "column": 18
                  },
                  "end": {
                    "line": 307,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"style","margin-top:7px");
                dom.setAttribute(el1,"class","button-rework icon-button list-action-square success icon-button--success");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element9);
                morphs[1] = dom.createMorphAt(element9,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["admissionPatient"],[],["loc",[null,[304,128],[304,157]]]],
                ["inline","button-admission",[],["color","white","showStroke",false,"content","Check-In"],["loc",[null,[305,22],[305,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 298,
                  "column": 16
                },
                "end": {
                  "line": 308,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","app.treatmentPatientId",["loc",[null,[299,24],[299,46]]]]],[],0,1,["loc",[null,[299,18],[307,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 281,
                "column": 12
              },
              "end": {
                "line": 310,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-10/24 gutter");
            var el2 = dom.createTextNode("\n\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Patient/in");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-2/24 gutter");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","patients",["loc",[null,[287,28],[287,36]]]]],[],[]],"value",["subexpr","@mut",[["get","app.patient",["loc",[null,[288,26],[288,37]]]]],[],[]],"optionLabelPath","fullName","allowClear",true,"name","task-patient","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[286,18],[294,20]]]],
            ["block","if",[["subexpr","and",[["get","app.id",["loc",[null,[298,27],[298,33]]]],["get","showAdmission",["loc",[null,[298,34],[298,47]]]],["get","app.customer",["loc",[null,[298,48],[298,60]]]],["get","app.patient",["loc",[null,[298,61],[298,72]]]]],[],["loc",[null,[298,22],[298,73]]]]],[],0,null,["loc",[null,[298,16],[308,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 369,
                  "column": 22
                },
                "end": {
                  "line": 371,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge info-badge-small default");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","value.name",["loc",[null,[370,82],[370,96]]]]
            ],
            locals: ["value"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 368,
                "column": 20
              },
              "end": {
                "line": 371,
                "column": 31
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","app.introductionReasons",["loc",[null,[369,30],[369,53]]]]],[],0,null,["loc",[null,[369,22],[371,31]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 377,
                "column": 14
              },
              "end": {
                "line": 382,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field col l-24/24 gutter");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","field-label title");
            var el3 = dom.createTextNode("Wunschperson");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2,"class","mb10");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element8,0,0);
            morphs[1] = dom.createMorphAt(element8,2,2);
            return morphs;
          },
          statements: [
            ["content","app.preferredEmployee.firstname",["loc",[null,[380,34],[380,69]]]],
            ["content","app.preferredEmployee.lastname",["loc",[null,[380,70],[380,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 384,
                "column": 10
              },
              "end": {
                "line": 392,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1,"class","fs20 mt-10 mb-10 ms-0 me-0");
            var el2 = dom.createTextNode("Nachricht von Kund:in");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid gutter-fix");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24 gutter");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["content","app.notice",["loc",[null,[388,17],[388,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 399,
                  "column": 14
                },
                "end": {
                  "line": 399,
                  "column": 82
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","app.referenceData.address",["loc",[null,[399,48],[399,77]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 400,
                  "column": 14
                },
                "end": {
                  "line": 400,
                  "column": 114
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","app.referenceData.postalCode",["loc",[null,[400,50],[400,82]]]],
              ["content","app.referenceData.town",["loc",[null,[400,83],[400,109]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 401,
                  "column": 14
                },
                "end": {
                  "line": 401,
                  "column": 98
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["inline","get-country-name",[["get","app.referenceData.country",["loc",[null,[401,66],[401,91]]]]],[],["loc",[null,[401,47],[401,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 394,
                "column": 8
              },
              "end": {
                "line": 407,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1,"class","fs20 mt-10 mb-10 ms-0 me-0");
            var el2 = dom.createTextNode("Kontaktdaten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid gutter-fix");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24 gutter");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(element7,1,1);
            morphs[1] = dom.createMorphAt(element7,3,3);
            morphs[2] = dom.createMorphAt(element7,6,6);
            morphs[3] = dom.createMorphAt(element7,8,8);
            morphs[4] = dom.createMorphAt(element7,10,10);
            morphs[5] = dom.createMorphAt(element7,12,12);
            morphs[6] = dom.createMorphAt(element7,15,15);
            return morphs;
          },
          statements: [
            ["content","app.referenceData.firstname",["loc",[null,[398,14],[398,45]]]],
            ["content","app.referenceData.lastname",["loc",[null,[398,46],[398,76]]]],
            ["block","if",[["get","app.referenceData.address",["loc",[null,[399,20],[399,45]]]]],[],0,null,["loc",[null,[399,14],[399,89]]]],
            ["block","if",[["get","app.referenceData.postalCode",["loc",[null,[400,20],[400,48]]]]],[],1,null,["loc",[null,[400,14],[400,121]]]],
            ["block","if",[["get","app.referenceData.country",["loc",[null,[401,20],[401,45]]]]],[],2,null,["loc",[null,[401,14],[401,105]]]],
            ["content","app.referenceData.phone",["loc",[null,[402,14],[402,41]]]],
            ["content","app.referenceData.email",["loc",[null,[403,14],[403,41]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 222,
              "column": 12
            },
            "end": {
              "line": 409,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","fs20 mt-10 mb-10 ms-0 me-0");
          var el2 = dom.createTextNode("Termin");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid gutter-fix");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Terminart");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex align-items-center");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","select-placeholder");
          var el6 = dom.createTextNode("Bezeichnung (in App sichtbar)");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24 gutter");
          var el4 = dom.createTextNode("\n\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","form-field-rework");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","select-placeholder");
          var el6 = dom.createTextNode("Arzt/in");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24 gutter");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","form-field-rework");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","select-placeholder");
          var el6 = dom.createTextNode("Assistent/in");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex align-items-center");
          var el5 = dom.createTextNode("\n\n\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","textarea-wrapper");
          dom.setAttribute(el5,"style","width: 100%");
          var el6 = dom.createTextNode("\n                  ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                  ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","placeholder");
          var el7 = dom.createTextNode("Beschreibung (in App sichtbar)");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","fs20 mt-10 mb-10 ms-0 me-0");
          var el2 = dom.createTextNode("Vorstellungsgründe");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid gutter-fix mb20");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list-rework");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","cursor info-badge info-badge-small add");
          var el6 = dom.createElement("img");
          dom.setAttribute(el6,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(element11, [3, 1, 1]);
          var element13 = dom.childAt(element11, [9]);
          var element14 = dom.childAt(element11, [11, 1, 1]);
          var element15 = dom.childAt(fragment, [7]);
          var element16 = dom.childAt(element15, [1, 1, 1]);
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(element12,3,3);
          morphs[2] = dom.createMorphAt(element12,5,5);
          morphs[3] = dom.createMorphAt(element11,5,5);
          morphs[4] = dom.createMorphAt(element11,7,7);
          morphs[5] = dom.createMorphAt(dom.childAt(element13, [1, 1]),3,3);
          morphs[6] = dom.createMorphAt(dom.childAt(element13, [3, 1]),3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
          morphs[8] = dom.createMorphAt(element14,3,3);
          morphs[9] = dom.createElementMorph(element16);
          morphs[10] = dom.createMorphAt(element16,1,1);
          morphs[11] = dom.createMorphAt(element15,3,3);
          morphs[12] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[13] = dom.createMorphAt(fragment,11,11,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[229,26],[229,36]]]]],[],[]],"id","appCatgory","value",["subexpr","@mut",[["get","app.category",["loc",[null,[231,24],[231,36]]]]],[],[]],"optionLabelPath","name","placeholder","Bitte wählen","allowClear",true,"name","appointment-category","cssClass","custom-select2 newStyle tuned"],["loc",[null,[228,16],[237,18]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[246,28],[246,43]]]]],[],[]],"id","etigaAppCategory","value",["subexpr","@mut",[["get","app.etigaCategory",["loc",[null,[248,26],[248,43]]]]],[],[]],"optionLabelPath","name","name","etiga-appointment-category","cssClass","custom-select2 newStyle tuned"],["loc",[null,[245,18],[252,20]]]],
          ["inline","button-etiga-small",[],["content","","classNames","ps-10","size","16","width",28,"style",""],["loc",[null,[253,18],[253,98]]]],
          ["block","if",[["get","selectCustomers",["loc",[null,[260,18],[260,33]]]]],[],0,null,["loc",[null,[260,12],[279,19]]]],
          ["block","if",[["subexpr","or",[["get","patients",["loc",[null,[281,22],[281,30]]]],["get","app.patient",["loc",[null,[281,31],[281,42]]]]],[],["loc",[null,[281,18],[281,43]]]]],[],1,null,["loc",[null,[281,12],[310,19]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[319,26],[319,33]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalUser1",["loc",[null,[320,24],[320,41]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-person1","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[318,16],[326,18]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[333,26],[333,36]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalAssistant1",["loc",[null,[334,24],[334,46]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-assistant1","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[332,16],[340,18]]]],
          ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","app.description",["loc",[null,[351,44],[351,59]]]]],[],[]],"class","","name","appDescription","autocomplete","off","placeholder",""],["loc",[null,[351,18],[352,45]]]],
          ["inline","button-etiga-small",[],["content","","classNames","ps-10","size","16","width",28],["loc",[null,[355,18],[355,89]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","app.introductionReasons",["loc",[null,[367,99],[367,122]]]],"Vorstellungsgründe",["get","app",["loc",[null,[367,144],[367,147]]]]],[],["loc",[null,[367,36],[367,149]]]],
          ["block","if",[["get","app.introductionReasons",["loc",[null,[368,26],[368,49]]]]],[],2,null,["loc",[null,[368,20],[371,38]]]],
          ["block","if",[["get","app.preferredEmployee",["loc",[null,[377,20],[377,41]]]]],[],3,null,["loc",[null,[377,14],[382,21]]]],
          ["block","if",[["get","app.notice",["loc",[null,[384,16],[384,26]]]]],[],4,null,["loc",[null,[384,10],[392,17]]]],
          ["block","if",[["get","app.referenceData",["loc",[null,[394,14],[394,31]]]]],[],5,null,["loc",[null,[394,8],[407,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 449,
                    "column": 10
                  },
                  "end": {
                    "line": 468,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","dateRow col l-24/24 mt20");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","inline-block");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("label");
                dom.setAttribute(el3,"for","slotDuration");
                dom.setAttribute(el3,"class","inline-block select--inline input-element");
                dom.setAttribute(el3,"style","width: 607px;margin-right: -10px;");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Die aktuelle Auswahl erzeugt ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" App Terminslots mit der Dauer von ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Minuten");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" zwischen ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("br");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Uhr");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" und ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("b");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Uhr");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1, 1]);
                var element4 = dom.childAt(fragment, [3]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element3,1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
                morphs[2] = dom.createMorphAt(element4,1,1);
                morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
                morphs[4] = dom.createMorphAt(dom.childAt(element4, [6]),0,0);
                morphs[5] = dom.createMorphAt(dom.childAt(element4, [8]),0,0);
                return morphs;
              },
              statements: [
                ["inline","button-duration",[],["color","#ADCCD4","width","20","class","vertical-middle inline-block","content",""],["loc",[null,[452,16],[452,110]]]],
                ["inline","select-2",[],["content",["subexpr","@mut",[["get","durations",["loc",[null,[455,28],[455,37]]]]],[],[]],"value",["subexpr","@mut",[["get","app.slotDuration",["loc",[null,[456,26],[456,42]]]]],[],[]],"cssClass","custom-select2 newStyle small","optionLabelPath","value","optionValuePath","id","allowClear",true,"name","slotDuration","placeholder","Terminslot Dauer wählen"],["loc",[null,[454,18],[463,20]]]],
                ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[467,44],[467,125]]]],
                ["content","app.slotDuration",["loc",[null,[467,163],[467,183]]]],
                ["content","app.dateFrom",["loc",[null,[467,213],[467,229]]]],
                ["content","app.dateTo",["loc",[null,[467,245],[467,259]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 426,
                  "column": 14
                },
                "end": {
                  "line": 469,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","title field-label mb10");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","title field-label mb20");
              dom.setAttribute(el1,"style","color: #626262;clear:both;");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" App Terminslot Einstellungen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 radio-custom radio-primary ");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"for","slotTypeSingle");
              var el3 = dom.createTextNode("Einzeltermin");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-10/24 radio-custom radio-primary mb10");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"for","slotTypeMultiple");
              var el3 = dom.createTextNode("Mehrere Terminslots");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
              morphs[3] = dom.createMorphAt(fragment,9,9,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[428,82],[428,163]]]],
              ["inline","radio-button",[],["value","single","name","slotType","radioId","slotTypeSingle","groupValue",["subexpr","@mut",[["get","app.slotType",["loc",[null,[435,25],[435,37]]]]],[],[]]],["loc",[null,[431,12],[436,14]]]],
              ["inline","radio-button",[],["value","multiple","name","slotType","radioId","slotTypeMultiple","groupValue",["subexpr","@mut",[["get","app.slotType",["loc",[null,[444,25],[444,37]]]]],[],[]]],["loc",[null,[440,12],[445,14]]]],
              ["block","if",[["subexpr","eq",[["get","app.slotType",["loc",[null,[449,20],[449,32]]]],"multiple"],[],["loc",[null,[449,16],[449,44]]]]],[],0,null,["loc",[null,[449,10],[468,17]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 409,
                "column": 8
              },
              "end": {
                "line": 471,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid gutter-fix");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24 gutter");
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Bezeichnung (in App sichtbar) ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
            morphs[1] = dom.createMorphAt(element6,3,3);
            morphs[2] = dom.createMorphAt(element5,3,3);
            return morphs;
          },
          statements: [
            ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","16","width",28],["loc",[null,[414,82],[414,159]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","etigaCategories",["loc",[null,[416,28],[416,43]]]]],[],[]],"id","etigaAppCategory","value",["subexpr","@mut",[["get","app.etigaCategory",["loc",[null,[418,26],[418,43]]]]],[],[]],"optionLabelPath","name","allowClear",false,"name","etiga-appointment-category","cssClass","custom-select2 newStyle tuned"],["loc",[null,[415,18],[423,20]]]],
            ["block","if",[["subexpr","not",[["get","app.id",["loc",[null,[426,25],[426,31]]]]],[],["loc",[null,[426,20],[426,32]]]]],[],0,null,["loc",[null,[426,14],[469,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 471,
                "column": 8
              },
              "end": {
                "line": 498,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid gutter-fix");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24 gutter");
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("MitarbeiterIn");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","textarea-wrapper");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"class","placeholder");
            var el6 = dom.createTextNode("Beschreibung");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","users",["loc",[null,[478,28],[478,33]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalUser1",["loc",[null,[479,26],[479,43]]]]],[],[]],"optionLabelPath","shortName","allowClear",false,"name","app-internal-person1","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[477,18],[485,20]]]],
            ["inline","textarea",[],["rows","7","value",["subexpr","@mut",[["get","app.description",["loc",[null,[491,46],[491,61]]]]],[],[]],"class","","name","appDescription","autocomplete","off","placeholder",""],["loc",[null,[491,20],[492,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 409,
              "column": 8
            },
            "end": {
              "line": 498,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","app.appType",["loc",[null,[409,22],[409,33]]]],"etiga"],[],["loc",[null,[409,18],[409,42]]]]],[],0,1,["loc",[null,[409,8],[498,8]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 502,
              "column": 12
            },
            "end": {
              "line": 630,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid gutter-fix");
          var el2 = dom.createTextNode("\n\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Resource");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Behandlungs-Lokation");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Station");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Arzt/in 2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Arzt/in 3");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Assistent/in 2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Assistent/in 3");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","user-id");
          dom.setAttribute(el4,"class","placeholder");
          var el5 = dom.createTextNode("Person extern");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","user-id");
          dom.setAttribute(el4,"class","placeholder");
          var el5 = dom.createTextNode("Ort");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [7, 1]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [9, 1]),3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element1, [11, 1]),3,3);
          morphs[6] = dom.createMorphAt(dom.childAt(element1, [13, 1]),3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element1, [15, 1]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element1, [17, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","resources",["loc",[null,[510,28],[510,37]]]]],[],[]],"value",["subexpr","@mut",[["get","app.resource",["loc",[null,[511,26],[511,38]]]]],[],[]],"optionLabelPath","name","allowClear",true,"name","appointment-resource","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[509,18],[517,20]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[526,28],[526,33]]]]],[],[]],"value",["subexpr","@mut",[["get","app.room",["loc",[null,[527,26],[527,34]]]]],[],[]],"optionLabelPath","name","allowClear",true,"name","app-room","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[525,18],[533,20]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","stations",["loc",[null,[541,28],[541,36]]]]],[],[]],"value",["subexpr","@mut",[["get","app.station",["loc",[null,[542,26],[542,37]]]]],[],[]],"optionLabelPath","name","allowClear",true,"name","app-station","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[540,18],[548,20]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[557,24],[557,31]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalUser2",["loc",[null,[558,22],[558,39]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-person2","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[556,14],[564,16]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[573,22],[573,29]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalUser3",["loc",[null,[574,20],[574,37]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-person3","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[572,12],[580,14]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[589,22],[589,32]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalAssistant2",["loc",[null,[590,20],[590,42]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-assistant2","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[588,12],[596,14]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[604,22],[604,32]]]]],[],[]],"value",["subexpr","@mut",[["get","app.internalAssistant3",["loc",[null,[605,20],[605,42]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","app-internal-assistant3","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[603,12],[611,14]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","app.persons",["loc",[null,[618,45],[618,56]]]]],[],[]],"name","appPersons","autocomplete","off"],["loc",[null,[618,16],[618,95]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","app.location",["loc",[null,[624,45],[624,57]]]]],[],[]],"name","appLocation","autocomplete","off"],["loc",[null,[624,16],[624,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 636,
              "column": 8
            },
            "end": {
              "line": 640,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--small default button-rework");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showCorres",["get","app.patient.id",["loc",[null,[637,96],[637,110]]]]],[],["loc",[null,[637,74],[637,112]]]],
          ["inline","button-view",[],["size","52","showStroke",false,"color","#fff","content",["subexpr","css-bool-evaluator",[["subexpr","is-journal-enabled",[],[],["loc",[null,[638,94],[638,114]]]],"Journal anzeigen","Korrespondenz anzeigen"],[],["loc",[null,[638,74],[638,159]]]]],["loc",[null,[638,12],[638,161]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 641,
              "column": 8
            },
            "end": {
              "line": 645,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button button-rework icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm-full",[],["size","52","showStroke",false,"color","#fff","item",["subexpr","@mut",[["get","app",["loc",[null,[643,89],[643,92]]]]],[],[]],"confirmed","delete","text",["subexpr","css-bool-evaluator",[["get","editSeries",["loc",[null,[643,137],[643,147]]]],"Wollen Sie diese Serie wirklich löschen?","Wollen Sie diesen Termin wirklich löschen?"],[],["loc",[null,[643,117],[643,236]]]],"content",""],["loc",[null,[643,10],[643,249]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 722,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/add-appointment-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-add-appointment-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large  admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","addAppointmentForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body js-tabs tabs p-15");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","rework-content tabs-rework-wrapper grid mb-20 mt-0");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-general");
        var el7 = dom.createTextNode("Allgemein ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","tabs__body mb-0 p-10 ");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-general");
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","fs20 mt-10 mb-10 ms-0 me-0");
        var el8 = dom.createTextNode("Allgemein");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","gutter-fix");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","fs20 mt-10 mb-10 ms-0 me-0");
        var el8 = dom.createTextNode("Datum & Uhrzeit");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","grid gutter-fix");
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col l-6/24 gutter");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","form-field-rework");
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","d-flex align-items-center");
        var el12 = dom.createTextNode("\n                ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","checkbox-custom checkbox-primary field-checkbox");
        var el13 = dom.createTextNode("\n                  ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                  ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","appWholeDay");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","appWholeDay");
        dom.setAttribute(el12,"class","checkbox-label");
        var el13 = dom.createTextNode("Ganztägig");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n              ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col l-6/24 gutter");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","form-field-rework");
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","d-flex align-items-center");
        var el12 = dom.createTextNode("\n                  ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","checkbox-custom checkbox-primary field-checkbox");
        var el13 = dom.createTextNode("\n                    ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                    ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","appMultiDay");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                  ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                  ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","appMultiDay");
        dom.setAttribute(el12,"class","checkbox-label");
        var el13 = dom.createTextNode("Mehrtägig");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n              ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-6/24 gutter field-datepicker");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","form-field-rework");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","startDate");
        dom.setAttribute(el10,"class","placeholder");
        var el11 = dom.createTextNode("Datum");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","form-field-rework");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","select-placeholder");
        var el11 = dom.createTextNode("Von");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/time-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","dateFrom");
        dom.setAttribute(el10,"class","placeholder");
        var el11 = dom.createTextNode("Von");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","select-placeholder");
        var el11 = dom.createTextNode("Bis");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/time-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","dateFrom");
        dom.setAttribute(el10,"class","placeholder");
        var el11 = dom.createTextNode("Bis");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","select-placeholder");
        var el11 = dom.createTextNode("Dauer");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("img");
        dom.setAttribute(el10,"src","assets/images/icons/rework/time-dark.svg");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n          ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-details");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button button-rework primary icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n\n    $(\"#addAppointmentForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'appTitle': {\n          required: true\n        },\n        'appCatgory': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $(\"body\").delegate(\".js-date-picker\", \"focusin\", function(){\n      $(this).daterangepicker({\n        singleDatePicker: true,\n        showDropdowns: true,\n        \"locale\": {\n          \"format\": \"DD. MM. YYYY\",\n          \"daysOfWeek\": [\n            \"So\",\n            \"Mo\",\n            \"Di\",\n            \"Mi\",\n            \"Do\",\n            \"Fr\",\n            \"Sa\"\n          ],\n          \"monthNames\": [\n            \"Jänner\",\n            \"Februar\",\n            \"März\",\n            \"April\",\n            \"Mai\",\n            \"Juni\",\n            \"Juli\",\n            \"August\",\n            \"September\",\n            \"Oktober\",\n            \"November\",\n            \"Dezember\"\n          ],\n          \"firstDay\": 1\n        },\n      });\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element22 = dom.childAt(fragment, [0, 1, 1]);
        var element23 = dom.childAt(element22, [3]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(element23, [3]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element27, [3]);
        var element29 = dom.childAt(element27, [7]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element29, [5]);
        var element32 = dom.childAt(element31, [3]);
        var element33 = dom.childAt(element29, [7]);
        var element34 = dom.childAt(element33, [1]);
        var element35 = dom.childAt(element33, [3]);
        var element36 = dom.childAt(element29, [9]);
        var element37 = dom.childAt(element36, [1]);
        var element38 = dom.childAt(element26, [3]);
        var element39 = dom.childAt(element22, [5]);
        var morphs = new Array(34);
        morphs[0] = dom.createAttrMorph(element22, 'class');
        morphs[1] = dom.createElementMorph(element22);
        morphs[2] = dom.createMorphAt(dom.childAt(element22, [1, 1]),1,1);
        morphs[3] = dom.createAttrMorph(element25, 'class');
        morphs[4] = dom.createElementMorph(element25);
        morphs[5] = dom.createMorphAt(element24,3,3);
        morphs[6] = dom.createAttrMorph(element27, 'class');
        morphs[7] = dom.createMorphAt(element28,1,1);
        morphs[8] = dom.createMorphAt(element28,3,3);
        morphs[9] = dom.createMorphAt(dom.childAt(element30, [1, 1, 1, 1]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element30, [3, 1, 1, 1]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element29, [3, 1]),1,1);
        morphs[12] = dom.createAttrMorph(element31, 'class');
        morphs[13] = dom.createMorphAt(dom.childAt(element31, [1]),3,3);
        morphs[14] = dom.createAttrMorph(element32, 'class');
        morphs[15] = dom.createMorphAt(element32,1,1);
        morphs[16] = dom.createAttrMorph(element33, 'class');
        morphs[17] = dom.createAttrMorph(element34, 'class');
        morphs[18] = dom.createMorphAt(element34,3,3);
        morphs[19] = dom.createAttrMorph(element35, 'class');
        morphs[20] = dom.createMorphAt(element35,1,1);
        morphs[21] = dom.createAttrMorph(element36, 'class');
        morphs[22] = dom.createAttrMorph(element37, 'class');
        morphs[23] = dom.createMorphAt(element37,3,3);
        morphs[24] = dom.createMorphAt(element29,11,11);
        morphs[25] = dom.createMorphAt(element29,13,13);
        morphs[26] = dom.createMorphAt(element29,15,15);
        morphs[27] = dom.createMorphAt(element27,9,9);
        morphs[28] = dom.createAttrMorph(element38, 'class');
        morphs[29] = dom.createMorphAt(element38,1,1);
        morphs[30] = dom.createMorphAt(element39,1,1);
        morphs[31] = dom.createMorphAt(element39,2,2);
        morphs[32] = dom.createMorphAt(dom.childAt(element39, [4]),1,1);
        morphs[33] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",[["get","app.appType",["loc",[null,[3,43],[3,54]]]]]]],
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,58],[3,90]]]],
        ["block","if",[["get","app.id",["loc",[null,[7,22],[7,28]]]]],[],0,1,["loc",[null,[7,16],[7,112]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[12,59],[12,70]]]],"data"],[],["loc",[null,[12,55],[12,78]]]],"is-active",""],[],["loc",[null,[12,34],[12,95]]]]]]],
        ["element","action",["selectTab","data"],[],["loc",[null,[12,117],[13,34]]]],
        ["block","if",[["subexpr","eq",[["get","app.appType",["loc",[null,[14,20],[14,31]]]],"cust"],[],["loc",[null,[14,16],[14,39]]]]],[],2,null,["loc",[null,[14,10],[17,17]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[21,60],[21,71]]]],"data"],[],["loc",[null,[21,56],[21,79]]]],"is-active",""],[],["loc",[null,[21,35],[21,96]]]]]]],
        ["block","if",[["get","supportUserApps",["loc",[null,[25,20],[25,35]]]]],[],3,null,["loc",[null,[25,14],[58,21]]]],
        ["block","if",[["subexpr","not",[["subexpr","eq",[["get","app.appType",["loc",[null,[60,29],[60,40]]]],"etiga"],[],["loc",[null,[60,25],[60,49]]]]],[],["loc",[null,[60,20],[60,50]]]]],[],4,null,["loc",[null,[60,14],[67,21]]]],
        ["inline","input",[],["type","checkbox","name","appWholeDay","id","appWholeDay","checked",["subexpr","@mut",[["get","app.wholeDay",["loc",[null,[78,86],[78,98]]]]],[],[]]],["loc",[null,[78,18],[78,100]]]],
        ["inline","input",[],["type","checkbox","name","appMultiDay","id","appMultiDay","checked",["subexpr","@mut",[["get","app.multiDay",["loc",[null,[90,88],[90,100]]]]],[],[]]],["loc",[null,[90,20],[90,102]]]],
        ["inline","input",[],["placeholder","","id","startDate","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","app.date",["loc",[null,[101,96],[101,104]]]]],[],[]],"name","startDate"],["loc",[null,[101,14],[101,123]]]],
        ["attribute","class",["concat",["col l-6/24 gutter ",["subexpr","css-bool-evaluator",[["get","app.wholeDay",["loc",[null,[106,61],[106,73]]]],"hidden",""],[],["loc",[null,[106,40],[106,87]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","times",["loc",[null,[110,26],[110,31]]]]],[],[]],"value",["subexpr","@mut",[["get","app.dateFrom",["loc",[null,[111,24],[111,36]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",false,"name","dateFrom","cssClass","custom-select2 newStyle tuned"],["loc",[null,[109,16],[117,18]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickSelect.id",["loc",[null,[121,51],[121,65]]]],"misc"],[],["loc",[null,[121,47],[121,73]]]],"","hidden"],[],["loc",[null,[121,26],[121,87]]]]]]],
        ["inline","input",[],["placeholder","","id","dateFrom","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","app.dateFromMisc",["loc",[null,[122,97],[122,113]]]]],[],[]],"name","dateFrom"],["loc",[null,[122,16],[122,131]]]],
        ["attribute","class",["concat",["col l-6/24 gutter ",["subexpr","css-bool-evaluator",[["get","app.wholeDay",["loc",[null,[128,61],[128,73]]]],"hidden",""],[],["loc",[null,[128,40],[128,87]]]]]]],
        ["attribute","class",["concat",["form-field-rework ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickSelect.id",["loc",[null,[129,67],[129,81]]]],"misc"],[],["loc",[null,[129,63],[129,89]]]],"hidden",""],[],["loc",[null,[129,42],[129,103]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","times",["loc",[null,[132,24],[132,29]]]]],[],[]],"value",["subexpr","@mut",[["get","app.dateTo",["loc",[null,[133,22],[133,32]]]]],[],[]],"optionLabelPath","value","optionValuePath","id","allowClear",false,"name","dateTo","cssClass","custom-select2 newStyle tuned"],["loc",[null,[131,14],[139,16]]]],
        ["attribute","class",["concat",[["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickSelect.id",["loc",[null,[142,49],[142,63]]]],"misc"],[],["loc",[null,[142,45],[142,71]]]],"","hidden"],[],["loc",[null,[142,24],[142,85]]]]]]],
        ["inline","input",[],["placeholder","","id","dateToMisc","class","js-date-picker datetimepicker","value",["subexpr","@mut",[["get","app.dateToMisc",["loc",[null,[143,97],[143,111]]]]],[],[]],"name","dateToMisc"],["loc",[null,[143,14],[143,131]]]],
        ["attribute","class",["concat",["col l-6/24 gutter ",["subexpr","css-bool-evaluator",[["get","app.seriesInterval",["loc",[null,[148,61],[148,79]]]],"",""],[],["loc",[null,[148,40],[148,87]]]]]]],
        ["attribute","class",["concat",["form-field-rework ",["subexpr","css-bool-evaluator",[["get","app.wholeDay",["loc",[null,[149,67],[149,79]]]],"hidden",""],[],["loc",[null,[149,46],[149,93]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","quickSelects",["loc",[null,[152,28],[152,40]]]]],[],[]],"value",["subexpr","@mut",[["get","quickSelect",["loc",[null,[153,26],[153,37]]]]],[],[]],"optionLabelPath","shortName","allowClear",true,"name","quickSelect","placeholder","Dauer wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[151,18],[159,20]]]],
        ["block","if",[["get","app.multiDay",["loc",[null,[163,20],[163,32]]]]],[],5,null,["loc",[null,[163,14],[171,21]]]],
        ["block","if",[["subexpr","not",[["subexpr","eq",[["get","app.appType",["loc",[null,[173,25],[173,36]]]],"etiga"],[],["loc",[null,[173,21],[173,45]]]]],[],["loc",[null,[173,16],[173,46]]]]],[],6,null,["loc",[null,[173,10],[190,17]]]],
        ["block","if",[["get","showSeries",["loc",[null,[192,16],[192,26]]]]],[],7,null,["loc",[null,[192,10],[218,17]]]],
        ["block","if",[["subexpr","eq",[["get","app.appType",["loc",[null,[222,22],[222,33]]]],"cust"],[],["loc",[null,[222,18],[222,41]]]]],[],8,9,["loc",[null,[222,12],[498,15]]]],
        ["attribute","class",["concat",["pl0 pr0 tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[501,68],[501,79]]]],"details"],[],["loc",[null,[501,64],[501,90]]]],"is-active",""],[],["loc",[null,[501,43],[501,107]]]]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","app.appType",["loc",[null,[502,26],[502,37]]]],"etiga"],[],["loc",[null,[502,22],[502,46]]]],["subexpr","eq",[["get","app.appType",["loc",[null,[502,51],[502,62]]]],"cust"],[],["loc",[null,[502,47],[502,70]]]]],[],["loc",[null,[502,18],[502,71]]]]],[],10,null,["loc",[null,[502,12],[630,17]]]],
        ["block","if",[["get","app.patient",["loc",[null,[636,14],[636,25]]]]],[],11,null,["loc",[null,[636,8],[640,15]]]],
        ["block","if",[["get","showDelete",["loc",[null,[641,14],[641,24]]]]],[],12,null,["loc",[null,[641,8],[645,15]]]],
        ["inline","button-save",[],["size","40","color","#ffffff","content",""],["loc",[null,[647,10],[647,62]]]],
        ["inline","treatment-select-panel",[],["prefix","app","confirm","updateSelects","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[655,77],[655,97]]]]],[],[]]],["loc",[null,[655,0],[655,99]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
    };
  }()));

});
define('jason-frontend/routes/invoices/show', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('invoice', params.id, { reload: true });
    },
    setupController: function setupController(controller, model) {
      this.store.fetch('invoice', model.id).then(function (data) {
        controller.set("model", data);
      });
    },
    actions: {
      downloadOriginal: function downloadOriginal(invoice) {
        var hostAppendix = '';
        if (location.hostname === "localhost") {
          hostAppendix = 'http://localhost:4200/';
        }

        var deferred = $.ajax({
          url: "/api/invoices/" + invoice.id + "/preview?original=true&operatingCountry=" + API['default'].getOperatingCountry(),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          var url = (hostAppendix + '/api/invoices/showOriginal/' + data.responseText).replace("4200", "8080");
          window.open(url);
        });
      },
      downloadInvoice: function downloadInvoice(invoice) {
        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoice.id + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        }, function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      downloadWarningInvoice: function downloadWarningInvoice(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      downloadWarningText: function downloadWarningText(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      openPreview: function openPreview(invoiceId) {
        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/preview",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        }, function (data) {
          window.location.href = window.location.origin + '/api/invoices/show/' + data.responseText;
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      sendEmail: function sendEmail(invoiceId, data) {
        var id = window.location.pathname.split('/')[3];

        if (id !== invoiceId) {
          jason.notifiction.notifiy('Fehler', 'Es ist ein interner Fehler aufgetreten');
          return;
        }

        Ember['default'].$.ajax({
          url: "/api/invoices/mailto/" + invoiceId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Rechnung wurde übermittelt');
        }, function (data) {
          if (data.status === 200) {
            jason.notifiction.notifiy('Erfolgreich', 'Rechnung wurde übermittelt');
          } else {
            jason.notifiction.error('Fehler', 'Rechnung konnte leider nicht übermittelt werden');
          }
        });
      }
    }
  });

});
define('jason-frontend/templates/components/start-treatment-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 18
            },
            "end": {
              "line": 13,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","gender-icon",[],["classNames","owner-gender me-20","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","customer.gender.id",["loc",[null,[12,112],[12,130]]]]],[],[]],"content",""],["loc",[null,[12,20],[12,143]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 43
            },
            "end": {
              "line": 16,
              "column": 86
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(",");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","customer.street",["loc",[null,[16,66],[16,85]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 59
            },
            "end": {
              "line": 17,
              "column": 190
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/phone.svg");
          dom.setAttribute(el1,"class","me-10");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","me-10");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["content","customer.phone",["loc",[null,[17,145],[17,163]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 198
            },
            "end": {
              "line": 17,
              "column": 302
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/email.svg");
          dom.setAttribute(el1,"class","me-10");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","customer.email",["loc",[null,[17,284],[17,302]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 20
            },
            "end": {
              "line": 31,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge me-5");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Guthaben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element16, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element16, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","depositSum",["loc",[null,[27,62],[27,72]]]],0],[],["loc",[null,[27,58],[27,75]]]],"is-negative","positive"],[],["loc",[null,[27,37],[27,102]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[28,77],[28,87]]]]],[],[]],"classNames","inline-block"],["loc",[null,[28,53],[28,115]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 20
            },
            "end": {
              "line": 43,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","readOnly");
          var el2 = dom.createTextNode("Termin");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["content","app.date",["loc",[null,[42,22],[42,34]]]],
          ["content","app.dateFrom",["loc",[null,[42,35],[42,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 20
            },
            "end": {
              "line": 55,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","select-placeholder");
          var el2 = dom.createTextNode("Termin");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["id","time","content",["subexpr","@mut",[["get","times",["loc",[null,[47,34],[47,39]]]]],[],[]],"value",["subexpr","@mut",[["get","appointmentTime",["loc",[null,[48,32],[48,47]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","placeholder","Bitte wählen","name","dateFrom"],["loc",[null,[45,24],[54,26]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 18
                },
                "end": {
                  "line": 77,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    zuletzt gemessen: ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" kg\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.currentWeight",["loc",[null,[76,38],[76,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 136,
                  "column": 20
                },
                "end": {
                  "line": 137,
                  "column": 213
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted-alternative");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'id');
              morphs[1] = dom.createAttrMorph(element6, 'data-ids');
              morphs[2] = dom.createUnsafeMorphAt(element6,0,0);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","patient.id",["loc",[null,[137,34],[137,44]]]],"_introReasons"]]],
              ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patient.introductionReason",["loc",[null,[137,125],[137,151]]]]],[],["loc",[null,[137,106],[137,153]]]]]]],
              ["inline","print-list-of-name",[["get","patient.introductionReason",["loc",[null,[137,177],[137,203]]]]],[],["loc",[null,[137,155],[137,206]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 146,
                  "column": 20
                },
                "end": {
                  "line": 147,
                  "column": 185
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'id');
              morphs[1] = dom.createAttrMorph(element5, 'data-ids');
              morphs[2] = dom.createUnsafeMorphAt(element5,0,0);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","patient.id",["loc",[null,[147,36],[147,46]]]],"_adminTasks"]]],
              ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patient.adminTasks",["loc",[null,[147,113],[147,131]]]]],[],["loc",[null,[147,94],[147,133]]]]]]],
              ["inline","print-list-of-name",[["get","patient.adminTasks",["loc",[null,[147,157],[147,175]]]]],[],["loc",[null,[147,135],[147,178]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 151,
                  "column": 14
                },
                "end": {
                  "line": 179,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col gutter l-8/24");
              var el2 = dom.createTextNode("\n\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","form-field-rework mb-0");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class"," d-flex align-items-center");
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","checkbox-custom checkbox-primary field-checkbox");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("label");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                      ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"for","");
              dom.setAttribute(el4,"class","checkbox-label");
              var el5 = dom.createTextNode("Abholtermin vereinbaren");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","mb-0 form-field-rework datetime-combined");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"class","placeholder");
              dom.setAttribute(el4,"for","dayDate");
              var el5 = dom.createTextNode("Datum");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"class","placeholder");
              dom.setAttribute(el4,"for","dayTime");
              var el5 = dom.createTextNode("Uhrzeit");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1, 1, 1]);
              var element2 = dom.childAt(element1, [3]);
              var element3 = dom.childAt(fragment, [3]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element1,1,1);
              morphs[1] = dom.createAttrMorph(element2, 'for');
              morphs[2] = dom.createAttrMorph(element3, 'class');
              morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
              morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id",["subexpr","concat",[["get","patient.id",["loc",[null,[157,59],[157,69]]]],"_pickupAppEnabled"],[],["loc",[null,[157,51],[157,90]]]],"checked",["subexpr","@mut",[["get","patient.pickupAppointment",["loc",[null,[158,40],[158,65]]]]],[],[]]],["loc",[null,[157,24],[158,67]]]],
              ["attribute","for",["concat",[["subexpr","concat",[["get","patient.id",["loc",[null,[159,45],[159,55]]]],"_pickupAppEnabled"],[],["loc",[null,[159,36],[159,77]]]]]]],
              ["attribute","class",["concat",["col gutter l-8/24 ",["subexpr","css-bool-evaluator",[["get","patient.pickupAppointment",["loc",[null,[165,67],[165,92]]]],"","hidden"],[],["loc",[null,[165,46],[165,106]]]]]]],
              ["inline","input",[],["id",["subexpr","concat",[["get","patient.id",["loc",[null,[168,39],[168,49]]]],"_date"],[],["loc",[null,[168,31],[168,58]]]],"value",["subexpr","@mut",[["get","patient.pickupDate",["loc",[null,[168,65],[168,83]]]]],[],[]],"class","datetimepicker2 input date-picker__input","name","pickupDate","placeholder",""],["loc",[null,[168,20],[169,111]]]],
              ["inline","input",[],["id",["subexpr","concat",[["get","patient.id",["loc",[null,[173,39],[173,49]]]],"_time"],[],["loc",[null,[173,31],[173,58]]]],"value",["subexpr","@mut",[["get","patient.pickupTime",["loc",[null,[173,65],[173,83]]]]],[],[]],"name","pickupTime","class","input date-picker__input time fixed-timepicker-width","placeholder",""],["loc",[null,[173,20],[174,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 194,
                    "column": 14
                  },
                  "end": {
                    "line": 204,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","treatment-history",[],["showCopy",false,"classNames","pl20 pr20","loadOverlay",false,"showEdit",false,"showChangeUser",false,"showFilter",false,"showToggleAllButton",false,"items",["subexpr","get-latest-patient-info",[["get","historicTreatments",["loc",[null,[203,49],[203,67]]]],["get","patient",["loc",[null,[203,68],[203,75]]]]],[],["loc",[null,[203,24],[203,76]]]]],["loc",[null,[195,16],[203,78]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 184,
                  "column": 12
                },
                "end": {
                  "line": 205,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","toggle-container");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h2");
              var el4 = dom.createTextNode("Letzte Behandlung");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","header-toggle");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"for","switchLasttreatment");
              dom.setAttribute(el4,"class","switch");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id","switchLasttreatment","classNames","switch","name","switchLasttreatment","checked",["subexpr","@mut",[["get","showLastTreatment",["loc",[null,[189,122],[189,139]]]]],[],[]]],["loc",[null,[189,18],[189,141]]]],
              ["block","if",[["get","showLastTreatment",["loc",[null,[194,20],[194,37]]]]],[],0,null,["loc",[null,[194,14],[204,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 12
              },
              "end": {
                "line": 208,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1,"class","d-flex align-items-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","cursor");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid gutter-fix");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","weight");
            dom.setAttribute(el4,"class","placeholder");
            var el5 = dom.createTextNode("Gewicht");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/weight-dark.svg");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-16/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            dom.setAttribute(el3,"style","padding-top: 12px");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Tierärzt:in");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Assistent:in");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Behandlungslokation");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","textarea-wrapper");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"class","placeholder");
            var el6 = dom.createTextNode("Anamnese");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            dom.setAttribute(el3,"class","d-flex align-items-center mb-10");
            var el4 = dom.createTextNode("Vorstellungsgründe ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pick-list");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","cursor");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"class","cursor info-badge info-badge-small add");
            var el6 = dom.createElement("img");
            dom.setAttribute(el6,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24@desk 24/24 gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            dom.setAttribute(el3,"class","d-flex align-items-center mb-10");
            var el4 = dom.createTextNode("Administrative Tätigkeiten");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pick-list");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","cursor");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"class","cursor info-badge info-badge-small add");
            var el6 = dom.createElement("img");
            dom.setAttribute(el6,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box pt0 pb0 mb20");
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(element7, [2]);
            var element9 = dom.childAt(fragment, [3]);
            var element10 = dom.childAt(element9, [5, 1]);
            var element11 = dom.childAt(element9, [7, 1]);
            var element12 = dom.childAt(element9, [9, 1]);
            var element13 = dom.childAt(element9, [13]);
            var element14 = dom.childAt(element13, [3, 1]);
            var element15 = dom.childAt(element9, [15, 3, 1]);
            var morphs = new Array(19);
            morphs[0] = dom.createMorphAt(element7,0,0);
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createMorphAt(element8,0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element9, [1, 1]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element9, [3, 1]),1,1);
            morphs[5] = dom.createAttrMorph(element10, 'id');
            morphs[6] = dom.createMorphAt(element10,3,3);
            morphs[7] = dom.createAttrMorph(element11, 'id');
            morphs[8] = dom.createMorphAt(element11,3,3);
            morphs[9] = dom.createAttrMorph(element12, 'id');
            morphs[10] = dom.createMorphAt(element12,3,3);
            morphs[11] = dom.createMorphAt(dom.childAt(element9, [11, 1, 1]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element13, [1]),1,1);
            morphs[13] = dom.createElementMorph(element14);
            morphs[14] = dom.createMorphAt(element14,1,1);
            morphs[15] = dom.createElementMorph(element15);
            morphs[16] = dom.createMorphAt(element15,1,1);
            morphs[17] = dom.createMorphAt(element9,17,17);
            morphs[18] = dom.createMorphAt(dom.childAt(fragment, [5]),1,1);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["color","#999999","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[62,92],[62,111]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[62,120],[62,144]]]]],[],[]],"size","32","classNames","me-10","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[62,182],[62,203]]]]],[],[]]],["loc",[null,[62,52],[62,205]]]],
            ["element","action",["gotoPatient",["get","patient.id",["loc",[null,[62,247],[62,257]]]]],[],["loc",[null,[62,224],[62,259]]]],
            ["inline","button-text",[],["text",["subexpr","@mut",[["get","patient.name",["loc",[null,[62,279],[62,291]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.id",["loc",[null,[62,300],[62,310]]]]],[],[]]],["loc",[null,[62,260],[62,312]]]],
            ["inline","input",[],["placeholder","","name","weight","id",["subexpr","concat",[["get","patient.id",["loc",[null,[68,66],[68,76]]]],"_weight"],[],["loc",[null,[68,58],[68,87]]]]],["loc",[null,[68,18],[68,89]]]],
            ["block","if",[["get","patient.currentWeight",["loc",[null,[75,24],[75,45]]]]],[],0,null,["loc",[null,[75,18],[77,25]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[81,53],[81,63]]]],"_wishDoctor"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[84,28],[84,35]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[85,26],[85,32]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","wish-doctor","placeholder","Bitte wählen"],["loc",[null,[83,18],[91,20]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[95,53],[95,63]]]],"_wishAssistant"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[98,28],[98,38]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[99,26],[99,35]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","wishAssistant","placeholder","Bitte wählen"],["loc",[null,[97,18],[105,20]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[109,53],[109,63]]]],"_room"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[112,28],[112,33]]]]],[],[]],"value",["subexpr","@mut",[["get","room",["loc",[null,[113,26],[113,30]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","treatment-room","placeholder","Bitte wählen"],["loc",[null,[111,18],[119,20]]]],
            ["inline","textarea",[],["id",["subexpr","concat",[["get","patient.id",["loc",[null,[125,42],[125,52]]]],"_anamnese"],[],["loc",[null,[125,34],[125,65]]]],"rows","3","value",["subexpr","@mut",[["get","app.title",["loc",[null,[125,81],[125,90]]]]],[],[]],"class","","name","anamnese","placeholder",""],["loc",[null,[125,20],[126,47]]]],
            ["inline","button-etiga-small",[],["classNames","ms-10","content","in eltiga App sichtbar","size","20"],["loc",[null,[132,79],[132,163]]]],
            ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","patient.introductionReason",["loc",[null,[135,99],[135,125]]]],"Vorstellungsgründe",["get","patient",["loc",[null,[135,147],[135,154]]]]],[],["loc",[null,[135,36],[135,156]]]],
            ["block","if",[["get","patient.introductionReason",["loc",[null,[136,26],[136,52]]]]],[],1,null,["loc",[null,[136,20],[137,220]]]],
            ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","patient.adminTasks",["loc",[null,[145,91],[145,109]]]],"Administrative Tätigkeiten",["get","patient",["loc",[null,[145,139],[145,146]]]]],[],["loc",[null,[145,36],[145,148]]]],
            ["block","if",[["get","patient.adminTasks",["loc",[null,[146,26],[146,44]]]]],[],2,null,["loc",[null,[146,20],[147,192]]]],
            ["block","if",[["subexpr","is-station-extended-enabled",[],[],["loc",[null,[151,20],[151,49]]]]],[],3,null,["loc",[null,[151,14],[179,21]]]],
            ["block","if",[["get","historicTreatments",["loc",[null,[184,18],[184,36]]]]],[],4,null,["loc",[null,[184,12],[205,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 10
            },
            "end": {
              "line": 209,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-patient-selected",[["get","selectedPatients",["loc",[null,[61,39],[61,55]]]],["get","patient",["loc",[null,[61,56],[61,63]]]]],[],["loc",[null,[61,18],[61,64]]]]],[],0,null,["loc",[null,[61,12],[208,19]]]]
        ],
        locals: ["patient","index"],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 215,
              "column": 12
            },
            "end": {
              "line": 219,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-in-treatment-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[217,51],[217,59]]]]],[],[]],"showStroke",false,"confirmed","confirmHelper","color","#fff","content","Aufnehmen und direkt in Behandlung","text","Der/Die ausgewählte Kunde/in ist als 'Gesperrt - Nichtzahler' vermerkt, trotzdem fortfahren?"],["loc",[null,[217,16],[217,262]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 219,
              "column": 12
            },
            "end": {
              "line": 223,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/treatment.svg");
          dom.setAttribute(el2,"title","Aufnehmen und direkt in Behandlung");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["confirm",true,["get","customerId",["loc",[null,[220,41],[220,51]]]],["get","selectedPatients",["loc",[null,[220,52],[220,68]]]]],[],["loc",[null,[220,17],[220,70]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 250,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-start-treatment-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-extremely-large mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","treatmentPanelForm");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                Aufnahme");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p-20");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card mb-0 min-h-130px customer-card");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex flex-row");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h1");
        dom.setAttribute(el8,"class","mb0 d-flex align-items-center");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","mt-0 mb-0");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","d-flex align-items-center");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ml-auto d-flex align-items-center");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","topcard-badge me-5");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","textBelow");
        var el11 = dom.createElement("b");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Saldo");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid gutter-fix mt-20");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-8/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework mb-0");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/waitingroom.svg");
        dom.setAttribute(el6,"title","Aufnehmen und ins Wartezimmer setzen");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"body\").delegate(\"#treatmentPanelForm textarea\", \"keyup\", function() {\n      $(this).height(30);\n      $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    });\n\n    $(\"body\").delegate(\"input.readonly-hack\", \"focusin\", function() {\n      $(this).removeAttr('readonly');\n    });\n\n    setTimeout(function() {\n      $(\"#treatmentPanelForm textarea\").each(function() {\n        $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n      });\n    }, 500);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element17 = dom.childAt(fragment, [0, 1, 1]);
        var element18 = dom.childAt(element17, [3]);
        var element19 = dom.childAt(element18, [1, 1]);
        var element20 = dom.childAt(element19, [3]);
        var element21 = dom.childAt(element20, [1]);
        var element22 = dom.childAt(element20, [3]);
        var element23 = dom.childAt(element20, [5]);
        var element24 = dom.childAt(element19, [5]);
        var element25 = dom.childAt(element24, [1, 1]);
        var element26 = dom.childAt(element17, [5]);
        var element27 = dom.childAt(element26, [1]);
        var morphs = new Array(18);
        morphs[0] = dom.createMorphAt(element19,1,1);
        morphs[1] = dom.createMorphAt(element21,0,0);
        morphs[2] = dom.createMorphAt(element21,2,2);
        morphs[3] = dom.createMorphAt(element21,4,4);
        morphs[4] = dom.createMorphAt(element22,0,0);
        morphs[5] = dom.createMorphAt(element22,2,2);
        morphs[6] = dom.createMorphAt(element22,4,4);
        morphs[7] = dom.createMorphAt(element23,0,0);
        morphs[8] = dom.createMorphAt(element23,2,2);
        morphs[9] = dom.createAttrMorph(element25, 'class');
        morphs[10] = dom.createMorphAt(dom.childAt(element25, [1, 0]),0,0);
        morphs[11] = dom.createMorphAt(element24,3,3);
        morphs[12] = dom.createMorphAt(element24,5,5);
        morphs[13] = dom.createMorphAt(dom.childAt(element18, [3, 1, 1]),1,1);
        morphs[14] = dom.createMorphAt(element18,5,5);
        morphs[15] = dom.createElementMorph(element27);
        morphs[16] = dom.createMorphAt(element26,3,3);
        morphs[17] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["get","customer.gender",["loc",[null,[11,24],[11,39]]]]],[],0,null,["loc",[null,[11,18],[13,25]]]],
        ["content","customer.firstname",["loc",[null,[15,62],[15,84]]]],
        ["content","customer.lastname",["loc",[null,[15,85],[15,106]]]],
        ["inline","eltiga-customer-badge",[],["customer",["subexpr","@mut",[["get","customer",["loc",[null,[15,140],[15,148]]]]],[],[]]],["loc",[null,[15,107],[15,150]]]],
        ["block","if",[["get","customer.street",["loc",[null,[16,49],[16,64]]]]],[],1,null,["loc",[null,[16,43],[16,93]]]],
        ["content","customer.postalCode",["loc",[null,[16,94],[16,117]]]],
        ["content","customer.town",["loc",[null,[16,118],[16,135]]]],
        ["block","if",[["get","customer.phone",["loc",[null,[17,65],[17,79]]]]],[],2,null,["loc",[null,[17,59],[17,197]]]],
        ["block","if",[["get","customer.email",["loc",[null,[17,204],[17,218]]]]],[],3,null,["loc",[null,[17,198],[17,309]]]],
        ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[21,60],[21,70]]]],0],[],["loc",[null,[21,56],[21,73]]]],"is-negative","positive"],[],["loc",[null,[21,35],[21,100]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[22,75],[22,85]]]]],[],[]],"classNames","inline-block"],["loc",[null,[22,51],[22,113]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[25,30],[25,40]]]],0],[],["loc",[null,[25,26],[25,43]]]]],[],4,null,["loc",[null,[25,20],[31,27]]]],
        ["inline","payment-moral-icon",[],["moral",["subexpr","get-payment-moral",[["get","model",["loc",[null,[32,66],[32,71]]]],true],[],["loc",[null,[32,47],[32,77]]]]],["loc",[null,[32,20],[32,79]]]],
        ["block","if",[["get","app",["loc",[null,[40,26],[40,29]]]]],[],5,6,["loc",[null,[40,20],[55,25]]]],
        ["block","each",[["get","customer.patients",["loc",[null,[60,18],[60,35]]]]],[],7,null,["loc",[null,[60,10],[209,19]]]],
        ["element","action",["confirm",false,["get","customerId",["loc",[null,[212,40],[212,50]]]],["get","selectedPatients",["loc",[null,[212,51],[212,67]]]]],[],["loc",[null,[212,15],[212,69]]]],
        ["block","if",[["get","customer.sued",["loc",[null,[215,18],[215,31]]]]],[],8,9,["loc",[null,[215,12],[223,19]]]],
        ["inline","treatment-select-panel",[],["confirm","updateSelects","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[249,64],[249,84]]]]],[],[]]],["loc",[null,[249,0],[249,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  }()));

});
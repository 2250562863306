define('jason-frontend/templates/components/animal-icon', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 2,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 0
              },
              "end": {
                "line": 4,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/patient-categories/heimtier.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 0
                },
                "end": {
                  "line": 6,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/patient-categories/hund.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 0
                  },
                  "end": {
                    "line": 8,
                    "column": 0
                  }
                },
                "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/patient-categories/katze.svg");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 8,
                      "column": 0
                    },
                    "end": {
                      "line": 10,
                      "column": 0
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/patient-categories/esel.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 10,
                        "column": 0
                      },
                      "end": {
                        "line": 12,
                        "column": 0
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"src","assets/images/icons/patient-categories/pferd.svg");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 12,
                          "column": 0
                        },
                        "end": {
                          "line": 14,
                          "column": 0
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1,"src","assets/images/icons/patient-categories/reptil.svg");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 14,
                            "column": 0
                          },
                          "end": {
                            "line": 16,
                            "column": 0
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("img");
                        dom.setAttribute(el1,"src","assets/images/icons/patient-categories/rind.svg");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 16,
                              "column": 0
                            },
                            "end": {
                              "line": 18,
                              "column": 0
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("img");
                          dom.setAttribute(el1,"src","assets/images/icons/patient-categories/schaf.svg");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes() { return []; },
                        statements: [

                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 18,
                                "column": 0
                              },
                              "end": {
                                "line": 20,
                                "column": 0
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("img");
                            dom.setAttribute(el1,"src","assets/images/icons/patient-categories/fisch.svg");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes() { return []; },
                          statements: [

                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        var child0 = (function() {
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 20,
                                  "column": 0
                                },
                                "end": {
                                  "line": 22,
                                  "column": 0
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("  ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("img");
                              dom.setAttribute(el1,"src","assets/images/icons/patient-categories/heimtier.svg");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes() { return []; },
                            statements: [

                            ],
                            locals: [],
                            templates: []
                          };
                        }());
                        var child1 = (function() {
                          var child0 = (function() {
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 22,
                                    "column": 0
                                  },
                                  "end": {
                                    "line": 24,
                                    "column": 0
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("  ");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("img");
                                dom.setAttribute(el1,"src","assets/images/icons/patient-categories/anderes.svg");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes() { return []; },
                              statements: [

                              ],
                              locals: [],
                              templates: []
                            };
                          }());
                          var child1 = (function() {
                            var child0 = (function() {
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 24,
                                      "column": 0
                                    },
                                    "end": {
                                      "line": 26,
                                      "column": 0
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("  ");
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createElement("img");
                                  dom.setAttribute(el1,"src","assets/images/icons/patient-categories/zootier.svg");
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createTextNode("\n");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes() { return []; },
                                statements: [

                                ],
                                locals: [],
                                templates: []
                              };
                            }());
                            var child1 = (function() {
                              var child0 = (function() {
                                return {
                                  meta: {
                                    "revision": "Ember@1.13.5",
                                    "loc": {
                                      "source": null,
                                      "start": {
                                        "line": 26,
                                        "column": 0
                                      },
                                      "end": {
                                        "line": 28,
                                        "column": 0
                                      }
                                    },
                                    "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                  },
                                  arity: 0,
                                  cachedFragment: null,
                                  hasRendered: false,
                                  buildFragment: function buildFragment(dom) {
                                    var el0 = dom.createDocumentFragment();
                                    var el1 = dom.createTextNode("  ");
                                    dom.appendChild(el0, el1);
                                    var el1 = dom.createElement("img");
                                    dom.setAttribute(el1,"src","assets/images/icons/patient-categories/ziege.svg");
                                    dom.appendChild(el0, el1);
                                    var el1 = dom.createTextNode("\n");
                                    dom.appendChild(el0, el1);
                                    return el0;
                                  },
                                  buildRenderNodes: function buildRenderNodes() { return []; },
                                  statements: [

                                  ],
                                  locals: [],
                                  templates: []
                                };
                              }());
                              var child1 = (function() {
                                var child0 = (function() {
                                  return {
                                    meta: {
                                      "revision": "Ember@1.13.5",
                                      "loc": {
                                        "source": null,
                                        "start": {
                                          "line": 28,
                                          "column": 0
                                        },
                                        "end": {
                                          "line": 30,
                                          "column": 0
                                        }
                                      },
                                      "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                    },
                                    arity: 0,
                                    cachedFragment: null,
                                    hasRendered: false,
                                    buildFragment: function buildFragment(dom) {
                                      var el0 = dom.createDocumentFragment();
                                      var el1 = dom.createTextNode("  ");
                                      dom.appendChild(el0, el1);
                                      var el1 = dom.createElement("img");
                                      dom.setAttribute(el1,"src","assets/images/icons/patient-categories/reptil.svg");
                                      dom.appendChild(el0, el1);
                                      var el1 = dom.createTextNode("\n");
                                      dom.appendChild(el0, el1);
                                      return el0;
                                    },
                                    buildRenderNodes: function buildRenderNodes() { return []; },
                                    statements: [

                                    ],
                                    locals: [],
                                    templates: []
                                  };
                                }());
                                var child1 = (function() {
                                  var child0 = (function() {
                                    return {
                                      meta: {
                                        "revision": "Ember@1.13.5",
                                        "loc": {
                                          "source": null,
                                          "start": {
                                            "line": 30,
                                            "column": 0
                                          },
                                          "end": {
                                            "line": 32,
                                            "column": 0
                                          }
                                        },
                                        "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                      },
                                      arity: 0,
                                      cachedFragment: null,
                                      hasRendered: false,
                                      buildFragment: function buildFragment(dom) {
                                        var el0 = dom.createDocumentFragment();
                                        var el1 = dom.createTextNode("  ");
                                        dom.appendChild(el0, el1);
                                        var el1 = dom.createElement("img");
                                        dom.setAttribute(el1,"src","assets/images/icons/patient-categories/reptil.svg");
                                        dom.appendChild(el0, el1);
                                        var el1 = dom.createTextNode("\n");
                                        dom.appendChild(el0, el1);
                                        return el0;
                                      },
                                      buildRenderNodes: function buildRenderNodes() { return []; },
                                      statements: [

                                      ],
                                      locals: [],
                                      templates: []
                                    };
                                  }());
                                  var child1 = (function() {
                                    var child0 = (function() {
                                      return {
                                        meta: {
                                          "revision": "Ember@1.13.5",
                                          "loc": {
                                            "source": null,
                                            "start": {
                                              "line": 32,
                                              "column": 0
                                            },
                                            "end": {
                                              "line": 34,
                                              "column": 0
                                            }
                                          },
                                          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                        },
                                        arity: 0,
                                        cachedFragment: null,
                                        hasRendered: false,
                                        buildFragment: function buildFragment(dom) {
                                          var el0 = dom.createDocumentFragment();
                                          var el1 = dom.createTextNode("  ");
                                          dom.appendChild(el0, el1);
                                          var el1 = dom.createElement("img");
                                          dom.setAttribute(el1,"src","assets/images/icons/patient-categories/schwein.svg");
                                          dom.appendChild(el0, el1);
                                          var el1 = dom.createTextNode("\n");
                                          dom.appendChild(el0, el1);
                                          return el0;
                                        },
                                        buildRenderNodes: function buildRenderNodes() { return []; },
                                        statements: [

                                        ],
                                        locals: [],
                                        templates: []
                                      };
                                    }());
                                    var child1 = (function() {
                                      var child0 = (function() {
                                        return {
                                          meta: {
                                            "revision": "Ember@1.13.5",
                                            "loc": {
                                              "source": null,
                                              "start": {
                                                "line": 34,
                                                "column": 0
                                              },
                                              "end": {
                                                "line": 36,
                                                "column": 0
                                              }
                                            },
                                            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                          },
                                          arity: 0,
                                          cachedFragment: null,
                                          hasRendered: false,
                                          buildFragment: function buildFragment(dom) {
                                            var el0 = dom.createDocumentFragment();
                                            var el1 = dom.createTextNode("  ");
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createElement("img");
                                            dom.setAttribute(el1,"src","assets/images/icons/patient-categories/vogel.svg");
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createTextNode("\n");
                                            dom.appendChild(el0, el1);
                                            return el0;
                                          },
                                          buildRenderNodes: function buildRenderNodes() { return []; },
                                          statements: [

                                          ],
                                          locals: [],
                                          templates: []
                                        };
                                      }());
                                      var child1 = (function() {
                                        return {
                                          meta: {
                                            "revision": "Ember@1.13.5",
                                            "loc": {
                                              "source": null,
                                              "start": {
                                                "line": 36,
                                                "column": 0
                                              },
                                              "end": {
                                                "line": 38,
                                                "column": 0
                                              }
                                            },
                                            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                          },
                                          arity: 0,
                                          cachedFragment: null,
                                          hasRendered: false,
                                          buildFragment: function buildFragment(dom) {
                                            var el0 = dom.createDocumentFragment();
                                            var el1 = dom.createTextNode("  ");
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createElement("img");
                                            dom.setAttribute(el1,"src","assets/images/icons/patient-categories/anderes.svg");
                                            dom.appendChild(el0, el1);
                                            var el1 = dom.createTextNode("\n");
                                            dom.appendChild(el0, el1);
                                            return el0;
                                          },
                                          buildRenderNodes: function buildRenderNodes() { return []; },
                                          statements: [

                                          ],
                                          locals: [],
                                          templates: []
                                        };
                                      }());
                                      return {
                                        meta: {
                                          "revision": "Ember@1.13.5",
                                          "loc": {
                                            "source": null,
                                            "start": {
                                              "line": 34,
                                              "column": 0
                                            },
                                            "end": {
                                              "line": 38,
                                              "column": 0
                                            }
                                          },
                                          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                        },
                                        arity: 0,
                                        cachedFragment: null,
                                        hasRendered: false,
                                        buildFragment: function buildFragment(dom) {
                                          var el0 = dom.createDocumentFragment();
                                          var el1 = dom.createComment("");
                                          dom.appendChild(el0, el1);
                                          return el0;
                                        },
                                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                          var morphs = new Array(1);
                                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                          dom.insertBoundary(fragment, 0);
                                          dom.insertBoundary(fragment, null);
                                          return morphs;
                                        },
                                        statements: [
                                          ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[34,14],[34,23]]]],"vogel"],[],["loc",[null,[34,10],[34,32]]]]],[],0,1,["loc",[null,[34,0],[38,0]]]]
                                        ],
                                        locals: [],
                                        templates: [child0, child1]
                                      };
                                    }());
                                    return {
                                      meta: {
                                        "revision": "Ember@1.13.5",
                                        "loc": {
                                          "source": null,
                                          "start": {
                                            "line": 32,
                                            "column": 0
                                          },
                                          "end": {
                                            "line": 38,
                                            "column": 0
                                          }
                                        },
                                        "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                      },
                                      arity: 0,
                                      cachedFragment: null,
                                      hasRendered: false,
                                      buildFragment: function buildFragment(dom) {
                                        var el0 = dom.createDocumentFragment();
                                        var el1 = dom.createComment("");
                                        dom.appendChild(el0, el1);
                                        return el0;
                                      },
                                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                        var morphs = new Array(1);
                                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                        dom.insertBoundary(fragment, 0);
                                        dom.insertBoundary(fragment, null);
                                        return morphs;
                                      },
                                      statements: [
                                        ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[32,14],[32,23]]]],"schwein"],[],["loc",[null,[32,10],[32,34]]]]],[],0,1,["loc",[null,[32,0],[38,0]]]]
                                      ],
                                      locals: [],
                                      templates: [child0, child1]
                                    };
                                  }());
                                  return {
                                    meta: {
                                      "revision": "Ember@1.13.5",
                                      "loc": {
                                        "source": null,
                                        "start": {
                                          "line": 30,
                                          "column": 0
                                        },
                                        "end": {
                                          "line": 38,
                                          "column": 0
                                        }
                                      },
                                      "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                    },
                                    arity: 0,
                                    cachedFragment: null,
                                    hasRendered: false,
                                    buildFragment: function buildFragment(dom) {
                                      var el0 = dom.createDocumentFragment();
                                      var el1 = dom.createComment("");
                                      dom.appendChild(el0, el1);
                                      return el0;
                                    },
                                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                      var morphs = new Array(1);
                                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                      dom.insertBoundary(fragment, 0);
                                      dom.insertBoundary(fragment, null);
                                      return morphs;
                                    },
                                    statements: [
                                      ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[30,14],[30,23]]]],"schlange"],[],["loc",[null,[30,10],[30,35]]]]],[],0,1,["loc",[null,[30,0],[38,0]]]]
                                    ],
                                    locals: [],
                                    templates: [child0, child1]
                                  };
                                }());
                                return {
                                  meta: {
                                    "revision": "Ember@1.13.5",
                                    "loc": {
                                      "source": null,
                                      "start": {
                                        "line": 28,
                                        "column": 0
                                      },
                                      "end": {
                                        "line": 38,
                                        "column": 0
                                      }
                                    },
                                    "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                  },
                                  arity: 0,
                                  cachedFragment: null,
                                  hasRendered: false,
                                  buildFragment: function buildFragment(dom) {
                                    var el0 = dom.createDocumentFragment();
                                    var el1 = dom.createComment("");
                                    dom.appendChild(el0, el1);
                                    return el0;
                                  },
                                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                    var morphs = new Array(1);
                                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                    dom.insertBoundary(fragment, 0);
                                    dom.insertBoundary(fragment, null);
                                    return morphs;
                                  },
                                  statements: [
                                    ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[28,14],[28,23]]]],"echse"],[],["loc",[null,[28,10],[28,32]]]]],[],0,1,["loc",[null,[28,0],[38,0]]]]
                                  ],
                                  locals: [],
                                  templates: [child0, child1]
                                };
                              }());
                              return {
                                meta: {
                                  "revision": "Ember@1.13.5",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 26,
                                      "column": 0
                                    },
                                    "end": {
                                      "line": 38,
                                      "column": 0
                                    }
                                  },
                                  "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                                },
                                arity: 0,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createComment("");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                  var morphs = new Array(1);
                                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                  dom.insertBoundary(fragment, 0);
                                  dom.insertBoundary(fragment, null);
                                  return morphs;
                                },
                                statements: [
                                  ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[26,14],[26,23]]]],"ziege"],[],["loc",[null,[26,10],[26,32]]]]],[],0,1,["loc",[null,[26,0],[38,0]]]]
                                ],
                                locals: [],
                                templates: [child0, child1]
                              };
                            }());
                            return {
                              meta: {
                                "revision": "Ember@1.13.5",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 24,
                                    "column": 0
                                  },
                                  "end": {
                                    "line": 38,
                                    "column": 0
                                  }
                                },
                                "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                              },
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                                dom.insertBoundary(fragment, 0);
                                dom.insertBoundary(fragment, null);
                                return morphs;
                              },
                              statements: [
                                ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[24,14],[24,23]]]],"zootier"],[],["loc",[null,[24,10],[24,34]]]]],[],0,1,["loc",[null,[24,0],[38,0]]]]
                              ],
                              locals: [],
                              templates: [child0, child1]
                            };
                          }());
                          return {
                            meta: {
                              "revision": "Ember@1.13.5",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 22,
                                  "column": 0
                                },
                                "end": {
                                  "line": 38,
                                  "column": 0
                                }
                              },
                              "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                            },
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [
                              ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[22,14],[22,23]]]],"anderes"],[],["loc",[null,[22,10],[22,34]]]]],[],0,1,["loc",[null,[22,0],[38,0]]]]
                            ],
                            locals: [],
                            templates: [child0, child1]
                          };
                        }());
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 20,
                                "column": 0
                              },
                              "end": {
                                "line": 38,
                                "column": 0
                              }
                            },
                            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [
                            ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[20,14],[20,23]]]],"heimtier"],[],["loc",[null,[20,10],[20,35]]]]],[],0,1,["loc",[null,[20,0],[38,0]]]]
                          ],
                          locals: [],
                          templates: [child0, child1]
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 18,
                              "column": 0
                            },
                            "end": {
                              "line": 38,
                              "column": 0
                            }
                          },
                          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[18,14],[18,23]]]],"fisch"],[],["loc",[null,[18,10],[18,32]]]]],[],0,1,["loc",[null,[18,0],[38,0]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 16,
                            "column": 0
                          },
                          "end": {
                            "line": 38,
                            "column": 0
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[16,14],[16,23]]]],"schaf"],[],["loc",[null,[16,10],[16,32]]]]],[],0,1,["loc",[null,[16,0],[38,0]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 14,
                          "column": 0
                        },
                        "end": {
                          "line": 38,
                          "column": 0
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[14,14],[14,23]]]],"rind"],[],["loc",[null,[14,10],[14,31]]]]],[],0,1,["loc",[null,[14,0],[38,0]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 12,
                        "column": 0
                      },
                      "end": {
                        "line": 38,
                        "column": 0
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[12,14],[12,23]]]],"reptil"],[],["loc",[null,[12,10],[12,33]]]]],[],0,1,["loc",[null,[12,0],[38,0]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 0
                    },
                    "end": {
                      "line": 38,
                      "column": 0
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[10,14],[10,23]]]],"pferd"],[],["loc",[null,[10,10],[10,32]]]]],[],0,1,["loc",[null,[10,0],[38,0]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 0
                  },
                  "end": {
                    "line": 38,
                    "column": 0
                  }
                },
                "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","is",[["get","animal-id",["loc",[null,[8,14],[8,23]]]],"esel"],[],["loc",[null,[8,10],[8,31]]]]],[],0,1,["loc",[null,[8,0],[38,0]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 0
                },
                "end": {
                  "line": 38,
                  "column": 0
                }
              },
              "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["subexpr","is",[["get","animal-id",["loc",[null,[6,18],[6,27]]]],"katze"],[],["loc",[null,[6,14],[6,36]]]],["subexpr","is",[["get","animal-id",["loc",[null,[6,41],[6,50]]]],"ka"],[],["loc",[null,[6,37],[6,56]]]]],[],["loc",[null,[6,10],[6,57]]]]],[],0,1,["loc",[null,[6,0],[38,0]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 0
              },
              "end": {
                "line": 38,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["subexpr","is",[["get","animal-id",["loc",[null,[4,18],[4,27]]]],"hund"],[],["loc",[null,[4,14],[4,35]]]],["subexpr","is",[["get","animal-id",["loc",[null,[4,40],[4,49]]]],"hu"],[],["loc",[null,[4,36],[4,55]]]]],[],["loc",[null,[4,10],[4,56]]]]],[],0,1,["loc",[null,[4,0],[38,0]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","and",[["subexpr","is",[["get","animal-id",["loc",[null,[2,19],[2,28]]]],"heimtier"],[],["loc",[null,[2,15],[2,40]]]],["subexpr","is",[["get","race-id",["loc",[null,[2,45],[2,52]]]],"rabbit"],[],["loc",[null,[2,41],[2,62]]]]],[],["loc",[null,[2,10],[2,63]]]]],[],0,1,["loc",[null,[2,0],[38,0]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/eltiga.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["eltiga-badge max-w-",["get","eltigaBadgeSize",["loc",[null,[40,35],[40,50]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/animal-icon.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","and",[["subexpr","is",[["get","animal-id",["loc",[null,[1,15],[1,24]]]],"heimtier"],[],["loc",[null,[1,11],[1,36]]]],["subexpr","is",[["get","race-id",["loc",[null,[1,41],[1,48]]]],"frettchen"],[],["loc",[null,[1,37],[1,61]]]]],[],["loc",[null,[1,6],[1,62]]]]],[],0,1,["loc",[null,[1,0],[38,7]]]],
        ["block","if",[["get","eltigaActive",["loc",[null,[39,6],[39,18]]]]],[],2,null,["loc",[null,[39,0],[41,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
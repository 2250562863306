define('jason-frontend/templates/practicemgmt/treatment-journal-new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Behandlungen im CSV-Format herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["download"],[],["loc",[null,[6,11],[6,32]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[34,4],[34,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Journal");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter rework-content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 3]),1,1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[5] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[6] = dom.createMorphAt(fragment,8,8,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["block","if",[["subexpr","has-permission",["d_treatment_history"],[],["loc",[null,[5,12],[5,50]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openDocument","openDocument","openSlopingInfos","openSlopingInfos","gotoReport","gotoReport","gotoTemplate","gotoTemplate","gotoVisitDetails","gotoVisitDetails","openLab","openLab","openApp","openEditAppointment","doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[26,14],[26,21]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[27,17],[27,27]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[28,12],[28,17]]]]],[],[]],"stables",["subexpr","@mut",[["get","stables",["loc",[null,[29,14],[29,21]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[30,24],[30,41]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[31,21],[31,38]]]]],[],[]]],["loc",[null,[15,4],[31,40]]]],
        ["block","if",[["get","showLoading",["loc",[null,[33,8],[33,19]]]]],[],1,null,["loc",[null,[33,2],[35,9]]]],
        ["inline","edit-patient-memo-panel",[],["reload","reload","actionReceiver",["subexpr","@mut",[["get","editPatientMemoPanel",["loc",[null,[39,57],[39,77]]]]],[],[]]],["loc",[null,[39,0],[39,79]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[40,30],[40,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[40,44],[40,51]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[40,63],[40,73]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[40,84],[40,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[40,100],[40,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[40,115],[40,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[40,166],[40,185]]]]],[],[]]],["loc",[null,[40,0],[40,187]]]],
        ["inline","sloping-info-panel",[],["hideSave",false,"gotoProduct","gotoProduct","save","saveSlopingInfo","selectBarcode","selectSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[41,137],[41,153]]]]],[],[]]],["loc",[null,[41,0],[41,155]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
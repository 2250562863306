define('jason-frontend/templates/components/button-station-new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/button-station-new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("circle");
          dom.setAttribute(el1,"class","circle");
          dom.setAttribute(el1,"cx","16");
          dom.setAttribute(el1,"cy","16");
          dom.setAttribute(el1,"r","16");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          return morphs;
        },
        statements: [
          ["attribute","stroke",["concat",[["get","color",["loc",[null,[6,62],[6,67]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-station-new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"viewBox","0 0 34 34");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"fill","none");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"transform","translate(1 1)");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.setAttribute(el3,"transform","translate(4,4) scale(0.8,0.8)");
        dom.setAttribute(el3,"fill-rule","evenodd");
        dom.setAttribute(el3,"class","icon-wrapper");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"d","M9.21425 15C10.9865 15 12.4285 13.558 12.4285 11.7857C12.4285 10.0134 10.9865 8.57142 9.21425 8.57142C7.44198 8.57142 5.99997 10.0134 5.99997 11.7857C5.99997 13.558 7.44198 15 9.21425 15ZM23.3571 9.85713H14.3571C14.0019 9.85713 13.7143 10.1448 13.7143 10.5V16.2857H4.71425V7.92856C4.71425 7.57338 4.42657 7.28571 4.07139 7.28571H2.78568C2.4305 7.28571 2.14282 7.57338 2.14282 7.92856V22.0714C2.14282 22.4266 2.4305 22.7143 2.78568 22.7143H4.07139C4.42657 22.7143 4.71425 22.4266 4.71425 22.0714V20.1428H25.2857V22.0714C25.2857 22.4266 25.5734 22.7143 25.9285 22.7143H27.2143C27.5694 22.7143 27.8571 22.4266 27.8571 22.0714V14.3571C27.8571 11.8717 25.8426 9.85713 23.3571 9.85713Z");
        dom.setAttribute(el4,"fill","white");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element2,1,1);
        morphs[4] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[1,49],[1,53]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[1,67],[1,71]]]]]]],
        ["attribute","class",["concat",["station-status-",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[2,48],[2,56]]]],"selected","none"],[],["loc",[null,[2,27],[2,76]]]]," state-button cursor ",["subexpr","css-bool-evaluator",[["get","selected",["loc",[null,[3,7],[3,15]]]],"active",""],[],["loc",[null,[2,97],[3,29]]]]]]],
        ["block","if",[["get","showStroke",["loc",[null,[5,10],[5,20]]]]],[],0,null,["loc",[null,[5,4],[7,11]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[8,15],[8,20]]]]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
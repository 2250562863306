define('jason-frontend/controllers/appointments/calendar', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Kalender",
    breadCrumbPath: "appointments.calendar",
    showLoading: true,
    sidebarActive: false,
    showFilter: false,
    usersExpanded: true,
    hideBgEvents: true,
    colorModeUserEnabled: false,
    resourcesExpanded: false,
    stationsExpanded: false,
    roomsExpanded: false,
    showUserBadges: true,
    colorMode: 'category',
    catsExpanded: true,
    switchToDate: null,
    onlyMine: false,
    selectedResourceFilterCount: 0,
    selectedResources: [],
    selectedView: API['default'].getCalWeekdays() === '7' ? 'timeGridWeek' : 'timeGridFiveDays',
    views: [{
      id: "timeGrid", name: "Tag"
    }, {
      id: "timeGridFiveDays", name: "5-Tage"
    }, {
      id: "timeGridWeek", name: "Woche"
    }],
    actions: {
      admissionPatient: function admissionPatient(customerId, patientId, app) {

        var self = this;
        var patients = [patientId];

        this.get('startTreatmentPanel').send('load', customerId, patients, app);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchView: (function () {
      var self = this;
      self.send('changeView', this.get('selectedView'));
    }).observes('selectedView'),
    watchSwitchToDate: (function () {
      this.send('gotoDate', this.get('switchToDate'));
    }).observes('switchToDate')
  });

});
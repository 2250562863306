define('jason-frontend/helpers/list-empty', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.listIsEmpty = listIsEmpty;

  function listIsEmpty(params) {
    var list = params[0];
    var isEmpty = false;
    if (!list) {
      isEmpty = true;
    } else if (list.get && list.get('totalPages') !== undefined && list.get('totalPages') === 0) {
      isEmpty = true;
    } else if (list.length === 0) {
      isEmpty = true;
    }
    return isEmpty;
  }

  exports['default'] = Ember['default'].Helper.helper(listIsEmpty);

});
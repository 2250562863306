define('jason-frontend/controllers/inventory/stocktaking', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Inventuren",
    breadCrumbPath: "inventory.stocktaking",
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    page: 1,
    showLoading: false,
    perPage: 20,
    filterStatus: 'created,used',
    showFilters: true,
    actions: {}
  });

});
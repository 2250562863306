define('jason-frontend/components/treatment-now-entry', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    showSlopingDownload: true,
    newMode: false,
    doctor: null,
    showVisitDetails: false,
    assistant: null,
    showExtraButtons: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initTooltips();
    },
    initTooltips: function initTooltips() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.$('.tooltipstered').tooltipster({
          theme: 'tooltipster-punk',
          delay: 0,
          side: 'top',
          touchDevices: false
        });
      });
    },
    actions: {
      changeDay: function changeDay(entry) {
        var day = this.$().find("input[data-day-entry-id='" + entry.id + "']").val();
        if (day !== entry.get('day')) {
          this.sendAction('changeDay', day, entry.id);
        }
      },
      openLaboklinChoosePanel: function openLaboklinChoosePanel(entryId, laboklinFormId, product, privateTreatment, subType) {
        this.sendAction('openLaboklinChoosePanel', entryId, laboklinFormId, product, privateTreatment, subType);
      },
      openVisitDetails: function openVisitDetails(entryId) {
        this.sendAction('openVisitDetails', entryId);
      },
      openXrayEntryPanel: function openXrayEntryPanel(entryId, xrayId, product) {
        this.sendAction('openXrayEntryPanel', entryId, xrayId, product);
      },
      addTemplateToPosition: function addTemplateToPosition(entry) {
        this.sendAction('addTemplateToPosition', entry);
      },
      openInventoryStockPanel: function openInventoryStockPanel(entry) {
        this.sendAction('openInventoryStockPanel', entry);
      },
      openDigitalForm: function openDigitalForm(entry) {
        this.sendAction('openDigitalForm', entry);
      },
      downloadLaboklinBarcode: function downloadLaboklinBarcode(entry) {
        this.sendAction('downloadLaboklinBarcode', entry);
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(entry) {
        this.sendAction('downloadSlopingBarcode', entry);
      },
      openSlopingInfos: function openSlopingInfos(entry) {
        this.sendAction('openSlopingInfos', entry);
      },
      openTemplatePanel: function openTemplatePanel(entryId, instanceId, readOnly, patient) {
        this.sendAction('openTemplateEdit', entryId, instanceId, readOnly, patient);
      },
      openMedia: function openMedia(tp, media, patient) {
        if (media.externalUrl) {
          window.open(media.externalUrl);
        } else {
          window.open('/api/media/' + media.id + '/download?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
        }
      },
      'delete': function _delete(entryId, offerId, productName) {
        this.sendAction('deleted', entryId, offerId, productName);
      },
      changeText: function changeText(entry, offerId, productName, oldText) {
        var text = this.$().find("input[data-article-text-entry-id='" + entry.id + "']").val();
        if (oldText !== text) {
          this.sendAction('textChanged', text, entry.id, offerId, productName);
        }
      },
      changeFactor: function changeFactor(entry) {
        this.sendAction('gotFactorChanged', entry);
      },
      changePrice: function changePrice(entry, invoiceId, productName) {
        var price = this.$().find("input[data-price-entry-id='" + entry.id + "']").val();
        price = price.replace(',', '.');
        if (price > 0) {
          this.sendAction('priceChanged', price, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeQuantity: function changeQuantity(qty, entryId, offerId, productName) {
        this.sendAction('quantityChanged', qty, entryId, offerId, productName);
      },
      moveUp: function moveUp(offerId, entryId, disabled) {
        if (!disabled) {
          this.sendAction('moveUp', offerId, entryId);
        }
      },
      moveDown: function moveDown(offerId, entryId, disabled) {
        if (!disabled) {
          this.sendAction('moveDown', offerId, entryId);
        }
      },
      changeGrossPrice: function changeGrossPrice(entry, percentage, invoiceId, productName) {
        var grossPrice = String(this.$().find("input[data-gross-price-entry-id='" + entry.id + "']").val()).replace(',', '.');
        var netPrice = this.$().find("input[data-price-entry-id='" + entry.id + "']"),
            newNetPrice = Math.round(grossPrice / (100 + percentage) * 100 * 1000) / 1000;

        if (newNetPrice > 0) {
          netPrice.val(String(newNetPrice).replace('.', ','));
          this.sendAction('priceChanged', newNetPrice, entry.id, invoiceId, productName);
        } else {
          jason.notifiction.error('Fehleingabe', 'Preis muss größer/gleich 0 sein');
        }
      },
      changeApplied: function changeApplied(entry, invoiceId, productName) {
        this.sendAction('appliedChanged', !entry.get('applied'), entry.id, invoiceId, productName);
      },
      setRelativeDiscountOfferEntry: function setRelativeDiscountOfferEntry(entry, invoiceId, discount, absolute, productName) {
        if (absolute) {
          this.sendAction('relativeDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      },
      setAbsoluteDiscountOfferEntry: function setAbsoluteDiscountOfferEntry(entry, invoiceId, discount, absolute, productName) {
        if (!absolute) {
          this.sendAction('absoluteDiscountChanged', entry.id, discount, invoiceId, productName);
        }
      },
      changeDiscountOfferEntry: function changeDiscountOfferEntry(entry, invoiceId, absolute, productName, oldDiscount) {
        var discount = this.$().find("input[data-discount-entry-id='" + entry.id + "']").val();
        if (oldDiscount != discount) {
          if (discount.replace(',', '.') >= 0) {
            this.sendAction('discountChanged', discount, absolute, entry.id, invoiceId, productName);
          }
        }
      }
    },
    doctorOberserver: (function () {
      var self = $(this);

      self.removeClass('unchanged');

      var data = {
        id: this.get('doctor') ? this.get('doctor').id : null,
        type: 'doctor'
      };

      $.ajax({
        url: "/api/treatmentOffers/0/changeUser/" + this.get('entry').get('id'),
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (error) {});
    }).observes('doctor'),
    assistantOberserver: (function () {
      var self = $(this);

      self.removeClass('unchanged');

      var data = {
        id: this.get('assistant') ? this.get('assistant').id : null,
        type: 'assistant'
      };

      $.ajax({
        url: "/api/treatmentOffers/0/changeUser/" + this.get('entry').get('id'),
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (error) {});
    }).observes('assistant')
  });

});
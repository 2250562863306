define('jason-frontend/helpers/is-station-extended-enabled', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports.isStationExtendedEnabled = isStationExtendedEnabled;

  function isStationExtendedEnabled() {
    return API['default'].isStationExtendedEnabled();
  }

  exports['default'] = Ember['default'].Helper.helper(isStationExtendedEnabled);

});
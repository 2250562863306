define('jason-frontend/routes/practicemgmt/admission-select-customer', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, RouteMixin, API, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {
      return this.findPaged('customer', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("gendersAll", this.store.find('gender'));

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category', { includeNull: true }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('doctors', this.store.find('user', { role: 'doctor' }));
      controller.set('assistants', this.store.find('user', { role: 'assistant,doctor', per_page: 25 }));
      controller.set("genders", this.store.find('gender'));

      controller.set("model", model);

      setTimeout(function () {
        $('form.search-box input[type=text]').focus();
      }, 500);
    },
    applyFilter: function applyFilter(searchString) {
      var filterDeleted = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
      var filterStable = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterPatient = controller.get('filterPatient'),
          filterChip = controller.get('filterChip'),
          filterContact = controller.get('filterContact'),
          filterAddress = controller.get('filterAddress'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('customer', {
        filterName: filterName,
        filterPatient: filterPatient,
        filterChip: filterChip,
        filterContact: filterContact,
        filterAddress: filterAddress,
        filterDeleted: filterDeleted ? "true" : "false",
        filterStable: filterStable ? "true" : "false",
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    actions: {
      resetSearch: function resetSearch() {
        this.get('controller').set('filterName', '');
        this.get('controller').set('filterContact', '');
        this.get('controller').set('filterChip', '');
        this.get('controller').set('filterAddress', '');
        this.get('controller').set('filterPatient', '');
        this.applyFilter();
      },
      triggerSearch: function triggerSearch() {
        this.applyFilter();
      },
      createPublicAdmissionPin: function createPublicAdmissionPin() {
        var self = this;
        $.magnificPopup.open({
          removalDelay: 200,
          closeOnBgClick: false,
          items: {
            src: '#modal-public-admission-pin-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        self.get('controller').get('publicAdmissionPinPanel').send('load');
      },
      gotoDetails: function gotoDetails(id) {
        this.transitionTo('customers.edit', id);
      },
      selectPatientForEtigaActivation: function selectPatientForEtigaActivation(clientId, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + clientId + "/connectToEtigaMaster/" + masterId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        }, function () {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', clientId);
        });
      },
      selectCustomerForEtigaActivation: function selectCustomerForEtigaActivation(customerId, patientId, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/connectCustomerToEtigaMaster/" + masterId + "/" + customerId + "/" + patientId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('eltiga aktiviert', '');
          self.transitionTo('patients.edit', data);
        });
      },
      createCustomerForEtigaActivation: function createCustomerForEtigaActivation(customer, masterId) {
        var self = this;
        $.ajax({
          url: "/api/patients/createCustomerAndConnectToEtigaMaster/" + masterId + "?referer=" + this.get('controller').get('referer'),
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(customer),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        }, function (data) {
          jason.notifiction.notifiy('Kunde erstellt', 'eltiga für Patient aktiviert');
          self.transitionTo('patients.edit', data);
        });
      },
      setCashsale: function setCashsale() {
        var invoice = {},
            self = this;

        Ember['default'].$.ajax({
          url: "/api/invoices",
          method: "POST",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var invoiceId = data;

          var invoice = {
            id: invoiceId,
            customer: null
          };

          $.ajax({
            url: "/api/invoices/" + invoiceId + "/changecustomer",
            method: "PUT",
            data: JSON.stringify(invoice),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.transitionTo('invoices.create', invoiceId);
            return data;
          });
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      gotoCustomer: function gotoCustomer(id) {
        this.transitionTo('customers.edit', id);
      },
      toWaitingRoom: function toWaitingRoom() {
        this.transitionTo('practicemgmt.admission');
      },
      toTreatment: function toTreatment(id) {
        this.transitionTo('practicemgmt.treatment-details', id);
      },
      gotoPatient: function gotoPatient(id, tab) {
        if (tab) {
          if (is_journal_enabled.isJournalEnabled() && tab === 'history') {
            tab = 'activities';
          }
          this.transitionTo('patients.edit', id, { queryParams: { selectTab: tab } });
        } else {
          this.transitionTo('patients.edit', id);
        }
      },
      applyFilter: function applyFilter(searchString, filterDeleted, filterStable) {
        this.applyFilter(searchString, filterDeleted, filterStable);
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          castrated: patientData.castrated,
          chipId: patientData.chipId,
          customerId: customerId
        };

        if (patientData.category) {
          newPatient.category = {
            id: patientData.category.id
          };
        }
        if (patientData.digitalRace) {
          newPatient.digitalRace = {
            id: patientData.digitalRace.id
          };
        }
        if (patientData.gender) {
          newPatient.gender = {
            id: patientData.gender.id
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter('');
        }, function (error) {});
      },
      selectCustomerConfirm: function selectCustomerConfirm(customerId, data, appointment, time, directTreatment) {
        var self = this,
            treatment = {
          customer: {
            id: customerId
          }
        };

        treatment.selectedPatients = data;
        treatment.appointment = appointment;
        treatment.time = time;

        $.ajax({
          url: "/api/treatments",
          method: "PUT",
          data: JSON.stringify(treatment),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          if (directTreatment) {
            self.transitionTo('practicemgmt.treatment-details', id, { queryParams: { entry: "true" } });
          } else {
            self.transitionTo('practicemgmt.admission');
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      create: function create(invoiceId, customerData, patientData) {
        var controller = this.get('controller');

        var newCustomer = this.store.createRecord('customer', {
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          genderId: customerData.genderId,
          email: customerData.email,
          street: customerData.street,
          postalCode: customerData.postalCode,
          town: customerData.town,
          phone1: customerData.phone
        });

        newCustomer.save().then(function (storedCustomer) {

          if (patientData) {

            var newPatient = {
              name: patientData.name,
              customerId: storedCustomer.id,
              castrated: patientData.castrated,
              birthdayFormatted: patientData.birthdayFormatted,
              chipId: patientData.chipId
            };

            if (patientData.patientCategory) {
              newPatient.category = {
                id: patientData.patientCategory
              };
            }
            if (patientData.patientGender) {
              newPatient.gender = {
                id: patientData.patientGender
              };
            }

            $.ajax({
              url: "/api/patients",
              method: "POST",
              data: JSON.stringify(newPatient),
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              var patients = Array();
              patients.push(data.patient.id);
            }, function (error) {});
          } else {}
        });
      }
    }

  });

});
define('jason-frontend/templates/patients/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/eltiga.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("eltiga QR-Code");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element91 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element91);
            return morphs;
          },
          statements: [
            ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[7,44],[7,49]]]]],[],["loc",[null,[7,13],[7,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 14,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/eltiga-light-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("eltiga aktivieren");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element90 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element90);
            return morphs;
          },
          statements: [
            ["element","action",["activateEtiga"],[],["loc",[null,[11,13],[11,39]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.etigaActive",["loc",[null,[6,14],[6,31]]]]],[],0,1,["loc",[null,[6,8],[14,15]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 21,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Notiz erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element89 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element89);
          return morphs;
        },
        statements: [
          ["element","action",["addNewMemo",["get","model.id",["loc",[null,[18,34],[18,42]]]]],[],["loc",[null,[18,11],[18,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 6
            },
            "end": {
              "line": 27,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Dokument hochladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element88 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element88);
          return morphs;
        },
        statements: [
          ["element","action",["openDocumentUpload"],[],["loc",[null,[23,11],[23,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 6
            },
            "end": {
              "line": 32,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Aufgabe erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element87 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element87);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[29,11],[29,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 6
            },
            "end": {
              "line": 36,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element86 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element86);
          return morphs;
        },
        statements: [
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[34,11],[34,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 6
            },
            "end": {
              "line": 41,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element85 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element85);
          return morphs;
        },
        statements: [
          ["element","action",["downloadHistoryCsv",["get","model.id",["loc",[null,[38,41],[38,49]]]]],[],["loc",[null,[38,11],[38,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 6
            },
            "end": {
              "line": 50,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/chat-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element84 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element84);
          return morphs;
        },
        statements: [
          ["element","action",["openDirectMessage"],[],["loc",[null,[47,11],[47,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 12
              },
              "end": {
                "line": 69,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/chip-dark.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Chip registrieren");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element82 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element82);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[65,42],[65,47]]]]],[],["loc",[null,[65,17],[65,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 10
            },
            "end": {
              "line": 70,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/picture-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tierbild ändern");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element83 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element83);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["showImagePanel"],[],["loc",[null,[60,15],[60,42]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[64,18],[64,30]]]]],[],0,null,["loc",[null,[64,12],[69,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 10
            },
            "end": {
              "line": 80,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Ordner herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element81 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element81);
          return morphs;
        },
        statements: [
          ["element","action",["downloadFolder"],[],["loc",[null,[76,15],[76,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 10
            },
            "end": {
              "line": 86,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element80 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element80);
          return morphs;
        },
        statements: [
          ["element","action",["downloadAppointmentCsv",["get","model.id",["loc",[null,[82,49],[82,57]]]]],[],["loc",[null,[82,15],[82,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 87,
              "column": 10
            },
            "end": {
              "line": 92,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/view-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Alle Laborwerte anzeigen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element79 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element79);
          return morphs;
        },
        statements: [
          ["element","action",["openAllLabParamsPanel",["get","model.id",["loc",[null,[88,49],[88,57]]]]],[],["loc",[null,[88,15],[88,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 10
            },
            "end": {
              "line": 103,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/notification-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erinnerung planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/appointment-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/more-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Zukunft planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element76 = dom.childAt(fragment, [1]);
          var element77 = dom.childAt(fragment, [3]);
          var element78 = dom.childAt(fragment, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element76);
          morphs[1] = dom.createElementMorph(element77);
          morphs[2] = dom.createElementMorph(element78);
          return morphs;
        },
        statements: [
          ["element","action",["openReminderPanel"],[],["loc",[null,[94,15],[94,45]]]],
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[97,15],[97,48]]]],
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[100,42],[100,50]]]]],[],["loc",[null,[100,15],[100,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 10
            },
            "end": {
              "line": 108,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/history-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element75 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element75);
          return morphs;
        },
        statements: [
          ["element","action",["openLogbook"],[],["loc",[null,[105,15],[105,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 10
            },
            "end": {
              "line": 116,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/archive-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tier archivieren");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element74 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element74);
          return morphs;
        },
        statements: [
          ["element","action",["setDeathDate","dead"],[],["loc",[null,[113,15],[113,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 6
            },
            "end": {
              "line": 130,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element73 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element73, 'style');
          return morphs;
        },
        statements: [
          ["attribute","style",["concat",["background-image: url('https://res.cloudinary.com/",["get","model.picture.cdnAcc",["loc",[null,[129,87],[129,107]]]],"/image/upload/c_fill,h_300,w_300/",["get","model.picture.cdnId",["loc",[null,[129,144],[129,163]]]],"')"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 130,
              "column": 6
            },
            "end": {
              "line": 132,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","cursor image");
          dom.setAttribute(el1,"style","background-image: url('assets/images/icons/rework/add-image.svg')");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element72 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element72);
          return morphs;
        },
        statements: [
          ["element","action",["showImagePanel"],[],["loc",[null,[131,14],[131,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 6
            },
            "end": {
              "line": 135,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","eltiga-badge w-50px tooltipstered cursor");
          dom.setAttribute(el1,"src","assets/images/icons/rework/eltiga.svg");
          dom.setAttribute(el1,"title","eltiga aktiviert");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element71 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element71);
          return morphs;
        },
        statements: [
          ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[134,163],[134,168]]]]],[],["loc",[null,[134,132],[134,170]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 140,
              "column": 8
            },
            "end": {
              "line": 142,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/dead.svg");
          dom.setAttribute(el1,"title","Verstorben");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 8
            },
            "end": {
              "line": 145,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/insured.svg");
          dom.setAttribute(el1,"title","Versichert");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 147,
                "column": 10
              },
              "end": {
                "line": 149,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/sun.svg");
            dom.setAttribute(el1,"title","CAVE");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 10
              },
              "end": {
                "line": 151,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/information-danger.svg");
            dom.setAttribute(el1,"title","CAVE");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 146,
              "column": 8
            },
            "end": {
              "line": 152,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[147,20],[147,44]]]],"153"],[],["loc",[null,[147,16],[147,51]]]]],[],0,1,["loc",[null,[147,10],[151,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 153,
              "column": 8
            },
            "end": {
              "line": 153,
              "column": 140
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/outdoor.svg");
          dom.setAttribute(el1,"title","Freigänger:in");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 154,
              "column": 8
            },
            "end": {
              "line": 154,
              "column": 196
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/heart.svg");
          dom.setAttribute(el1,"title","Chronische Erkrankungen /Medikamenten-Unverträglichkeit");
          dom.setAttribute(el1,"class","tooltipstered ms-10 max-h-30px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 157,
                "column": 384
              },
              "end": {
                "line": 157,
                "column": 435
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.digitalRace.name",["loc",[null,[157,409],[157,435]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 157,
                "column": 435
              },
              "end": {
                "line": 157,
                "column": 457
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.race",["loc",[null,[157,443],[157,457]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 157,
              "column": 263
            },
            "end": {
              "line": 157,
              "column": 471
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"style","overflow: hidden; height: 20px;margin-top:-5px");
          dom.setAttribute(el1,"class","word-break");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.digitalRace",["loc",[null,[157,390],[157,407]]]]],[],0,1,["loc",[null,[157,384],[157,464]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child23 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 80
              },
              "end": {
                "line": 158,
                "column": 224
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.customer.firstname",["loc",[null,[158,168],[158,196]]]],
            ["content","model.customer.lastname",["loc",[null,[158,197],[158,224]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 158,
              "column": 58
            },
            "end": {
              "line": 158,
              "column": 236
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["customers.edit",["get","model.customer",["loc",[null,[158,108],[158,122]]]]],["class","lh-30px fs-2 fw-bold is-highlighted"],0,null,["loc",[null,[158,80],[158,236]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 165,
              "column": 10
            },
            "end": {
              "line": 167,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","width: 27px;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element70 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element70, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",["assets/images/icons/",["get","model.gender.id",["loc",[null,[166,65],[166,80]]]],".svg"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 10
            },
            "end": {
              "line": 169,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("N/A");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 170,
              "column": 34
            },
            "end": {
              "line": 170,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Kastriert");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 170,
              "column": 66
            },
            "end": {
              "line": 170,
              "column": 84
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Geschlecht");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 173,
              "column": 10
            },
            "end": {
              "line": 175,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("em");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element69 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element69,0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element69, [2]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.ageShort",["loc",[null,[174,35],[174,53]]]],
          ["content","model.ageShortUnit",["loc",[null,[174,58],[174,80]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child29 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 175,
              "column": 10
            },
            "end": {
              "line": 177,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("N/A");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 178,
              "column": 34
            },
            "end": {
              "line": 178,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","model.birthday",["loc",[null,[178,70],[178,84]]]],"DD. MM. YYYY"],[],["loc",[null,[178,56],[178,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child31 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 178,
              "column": 101
            },
            "end": {
              "line": 178,
              "column": 114
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Alter");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child32 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 181,
              "column": 10
            },
            "end": {
              "line": 183,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("kg");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-number-german-3",[["get","model.currentWeight",["loc",[null,[182,60],[182,79]]]]],[],["loc",[null,[182,35],[182,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child33 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 183,
              "column": 10
            },
            "end": {
              "line": 185,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("N/A");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child34 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 195,
                    "column": 79
                  },
                  "end": {
                    "line": 195,
                    "column": 138
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\"");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","get-html",[["get","model.allergies",["loc",[null,[195,115],[195,130]]]]],[],["loc",[null,[195,103],[195,133]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 195,
                  "column": 16
                },
                "end": {
                  "line": 195,
                  "column": 196
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\"");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createUnsafeMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.allergies",["loc",[null,[195,85],[195,100]]]]],[],0,null,["loc",[null,[195,79],[195,145]]]],
              ["inline","get-html",[["get","model.medicalIncompatibilityNotice",["loc",[null,[195,158],[195,192]]]]],[],["loc",[null,[195,146],[195,195]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 195,
                  "column": 196
                },
                "end": {
                  "line": 195,
                  "column": 224
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dosierungen einsehen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 14
              },
              "end": {
                "line": 196,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[195,26],[195,60]]]],["get","model.allergies",["loc",[null,[195,61],[195,76]]]]],[],["loc",[null,[195,22],[195,77]]]]],[],0,1,["loc",[null,[195,16],[195,231]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 196,
                "column": 14
              },
              "end": {
                "line": 198,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                Derzeit keine Erkrankungen / Dosierungen hinterlegt.\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 189,
              "column": 6
            },
            "end": {
              "line": 202,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","card-health d-flex flex-gap-10 mt-5 justify-content-start");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","cursor fs-5");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("b");
          var el4 = dom.createTextNode("Gesundheit:");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","block");
          dom.setAttribute(el3,"style","overflow: hidden;max-height: 37px;");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element68 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element68);
          morphs[1] = dom.createMorphAt(dom.childAt(element68, [3]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openMedicalProblemsPanel",["get","model.id",["loc",[null,[191,71],[191,79]]]],"infinity"],[],["loc",[null,[191,35],[191,92]]]],
          ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[194,24],[194,58]]]],["get","model.allergies",["loc",[null,[194,59],[194,74]]]]],[],["loc",[null,[194,20],[194,75]]]]],[],0,1,["loc",[null,[194,14],[198,21]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child35 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 216,
              "column": 12
            },
            "end": {
              "line": 218,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/dead.svg");
          dom.setAttribute(el1,"title","Verstorben");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-20px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child36 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 219,
              "column": 12
            },
            "end": {
              "line": 221,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/insured.svg");
          dom.setAttribute(el1,"title","Versichert");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-20px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child37 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 223,
                "column": 14
              },
              "end": {
                "line": 225,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/sun.svg");
            dom.setAttribute(el1,"title","CAVE");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-20px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 225,
                "column": 14
              },
              "end": {
                "line": 228,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/information-danger.svg");
            dom.setAttribute(el1,"title","CAVE");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-20px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 222,
              "column": 12
            },
            "end": {
              "line": 229,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[223,24],[223,48]]]],"153"],[],["loc",[null,[223,20],[223,55]]]]],[],0,1,["loc",[null,[223,14],[228,21]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child38 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 230,
              "column": 12
            },
            "end": {
              "line": 231,
              "column": 77
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/outdoor.svg");
          dom.setAttribute(el1,"title","Freigänger:in");
          dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-20px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child39 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 232,
              "column": 12
            },
            "end": {
              "line": 234,
              "column": 93
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/rework/heart.svg");
          dom.setAttribute(el1,"title","Chronische Erkrankungen /Medikamenten-Unverträglichkeit");
          dom.setAttribute(el1,"class","tooltipstered ms-10 max-h-20px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child40 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 14
              },
              "end": {
                "line": 237,
                "column": 65
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.digitalRace.name",["loc",[null,[237,39],[237,65]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 65
              },
              "end": {
                "line": 237,
                "column": 87
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.race",["loc",[null,[237,73],[237,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 236,
              "column": 52
            },
            "end": {
              "line": 237,
              "column": 94
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n            - ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.digitalRace",["loc",[null,[237,20],[237,37]]]]],[],0,1,["loc",[null,[237,14],[237,94]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child41 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 243,
              "column": 10
            },
            "end": {
              "line": 245,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","width: 27px;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element67 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element67, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",["assets/images/icons/",["get","model.gender.id",["loc",[null,[244,65],[244,80]]]],".svg"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child42 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 246,
              "column": 34
            },
            "end": {
              "line": 246,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Kastriert");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child43 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 246,
              "column": 66
            },
            "end": {
              "line": 246,
              "column": 89
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Kein Geschlecht");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child44 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 249,
              "column": 10
            },
            "end": {
              "line": 251,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("em");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element66 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element66,0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element66, [2]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.ageShort",["loc",[null,[250,35],[250,53]]]],
          ["content","model.ageShortUnit",["loc",[null,[250,58],[250,80]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child45 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 251,
              "column": 10
            },
            "end": {
              "line": 253,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","textBelow");
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("Kein Alter");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child46 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 256,
              "column": 10
            },
            "end": {
              "line": 258,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","infoText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("kg");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","format-number-german-3",[["get","model.currentWeight",["loc",[null,[257,60],[257,79]]]]],[],["loc",[null,[257,35],[257,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child47 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 258,
              "column": 10
            },
            "end": {
              "line": 260,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","textBelow");
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("Kein Gewicht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child48 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 266,
                "column": 4
              },
              "end": {
                "line": 268,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/dead.svg");
            dom.setAttribute(el1,"title","Verstorben");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 269,
                "column": 4
              },
              "end": {
                "line": 271,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/insured.svg");
            dom.setAttribute(el1,"title","Versichert");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 273,
                  "column": 6
                },
                "end": {
                  "line": 275,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/sun.svg");
              dom.setAttribute(el1,"title","CAVE");
              dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 275,
                  "column": 6
                },
                "end": {
                  "line": 277,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/information-danger.svg");
              dom.setAttribute(el1,"title","CAVE");
              dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 272,
                "column": 4
              },
              "end": {
                "line": 278,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[273,16],[273,40]]]],"153"],[],["loc",[null,[273,12],[273,47]]]]],[],0,1,["loc",[null,[273,6],[277,13]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 279,
                "column": 4
              },
              "end": {
                "line": 279,
                "column": 136
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/outdoor.svg");
            dom.setAttribute(el1,"title","Freigänger:in");
            dom.setAttribute(el1,"class","tooltipstered ms-5 max-h-30px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 280,
                "column": 4
              },
              "end": {
                "line": 280,
                "column": 192
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/heart.svg");
            dom.setAttribute(el1,"title","Chronische Erkrankungen /Medikamenten-Unverträglichkeit");
            dom.setAttribute(el1,"class","tooltipstered ms-10 max-h-30px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 265,
              "column": 4
            },
            "end": {
              "line": 281,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,4,4,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,6,6,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.dead",["loc",[null,[266,10],[266,20]]]]],[],0,null,["loc",[null,[266,4],[268,11]]]],
          ["block","if",[["get","model.insured",["loc",[null,[269,10],[269,23]]]]],[],1,null,["loc",[null,[269,4],[271,11]]]],
          ["block","if",[["get","model.evil",["loc",[null,[272,10],[272,20]]]]],[],2,null,["loc",[null,[272,4],[278,11]]]],
          ["block","if",[["get","model.outdoor",["loc",[null,[279,10],[279,23]]]]],[],3,null,["loc",[null,[279,4],[279,143]]]],
          ["block","if",[["get","model.medicalIncompatibility",["loc",[null,[280,10],[280,38]]]]],[],4,null,["loc",[null,[280,4],[280,199]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child49 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 286,
              "column": 4
            },
            "end": {
              "line": 289,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-activities");
          var el2 = dom.createTextNode("Journal");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element65 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element65, 'class');
          morphs[1] = dom.createElementMorph(element65);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[287,53],[287,62]]]],"activities"],[],["loc",[null,[287,49],[287,76]]]],"is-active",""],[],["loc",[null,[287,28],[287,93]]]]]]],
          ["element","action",["selectTab","activities"],[],["loc",[null,[288,32],[288,67]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child50 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 290,
              "column": 4
            },
            "end": {
              "line": 293,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-history");
          var el2 = dom.createTextNode("Historie");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element64 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element64, 'class');
          morphs[1] = dom.createElementMorph(element64);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[291,53],[291,62]]]],"history"],[],["loc",[null,[291,49],[291,73]]]],"is-active",""],[],["loc",[null,[291,28],[291,90]]]]]]],
          ["element","action",["selectTab","history"],[],["loc",[null,[291,112],[292,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child51 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 300,
              "column": 4
            },
            "end": {
              "line": 303,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-correspondance");
          var el2 = dom.createTextNode("Korrespondenz");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element63 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element63, 'class');
          morphs[1] = dom.createElementMorph(element63);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[301,53],[301,62]]]],"correspondance"],[],["loc",[null,[301,49],[301,80]]]],"is-active",""],[],["loc",[null,[301,28],[301,97]]]]]]],
          ["element","action",["selectTab","correspondance"],[],["loc",[null,[302,36],[302,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child52 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 4
            },
            "end": {
              "line": 309,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-todo");
          var el2 = dom.createTextNode("TO-DO");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element62 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element62, 'class');
          morphs[1] = dom.createElementMorph(element62);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[307,53],[307,62]]]],"todo"],[],["loc",[null,[307,49],[307,70]]]],"is-active",""],[],["loc",[null,[307,28],[307,87]]]]]]],
          ["element","action",["selectTab","todo"],[],["loc",[null,[307,106],[308,28]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child53 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 310,
              "column": 4
            },
            "end": {
              "line": 313,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-appointments");
          var el2 = dom.createTextNode("Termine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element61 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element61, 'class');
          morphs[1] = dom.createElementMorph(element61);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[311,53],[311,62]]]],"appointments"],[],["loc",[null,[311,49],[311,78]]]],"is-active",""],[],["loc",[null,[311,28],[311,95]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[312,34],[312,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child54 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 330,
              "column": 20
            },
            "end": {
              "line": 332,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","patients-edit-additional-form",[],["etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[331,71],[331,87]]]]],[],[]],"edit",true,"openLogbook","openEtigaMasterdataLogbook","model",["subexpr","@mut",[["get","model",["loc",[null,[331,145],[331,150]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[331,160],[331,168]]]]],[],[]]],["loc",[null,[331,22],[331,170]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child55 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 342,
              "column": 16
            },
            "end": {
              "line": 344,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Gewichtsentwicklung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child56 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 451,
              "column": 14
            },
            "end": {
              "line": 454,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[452,40],[452,50]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[452,16],[452,106]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[453,41],[453,52]]]]],[],[]]],["loc",[null,[453,16],[453,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child57 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 463,
              "column": 20
            },
            "end": {
              "line": 463,
              "column": 240
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element60 = dom.childAt(fragment, [0]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element60, 'class');
          morphs[1] = dom.createAttrMorph(element60, 'style');
          morphs[2] = dom.createAttrMorph(element60, 'data-id');
          morphs[3] = dom.createElementMorph(element60);
          morphs[4] = dom.createMorphAt(element60,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[463,96],[463,108]]]],"selected",""],[],["loc",[null,[463,75],[463,124]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[463,153],[463,162]]]]]]],
          ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[463,177],[463,183]]]]]]],
          ["element","action",["selectSearchTag",["get","tag",["loc",[null,[463,214],[463,217]]]]],[],["loc",[null,[463,187],[463,219]]]],
          ["content","tag.name",["loc",[null,[463,220],[463,232]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child58 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 518,
                  "column": 22
                },
                "end": {
                  "line": 520,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        Alle ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" um ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Uhr, ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Wiederholungen\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.dueTimeUnitInterval",["loc",[null,[519,29],[519,57]]]],
              ["content","item.dueTimeUnit.name",["loc",[null,[519,58],[519,83]]]],
              ["content","item.time",["loc",[null,[519,87],[519,100]]]],
              ["content","item.dueTimeUnitIterations",["loc",[null,[519,106],[519,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 520,
                  "column": 22
                },
                "end": {
                  "line": 522,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.dueDate",["loc",[null,[521,38],[521,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[521,24],[521,73]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 529,
                  "column": 28
                },
                "end": {
                  "line": 529,
                  "column": 102
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge success");
              var el2 = dom.createTextNode("erledigt");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 529,
                  "column": 102
                },
                "end": {
                  "line": 529,
                  "column": 149
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge");
              var el2 = dom.createTextNode("offen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 514,
                "column": 20
              },
              "end": {
                "line": 541,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-5/24@desk");
            dom.setAttribute(el2,"data-label","Fälligkeit");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
            dom.setAttribute(el2,"data-label","Titel");
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
            dom.setAttribute(el2,"data-label","Status");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell buttons-2 table__cell--action l-6/24 l-5/24@desk buttons-2");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n                            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            var el5 = dom.createTextNode("Bearbeiten");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element56 = dom.childAt(fragment, [1]);
            var element57 = dom.childAt(element56, [7]);
            var element58 = dom.childAt(element57, [1]);
            var element59 = dom.childAt(element57, [3]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element56, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element56, [3, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element56, [5, 1]),0,0);
            morphs[3] = dom.createMorphAt(element58,1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element58, [3]),0,0);
            morphs[5] = dom.createElementMorph(element59);
            morphs[6] = dom.createMorphAt(element59,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[518,32],[518,43]]]],"repeat"],[],["loc",[null,[518,28],[518,53]]]]],[],0,1,["loc",[null,[518,22],[522,29]]]],
            ["content","item.name",["loc",[null,[526,32],[526,45]]]],
            ["block","if",[["get","item.checkedDate",["loc",[null,[529,34],[529,50]]]]],[],2,3,["loc",[null,[529,28],[529,156]]]],
            ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[533,106],[533,110]]]]],[],[]],"confirmed","deleteTodo","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[533,28],[533,186]]]],
            ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[534,59],[534,63]]]]],[],[]],"confirmed","deleteTodo","textToShow","Löschen","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[534,34],[534,160]]]],
            ["element","action",["editTodo",["get","item",["loc",[null,[536,49],[536,53]]]]],[],["loc",[null,[536,29],[536,55]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[537,28],[537,94]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 505,
              "column": 16
            },
            "end": {
              "line": 544,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-5/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Fällig");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-10/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Titel");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Status");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-5/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","todos",["loc",[null,[514,28],[514,33]]]]],[],0,null,["loc",[null,[514,20],[541,29]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child59 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 554,
              "column": 14
            },
            "end": {
              "line": 571,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","customer-etiga",[],["patient",["subexpr","@mut",[["get","model",["loc",[null,[555,39],[555,44]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[556,48],[556,64]]]]],[],[]],"activateEtiga","activateEtiga","uploadDeclaration","uploadDeclaration","removeShare","removeEtigaShare","sendEtigaSave","sendEtigaSave","downloadEtigaQr","downloadEtigaQr","hasEtigaPermissions",["subexpr","has-permission",["etiga"],[],["loc",[null,[562,51],[562,75]]]],"addEtigaShare","addEtigaShare","disconnectEtiga","disconnectEtiga","selectDocs","selectDocs","submitCredentials","sendCredentials","savePermission","saveEtigaPermission","editEtigaPermissions","editEtigaPermissions","classNames","etigaPermissionPanel patients","actionReceiver",["subexpr","@mut",[["get","etigaPanel",["loc",[null,[570,46],[570,56]]]]],[],[]]],["loc",[null,[555,14],[570,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child60 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 571,
              "column": 14
            },
            "end": {
              "line": 573,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child61 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 582,
              "column": 6
            },
            "end": {
              "line": 586,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element55 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element55);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[583,13],[583,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child62 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 587,
              "column": 6
            },
            "end": {
              "line": 591,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element54);
          return morphs;
        },
        statements: [
          ["element","action",["sendEtigaSave"],[],["loc",[null,[588,11],[588,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child63 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 592,
              "column": 6
            },
            "end": {
              "line": 599,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element52 = dom.childAt(fragment, [1]);
          var element53 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element52);
          morphs[1] = dom.createMorphAt(element52,1,1);
          morphs[2] = dom.createElementMorph(element53);
          morphs[3] = dom.createMorphAt(element53,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadWeightCsv",["get","model.id",["loc",[null,[593,40],[593,48]]]]],[],["loc",[null,[593,11],[593,50]]]],
          ["inline","button-download-csv",[],["color","#ffffff","size","40","content","Gewichtsverlauf als CSV herunterladen"],["loc",[null,[594,10],[594,107]]]],
          ["element","action",["openWeight",null,["get","model.id",["loc",[null,[596,38],[596,46]]]]],[],["loc",[null,[596,11],[596,48]]]],
          ["inline","button-add-entry",[],["content","Neues Gewicht eintragen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[597,10],[597,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child64 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 600,
              "column": 6
            },
            "end": {
              "line": 604,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element51 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element51);
          morphs[1] = dom.createMorphAt(element51,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[601,13],[601,40]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[602,12],[602,107]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child65 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 617,
                    "column": 98
                  },
                  "end": {
                    "line": 617,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["inline","get-html",[["get","model.allergies",["loc",[null,[617,133],[617,148]]]]],[],["loc",[null,[617,121],[617,151]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 617,
                  "column": 35
                },
                "end": {
                  "line": 617,
                  "column": 213
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createUnsafeMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.allergies",["loc",[null,[617,104],[617,119]]]]],[],0,null,["loc",[null,[617,98],[617,163]]]],
              ["inline","get-html",[["get","model.medicalIncompatibilityNotice",["loc",[null,[617,176],[617,210]]]]],[],["loc",[null,[617,164],[617,213]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 617,
                  "column": 213
                },
                "end": {
                  "line": 617,
                  "column": 241
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dosierungen einsehen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 616,
                "column": 12
              },
              "end": {
                "line": 618,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[617,45],[617,79]]]],["get","model.allergies",["loc",[null,[617,80],[617,95]]]]],[],["loc",[null,[617,41],[617,96]]]]],[],0,1,["loc",[null,[617,35],[617,248]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 618,
                "column": 12
              },
              "end": {
                "line": 620,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createTextNode("Derzeit keine Erkrankungen / Dosierungen hinterlegt.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 647,
                  "column": 16
                },
                "end": {
                  "line": 650,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Farbe");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              return morphs;
            },
            statements: [
              ["content","model.color",["loc",[null,[649,21],[649,36]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 651,
                  "column": 16
                },
                "end": {
                  "line": 654,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Geburtstag");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","model.birthday",["loc",[null,[653,35],[653,49]]]],"DD. MM. YYYY"],[],["loc",[null,[653,21],[653,66]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 655,
                  "column": 16
                },
                "end": {
                  "line": 658,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Todestag");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element9);
              morphs[1] = dom.createMorphAt(element9,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["setDeathDate","dead"],[],["loc",[null,[657,36],[657,68]]]],
              ["inline","time-format",[["get","model.deathday",["loc",[null,[657,83],[657,97]]]],"DD. MM. YYYY"],[],["loc",[null,[657,69],[657,114]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 659,
                  "column": 16
                },
                "end": {
                  "line": 662,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Archiviert");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element8);
              morphs[1] = dom.createMorphAt(element8,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["setDeathDate","giveaway"],[],["loc",[null,[661,36],[661,72]]]],
              ["inline","time-format",[["get","model.soldDate",["loc",[null,[661,87],[661,101]]]],"DD. MM. YYYY"],[],["loc",[null,[661,73],[661,118]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 667,
                  "column": 14
                },
                "end": {
                  "line": 670,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Chip-Nr");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","cursor flex-center");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"class","ml5");
              dom.setAttribute(el2,"src","assets/images/icons/external-link.svg");
              dom.setAttribute(el2,"style","max-width: 18px;");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openChipPanel",["get","model",["loc",[null,[669,46],[669,51]]]]],[],["loc",[null,[669,21],[669,53]]]],
              ["content","model.chipId",["loc",[null,[669,81],[669,97]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 671,
                  "column": 14
                },
                "end": {
                  "line": 674,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Heimtier-Nr");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              return morphs;
            },
            statements: [
              ["content","model.homeanimalNumber",["loc",[null,[673,19],[673,45]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 675,
                  "column": 14
                },
                "end": {
                  "line": 680,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Versicherung");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("Versicherungs-Nr");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]),0,0);
              return morphs;
            },
            statements: [
              ["content","model.insurer.name",["loc",[null,[677,28],[677,50]]]],
              ["content","model.insuranceNumber",["loc",[null,[679,28],[679,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 635,
                "column": 6
              },
              "end": {
                "line": 686,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sidebar-card");
            dom.setAttribute(el1,"id","masterdata");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","category-tab-bar");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"href","#tab-patientmasterdata");
            var el4 = dom.createTextNode("Profil");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2,"class","tabs__body mt-20 pl0 pr0 mb0");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            dom.setAttribute(el3,"data-for","#tab-patientmasterdata");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","dataList");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("label");
            var el7 = dom.createTextNode("Nr");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("p");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col l-12/24 mt10");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","dataList");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [1, 1]);
            var element12 = dom.childAt(element10, [3, 1]);
            var element13 = dom.childAt(element12, [1, 1]);
            var element14 = dom.childAt(element12, [3, 1]);
            var morphs = new Array(11);
            morphs[0] = dom.createAttrMorph(element11, 'class');
            morphs[1] = dom.createElementMorph(element11);
            morphs[2] = dom.createAttrMorph(element12, 'class');
            morphs[3] = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
            morphs[4] = dom.createMorphAt(element13,5,5);
            morphs[5] = dom.createMorphAt(element13,6,6);
            morphs[6] = dom.createMorphAt(element13,7,7);
            morphs[7] = dom.createMorphAt(element13,8,8);
            morphs[8] = dom.createMorphAt(element14,1,1);
            morphs[9] = dom.createMorphAt(element14,2,2);
            morphs[10] = dom.createMorphAt(element14,3,3);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[638,76],[638,86]]]],"masterdata"],[],["loc",[null,[638,72],[638,100]]]],"masterdata","deselected"],[],["loc",[null,[638,51],[638,128]]]]]]],
            ["element","action",["selectPatientTab","masterdata"],[],["loc",[null,[638,160],[638,202]]]],
            ["attribute","class",["concat",["pl0 pr0 pb0 tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[642,80],[642,90]]]],"masterdata"],[],["loc",[null,[642,76],[642,104]]]],"is-active",""],[],["loc",[null,[642,55],[642,121]]]]]]],
            ["content","model.id",["loc",[null,[646,19],[646,31]]]],
            ["block","if",[["get","model.color",["loc",[null,[647,22],[647,33]]]]],[],0,null,["loc",[null,[647,16],[650,23]]]],
            ["block","if",[["get","model.birthday",["loc",[null,[651,22],[651,36]]]]],[],1,null,["loc",[null,[651,16],[654,23]]]],
            ["block","if",[["get","model.dead",["loc",[null,[655,22],[655,32]]]]],[],2,null,["loc",[null,[655,16],[658,23]]]],
            ["block","if",[["get","model.sold",["loc",[null,[659,22],[659,32]]]]],[],3,null,["loc",[null,[659,16],[662,23]]]],
            ["block","if",[["get","model.chipId",["loc",[null,[667,20],[667,32]]]]],[],4,null,["loc",[null,[667,14],[670,21]]]],
            ["block","if",[["get","model.homeanimalNumber",["loc",[null,[671,20],[671,42]]]]],[],5,null,["loc",[null,[671,14],[674,21]]]],
            ["block","if",[["get","model.insurer",["loc",[null,[675,20],[675,33]]]]],[],6,null,["loc",[null,[675,14],[680,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 691,
                "column": 10
              },
              "end": {
                "line": 696,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"style","padding-top: 5px;padding-left: 4px;");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","gender-icon",[],["maxWidth","max-w-70px","classNames","owner-gender","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.customer.gender.id",["loc",[null,[694,35],[694,59]]]]],[],[]],"content",""],["loc",[null,[693,14],[694,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 696,
                "column": 10
              },
              "end": {
                "line": 700,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/gender/unknown.svg");
            dom.setAttribute(el2,"class","inline-block vertical-middle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 704,
                "column": 27
              },
              "end": {
                "line": 705,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          , ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["content","model.customer.street",["loc",[null,[704,56],[704,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 712,
                "column": 10
              },
              "end": {
                "line": 714,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","payment-moral-icon",[],["content",["subexpr","get-payment-moral-name",[["get","model.customer",["loc",[null,[713,63],[713,77]]]],true],[],["loc",[null,[713,39],[713,83]]]],"moral",["subexpr","get-payment-moral",[["get","model.customer",["loc",[null,[713,109],[713,123]]]],true],[],["loc",[null,[713,90],[713,129]]]]],["loc",[null,[713,10],[713,131]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 717,
                "column": 8
              },
              "end": {
                "line": 721,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","appLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 721,
                  "column": 8
                },
                "end": {
                  "line": 725,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","appLabel");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createTextNode("\n             ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe-dark.png");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 721,
                "column": 8
              },
              "end": {
                "line": 725,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.customer.etigaCodeRequested",["loc",[null,[721,18],[721,51]]]]],[],0,null,["loc",[null,[721,8],[725,8]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 757,
                    "column": 139
                  },
                  "end": {
                    "line": 757,
                    "column": 180
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[757,162],[757,179]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 756,
                  "column": 14
                },
                "end": {
                  "line": 758,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/phone.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'href');
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["tel:",["get","entry.value",["loc",[null,[757,109],[757,120]]]]]]],
              ["content","entry.value",["loc",[null,[757,124],[757,139]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[757,145],[757,158]]]]],[],0,null,["loc",[null,[757,139],[757,187]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 753,
                "column": 10
              },
              "end": {
                "line": 760,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Telefon");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.phones",["loc",[null,[756,22],[756,43]]]]],[],0,null,["loc",[null,[756,14],[758,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 765,
                    "column": 143
                  },
                  "end": {
                    "line": 765,
                    "column": 184
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[765,166],[765,183]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 764,
                  "column": 14
                },
                "end": {
                  "line": 766,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/email.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("   ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'href');
              morphs[1] = dom.createMorphAt(element5,0,0);
              morphs[2] = dom.createMorphAt(element5,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["mailto:",["get","entry.value",["loc",[null,[765,113],[765,124]]]]]]],
              ["content","entry.value",["loc",[null,[765,128],[765,143]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[765,149],[765,162]]]]],[],0,null,["loc",[null,[765,143],[765,191]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 761,
                "column": 10
              },
              "end": {
                "line": 768,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Email");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.emails",["loc",[null,[764,22],[764,43]]]]],[],0,null,["loc",[null,[764,14],[766,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 769,
                "column": 10
              },
              "end": {
                "line": 772,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Guthaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[771,39],[771,49]]]]],[],[]]],["loc",[null,[771,15],[771,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 788,
                    "column": 46
                  },
                  "end": {
                    "line": 788,
                    "column": 76
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("ganztägig");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 788,
                      "column": 89
                    },
                    "end": {
                      "line": 788,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" - ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.dateFrom",["loc",[null,[788,110],[788,127]]]],
                  ["content","item.dateTo",["loc",[null,[788,130],[788,145]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 788,
                    "column": 76
                  },
                  "end": {
                    "line": 788,
                    "column": 152
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.dateFrom",["loc",[null,[788,95],[788,108]]]]],[],0,null,["loc",[null,[788,89],[788,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 788,
                    "column": 159
                  },
                  "end": {
                    "line": 788,
                    "column": 245
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("bis ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.dateEnd",["loc",[null,[788,229],[788,245]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 785,
                  "column": 20
                },
                "end": {
                  "line": 794,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-8/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-16/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","Titel/Kategori");
              var el3 = dom.createTextNode("\n                          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","cursor is-highlighted");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1, 1]);
              var element3 = dom.childAt(element1, [3, 1]);
              var element4 = dom.childAt(element3, [0]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element2,0,0);
              morphs[1] = dom.createMorphAt(element2,2,2);
              morphs[2] = dom.createMorphAt(element2,3,3);
              morphs[3] = dom.createElementMorph(element4);
              morphs[4] = dom.createMorphAt(element4,0,0);
              morphs[5] = dom.createMorphAt(element3,2,2);
              return morphs;
            },
            statements: [
              ["content","item.date",["loc",[null,[788,32],[788,45]]]],
              ["block","if",[["get","item.wholeDay",["loc",[null,[788,52],[788,65]]]]],[],0,1,["loc",[null,[788,46],[788,159]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.dateEnd",["loc",[null,[788,179],[788,191]]]],["get","item.date",["loc",[null,[788,192],[788,201]]]]],[],["loc",[null,[788,175],[788,202]]]]],[],["loc",[null,[788,170],[788,203]]]],["get","item.multiDay",["loc",[null,[788,204],[788,217]]]]],[],["loc",[null,[788,165],[788,218]]]]],[],2,null,["loc",[null,[788,159],[788,252]]]],
              ["element","action",["showAppointment",["get","item",["loc",[null,[791,92],[791,96]]]]],[],["loc",[null,[791,65],[791,98]]]],
              ["content","item.title",["loc",[null,[791,99],[791,113]]]],
              ["content","item.category.name",["loc",[null,[791,122],[791,144]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 777,
                "column": 12
              },
              "end": {
                "line": 798,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper-rework small");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table grid");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Datum");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Titel/Kategorie");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerAppointments",["loc",[null,[785,28],[785,48]]]]],[],0,null,["loc",[null,[785,20],[794,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 798,
                "column": 12
              },
              "end": {
                "line": 802,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ml10");
            var el2 = dom.createTextNode("\n                Derzeit keine Termine hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 821,
                      "column": 257
                    },
                    "end": {
                      "line": 821,
                      "column": 306
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.name",["loc",[null,[821,293],[821,306]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 818,
                    "column": 22
                  },
                  "end": {
                    "line": 824,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-24/24");
                dom.setAttribute(el2,"data-label","Name");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","is-highlighted d-flex align-items-center");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0,0,0);
                morphs[1] = dom.createMorphAt(element0,2,2);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["classNames","me-15","evil",["subexpr","@mut",[["get","item.evil",["loc",[null,[821,121],[821,130]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.category.id",["loc",[null,[821,141],[821,157]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","item.etigaActive",["loc",[null,[821,171],[821,187]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.digitalRace.icon",["loc",[null,[821,196],[821,217]]]]],[],[]],"size","22","content","","color","#4A4A4A"],["loc",[null,[821,83],[821,256]]]],
                ["block","link-to",["patients.edit",["get","item.id",["loc",[null,[821,284],[821,291]]]]],[],0,null,["loc",[null,[821,257],[821,318]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 817,
                  "column": 20
                },
                "end": {
                  "line": 825,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.dead",["loc",[null,[818,38],[818,47]]]]],[],["loc",[null,[818,33],[818,48]]]],["subexpr","not",[["get","item.sold",["loc",[null,[818,54],[818,63]]]]],[],["loc",[null,[818,49],[818,64]]]]],[],["loc",[null,[818,28],[818,65]]]]],[],0,null,["loc",[null,[818,22],[824,29]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 810,
                "column": 12
              },
              "end": {
                "line": 829,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper-rework small mb-0");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table grid");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-24/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Name");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerPatients",["loc",[null,[817,28],[817,44]]]]],[],0,null,["loc",[null,[817,20],[825,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 829,
                "column": 12
              },
              "end": {
                "line": 833,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","mt20 ");
            var el2 = dom.createTextNode("\n                Keine anderen Tiere hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 607,
              "column": 3
            },
            "end": {
              "line": 840,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet patientSidebar");
          dom.setAttribute(el1,"id","patientEditSidebar");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","health");
          dom.setAttribute(el3,"style","margin-top: 25px;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-health");
          var el6 = dom.createTextNode("Gesundheit");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-health");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-activity");
          var el6 = dom.createTextNode("\n            In Entwicklung\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-memos");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Notiz erstellen");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerSummary");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4,"class","is-highlighted cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","d-flex align-items-center");
          dom.setAttribute(el4,"id","saldoLabelContainer");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"id","saldoLabel");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createElement("b");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode(" Saldo");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","customer-buttons d-flex justify-content-around");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"class","button-rework auto-height info ms-0 d-inline-block");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("img");
          dom.setAttribute(el6,"src","assets/images/icons/rework/graph-light-dark.svg");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Umsatz");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"class","button-rework auto-height info ms-0 d-inline-block");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("img");
          dom.setAttribute(el6,"src","assets/images/icons/rework/contact-light-dark.svg");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Kontakt");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"class","button-rework auto-height info ms-0 d-inline-block");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("img");
          dom.setAttribute(el6,"src","assets/images/icons/rework/percentage.svg");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Rabatt");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerdata");
          dom.setAttribute(el3,"style","margin-bottom: 120px");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customermasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerapps");
          var el6 = dom.createTextNode("Termine");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerpatients");
          var el6 = dom.createTextNode("Tiere");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerdocs");
          var el6 = dom.createTextNode("Docs");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body mt-20 pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customermasterdata");
          var el6 = dom.createTextNode("\n\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","dataList ");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#customerapps");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Termine");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerdocs");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Dokumente");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerpatients");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/rework/arrow-right-brand.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Tiere");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [0]);
          var element16 = dom.childAt(element15, [1]);
          var element17 = dom.childAt(element15, [3]);
          var element18 = dom.childAt(element17, [1]);
          var element19 = dom.childAt(element18, [1, 1]);
          var element20 = dom.childAt(element18, [3]);
          var element21 = dom.childAt(element20, [1]);
          var element22 = dom.childAt(element20, [3]);
          var element23 = dom.childAt(element20, [5]);
          var element24 = dom.childAt(element23, [3]);
          var element25 = dom.childAt(element17, [5]);
          var element26 = dom.childAt(element25, [1]);
          var element27 = dom.childAt(element25, [3]);
          var element28 = dom.childAt(element25, [5]);
          var element29 = dom.childAt(element25, [7]);
          var element30 = dom.childAt(element29, [1, 1]);
          var element31 = dom.childAt(element25, [11]);
          var element32 = dom.childAt(element31, [1]);
          var element33 = dom.childAt(element31, [3]);
          var element34 = dom.childAt(element31, [5]);
          var element35 = dom.childAt(element17, [7]);
          var element36 = dom.childAt(element35, [1]);
          var element37 = dom.childAt(element36, [1]);
          var element38 = dom.childAt(element36, [3]);
          var element39 = dom.childAt(element36, [5]);
          var element40 = dom.childAt(element36, [7]);
          var element41 = dom.childAt(element35, [3]);
          var element42 = dom.childAt(element41, [1]);
          var element43 = dom.childAt(element42, [1]);
          var element44 = dom.childAt(element42, [3]);
          var element45 = dom.childAt(element41, [3]);
          var element46 = dom.childAt(element45, [3]);
          var element47 = dom.childAt(element41, [5]);
          var element48 = dom.childAt(element47, [3]);
          var element49 = dom.childAt(element41, [7]);
          var element50 = dom.childAt(element49, [3]);
          var morphs = new Array(48);
          morphs[0] = dom.createElementMorph(element16);
          morphs[1] = dom.createAttrMorph(element19, 'class');
          morphs[2] = dom.createElementMorph(element19);
          morphs[3] = dom.createAttrMorph(element21, 'class');
          morphs[4] = dom.createElementMorph(element21);
          morphs[5] = dom.createMorphAt(element21,1,1);
          morphs[6] = dom.createAttrMorph(element22, 'class');
          morphs[7] = dom.createAttrMorph(element23, 'class');
          morphs[8] = dom.createElementMorph(element24);
          morphs[9] = dom.createMorphAt(element17,3,3);
          morphs[10] = dom.createAttrMorph(element26, 'class');
          morphs[11] = dom.createMorphAt(element26,1,1);
          morphs[12] = dom.createElementMorph(element27);
          morphs[13] = dom.createMorphAt(element27,0,0);
          morphs[14] = dom.createMorphAt(element27,2,2);
          morphs[15] = dom.createMorphAt(element27,4,4);
          morphs[16] = dom.createMorphAt(element28,0,0);
          morphs[17] = dom.createMorphAt(element28,1,1);
          morphs[18] = dom.createMorphAt(element28,3,3);
          morphs[19] = dom.createAttrMorph(element30, 'class');
          morphs[20] = dom.createMorphAt(dom.childAt(element30, [1, 0]),0,0);
          morphs[21] = dom.createMorphAt(element29,3,3);
          morphs[22] = dom.createMorphAt(element25,9,9);
          morphs[23] = dom.createElementMorph(element32);
          morphs[24] = dom.createElementMorph(element33);
          morphs[25] = dom.createElementMorph(element34);
          morphs[26] = dom.createAttrMorph(element37, 'class');
          morphs[27] = dom.createElementMorph(element37);
          morphs[28] = dom.createAttrMorph(element38, 'class');
          morphs[29] = dom.createElementMorph(element38);
          morphs[30] = dom.createAttrMorph(element39, 'class');
          morphs[31] = dom.createElementMorph(element39);
          morphs[32] = dom.createAttrMorph(element40, 'class');
          morphs[33] = dom.createElementMorph(element40);
          morphs[34] = dom.createAttrMorph(element42, 'class');
          morphs[35] = dom.createMorphAt(element43,1,1);
          morphs[36] = dom.createMorphAt(element43,2,2);
          morphs[37] = dom.createMorphAt(element43,3,3);
          morphs[38] = dom.createElementMorph(element44);
          morphs[39] = dom.createAttrMorph(element45, 'class');
          morphs[40] = dom.createMorphAt(element45,1,1);
          morphs[41] = dom.createElementMorph(element46);
          morphs[42] = dom.createAttrMorph(element47, 'class');
          morphs[43] = dom.createMorphAt(element47,1,1);
          morphs[44] = dom.createElementMorph(element48);
          morphs[45] = dom.createAttrMorph(element49, 'class');
          morphs[46] = dom.createMorphAt(element49,1,1);
          morphs[47] = dom.createElementMorph(element50);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[608,7],[608,38]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[612,65],[612,74]]]],"health"],[],["loc",[null,[612,61],[612,84]]]],"health","deselected"],[],["loc",[null,[612,40],[612,108]]]]]]],
          ["element","action",["selectHealthTab","health"],[],["loc",[null,[612,129],[612,166]]]],
          ["attribute","class",["concat",["pl0 pb0 pt10 pr0 tabs__content content cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[615,92],[615,101]]]],"health"],[],["loc",[null,[615,88],[615,111]]]],"is-active",""],[],["loc",[null,[615,67],[615,128]]]]]]],
          ["element","action",["openMedicalProblemsPanel",["get","model.id",["loc",[null,[615,189],[615,197]]]],"infinity"],[],["loc",[null,[615,153],[615,210]]]],
          ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[616,22],[616,56]]]],["get","model.allergies",["loc",[null,[616,57],[616,72]]]]],[],["loc",[null,[616,18],[616,73]]]]],[],0,1,["loc",[null,[616,12],[620,19]]]],
          ["attribute","class",["concat",["tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[625,68],[625,77]]]],"activity"],[],["loc",[null,[625,64],[625,89]]]],"is-active",""],[],["loc",[null,[625,43],[625,106]]]]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[628,80],[628,89]]]],"memos"],[],["loc",[null,[628,76],[628,98]]]],"is-active",""],[],["loc",[null,[628,55],[628,115]]]]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[630,40],[630,63]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[635,12],[635,20]]]]],[],2,null,["loc",[null,[635,6],[686,13]]]],
          ["attribute","class",["concat",["avatar ",["get","model.customer.gender.id",["loc",[null,[690,29],[690,53]]]]]]],
          ["block","if",[["subexpr","and",[["get","model.customer.gender",["loc",[null,[691,21],[691,42]]]],["subexpr","not",[["subexpr","eq",[["get","model.customer.gender.id",["loc",[null,[691,52],[691,76]]]],"male/female/company"],[],["loc",[null,[691,48],[691,99]]]]],[],["loc",[null,[691,43],[691,100]]]]],[],["loc",[null,[691,16],[691,101]]]]],[],3,4,["loc",[null,[691,10],[700,17]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[703,67],[703,84]]]],""],[],["loc",[null,[703,43],[703,89]]]],
          ["content","model.customer.title.name",["loc",[null,[703,90],[703,119]]]],
          ["content","model.customer.firstname",["loc",[null,[703,120],[703,148]]]],
          ["content","model.customer.lastname",["loc",[null,[703,149],[703,176]]]],
          ["block","if",[["get","model.customer.street",["loc",[null,[704,33],[704,54]]]]],[],5,null,["loc",[null,[704,27],[705,19]]]],
          ["content","model.customer.postalCode",["loc",[null,[705,19],[705,48]]]],
          ["content","model.customer.town",["loc",[null,[705,49],[705,72]]]],
          ["attribute","class",["concat",["insured ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[708,57],[708,67]]]],0],[],["loc",[null,[708,53],[708,70]]]],"is-negative",""],[],["loc",[null,[708,32],[708,89]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[709,65],[709,75]]]]],[],[]],"classNames","inline-block"],["loc",[null,[709,41],[709,103]]]],
          ["block","if",[["get","model.customer",["loc",[null,[712,16],[712,30]]]]],[],6,null,["loc",[null,[712,10],[714,17]]]],
          ["block","if",[["get","model.customer.etigaAppConnected",["loc",[null,[717,14],[717,46]]]]],[],7,8,["loc",[null,[717,8],[725,15]]]],
          ["element","action",["openRevenue",["get","model.customer.id",["loc",[null,[728,100],[728,117]]]]],[],["loc",[null,[728,77],[728,119]]]],
          ["element","action",["openContactInfo",["get","model.customer",["loc",[null,[731,104],[731,118]]]]],[],["loc",[null,[731,77],[731,120]]]],
          ["element","action",["openCustomerReductions",["get","model.customer.id",["loc",[null,[734,111],[734,128]]]]],[],["loc",[null,[734,77],[734,130]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[743,76],[743,87]]]],"masterdata"],[],["loc",[null,[743,72],[743,101]]]],"customerdata","deselected"],[],["loc",[null,[743,51],[743,131]]]]]]],
          ["element","action",["selectCustomerTab","masterdata"],[],["loc",[null,[743,164],[743,207]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[744,65],[744,76]]]],"appointments"],[],["loc",[null,[744,61],[744,92]]]],"customerdata","deselected"],[],["loc",[null,[744,40],[744,122]]]]]]],
          ["element","action",["selectCustomerTab","appointments"],[],["loc",[null,[744,149],[744,194]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[745,65],[745,76]]]],"patients"],[],["loc",[null,[745,61],[745,88]]]],"customerdata","deselected"],[],["loc",[null,[745,40],[745,118]]]]]]],
          ["element","action",["selectCustomerTab","patients"],[],["loc",[null,[745,149],[745,190]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[746,65],[746,76]]]],"documents"],[],["loc",[null,[746,61],[746,89]]]],"customerdata","deselected"],[],["loc",[null,[746,40],[746,119]]]]]]],
          ["element","action",["selectCustomerTab","documents"],[],["loc",[null,[746,147],[746,189]]]],
          ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[750,80],[750,91]]]],"masterdata"],[],["loc",[null,[750,76],[750,105]]]],"is-active",""],[],["loc",[null,[750,55],[750,122]]]]]]],
          ["block","if",[["get","model.customer.phones",["loc",[null,[753,16],[753,37]]]]],[],9,null,["loc",[null,[753,10],[760,17]]]],
          ["block","if",[["get","model.customer.emails",["loc",[null,[761,16],[761,37]]]]],[],10,null,["loc",[null,[761,10],[768,17]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[769,20],[769,30]]]],0],[],["loc",[null,[769,16],[769,33]]]]],[],11,null,["loc",[null,[769,10],[772,17]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[774,60],[774,77]]]],""],[],["loc",[null,[774,36],[774,82]]]],
          ["attribute","class",["concat",["tabs__content pl0 pt0 pb0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[776,76],[776,87]]]],"appointments"],[],["loc",[null,[776,72],[776,103]]]],"is-active",""],[],["loc",[null,[776,51],[776,120]]]]]]],
          ["block","if",[["get","customerAppointments",["loc",[null,[777,18],[777,38]]]]],[],12,13,["loc",[null,[777,12],[802,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[803,64],[803,81]]]],"appointments"],[],["loc",[null,[803,40],[803,98]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[805,76],[805,87]]]],"documents"],[],["loc",[null,[805,72],[805,100]]]],"is-active",""],[],["loc",[null,[805,51],[805,117]]]]]]],
          ["inline","documents-panel",[],["prefix","cdocs","loadPanels",false,"smallView",true,"showSearch",false,"useHover",false,"showBreadcrumbs",false,"actionReceiver",["subexpr","@mut",[["get","customerDocumentsPanel",["loc",[null,[806,146],[806,168]]]]],[],[]]],["loc",[null,[806,12],[806,170]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[807,64],[807,81]]]],"documents"],[],["loc",[null,[807,40],[807,95]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[809,76],[809,87]]]],"patients"],[],["loc",[null,[809,72],[809,99]]]],"is-active",""],[],["loc",[null,[809,51],[809,116]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","loading",["loc",[null,[810,28],[810,35]]]]],[],["loc",[null,[810,23],[810,36]]]],["get","customerPatients",["loc",[null,[810,37],[810,53]]]]],[],["loc",[null,[810,18],[810,54]]]]],[],14,15,["loc",[null,[810,12],[833,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[834,64],[834,81]]]],"patients"],[],["loc",[null,[834,40],[834,94]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
      };
    }());
    var child66 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 840,
              "column": 0
            },
            "end": {
              "line": 844,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[842,61],[842,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1012,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/patients/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Patientenkartei");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/digital-assistant-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Digitaler Assistent");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/admission.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Check-In");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown end");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","button-rework context icon-only dropdown d-flex align-items-center");
        dom.setAttribute(el5,"data-toggle","dropdown");
        dom.setAttribute(el5,"aria-haspopup","true");
        dom.setAttribute(el5,"aria-expanded","false");
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/more-dark.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dropdown-menu larger");
        dom.setAttribute(el5,"aria-labelledby","dropdownMenuButton");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","d-flex align-items-center dropdown-item");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/rework/barcode-dark.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Etiketten herunterladen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","d-flex align-items-center dropdown-item");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/rework/merge-dark.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Tier zusammenführen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","navBackgroundWrapper");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex flex-row align-items-top");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","eltiga-style");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        dom.setAttribute(el4,"id","nameContainer");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"class","mb-0");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","patient-infos");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","d-flex align-items-center subName fs-3 mb0");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","d-flex flex-column ms-5");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","fw-bold");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mt-20 mb-0 d-flex align-items-center");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-column flex-gap-10 mb-10 p-absolute u-hide@tablet");
        dom.setAttribute(el3,"style","right:0;top:0");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex mt-20 me-20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge cursor large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createTextNode("Gewicht");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","floating-header patient-mini-card card-rework");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex align-items-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","d-flex align-items-center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","d-flex flex-column");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","fs-2 fw-bold d-flex align-items-center");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","fs-5");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml-auto d-flex u-hide@phone");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","badge cursor large");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-master-data");
        var el4 = dom.createTextNode("Stammdaten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-future");
        var el4 = dom.createTextNode("Zukunft");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-lab");
        var el4 = dom.createTextNode("Labor & Röntgen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-documents");
        var el4 = dom.createTextNode("Dokumente");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-tasks");
        var el4 = dom.createTextNode("Aufgaben");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-vacc");
        var el4 = dom.createTextNode("Impfpass");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-memos");
        var el4 = dom.createTextNode("Notizen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-etiga");
        var el4 = dom.createTextNode("eltiga");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","patientEditPage");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","patientEdit");
        dom.setAttribute(el4,"class","mb0 admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-memos");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-vacc");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-weight");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Gewicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-activities");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-history");
        var el5 = dom.createTextNode("\n\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card min-h-50px");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter standalone pb-0 col l-12/24");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","");
        var el9 = dom.createTextNode("Filter");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Nur eingeblendete");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter col pb-0 l-11/24");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","");
        var el9 = dom.createTextNode("Tags");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Ernährung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Leistungen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Produkte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Labor");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-lab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-medias");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-documents");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card-content min-h-60px mb-20");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex mt-10 flex-column-mobile");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ps-30 ml-auto pt-10 quick-filter");
        dom.setAttribute(el7,"id","tagFilters");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-correspondance");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-tasks");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-todo");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper-rework");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter-wrapper mt-30 col l-12/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework mb-0");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Status");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","pagination-wrapper is-fixed");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-appointments");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-etiga");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    setTimeout(function() {\n      $('.floating-header-wrapper').attr('style', 'width: ' + $('#patientEditPage').width() + 'px !important');\n    }, 300);\n\n    $(window).resize(function () {\n      $('aside.side-bar ').css('min-height', $(window).height() - 375);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);\n\n      if ($('body').hasClass('floating-header-active')) {\n        $('.floating-header-wrapper').attr('style', 'width: '+$('#patientEditPage').width()+'px !important');\n      }\n    });\n    $('aside.side-bar').css('min-height', $(window).height() - 375);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);\n    $('.datetimepicker').on('apply.daterangepicker', function (ev, picker) {\n      $(this).val(picker.startDate.format('DD. MM. YYYY'));\n      $(this).focus();\n    });\n    $('.datetimepicker').daterangepicker({\n      singleDatePicker: true,\n      autoUpdateInput: false,\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element92 = dom.childAt(fragment, [0]);
        var element93 = dom.childAt(element92, [1]);
        var element94 = dom.childAt(element93, [3]);
        var element95 = dom.childAt(element94, [8]);
        var element96 = dom.childAt(element94, [12]);
        var element97 = dom.childAt(element94, [14, 3]);
        var element98 = dom.childAt(element97, [3]);
        var element99 = dom.childAt(element97, [11]);
        var element100 = dom.childAt(fragment, [4]);
        var element101 = dom.childAt(element100, [1]);
        var element102 = dom.childAt(element101, [1]);
        var element103 = dom.childAt(element102, [4]);
        var element104 = dom.childAt(element102, [6]);
        var element105 = dom.childAt(element102, [8]);
        var element106 = dom.childAt(element105, [1]);
        var element107 = dom.childAt(element106, [1]);
        var element108 = dom.childAt(element101, [3]);
        var element109 = dom.childAt(element108, [1]);
        var element110 = dom.childAt(element109, [1]);
        var element111 = dom.childAt(element109, [3]);
        var element112 = dom.childAt(element109, [5]);
        var element113 = dom.childAt(fragment, [6]);
        var element114 = dom.childAt(element113, [1]);
        var element115 = dom.childAt(element114, [1]);
        var element116 = dom.childAt(element115, [1]);
        var element117 = dom.childAt(element116, [3]);
        var element118 = dom.childAt(element117, [1]);
        var element119 = dom.childAt(element117, [3]);
        var element120 = dom.childAt(element115, [3]);
        var element121 = dom.childAt(element120, [1]);
        var element122 = dom.childAt(element120, [5]);
        var element123 = dom.childAt(element113, [3]);
        var element124 = dom.childAt(element123, [1]);
        var element125 = dom.childAt(element123, [6]);
        var element126 = dom.childAt(element123, [8]);
        var element127 = dom.childAt(element123, [10]);
        var element128 = dom.childAt(element123, [14]);
        var element129 = dom.childAt(element123, [19]);
        var element130 = dom.childAt(element123, [21]);
        var element131 = dom.childAt(element123, [23]);
        var element132 = dom.childAt(fragment, [8]);
        var element133 = dom.childAt(element132, [1, 1]);
        var element134 = dom.childAt(element133, [1]);
        var element135 = dom.childAt(element134, [1]);
        var element136 = dom.childAt(element133, [3]);
        var element137 = dom.childAt(element133, [5]);
        var element138 = dom.childAt(element133, [7]);
        var element139 = dom.childAt(element133, [9]);
        var element140 = dom.childAt(element133, [11]);
        var element141 = dom.childAt(element140, [1, 1]);
        var element142 = dom.childAt(element141, [1]);
        var element143 = dom.childAt(element142, [3]);
        var element144 = dom.childAt(element142, [5]);
        var element145 = dom.childAt(element142, [7]);
        var element146 = dom.childAt(element141, [3]);
        var element147 = dom.childAt(element146, [3]);
        var element148 = dom.childAt(element146, [5]);
        var element149 = dom.childAt(element146, [7]);
        var element150 = dom.childAt(element146, [9]);
        var element151 = dom.childAt(element133, [13]);
        var element152 = dom.childAt(element133, [15]);
        var element153 = dom.childAt(element133, [17]);
        var element154 = dom.childAt(element133, [19]);
        var element155 = dom.childAt(element154, [1, 1]);
        var element156 = dom.childAt(element133, [21]);
        var element157 = dom.childAt(element133, [23]);
        var element158 = dom.childAt(element133, [25]);
        var element159 = dom.childAt(element158, [1]);
        var element160 = dom.childAt(element133, [27]);
        var element161 = dom.childAt(element133, [29]);
        var element162 = dom.childAt(element132, [3]);
        var element163 = dom.childAt(element162, [1]);
        var morphs = new Array(181);
        morphs[0] = dom.createAttrMorph(element92, 'class');
        morphs[1] = dom.createAttrMorph(element93, 'class');
        morphs[2] = dom.createMorphAt(element94,1,1);
        morphs[3] = dom.createMorphAt(element94,2,2);
        morphs[4] = dom.createMorphAt(element94,3,3);
        morphs[5] = dom.createMorphAt(element94,4,4);
        morphs[6] = dom.createMorphAt(element94,5,5);
        morphs[7] = dom.createMorphAt(element94,6,6);
        morphs[8] = dom.createElementMorph(element95);
        morphs[9] = dom.createMorphAt(element94,10,10);
        morphs[10] = dom.createElementMorph(element96);
        morphs[11] = dom.createMorphAt(element97,1,1);
        morphs[12] = dom.createElementMorph(element98);
        morphs[13] = dom.createMorphAt(element97,5,5);
        morphs[14] = dom.createMorphAt(element97,6,6);
        morphs[15] = dom.createMorphAt(element97,7,7);
        morphs[16] = dom.createMorphAt(element97,8,8);
        morphs[17] = dom.createMorphAt(element97,9,9);
        morphs[18] = dom.createElementMorph(element99);
        morphs[19] = dom.createMorphAt(element97,13,13);
        morphs[20] = dom.createAttrMorph(element100, 'class');
        morphs[21] = dom.createMorphAt(element102,1,1);
        morphs[22] = dom.createMorphAt(element102,2,2);
        morphs[23] = dom.createAttrMorph(element103, 'class');
        morphs[24] = dom.createMorphAt(dom.childAt(element104, [1]),0,0);
        morphs[25] = dom.createMorphAt(element104,3,3);
        morphs[26] = dom.createMorphAt(element104,4,4);
        morphs[27] = dom.createMorphAt(element104,5,5);
        morphs[28] = dom.createMorphAt(element104,7,7);
        morphs[29] = dom.createMorphAt(element104,9,9);
        morphs[30] = dom.createMorphAt(element106,0,0);
        morphs[31] = dom.createMorphAt(dom.childAt(element107, [0]),0,0);
        morphs[32] = dom.createMorphAt(element107,1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element105, [3]),0,0);
        morphs[34] = dom.createMorphAt(element110,1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(element110, [3]),0,0);
        morphs[36] = dom.createMorphAt(element111,1,1);
        morphs[37] = dom.createMorphAt(dom.childAt(element111, [3]),0,0);
        morphs[38] = dom.createElementMorph(element112);
        morphs[39] = dom.createMorphAt(element112,1,1);
        morphs[40] = dom.createMorphAt(element108,3,3);
        morphs[41] = dom.createAttrMorph(element113, 'class');
        morphs[42] = dom.createMorphAt(element116,1,1);
        morphs[43] = dom.createMorphAt(element118,0,0);
        morphs[44] = dom.createMorphAt(element118,2,2);
        morphs[45] = dom.createMorphAt(element118,3,3);
        morphs[46] = dom.createMorphAt(element118,4,4);
        morphs[47] = dom.createMorphAt(element118,6,6);
        morphs[48] = dom.createMorphAt(element118,8,8);
        morphs[49] = dom.createMorphAt(element119,0,0);
        morphs[50] = dom.createMorphAt(element119,1,1);
        morphs[51] = dom.createMorphAt(element121,1,1);
        morphs[52] = dom.createMorphAt(dom.childAt(element121, [3]),0,0);
        morphs[53] = dom.createMorphAt(dom.childAt(element120, [3]),1,1);
        morphs[54] = dom.createElementMorph(element122);
        morphs[55] = dom.createMorphAt(element122,1,1);
        morphs[56] = dom.createMorphAt(element114,3,3);
        morphs[57] = dom.createAttrMorph(element124, 'class');
        morphs[58] = dom.createElementMorph(element124);
        morphs[59] = dom.createMorphAt(element123,3,3);
        morphs[60] = dom.createMorphAt(element123,4,4);
        morphs[61] = dom.createAttrMorph(element125, 'class');
        morphs[62] = dom.createElementMorph(element125);
        morphs[63] = dom.createAttrMorph(element126, 'class');
        morphs[64] = dom.createElementMorph(element126);
        morphs[65] = dom.createAttrMorph(element127, 'class');
        morphs[66] = dom.createElementMorph(element127);
        morphs[67] = dom.createMorphAt(element123,12,12);
        morphs[68] = dom.createAttrMorph(element128, 'class');
        morphs[69] = dom.createElementMorph(element128);
        morphs[70] = dom.createMorphAt(element123,16,16);
        morphs[71] = dom.createMorphAt(element123,17,17);
        morphs[72] = dom.createAttrMorph(element129, 'class');
        morphs[73] = dom.createElementMorph(element129);
        morphs[74] = dom.createAttrMorph(element130, 'class');
        morphs[75] = dom.createElementMorph(element130);
        morphs[76] = dom.createAttrMorph(element131, 'class');
        morphs[77] = dom.createElementMorph(element131);
        morphs[78] = dom.createAttrMorph(element132, 'class');
        morphs[79] = dom.createElementMorph(element134);
        morphs[80] = dom.createAttrMorph(element135, 'class');
        morphs[81] = dom.createMorphAt(element135,1,1);
        morphs[82] = dom.createMorphAt(element135,3,3);
        morphs[83] = dom.createAttrMorph(element136, 'class');
        morphs[84] = dom.createMorphAt(element136,1,1);
        morphs[85] = dom.createAttrMorph(element137, 'class');
        morphs[86] = dom.createMorphAt(element137,1,1);
        morphs[87] = dom.createAttrMorph(element138, 'class');
        morphs[88] = dom.createMorphAt(element138,1,1);
        morphs[89] = dom.createMorphAt(element138,3,3);
        morphs[90] = dom.createMorphAt(element138,7,7);
        morphs[91] = dom.createAttrMorph(element139, 'class');
        morphs[92] = dom.createMorphAt(element139,1,1);
        morphs[93] = dom.createAttrMorph(element140, 'class');
        morphs[94] = dom.createAttrMorph(element143, 'class');
        morphs[95] = dom.createElementMorph(element143);
        morphs[96] = dom.createAttrMorph(element144, 'class');
        morphs[97] = dom.createElementMorph(element144);
        morphs[98] = dom.createAttrMorph(element145, 'class');
        morphs[99] = dom.createElementMorph(element145);
        morphs[100] = dom.createAttrMorph(element147, 'class');
        morphs[101] = dom.createElementMorph(element147);
        morphs[102] = dom.createAttrMorph(element148, 'class');
        morphs[103] = dom.createElementMorph(element148);
        morphs[104] = dom.createAttrMorph(element149, 'class');
        morphs[105] = dom.createElementMorph(element149);
        morphs[106] = dom.createAttrMorph(element150, 'class');
        morphs[107] = dom.createElementMorph(element150);
        morphs[108] = dom.createMorphAt(element140,3,3);
        morphs[109] = dom.createAttrMorph(element151, 'class');
        morphs[110] = dom.createMorphAt(element151,1,1);
        morphs[111] = dom.createAttrMorph(element152, 'class');
        morphs[112] = dom.createMorphAt(element152,1,1);
        morphs[113] = dom.createAttrMorph(element153, 'class');
        morphs[114] = dom.createMorphAt(element153,1,1);
        morphs[115] = dom.createMorphAt(element153,3,3);
        morphs[116] = dom.createAttrMorph(element154, 'class');
        morphs[117] = dom.createMorphAt(element155,1,1);
        morphs[118] = dom.createMorphAt(dom.childAt(element155, [3]),1,1);
        morphs[119] = dom.createMorphAt(element154,3,3);
        morphs[120] = dom.createAttrMorph(element156, 'class');
        morphs[121] = dom.createMorphAt(element156,1,1);
        morphs[122] = dom.createAttrMorph(element157, 'class');
        morphs[123] = dom.createMorphAt(element157,1,1);
        morphs[124] = dom.createAttrMorph(element158, 'class');
        morphs[125] = dom.createMorphAt(dom.childAt(element159, [1, 1]),3,3);
        morphs[126] = dom.createMorphAt(element159,3,3);
        morphs[127] = dom.createMorphAt(dom.childAt(element159, [5]),1,1);
        morphs[128] = dom.createAttrMorph(element160, 'class');
        morphs[129] = dom.createMorphAt(element160,1,1);
        morphs[130] = dom.createAttrMorph(element161, 'class');
        morphs[131] = dom.createMorphAt(element161,1,1);
        morphs[132] = dom.createElementMorph(element163);
        morphs[133] = dom.createMorphAt(element163,1,1);
        morphs[134] = dom.createMorphAt(element162,3,3);
        morphs[135] = dom.createMorphAt(element162,4,4);
        morphs[136] = dom.createMorphAt(element162,5,5);
        morphs[137] = dom.createMorphAt(element162,6,6);
        morphs[138] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[139] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[140] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[141] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[142] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[143] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[144] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[145] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[146] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[147] = dom.createMorphAt(fragment,29,29,contextualElement);
        morphs[148] = dom.createMorphAt(fragment,31,31,contextualElement);
        morphs[149] = dom.createMorphAt(fragment,33,33,contextualElement);
        morphs[150] = dom.createMorphAt(fragment,35,35,contextualElement);
        morphs[151] = dom.createMorphAt(fragment,37,37,contextualElement);
        morphs[152] = dom.createMorphAt(fragment,39,39,contextualElement);
        morphs[153] = dom.createMorphAt(fragment,41,41,contextualElement);
        morphs[154] = dom.createMorphAt(fragment,43,43,contextualElement);
        morphs[155] = dom.createMorphAt(fragment,45,45,contextualElement);
        morphs[156] = dom.createMorphAt(fragment,47,47,contextualElement);
        morphs[157] = dom.createMorphAt(fragment,49,49,contextualElement);
        morphs[158] = dom.createMorphAt(fragment,51,51,contextualElement);
        morphs[159] = dom.createMorphAt(fragment,53,53,contextualElement);
        morphs[160] = dom.createMorphAt(fragment,55,55,contextualElement);
        morphs[161] = dom.createMorphAt(fragment,57,57,contextualElement);
        morphs[162] = dom.createMorphAt(fragment,59,59,contextualElement);
        morphs[163] = dom.createMorphAt(fragment,61,61,contextualElement);
        morphs[164] = dom.createMorphAt(fragment,63,63,contextualElement);
        morphs[165] = dom.createMorphAt(fragment,65,65,contextualElement);
        morphs[166] = dom.createMorphAt(fragment,67,67,contextualElement);
        morphs[167] = dom.createMorphAt(fragment,69,69,contextualElement);
        morphs[168] = dom.createMorphAt(fragment,71,71,contextualElement);
        morphs[169] = dom.createMorphAt(fragment,73,73,contextualElement);
        morphs[170] = dom.createMorphAt(fragment,75,75,contextualElement);
        morphs[171] = dom.createMorphAt(fragment,77,77,contextualElement);
        morphs[172] = dom.createMorphAt(fragment,79,79,contextualElement);
        morphs[173] = dom.createMorphAt(fragment,81,81,contextualElement);
        morphs[174] = dom.createMorphAt(fragment,83,83,contextualElement);
        morphs[175] = dom.createMorphAt(fragment,85,85,contextualElement);
        morphs[176] = dom.createMorphAt(fragment,87,87,contextualElement);
        morphs[177] = dom.createMorphAt(fragment,89,89,contextualElement);
        morphs[178] = dom.createMorphAt(fragment,91,91,contextualElement);
        morphs[179] = dom.createMorphAt(fragment,93,93,contextualElement);
        morphs[180] = dom.createMorphAt(fragment,95,95,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky floading-header pb-5 ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,75],[1,88]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,54],[1,119]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[2,72],[2,85]]]]],[],["loc",[null,[2,67],[2,86]]]],"24","18"],[],["loc",[null,[2,46],[2,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[5,12],[5,29]]]]],[],0,null,["loc",[null,[5,6],[16,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[17,16],[17,25]]]],"memos"],[],["loc",[null,[17,12],[17,34]]]]],[],1,null,["loc",[null,[17,6],[21,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[22,16],[22,25]]]],"documents"],[],["loc",[null,[22,12],[22,38]]]]],[],2,null,["loc",[null,[22,6],[27,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[28,16],[28,25]]]],"tasks"],[],["loc",[null,[28,12],[28,34]]]]],[],3,null,["loc",[null,[28,6],[32,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[33,16],[33,25]]]],"appointments"],[],["loc",[null,[33,12],[33,41]]]]],[],4,null,["loc",[null,[33,6],[36,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[37,16],[37,25]]]],"history"],[],["loc",[null,[37,12],[37,36]]]]],[],5,null,["loc",[null,[37,6],[41,13]]]],
        ["element","action",["openDigitalHelperPanel",["get","model.id",["loc",[null,[43,43],[43,51]]]]],[],["loc",[null,[43,9],[43,53]]]],
        ["block","if",[["get","model.etigaActive",["loc",[null,[46,12],[46,29]]]]],[],6,null,["loc",[null,[46,6],[50,13]]]],
        ["element","action",["admissionPatient",["get","model.customerId",["loc",[null,[51,37],[51,53]]]],["get","model.id",["loc",[null,[51,54],[51,62]]]]],[],["loc",[null,[51,9],[51,65]]]],
        ["block","if",[["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[59,48],[59,64]]]],["get","model",["loc",[null,[59,65],[59,70]]]]],[],["loc",[null,[59,16],[59,71]]]]],[],7,null,["loc",[null,[59,10],[70,17]]]],
        ["element","action",["selectBarcode",["get","model",["loc",[null,[71,38],[71,43]]]]],[],["loc",[null,[71,13],[71,45]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[75,20],[75,29]]]],"documents"],[],["loc",[null,[75,16],[75,42]]]]],[],8,null,["loc",[null,[75,10],[80,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[81,20],[81,29]]]],"appointments"],[],["loc",[null,[81,16],[81,45]]]]],[],9,null,["loc",[null,[81,10],[86,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[87,20],[87,29]]]],"lab"],[],["loc",[null,[87,16],[87,36]]]]],[],10,null,["loc",[null,[87,10],[92,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[93,20],[93,29]]]],"future"],[],["loc",[null,[93,16],[93,39]]]]],[],11,null,["loc",[null,[93,10],[103,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[104,20],[104,29]]]],"etiga"],[],["loc",[null,[104,16],[104,38]]]]],[],12,null,["loc",[null,[104,10],[108,17]]]],
        ["element","action",["openMergePatient"],[],["loc",[null,[109,13],[109,42]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.dead",["loc",[null,[112,26],[112,36]]]]],[],["loc",[null,[112,21],[112,37]]]],["subexpr","not",[["get","model.sold",["loc",[null,[112,43],[112,53]]]]],[],["loc",[null,[112,38],[112,54]]]]],[],["loc",[null,[112,16],[112,55]]]]],[],13,null,["loc",[null,[112,10],[116,17]]]],
        ["attribute","class",["concat",["u-hide@tablet card p-relative ",["subexpr","css-bool-evaluator",[["get","model.dead",["loc",[null,[124,63],[124,73]]]],"dead",""],[],["loc",[null,[124,42],[124,85]]]]," patient-card ps-0 pt-0 pb-0 mb-0 mt-50 min-h-130px col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[124,169],[124,182]]]]],[],["loc",[null,[124,164],[124,183]]]],"24","18"],[],["loc",[null,[124,143],[124,195]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["get","model.picture",["loc",[null,[128,12],[128,25]]]]],[],14,15,["loc",[null,[128,6],[132,13]]]],
        ["block","if",[["get","model.etigaActive",["loc",[null,[133,12],[133,29]]]]],[],16,null,["loc",[null,[133,6],[135,13]]]],
        ["attribute","class",["concat",["bubble ",["subexpr","css-bool-evaluator",[["get","model.picture",["loc",[null,[136,47],[136,60]]]],"with-image",""],[],["loc",[null,[136,26],[136,78]]]]]]],
        ["content","model.name",["loc",[null,[138,25],[138,39]]]],
        ["block","if",[["get","model.dead",["loc",[null,[140,14],[140,24]]]]],[],17,null,["loc",[null,[140,8],[142,15]]]],
        ["block","if",[["get","model.insured",["loc",[null,[143,14],[143,27]]]]],[],18,null,["loc",[null,[143,8],[145,15]]]],
        ["block","if",[["get","model.evil",["loc",[null,[146,14],[146,24]]]]],[],19,null,["loc",[null,[146,8],[152,15]]]],
        ["block","if",[["get","model.outdoor",["loc",[null,[153,14],[153,27]]]]],[],20,null,["loc",[null,[153,8],[153,147]]]],
        ["block","if",[["get","model.medicalIncompatibility",["loc",[null,[154,14],[154,42]]]]],[],21,null,["loc",[null,[154,8],[154,203]]]],
        ["inline","animal-icon",[],["animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[157,86],[157,103]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[157,112],[157,134]]]]],[],[]],"size","22","content","","color","#4A4A4A"],["loc",[null,[157,62],[157,173]]]],
        ["content","model.category.name",["loc",[null,[157,233],[157,256]]]],
        ["block","if",[["subexpr","or",[["get","model.race",["loc",[null,[157,273],[157,283]]]],["get","model.digitalRace",["loc",[null,[157,284],[157,301]]]]],[],["loc",[null,[157,269],[157,302]]]]],[],22,null,["loc",[null,[157,263],[157,478]]]],
        ["block","if",[["get","model.customer",["loc",[null,[158,64],[158,78]]]]],[],23,null,["loc",[null,[158,58],[158,243]]]],
        ["block","if",[["get","model.gender",["loc",[null,[165,16],[165,28]]]]],[],24,25,["loc",[null,[165,10],[169,17]]]],
        ["block","if",[["get","model.castrated",["loc",[null,[170,40],[170,55]]]]],[],26,27,["loc",[null,[170,34],[170,91]]]],
        ["block","if",[["get","model.ageShort",["loc",[null,[173,16],[173,30]]]]],[],28,29,["loc",[null,[173,10],[177,17]]]],
        ["block","if",[["get","model.birthday",["loc",[null,[178,40],[178,54]]]]],[],30,31,["loc",[null,[178,34],[178,121]]]],
        ["element","action",["openWeightPanel"],[],["loc",[null,[180,40],[180,68]]]],
        ["block","if",[["get","model.currentWeight",["loc",[null,[181,16],[181,35]]]]],[],32,33,["loc",[null,[181,10],[185,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[189,12],[189,20]]]]],[],34,null,["loc",[null,[189,6],[202,13]]]],
        ["attribute","class",["concat",["floating-header-wrapper col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[208,68],[208,81]]]]],[],["loc",[null,[208,63],[208,82]]]],"24","18"],[],["loc",[null,[208,42],[208,94]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["inline","animal-icon",[],["animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[212,32],[212,49]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[212,58],[212,80]]]]],[],[]],"size","22","content","","color","#4A4A4A","classNames","me-10"],["loc",[null,[212,8],[213,42]]]],
        ["content","model.name",["loc",[null,[215,61],[215,75]]]],
        ["block","if",[["get","model.dead",["loc",[null,[216,18],[216,28]]]]],[],35,null,["loc",[null,[216,12],[218,19]]]],
        ["block","if",[["get","model.insured",["loc",[null,[219,18],[219,31]]]]],[],36,null,["loc",[null,[219,12],[221,19]]]],
        ["block","if",[["get","model.evil",["loc",[null,[222,18],[222,28]]]]],[],37,null,["loc",[null,[222,12],[229,19]]]],
        ["block","if",[["get","model.outdoor",["loc",[null,[230,18],[230,31]]]]],[],38,null,["loc",[null,[230,12],[231,84]]]],
        ["block","if",[["get","model.medicalIncompatibility",["loc",[null,[232,18],[232,46]]]]],[],39,null,["loc",[null,[232,12],[234,100]]]],
        ["content","model.category.name",["loc",[null,[236,29],[236,52]]]],
        ["block","if",[["subexpr","or",[["get","model.race",["loc",[null,[236,62],[236,72]]]],["get","model.digitalRace",["loc",[null,[236,73],[236,90]]]]],[],["loc",[null,[236,58],[236,91]]]]],[],40,null,["loc",[null,[236,52],[237,101]]]],
        ["block","if",[["get","model.gender",["loc",[null,[243,16],[243,28]]]]],[],41,null,["loc",[null,[243,10],[245,17]]]],
        ["block","if",[["get","model.castrated",["loc",[null,[246,40],[246,55]]]]],[],42,43,["loc",[null,[246,34],[246,96]]]],
        ["block","if",[["get","model.ageShort",["loc",[null,[249,16],[249,30]]]]],[],44,45,["loc",[null,[249,10],[253,17]]]],
        ["element","action",["openWeightPanel"],[],["loc",[null,[255,40],[255,68]]]],
        ["block","if",[["get","model.currentWeight",["loc",[null,[256,16],[256,35]]]]],[],46,47,["loc",[null,[256,10],[260,17]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[265,10],[265,18]]]]],[],48,null,["loc",[null,[265,4],[281,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[284,51],[284,60]]]],"masterdata"],[],["loc",[null,[284,47],[284,74]]]],"is-active",""],[],["loc",[null,[284,26],[284,91]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[285,31],[285,66]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-journal-enabled",[],[],["loc",[null,[286,14],[286,34]]]],["subexpr","is-debug-user",[],[],["loc",[null,[286,35],[286,50]]]]],[],["loc",[null,[286,10],[286,51]]]]],[],49,null,["loc",[null,[286,4],[289,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[290,14],[290,31]]]],["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[290,37],[290,57]]]]],[],["loc",[null,[290,32],[290,58]]]],["subexpr","is-debug-user",[],[],["loc",[null,[290,59],[290,74]]]]],[],["loc",[null,[290,10],[290,75]]]]],[],50,null,["loc",[null,[290,4],[293,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[294,51],[294,60]]]],"future"],[],["loc",[null,[294,47],[294,70]]]],"is-active",""],[],["loc",[null,[294,26],[294,87]]]]]]],
        ["element","action",["selectTab","future"],[],["loc",[null,[294,108],[295,28]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[296,51],[296,60]]]],"lab"],[],["loc",[null,[296,47],[296,67]]]],"is-active",""],[],["loc",[null,[296,26],[296,84]]]]]]],
        ["element","action",["selectTab","lab"],[],["loc",[null,[296,102],[297,25]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[298,51],[298,60]]]],"documents"],[],["loc",[null,[298,47],[298,73]]]],"is-active",""],[],["loc",[null,[298,26],[298,90]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[299,29],[299,63]]]],
        ["block","if",[["subexpr","or",[["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[300,19],[300,39]]]]],[],["loc",[null,[300,14],[300,40]]]],["subexpr","is-debug-user",[],[],["loc",[null,[300,41],[300,56]]]]],[],["loc",[null,[300,10],[300,57]]]]],[],51,null,["loc",[null,[300,4],[303,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[304,51],[304,60]]]],"tasks"],[],["loc",[null,[304,47],[304,69]]]],"is-active",""],[],["loc",[null,[304,26],[304,86]]]]]]],
        ["element","action",["selectTab","tasks"],[],["loc",[null,[304,106],[305,27]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[306,10],[306,27]]]]],[],52,null,["loc",[null,[306,4],[309,11]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[310,10],[310,33]]]]],[],53,null,["loc",[null,[310,4],[313,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[314,51],[314,60]]]],"vacc"],[],["loc",[null,[314,47],[314,68]]]],"is-active",""],[],["loc",[null,[314,26],[314,85]]]]]]],
        ["element","action",["selectTab","vacc"],[],["loc",[null,[314,104],[315,26]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[316,51],[316,60]]]],"memos"],[],["loc",[null,[316,47],[316,69]]]],"is-active",""],[],["loc",[null,[316,26],[316,86]]]]]]],
        ["element","action",["selectTab","memos"],[],["loc",[null,[316,106],[317,27]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[318,51],[318,60]]]],"etiga"],[],["loc",[null,[318,47],[318,69]]]],"is-active",""],[],["loc",[null,[318,26],[318,86]]]]]]],
        ["element","action",["selectTab","etiga"],[],["loc",[null,[318,106],[319,27]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[323,48],[323,61]]]]],[],["loc",[null,[323,43],[323,62]]]],"24","18"],[],["loc",[null,[323,22],[323,74]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[323,133],[323,146]]]],"sidebar-active",""],[],["loc",[null,[323,112],[323,168]]]]," fadeIn"]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[327,58],[327,87]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[328,79],[328,88]]]],"masterdata"],[],["loc",[null,[328,75],[328,102]]]],"is-active",""],[],["loc",[null,[328,54],[328,119]]]]]]],
        ["inline","patients-edit-form",[],["edit",true,"digitalRaceEnabled",true,"customerOverride",["subexpr","@mut",[["get","customerOverride",["loc",[null,[329,92],[329,108]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[329,115],[329,120]]]]],[],[]],"openChipPanel","openChipPanel","openLogbook","openEtigaMasterdataLogbook","changeUser","changeUser","etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[329,233],[329,249]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[329,258],[329,265]]]]],[],[]],"castrationOptions",["subexpr","@mut",[["get","castrationOptions",["loc",[null,[329,284],[329,301]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[329,313],[329,323]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[329,333],[329,341]]]]],[],[]],"insurers",["subexpr","@mut",[["get","insurers",["loc",[null,[329,351],[329,359]]]]],[],[]],"patientImage",["subexpr","@mut",[["get","patientImage",["loc",[null,[329,373],[329,385]]]]],[],[]],"imageChanged","changeImage","actionReceiver",["subexpr","@mut",[["get","patientEditForm",["loc",[null,[329,428],[329,443]]]]],[],[]]],["loc",[null,[329,20],[329,445]]]],
        ["block","if",[["subexpr","eq",[["get","model.category.id",["loc",[null,[330,30],[330,47]]]],"pferd"],[],["loc",[null,[330,26],[330,56]]]]],[],54,null,["loc",[null,[330,20],[332,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[335,60],[335,69]]]],"memos"],[],["loc",[null,[335,56],[335,78]]]],"is-active",""],[],["loc",[null,[335,35],[335,95]]]]]]],
        ["inline","patient-memos",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[336,38],[336,46]]]]],[],[]],"items",["subexpr","@mut",[["get","memos",["loc",[null,[336,53],[336,58]]]]],[],[]],"reload","reloadMemos","actionReceiver",["subexpr","@mut",[["get","patientMemoPanel",["loc",[null,[336,96],[336,112]]]]],[],[]]],["loc",[null,[336,12],[336,114]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[338,60],[338,69]]]],"vacc"],[],["loc",[null,[338,56],[338,77]]]],"is-active",""],[],["loc",[null,[338,35],[338,94]]]]]]],
        ["inline","patient-vaccinations",[],["gotoService","gotoService","gotoProduct","gotoProduct","items",["subexpr","@mut",[["get","vaccinations",["loc",[null,[339,93],[339,105]]]]],[],[]]],["loc",[null,[339,12],[339,107]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[341,75],[341,84]]]],"weight"],[],["loc",[null,[341,71],[341,94]]]],"is-active",""],[],["loc",[null,[341,50],[341,111]]]]]]],
        ["block","if",[["get","weightInfos",["loc",[null,[342,22],[342,33]]]]],[],55,null,["loc",[null,[342,16],[344,23]]]],
        ["inline","patient-weight-chart",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[345,49],[345,57]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[345,73],[345,84]]]]],[],[]]],["loc",[null,[345,16],[345,86]]]],
        ["inline","patient-weight",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[347,45],[347,56]]]]],[],[]],"edit","openWeight","delete","deleteWeight","editable",["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[347,137],[347,153]]]],["get","model",["loc",[null,[347,154],[347,159]]]]],[],["loc",[null,[347,106],[347,160]]]],"patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[347,171],[347,179]]]]],[],[]]],["loc",[null,[347,16],[347,181]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[349,73],[349,82]]]],"activities"],[],["loc",[null,[349,69],[349,96]]]],"is-active",""],[],["loc",[null,[349,48],[349,113]]]]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openSlopingInfos","openSlopingInfos","openDocument","openDocument","gotoReport","gotoReport","gotoTemplate","gotoTemplate","openLab","openLab","openApp","openEditAppointment","gotoVisitDetails","gotoVisitDetails","doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[361,24],[361,31]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[362,27],[362,37]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[363,22],[363,27]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[364,31],[364,48]]]]],[],[]]],["loc",[null,[350,14],[364,50]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[366,60],[366,69]]]],"history"],[],["loc",[null,[366,56],[366,80]]]],"is-active",""],[],["loc",[null,[366,35],[366,97]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[373,75],[373,86]]]],"all"],[],["loc",[null,[373,71],[373,93]]]],"selected",""],[],["loc",[null,[373,50],[373,110]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[373,112],[373,148]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[374,75],[374,86]]]],"visible"],[],["loc",[null,[374,71],[374,97]]]],"selected",""],[],["loc",[null,[374,50],[374,114]]]]]]],
        ["element","action",["toggleQuickFilter","visible"],[],["loc",[null,[374,116],[374,156]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[375,75],[375,86]]]],"deleted"],[],["loc",[null,[375,71],[375,97]]]],"selected",""],[],["loc",[null,[375,50],[375,114]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[375,116],[375,156]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterFood",["loc",[null,[379,71],[379,81]]]],"selected",""],[],["loc",[null,[379,50],[379,97]]]]]]],
        ["element","action",["toggleTabFilter","filterFood"],[],["loc",[null,[379,99],[379,140]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterServices",["loc",[null,[380,71],[380,85]]]],"selected",""],[],["loc",[null,[380,50],[380,101]]]]]]],
        ["element","action",["toggleTabFilter","filterServices"],[],["loc",[null,[380,103],[380,148]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterProducts",["loc",[null,[381,71],[381,85]]]],"selected",""],[],["loc",[null,[381,50],[381,101]]]]]]],
        ["element","action",["toggleTabFilter","filterProducts"],[],["loc",[null,[381,103],[381,148]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterLab",["loc",[null,[382,71],[382,80]]]],"selected",""],[],["loc",[null,[382,50],[382,96]]]]]]],
        ["element","action",["toggleTabFilter","filterLab"],[],["loc",[null,[382,98],[382,138]]]],
        ["inline","treatment-history",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[388,22],[388,27]]]]],[],[]],"showCopy",false,"showEdit",true,"showHide",true,"showHideApp",true,"showFilter",false,"users",["subexpr","@mut",[["get","users",["loc",[null,[394,22],[394,27]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[395,33],[395,49]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[396,24],[396,31]]]]],[],[]],"reload","reloadHistory","gotoReport","gotoReport","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[399,22],[399,27]]]]],[],[]],"showPager",true,"reload","reloadHistory","assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[402,27],[402,37]]]]],[],[]],"openDigitalForm","openDigitalForm","employeesSaved","employeesSaved","openSlopingInfos","openSlopingInfos","roomSaved","roomSaved","downloadSlopingBarcode","selectSlopingBarcodeForInvoice","deleteTreatment","deleteTreatment","editTreatmentPanel","editTreatmentPanel","treatmentSaved","saveTreatment","openTemplateEdit","openTemplateEdit","forwardToBilling","forwardToBilling","openLaboklinChoosePanel","openLaboklinChoosePanel","items",["subexpr","@mut",[["get","historicTreatments",["loc",[null,[414,22],[414,40]]]]],[],[]]],["loc",[null,[387,14],[414,42]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[416,62],[416,71]]]],"future"],[],["loc",[null,[416,58],[416,81]]]],"is-active",""],[],["loc",[null,[416,37],[416,98]]]]]]],
        ["inline","treatment-future",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[418,22],[418,27]]]]],[],[]],"items",["subexpr","@mut",[["get","futureItems",["loc",[null,[419,22],[419,33]]]]],[],[]],"newMode",true,"crud",false,"editFuture","editFuture","reload","reloadFuture","moveFuture","moveFuture","addDirectNotification","openAddDirectNotification","addDirectAppNotification","openAddAppDirectNotification","addFutureAction","addFutureAction","deleteFutureEntry","deleteFuture","actionReceiver",["subexpr","@mut",[["get","treatmentFuturePanel",["loc",[null,[429,29],[429,49]]]]],[],[]]],["loc",[null,[417,14],[429,51]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[431,62],[431,71]]]],"lab"],[],["loc",[null,[431,58],[431,78]]]],"is-active",""],[],["loc",[null,[431,37],[431,95]]]]]]],
        ["inline","laboklin-historic-forms",[],["actionReceiver",["subexpr","@mut",[["get","historicItems",["loc",[null,[432,57],[432,70]]]]],[],[]],"downloadBarcode","selectLaboklinBarcode","editable",["subexpr","is-etiga-editable",["addLabor",["get","etigaPermissions",["loc",[null,[434,81],[434,97]]]],["get","model",["loc",[null,[434,98],[434,103]]]]],[],["loc",[null,[434,51],[434,104]]]],"showSendAll",false,"showEdit",true,"size",40,"downloadMedia","downloadMedia","editLaboklinForm","editLaboklinForm","downloadVetconnectResult","downloadVetconnectResult","openResult","openResult","downloadResult","downloadResult","downloadPdf","downloadPdf","forwardEmail","openEmailForwardPanel","deleteReport","deleteReport","submitLaboklin","submitLaboklin","reload","reloadLaboklin","labItems",["subexpr","@mut",[["get","labItems",["loc",[null,[448,51],[448,59]]]]],[],[]]],["loc",[null,[432,16],[448,61]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[450,75],[450,84]]]],"medias"],[],["loc",[null,[450,71],[450,94]]]],"is-active",""],[],["loc",[null,[450,50],[450,111]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[451,24],[451,33]]]],"documents"],[],["loc",[null,[451,20],[451,46]]]]],[],56,null,["loc",[null,[451,14],[454,21]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[455,39],[455,45]]]]],[],[]],"deleteMedia","deleteMedia","editable",["subexpr","is-etiga-editable",["addDocs",["get","etigaPermissions",["loc",[null,[455,110],[455,126]]]],["get","model",["loc",[null,[455,127],[455,132]]]]],[],["loc",[null,[455,81],[455,133]]]],"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[455,16],[455,204]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[457,62],[457,71]]]],"documents"],[],["loc",[null,[457,58],[457,84]]]],"is-active",""],[],["loc",[null,[457,37],[457,101]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[461,42],[461,52]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[461,18],[461,108]]]],
        ["block","each",[["get","tags",["loc",[null,[463,28],[463,32]]]]],[],57,null,["loc",[null,[463,20],[463,249]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[473,31],[473,45]]]]],[],[]]],["loc",[null,[468,14],[473,47]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[475,62],[475,71]]]],"correspondance"],[],["loc",[null,[475,58],[475,89]]]],"is-active",""],[],["loc",[null,[475,37],[475,106]]]]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[476,48],[476,62]]]]],[],[]]],["loc",[null,[476,16],[476,64]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[478,62],[478,71]]]],"tasks"],[],["loc",[null,[478,58],[478,80]]]],"is-active",""],[],["loc",[null,[478,37],[478,97]]]]]]],
        ["inline","patient-tasks",[],["items",["subexpr","@mut",[["get","tasks",["loc",[null,[479,36],[479,41]]]]],[],[]],"startTask","startTask","createNotification","addMessage","showNotification","showNotification","finishTask","finishTask","showTask","showTask","startTask","startTask","changeOwner","changeOwner","setSortDir","setSortDir"],["loc",[null,[479,14],[487,55]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[489,60],[489,69]]]],"todo"],[],["loc",[null,[489,56],[489,77]]]],"is-active",""],[],["loc",[null,[489,35],[489,94]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","todoStatus",["loc",[null,[495,30],[495,40]]]]],[],[]],"value",["subexpr","@mut",[["get","filterTodoStatus",["loc",[null,[496,28],[496,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[494,20],[502,22]]]],
        ["block","if",[["subexpr","list-not-empty",[["get","todos",["loc",[null,[505,38],[505,43]]]]],[],["loc",[null,[505,22],[505,44]]]]],[],58,null,["loc",[null,[505,16],[544,23]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","todos",["loc",[null,[546,41],[546,46]]]]],[],[]],"numPagesToShow",7],["loc",[null,[546,18],[546,65]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[550,62],[550,71]]]],"appointments"],[],["loc",[null,[550,58],[550,87]]]],"is-active",""],[],["loc",[null,[550,37],[550,104]]]]]]],
        ["inline","patient-appointments",[],["startTreatment","admissionPatient","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[551,202],[551,214]]]]],[],[]]],["loc",[null,[551,14],[551,216]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[553,62],[553,71]]]],"etiga"],[],["loc",[null,[553,58],[553,80]]]],"is-active",""],[],["loc",[null,[553,37],[553,97]]]]]]],
        ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[554,20],[554,37]]]]],[],59,60,["loc",[null,[554,14],[573,21]]]],
        ["element","action",["back"],[],["loc",[null,[579,48],[579,65]]]],
        ["content","button-prev",["loc",[null,[580,8],[580,23]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[582,21],[582,30]]]],"masterdata"],[],["loc",[null,[582,17],[582,44]]]],["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[582,77],[582,93]]]],["get","model",["loc",[null,[582,94],[582,99]]]]],[],["loc",[null,[582,45],[582,100]]]]],[],["loc",[null,[582,12],[582,101]]]]],[],61,null,["loc",[null,[582,6],[586,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[587,16],[587,25]]]],"etiga"],[],["loc",[null,[587,12],[587,34]]]]],[],62,null,["loc",[null,[587,6],[591,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[592,48],[592,64]]]],["get","model",["loc",[null,[592,65],[592,70]]]]],[],["loc",[null,[592,17],[592,71]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[592,76],[592,85]]]],"weight"],[],["loc",[null,[592,72],[592,95]]]]],[],["loc",[null,[592,12],[592,96]]]]],[],63,null,["loc",[null,[592,6],[599,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[600,16],[600,25]]]],"medias"],[],["loc",[null,[600,12],[600,35]]]]],[],64,null,["loc",[null,[600,6],[604,13]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[607,9],[607,22]]]]],[],65,66,["loc",[null,[607,3],[844,7]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[846,40],[846,60]]]]],[],[]]],["loc",[null,[846,0],[846,62]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[847,36],[847,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[847,55],[847,60]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[847,77],[847,92]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[847,0],[847,122]]]],
        ["inline","customer-reduction-panel",[],["actionReceiver",["subexpr","@mut",[["get","setCustomer",["loc",[null,[848,42],[848,53]]]]],[],[]]],["loc",[null,[848,0],[848,55]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[851,37],[851,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[851,0],[851,76]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[853,60],[853,75]]]]],[],[]]],["loc",[null,[853,0],[853,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[854,59],[854,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[854,80],[854,100]]]]],[],[]]],["loc",[null,[854,0],[854,102]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[856,30],[856,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[856,44],[856,51]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[856,63],[856,73]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[856,84],[856,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[856,100],[856,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[856,115],[856,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[856,166],[856,185]]]]],[],[]]],["loc",[null,[856,0],[856,187]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[857,23],[857,28]]]]],[],[]],"create","create","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[857,92],[857,104]]]]],[],[]]],["loc",[null,[857,0],[857,106]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[858,33],[858,46]]]]],[],[]],"linkTo","linkTo","goto","goto","create","createNotification","addReply","addReply","startTask","startTask","finishTask","finishTask","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[858,0],[858,220]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[859,40],[859,60]]]]],[],[]]],["loc",[null,[859,0],[859,62]]]],
        ["inline","show-notification-panel",[],["actionReceiver",["subexpr","@mut",[["get","showNotificationPanel",["loc",[null,[861,41],[861,62]]]]],[],[]]],["loc",[null,[861,0],[861,64]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[863,31],[863,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[863,46],[863,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[863,63],[863,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[863,136],[863,156]]]]],[],[]]],["loc",[null,[863,0],[863,158]]]],
        ["inline","etiga-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaLogbookPanel",["loc",[null,[864,37],[864,54]]]]],[],[]]],["loc",[null,[864,0],[864,56]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadPatientBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[866,80],[866,105]]]]],[],[]]],["loc",[null,[866,0],[866,107]]]],
        ["inline","laboklin-select-barcode-panel",[],["download","downloadLaboklinBarcode","actionReceiver",["subexpr","@mut",[["get","laboklinSelectBarcodePanel",["loc",[null,[867,82],[867,108]]]]],[],[]]],["loc",[null,[867,0],[867,110]]]],
        ["inline","choose-existing-etiga-panel",[],["createNew","createNew","select","connectToExistingEtigaCustomer","actionReceiver",["subexpr","@mut",[["get","existingEtigaPanel",["loc",[null,[868,107],[868,125]]]]],[],[]]],["loc",[null,[868,0],[868,127]]]],
        ["inline","sloping-info-panel",[],["save","saveSlopingInfo","gotoProduct","gotoProduct","gotoService","gotoService","selectBarcode","downloadSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[869,150],[869,166]]]]],[],[]]],["loc",[null,[869,0],[869,168]]]],
        ["inline","edit-medical-problems-panel",[],["confirm","saveMedicalProblemsInfo","openEditDosing","openEditDosing","refresh","refreshPastDosings","actionReceiver",["subexpr","@mut",[["get","medicalProblemsPanel",["loc",[null,[870,140],[870,160]]]]],[],[]]],["loc",[null,[870,0],[870,162]]]],
        ["inline","edit-past-actions-panel",[],["save","savePastAction","actionReceiver",["subexpr","@mut",[["get","editPastActionsPanel",["loc",[null,[871,63],[871,83]]]]],[],[]]],["loc",[null,[871,0],[871,85]]]],
        ["inline","edit-lab-date-panel",[],["confirm","changeLabDate","actionReceiver",["subexpr","@mut",[["get","changeLabDatePanel",["loc",[null,[873,61],[873,79]]]]],[],[]]],["loc",[null,[873,0],[873,81]]]],
        ["inline","digital-form-panel",[],["confirm","updateDigitalForm","actionReceiver",["subexpr","@mut",[["get","digitalFormPanel",["loc",[null,[877,25],[877,41]]]]],[],[]]],["loc",[null,[875,0],[877,43]]]],
        ["inline","add-etiga-share",[],["etigaShareInfos",["subexpr","@mut",[["get","etigaShareInfos",["loc",[null,[880,18],[880,33]]]]],[],[]],"select","addEtigaPermisison","remove","removeEtigaShare","actionReceiver",["subexpr","@mut",[["get","addEtigaShare",["loc",[null,[883,17],[883,30]]]]],[],[]]],["loc",[null,[879,0],[883,32]]]],
        ["inline","digital-helper-qr-panel",[],["actionReceiver",["subexpr","@mut",[["get","digitalHelperPanel",["loc",[null,[885,41],[885,59]]]]],[],[]]],["loc",[null,[885,0],[885,61]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[898,17],[898,39]]]]],[],[]]],["loc",[null,[887,0],[898,41]]]],
        ["inline","private-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","downloadResult","downloadResult","openLabResult","openLabResult","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","privateTreatmentPanel",["loc",[null,[913,17],[913,38]]]]],[],[]]],["loc",[null,[900,0],[913,40]]]],
        ["inline","lab-result-panel",[],["changeParamValue","changeParamValue","downloadResult","downloadResult","openChart","openChart","selectBarcode","selectBarcode","dateChanged","openChangeDate","save","saveLabResult","actionReceiver",["subexpr","@mut",[["get","labResultPanel",["loc",[null,[921,17],[921,31]]]]],[],[]]],["loc",[null,[914,0],[921,33]]]],
        ["inline","all-lab-result-panel",[],["openChart","openChart","actionReceiver",["subexpr","@mut",[["get","allLabResultPanel",["loc",[null,[924,17],[924,34]]]]],[],[]]],["loc",[null,[922,0],[924,36]]]],
        ["inline","lab-result-chart-panel",[],["back","openResult","actionReceiver",["subexpr","@mut",[["get","labResultChartPanel",["loc",[null,[927,17],[927,36]]]]],[],[]]],["loc",[null,[925,0],[927,38]]]],
        ["inline","etiga-masterdata-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaMasterdataLogbookPanel",["loc",[null,[929,17],[929,44]]]]],[],[]]],["loc",[null,[928,0],[929,46]]]],
        ["inline","contact-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","contactInfoPanel",["loc",[null,[931,36],[931,52]]]]],[],[]]],["loc",[null,[931,0],[931,54]]]],
        ["inline","patient-chip-panel",[],["genders",["subexpr","@mut",[["get","genders",["loc",[null,[934,12],[934,19]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientChipPanel",["loc",[null,[935,19],[935,35]]]]],[],[]]],["loc",[null,[933,2],[935,37]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[941,8],[941,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[942,10],[942,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[943,13],[943,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[944,17],[944,36]]]]],[],[]]],["loc",[null,[937,2],[944,38]]]],
        ["inline","set-death-day-panel",[],["confirm","updateDeathDate","remove","removeDeathDate","actionReceiver",["subexpr","@mut",[["get","deathDayPanel",["loc",[null,[946,88],[946,101]]]]],[],[]]],["loc",[null,[946,0],[946,103]]]],
        ["inline","treatment-weight-panel",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[947,37],[947,48]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[947,57],[947,62]]]]],[],[]],"reloadWeightData","reloadWeightData","reloadPatient","reloadPatient","reopen","openWeightPanel","actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[947,169],[947,180]]]]],[],[]]],["loc",[null,[947,0],[947,182]]]],
        ["inline","add-direct-app-notification-panel",[],["showTitle",false,"reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[948,117],[948,141]]]]],[],[]]],["loc",[null,[948,0],[948,143]]]],
        ["inline","add-direct-notification-panel",[],["reload","reload","create","addDirectNotification","users",["subexpr","@mut",[["get","users",["loc",[null,[949,85],[949,90]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[949,100],[949,108]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[949,117],[949,124]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","addDirectNotification",["loc",[null,[949,140],[949,161]]]]],[],[]]],["loc",[null,[949,0],[949,163]]]],
        ["inline","treatment-info-form-clone",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[950,74],[950,94]]]]],[],[]]],["loc",[null,[950,0],[950,96]]]],
        ["inline","template-edit-panel",[],["download","downloadTemplate","reloadTemplate","reloadTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[951,97],[951,114]]]]],[],[]]],["loc",[null,[951,0],[951,116]]]],
        ["inline","select-customer-panel",[],["select","changePatientOwner","actionReceiver",["subexpr","@mut",[["get","selectCustomerPanel",["loc",[null,[952,67],[952,86]]]]],[],[]]],["loc",[null,[952,0],[952,88]]]],
        ["inline","edit-todo-panel",[],["confirm","saveTodoInstance","actionReceiver",["subexpr","@mut",[["get","editTodoPanel",["loc",[null,[953,60],[953,73]]]]],[],[]]],["loc",[null,[953,0],[953,75]]]],
        ["inline","email-etiga-panel",[],["confirm","sendEtigaMail","openAddAppDirectNotification","openDirectMessage","qrDownload","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","emailEtigaPanel",["loc",[null,[955,137],[955,152]]]]],[],[]]],["loc",[null,[955,0],[955,154]]]],
        ["inline","merge-patient-panel",[],["gotoPatient","gotoPatient","actionReceiver",["subexpr","@mut",[["get","mergePatientPanel",["loc",[null,[957,63],[957,80]]]]],[],[]]],["loc",[null,[957,0],[957,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46, child47, child48, child49, child50, child51, child52, child53, child54, child55, child56, child57, child58, child59, child60, child61, child62, child63, child64, child65, child66]
    };
  }()));

});
define('jason-frontend/components/treatment-patient-list', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    filterState: null,
    showPager: false,
    showSums: false,
    inProgress: false,
    allStationsSelected: true,
    actions: {
      toggleAllStations: function toggleAllStations() {
        this.set('allStationsSelected', true);
        this.get('stations').forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        this.get('controller').set('changeTrigger', new Date());
      },
      toggleStation: function toggleStation(station) {
        this.set('allStationsSelected', false);
        if (station.get('selected')) {
          Ember['default'].set(station, 'selected', false);
        } else {
          Ember['default'].set(station, 'selected', true);
        }

        if (!this.get('stations').some(function (item) {
          return item.get('selected') === true;
        })) {
          this.set('allStationsSelected', true);
        }
        this.set('changeTrigger', new Date());
      },
      openTime: function openTime(treatment) {
        this.sendAction('openTime', treatment);
      },
      editTreatmentInfo: function editTreatmentInfo(info) {
        this.sendAction('editTreatmentInfo', info);
      },
      openStationPanel: function openStationPanel(id) {

        this.get('setStationPanel').send('load', id);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-station-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      movePatientToStation: function movePatientToStation(id, data) {
        this.sendAction('movePatientToStation', id, data);
      },
      openStationVisit: function openStationVisit(id) {
        this.sendAction('openStationVisit', id);
      },
      movePatientToStationSimple: function movePatientToStationSimple(id, data) {
        this.sendAction('movePatientToStationSimple', id, data);
      },
      movePatientToStationExtended: function movePatientToStationExtended(id, data) {
        this.sendAction('movePatientToStationExtended', id, data);
      },
      movePatientToWaitingRoom: function movePatientToWaitingRoom(id) {
        this.sendAction('movePatientToWaitingRoom', id);
      },
      movePatientToTreatmentRoom: function movePatientToTreatmentRoom(id) {
        this.sendAction('movePatientToTreatmentRoom', id);
      },
      abort: function abort(patientId) {
        this.sendAction('abort', patientId);
      },
      exportInvoice: function exportInvoice(patientId, invoiceId) {
        this.set('inProgress', true);
        this.sendAction('exportInvoice', patientId, invoiceId);
      },
      clickPatient: function clickPatient(id) {
        this.sendAction('clickPatient', id);
      },
      onInsert: (function () {
        this.set('inProgress', false);
      }).on('didInsertElement')
    }
  });

});
define('jason-frontend/templates/components/stock-details', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 12
              },
              "end": {
                "line": 7,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element21, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[6,87],[6,105]]]]],[],["loc",[null,[6,71],[6,107]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 12
              },
              "end": {
                "line": 9,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
            dom.setAttribute(el1,"class","media-object");
            dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 100
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","title title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.partnerCategory",["loc",[null,[13,63],[13,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 17
              },
              "end": {
                "line": 22,
                "column": 41
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Ja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 41
              },
              "end": {
                "line": 22,
                "column": 53
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Nein");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 6
            },
            "end": {
              "line": 29,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-8/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","u-text--center pr30");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-16/24");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","product-description");
          dom.setAttribute(el2,"class","");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dataList grid");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Verpackungsmenge");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Kategorie");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","");
          var el6 = dom.createTextNode("Suchtgiftmittel");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","");
          var el6 = dom.createTextNode("Wirkstoffe / Zusatzstoffe");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [3]);
          var element23 = dom.childAt(element22, [3, 1, 1]);
          var element24 = dom.childAt(element23, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(element22,1,1);
          morphs[2] = dom.createMorphAt(element24,0,0);
          morphs[3] = dom.createMorphAt(element24,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(element23, [7]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element23, [11]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element23, [15]),0,0);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.picture",["loc",[null,[5,18],[5,31]]]]],[],0,1,["loc",[null,[5,12],[9,19]]]],
          ["block","if",[["get","model.partnerCategory",["loc",[null,[13,14],[13,35]]]]],[],2,null,["loc",[null,[13,8],[13,107]]]],
          ["content","purchaseProduct.packageQuantity",["loc",[null,[18,17],[18,52]]]],
          ["content","model.unit.name",["loc",[null,[18,53],[18,72]]]],
          ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[20,43],[20,62]]]],"-"],[],["loc",[null,[20,17],[20,68]]]],
          ["block","if",[["get","model.narcotic",["loc",[null,[22,23],[22,37]]]]],[],3,4,["loc",[null,[22,17],[22,60]]]],
          ["inline","get-value-or-empty-sign",[["get","model.ingredients",["loc",[null,[24,43],[24,60]]]],["get","-",["loc",[null,[24,61],[24,62]]]]],[],["loc",[null,[24,17],[24,65]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 10
              },
              "end": {
                "line": 37,
                "column": 140
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell l-3/24 table__cell--center");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","table__title title");
            var el3 = dom.createTextNode("Lager");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 12
                },
                "end": {
                  "line": 53,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell l-8/24 l-3/24@desk table__cell--center");
              var el2 = dom.createTextNode("\n               ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
              return morphs;
            },
            statements: [
              ["content","item.warehouseName",["loc",[null,[52,15],[52,37]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 16
                },
                "end": {
                  "line": 83,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Korrektur");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element7);
              morphs[1] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openCorrection",["get","item.id",["loc",[null,[80,47],[80,54]]]]],[],["loc",[null,[80,21],[80,56]]]],
              ["inline","button-correct",[],["classNames","ml5","color","#fff","size","32","showStroke",false],["loc",[null,[81,20],[81,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 16
                },
                "end": {
                  "line": 88,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["openOutgoing",["get","item.id",["loc",[null,[85,45],[85,52]]]]],[],["loc",[null,[85,21],[85,54]]]],
              ["inline","button-outgoing",[],["showStroke",false,"size",32,"classNames","","color","#fff"],["loc",[null,[86,20],[86,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 16
                },
                "end": {
                  "line": 96,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square cursor");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(element5,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["showStock",["get","item.id",["loc",[null,[93,109],[93,116]]]]],[],["loc",[null,[93,88],[93,118]]]],
              ["content","button-view",["loc",[null,[94,20],[94,35]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 115,
                    "column": 59
                  },
                  "end": {
                    "line": 115,
                    "column": 98
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" / ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","history.type",["loc",[null,[115,79],[115,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 115,
                    "column": 105
                  },
                  "end": {
                    "line": 115,
                    "column": 147
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","history.subType",["loc",[null,[115,128],[115,147]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 117,
                    "column": 62
                  },
                  "end": {
                    "line": 117,
                    "column": 92
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("+");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 118,
                    "column": 62
                  },
                  "end": {
                    "line": 118,
                    "column": 153
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","history.user.shortName",["loc",[null,[118,127],[118,153]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 120,
                      "column": 70
                    },
                    "end": {
                      "line": 120,
                      "column": 209
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","history.referenceText",["loc",[null,[120,177],[120,202]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 22
                  },
                  "end": {
                    "line": 121,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","link-to",["inventory.stocktaking-detail",["get","history.referenceId",["loc",[null,[120,112],[120,131]]]]],["class","is-highlighted"],0,null,["loc",[null,[120,70],[120,221]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 121,
                      "column": 22
                    },
                    "end": {
                      "line": 122,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","cursor is-highlighted");
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","small");
                  var el3 = dom.createTextNode("Patient ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]),1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["showPatient",["get","history.referenceId",["loc",[null,[121,140],[121,159]]]]],[],["loc",[null,[121,117],[121,161]]]],
                  ["content","history.referenceText",["loc",[null,[121,220],[121,245]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 122,
                          "column": 68
                        },
                        "end": {
                          "line": 122,
                          "column": 193
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","history.referenceText",["loc",[null,[122,161],[122,186]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 122,
                        "column": 22
                      },
                      "end": {
                        "line": 123,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["purchases.show",["get","history.referenceId",["loc",[null,[122,96],[122,115]]]]],["class","is-highlighted"],0,null,["loc",[null,[122,68],[122,205]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 123,
                          "column": 22
                        },
                        "end": {
                          "line": 124,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"class","cursor is-highlighted");
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2,"class","small");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("2");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [0]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element0);
                      morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["showInvoice",["get","history.referenceId",["loc",[null,[123,93],[123,112]]]]],[],["loc",[null,[123,70],[123,114]]]],
                      ["content","history.referenceText",["loc",[null,[123,165],[123,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 124,
                            "column": 22
                          },
                          "end": {
                            "line": 125,
                            "column": 22
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","small");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                      ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["content","history.notice",["loc",[null,[124,68],[124,86]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 124,
                          "column": 22
                        },
                        "end": {
                          "line": 125,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","history.notice",["loc",[null,[124,32],[124,46]]]]],[],0,null,["loc",[null,[124,22],[125,22]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 123,
                        "column": 22
                      },
                      "end": {
                        "line": 125,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[123,36],[123,57]]]],"sale"],[],["loc",[null,[123,32],[123,65]]]]],[],0,1,["loc",[null,[123,22],[125,22]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 122,
                      "column": 22
                    },
                    "end": {
                      "line": 125,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/stock-details.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[122,36],[122,57]]]],"order"],[],["loc",[null,[122,32],[122,66]]]]],[],0,1,["loc",[null,[122,22],[125,22]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 22
                  },
                  "end": {
                    "line": 125,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[121,40],[121,61]]]],"treatment"],[],["loc",[null,[121,36],[121,74]]]],["subexpr","eq",[["get","history.referenceType",["loc",[null,[121,79],[121,100]]]],"patient"],[],["loc",[null,[121,75],[121,111]]]]],[],["loc",[null,[121,32],[121,112]]]]],[],0,1,["loc",[null,[121,22],[125,22]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 20
                  },
                  "end": {
                    "line": 132,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/stock-details.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","cursor");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["size","28","item",["subexpr","@mut",[["get","history",["loc",[null,[130,70],[130,77]]]]],[],[]],"confirmed","bookoutMovement","text","Wollen Sie diese Lagerbewegung wirklich stornieren?","content","Lagerbewegung stornieren"],["loc",[null,[130,26],[130,201]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 18
                },
                "end": {
                  "line": 135,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/stock-details.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-1/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-5/24 pt10");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-4/24 pt10");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--action");
              dom.setAttribute(el2,"style","padding-top: 5px !important;");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [3]);
              var element4 = dom.childAt(element2, [7]);
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
              morphs[1] = dom.createMorphAt(element3,0,0);
              morphs[2] = dom.createMorphAt(element3,1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
              morphs[4] = dom.createMorphAt(element4,0,0);
              morphs[5] = dom.createMorphAt(element4,2,2);
              morphs[6] = dom.createMorphAt(element4,4,4);
              morphs[7] = dom.createMorphAt(dom.childAt(element2, [9]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element2, [11]),1,1);
              morphs[9] = dom.createMorphAt(dom.childAt(element2, [13]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","history.created",["loc",[null,[113,38],[113,53]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[113,24],[113,76]]]],
              ["block","if",[["get","history.type",["loc",[null,[115,65],[115,77]]]]],[],0,null,["loc",[null,[115,59],[115,105]]]],
              ["block","if",[["get","history.subType",["loc",[null,[115,111],[115,126]]]]],[],1,null,["loc",[null,[115,105],[115,154]]]],
              ["inline","show-movement-type",[],["value",["subexpr","@mut",[["get","history.amount",["loc",[null,[116,89],[116,103]]]]],[],[]],"classNames","movement--type","size",26],["loc",[null,[116,62],[116,141]]]],
              ["block","if",[["subexpr","gt",[["get","history.amount",["loc",[null,[117,72],[117,86]]]],0],[],["loc",[null,[117,68],[117,89]]]]],[],2,null,["loc",[null,[117,62],[117,99]]]],
              ["content","history.amount",["loc",[null,[117,100],[117,118]]]],
              ["content","item.unit.name",["loc",[null,[117,119],[117,137]]]],
              ["block","link-to",["users.edit",["get","history.user.id",["loc",[null,[118,86],[118,101]]]]],["class","is-highlighted"],3,null,["loc",[null,[118,62],[118,165]]]],
              ["block","if",[["subexpr","eq",[["get","history.referenceType",["loc",[null,[120,32],[120,53]]]],"stocktaking"],[],["loc",[null,[120,28],[120,68]]]]],[],4,5,["loc",[null,[120,22],[125,29]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","history.cancelled",["loc",[null,[128,36],[128,53]]]]],[],["loc",[null,[128,31],[128,54]]]],["subexpr","not",[["get","history.wasCancelled",["loc",[null,[128,60],[128,80]]]]],[],["loc",[null,[128,55],[128,81]]]],["get","showActionButtons",["loc",[null,[128,82],[128,99]]]]],[],["loc",[null,[128,26],[128,100]]]]],[],6,null,["loc",[null,[128,20],[132,27]]]]
            ],
            locals: ["history"],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 10
              },
              "end": {
                "line": 140,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/stock-details.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--toggle l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-2/24@desk table__cell--center");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createElement("b");
            var el4 = dom.createElement("span");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("/");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"style","padding-right: 50px");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","d-flex align-items-center");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","form-field-rework mb-0");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("label");
            dom.setAttribute(el5,"for","chargeNumber");
            dom.setAttribute(el5,"class","placeholder required");
            var el6 = dom.createTextNode("Charge-Nr");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","icon-button icon-button--default list-action-square cursor");
            dom.setAttribute(el4,"style","position: absolute;right:0");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("img");
            dom.setAttribute(el5,"src","assets/images/icons/rework/qr.svg");
            dom.setAttribute(el5,"title","Datamatrix-QR-Code scannen");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk pt15");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework mb-0");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","placeholder");
            var el5 = dom.createTextNode("Datum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk pt15");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework mb-0");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","chargeNumber");
            dom.setAttribute(el4,"class","placeholder required");
            var el5 = dom.createTextNode("Lagerplatz");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk table__cell--action buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square cursor");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24@desk table__second-row hidden");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pl20 box no-box@phone table table--invoice pr0");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Datum");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Typ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-1/24");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Bewegte Menge");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-5/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("MitarbeiterIn");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-4/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title small title");
            var el7 = dom.createTextNode("Referenz");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-2/24");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [5]);
            var element10 = dom.childAt(element9, [0, 0]);
            var element11 = dom.childAt(element8, [9]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element12, [3]);
            var element14 = dom.childAt(element8, [15]);
            var element15 = dom.childAt(element14, [4]);
            var element16 = dom.childAt(element8, [17]);
            var morphs = new Array(20);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
            morphs[2] = dom.createAttrMorph(element9, 'class');
            morphs[3] = dom.createAttrMorph(element10, 'class');
            morphs[4] = dom.createMorphAt(element10,0,0);
            morphs[5] = dom.createMorphAt(element10,2,2);
            morphs[6] = dom.createMorphAt(element9,2,2);
            morphs[7] = dom.createMorphAt(element8,7,7);
            morphs[8] = dom.createAttrMorph(element11, 'class');
            morphs[9] = dom.createMorphAt(dom.childAt(element12, [1]),1,1);
            morphs[10] = dom.createElementMorph(element13);
            morphs[11] = dom.createMorphAt(dom.childAt(element8, [11, 1]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element8, [13, 1]),1,1);
            morphs[13] = dom.createMorphAt(element14,1,1);
            morphs[14] = dom.createMorphAt(element14,2,2);
            morphs[15] = dom.createElementMorph(element15);
            morphs[16] = dom.createMorphAt(element15,1,1);
            morphs[17] = dom.createMorphAt(element14,6,6);
            morphs[18] = dom.createAttrMorph(element16, 'data-id');
            morphs[19] = dom.createMorphAt(dom.childAt(element16, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","item",["loc",[null,[47,58],[47,62]]]]],[],[]],"toggle","toggle","content","Lagerbewegungen anzeigen"],["loc",[null,[47,36],[47,115]]]],
            ["content","item.id",["loc",[null,[49,78],[49,89]]]],
            ["attribute","class",["concat",["table__cell l-8/24 l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[50,68],[50,87]]]],"3","4"],[],["loc",[null,[50,47],[50,97]]]],"/24@desk"]]],
            ["attribute","class",["concat",[["subexpr","get-stock-color",[["get","item.flatStock",["loc",[null,[50,141],[50,155]]]],["get","item.packageQuantity",["loc",[null,[50,156],[50,176]]]]],[],["loc",[null,[50,123],[50,178]]]]]]],
            ["inline","format-number-german",[["get","item.flatStock",["loc",[null,[50,203],[50,217]]]]],[],["loc",[null,[50,180],[50,219]]]],
            ["content","item.packageQuantity",["loc",[null,[50,220],[50,244]]]],
            ["content","item.unit.name",["loc",[null,[50,256],[50,274]]]],
            ["block","if",[["get","showWarehouseInLine",["loc",[null,[51,18],[51,37]]]]],[],0,null,["loc",[null,[51,12],[53,27]]]],
            ["attribute","class",["concat",["table__cell l-8/24 l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[54,68],[54,87]]]],"4","6"],[],["loc",[null,[54,47],[54,97]]]],"/24@desk pt15"]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.batchNumber",["loc",[null,[57,34],[57,50]]]]],[],[]],"id",["subexpr","concat",["batch",["get","item.id",["loc",[null,[57,70],[57,77]]]]],[],["loc",[null,[57,54],[57,78]]]],"name","chargeNumber","focus-out",["subexpr","action",["updateBatchNumber",["get","item",["loc",[null,[57,137],[57,141]]]]],[],["loc",[null,[57,109],[57,142]]]]],["loc",[null,[57,20],[57,144]]]],
            ["element","action",["openScan",["get","item",["loc",[null,[61,41],[61,45]]]]],[],["loc",[null,[61,21],[61,47]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.expiryDate",["loc",[null,[68,32],[68,47]]]]],[],[]],"class","js-date-picker","name","expiryDate","focus-out",["subexpr","action",["updateExpiry",["get","item",["loc",[null,[68,122],[68,126]]]]],[],["loc",[null,[68,99],[68,127]]]]],["loc",[null,[68,18],[68,129]]]],
            ["inline","input",[],["value",["subexpr","@mut",[["get","item.locationInfo",["loc",[null,[74,32],[74,49]]]]],[],[]],"name","locationInfo","autocomplete","off","focus-out",["subexpr","action",["updateLocation",["get","item",["loc",[null,[74,124],[74,128]]]]],[],["loc",[null,[74,99],[74,129]]]]],["loc",[null,[74,18],[74,131]]]],
            ["block","if",[["get","showCorrectionButton",["loc",[null,[79,22],[79,42]]]]],[],1,null,["loc",[null,[79,16],[83,23]]]],
            ["block","if",[["get","showActionButtons",["loc",[null,[84,22],[84,39]]]]],[],2,null,["loc",[null,[84,16],[88,23]]]],
            ["element","action",["printBarcode",["get","item.id",["loc",[null,[89,110],[89,117]]]]],[],["loc",[null,[89,86],[89,119]]]],
            ["inline","button-qr",[],["content","Etiketten herunterladen"],["loc",[null,[90,18],[90,65]]]],
            ["block","if",[["get","showInventoryDetailsButton",["loc",[null,[92,22],[92,48]]]]],[],3,null,["loc",[null,[92,16],[96,23]]]],
            ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[98,88],[98,95]]]]]]],
            ["block","each",[["get","item.movements",["loc",[null,[110,26],[110,40]]]]],[],4,null,["loc",[null,[110,18],[135,27]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 6
            },
            "end": {
              "line": 143,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table table--large grid no-hover mt-30");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__head");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-1/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24 table__cell--center");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Inv-Nr");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Verfügbar");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Chargen-Nr");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24 ");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Ablaufdatum");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-4/24");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","table__title title");
          var el5 = dom.createTextNode("Lagerplatz");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__cell l-2/24");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(element17, [1]);
          var element19 = dom.childAt(element18, [5]);
          var element20 = dom.childAt(element18, [9]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createMorphAt(element18,7,7);
          morphs[2] = dom.createAttrMorph(element20, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element17, [3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[36,57],[36,76]]]],"3","4"],[],["loc",[null,[36,36],[36,86]]]],"/24"]]],
          ["block","if",[["get","showWarehouseInLine",["loc",[null,[37,16],[37,35]]]]],[],0,null,["loc",[null,[37,10],[37,147]]]],
          ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showWarehouseInLine",["loc",[null,[38,57],[38,76]]]],"4","6"],[],["loc",[null,[38,36],[38,86]]]],"/24"]]],
          ["block","each",[["get","items",["loc",[null,[44,18],[44,23]]]]],[],1,null,["loc",[null,[44,10],[140,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 6
            },
            "end": {
              "line": 145,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/stock-details.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        Keine Lagerbestände\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 183,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/stock-details.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","box");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n      $(\"body\").delegate(\".js-date-picker\", \"focusin\", function(){\n        $(this).daterangepicker({\n          singleDatePicker: true,\n          showDropdowns: true,\n          \"locale\": {\n            \"format\": \"DD. MM. YYYY\",\n            \"daysOfWeek\": [\n              \"So\",\n              \"Mo\",\n              \"Di\",\n              \"Mi\",\n              \"Do\",\n              \"Fr\",\n              \"Sa\"\n            ],\n            \"monthNames\": [\n              \"Jänner\",\n              \"Februar\",\n              \"März\",\n              \"April\",\n              \"Mai\",\n              \"Juni\",\n              \"Juli\",\n              \"August\",\n              \"September\",\n              \"Oktober\",\n              \"November\",\n              \"Dezember\"\n            ],\n            \"firstDay\": 1\n          },\n        });\n      });\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element25,1,1);
        morphs[1] = dom.createMorphAt(element25,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showHeader",["loc",[null,[2,12],[2,22]]]]],[],0,null,["loc",[null,[2,6],[29,13]]]],
        ["block","if",[["get","items",["loc",[null,[31,12],[31,17]]]]],[],1,2,["loc",[null,[31,6],[145,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
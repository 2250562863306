define('jason-frontend/controllers/sale/etigacampaigns', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "eltiga Kampagnen",
    breadCrumbPath: "sale.etigacampaigns",
    filterPatientCategory: null,
    filterGender: null,
    showFilters: true,
    myStickyOptionsTableHead: {
      topSpacing: API['default'].stickyTopSpacing,
      zIndex: 9
    },
    myStickyOptionsTableHeadCollapsed: {
      topSpacing: API['default'].stickyTopSpacingCollapsed,
      zIndex: 9
    },
    ages: [{
      id: "1",
      value: "1 Jahr"
    }, {
      id: "2",
      value: "2 Jahre"
    }, {
      id: "3",
      value: "3 Jahre"
    }, {
      id: "4",
      value: "4 Jahre"
    }, {
      id: "5", value: "5 Jahre"
    }, {
      id: "6", value: "6 Jahre"
    }, {
      id: "7", value: "7 Jahre"
    }, {
      id: "8", value: "8 Jahre"
    }, {
      id: "9", value: "9 Jahre"
    }, {
      id: "10", value: "10 Jahre"
    }, {
      id: "11", value: "11 Jahre"
    }, {
      id: "12", value: "12 Jahre"
    }, {
      id: "13", value: "13 Jahre"
    }, {
      id: "14", value: "14 Jahre"
    }, {
      id: "15", value: "15 Jahre"
    }, {
      id: "16", value: "16 Jahre"
    }, {
      id: "17", value: "17 Jahre"
    }, {
      id: "18", value: "18 Jahre"
    }, {
      id: "19", value: "19 Jahre"
    }, {
      id: "20", value: "20 Jahre"
    }, {
      id: "21", value: "21 Jahre"
    }, {
      id: "22", value: "22 Jahre"
    }, {
      id: "23", value: "23 Jahre"
    }, {
      id: "24", value: "24 Jahre"
    }, {
      id: "25", value: "25 Jahre"
    }, {
      id: "26", value: "26 Jahre"
    }, {
      id: "27", value: "27 Jahre"
    }, {
      id: "28", value: "28 Jahre"
    }, {
      id: "29", value: "29 Jahre"
    }, {
      id: "30", value: "30 Jahre"
    }, {
      id: "31", value: "31 Jahre"
    }, {
      id: "32", value: "32 Jahre"
    }, {
      id: "33", value: "33 Jahre"
    }, {
      id: "34", value: "34 Jahre"
    }, {
      id: "35", value: "35 Jahre"
    }, {
      id: "36", value: "36 Jahre"
    }, {
      id: "37", value: "37 Jahre"
    }, {
      id: "38", value: "38 Jahre"
    }, {
      id: "39", value: "39 Jahre"
    }, {
      id: "40", value: "40 Jahre"
    }, {
      id: "41", value: "41 Jahre"
    }, {
      id: "42", value: "42 Jahre"
    }, {
      id: "43", value: "43 Jahre"
    }, {
      id: "44", value: "44 Jahre"
    }, {
      id: "45", value: "45 Jahre"
    }, {
      id: "46", value: "46 Jahre"
    }, {
      id: "47", value: "47 Jahre"
    }, {
      id: "48", value: "48 Jahre"
    }, {
      id: "49", value: "49 Jahre"
    }, {
      id: "50", value: "50 Jahre"
    }],
    showPanel: function showPanel(id) {

      var self = this;
      $.ajax({
        url: "/api/etigaCampaigns/" + id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('showEtigaCampaignPanel').send('load', data.etigaCampaign);
      }, function (error) {});

      $.magnificPopup.open({
        removalDelay: 500,
        closeOnBgClick: false,
        items: {
          src: '#modal-show-etiga-campaign-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      showPanel: function showPanel(id) {
        this.showPanel(id);
      },
      openPanel: function openPanel() {

        this.get('editEtigaCampaignPanel').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-etiga-campaign-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editPanel: function editPanel(item) {

        var self = this;

        $.ajax({
          url: "/api/etigaCampaigns/" + item.id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('editEtigaCampaignPanel').send('loadRecord', data.etigaCampaign);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-etiga-campaign-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reload: function reload(id) {
        this.showPanel(id);
      }
    },
    watchDate: (function () {
      this.send('applyFilter');
    }).observes('filterPatientCategory'),
    watchGender: (function () {
      this.send('applyFilter');
    }).observes('filterGender'),
    watchAge: (function () {
      this.send('applyFilter');
    }).observes('filterAge'),
    watchAgeTo: (function () {
      this.send('applyFilter');
    }).observes('filterAgeTo')
  });

});
define('jason-frontend/templates/practicemgmt/station', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 18,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createMorphAt(element4,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[16,61],[16,77]]]],"selected",""],[],["loc",[null,[16,40],[16,93]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[17,104],[17,111]]]]],[],["loc",[null,[16,95],[17,113]]]],
            ["content","station.name",["loc",[null,[17,114],[17,130]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 48
                      },
                      "end": {
                        "line": 33,
                        "column": 83
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Frei");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 33,
                          "column": 83
                        },
                        "end": {
                          "line": 33,
                          "column": 128
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Belegt");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 83
                      },
                      "end": {
                        "line": 33,
                        "column": 128
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","cage.status",["loc",[null,[33,97],[33,108]]]],"occupied"],[],["loc",[null,[33,93],[33,120]]]]],[],0,null,["loc",[null,[33,83],[33,128]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 40,
                            "column": 30
                          },
                          "end": {
                            "line": 41,
                            "column": 152
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode(" ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["content","cage.visit.patient.customer.firstname",["loc",[null,[41,70],[41,111]]]],
                        ["content","cage.visit.patient.customer.lastname",["loc",[null,[41,112],[41,152]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 44,
                            "column": 26
                          },
                          "end": {
                            "line": 44,
                            "column": 254
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("i");
                        dom.setAttribute(el1,"class","animal-icon");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","cage.visit.patient.category.id",["loc",[null,[44,116],[44,146]]]]],[],[]],"race-id",["subexpr","@mut",[["get","cage.visit.patient.digitalRace.icon",["loc",[null,[44,155],[44,190]]]]],[],[]],"content",["subexpr","@mut",[["get","cage.visit.patient.category.name",["loc",[null,[44,199],[44,231]]]]],[],[]],"classNames","mr5"],["loc",[null,[44,84],[44,250]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 45,
                            "column": 26
                          },
                          "end": {
                            "line": 45,
                            "column": 150
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","cage.visit.patient.name",["loc",[null,[45,95],[45,118]]]]],[],[]],"content",["subexpr","@mut",[["get","cage.visit.patient.id",["loc",[null,[45,127],[45,148]]]]],[],[]]],["loc",[null,[45,76],[45,150]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 46,
                            "column": 26
                          },
                          "end": {
                            "line": 46,
                            "column": 193
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[46,64],[46,193]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 39,
                          "column": 22
                        },
                        "end": {
                          "line": 48,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [3]);
                      var morphs = new Array(5);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      morphs[1] = dom.createElementMorph(element0);
                      morphs[2] = dom.createMorphAt(element0,1,1);
                      morphs[3] = dom.createMorphAt(element0,3,3);
                      morphs[4] = dom.createMorphAt(element0,5,5);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["customers.edit",["get","cage.visit.patient.customer.id",["loc",[null,[40,58],[40,88]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[40,30],[41,164]]]],
                      ["element","action",["gotoPatient",["get","cage.visit.patient.id",["loc",[null,[43,108],[43,129]]]]],["bubbles",true],["loc",[null,[43,85],[43,144]]]],
                      ["block","if",[["get","cage.visit.patient.category",["loc",[null,[44,32],[44,59]]]]],[],1,null,["loc",[null,[44,26],[44,261]]]],
                      ["block","link-to",["patients.edit",["get","cage.visit.patient.id",["loc",[null,[45,53],[45,74]]]]],[],2,null,["loc",[null,[45,26],[45,162]]]],
                      ["block","if",[["get","cage.visit.patient.etigaActive",["loc",[null,[46,32],[46,62]]]]],[],3,null,["loc",[null,[46,26],[46,200]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 20
                      },
                      "end": {
                        "line": 53,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","mt10");
                    var el2 = dom.createTextNode("\n                        Auf Station seit: ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),0,0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","cage.visit.patient",["loc",[null,[39,28],[39,46]]]]],[],0,null,["loc",[null,[39,22],[48,29]]]],
                    ["inline","time-format",[["get","cage.visit.start",["loc",[null,[51,62],[51,78]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[51,48],[51,101]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 12
                    },
                    "end": {
                      "line": 57,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-8/24@desk l-12/24@tablet l-24/24 gutter");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","header");
                  var el4 = dom.createTextNode("\n                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4,"class","d-flex align-items-center");
                  var el5 = dom.createTextNode("\n                      ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("h3");
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                      ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("div");
                  var el6 = dom.createTextNode("\n                        ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createElement("span");
                  dom.setAttribute(el6,"class","textBelow");
                  var el7 = dom.createComment("");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                      ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                  ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","body");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("                  ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1, 1]);
                  var element2 = dom.childAt(element1, [1, 1]);
                  var element3 = dom.childAt(element2, [3]);
                  var morphs = new Array(6);
                  morphs[0] = dom.createAttrMorph(element1, 'class');
                  morphs[1] = dom.createElementMorph(element1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
                  morphs[3] = dom.createAttrMorph(element3, 'class');
                  morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                  morphs[5] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["card cage-card status-",["get","cage.status",["loc",[null,[28,52],[28,63]]]]," ",["subexpr","css-bool-evaluator",[["get","cage.visit",["loc",[null,[28,87],[28,97]]]],"cursor",""],[],["loc",[null,[28,66],[28,111]]]]]]],
                  ["element","action",["selectCage",["get","cage",["loc",[null,[28,135],[28,139]]]]],[],["loc",[null,[28,113],[28,141]]]],
                  ["content","cage.name",["loc",[null,[31,26],[31,39]]]],
                  ["attribute","class",["concat",["status-badge ",["get","cage.status",["loc",[null,[32,49],[32,60]]]]]]],
                  ["block","if",[["subexpr","eq",[["get","cage.status",["loc",[null,[33,58],[33,69]]]],"free"],[],["loc",[null,[33,54],[33,77]]]]],[],0,1,["loc",[null,[33,48],[33,135]]]],
                  ["block","if",[["get","cage.visit",["loc",[null,[38,26],[38,36]]]]],[],2,null,["loc",[null,[38,20],[53,27]]]]
                ],
                locals: ["cage"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 8
                  },
                  "end": {
                    "line": 59,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","grid");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","station.cages",["loc",[null,[26,20],[26,33]]]]],[],0,null,["loc",[null,[26,12],[57,21]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 8
                  },
                  "end": {
                    "line": 61,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Derzeit keine Boxen hinterlegt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 6
                },
                "end": {
                  "line": 62,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1,"class","mt-10 mb-10");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","station.name",["loc",[null,[23,32],[23,48]]]],
              ["block","if",[["subexpr","list-not-empty",[["get","station.cages",["loc",[null,[24,30],[24,43]]]]],[],["loc",[null,[24,14],[24,44]]]]],[],0,1,["loc",[null,[24,8],[61,15]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","allStationsSelected",["loc",[null,[22,16],[22,35]]]],["get","station.selected",["loc",[null,[22,36],[22,52]]]]],[],["loc",[null,[22,12],[22,53]]]]],[],0,null,["loc",[null,[22,6],[62,13]]]]
          ],
          locals: ["station"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 66,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1,"class","site-content card");
          dom.setAttribute(el1,"id","stations");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ms-0 quick-filter");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("Stationen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Alle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element7, 'class');
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(element6,5,5);
          morphs[3] = dom.createMorphAt(element5,3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","allStationsSelected",["loc",[null,[12,59],[12,78]]]],"selected",""],[],["loc",[null,[12,38],[12,94]]]]]]],
          ["element","action",["toggleAllStations"],[],["loc",[null,[12,96],[13,29]]]],
          ["block","each",[["get","stations",["loc",[null,[15,14],[15,22]]]]],[],0,null,["loc",[null,[15,6],[18,15]]]],
          ["block","each",[["get","stations",["loc",[null,[21,12],[21,20]]]]],[],1,null,["loc",[null,[21,4],[63,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[69,10],[69,15]]]]],[],[]],"showStation",true,"showStationFilter",true,"stations",["subexpr","@mut",[["get","stations",["loc",[null,[72,13],[72,21]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[73,18],[73,31]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[74,21],[74,29]]]]],[],[]],"movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToWaitingRoom","movePatientToWaitingRoom","abort","abort","exportInvoice","exportInvoice","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[68,2],[80,4]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/station.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Stationen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","is-station-extended-enabled",[],[],["loc",[null,[7,6],[7,35]]]]],[],0,1,["loc",[null,[7,0],[81,7]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[83,67],[83,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[83,84],[83,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[83,103],[83,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[84,44],[84,62]]]]],[],[]]],["loc",[null,[83,0],[84,64]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
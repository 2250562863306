define('jason-frontend/components/eltiga-customer-badge', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    side: 'top',
    classNames: ['d-flex align-items-center ms-5'],
    iconClass: 'max-h-20px',
    status: 'active',
    content: 'eltiga App Benutzer:in',
    theme: 'tooltipster-punk'
  });

});
define('jason-frontend/controllers/invoices/open', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Offene Posten",
    breadCrumbPath: "invoices.open",
    queryParams: ["page", "perPage", "filterCode", "customerId"],
    pickDate: moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"),
    filterStatus: "",
    showFilters: true,
    filterCode: "",
    filterCustomer: null,
    customerId: null,
    sortBy: 'created',
    sortDir: 'desc',

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    filterPaymentStatusList: [{ value: "Teilweise bezahlt", id: "partpaid" }, { value: "Nicht bezahlt", id: "notpaid" }],

    filterStatusList: [{ value: "Ausgestellt", id: "to_be_verified" }, { value: "1. Mahnung", id: "warning1" }, { value: "2. Mahnung", id: "warning2" }, { value: "Bereit für Inkasso", id: "ready_for_money_collection" }, { value: "An Rechtsanwalt übergeben", id: "lawyer" }, { value: "An Inkasso übergeben", id: "money_collection" }, { value: "Keine Mahnung", id: "no_warning" }, { value: "SEPA", id: "sepa" }],
    myStickyOptionsTableHead: {
      topSpacing: API['default'].stickyTopSpacing,
      zIndex: 9
    },
    myStickyOptionsTableHeadCollapsed: {
      topSpacing: API['default'].stickyTopSpacingCollapsed,
      zIndex: 9
    },
    page: 1,
    perPage: 10,
    actions: {
      downloadWarning: function downloadWarning(invoice) {
        var self = this;
        self.get('downloadInvoicePanel').send('load', invoice);

        $.ajax({
          url: "/api/editor/invoice/printTemplates",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('downloadInvoicePanel').send('loadPrintTemplates', data.templateStyle);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-download-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      forwardWarning: function forwardWarning(invoice) {
        var self = this;

        if (invoice.get('customer')) {
          $.ajax({
            url: "/api/customers/" + invoice.get('customer').id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (customerData) {
            self.get('forwardInvoicePanel').send('load', invoice, customerData.customer.emails);
          });
        }

        $.ajax({
          url: "/api/editor/invoice/printTemplates",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('forwardInvoicePanel').send('loadPrintTemplates', data.templateStyle);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-forward-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editPaymentAgreement: function editPaymentAgreement(invoice) {
        var self = this;
        self.get('paymentAgreementPanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-payment-agreement-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: false
        });
      },
      changeStatus: function changeStatus(invoice) {
        var self = this;
        self.get('changeStatusPanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-invoice-status-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editWarningInfo: function editWarningInfo(invoice) {
        var self = this;
        self.get('warningInfoPanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-warning-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editWarningText: function editWarningText(invoice) {
        var self = this;
        self.get('warningAddTextPanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-warning-add-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editStatusChange: function editStatusChange(invoice) {
        var self = this;
        self.get('nextStatusChangePanel').send('load', invoice);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-invoice-next-status-change-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchCustomer: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterCustomer'),
    watchPaymentStatus: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterPaymentStatus'),
    watchStatusList: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('filterStatus'),
    watchDate: (function () {
      var self = this;
      this.set('page', 1);
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    }).observes('pickDate')
  });

});
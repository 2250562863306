define('jason-frontend/templates/components/invoice-payment-chooser', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-3/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("EC - Terminal");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 29,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","row0");
          dom.setAttribute(el1,"data-id","0");
          dom.setAttribute(el1,"class","js-table__row table__row rows deposit");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  text-center l-8/24@tablet l-3/24@desk open payment-label");
          dom.setAttribute(el2,"data-label","Offen");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell text-center l-8/24@tablet l-3/24@desk given payment-label");
          dom.setAttribute(el2,"data-label","Gegeben");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3,"type","text");
          dom.setAttribute(el3,"value","0");
          dom.setAttribute(el3,"class","input input--editable given-input");
          dom.setAttribute(el3,"name","given0");
          dom.setAttribute(el3,"autocomplete","off");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  text-center l-8/24@tablet l-3/24@desk change payment-label");
          dom.setAttribute(el2,"data-label","Wechselgeld");
          dom.setAttribute(el2,"data-change","0");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-14/24@desk payment-types payment-label");
          dom.setAttribute(el2,"data-label","Zahlungsmittel");
          dom.setAttribute(el2,"id","deposit-label");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","cash hidden");
          dom.setAttribute(el3,"for","cash0");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("input");
          dom.setAttribute(el4,"class","cash");
          dom.setAttribute(el4,"name","paymentType0");
          dom.setAttribute(el4,"type","radio");
          dom.setAttribute(el4,"id","cash0");
          dom.setAttribute(el4,"value","cash");
          dom.setAttribute(el4,"checked","");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              Gesamtguthaben ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(", Saldo: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [7]);
          if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element3, [1, 1]),[],true); }
          var element4 = dom.childAt(element3, [8]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'data-open');
          morphs[1] = dom.createAttrMorph(element2, 'data-deposit');
          morphs[2] = dom.createMorphAt(element2,0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [6]),0,0);
          morphs[4] = dom.createAttrMorph(element4, 'class');
          morphs[5] = dom.createMorphAt(element4,0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-open",["concat",[["get","open",["loc",[null,[16,125],[16,129]]]]]]],
          ["attribute","data-deposit",["concat",[["get","depositSum",["loc",[null,[16,149],[16,159]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","open",["loc",[null,[16,187],[16,191]]]]],[],[]]],["loc",[null,[16,163],[16,193]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[26,167],[26,177]]]]],[],[]]],["loc",[null,[26,143],[26,179]]]],
          ["attribute","class",["concat",["title ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[26,239],[26,249]]]],0],[],["loc",[null,[26,235],[26,252]]]],"is-negative",""],[],["loc",[null,[26,214],[26,271]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[26,297],[26,307]]]]],[],[]]],["loc",[null,[26,273],[26,309]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 8
            },
            "end": {
              "line": 86,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-8/24@tablet l-3/24@desk table__cell--action terminal-label");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dropdown terminalDisabled");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--success list-action-square dropdown");
          dom.setAttribute(el3,"data-toggle","dropdown");
          dom.setAttribute(el3,"aria-haspopup","true");
          dom.setAttribute(el3,"aria-expanded","false");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/ecterminal.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","sep");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/dropdown-arrow.svg");
          dom.setAttribute(el4,"class","dropdown-arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dropdown-menu");
          dom.setAttribute(el3,"aria-labelledby","dropdownMenuButton");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","dropdown-item terminal");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"src","assets/images/icons/rework/send-dark.svg");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode(" Betrag senden");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 97,
              "column": 2
            },
            "end": {
              "line": 101,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["registerPayments"],[],["loc",[null,[98,69],[98,98]]]],
          ["inline","button-next",[],["size","52","color","#ffffff","content","Kassieren"],["loc",[null,[99,8],[99,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 103,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-payment-chooser.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table-wrapper-rework with-default-empty-line");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table grid table--payment");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__head");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell  text-center l-3/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Offen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell text-center l-3/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Gegeben");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell text-center l-3/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Wechselgeld");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-11/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Zahlungsmittel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        dom.setAttribute(el3,"id","transaction-input");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"id","init-open");
        dom.setAttribute(el4,"style","visibility:hidden;position:absolute;left:-9999%;");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","row1");
        dom.setAttribute(el4,"data-id","1");
        dom.setAttribute(el4,"class","js-table__row table__row rows");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell text-center l-8/24@tablet l-3/24@desk open payment-label");
        dom.setAttribute(el5,"data-label","Offen");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell text-center l-8/24@tablet l-3/24@desk given payment-label");
        dom.setAttribute(el5,"data-label","Gegeben");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"type","text");
        dom.setAttribute(el6,"class","input input--editable given-input");
        dom.setAttribute(el6,"name","given1");
        dom.setAttribute(el6,"autocomplete","off");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell text-center l-8/24@tablet l-3/24@desk change payment-label");
        dom.setAttribute(el5,"data-change","0");
        dom.setAttribute(el5,"data-label","Wechselgeld");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","payment-types table__cell l-11/24@desk");
        dom.setAttribute(el5,"data-label","Zahlungsmittel");
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"class","button--toggle__input cash");
        dom.setAttribute(el6,"name","paymentType1");
        dom.setAttribute(el6,"type","radio");
        dom.setAttribute(el6,"id","cash1");
        dom.setAttribute(el6,"value","cash");
        dom.setAttribute(el6,"checked","");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","cash button button--toggle me-10");
        dom.setAttribute(el6,"for","cash1");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","nonhover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/cash_new_colorful.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","hover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/cash_new.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Bargeld");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"class","button--toggle__input cashcard");
        dom.setAttribute(el6,"name","paymentType1");
        dom.setAttribute(el6,"id","cashcard1");
        dom.setAttribute(el6,"value","cashcard");
        dom.setAttribute(el6,"type","radio");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","cashcard button button--toggle me-10 ");
        dom.setAttribute(el6,"for","cashcard1");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","nonhover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/debit_card_colorful.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","hover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/debit_card.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("EC-Karte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"class","button--toggle__input creditcard");
        dom.setAttribute(el6,"name","paymentType1");
        dom.setAttribute(el6,"id","creditcard1");
        dom.setAttribute(el6,"value","creditcard");
        dom.setAttribute(el6,"type","radio");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","button button--toggle me-10 creditcard");
        dom.setAttribute(el6,"for","creditcard1");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","nonhover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/credit_card_colorful.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","hover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/credit_card.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Kreditkarte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6,"class","button--toggle__input voucher");
        dom.setAttribute(el6,"name","paymentType1");
        dom.setAttribute(el6,"id","voucher1");
        dom.setAttribute(el6,"value","voucher");
        dom.setAttribute(el6,"type","radio");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","voucher button button--toggle me-10");
        dom.setAttribute(el6,"for","voucher1");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","nonhover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/voucher_colorful.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"class","hover");
        dom.setAttribute(el7,"src","assets/images/icons/rework/voucher.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Gutschein");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-1/24 remove");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element6, [5]);
        var element8 = dom.childAt(element7, [1]);
        if (this.cachedFragment) { dom.repairClonedNode(dom.childAt(element7, [7, 1]),[],true); }
        var element9 = dom.childAt(fragment, [2]);
        var element10 = dom.childAt(element9, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),9,9);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[2] = dom.createMorphAt(element6,3,3);
        morphs[3] = dom.createAttrMorph(element8, 'data-open');
        morphs[4] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[5] = dom.createMorphAt(element7,9,9);
        morphs[6] = dom.createElementMorph(element10);
        morphs[7] = dom.createMorphAt(element10,1,1);
        morphs[8] = dom.createMorphAt(element9,3,3);
        return morphs;
      },
      statements: [
        ["block","if",[["get","terminalActivated",["loc",[null,[8,14],[8,31]]]]],[],0,null,["loc",[null,[8,8],[10,15]]]],
        ["content","open",["loc",[null,[13,84],[13,92]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[14,16],[14,26]]]],0],[],["loc",[null,[14,12],[14,29]]]]],[],1,null,["loc",[null,[14,6],[29,13]]]],
        ["attribute","data-open",["concat",[["get","open",["loc",[null,[32,122],[32,126]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","open",["loc",[null,[33,49],[33,53]]]]],[],[]]],["loc",[null,[33,25],[33,55]]]],
        ["block","if",[["get","terminalActivated",["loc",[null,[70,14],[70,31]]]]],[],2,null,["loc",[null,[70,8],[86,15]]]],
        ["element","action",["toSelectPositions"],[],["loc",[null,[94,7],[94,37]]]],
        ["content","button-prev",["loc",[null,[95,6],[95,21]]]],
        ["block","if",[["get","submitButtonEnabled",["loc",[null,[97,8],[97,27]]]]],[],3,null,["loc",[null,[97,2],[101,9]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
define('jason-frontend/components/icon-etiga', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    size: 18,
    side: 'bottom',
    timer: 0,
    width: 150,
    content: 'mit eltiga verbunden',
    theme: 'tooltipster-punk',
    connected: true
  });

});
define('jason-frontend/routes/stables/index', ['exports', 'ember', 'jason-frontend/helpers/is-journal-enabled', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, is_journal_enabled, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model(params) {},
    perPage: 10,
    queryParams: {},
    updateTooltips: function updateTooltips() {
      $('.tooltipstered').tooltipster({
        theme: 'tooltipster-punk',
        delay: 0,
        side: 'top',
        touchDevices: false
      });
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller');
      var self = this;
      this.findPaged('stable', {
        filterName: controller.get('filterName'),
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
        setTimeout(function () {
          self.updateTooltips();
        }, 500);
      });
    },
    setupController: function setupController(controller, model) {
      this.applyFilter();
    },
    actions: {
      clickPatient: function clickPatient(id) {
        this.transitionTo('patients.edit', id);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      reload: function reload() {
        this.applyFilter();
      },
      selectPatient: function selectPatient(stableId, id) {
        var self = this;
        $.ajax({
          url: "/api/stables/" + stableId + "/addPatient/" + id,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        });
      },
      removePatient: function removePatient(stableId, id) {
        var self = this;
        $.ajax({
          url: "/api/stables/" + stableId + "/addPatient/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        });
      },
      addPatient: function addPatient(id) {
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-stable-select-customer-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('controller').get('stableSelectCustomerPanel').send('load', id, this);
      },
      gotoHistory: function gotoHistory(patient) {
        var tab = 'history';
        if (is_journal_enabled.isJournalEnabled()) {
          tab = 'activities';
        }
        this.transitionTo('patients.edit', patient.id, { queryParams: { tab: tab } });
      },
      "delete": function _delete(id) {
        var self = this;
        $.ajax({
          url: "/api/stables/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.applyFilter();
        });
      }
    }
  });

});
define('jason-frontend/components/contact-info-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    show: function show(id) {},
    actions: {
      load: function load(data) {
        this.set('data', data);
      }
    }
  });

});
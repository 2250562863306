define('jason-frontend/models/treatment-station-visit', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    offerId: DS['default'].attr('number'),
    treatmentPatientId: DS['default'].attr(''),
    start: DS['default'].attr('date'),
    end: DS['default'].attr('date'),
    station: DS['default'].attr(''),
    cage: DS['default'].attr(''),
    patient: DS['default'].attr(''),
    title: DS['default'].attr(''),
    notice: DS['default'].attr(''),
    patientOwner: DS['default'].attr(''),
    editable: DS['default'].attr(''),
    open: DS['default'].attr('')
  });

});
define('jason-frontend/templates/components/notification-center-calendar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","badge badge-danger");
            dom.setAttribute(el1,"style","background-color: #e4003a");
            var el2 = dom.createTextNode("5+");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 8,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","badge badge-danger");
            dom.setAttribute(el1,"style","background-color: #e4003a");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","unreadMessagesCount",["loc",[null,[7,73],[7,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","gt",[["get","unreadMessagesCount",["loc",[null,[4,14],[4,33]]]],5],[],["loc",[null,[4,10],[4,36]]]]],[],0,1,["loc",[null,[4,4],[8,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 6
            },
            "end": {
              "line": 23,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"id","readAllButtonEtiga");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/notifications-check.svg");
          dom.setAttribute(el2,"title","Alle als gelesen markieren");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["element","action",["markAllAsRead"],[],["loc",[null,[20,11],[20,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 20
                },
                "end": {
                  "line": 51,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 26
                  },
                  "end": {
                    "line": 54,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small block");
                var el2 = dom.createTextNode("Patient: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.patient.name",["loc",[null,[53,63],[53,84]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 26
                  },
                  "end": {
                    "line": 57,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small block");
                var el2 = dom.createTextNode("Besitzer: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element1,1,1);
                morphs[1] = dom.createMorphAt(element1,3,3);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[56,64],[56,91]]]],
                ["content","item.customer.lastname",["loc",[null,[56,92],[56,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 20
                },
                "end": {
                  "line": 58,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.patient",["loc",[null,[52,32],[52,44]]]]],[],0,null,["loc",[null,[52,26],[54,33]]]],
              ["block","if",[["get","item.customer",["loc",[null,[55,32],[55,45]]]]],[],1,null,["loc",[null,[55,26],[57,33]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 12
              },
              "end": {
                "line": 71,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","timeline-item notification-entry cursor");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-icon etiga");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-desc");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","mb0 pr60");
            var el4 = dom.createElement("b");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","readButton cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/check-circle-full.svg");
            dom.setAttribute(el4,"title","Als gelesen markieren");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","readAllButton cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/rework/double-check-circle-full.svg");
            dom.setAttribute(el4,"title","Für alle (anderen MitarbeiterInnen) als gelesen markieren");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","content");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","notTitle");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","small inline-block");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","expand");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el4 = dom.createElement("svg");
            dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
            dom.setAttribute(el4,"width","32");
            dom.setAttribute(el4,"height","32");
            dom.setAttribute(el4,"viewBox","0 0 32 32");
            dom.setAttribute(el4,"class","toggle-button");
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("path");
            dom.setAttribute(el5,"fill","#3B6182");
            dom.setAttribute(el5,"fill-rule","evenodd");
            dom.setAttribute(el5,"d","M1.12401361,14.9642143 C0.841241497,14.9642143 0.558027211,14.8596429 0.342465986,14.6502857 C-0.0893197279,14.232 -0.0893197279,13.5535714 0.342465986,13.1352857 L6.15620748,7.5 L0.342465986,1.86492857 C-0.0893197279,1.44642857 -0.0893197279,0.768 0.342465986,0.349714286 C0.773809524,-0.0685714286 1.47421769,-0.0685714286 1.90556122,0.349714286 L9.28284014,7.5 L1.90556122,14.6502857 C1.69,14.8596429 1.40678571,14.9642143 1.12401361,14.9642143 L1.12401361,14.9642143 Z");
            dom.setAttribute(el5,"transform","rotate(90 6 17.5)");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.setNamespace(null);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3]);
            var element4 = dom.childAt(element3, [3]);
            var element5 = dom.childAt(element3, [5]);
            var element6 = dom.childAt(element3, [7]);
            var element7 = dom.childAt(element2, [5]);
            var morphs = new Array(13);
            morphs[0] = dom.createAttrMorph(element2, 'id');
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [1, 0]),0,0);
            morphs[3] = dom.createAttrMorph(element4, 'data-id');
            morphs[4] = dom.createElementMorph(element4);
            morphs[5] = dom.createAttrMorph(element5, 'data-id');
            morphs[6] = dom.createElementMorph(element5);
            morphs[7] = dom.createElementMorph(element6);
            morphs[8] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
            morphs[9] = dom.createMorphAt(element6,3,3);
            morphs[10] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
            morphs[11] = dom.createAttrMorph(element7, 'class');
            morphs[12] = dom.createUnsafeMorphAt(element7,1,1);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["message",["get","item.id",["loc",[null,[35,31],[35,38]]]]]]],
            ["inline","button-etiga-small",[],["color","#404040","showStroke",true,"content",""],["loc",[null,[38,20],[38,85]]]],
            ["content","item.category.name",["loc",[null,[41,41],[41,63]]]],
            ["attribute","data-id",["concat",["message",["get","item.id",["loc",[null,[42,94],[42,101]]]]]]],
            ["element","action",["markAsRead",["get","item",["loc",[null,[42,43],[42,47]]]]],[],["loc",[null,[42,21],[42,49]]]],
            ["attribute","data-id",["concat",["message",["get","item.id",["loc",[null,[45,103],[45,110]]]]]]],
            ["element","action",["markAsReadForAll",["get","item",["loc",[null,[45,49],[45,53]]]]],[],["loc",[null,[45,21],[45,55]]]],
            ["element","action",["openMessage",["get","item.id",["loc",[null,[48,62],[48,69]]]]],[],["loc",[null,[48,39],[48,71]]]],
            ["content","item.title",["loc",[null,[49,42],[49,56]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[50,30],[50,46]]]],"etiga"],[],["loc",[null,[50,26],[50,55]]]]],[],0,1,["loc",[null,[50,20],[58,27]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[59,67],[59,79]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[59,53],[59,102]]]],
            ["attribute","class",["concat",["full-text small mt10 pr40 ",["subexpr","css-bool-evaluator",[["get","item.collapsed",["loc",[null,[67,75],[67,89]]]],"","hidden"],[],["loc",[null,[67,54],[67,103]]]]]]],
            ["content","item.message",["loc",[null,[68,18],[68,36]]]]
          ],
          locals: ["item"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 10
            },
            "end": {
              "line": 72,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","unreadMessages",["loc",[null,[34,20],[34,34]]]]],[],0,null,["loc",[null,[34,12],[71,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 10
            },
            "end": {
              "line": 74,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            Zurzeit nichts Neues\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 4
            },
            "end": {
              "line": 83,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-footer text-center p7 cursor ");
          var el2 = dom.createTextNode("\n        Kalender öffnen\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["toCalendar"],[],["loc",[null,[80,55],[80,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 119,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/notification-center-calendar.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","triggerNotificationCalendarDropdown");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","w350 notification-dropdown dropdown-persist hidden");
        dom.setAttribute(el1,"role","menu");
        dom.setAttribute(el1,"id","notificationPanelCalendar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel mbn");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-menu");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title fw600");
        var el5 = dom.createTextNode(" eltiga Benachrichtigungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","cursor");
        dom.setAttribute(el4,"id","toCalendarButton");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/notification-light-dark.svg");
        dom.setAttribute(el5,"title","Eltiga Benachrichtigungen anzeigen");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","scroller-bar");
        dom.setAttribute(el4,"style","height: 249px;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","scroller-track");
        dom.setAttribute(el5,"style","height: 249px; margin-bottom: 0px; margin-top: 0px;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","scroller-handle");
        dom.setAttribute(el6,"style","height: 123.262px; top: 0px;");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","scroller-content");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        dom.setAttribute(el5,"class","timeline-list");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(document).on('click', '#triggerNotificationCalendarDropdown', function(e) {\n      $('#notificationPanelCalendar').toggleClass('hidden');\n       $('#notificationPanel').addClass('hidden');\n      e.stopPropagation();\n    });\n\n    $(document).on('click', function(e) {\n      if (e.target.id != 'notificationPanelCalendar' && !$('#notificationPanelCalendar').has(e.target).length && !$(\n          '#notificationPanelCalendar').hasClass('hidden')) {\n        $('#notificationPanelCalendar').addClass('hidden');\n      }\n    });\n\n    $(document).on('click', '#notificationPanelCalendar li.notification-entry', function() {\n        if($(this).find('div.full-text').hasClass('hidden')) {\n          $(this).find('div.full-text').removeClass('hidden'); //toggleClass\n          $(this).find('div.timeline-desc div.float-right').addClass('hidden');\n          $(this).find('div.expand').addClass('inverted');\n        } else {\n          $(this).find('div.full-text').addClass('hidden'); //toggleClass\n          $(this).find('div.timeline-desc div.float-right').removeClass('hidden');\n          $(this).find('div.expand').removeClass('inverted');\n        }\n    });\n    $(document).on('click', '#notificationPanelCalendar a.readButton', function() {\n      $('#' + $(this).attr('data-id')).addClass('hidden');\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(fragment, [2, 1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element9,1,1);
        morphs[1] = dom.createMorphAt(element9,3,3);
        morphs[2] = dom.createElementMorph(element12);
        morphs[3] = dom.createMorphAt(element11,5,5);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3, 3, 1]),1,1);
        morphs[5] = dom.createMorphAt(element10,5,5);
        return morphs;
      },
      statements: [
        ["inline","header-nav-icon",[],["content","eltiga Benachrichtigungen","type","chat"],["loc",[null,[2,2],[2,69]]]],
        ["block","if",[["subexpr","gt",[["get","unreadMessagesCount",["loc",[null,[3,12],[3,31]]]],0],[],["loc",[null,[3,8],[3,34]]]]],[],0,null,["loc",[null,[3,2],[9,9]]]],
        ["element","action",["toEtigaNotifications"],[],["loc",[null,[16,9],[16,42]]]],
        ["block","if",[["get","unreadMessages",["loc",[null,[19,12],[19,26]]]]],[],1,null,["loc",[null,[19,6],[23,13]]]],
        ["block","if",[["get","unreadMessages",["loc",[null,[33,16],[33,30]]]]],[],2,3,["loc",[null,[33,10],[74,17]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[79,10],[79,33]]]]],[],4,null,["loc",[null,[79,4],[83,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});
define('jason-frontend/templates/purchases/masterdata', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 269,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/masterdata.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card site-content fadeIn");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createTextNode("Lieferant: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"id","userEdit");
        dom.setAttribute(el2,"class","admin-form");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tabs__head");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","u-mb0 clearfix");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"class","js-tabs__label tabs__label title is-active");
        dom.setAttribute(el7,"href","#tab-master-data");
        var el8 = dom.createTextNode("Stammdaten");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el7,"href","#tab-conditions");
        var el8 = dom.createTextNode("Bedingungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el7,"href","#tab-dispatch");
        var el8 = dom.createTextNode("Versand");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs__body mt20");
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content is-active");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-name");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-street");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Anschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-postalcode");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-town");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Land");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("select");
        dom.setAttribute(el9,"id","location");
        dom.setAttribute(el9,"name","location");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("option");
        dom.setAttribute(el10,"value","AT");
        var el11 = dom.createTextNode("Österreich");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow double");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-town");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Stadt/Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Kommunikation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-email");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Email (allgemein)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-email");
        dom.setAttribute(el8,"class","field prepend-icon");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","account-email");
        dom.setAttribute(el9,"class","field-icon");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("i");
        dom.setAttribute(el10,"class","fa fa-envelope-o");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-homepage");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Website");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-homepage");
        dom.setAttribute(el8,"class","field prepend-icon");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-homepage");
        dom.setAttribute(el9,"class","field-icon");
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("i");
        dom.setAttribute(el10,"class","fa fa fa-globe");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-purchaseEmail");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Email (Einkauf)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-purchaseEmail");
        dom.setAttribute(el8,"class","field prepend-icon");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","mandant-purchaseEmail");
        dom.setAttribute(el9,"class","field-icon");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("i");
        dom.setAttribute(el10,"class","fa fa-envelope-o");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-phone");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Telefon");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-phone");
        dom.setAttribute(el8,"class","field prepend-icon");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","business-phone");
        dom.setAttribute(el9,"class","field-icon");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("i");
        dom.setAttribute(el10,"class","fa fa-phone");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Kontakt");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-contactFirstname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-contactEmail");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Email");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-contactLastname");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Nachname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-conditions");
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Bedingungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-agb");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Link zu Allgemeine Geschäftsbedingungen (AGB)");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-terms");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Lieferbedingungen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-paymentterms");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Zahlungsbedingungen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-returnTerms");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Retourbedingungen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-deliveryTerms");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Zustellungsbedigungen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-discounts");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Sonderrabatte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-debitNecessary");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Abbucher erforderlich?");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","supplier-debitNecessary");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-dispatch");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Versand");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-freeHouseOrderValue");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Mindestbestellwert");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","priceEuroLabel");
        var el9 = dom.createTextNode("EUR");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-shippingCostsAbsolute");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Versandkosten");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","priceEuroLabel");
        var el9 = dom.createTextNode("EUR");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-parcelDropping");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Paketzustellung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","supplier-parcelDropping");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","supplier-dailyDelivery");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Auslieferung täglich?");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","supplier-dailyDelivery");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-deliveryDays");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Auslieferungstage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","action-icons");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","submit");
        dom.setAttribute(el4,"class","icon-button--success icon-button");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $(\"#userEdit\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'mandant-name': {\n                    required: true\n                },\n                'mandant-street': {\n                    required: true\n                },\n                'mandant-town': {\n                    required: true\n                },\n                'mandant-purchaseEmail': {\n                    required: true\n                },\n                'mandant-postalcode': {\n                    required: true\n                },\n                'mandant-phone': {\n                    pattern: /^\\+?[\\s\\d]+$/,\n                    required: true\n                }\n            },\n            highlight: function(element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function(element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function(error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element3, [3, 1]);
        var element6 = dom.childAt(element3, [5, 1]);
        var element7 = dom.childAt(element2, [3]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [5]);
        var element10 = dom.childAt(element8, [11]);
        var element11 = dom.childAt(element8, [13]);
        var element12 = dom.childAt(element8, [17]);
        var element13 = dom.childAt(element7, [3]);
        var element14 = dom.childAt(element7, [5]);
        var morphs = new Array(29);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createElementMorph(element4);
        morphs[3] = dom.createElementMorph(element5);
        morphs[4] = dom.createElementMorph(element6);
        morphs[5] = dom.createMorphAt(dom.childAt(element8, [3, 1]),3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element9, [1]),3,3);
        morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]),3,3);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [7, 3]),3,3);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [1, 3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [3, 3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element11, [1, 3]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element11, [3, 3]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element12, [1]),3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element12, [3]),3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element8, [19, 1]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element13, [3, 1]),3,3);
        morphs[17] = dom.createMorphAt(dom.childAt(element13, [5, 1]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element13, [7, 1]),3,3);
        morphs[19] = dom.createMorphAt(dom.childAt(element13, [9, 1]),3,3);
        morphs[20] = dom.createMorphAt(dom.childAt(element13, [11, 1]),3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element13, [13, 1]),3,3);
        morphs[22] = dom.createMorphAt(dom.childAt(element13, [15, 1, 3]),1,1);
        morphs[23] = dom.createMorphAt(dom.childAt(element14, [3, 1]),3,3);
        morphs[24] = dom.createMorphAt(dom.childAt(element14, [5, 1]),3,3);
        morphs[25] = dom.createMorphAt(dom.childAt(element14, [7, 1, 3]),1,1);
        morphs[26] = dom.createMorphAt(dom.childAt(element14, [9, 1, 3]),1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element14, [11, 1]),3,3);
        morphs[28] = dom.createMorphAt(dom.childAt(element1, [3, 1]),1,1);
        return morphs;
      },
      statements: [
        ["content","model.name",["loc",[null,[2,32],[2,46]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[3,41],[3,70]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[8,98],[8,133]]]],
        ["element","action",["selectTab","conditions"],[],["loc",[null,[11,87],[11,122]]]],
        ["element","action",["selectTab","dispatch"],[],["loc",[null,[14,85],[14,118]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.name",["loc",[null,[27,40],[27,50]]]]],[],[]],"autofocus",true,"class","gui-input","title","Bitte geben Sie einen Namen an","name","mandant-name"],["loc",[null,[27,26],[27,144]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.street",["loc",[null,[33,40],[33,52]]]]],[],[]],"class","gui-input","name","mandant-street","placeholder","Straße","autofocus",true,"title","Bitte geben Sie eine Anschrift an"],["loc",[null,[33,26],[33,172]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.postalCode",["loc",[null,[37,40],[37,56]]]]],[],[]],"class","gui-input","name","mandant-postalcode","placeholder","PLZ","autofocus",true,"title","Bitte geben Sie eine PLZ an"],["loc",[null,[37,26],[37,171]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.town",["loc",[null,[53,40],[53,50]]]]],[],[]],"class","gui-input","name","mandant-town","autofocus",true,"title","Bitte geben Sie eine Stadt an"],["loc",[null,[53,26],[53,144]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.generalEmail",["loc",[null,[63,44],[63,62]]]]],[],[]],"class","gui-input","name","mandant-email"],["loc",[null,[63,30],[63,103]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.homepage",["loc",[null,[73,40],[73,54]]]]],[],[]],"class","gui-input","name","mandant-homepage"],["loc",[null,[73,26],[73,98]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.purchaseEmail",["loc",[null,[84,36],[84,55]]]]],[],[]],"class","gui-input","name","purchaseEmail-email"],["loc",[null,[84,22],[84,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.phone",["loc",[null,[93,44],[93,55]]]]],[],[]],"class","gui-input","name","mandant-phone","title","Nur Nummern und optionales + erlaubt"],["loc",[null,[93,30],[93,141]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.contactFirstname",["loc",[null,[106,36],[106,58]]]]],[],[]],"autofocus",true,"class","gui-input","name","mandant-contactFirstname"],["loc",[null,[106,22],[106,125]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.contactEmail",["loc",[null,[110,36],[110,54]]]]],[],[]],"class","gui-input","name","mandant-contactEmail","autofocus",true],["loc",[null,[110,22],[110,118]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.contactLastname",["loc",[null,[116,36],[116,57]]]]],[],[]],"class","gui-input","name","mandant-contactLastname","autofocus",true,"title","Bitte geben Sie einen Nachnamen an"],["loc",[null,[116,22],[116,167]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.agbLink",["loc",[null,[128,38],[128,51]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","supplier-agb"],["loc",[null,[128,24],[128,124]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.termsAndConditions",["loc",[null,[134,48],[134,72]]]]],[],[]],"class","input textarea__input full","name","supplier-terms"],["loc",[null,[134,22],[134,131]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.paymentTerms",["loc",[null,[140,48],[140,66]]]]],[],[]],"class","input textarea__input full","name","supplier-paymentterms"],["loc",[null,[140,22],[140,132]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.returnTerms",["loc",[null,[146,48],[146,65]]]]],[],[]],"class","input textarea__input full","name","supplier-returnTerms"],["loc",[null,[146,22],[146,130]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.deliveryTerms",["loc",[null,[152,48],[152,67]]]]],[],[]],"class","input textarea__input full","name","supplier-deliveryTerms"],["loc",[null,[152,22],[152,134]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.specialDiscounts",["loc",[null,[158,48],[158,70]]]]],[],[]],"class","input textarea__input full","name","supplier-discounts"],["loc",[null,[158,22],[158,133]]]],
        ["inline","input",[],["type","checkbox","name","supplier-debitNecessary","id","supplier-debitNecessary","checked",["subexpr","@mut",[["get","model.debitNecessary",["loc",[null,[165,116],[165,136]]]]],[],[]]],["loc",[null,[165,24],[165,138]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.freeHouseOrderValue",["loc",[null,[178,34],[178,59]]]]],[],[]],"autofocus",true,"class","col l-4/24 gui-input price","name","mandant-freeHouseOrderValue"],["loc",[null,[178,20],[178,146]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.shippingCostsAbsolute",["loc",[null,[185,34],[185,61]]]]],[],[]],"autofocus",true,"class","col l-4/24 gui-input price","name","mandant-shippingCostsAbsolute"],["loc",[null,[185,20],[185,150]]]],
        ["inline","input",[],["type","checkbox","name","supplier-parcelDropping","id","supplier-parcelDropping","checked",["subexpr","@mut",[["get","model.parcelDropping",["loc",[null,[193,114],[193,134]]]]],[],[]]],["loc",[null,[193,22],[193,136]]]],
        ["inline","input",[],["type","checkbox","name","supplier-parcelDropping","id","supplier-dailyDelivery","checked",["subexpr","@mut",[["get","model.dailyDelivery",["loc",[null,[202,113],[202,132]]]]],[],[]]],["loc",[null,[202,22],[202,134]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.deliveryDays",["loc",[null,[210,34],[210,52]]]]],[],[]],"autofocus",true,"class","gui-input","name","mandant-deliveryDays"],["loc",[null,[210,20],[210,115]]]],
        ["inline","button-save",[],["color","#ffffff","size","40","content","Speichern"],["loc",[null,[219,10],[219,71]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});
define('jason-frontend/components/select-station-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    allStationsSelected: true,
    actions: {
      toggleAllStations: function toggleAllStations() {
        this.get('stations').forEach(function (station) {
          station.cages.forEach(function (c) {
            return Ember['default'].set(c, 'selected', false);
          });
        });
        this.set('allStationsSelected', true);
        this.get('stations').forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        this.set('changeTrigger', new Date());
      },
      toggleStation: function toggleStation(station) {
        this.get('stations').forEach(function (station) {
          station.cages.forEach(function (c) {
            return Ember['default'].set(c, 'selected', false);
          });
        });
        this.set('allStationsSelected', false);
        if (station.selected) {
          Ember['default'].set(station, 'selected', false);
        } else {
          Ember['default'].set(station, 'selected', true);
        }

        if (!this.get('stations').some(function (item) {
          return item.selected === true;
        })) {
          this.set('allStationsSelected', true);
        }
        this.set('changeTrigger', new Date());
      },
      selectCage: function selectCage(cage) {
        if (cage.visit) {
          return;
        }
        this.get('stations').forEach(function (station) {
          station.cages.forEach(function (c) {
            return Ember['default'].set(c, 'selected', false);
          });
        });
        Ember['default'].set(cage, 'selected', true);
      },
      confirmSimple: function confirmSimple(id) {
        var data = {
          patientTreatmentId: id,
          stationId: this.get('station').id
        };

        this.sendAction('movePatientToStationSimple', id, data);
        $.magnificPopup.close();
      },
      confirmExtended: function confirmExtended(id) {

        var selectedCage = null;
        this.get('stations').forEach(function (station) {
          var found = station.cages.find(function (cage) {
            return cage.selected;
          });
          if (found) {
            selectedCage = found;
          }
        });

        if (!selectedCage) {
          // Handle the case where no cage is selected
          jason.notifiction.error('Fehler', 'Bitte wählen Sie einen Käfig aus');
          return;
        }

        var data = {
          patientTreatmentId: id,
          cageId: selectedCage.id
        };

        this.sendAction('movePatientToStationExtended', id, data);
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(id) {
        this.set('id', id);

        var self = this;
        $.ajax({
          url: "/api/treatmentStations?includeCages=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('stations', data.treatmentStation);
        }, function (error) {});
      },
      loadChangeBox: function loadChangeBox(visitId) {
        this.set('id', visitId);

        var self = this;
        $.ajax({
          url: "/api/treatmentStations?includeCages=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('stations', data.treatmentStation);
        }, function (error) {});
      }
    }
  });

});
define('jason-frontend/templates/products/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Maßnahme erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element52 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element52);
          return morphs;
        },
        statements: [
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[9,38],[9,46]]]]],[],["loc",[null,[9,11],[9,48]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 8
            },
            "end": {
              "line": 23,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element51 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element51, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[22,83],[22,101]]]]],[],["loc",[null,[22,67],[22,103]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 42
            },
            "end": {
              "line": 32,
              "column": 108
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","info-badge mr5 mb5 tag12");
          dom.setAttribute(el1,"style","position: absolute;margin-left: 10px");
          var el2 = dom.createTextNode("Inaktiv");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 33
            },
            "end": {
              "line": 34,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("Art.-Nr:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.rpaId",["loc",[null,[34,50],[34,61]]]],["get","-",["loc",[null,[34,62],[34,63]]]]],[],["loc",[null,[34,24],[34,66]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 74
            },
            "end": {
              "line": 35,
              "column": 60
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("PZN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.pzn",["loc",[null,[35,46],[35,55]]]],["get","-",["loc",[null,[35,56],[35,57]]]]],[],["loc",[null,[35,20],[35,60]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 68
            },
            "end": {
              "line": 36,
              "column": 61
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("EAN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.gtin",["loc",[null,[36,46],[36,56]]]],["get","-",["loc",[null,[36,57],[36,58]]]]],[],["loc",[null,[36,20],[36,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 12
            },
            "end": {
              "line": 46,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Verpackungseinheit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element50 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element50,0,0);
          morphs[1] = dom.createMorphAt(element50,2,2);
          return morphs;
        },
        statements: [
          ["content","purchaseProduct.packageQuantity",["loc",[null,[45,17],[45,52]]]],
          ["content","model.altUnit.name",["loc",[null,[45,53],[45,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 15
            },
            "end": {
              "line": 50,
              "column": 39
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Ja");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 39
            },
            "end": {
              "line": 50,
              "column": 51
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nein");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 15
            },
            "end": {
              "line": 53,
              "column": 72
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.licenceNumber",["loc",[null,[52,68],[52,87]]]],["get","-",["loc",[null,[53,68],[53,69]]]]],[],["loc",[null,[52,42],[53,72]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 72
            },
            "end": {
              "line": 54,
              "column": 47
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.individualLicencNumber",["loc",[null,[54,14],[54,42]]]],["get","-",["loc",[null,[54,43],[54,44]]]]],[],["loc",[null,[53,80],[54,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 18
                },
                "end": {
                  "line": 66,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label stock-label label-danger");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","stock.amount",["loc",[null,[64,22],[64,38]]]],
              ["content","stock.warehouseName",["loc",[null,[65,28],[65,51]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 66,
                    "column": 18
                  },
                  "end": {
                    "line": 68,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/products/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label stock-label label-warning");
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["content","stock.amount",["loc",[null,[67,67],[67,83]]]],
                ["content","stock.warehouseName",["loc",[null,[67,90],[67,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 18
                    },
                    "end": {
                      "line": 70,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label stock-label label-success");
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","stock.amount",["loc",[null,[69,67],[69,83]]]],
                  ["content","stock.warehouseName",["loc",[null,[69,90],[69,113]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 18
                  },
                  "end": {
                    "line": 70,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/products/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","stock.amount",["loc",[null,[68,32],[68,44]]]],2],[],["loc",[null,[68,28],[68,48]]]]],[],0,null,["loc",[null,[68,18],[70,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 18
                },
                "end": {
                  "line": 70,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[66,33],[66,45]]]],2],[],["loc",[null,[66,28],[66,49]]]]],[],0,1,["loc",[null,[66,18],[70,18]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 14
              },
              "end": {
                "line": 72,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[62,29],[62,41]]]],0],[],["loc",[null,[62,24],[62,44]]]]],[],0,1,["loc",[null,[62,18],[70,25]]]]
          ],
          locals: ["stock"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 12
            },
            "end": {
              "line": 73,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10");
          var el2 = dom.createTextNode("Lagerstand");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","purchaseProduct.stockLevels",["loc",[null,[60,22],[60,49]]]]],[],0,null,["loc",[null,[60,14],[72,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 32
              },
              "end": {
                "line": 77,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-very-small");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","tag.name",["loc",[null,[77,64],[77,76]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 12
            },
            "end": {
              "line": 78,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10 mt20");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","tags");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.systemTags",["loc",[null,[76,40],[76,56]]]]],[],0,null,["loc",[null,[76,32],[77,93]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 4
            },
            "end": {
              "line": 88,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","tabs__label");
          dom.setAttribute(el1,"href","#tab-purchase");
          var el2 = dom.createTextNode("Einkauf");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element49 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element49);
          return morphs;
        },
        statements: [
          ["element","action",["toPurchase",["get","model",["loc",[null,[87,74],[87,79]]]]],[],["loc",[null,[87,52],[87,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 4
            },
            "end": {
              "line": 93,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-treatment");
          var el2 = dom.createTextNode("Behandlung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element48 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element48, 'class');
          morphs[1] = dom.createElementMorph(element48);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[92,55],[92,64]]]],"treatment"],[],["loc",[null,[92,51],[92,77]]]],"is-active",""],[],["loc",[null,[92,30],[92,94]]]]]]],
          ["element","action",["selectTab","treatment"],[],["loc",[null,[92,118],[92,152]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 94,
              "column": 4
            },
            "end": {
              "line": 96,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-future");
          var el2 = dom.createTextNode("Zukunft");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element47 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element47, 'class');
          morphs[1] = dom.createElementMorph(element47);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[95,55],[95,64]]]],"future"],[],["loc",[null,[95,51],[95,74]]]],"is-active",""],[],["loc",[null,[95,30],[95,91]]]]]]],
          ["element","action",["selectTab","future"],[],["loc",[null,[95,112],[95,143]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 4
            },
            "end": {
              "line": 101,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-6");
          var el2 = dom.createTextNode("Nachbestellung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-inventory");
          var el2 = dom.createTextNode("Lager");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element45 = dom.childAt(fragment, [1]);
          var element46 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element45, 'class');
          morphs[1] = dom.createElementMorph(element45);
          morphs[2] = dom.createAttrMorph(element46, 'class');
          morphs[3] = dom.createElementMorph(element46);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[99,55],[99,64]]]],"reorder"],[],["loc",[null,[99,51],[99,75]]]],"is-active",""],[],["loc",[null,[99,30],[99,92]]]]]]],
          ["element","action",["selectTab","reorder"],[],["loc",[null,[99,108],[99,140]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[100,55],[100,64]]]],"inventory"],[],["loc",[null,[100,51],[100,77]]]],"is-active",""],[],["loc",[null,[100,30],[100,94]]]]]]],
          ["element","action",["selectTab","inventory"],[],["loc",[null,[100,118],[100,152]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 104,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-debug");
          var el2 = dom.createTextNode("Debug");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element44 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element44, 'class');
          morphs[1] = dom.createElementMorph(element44);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,55],[103,64]]]],"debug"],[],["loc",[null,[103,51],[103,73]]]],"is-active",""],[],["loc",[null,[103,30],[103,90]]]]]]],
          ["element","action",["selectTab","debug"],[],["loc",[null,[103,110],[103,140]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 16
              },
              "end": {
                "line": 150,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element40 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element40, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element40, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element40, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[143,69],[143,77]]]]]]],
            ["content","entry.title",["loc",[null,[147,24],[147,39]]]],
            ["content","entry.description",["loc",[null,[148,38],[148,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 159,
                "column": 164
              },
              "end": {
                "line": 160,
                "column": 206
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element39 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(element39,0,0);
            morphs[1] = dom.createMorphAt(element39,2,2);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[160,81],[160,124]]]]],[],["loc",[null,[160,59],[160,127]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[160,128],[160,199]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 160,
                "column": 206
              },
              "end": {
                "line": 161,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[161,28],[161,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 167,
                "column": 155
              },
              "end": {
                "line": 168,
                "column": 185
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[168,69],[168,103]]]]],[],["loc",[null,[168,47],[168,106]]]],
            ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[168,114],[168,185]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 168,
                "column": 185
              },
              "end": {
                "line": 169,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[169,28],[169,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 136,
              "column": 8
            },
            "end": {
              "line": 173,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Anamnese");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableMedicalHistory");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Vorstellungsgründe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Administrative Tätigkeiten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element41 = dom.childAt(fragment, [3]);
          var element42 = dom.childAt(element41, [1, 3, 1]);
          var element43 = dom.childAt(element41, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element42);
          morphs[2] = dom.createMorphAt(element42,0,0);
          morphs[3] = dom.createElementMorph(element43);
          morphs[4] = dom.createMorphAt(element43,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.medicalHistoryEntries",["loc",[null,[142,24],[142,65]]]]],[],0,null,["loc",[null,[142,16],[150,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","selectedTreatmentValues.introductionReasons",["loc",[null,[159,97],[159,140]]]],"Vorstellungsgründe"],[],["loc",[null,[159,34],[159,163]]]],
          ["block","if",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[159,170],[159,213]]]]],[],1,2,["loc",[null,[159,164],[161,106]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","selectedTreatmentValues.adminTasks",["loc",[null,[167,89],[167,123]]]],"Administrative Tätigkeiten"],[],["loc",[null,[167,34],[167,154]]]],
          ["block","if",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[167,161],[167,195]]]]],[],3,4,["loc",[null,[167,155],[169,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 189,
                "column": 16
              },
              "end": {
                "line": 197,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element36 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element36, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element36, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element36, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[190,69],[190,77]]]]]]],
            ["content","entry.title",["loc",[null,[194,24],[194,39]]]],
            ["content","entry.description",["loc",[null,[195,38],[195,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 206,
                "column": 133
              },
              "end": {
                "line": 206,
                "column": 350
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.symptoms",["loc",[null,[206,236],[206,268]]]]],[],["loc",[null,[206,214],[206,271]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[206,279],[206,350]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 206,
                "column": 350
              },
              "end": {
                "line": 207,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[207,28],[207,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 214,
                  "column": 144
                },
                "end": {
                  "line": 214,
                  "column": 363
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,242],[214,281]]]]],[],["loc",[null,[214,220],[214,284]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[214,292],[214,363]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 214,
                  "column": 363
                },
                "end": {
                  "line": 215,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[215,30],[215,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 210,
                "column": 12
              },
              "end": {
                "line": 218,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createTextNode("Diagnostische Untersuchungen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","pick-list");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element35 = dom.childAt(fragment, [1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element35);
            morphs[1] = dom.createMorphAt(element35,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","un","Diagnostische Untersuchungen",["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,102],[214,141]]]]],[],["loc",[null,[214,36],[214,143]]]],
            ["block","if",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,150],[214,189]]]]],[],0,1,["loc",[null,[214,144],[215,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 183,
              "column": 8
            },
            "end": {
              "line": 220,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Symptome");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableSymptoms");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Klinische Symptomatik");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element37 = dom.childAt(fragment, [3]);
          var element38 = dom.childAt(element37, [1, 3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element38);
          morphs[2] = dom.createMorphAt(element38,0,0);
          morphs[3] = dom.createMorphAt(element37,3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.symptomsEntries",["loc",[null,[189,24],[189,59]]]]],[],0,null,["loc",[null,[189,16],[197,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","symptoms",["get","selectedTreatmentValues.symptoms",["loc",[null,[206,87],[206,119]]]],"Symptome"],[],["loc",[null,[206,34],[206,132]]]],
          ["block","if",[["get","selectedTreatmentValues.symptoms",["loc",[null,[206,139],[206,171]]]]],[],1,2,["loc",[null,[206,133],[207,106]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[210,18],[210,26]]]]],[],3,null,["loc",[null,[210,12],[218,19]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 16
              },
              "end": {
                "line": 245,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element31, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element31, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[238,69],[238,77]]]]]]],
            ["content","entry.title",["loc",[null,[242,24],[242,39]]]],
            ["content","entry.description",["loc",[null,[243,38],[243,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 153
              },
              "end": {
                "line": 254,
                "column": 386
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,264],[254,304]]]]],[],["loc",[null,[254,242],[254,307]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[254,315],[254,386]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 386
              },
              "end": {
                "line": 255,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[255,28],[255,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 261,
                "column": 161
              },
              "end": {
                "line": 261,
                "column": 404
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,277],[261,322]]]]],[],["loc",[null,[261,255],[261,325]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[261,333],[261,404]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 261,
                "column": 404
              },
              "end": {
                "line": 262,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[262,28],[262,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 232,
              "column": 8
            },
            "end": {
              "line": 266,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Diagnose");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableDiagnoses");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Verdachtsdiagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Finale Diagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element32 = dom.childAt(fragment, [3]);
          var element33 = dom.childAt(element32, [1, 3, 1]);
          var element34 = dom.childAt(element32, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element33);
          morphs[2] = dom.createMorphAt(element33,0,0);
          morphs[3] = dom.createElementMorph(element34);
          morphs[4] = dom.createMorphAt(element34,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.diagnosesEntries",["loc",[null,[237,24],[237,60]]]]],[],0,null,["loc",[null,[237,16],[245,25]]]],
          ["element","action",["selectMultistageTreatmentDetail","diagnoses",["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,89],[254,129]]]],"Verdachtsdiagnosen"],[],["loc",[null,[254,34],[254,152]]]],
          ["block","if",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,159],[254,199]]]]],[],1,2,["loc",[null,[254,153],[255,106]]]],
          ["element","action",["selectMultistageTreatmentDetail","finalDiagnoses",["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,94],[261,139]]]],"Finale Diagnosen"],[],["loc",[null,[261,34],[261,160]]]],
          ["block","if",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,167],[261,212]]]]],[],3,4,["loc",[null,[261,161],[262,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child22 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 283,
                "column": 16
              },
              "end": {
                "line": 291,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element30, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element30, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element30, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[284,69],[284,77]]]]]]],
            ["content","entry.title",["loc",[null,[288,24],[288,39]]]],
            ["content","entry.description",["loc",[null,[289,38],[289,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 300,
                  "column": 144
                },
                "end": {
                  "line": 301,
                  "column": 191
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[301,70],[301,110]]]]],[],["loc",[null,[301,49],[301,112]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[301,120],[301,191]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 301,
                  "column": 191
                },
                "end": {
                  "line": 302,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[302,30],[302,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 295,
                "column": 10
              },
              "end": {
                "line": 306,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-24/24@tablet l-8/24@desk gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            var el4 = dom.createTextNode("Therapeutische Behandlungen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pick-list");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","cursor");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1, 1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element29);
            morphs[1] = dom.createMorphAt(element29,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","tb","Therapeutische Behandlungen",["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[300,101],[300,141]]]]],[],["loc",[null,[300,36],[300,143]]]],
            ["block","if",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[300,150],[300,190]]]]],[],0,1,["loc",[null,[300,144],[302,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 278,
              "column": 8
            },
            "end": {
              "line": 307,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Therapie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableTherapies");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.therapiesEntries",["loc",[null,[283,24],[283,60]]]]],[],0,null,["loc",[null,[283,16],[291,25]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[295,16],[295,24]]]]],[],1,null,["loc",[null,[295,10],[306,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child23 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 324,
                "column": 16
              },
              "end": {
                "line": 332,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element28 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element28, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element28, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[325,69],[325,77]]]]]]],
            ["content","entry.title",["loc",[null,[329,24],[329,39]]]],
            ["content","entry.description",["loc",[null,[330,38],[330,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 319,
              "column": 8
            },
            "end": {
              "line": 336,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Beratung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableReports");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.reportEntries",["loc",[null,[324,24],[324,57]]]]],[],0,null,["loc",[null,[324,16],[332,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child24 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 345,
                "column": 16
              },
              "end": {
                "line": 347,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.slopingInfo",["loc",[null,[346,24],[346,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 347,
                "column": 16
              },
              "end": {
                "line": 349,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Derzeit keine Hersteller Dosierungsinformationen hinterlegt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 341,
              "column": 12
            },
            "end": {
              "line": 351,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Hersteller Dosierungsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 gutter");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.slopingInfo",["loc",[null,[345,22],[345,39]]]]],[],0,1,["loc",[null,[345,16],[349,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child25 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 376,
                "column": 58
              },
              "end": {
                "line": 377,
                "column": 57
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.patientCategory2.name",["loc",[null,[377,26],[377,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 377,
                "column": 64
              },
              "end": {
                "line": 378,
                "column": 57
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.patientCategory3.name",["loc",[null,[378,26],[378,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 385,
                  "column": 68
                },
                "end": {
                  "line": 386,
                  "column": 41
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                        , Dauermedikation");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 385,
                "column": 28
              },
              "end": {
                "line": 386,
                "column": 48
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.override",["loc",[null,[385,50],[385,68]]]],
            ["block","if",[["get","entry.infinity",["loc",[null,[385,74],[385,88]]]]],[],0,null,["loc",[null,[385,68],[386,48]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 387,
                  "column": 61
                },
                "end": {
                  "line": 388,
                  "column": 52
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                          über ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Tag(e)");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.days",["loc",[null,[388,31],[388,45]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 388,
                  "column": 52
                },
                "end": {
                  "line": 388,
                  "column": 75
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dauermedikation");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 386,
                "column": 48
              },
              "end": {
                "line": 388,
                "column": 86
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" x täglich, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.unitsPerDay",["loc",[null,[386,56],[386,77]]]],
            ["content","entry.serviceunit.name",["loc",[null,[386,78],[386,104]]]],
            ["content","entry.dosingsPerDay",["loc",[null,[387,26],[387,49]]]],
            ["block","unless",[["get","entry.infinity",["loc",[null,[387,71],[387,85]]]]],[],0,1,["loc",[null,[387,61],[388,86]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 389,
                "column": 22
              },
              "end": {
                "line": 389,
                "column": 112
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small block");
            var el2 = dom.createElement("b");
            var el3 = dom.createTextNode("Interne Notiz:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            return morphs;
          },
          statements: [
            ["content","entry.notice",["loc",[null,[389,89],[389,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 373,
              "column": 16
            },
            "end": {
              "line": 405,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-8/24@desk");
          dom.setAttribute(el2,"data-label","Spezies");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24@phone l-12/24@tablet l-6/24@desk text-center");
          dom.setAttribute(el2,"data-label","Gewichtsklasse von/bis");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" kg");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-23/24@phone l-23/24@tablet l-6/24@desk");
          dom.setAttribute(el2,"data-label","Anwendung");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 table__cell--action");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button list-action-square ms-5");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button list-action-square  me-5 cursor");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/barcode.svg");
          dom.setAttribute(el4,"class","tooltipstered");
          dom.setAttribute(el4,"title","Dosierungsetikett herunterladen");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var element22 = dom.childAt(element21, [1, 1]);
          var element23 = dom.childAt(element21, [3, 1]);
          var element24 = dom.childAt(element21, [5]);
          var element25 = dom.childAt(element21, [7]);
          var element26 = dom.childAt(element25, [3]);
          var element27 = dom.childAt(element25, [5]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(element22,0,0);
          morphs[1] = dom.createMorphAt(element22,1,1);
          morphs[2] = dom.createMorphAt(element22,2,2);
          morphs[3] = dom.createMorphAt(element23,0,0);
          morphs[4] = dom.createMorphAt(element23,2,2);
          morphs[5] = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
          morphs[6] = dom.createMorphAt(element24,3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
          morphs[8] = dom.createElementMorph(element26);
          morphs[9] = dom.createElementMorph(element27);
          return morphs;
        },
        statements: [
          ["content","entry.patientCategory.name",["loc",[null,[376,28],[376,58]]]],
          ["block","if",[["get","entry.patientCategory2",["loc",[null,[376,64],[376,86]]]]],[],0,null,["loc",[null,[376,58],[377,64]]]],
          ["block","if",[["get","entry.patientCategory3",["loc",[null,[377,70],[377,92]]]]],[],1,null,["loc",[null,[377,64],[378,64]]]],
          ["content","entry.weightFrom",["loc",[null,[382,28],[382,48]]]],
          ["content","entry.weightTo",["loc",[null,[382,51],[382,69]]]],
          ["block","if",[["get","entry.override",["loc",[null,[385,34],[385,48]]]]],[],2,3,["loc",[null,[385,28],[388,93]]]],
          ["block","if",[["get","entry.notice",["loc",[null,[389,28],[389,40]]]]],[],4,null,["loc",[null,[389,22],[389,119]]]],
          ["inline","button-delete-with-confirm",[],["size","32","item",["subexpr","@mut",[["get","entry",["loc",[null,[393,68],[393,73]]]]],[],[]],"confirmed","deleteSlopping","text","Wollen Sie diese Dosierungsinformation wirklich enternen?","content","Dosierungsinformation entfernen"],["loc",[null,[393,24],[395,96]]]],
          ["element","action",["editSloping",["get","entry",["loc",[null,[397,92],[397,97]]]]],[],["loc",[null,[397,69],[397,99]]]],
          ["element","action",["openBarcodeSelection",["get","entry.id",["loc",[null,[400,109],[400,117]]]]],[],["loc",[null,[400,77],[400,119]]]]
        ],
        locals: ["entry"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 421,
              "column": 78
            },
            "end": {
              "line": 421,
              "column": 173
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Automatische Nachbestellung bei");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 422,
              "column": 78
            },
            "end": {
              "line": 422,
              "column": 160
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nachbestellung von");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 429,
                "column": 26
              },
              "end": {
                "line": 429,
                "column": 77
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","reorder.warehouse.name",["loc",[null,[429,51],[429,77]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 429,
                "column": 77
              },
              "end": {
                "line": 429,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alle Lager");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 449,
                "column": 20
              },
              "end": {
                "line": 463,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field number-input");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","product-reorderMinimalServiceunit");
            dom.setAttribute(el1,"class","select--inline input-element");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderMinimalAmount",["loc",[null,[451,52],[451,80]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.reorderSpinnerId",["loc",[null,[451,84],[451,108]]]]],[],[]]],["loc",[null,[451,22],[451,110]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[454,30],[454,42]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderMinimalServiceunit",["loc",[null,[455,28],[455,61]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-reorderMinimalServiceunit"],["loc",[null,[453,20],[461,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 466,
                "column": 16
              },
              "end": {
                "line": 483,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field number-input");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","product-priceLine");
            dom.setAttribute(el1,"class","select--inline input-element");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mtm5");
            var el2 = dom.createTextNode("\n                      Einzelpreis: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("b");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(", Gesamtpreis: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("b");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderAmount",["loc",[null,[468,52],[468,73]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.amountSpinnerId",["loc",[null,[468,77],[468,100]]]]],[],[]]],["loc",[null,[468,22],[468,102]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","purchaseProduct.reorderPurchaseSupplierInfos",["loc",[null,[471,30],[471,74]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo",["loc",[null,[472,28],[472,63]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","title","allowClear",true,"placeholder","Lieferant & VE wählen","name","product-priceLine"],["loc",[null,[470,20],[478,22]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[481,62],[481,108]]]]],[],[]],"classNames","inline"],["loc",[null,[481,38],[481,130]]]],
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","multiply",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[481,206],[481,252]]]],["get","reorder.reorderAmount",["loc",[null,[481,253],[481,274]]]]],[],["loc",[null,[481,196],[481,275]]]]],["loc",[null,[481,152],[481,277]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 486,
                "column": 20
              },
              "end": {
                "line": 491,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"checked","checked");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var element16 = dom.childAt(element15, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element16,[],true); }
            var element17 = dom.childAt(element15, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element16, 'id');
            morphs[1] = dom.createElementMorph(element16);
            morphs[2] = dom.createAttrMorph(element17, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[488,49],[488,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[488,138],[488,145]]]]],["on","change"],["loc",[null,[488,110],[488,159]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[489,50],[489,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 491,
                "column": 20
              },
              "end": {
                "line": 496,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element12, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element13, 'id');
            morphs[1] = dom.createElementMorph(element13);
            morphs[2] = dom.createAttrMorph(element14, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[493,49],[493,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[493,120],[493,127]]]]],["on","change"],["loc",[null,[493,92],[493,141]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[494,50],[494,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 426,
              "column": 14
            },
            "end": {
              "line": 499,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Benachrichtigung");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-infoMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Automatisch Nachbestellen bei");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk pb5");
          dom.setAttribute(el2,"data-label","Nachbestellen von");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element19, [1, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element19, [5]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element19, [7]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element19, [9]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","reorder.deleted",["loc",[null,[427,60],[427,75]]]],"hidden",""],[],["loc",[null,[427,39],[427,89]]]]]]],
          ["block","if",[["get","reorder.warehouse",["loc",[null,[429,32],[429,49]]]]],[],0,1,["loc",[null,[429,26],[429,102]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.infoMinimalAmount",["loc",[null,[434,52],[434,77]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.infoSpinnerId",["loc",[null,[434,81],[434,102]]]]],[],[]]],["loc",[null,[434,22],[434,104]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[438,32],[438,44]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.infoMinimalServiceunit",["loc",[null,[439,30],[439,60]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-infoMinimalServiceunit"],["loc",[null,[437,22],[445,24]]]],
          ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[449,31],[449,58]]]],["get","model.purchaseRelevant",["loc",[null,[449,59],[449,81]]]]],[],["loc",[null,[449,26],[449,82]]]]],[],2,null,["loc",[null,[449,20],[463,25]]]],
          ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[466,27],[466,54]]]],["get","model.purchaseRelevant",["loc",[null,[466,55],[466,77]]]]],[],["loc",[null,[466,22],[466,78]]]]],[],3,null,["loc",[null,[466,16],[483,23]]]],
          ["block","if",[["get","reorder.active",["loc",[null,[486,26],[486,40]]]]],[],4,5,["loc",[null,[486,20],[496,27]]]]
        ],
        locals: ["reorder"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child29 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 503,
              "column": 6
            },
            "end": {
              "line": 522,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Automatische Nachbestellung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form-field-rework");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class"," d-flex align-items-center");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","checkbox-custom checkbox-primary field-checkbox");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","productAutosendReorder");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","checkbox-label");
          var el4 = dom.createTextNode("Warenkorb automatisch abschicken");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form-field-rework");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","textarea-wrapper");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","orderNotice");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Nachbestellungs-Notiz");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["type","checkbox","name","productAutosendReorder","id","productAutosendReorder","checked",["subexpr","@mut",[["get","purchaseProduct.autosendReorder",["loc",[null,[509,104],[509,135]]]]],[],[]]],["loc",[null,[509,14],[509,137]]]],
          ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","purchaseProduct.orderNotice",["loc",[null,[517,38],[517,65]]]]],[],[]],"class","","name","orderNotice","placeholder",""],["loc",[null,[517,12],[518,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 559,
              "column": 10
            },
            "end": {
              "line": 562,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (EU): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberEu",["loc",[null,[561,15],[561,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child31 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 563,
              "column": 10
            },
            "end": {
              "line": 566,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Ages): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberAges",["loc",[null,[565,15],[565,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child32 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 567,
              "column": 10
            },
            "end": {
              "line": 570,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Apothekerverlag): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberApo",["loc",[null,[569,15],[569,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child33 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 571,
              "column": 10
            },
            "end": {
              "line": 574,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Dosierungsinformationen / Analytische Bestandteile");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.slopingInfo",["loc",[null,[573,23],[573,40]]]],"Link"],[],["loc",[null,[573,15],[573,50]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child34 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 578,
                "column": 19
              },
              "end": {
                "line": 578,
                "column": 134
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [0]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element11,0,0);
            morphs[1] = dom.createMorphAt(element11,2,2);
            morphs[2] = dom.createMorphAt(element11,4,4);
            return morphs;
          },
          statements: [
            ["content","substance.name",["loc",[null,[578,64],[578,82]]]],
            ["content","substance.concentration",["loc",[null,[578,83],[578,110]]]],
            ["content","substance.unit",["loc",[null,[578,111],[578,129]]]]
          ],
          locals: ["substance"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 576,
              "column": 10
            },
            "end": {
              "line": 579,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Wirkstoffe: ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 0]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.substances",["loc",[null,[578,27],[578,43]]]]],[],0,null,["loc",[null,[578,19],[578,143]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child35 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 581,
              "column": 10
            },
            "end": {
              "line": 584,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Fachinformationen / Zusammensetzung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.domainInfo",["loc",[null,[583,23],[583,39]]]],"Link",["get","model.supplier.id",["loc",[null,[583,47],[583,64]]]]],[],["loc",[null,[583,15],[583,67]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child36 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 586,
              "column": 10
            },
            "end": {
              "line": 589,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Infos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element10,0,0);
          morphs[1] = dom.createUnsafeMorphAt(element10,1,1);
          return morphs;
        },
        statements: [
          ["content","model.info",["loc",[null,[588,15],[588,29]]]],
          ["inline","link",[["get","model.detailUrl",["loc",[null,[588,37],[588,52]]]],"Download"],[],["loc",[null,[588,29],[588,66]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child37 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 590,
              "column": 10
            },
            "end": {
              "line": 596,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Fachinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Gebrauchsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [3, 0]);
          var element9 = dom.childAt(fragment, [7, 0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element8, 'href');
          morphs[1] = dom.createAttrMorph(element9, 'href');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[592,92],[592,111]]]],"&type=DOTC_FACH_INFO"]]],
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[595,92],[595,111]]]],"&type=DOTC_GEBR_INFO"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child38 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 598,
              "column": 10
            },
            "end": {
              "line": 601,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zielspezies");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.targetRace",["loc",[null,[600,15],[600,35]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child39 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 612,
              "column": 11
            },
            "end": {
              "line": 612,
              "column": 102
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/icon-check.svg");
          dom.setAttribute(el1,"style","height: 24px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child40 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 612,
              "column": 102
            },
            "end": {
              "line": 612,
              "column": 111
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child41 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 612,
              "column": 119
            },
            "end": {
              "line": 612,
              "column": 172
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(case ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.richterCase",["loc",[null,[612,150],[612,171]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child42 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 630,
                  "column": 10
                },
                "end": {
                  "line": 638,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","sortable-item draggable");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","delete");
              dom.setAttribute(el2,"onclick","this.parentNode.remove()");
              var el3 = dom.createTextNode("\n                ×\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h2");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","sub");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element4, 'data-module-id');
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[631,67],[631,75]]]]]]],
              ["content","entry.title",["loc",[null,[635,18],[635,33]]]],
              ["content","entry.description",["loc",[null,[636,32],[636,53]]]]
            ],
            locals: ["entry"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 628,
                "column": 6
              },
              "end": {
                "line": 643,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class"," scrollable");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","block small mb-30");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Tipp:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Ziehen Sie die Textbausteine an die gewünschte Stelle");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","allItems",["loc",[null,[630,18],[630,26]]]]],[],0,null,["loc",[null,[630,10],[638,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 644,
                  "column": 8
                },
                "end": {
                  "line": 645,
                  "column": 49
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Derzeit keine Textbausteine hinterlegt.");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 643,
                "column": 6
              },
              "end": {
                "line": 646,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","link-to",["templates",["subexpr","query-params",[],["selectTab","textmodules"],["loc",[null,[644,31],[644,69]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[644,8],[645,61]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 619,
              "column": 0
            },
            "end": {
              "line": 649,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","editorSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","box inner wizzard");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Textbausteine");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element5, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
          morphs[2] = dom.createMorphAt(element7,5,5);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[621,9],[621,40]]]],
          ["inline","search-box",[],["classNames","w-100","filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[626,51],[626,61]]]]],[],[]],"placeHolder","Textbaustein suchen","search","searchTextModules"],["loc",[null,[626,8],[626,124]]]],
          ["block","if",[["get","allItems",["loc",[null,[628,12],[628,20]]]]],[],0,1,["loc",[null,[628,6],[646,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child43 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 650,
                "column": 2
              },
              "end": {
                "line": 654,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[652,63],[652,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 649,
              "column": 0
            },
            "end": {
              "line": 655,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[650,12],[650,21]]]],"treatment"],[],["loc",[null,[650,8],[650,34]]]]],[],0,null,["loc",[null,[650,2],[654,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child44 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 659,
              "column": 2
            },
            "end": {
              "line": 663,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          dom.setAttribute(el1,"id","add-price");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-add-entry",[],["content","Weitere Verkaufseinheit hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[661,6],[661,114]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child45 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 664,
              "column": 2
            },
            "end": {
              "line": 668,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["addSloping"],[],["loc",[null,[665,27],[665,50]]]],
          ["inline","button-add-entry",[],["content","Weitere Dosierungsinformation hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[666,6],[666,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child46 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 669,
              "column": 2
            },
            "end": {
              "line": 673,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["saveReorder",["get","model",["loc",[null,[670,71],[670,76]]]]],[],["loc",[null,[670,48],[670,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child47 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 673,
                "column": 2
              },
              "end": {
                "line": 677,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--success");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["saveTextModules"],[],["loc",[null,[674,48],[674,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 677,
                "column": 2
              },
              "end": {
                "line": 681,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--success input");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerSubmit"],[],["loc",[null,[678,7],[678,33]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 673,
              "column": 2
            },
            "end": {
              "line": 681,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[673,16],[673,25]]]],"treatment"],[],["loc",[null,[673,12],[673,38]]]]],[],0,1,["loc",[null,[673,2],[681,2]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 692,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/products/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Produkt");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/qr-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("QR-Code drucken");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","productEditPage");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","card mb-0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-8/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","u-text--center mt10 pr30");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n     ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-16/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","sub-headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","product-description");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dataList grid");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Verpackungsmenge");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Kategorie");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Suchtgiftmittel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Zulassungsnummer");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-master-data");
        var el4 = dom.createTextNode("Verkauf");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-add-data");
        var el4 = dom.createTextNode("Artikelinformationen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-sloping");
        var el4 = dom.createTextNode("Dosierung");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-master-data");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","productEdit");
        dom.setAttribute(el5,"class","admin-form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-treatment");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","is-highlighted-alternative");
        var el6 = dom.createTextNode("Die ausgewählten Textbausteine werden beim Hinzufügen zu einer Behandlung automatisch ergänzt");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Anamnese");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchAnamnese");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Symptome");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchSymptoms");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Diagnosen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchDiagnose");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Therapien");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchTherapy");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Beratung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchReport");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-sloping");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","productSlopginEdit");
        dom.setAttribute(el5,"class","admin-form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card--no-padding");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Dosierung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea-wrapper");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","placeholder");
        dom.setAttribute(el9,"for","product-doseMethod");
        var el10 = dom.createTextNode("Verabreichungsmethode");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Meine Dosierungsinformationen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table-wrapper-rework");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table table--large");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Spezies");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title text-center");
        var el12 = dom.createTextNode("Gewichtsklasse von/bis");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24 u-text--left");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Anwendung");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-3/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-6");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Nachbestellungs-Regeln");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper mb40");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table mt20 table--ve  table--small");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-3/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Lager");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-6/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Benachrichtigung");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" für Nachbestellungen bei");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-6/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-7/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell  table__cell--right l-2/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Aktiv");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-inventory");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Lagerverwaltung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","form-field-rework");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom field-checkbox");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productEhapo");
        var el10 = dom.createTextNode(" ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","checkbox-label");
        var el9 = dom.createTextNode("Lagerverwaltung für dieses Produkt aktivieren");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","d-flex align-items-center");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom field-checkbox");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productAutoBooking");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","checkbox-label");
        var el9 = dom.createTextNode(" Automatisch aus ältester Lagereinheit ausbuchen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Lagerbestände");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-add-data");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dataList");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("Beschreibung: ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-debug");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Aktualisiert");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","mt20");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Datenlieferant");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("RPA-Migration");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","buttons action-icons clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element53 = dom.childAt(fragment, [0]);
        var element54 = dom.childAt(element53, [1]);
        var element55 = dom.childAt(element54, [3]);
        var element56 = dom.childAt(element55, [1]);
        var element57 = dom.childAt(fragment, [2]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element59, [1]);
        var element61 = dom.childAt(element59, [3]);
        var element62 = dom.childAt(element59, [5]);
        var element63 = dom.childAt(element62, [3]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element63, [3]);
        var element67 = dom.childAt(element57, [3]);
        var element68 = dom.childAt(element67, [3]);
        var element69 = dom.childAt(element67, [5]);
        var element70 = dom.childAt(element67, [10]);
        var element71 = dom.childAt(element57, [5, 1]);
        var element72 = dom.childAt(element71, [1]);
        var element73 = dom.childAt(element72, [1]);
        var element74 = dom.childAt(element71, [3]);
        var element75 = dom.childAt(element71, [5]);
        var element76 = dom.childAt(element71, [7]);
        var element77 = dom.childAt(element76, [1, 1]);
        var element78 = dom.childAt(element71, [9]);
        var element79 = dom.childAt(element78, [3, 1]);
        var element80 = dom.childAt(element79, [1]);
        var element81 = dom.childAt(element71, [11]);
        var element82 = dom.childAt(element81, [3]);
        var element83 = dom.childAt(element82, [3]);
        var element84 = dom.childAt(element71, [13]);
        var element85 = dom.childAt(element84, [1]);
        var element86 = dom.childAt(element71, [15]);
        var element87 = dom.childAt(element86, [7]);
        var element88 = dom.childAt(element86, [11]);
        var element89 = dom.childAt(fragment, [6]);
        var morphs = new Array(88);
        morphs[0] = dom.createAttrMorph(element53, 'class');
        morphs[1] = dom.createAttrMorph(element54, 'class');
        morphs[2] = dom.createElementMorph(element56);
        morphs[3] = dom.createMorphAt(element55,3,3);
        morphs[4] = dom.createAttrMorph(element57, 'class');
        morphs[5] = dom.createMorphAt(dom.childAt(element58, [1, 1]),1,1);
        morphs[6] = dom.createMorphAt(element60,0,0);
        morphs[7] = dom.createMorphAt(element60,2,2);
        morphs[8] = dom.createMorphAt(element61,0,0);
        morphs[9] = dom.createMorphAt(element61,2,2);
        morphs[10] = dom.createMorphAt(element61,4,4);
        morphs[11] = dom.createUnsafeMorphAt(dom.childAt(element62, [1]),0,0);
        morphs[12] = dom.createMorphAt(element65,0,0);
        morphs[13] = dom.createMorphAt(element65,2,2);
        morphs[14] = dom.createMorphAt(element64,5,5);
        morphs[15] = dom.createMorphAt(dom.childAt(element64, [9]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element64, [13]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element64, [17]),0,0);
        morphs[18] = dom.createMorphAt(element66,1,1);
        morphs[19] = dom.createMorphAt(element66,2,2);
        morphs[20] = dom.createMorphAt(element67,1,1);
        morphs[21] = dom.createAttrMorph(element68, 'class');
        morphs[22] = dom.createElementMorph(element68);
        morphs[23] = dom.createAttrMorph(element69, 'class');
        morphs[24] = dom.createElementMorph(element69);
        morphs[25] = dom.createMorphAt(element67,7,7);
        morphs[26] = dom.createMorphAt(element67,8,8);
        morphs[27] = dom.createAttrMorph(element70, 'class');
        morphs[28] = dom.createElementMorph(element70);
        morphs[29] = dom.createMorphAt(element67,12,12);
        morphs[30] = dom.createMorphAt(element67,13,13);
        morphs[31] = dom.createAttrMorph(element72, 'class');
        morphs[32] = dom.createElementMorph(element73);
        morphs[33] = dom.createMorphAt(element73,1,1);
        morphs[34] = dom.createAttrMorph(element74, 'class');
        morphs[35] = dom.createMorphAt(element74,1,1);
        morphs[36] = dom.createAttrMorph(element75, 'class');
        morphs[37] = dom.createMorphAt(dom.childAt(element75, [3, 1, 3]),1,1);
        morphs[38] = dom.createMorphAt(element75,5,5);
        morphs[39] = dom.createMorphAt(dom.childAt(element75, [7, 1, 3]),1,1);
        morphs[40] = dom.createMorphAt(element75,9,9);
        morphs[41] = dom.createMorphAt(dom.childAt(element75, [11, 1, 3]),1,1);
        morphs[42] = dom.createMorphAt(element75,13,13);
        morphs[43] = dom.createMorphAt(dom.childAt(element75, [15, 1, 3]),1,1);
        morphs[44] = dom.createMorphAt(element75,17,17);
        morphs[45] = dom.createMorphAt(dom.childAt(element75, [19, 1, 3]),1,1);
        morphs[46] = dom.createMorphAt(element75,21,21);
        morphs[47] = dom.createAttrMorph(element76, 'class');
        morphs[48] = dom.createMorphAt(element77,1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element77, [5, 1]),1,1);
        morphs[50] = dom.createMorphAt(dom.childAt(element77, [9, 1, 3]),1,1);
        morphs[51] = dom.createAttrMorph(element78, 'class');
        morphs[52] = dom.createMorphAt(dom.childAt(element80, [5, 0]),0,0);
        morphs[53] = dom.createMorphAt(dom.childAt(element80, [7, 0]),0,0);
        morphs[54] = dom.createMorphAt(dom.childAt(element79, [3]),1,1);
        morphs[55] = dom.createMorphAt(element78,5,5);
        morphs[56] = dom.createAttrMorph(element81, 'class');
        morphs[57] = dom.createMorphAt(dom.childAt(element82, [1, 1, 1]),1,1);
        morphs[58] = dom.createAttrMorph(element83, 'class');
        morphs[59] = dom.createMorphAt(dom.childAt(element83, [1, 1]),1,1);
        morphs[60] = dom.createMorphAt(element81,7,7);
        morphs[61] = dom.createAttrMorph(element84, 'class');
        morphs[62] = dom.createMorphAt(dom.childAt(element85, [3]),0,0);
        morphs[63] = dom.createMorphAt(element85,5,5);
        morphs[64] = dom.createMorphAt(element85,6,6);
        morphs[65] = dom.createMorphAt(element85,7,7);
        morphs[66] = dom.createMorphAt(element85,8,8);
        morphs[67] = dom.createMorphAt(element85,10,10);
        morphs[68] = dom.createMorphAt(element85,12,12);
        morphs[69] = dom.createMorphAt(element85,14,14);
        morphs[70] = dom.createMorphAt(element85,15,15);
        morphs[71] = dom.createMorphAt(element85,17,17);
        morphs[72] = dom.createAttrMorph(element86, 'class');
        morphs[73] = dom.createMorphAt(dom.childAt(element86, [3]),0,0);
        morphs[74] = dom.createMorphAt(element87,0,0);
        morphs[75] = dom.createMorphAt(element87,2,2);
        morphs[76] = dom.createMorphAt(element88,0,0);
        morphs[77] = dom.createMorphAt(element88,2,2);
        morphs[78] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[79] = dom.createMorphAt(dom.childAt(element89, [1]),0,0);
        morphs[80] = dom.createMorphAt(element89,3,3);
        morphs[81] = dom.createMorphAt(element89,4,4);
        morphs[82] = dom.createMorphAt(element89,5,5);
        morphs[83] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[84] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[85] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[86] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[87] = dom.createMorphAt(fragment,16,16,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[1,52],[1,61]]]],"treatment"],[],["loc",[null,[1,48],[1,74]]]],["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,95],[1,108]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,75],[1,138]]]],""],[],["loc",[null,[1,27],[1,143]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[2,72],[2,85]]]]],[],["loc",[null,[2,67],[2,86]]]],"24","18"],[],["loc",[null,[2,46],[2,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["printQr",["get","model.id",["loc",[null,[5,28],[5,36]]]]],[],["loc",[null,[5,9],[5,38]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[8,16],[8,25]]]],"future"],[],["loc",[null,[8,12],[8,35]]]]],[],0,null,["loc",[null,[8,6],[12,13]]]],
        ["attribute","class",["concat",["edit-product rework-content l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[17,72],[17,85]]]]],[],["loc",[null,[17,67],[17,86]]]],"24","18"],[],["loc",[null,[17,46],[17,98]]]],"/24@desk ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[17,128],[17,141]]]],"sidebar-active",""],[],["loc",[null,[17,107],[17,163]]]]]]],
        ["block","if",[["get","model.picture",["loc",[null,[21,14],[21,27]]]]],[],1,2,["loc",[null,[21,8],[27,15]]]],
        ["content","model.name",["loc",[null,[31,27],[31,41]]]],
        ["block","if",[["get","model.deleted",["loc",[null,[31,48],[31,61]]]]],[],3,null,["loc",[null,[31,42],[32,115]]]],
        ["block","if",[["get","model.rpaId",["loc",[null,[33,39],[33,50]]]]],[],4,null,["loc",[null,[33,33],[34,73]]]],
        ["block","if",[["get","model.pzn",["loc",[null,[34,80],[34,89]]]]],[],5,null,["loc",[null,[34,74],[35,67]]]],
        ["block","if",[["subexpr","or",[["get","model.gtin",["loc",[null,[35,78],[35,88]]]],["get","model.ean",["loc",[null,[35,89],[35,98]]]]],[],["loc",[null,[35,74],[35,99]]]]],[],6,null,["loc",[null,[35,68],[36,68]]]],
        ["inline","truncate",[["get","model.description",["loc",[null,[38,23],[38,40]]]],300],[],["loc",[null,[38,11],[38,47]]]],
        ["content","purchaseProduct.quantity",["loc",[null,[42,15],[42,43]]]],
        ["content","model.unit.name",["loc",[null,[42,44],[42,63]]]],
        ["block","if",[["get","model.altUnit",["loc",[null,[43,18],[43,31]]]]],[],7,null,["loc",[null,[43,12],[46,19]]]],
        ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[48,41],[48,60]]]],"-"],[],["loc",[null,[48,15],[48,66]]]],
        ["block","if",[["get","model.narcotic",["loc",[null,[50,21],[50,35]]]]],[],8,9,["loc",[null,[50,15],[50,58]]]],
        ["block","if",[["get","model.licenceNumber",["loc",[null,[52,21],[52,40]]]]],[],10,11,["loc",[null,[52,15],[54,54]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[58,18],[58,35]]]]],[],12,null,["loc",[null,[58,12],[73,19]]]],
        ["block","if",[["get","model.systemTags",["loc",[null,[74,18],[74,34]]]]],[],13,null,["loc",[null,[74,12],[78,19]]]],
        ["block","if",[["subexpr","and",[["subexpr","has-permission",["purchases"],[],["loc",[null,[86,15],[86,43]]]],["get","model.purchaseRelevant",["loc",[null,[86,44],[86,66]]]]],[],["loc",[null,[86,10],[86,67]]]]],[],14,null,["loc",[null,[86,4],[88,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[89,53],[89,62]]]],"masterdata"],[],["loc",[null,[89,49],[89,76]]]],"is-active",""],[],["loc",[null,[89,28],[89,93]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[89,119],[89,154]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[90,53],[90,62]]]],"adddata"],[],["loc",[null,[90,49],[90,73]]]],"is-active",""],[],["loc",[null,[90,28],[90,90]]]]]]],
        ["element","action",["selectTab","adddata"],[],["loc",[null,[90,113],[90,145]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[91,10],[91,35]]]]],[],15,null,["loc",[null,[91,4],[93,11]]]],
        ["block","if",[["subexpr","has-permission",["crud_treatment_future_blueprints"],[],["loc",[null,[94,10],[94,61]]]]],[],16,null,["loc",[null,[94,4],[96,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[97,53],[97,62]]]],"sloping"],[],["loc",[null,[97,49],[97,73]]]],"is-active",""],[],["loc",[null,[97,28],[97,90]]]]]]],
        ["element","action",["selectTab","sloping"],[],["loc",[null,[97,112],[97,144]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[98,10],[98,27]]]]],[],17,null,["loc",[null,[98,4],[101,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[102,14],[102,31]]]],["subexpr","is-debug-user",[],[],["loc",[null,[102,32],[102,47]]]]],[],["loc",[null,[102,10],[102,48]]]]],[],18,null,["loc",[null,[102,4],[104,11]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[110,69],[110,78]]]],"masterdata"],[],["loc",[null,[110,65],[110,92]]]],"is-active",""],[],["loc",[null,[110,44],[110,109]]]]]]],
        ["element","action",["saveCheck",["get","referer",["loc",[null,[111,71],[111,78]]]],["get","refererInvoiceId",["loc",[null,[111,79],[111,95]]]],["get","refererPatientId",["loc",[null,[111,96],[111,112]]]]],["on","submit"],["loc",[null,[111,50],[111,126]]]],
        ["inline","products-edit-form",[],["history",["subexpr","@mut",[["get","history",["loc",[null,[112,39],[112,46]]]]],[],[]],"articleTags",["subexpr","@mut",[["get","articleTags",["loc",[null,[112,59],[112,70]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[112,77],[112,82]]]]],[],[]],"mode","edit","editable",["subexpr","@mut",[["get","model.editable",["loc",[null,[112,104],[112,118]]]]],[],[]],"labProfiles",["subexpr","@mut",[["get","labProfiles",["loc",[null,[112,131],[112,142]]]]],[],[]],"taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[112,149],[112,154]]]]],[],[]],"allUnits",["subexpr","@mut",[["get","allUnits",["loc",[null,[112,164],[112,172]]]]],[],[]],"distributors",["subexpr","@mut",[["get","distributors",["loc",[null,[112,186],[112,198]]]]],[],[]],"productCategories",["subexpr","@mut",[["get","productCategories",["loc",[null,[112,217],[112,234]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[112,246],[112,256]]]]],[],[]],"manufacturers",["subexpr","@mut",[["get","manufacturers",["loc",[null,[112,271],[112,284]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[112,303],[112,320]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","productEditForm",["loc",[null,[112,336],[112,351]]]]],[],[]]],["loc",[null,[112,10],[112,353]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[115,56],[115,65]]]],"future"],[],["loc",[null,[115,52],[115,75]]]],"is-active",""],[],["loc",[null,[115,31],[115,92]]]]]]],
        ["inline","future-blueprint-panel",[],["edit","editEntry","delete","deleteEntry","items",["subexpr","@mut",[["get","futureItems",["loc",[null,[120,16],[120,27]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[121,16],[121,21]]]]],[],[]]],["loc",[null,[117,8],[121,23]]]],
        ["attribute","class",["concat",["card-content tabs__content article-wizzard ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[123,85],[123,94]]]],"treatment"],[],["loc",[null,[123,81],[123,107]]]],"is-active",""],[],["loc",[null,[123,60],[123,124]]]]]]],
        ["inline","input",[],["type","checkbox","id","switchAnamnese","classNames","switch","name","switchAnamnese","checked",["subexpr","@mut",[["get","showAnamnese",["loc",[null,[131,108],[131,120]]]]],[],[]]],["loc",[null,[131,14],[131,122]]]],
        ["block","if",[["get","showAnamnese",["loc",[null,[136,14],[136,26]]]]],[],19,null,["loc",[null,[136,8],[173,15]]]],
        ["inline","input",[],["type","checkbox","id","switchSymptoms","classNames","switch","name","switchSymptoms","checked",["subexpr","@mut",[["get","showSymptoms",["loc",[null,[178,108],[178,120]]]]],[],[]]],["loc",[null,[178,14],[178,122]]]],
        ["block","if",[["get","showSymptoms",["loc",[null,[183,14],[183,26]]]]],[],20,null,["loc",[null,[183,8],[220,15]]]],
        ["inline","input",[],["type","checkbox","id","switchDiagnose","classNames","switch","name","switchDiagnose","checked",["subexpr","@mut",[["get","showDiagnoses",["loc",[null,[226,108],[226,121]]]]],[],[]]],["loc",[null,[226,14],[226,123]]]],
        ["block","if",[["get","showDiagnoses",["loc",[null,[232,14],[232,27]]]]],[],21,null,["loc",[null,[232,8],[266,15]]]],
        ["inline","input",[],["type","checkbox","id","switchTherapy","classNames","switch","name","switchTherapy","checked",["subexpr","@mut",[["get","showTherapies",["loc",[null,[272,106],[272,119]]]]],[],[]]],["loc",[null,[272,14],[272,121]]]],
        ["block","if",[["get","showTherapies",["loc",[null,[278,14],[278,27]]]]],[],22,null,["loc",[null,[278,8],[307,15]]]],
        ["inline","input",[],["type","checkbox","id","switchReport","classNames","switch","name","switchReport","checked",["subexpr","@mut",[["get","showReport",["loc",[null,[313,104],[313,114]]]]],[],[]]],["loc",[null,[313,14],[313,116]]]],
        ["block","if",[["get","showReport",["loc",[null,[319,14],[319,24]]]]],[],23,null,["loc",[null,[319,8],[336,15]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[338,69],[338,78]]]],"sloping"],[],["loc",[null,[338,65],[338,89]]]],"is-active",""],[],["loc",[null,[338,44],[338,106]]]]," "]]],
        ["block","if",[["get","model.imported",["loc",[null,[341,18],[341,32]]]]],[],24,null,["loc",[null,[341,12],[351,19]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","model.doseMethod",["loc",[null,[356,44],[356,60]]]]],[],[]],"class","","name","product-doseMethod","placeholder",""],["loc",[null,[356,18],[357,45]]]],
        ["block","each",[["get","dosings",["loc",[null,[373,24],[373,31]]]]],[],25,null,["loc",[null,[373,16],[405,25]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[412,69],[412,78]]]],"reorder"],[],["loc",[null,[412,65],[412,89]]]],"is-active",""],[],["loc",[null,[412,44],[412,106]]]]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[421,89],[421,116]]]],["get","model.purchaseRelevant",["loc",[null,[421,117],[421,139]]]]],[],["loc",[null,[421,84],[421,140]]]]],[],26,null,["loc",[null,[421,78],[421,180]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[422,89],[422,116]]]],["get","model.purchaseRelevant",["loc",[null,[422,117],[422,139]]]]],[],["loc",[null,[422,84],[422,140]]]]],[],27,null,["loc",[null,[422,78],[422,167]]]],
        ["block","each",[["get","purchaseProduct.reorderSettings",["loc",[null,[426,22],[426,53]]]]],[],28,null,["loc",[null,[426,14],[499,23]]]],
        ["block","if",[["subexpr","and",[["get","model.purchaseRelevant",["loc",[null,[503,17],[503,39]]]],["subexpr","is-operating-country",["AT"],[],["loc",[null,[503,40],[503,67]]]]],[],["loc",[null,[503,12],[503,68]]]]],[],29,null,["loc",[null,[503,6],[522,13]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[524,69],[524,78]]]],"inventory"],[],["loc",[null,[524,65],[524,91]]]],"is-active",""],[],["loc",[null,[524,44],[524,108]]]]," "]]],
        ["inline","input",[],["type","checkbox","name","productEhapo","id","productEhapo","checked",["subexpr","@mut",[["get","model.ehapoActive",["loc",[null,[532,86],[532,103]]]]],[],[]]],["loc",[null,[532,16],[532,105]]]],
        ["attribute","class",["concat",["form-field-rework ",["subexpr","css-bool-evaluator",[["get","model.globalAutoBookingActive",["loc",[null,[538,61],[538,90]]]],"hidden",""],[],["loc",[null,[538,40],[538,104]]]]]]],
        ["inline","input",[],["type","checkbox","name","productAutoBooking","id","productAutoBooking","checked",["subexpr","@mut",[["get","model.ehapoAutoBookingActive",["loc",[null,[541,98],[541,126]]]]],[],[]]],["loc",[null,[541,16],[541,128]]]],
        ["inline","stock-details",[],["updateBatchNumber","updateBatchNumber","updateExpiry","updateExpiry","updateLocation","updateLocation","showActionButtons",false,"showInventoryDetailsButton",true,"showInvoice","showInvoice","showPatient","showPatient","showHeader",false,"printBarcode","printBarcode","showStock","showStock","bookoutMovement","bookoutMovement","transfer","transfer","bookout","bookout","actionReceiver",["subexpr","@mut",[["get","stockDetailPanel",["loc",[null,[551,386],[551,402]]]]],[],[]]],["loc",[null,[551,8],[551,404]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[553,69],[553,78]]]],"adddata"],[],["loc",[null,[553,65],[553,89]]]],"is-active",""],[],["loc",[null,[553,44],[553,106]]]]," "]]],
        ["content","model.description",["loc",[null,[557,13],[557,34]]]],
        ["block","if",[["get","model.licenceNumberEu",["loc",[null,[559,16],[559,37]]]]],[],30,null,["loc",[null,[559,10],[562,17]]]],
        ["block","if",[["get","model.licenceNumberAges",["loc",[null,[563,16],[563,39]]]]],[],31,null,["loc",[null,[563,10],[566,17]]]],
        ["block","if",[["get","model.licenceNumberApo",["loc",[null,[567,16],[567,38]]]]],[],32,null,["loc",[null,[567,10],[570,17]]]],
        ["block","if",[["get","model.slopingInfo",["loc",[null,[571,16],[571,33]]]]],[],33,null,["loc",[null,[571,10],[574,17]]]],
        ["block","if",[["get","model.substances",["loc",[null,[576,16],[576,32]]]]],[],34,null,["loc",[null,[576,10],[579,17]]]],
        ["block","if",[["get","model.domainInfo",["loc",[null,[581,16],[581,32]]]]],[],35,null,["loc",[null,[581,10],[584,17]]]],
        ["block","if",[["subexpr","or",[["get","model.info",["loc",[null,[586,20],[586,30]]]],["get","model.detailUrl",["loc",[null,[586,31],[586,46]]]]],[],["loc",[null,[586,16],[586,47]]]]],[],36,null,["loc",[null,[586,10],[589,17]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[590,25],[590,42]]]],"jac"],[],["loc",[null,[590,21],[590,49]]]],["get","model.licenceNumber",["loc",[null,[590,50],[590,69]]]]],[],["loc",[null,[590,16],[590,70]]]]],[],37,null,["loc",[null,[590,10],[596,17]]]],
        ["block","if",[["get","model.targetRace",["loc",[null,[598,16],[598,32]]]]],[],38,null,["loc",[null,[598,10],[601,17]]]],
        ["attribute","class",["concat",["card-content  tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[606,70],[606,79]]]],"debug"],[],["loc",[null,[606,66],[606,88]]]],"is-active",""],[],["loc",[null,[606,45],[606,105]]]]," "]]],
        ["inline","time-format",[["get","model.lastImport",["loc",[null,[608,25],[608,41]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[608,11],[608,64]]]],
        ["content","model.primarySupplierId",["loc",[null,[610,11],[610,38]]]],
        ["content","model.importDetails",["loc",[null,[610,39],[610,62]]]],
        ["block","if",[["get","model.migrated",["loc",[null,[612,17],[612,31]]]]],[],39,40,["loc",[null,[612,11],[612,118]]]],
        ["block","if",[["get","model.richterCase",["loc",[null,[612,125],[612,142]]]]],[],41,null,["loc",[null,[612,119],[612,179]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[619,6],[619,19]]]]],[],42,43,["loc",[null,[619,0],[655,7]]]],
        ["content","button-prev",["loc",[null,[658,69],[658,84]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[659,12],[659,21]]]],"masterdata"],[],["loc",[null,[659,8],[659,35]]]]],[],44,null,["loc",[null,[659,2],[663,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[664,12],[664,21]]]],"sloping"],[],["loc",[null,[664,8],[664,32]]]]],[],45,null,["loc",[null,[664,2],[668,9]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[669,16],[669,25]]]],"inventory"],[],["loc",[null,[669,12],[669,38]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[669,43],[669,52]]]],"reorder"],[],["loc",[null,[669,39],[669,63]]]]],[],["loc",[null,[669,8],[669,64]]]]],[],46,47,["loc",[null,[669,2],[681,9]]]],
        ["inline","edit-sloping-panel",[],["save","saveSlopingInfo","actionReceiver",["subexpr","@mut",[["get","editSlopingPanel",["loc",[null,[684,59],[684,75]]]]],[],[]]],["loc",[null,[684,0],[684,77]]]],
        ["inline","product-sloping-select-barcode-panel",[],["actionReceiver",["subexpr","@mut",[["get","productSlopingSelectBarcodePanel",["loc",[null,[685,54],[685,86]]]]],[],[]]],["loc",[null,[685,0],[685,88]]]],
        ["inline","treatment-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[687,17],[687,37]]]]],[],[]]],["loc",[null,[686,0],[687,39]]]],
        ["inline","treatment-multistage-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentMultiStageSelectPanel",["loc",[null,[689,17],[689,47]]]]],[],[]]],["loc",[null,[688,0],[689,49]]]],
        ["inline","treatment-select-service-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectServicePanel",["loc",[null,[691,17],[691,44]]]]],[],[]]],["loc",[null,[690,0],[691,46]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46, child47]
    };
  }()));

});
define('jason-frontend/templates/components/treatment-history', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mb30 grid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24 l-24/24@desk");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Mitarbeiter:in");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","text-right col l-12/24 l-24/24@desk");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Ernährung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Leistungen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Produkte");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Labor");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var element23 = dom.childAt(element22, [3]);
          var element24 = dom.childAt(element23, [1]);
          var element25 = dom.childAt(element23, [3]);
          var element26 = dom.childAt(element23, [5]);
          var element27 = dom.childAt(element23, [7]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element22, [1, 1]),3,3);
          morphs[1] = dom.createAttrMorph(element24, 'class');
          morphs[2] = dom.createElementMorph(element24);
          morphs[3] = dom.createAttrMorph(element25, 'class');
          morphs[4] = dom.createElementMorph(element25);
          morphs[5] = dom.createAttrMorph(element26, 'class');
          morphs[6] = dom.createElementMorph(element26);
          morphs[7] = dom.createAttrMorph(element27, 'class');
          morphs[8] = dom.createElementMorph(element27);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","users",["loc",[null,[7,18],[7,23]]]]],[],[]],"value",["subexpr","@mut",[["get","filterUser",["loc",[null,[8,16],[8,26]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","optionValuePath","id","placeholder","Alle","allowClear",true],["loc",[null,[6,8],[14,10]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showfeeding",["loc",[null,[18,59],[18,70]]]],"selected",""],[],["loc",[null,[18,38],[18,86]]]]]]],
          ["element","action",["toggleQuickfilter","showfeeding"],[],["loc",[null,[18,88],[19,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showservice",["loc",[null,[20,59],[20,70]]]],"selected",""],[],["loc",[null,[20,38],[20,86]]]]]]],
          ["element","action",["toggleQuickfilter","showservice"],[],["loc",[null,[20,88],[21,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showarticle",["loc",[null,[22,59],[22,70]]]],"selected",""],[],["loc",[null,[22,38],[22,86]]]]]]],
          ["element","action",["toggleQuickfilter","showarticle"],[],["loc",[null,[22,88],[23,112]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showlabor",["loc",[null,[24,59],[24,68]]]],"selected",""],[],["loc",[null,[24,38],[24,84]]]]]]],
          ["element","action",["toggleQuickfilter","showlabor"],[],["loc",[null,[24,86],[25,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-text--right l-24/24@desk  buttons-in-a-row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element21,1,1);
          morphs[1] = dom.createMorphAt(element21,3,3);
          morphs[2] = dom.createMorphAt(element21,5,5);
          morphs[3] = dom.createMorphAt(element21,7,7);
          return morphs;
        },
        statements: [
          ["inline","button-feeding",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[32,4],[32,75]]]],
          ["inline","button-service",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[33,4],[33,75]]]],
          ["inline","button-articles",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[34,4],[34,76]]]],
          ["inline","button-labor",[],["size",50,"toggle","filterHistory","toggleAll","toggleAll"],["loc",[null,[35,4],[35,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 50
            },
            "end": {
              "line": 42,
              "column": 191
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-all",[],["size",30,"showStroke",false,"classNames","auto-center","active",["subexpr","@mut",[["get","showToggleAll",["loc",[null,[42,154],[42,167]]]]],[],[]],"toggleAll","toggleAll"],["loc",[null,[42,77],[42,191]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 13
            },
            "end": {
              "line": 45,
              "column": 118
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-4/24");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","table__title title");
          var el3 = dom.createTextNode("Patient");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 128
            },
            "end": {
              "line": 48,
              "column": 196
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Ergebnisse in ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Behandlungen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["content","total",["loc",[null,[48,145],[48,154]]]],
          ["content","totalGroup",["loc",[null,[48,169],[48,183]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 16
                },
                "end": {
                  "line": 58,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","button-toggle",[],["entry",["subexpr","@mut",[["get","item",["loc",[null,[57,58],[57,62]]]]],[],[]],"toggle","toggle","content","Zeige erhaltene Produkte und Leistungen an"],["loc",[null,[57,36],[57,133]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 66,
                          "column": 22
                        },
                        "end": {
                          "line": 66,
                          "column": 169
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","icon-open-payment",[],["content","Offene Ausgangsrechnung","size","32","showStroke",true,"color","#EF5F4E"],["loc",[null,[66,72],[66,169]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 20
                      },
                      "end": {
                        "line": 67,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["invoices.create",["get","item.info.invoiceId",["loc",[null,[66,51],[66,70]]]]],[],0,null,["loc",[null,[66,22],[66,181]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 67,
                        "column": 20
                      },
                      "end": {
                        "line": 69,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-open-payment",[],["content","Noch nicht verrechnet","size","32","showStroke",true,"color","#4A4A4A"],["loc",[null,[68,22],[68,117]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 18
                    },
                    "end": {
                      "line": 70,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","item.info.invoiceId",["loc",[null,[65,26],[65,45]]]]],[],0,1,["loc",[null,[65,20],[69,27]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 71,
                        "column": 20
                      },
                      "end": {
                        "line": 71,
                        "column": 168
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-open-payment",[],["size","32","content","Bezahlte Ausgangsrechnung","showStroke",true,"color","#429321"],["loc",[null,[71,68],[71,168]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 18
                    },
                    "end": {
                      "line": 72,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["invoices.show",["get","item.info.invoiceId",["loc",[null,[71,47],[71,66]]]]],[],0,null,["loc",[null,[71,20],[71,180]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 16
                  },
                  "end": {
                    "line": 73,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","not",[["get","item.billed",["loc",[null,[64,29],[64,40]]]]],[],["loc",[null,[64,24],[64,41]]]]],[],0,1,["loc",[null,[64,18],[72,25]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 14
                },
                "end": {
                  "line": 74,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","showBillingInfo",["loc",[null,[63,27],[63,42]]]],["subexpr","show-prices",[],[],["loc",[null,[63,43],[63,56]]]]],[],["loc",[null,[63,22],[63,57]]]]],[],0,null,["loc",[null,[63,16],[73,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 12
                },
                "end": {
                  "line": 76,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["content","","classNames","inline-icon","size","24","width",50],["loc",[null,[75,14],[75,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 67
                },
                "end": {
                  "line": 80,
                  "column": 111
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.dayTime",["loc",[null,[80,95],[80,111]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 81,
                  "column": 18
                },
                "end": {
                  "line": 81,
                  "column": 220
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block small mb5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Std. ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Min.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element15,0,0);
              morphs[1] = dom.createMorphAt(element15,2,2);
              morphs[2] = dom.createMorphAt(element15,4,4);
              return morphs;
            },
            statements: [
              ["inline","button-duration",[],["color","#4A4A4A","classNames","inline-block verticaltop","size",16],["loc",[null,[81,76],[81,157]]]],
              ["content","item.durationHours",["loc",[null,[81,158],[81,180]]]],
              ["content","item.durationMinutes",["loc",[null,[81,185],[81,209]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 22
                  },
                  "end": {
                    "line": 84,
                    "column": 84
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Lokation auswählen");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 91
                  },
                  "end": {
                    "line": 84,
                    "column": 126
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.room.name",["loc",[null,[84,108],[84,126]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 18
                },
                "end": {
                  "line": 86,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","mt5 clickable is-highlighted input--editable");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element14);
              morphs[1] = dom.createMorphAt(element14,1,1);
              morphs[2] = dom.createMorphAt(element14,2,2);
              return morphs;
            },
            statements: [
              ["element","action",["editTreatmentRoom",["get","item",["loc",[null,[83,105],[83,109]]]]],[],["loc",[null,[83,76],[83,111]]]],
              ["block","if",[["subexpr","and",[["get","showChangeUser",["loc",[null,[84,33],[84,47]]]],["subexpr","not",[["get","item.room",["loc",[null,[84,53],[84,62]]]]],[],["loc",[null,[84,48],[84,63]]]]],[],["loc",[null,[84,28],[84,64]]]]],[],0,null,["loc",[null,[84,22],[84,91]]]],
              ["block","if",[["get","item.room",["loc",[null,[84,97],[84,106]]]]],[],1,null,["loc",[null,[84,91],[84,133]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 20
                  },
                  "end": {
                    "line": 87,
                    "column": 55
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.room.name",["loc",[null,[87,37],[87,55]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 18
                },
                "end": {
                  "line": 88,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.room",["loc",[null,[87,26],[87,35]]]]],[],0,null,["loc",[null,[87,20],[87,62]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 91,
                      "column": 101
                    },
                    "end": {
                      "line": 91,
                      "column": 334
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["color","#3B6182","classNames","inline-icon middle","evil",["subexpr","@mut",[["get","item.patient.evil",["loc",[null,[91,197],[91,214]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.patient.category.id",["loc",[null,[91,225],[91,249]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.patient.digitalRace.icon",["loc",[null,[91,258],[91,287]]]]],[],[]],"size","24","content",["subexpr","@mut",[["get","item.patient.category.name",["loc",[null,[91,306],[91,332]]]]],[],[]]],["loc",[null,[91,130],[91,334]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 34
                  },
                  "end": {
                    "line": 91,
                    "column": 363
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patient.category",["loc",[null,[91,107],[91,128]]]]],[],0,null,["loc",[null,[91,101],[91,341]]]],
                ["content","item.patient.name",["loc",[null,[91,342],[91,363]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 91,
                      "column": 454
                    },
                    "end": {
                      "line": 91,
                      "column": 626
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","gender-icon",[],["size","26","classNames","middle inline-icon customer-gender-icon","color","#3B6182","gender",["subexpr","@mut",[["get","item.patientOwner.gender.id",["loc",[null,[91,586],[91,613]]]]],[],[]],"content",""],["loc",[null,[91,486],[91,626]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 380
                  },
                  "end": {
                    "line": 91,
                    "column": 663
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patientOwner.gender",["loc",[null,[91,460],[91,484]]]]],[],0,null,["loc",[null,[91,454],[91,633]]]],
                ["content","item.patientOwner.fullName",["loc",[null,[91,633],[91,663]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 676
                  },
                  "end": {
                    "line": 91,
                    "column": 828
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-ical",[],["size",24,"classNames","inline-block verticaltop","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[91,719],[91,828]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 91,
                      "column": 828
                    },
                    "end": {
                      "line": 91,
                      "column": 994
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-ical",[],["size",24,"classNames","inline-block verticaltop","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[91,876],[91,994]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 828
                  },
                  "end": {
                    "line": 91,
                    "column": 994
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.patientOwner.etigaCodeRequested",["loc",[null,[91,838],[91,874]]]]],[],0,null,["loc",[null,[91,828],[91,994]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 17
                },
                "end": {
                  "line": 92,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__cell l-12/24 l-6/24@tablet l-4/24@desk");
              dom.setAttribute(el1,"data-label","Patient");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","like-h3");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("br");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [0, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element13,0,0);
              morphs[1] = dom.createMorphAt(element13,2,2);
              morphs[2] = dom.createMorphAt(element13,4,4);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","item.patient.id",["loc",[null,[91,61],[91,76]]]]],["class","is-highlighted"],0,null,["loc",[null,[91,34],[91,375]]]],
              ["block","link-to",["customers.edit",["get","item.patientOwner.id",["loc",[null,[91,408],[91,428]]]]],["class","is-highlighted"],1,null,["loc",[null,[91,380],[91,675]]]],
              ["block","if",[["get","item.patientOwner.etigaAppConnected",["loc",[null,[91,682],[91,717]]]]],[],2,3,["loc",[null,[91,676],[91,1001]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 16
                    },
                    "end": {
                      "line": 98,
                      "column": 92
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("auswählen");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 99
                    },
                    "end": {
                      "line": 98,
                      "column": 173
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","item.doctor.firstname",["loc",[null,[98,118],[98,143]]]],
                  ["content","item.doctor.lastname",["loc",[null,[98,144],[98,168]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 180
                    },
                    "end": {
                      "line": 98,
                      "column": 258
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.assistant.firstname",["loc",[null,[98,202],[98,230]]]],
                  ["content","item.assistant.lastname",["loc",[null,[98,231],[98,258]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 96,
                    "column": 18
                  },
                  "end": {
                    "line": 100,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","clickable is-highlighted input--editable");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element12 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createElementMorph(element12);
                morphs[1] = dom.createMorphAt(element12,1,1);
                morphs[2] = dom.createMorphAt(element12,2,2);
                morphs[3] = dom.createMorphAt(element12,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["editTreatmentEmployees",["get","item",["loc",[null,[97,106],[97,110]]]]],[],["loc",[null,[97,72],[97,112]]]],
                ["block","if",[["subexpr","and",[["get","showChangeUser",["loc",[null,[98,27],[98,41]]]],["subexpr","not",[["get","item.assistant",["loc",[null,[98,47],[98,61]]]]],[],["loc",[null,[98,42],[98,62]]]],["subexpr","not",[["get","item.doctor",["loc",[null,[98,68],[98,79]]]]],[],["loc",[null,[98,63],[98,80]]]]],[],["loc",[null,[98,22],[98,81]]]]],[],0,null,["loc",[null,[98,16],[98,99]]]],
                ["block","if",[["get","item.doctor",["loc",[null,[98,105],[98,116]]]]],[],1,null,["loc",[null,[98,99],[98,180]]]],
                ["block","if",[["get","item.assistant",["loc",[null,[98,186],[98,200]]]]],[],2,null,["loc",[null,[98,180],[98,265]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 101,
                      "column": 18
                    },
                    "end": {
                      "line": 101,
                      "column": 92
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["content","item.doctor.firstname",["loc",[null,[101,37],[101,62]]]],
                  ["content","item.doctor.lastname",["loc",[null,[101,63],[101,87]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 101,
                      "column": 99
                    },
                    "end": {
                      "line": 101,
                      "column": 177
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.assistant.firstname",["loc",[null,[101,121],[101,149]]]],
                  ["content","item.assistant.lastname",["loc",[null,[101,150],[101,177]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 100,
                    "column": 16
                  },
                  "end": {
                    "line": 102,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.doctor",["loc",[null,[101,24],[101,35]]]]],[],0,null,["loc",[null,[101,18],[101,99]]]],
                ["block","if",[["get","item.assistant",["loc",[null,[101,105],[101,119]]]]],[],1,null,["loc",[null,[101,99],[101,184]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 95,
                  "column": 14
                },
                "end": {
                  "line": 103,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","has-permission",["u_treatment_history"],[],["loc",[null,[96,24],[96,62]]]]],[],0,1,["loc",[null,[96,18],[102,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child9 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 14
                },
                "end": {
                  "line": 105,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item.mandantName",["loc",[null,[104,16],[104,36]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child10 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 109,
                  "column": 10
                },
                "end": {
                  "line": 111,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            Abgabe Produkte/Leistungen\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child11 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 112,
                      "column": 95
                    },
                    "end": {
                      "line": 112,
                      "column": 338
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" > ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small");
                  var el2 = dom.createElement("b");
                  var el3 = dom.createTextNode("Anmerkung:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]),2,2);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.digitalTopDiagnose",["loc",[null,[112,153],[112,181]]]]],[],["loc",[null,[112,128],[112,183]]]],
                  ["inline","get-value-or-emptySign",[["get","item.info.digitalDiagnose",["loc",[null,[112,211],[112,236]]]]],[],["loc",[null,[112,186],[112,238]]]],
                  ["inline","get-value-or-emptySign",[["get","item.info.diagnose",["loc",[null,[112,311],[112,329]]]]],[],["loc",[null,[112,286],[112,331]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 112,
                      "column": 338
                    },
                    "end": {
                      "line": 112,
                      "column": 393
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.diagnose",["loc",[null,[112,372],[112,390]]]]],[],["loc",[null,[112,346],[112,393]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 12
                  },
                  "end": {
                    "line": 113,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.info.digitalDiagnose",["loc",[null,[112,101],[112,126]]]]],[],0,1,["loc",[null,[112,95],[112,400]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 10
                },
                "end": {
                  "line": 114,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[112,22],[112,32]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[112,62],[112,78]]]],["get","item.patient",["loc",[null,[112,79],[112,91]]]]],[],["loc",[null,[112,33],[112,92]]]]],[],["loc",[null,[112,18],[112,93]]]]],[],0,null,["loc",[null,[112,12],[113,19]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child12 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 12
                },
                "end": {
                  "line": 122,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element11);
              morphs[1] = dom.createMorphAt(element11,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undelete",["get","item.id",["loc",[null,[119,97],[119,104]]]]],[],["loc",[null,[119,77],[119,106]]]],
              ["inline","button-checked",[],["content","Gelöschte Behandlung wiederherstellen","color","#fff","showStroke",false],["loc",[null,[120,16],[120,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child13 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 123,
                    "column": 14
                  },
                  "end": {
                    "line": 127,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element10);
                morphs[1] = dom.createMorphAt(element10,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["copy",["get","model.id",["loc",[null,[124,35],[124,43]]]],["get","item.id",["loc",[null,[124,44],[124,51]]]]],[],["loc",[null,[124,19],[124,53]]]],
                ["inline","button-copy-list",[],["showStroke",false,"size","30","color","#fff","classNames",""],["loc",[null,[125,18],[125,92]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 14
                  },
                  "end": {
                    "line": 133,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element9);
                morphs[1] = dom.createMorphAt(element9,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["forwardToBilling",["get","item.id",["loc",[null,[129,107],[129,114]]]]],[],["loc",[null,[129,79],[129,116]]]],
                ["inline","button-ready4payment",[],["showStroke",false,"color","#fff","item",["subexpr","@mut",[["get","item",["loc",[null,[130,76],[130,80]]]]],[],[]],"size",30,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[130,18],[131,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 135,
                      "column": 16
                    },
                    "end": {
                      "line": 141,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[137,98],[137,102]]]]],[],[]],"confirmed","deleteTreatment","text","Wollen Sie diese Behandlung wirklich löschen?"],["loc",[null,[137,20],[139,103]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 143,
                        "column": 18
                      },
                      "end": {
                        "line": 148,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element8);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["open",["get","item.info.id",["loc",[null,[144,39],[144,51]]]],["get","item.patient",["loc",[null,[144,52],[144,64]]]],0,["get","etigaPermissions",["loc",[null,[144,67],[144,83]]]],["get","item.owner",["loc",[null,[144,84],[144,94]]]]],[],["loc",[null,[144,23],[144,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 142,
                      "column": 16
                    },
                    "end": {
                      "line": 149,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","not",[["get","item.appEditable",["loc",[null,[143,29],[143,45]]]]],[],["loc",[null,[143,24],[143,46]]]]],[],0,null,["loc",[null,[143,18],[148,25]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 14
                  },
                  "end": {
                    "line": 150,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[135,27],[135,37]]]]],[],["loc",[null,[135,22],[135,38]]]]],[],0,null,["loc",[null,[135,16],[141,23]]]],
                ["block","unless",[["get","item.info.virtualTreatment",["loc",[null,[142,26],[142,52]]]]],[],1,null,["loc",[null,[142,16],[149,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 152,
                      "column": 16
                    },
                    "end": {
                      "line": 156,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element7);
                  morphs[1] = dom.createMorphAt(element7,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["hide",["get","item.id",["loc",[null,[153,97],[153,104]]]],["get","item.hidden",["loc",[null,[153,105],[153,116]]]]],[],["loc",[null,[153,81],[153,118]]]],
                  ["inline","button-unhide",[],["content","einblenden","color","#fff","showStroke",false,"classNames","ml10"],["loc",[null,[154,20],[154,106]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 156,
                      "column": 16
                    },
                    "end": {
                      "line": 163,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-hide-with-confirm",[],["content","ausblenden","color","#fff","id",["subexpr","concat",["hide",["get","item.id",["loc",[null,[158,99],[158,106]]]]],[],["loc",[null,[158,84],[158,107]]]],"hidden",["subexpr","@mut",[["get","item.hidden",["loc",[null,[159,54],[159,65]]]]],[],[]],"showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[159,102],[159,106]]]]],[],[]],"confirmed","hide","text","Wollen Sie diese Behandlungsdetails wirklich ausblenden?"],["loc",[null,[158,20],[161,112]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 14
                  },
                  "end": {
                    "line": 164,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.hidden",["loc",[null,[152,22],[152,33]]]]],[],0,1,["loc",[null,[152,16],[163,23]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child4 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 167,
                      "column": 18
                    },
                    "end": {
                      "line": 169,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-shared-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","In App geteilt"],["loc",[null,[168,20],[168,122]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 169,
                      "column": 18
                    },
                    "end": {
                      "line": 171,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-unshared-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content","Nicht in App geteilt"],["loc",[null,[170,20],[170,130]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 176,
                          "column": 18
                        },
                        "end": {
                          "line": 183,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"style","border: 2px black solid");
                      dom.setAttribute(el1,"class","icon-button list-action-square");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-hide-app-with-confirm",[],["confirmed","hideForAppOverride","size","28","refId",["subexpr","concat",["appHide",["get","item.id",["loc",[null,[179,77],[179,84]]]]],[],["loc",[null,[179,59],[179,85]]]],"hiddenForApp",["subexpr","@mut",[["get","item.hideAppOverride",["loc",[null,[179,99],[179,119]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App BenutzerInnen wirklich ausblenden?"],["loc",[null,[178,22],[181,139]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 183,
                          "column": 18
                        },
                        "end": {
                          "line": 189,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"style","border: 2px black solid");
                      dom.setAttribute(el1,"class","icon-button icon-button--default  list-action-square");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element5 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element5);
                      morphs[1] = dom.createMorphAt(element5,1,1);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["hideForAppOverride",["get","item.id",["loc",[null,[184,85],[184,92]]]],["get","item.hideAppOverride",["loc",[null,[184,93],[184,113]]]]],[],["loc",[null,[184,55],[184,115]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[187,70],[187,81]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[187,50],[187,145]]]]],["loc",[null,[186,22],[187,147]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 175,
                        "column": 16
                      },
                      "end": {
                        "line": 190,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","item.hideAppOverride",["loc",[null,[176,24],[176,44]]]]],[],0,1,["loc",[null,[176,18],[189,25]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 191,
                          "column": 18
                        },
                        "end": {
                          "line": 197,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"style","border: 2px black solid");
                      dom.setAttribute(el1,"class","icon-button icon-button--default  list-action-square");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element4 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createElementMorph(element4);
                      morphs[1] = dom.createMorphAt(element4,1,1);
                      return morphs;
                    },
                    statements: [
                      ["element","action",["hideForApp",["get","item.id",["loc",[null,[192,77],[192,84]]]],["get","item.hiddenForApp",["loc",[null,[192,85],[192,102]]]]],[],["loc",[null,[192,55],[192,104]]]],
                      ["inline","button-unhide-app",[],["size",28,"showStroke",false,"innerColor","#fff","color","#fff","content",["subexpr","css-bool-evaluator",[["get","item.billed",["loc",[null,[195,70],[195,81]]]],"In eltiga App ausgeblendet","Ausgeblendet weil nicht bezahlt"],[],["loc",[null,[195,50],[195,145]]]]],["loc",[null,[194,22],[195,147]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 197,
                          "column": 18
                        },
                        "end": {
                          "line": 204,
                          "column": 18
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1,"style","border: 2px black solid");
                      dom.setAttribute(el1,"class","icon-button list-action-square");
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-hide-app-with-confirm",[],["confirmed","hideForApp","size","28","refId",["subexpr","concat",["appHide",["get","item.id",["loc",[null,[199,110],[199,117]]]]],[],["loc",[null,[199,92],[199,118]]]],"hiddenForApp",["subexpr","@mut",[["get","item.hiddenForApp",["loc",[null,[200,66],[200,83]]]]],[],[]],"classNames","","color","#3B6182","content","In eltiga App sichtbar","text","Wollen Sie diese Behandlung für eltiga App BenutzerInnen wirklich ausblenden?"],["loc",[null,[199,22],[202,139]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 190,
                        "column": 16
                      },
                      "end": {
                        "line": 205,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","item.hiddenForApp",["loc",[null,[191,24],[191,41]]]]],[],0,1,["loc",[null,[191,18],[204,25]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 173,
                      "column": 14
                    },
                    "end": {
                      "line": 206,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","and",[["get","item.globalAppOverride",["loc",[null,[175,27],[175,49]]]],["subexpr","not",[["get","item.billed",["loc",[null,[175,55],[175,66]]]]],[],["loc",[null,[175,50],[175,67]]]]],[],["loc",[null,[175,22],[175,68]]]]],[],0,1,["loc",[null,[175,16],[205,25]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 165,
                    "column": 14
                  },
                  "end": {
                    "line": 207,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element6, 'class');
                morphs[1] = dom.createElementMorph(element6);
                morphs[2] = dom.createMorphAt(element6,1,1);
                morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["icon-button list-action-square ",["subexpr","css-bool-evaluator",[["get","item.shareApp",["loc",[null,[166,111],[166,124]]]],"","icon-button--default"],[],["loc",[null,[166,90],[166,152]]]]]]],
                ["element","action",["toggleAppShare",["get","item",["loc",[null,[166,45],[166,49]]]]],[],["loc",[null,[166,19],[166,51]]]],
                ["block","if",[["get","item.shareApp",["loc",[null,[167,24],[167,37]]]]],[],0,1,["loc",[null,[167,18],[171,25]]]],
                ["block","if",[["subexpr","is-debug-user",[],[],["loc",[null,[173,20],[173,35]]]]],[],2,null,["loc",[null,[173,14],[206,23]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 122,
                  "column": 12
                },
                "end": {
                  "line": 208,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.hasEntries",["loc",[null,[123,25],[123,40]]]],["get","showCopy",["loc",[null,[123,41],[123,49]]]]],[],["loc",[null,[123,20],[123,50]]]]],[],0,null,["loc",[null,[123,14],[127,21]]]],
              ["block","if",[["subexpr","and",[["subexpr","show-prices",[],[],["loc",[null,[128,25],[128,38]]]],["subexpr","not",[["get","item.info.invoiceId",["loc",[null,[128,44],[128,63]]]]],[],["loc",[null,[128,39],[128,64]]]],["subexpr","not",[["get","item.billed",["loc",[null,[128,70],[128,81]]]]],[],["loc",[null,[128,65],[128,82]]]],["get","item.hasEntries",["loc",[null,[128,83],[128,98]]]]],[],["loc",[null,[128,20],[128,99]]]]],[],1,null,["loc",[null,[128,14],[133,21]]]],
              ["block","if",[["get","showEdit",["loc",[null,[134,20],[134,28]]]]],[],2,null,["loc",[null,[134,14],[150,21]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.appEditable",["loc",[null,[151,30],[151,46]]]]],[],["loc",[null,[151,25],[151,47]]]],["get","showHide",["loc",[null,[151,48],[151,56]]]]],[],["loc",[null,[151,20],[151,57]]]]],[],3,null,["loc",[null,[151,14],[164,21]]]],
              ["block","if",[["subexpr","and",[["get","showHideApp",["loc",[null,[165,25],[165,36]]]],["get","item.owner",["loc",[null,[165,37],[165,47]]]]],[],["loc",[null,[165,20],[165,48]]]]],[],4,null,["loc",[null,[165,14],[207,21]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        }());
        var child14 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 211,
                  "column": 14
                },
                "end": {
                  "line": 212,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child15 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 213,
                    "column": 16
                  },
                  "end": {
                    "line": 216,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("b");
                dom.setAttribute(el2,"class","is-error");
                var el3 = dom.createTextNode("Behandlung wurde gelöscht");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 218,
                    "column": 67
                  },
                  "end": {
                    "line": 218,
                    "column": 203
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.medicalHistory",["loc",[null,[218,176],[218,200]]]]],[],["loc",[null,[218,150],[218,203]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 219,
                    "column": 16
                  },
                  "end": {
                    "line": 220,
                    "column": 139
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Vorstellungsgründe:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.introductionReasons",["loc",[null,[220,89],[220,118]]]],", "],[],["loc",[null,[220,67],[220,126]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 221,
                    "column": 16
                  },
                  "end": {
                    "line": 222,
                    "column": 125
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Administrative Tätigkeiten:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.adminTasks",["loc",[null,[222,89],[222,109]]]]],[],["loc",[null,[222,67],[222,112]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 224,
                    "column": 67
                  },
                  "end": {
                    "line": 224,
                    "column": 194
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.symptom",["loc",[null,[224,175],[224,192]]]]],[],["loc",[null,[224,150],[224,194]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 225,
                    "column": 16
                  },
                  "end": {
                    "line": 226,
                    "column": 123
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Klinische Symptomatik:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.symptoms",["loc",[null,[226,89],[226,107]]]]],[],["loc",[null,[226,67],[226,110]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 230,
                        "column": 34
                      },
                      "end": {
                        "line": 230,
                        "column": 71
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" (");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(")");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","dia.details",["loc",[null,[230,55],[230,70]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 230,
                        "column": 78
                      },
                      "end": {
                        "line": 230,
                        "column": 146
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(", ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 18
                    },
                    "end": {
                      "line": 231,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","dia.name",["loc",[null,[230,20],[230,34]]]],
                  ["block","if",[["get","dia.details",["loc",[null,[230,40],[230,51]]]]],[],0,null,["loc",[null,[230,34],[230,78]]]],
                  ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[230,92],[230,97]]]],["subexpr","minus",[["get","item.info.digitalDiagnoses.length",["loc",[null,[230,105],[230,138]]]],1],[],["loc",[null,[230,98],[230,141]]]]],[],["loc",[null,[230,88],[230,142]]]]],[],1,null,["loc",[null,[230,78],[230,157]]]]
                ],
                locals: ["dia"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 227,
                    "column": 16
                  },
                  "end": {
                    "line": 232,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Vorläufige Diagnosen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.info.digitalDiagnoses",["loc",[null,[229,26],[229,52]]]]],[],0,null,["loc",[null,[229,18],[231,27]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child7 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 236,
                        "column": 35
                      },
                      "end": {
                        "line": 236,
                        "column": 72
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(" (");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(")");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","dia.details",["loc",[null,[236,56],[236,71]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 236,
                        "column": 79
                      },
                      "end": {
                        "line": 236,
                        "column": 152
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode(", ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 235,
                      "column": 19
                    },
                    "end": {
                      "line": 237,
                      "column": 19
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                     ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","dia.name",["loc",[null,[236,21],[236,35]]]],
                  ["block","if",[["get","dia.details",["loc",[null,[236,41],[236,52]]]]],[],0,null,["loc",[null,[236,35],[236,79]]]],
                  ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[236,93],[236,98]]]],["subexpr","minus",[["get","item.info.finalDigitalDiagnoses.length",["loc",[null,[236,106],[236,144]]]],1],[],["loc",[null,[236,99],[236,147]]]]],[],["loc",[null,[236,89],[236,148]]]]],[],1,null,["loc",[null,[236,79],[236,163]]]]
                ],
                locals: ["dia","index"],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 233,
                    "column": 16
                  },
                  "end": {
                    "line": 238,
                    "column": 29
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Finale Diagnosen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","item.info.finalDigitalDiagnoses",["loc",[null,[235,27],[235,58]]]]],[],0,null,["loc",[null,[235,19],[237,28]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 240,
                    "column": 67
                  },
                  "end": {
                    "line": 240,
                    "column": 195
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","get-value-or-emptySign",[["get","item.info.therapy",["loc",[null,[240,176],[240,193]]]]],[],["loc",[null,[240,151],[240,195]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child9 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 241,
                    "column": 16
                  },
                  "end": {
                    "line": 242,
                    "column": 131
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Therapeutische Behandlungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","print-list-of-name",[["get","item.info.digitalTherapies",["loc",[null,[242,89],[242,115]]]]],[],["loc",[null,[242,67],[242,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child10 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 244,
                      "column": 67
                    },
                    "end": {
                      "line": 244,
                      "column": 195
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","get-value-or-emptySign",[["get","item.info.report",["loc",[null,[244,176],[244,192]]]]],[],["loc",[null,[244,150],[244,195]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 243,
                    "column": 16
                  },
                  "end": {
                    "line": 244,
                    "column": 215
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Beratung:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[244,77],[244,87]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[244,117],[244,133]]]],["get","item.patient",["loc",[null,[244,134],[244,146]]]]],[],["loc",[null,[244,88],[244,147]]]]],[],["loc",[null,[244,73],[244,148]]]]],[],0,null,["loc",[null,[244,67],[244,202]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child11 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 246,
                      "column": 182
                    },
                    "end": {
                      "line": 246,
                      "column": 232
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\"");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\"");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","item.ratingComment",["loc",[null,[246,209],[246,231]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 245,
                    "column": 16
                  },
                  "end": {
                    "line": 246,
                    "column": 252
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@deskmt10");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Feedback:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk mt10");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","ml10 inline");
                dom.setAttribute(el2,"style","font-style: italic");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [2]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3,0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
                return morphs;
              },
              statements: [
                ["inline","rating-status",[],["rating",["subexpr","@mut",[["get","item.rating",["loc",[null,[246,89],[246,100]]]]],[],[]],"classNames","inline-block"],["loc",[null,[246,66],[246,128]]]],
                ["block","if",[["get","item.ratingComment",["loc",[null,[246,188],[246,206]]]]],[],0,null,["loc",[null,[246,182],[246,239]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child12 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 247,
                    "column": 16
                  },
                  "end": {
                    "line": 248,
                    "column": 112
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
                var el2 = dom.createElement("b");
                var el3 = dom.createTextNode("Tierärztliche Leistungen:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [2, 0]),0,0);
                return morphs;
              },
              statements: [
                ["content","item.info.manualTreatments",["loc",[null,[248,67],[248,99]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 212,
                  "column": 14
                },
                "end": {
                  "line": 249,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Anamnese:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Symptome:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-6/24 l-5/24@desk");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Therapie:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col l-18/24 l-17/24@desk");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(13);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4, 0]),0,0);
              morphs[2] = dom.createMorphAt(fragment,6,6,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,8,8,contextualElement);
              morphs[4] = dom.createMorphAt(dom.childAt(fragment, [12, 0]),0,0);
              morphs[5] = dom.createMorphAt(fragment,14,14,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,16,16,contextualElement);
              morphs[7] = dom.createMorphAt(fragment,18,18,contextualElement);
              morphs[8] = dom.createMorphAt(dom.childAt(fragment, [22, 0]),0,0);
              morphs[9] = dom.createMorphAt(fragment,24,24,contextualElement);
              morphs[10] = dom.createMorphAt(fragment,26,26,contextualElement);
              morphs[11] = dom.createMorphAt(fragment,28,28,contextualElement);
              morphs[12] = dom.createMorphAt(fragment,30,30,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.deleted",["loc",[null,[213,22],[213,34]]]]],[],0,null,["loc",[null,[213,16],[216,23]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[218,77],[218,87]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[218,117],[218,133]]]],["get","item.patient",["loc",[null,[218,134],[218,146]]]]],[],["loc",[null,[218,88],[218,147]]]]],[],["loc",[null,[218,73],[218,148]]]]],[],1,null,["loc",[null,[218,67],[218,210]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[219,31],[219,41]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[219,71],[219,87]]]],["get","item.patient",["loc",[null,[219,88],[219,100]]]]],[],["loc",[null,[219,42],[219,101]]]]],[],["loc",[null,[219,27],[219,102]]]],["get","item.info.introductionReasons",["loc",[null,[219,103],[219,132]]]]],[],["loc",[null,[219,22],[219,133]]]]],[],2,null,["loc",[null,[219,16],[220,146]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[221,31],[221,41]]]],["subexpr","is-etiga-readable",["addAnam",["get","etigaPermissions",["loc",[null,[221,71],[221,87]]]],["get","item.patient",["loc",[null,[221,88],[221,100]]]]],[],["loc",[null,[221,42],[221,101]]]]],[],["loc",[null,[221,27],[221,102]]]],["get","item.info.adminTasks",["loc",[null,[221,103],[221,123]]]]],[],["loc",[null,[221,22],[221,124]]]]],[],3,null,["loc",[null,[221,16],[222,132]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[224,77],[224,87]]]],["subexpr","is-etiga-readable",["addSymp",["get","etigaPermissions",["loc",[null,[224,117],[224,133]]]],["get","item.patient",["loc",[null,[224,134],[224,146]]]]],[],["loc",[null,[224,88],[224,147]]]]],[],["loc",[null,[224,73],[224,148]]]]],[],4,null,["loc",[null,[224,67],[224,201]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[225,31],[225,41]]]],["subexpr","is-etiga-readable",["addSymp",["get","etigaPermissions",["loc",[null,[225,71],[225,87]]]],["get","item.patient",["loc",[null,[225,88],[225,100]]]]],[],["loc",[null,[225,42],[225,101]]]]],[],["loc",[null,[225,27],[225,102]]]],["get","item.info.symptoms",["loc",[null,[225,103],[225,121]]]]],[],["loc",[null,[225,22],[225,122]]]]],[],5,null,["loc",[null,[225,16],[226,130]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[227,31],[227,41]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[227,71],[227,87]]]],["get","item.patient",["loc",[null,[227,88],[227,100]]]]],[],["loc",[null,[227,42],[227,101]]]]],[],["loc",[null,[227,27],[227,102]]]],["get","item.info.digitalDiagnoses",["loc",[null,[227,103],[227,129]]]]],[],["loc",[null,[227,22],[227,130]]]]],[],6,null,["loc",[null,[227,16],[232,36]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[233,31],[233,41]]]],["subexpr","is-etiga-readable",["addDiag",["get","etigaPermissions",["loc",[null,[233,71],[233,87]]]],["get","item.patient",["loc",[null,[233,88],[233,100]]]]],[],["loc",[null,[233,42],[233,101]]]]],[],["loc",[null,[233,27],[233,102]]]],["get","item.info.finalDigitalDiagnoses",["loc",[null,[233,103],[233,134]]]]],[],["loc",[null,[233,22],[233,135]]]]],[],7,null,["loc",[null,[233,16],[238,36]]]],
              ["block","if",[["subexpr","or",[["get","item.owner",["loc",[null,[240,77],[240,87]]]],["subexpr","is-etiga-readable",["addThera",["get","etigaPermissions",["loc",[null,[240,118],[240,134]]]],["get","item.patient",["loc",[null,[240,135],[240,147]]]]],[],["loc",[null,[240,88],[240,148]]]]],[],["loc",[null,[240,73],[240,149]]]]],[],8,null,["loc",[null,[240,67],[240,202]]]],
              ["block","if",[["subexpr","and",[["subexpr","or",[["get","item.owner",["loc",[null,[241,31],[241,41]]]],["subexpr","is-etiga-readable",["addThera",["get","etigaPermissions",["loc",[null,[241,72],[241,88]]]],["get","item.patient",["loc",[null,[241,89],[241,101]]]]],[],["loc",[null,[241,42],[241,102]]]]],[],["loc",[null,[241,27],[241,103]]]],["get","item.info.digitalTherapies",["loc",[null,[241,104],[241,130]]]]],[],["loc",[null,[241,22],[241,131]]]]],[],9,null,["loc",[null,[241,16],[242,138]]]],
              ["block","if",[["get","item.info.report",["loc",[null,[243,22],[243,38]]]]],[],10,null,["loc",[null,[243,16],[244,222]]]],
              ["block","if",[["get","item.rating",["loc",[null,[245,22],[245,33]]]]],[],11,null,["loc",[null,[245,16],[246,259]]]],
              ["block","if",[["get","item.info.manualTreatments",["loc",[null,[247,22],[247,48]]]]],[],12,null,["loc",[null,[247,16],[248,119]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
          };
        }());
        var child16 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 252,
                  "column": 12
                },
                "end": {
                  "line": 254,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","button-rework context");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/edit-dark.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Berichte");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["element","action",["openReportChooser",["get","item.id",["loc",[null,[253,46],[253,53]]]]],[],["loc",[null,[253,17],[253,55]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child17 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 263,
                    "column": 66
                  },
                  "end": {
                    "line": 263,
                    "column": 153
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createTextNode("VK Brutto");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 264,
                      "column": 138
                    },
                    "end": {
                      "line": 264,
                      "column": 179
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Rabatt");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 264,
                      "column": 179
                    },
                    "end": {
                      "line": 264,
                      "column": 193
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Faktor");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 264,
                    "column": 67
                  },
                  "end": {
                    "line": 264,
                    "column": 207
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[264,144],[264,171]]]]],[],0,1,["loc",[null,[264,138],[264,200]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 265,
                    "column": 66
                  },
                  "end": {
                    "line": 265,
                    "column": 150
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","table__title title");
                var el2 = dom.createTextNode("Gesamt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 270,
                    "column": 20
                  },
                  "end": {
                    "line": 287,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","treatment-now-entry",[],["classNames",["subexpr","@mut",[["get","entry.type",["loc",[null,[273,37],[273,47]]]]],[],[]],"openLaboklinChoosePanel","openLaboklinChoosePanel","downloadLaboklinBarcode","downloadBarcode","openSlopingInfos","openSlopingInfos","openTemplateEdit","openTemplateEdit","openDigitalForm","openDigitalForm","readOnly",true,"showPrice",["subexpr","@mut",[["get","item.owner",["loc",[null,[280,36],[280,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","item.patient",["loc",[null,[281,34],[281,46]]]]],[],[]],"treatmentPatientId",["subexpr","@mut",[["get","item.id",["loc",[null,[282,45],[282,52]]]]],[],[]],"entry",["subexpr","@mut",[["get","entry",["loc",[null,[283,32],[283,37]]]]],[],[]],"offer",["subexpr","@mut",[["get","offer",["loc",[null,[284,32],[284,37]]]]],[],[]]],["loc",[null,[272,24],[285,26]]]]
              ],
              locals: ["entry"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 258,
                  "column": 12
                },
                "end": {
                  "line": 290,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","pl50 box no-box@phone table table--invoice no-hover");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__head");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Menge");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-5/24");
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"class","table__title title");
              var el5 = dom.createTextNode("Position");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--right l-3/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--center l-3/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell table__cell--right l-2/24");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-2/24");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","table__cell l-1/24");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [7]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [9]),0,0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[263,77],[263,87]]]],["subexpr","show-prices",[],[],["loc",[null,[263,88],[263,101]]]]],[],["loc",[null,[263,72],[263,102]]]]],[],0,null,["loc",[null,[263,66],[263,160]]]],
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[264,78],[264,88]]]],["subexpr","show-prices",[],[],["loc",[null,[264,89],[264,102]]]]],[],["loc",[null,[264,73],[264,103]]]]],[],1,null,["loc",[null,[264,67],[264,214]]]],
              ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[265,77],[265,87]]]],["subexpr","show-prices",[],[],["loc",[null,[265,88],[265,101]]]]],[],["loc",[null,[265,72],[265,102]]]]],[],2,null,["loc",[null,[265,66],[265,157]]]],
              ["block","each",[["get","item.entries",["loc",[null,[270,28],[270,40]]]]],[],3,null,["loc",[null,[270,20],[287,29]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 8
              },
              "end": {
                "line": 294,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row no-stripes");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell pt-40 table__cell--toggle l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 l-2/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Datum");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block mb5 like-h3");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"data-label","MitarbeiterIn");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-7/24@desk overflow");
            dom.setAttribute(el2,"data-label","Diagnose");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","like-h3");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n           ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell   table__cell--action buttons-4 l-6/24 l-4/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 pl30 l-20/24@desk");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right l-24/24 l-4/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__second-row l-24/24 hidden");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [5]);
            var element18 = dom.childAt(element17, [1]);
            var element19 = dom.childAt(element16, [9]);
            var element20 = dom.childAt(element16, [19]);
            var morphs = new Array(15);
            morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element16, [3, 1]),1,1);
            morphs[2] = dom.createMorphAt(element18,0,0);
            morphs[3] = dom.createMorphAt(element18,2,2);
            morphs[4] = dom.createMorphAt(element17,3,3);
            morphs[5] = dom.createMorphAt(element17,5,5);
            morphs[6] = dom.createMorphAt(element16,7,7);
            morphs[7] = dom.createAttrMorph(element19, 'class');
            morphs[8] = dom.createMorphAt(dom.childAt(element19, [1]),1,1);
            morphs[9] = dom.createMorphAt(dom.childAt(element16, [11, 1]),1,1);
            morphs[10] = dom.createMorphAt(dom.childAt(element16, [13]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element16, [15]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element16, [17]),1,1);
            morphs[13] = dom.createAttrMorph(element20, 'data-id');
            morphs[14] = dom.createMorphAt(element20,1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","item.hasEntries",["loc",[null,[56,22],[56,37]]]]],[],0,null,["loc",[null,[56,16],[58,23]]]],
            ["block","if",[["get","item.owner",["loc",[null,[62,20],[62,30]]]]],[],1,2,["loc",[null,[62,14],[76,19]]]],
            ["content","item.dayDate",["loc",[null,[80,50],[80,66]]]],
            ["block","unless",[["get","item.appEditable",["loc",[null,[80,77],[80,93]]]]],[],3,null,["loc",[null,[80,67],[80,122]]]],
            ["block","if",[["get","item.durationMinutes",["loc",[null,[81,24],[81,44]]]]],[],4,null,["loc",[null,[81,18],[81,227]]]],
            ["block","if",[["subexpr","and",[["get","item.owner",["loc",[null,[82,29],[82,39]]]],["subexpr","has-permission",["u_treatment_history"],[],["loc",[null,[82,40],[82,78]]]]],[],["loc",[null,[82,24],[82,79]]]]],[],5,6,["loc",[null,[82,18],[88,25]]]],
            ["block","if",[["get","showPatients",["loc",[null,[90,23],[90,35]]]]],[],7,null,["loc",[null,[90,17],[92,27]]]],
            ["attribute","class",["concat",["table__cell l-12/24 l-12/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showPatients",["loc",[null,[93,87],[93,99]]]],"3","7"],[],["loc",[null,[93,66],[93,109]]]],"/24@desk"]]],
            ["block","if",[["get","item.owner",["loc",[null,[95,20],[95,30]]]]],[],8,9,["loc",[null,[95,14],[105,21]]]],
            ["block","if",[["subexpr","and",[["get","item.virtualTreatment",["loc",[null,[109,21],[109,42]]]],["get","item.info.virtualTreatment",["loc",[null,[109,43],[109,69]]]]],[],["loc",[null,[109,16],[109,70]]]]],[],10,11,["loc",[null,[109,10],[114,17]]]],
            ["block","if",[["get","item.deleted",["loc",[null,[118,18],[118,30]]]]],[],12,13,["loc",[null,[118,12],[208,19]]]],
            ["block","if",[["subexpr","and",[["get","item.virtualTreatment",["loc",[null,[211,25],[211,46]]]],["get","item.info.virtualTreatment",["loc",[null,[211,47],[211,73]]]]],[],["loc",[null,[211,20],[211,74]]]]],[],14,15,["loc",[null,[211,14],[249,21]]]],
            ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.deleted",["loc",[null,[252,28],[252,40]]]]],[],["loc",[null,[252,23],[252,41]]]],["get","item.owner",["loc",[null,[252,42],[252,52]]]]],[],["loc",[null,[252,18],[252,53]]]]],[],16,null,["loc",[null,[252,12],[254,19]]]],
            ["attribute","data-id",["concat",[["get","item.id",["loc",[null,[257,83],[257,90]]]]]]],
            ["block","if",[["get","item.hasEntries",["loc",[null,[258,18],[258,33]]]]],[],17,null,["loc",[null,[258,12],[290,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 6
            },
            "end": {
              "line": 295,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","ctx",["loc",[null,[52,22],[52,25]]]],"treatment"],[],["loc",[null,[52,18],[52,38]]]],["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","invoiceId",["loc",[null,[52,53],[52,62]]]],["get","item.info.invoiceId",["loc",[null,[52,63],[52,82]]]]],[],["loc",[null,[52,49],[52,83]]]]],[],["loc",[null,[52,44],[52,84]]]],["subexpr","not",[["subexpr","eq",[["get","item.id",["loc",[null,[52,94],[52,101]]]],["get","model.id",["loc",[null,[52,102],[52,110]]]]],[],["loc",[null,[52,90],[52,111]]]]],[],["loc",[null,[52,85],[52,112]]]]],[],["loc",[null,[52,39],[52,113]]]]],[],["loc",[null,[52,14],[52,114]]]]],[],0,null,["loc",[null,[52,8],[294,15]]]]
        ],
        locals: ["item"],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 297,
              "column": 2
            },
            "end": {
              "line": 301,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","items",["loc",[null,[299,29],[299,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[299,6],[299,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 305,
              "column": 0
            },
            "end": {
              "line": 310,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","treatment-info-form",[],["rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[306,30],[306,35]]]]],[],[]],"treatmentSaved","saveTreatment","editDate",true,"actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[306,96],[306,116]]]]],[],[]]],["loc",[null,[306,2],[306,118]]]],
          ["inline","treatment-history-employees",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[307,40],[307,47]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[307,59],[307,69]]]]],[],[]],"employeesSaved","employeesSaved","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryEmployees",["loc",[null,[307,117],[307,142]]]]],[],[]]],["loc",[null,[307,2],[307,144]]]],
          ["inline","treatment-history-rooms",[],["rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[308,34],[308,39]]]]],[],[]],"roomSaved","roomSaved","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryRooms",["loc",[null,[308,77],[308,98]]]]],[],[]]],["loc",[null,[308,2],[308,100]]]],
          ["inline","past-actions-panel",[],["actionReceiver",["subexpr","@mut",[["get","pastActionsPanel",["loc",[null,[309,38],[309,54]]]]],[],[]]],["loc",[null,[309,2],[309,56]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 324,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-history.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table-wrapper-rework");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table table--small table--treatment-history no-hover");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__head");
        var el4 = dom.createComment("\n         ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-1/24");
        var el5 = dom.createElement("span");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n         ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-1/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-4/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Datum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("MitarbeiterIn");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Diagnose");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element28 = dom.childAt(fragment, [3, 1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element29, [9]);
        var element31 = dom.childAt(element29, [11]);
        var element32 = dom.childAt(element29, [13]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element29, [1, 0]),0,0);
        morphs[3] = dom.createMorphAt(element29,7,7);
        morphs[4] = dom.createAttrMorph(element30, 'class');
        morphs[5] = dom.createAttrMorph(element31, 'class');
        morphs[6] = dom.createAttrMorph(element32, 'class');
        morphs[7] = dom.createMorphAt(dom.childAt(element32, [0]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element28, [3]),1,1);
        morphs[9] = dom.createMorphAt(element28,5,5);
        morphs[10] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[13] = dom.createMorphAt(fragment,11,11,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","showFilter",["loc",[null,[1,6],[1,16]]]]],[],0,null,["loc",[null,[1,0],[29,7]]]],
        ["block","if",[["get","showAdvancedFilter",["loc",[null,[30,6],[30,24]]]]],[],1,null,["loc",[null,[30,0],[37,7]]]],
        ["block","if",[["get","showToggleAllButton",["loc",[null,[42,56],[42,75]]]]],[],2,null,["loc",[null,[42,50],[42,198]]]],
        ["block","if",[["get","showPatients",["loc",[null,[45,19],[45,31]]]]],[],3,null,["loc",[null,[45,13],[45,125]]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showPatients",["loc",[null,[46,60],[46,72]]]],"3","7"],[],["loc",[null,[46,39],[46,82]]]],"/24"]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showTotal",["loc",[null,[47,60],[47,69]]]],"6","7"],[],["loc",[null,[47,39],[47,79]]]],"/24"]]],
        ["attribute","class",["concat",["table__cell l-",["subexpr","css-bool-evaluator",[["get","showTotal",["loc",[null,[48,60],[48,69]]]],"5","4"],[],["loc",[null,[48,39],[48,79]]]],"/24 text-right"]]],
        ["block","if",[["get","showTotal",["loc",[null,[48,134],[48,143]]]]],[],4,null,["loc",[null,[48,128],[48,203]]]],
        ["block","each",[["get","items",["loc",[null,[51,14],[51,19]]]]],[],5,null,["loc",[null,[51,6],[295,17]]]],
        ["block","if",[["get","showPager",["loc",[null,[297,8],[297,17]]]]],[],6,null,["loc",[null,[297,2],[301,9]]]],
        ["block","if",[["get","loadOverlay",["loc",[null,[305,6],[305,17]]]]],[],7,null,["loc",[null,[305,0],[310,7]]]],
        ["inline","treatment-history-report-chooser",[],["chooseReport","gotoReport","delete","deleteReport","openWizard","openReportWizard","actionReceiver",["subexpr","@mut",[["get","treatmentHistoryReportChooser",["loc",[null,[316,17],[316,46]]]]],[],[]]],["loc",[null,[312,0],[316,48]]]],
        ["inline","treatment-report-wizzard2",[],["saveFinished","openReportPreview","actionReceiver",["subexpr","@mut",[["get","treatmentReportWizzard2",["loc",[null,[320,17],[320,40]]]]],[],[]]],["loc",[null,[318,0],[320,42]]]],
        ["content","eltiga-sharenotallowed-panel",["loc",[null,[323,0],[323,32]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});
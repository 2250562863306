define('jason-frontend/routes/mandants/edit', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {
      return this.store.find('mandant', API['default'].getMandant());
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      controller.set('ehapoOptions', this.store.find('ehapo-setting'));

      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant', per_page: 100 }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('agents', this.store.find('agent'));
      controller.set('countries', this.store.find('country'));
      controller.set('hideVetconnectPassword', true);
      controller.set('hideLaboklinPassword', true);
      controller.set('hideRpaPassword', true);

      var selectedTab = params.queryParams.selectTab;

      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        this.selectTab('masterdata');
      }
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'etiga') {
        $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/etigaDefaultPermissions",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('permissions', data);
        });
      }
      if (selected === 'sales') {
        $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/adaptPrices",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('indexes', data.adaptPrice);
        });
      }
      if (selected === 'templates') {
        $.ajax({
          url: "/api/templates?per_page=100",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('templates', data.template);
        });
      }
      if (selected === 'accounts') {
        controller.set('accounts', this.store.find('bankingAccount'));
      }
      if (selected === 'emergency') {
        var controller = this.get('controller'),
            dateRange = controller.get('pickDate'),
            dateFromTo = dateRange.split('-');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        controller.set('to', to);
        controller.set('from', from);

        controller.set('showLoading', true);

        this.findPaged('emergency-service', {
          per_page: 50
        }).then(function (transactions) {
          controller.set('meta', transactions.meta);
          controller.set('emergencies', transactions);
          controller.set('showLoading', false);
        });

        $.ajax({
          url: "/api/emergencyServices/mandant/opening",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('opening', data.recurringEmergencyService);
        });

        $.ajax({
          url: "/api/emergencyServices/mandant/emergency",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          controller.set('emergency', data.recurringEmergencyService);
        });
      }
    },
    actions: {
      revealAnimalData: function revealAnimalData() {
        this.get('controller').set('hideAnimaldataVetlogin', !this.get('controller').get('hideAnimaldataVetlogin'));
      },
      revealVetconnect: function revealVetconnect() {
        this.get('controller').set('hideVetconnectPassword', !this.get('controller').get('hideVetconnectPassword'));
      },
      revealLaboklin: function revealLaboklin() {
        this.get('controller').set('hideLaboklinPassword', !this.get('controller').get('hideLaboklinPassword'));
      },
      revealRpa: function revealRpa() {
        this.get('controller').set('hideRpaPassword', !this.get('controller').get('hideRpaPassword'));
      },
      deleteSignature: function deleteSignature() {
        $.ajax({
          url: "/api/mandants/signature",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Unterschrift', 'entfernt');
          $('#signatureMedia').hide();
          $('#deleteSignatureButton').hide();
        }, function (data) {
          jason.notifiction.notifiy('Unterschrift', 'entfernt');
          $('#signatureMedia').hide();
          $('#deleteSignatureButton').hide();
        });
      },
      insertSignature: function insertSignature(id) {

        var self = this;

        var data = {
          cdnId: id
        };

        $.ajax({
          url: "/api/mandants/uploadSignature",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Unterschrift', 'gespeichert');
          self.get('controller').set('tmpSignature', id);
        }, function (data) {
          jason.notifiction.notifiy('Unterschrift', 'gespeichert');
          self.get('controller').set('tmpSignature', id);
        });
      },
      showSignaturePanel: function showSignaturePanel() {
        var controller = this.get('controller');
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-image-upload-and-select-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        controller.get('imageUploadAndSelectPanel').send('load', self, 'simple', 0);
      },
      saveEmergency: function saveEmergency() {
        var self = this;
        var pay = this.get('controller').get('opening');
        Ember['default'].set(pay, 'type', 'opening');

        Ember['default'].set(pay, 'mondayFrom', $('#mondayFromInput').val());
        Ember['default'].set(pay, 'mondayTo', $('#mondayToInput').val());
        Ember['default'].set(pay, 'tuesdayFrom', $('#tuesdayFromInput').val());
        Ember['default'].set(pay, 'tuesdayTo', $('#tuesdayToInput').val());
        Ember['default'].set(pay, 'wednesdayFrom', $('#wednesdayFromInput').val());
        Ember['default'].set(pay, 'wednesdayTo', $('#wednesdayToInput').val());
        Ember['default'].set(pay, 'thursdayFrom', $('#thursdayFromInput').val());
        Ember['default'].set(pay, 'thursdayTo', $('#thursdayToInput').val());
        Ember['default'].set(pay, 'fridayFrom', $('#fridayFromInput').val());
        Ember['default'].set(pay, 'fridayTo', $('#fridayToInput').val());
        Ember['default'].set(pay, 'saturdayFrom', $('#saturdayFromInput').val());
        Ember['default'].set(pay, 'saturdayTo', $('#saturdayToInput').val());
        Ember['default'].set(pay, 'sundayFrom', $('#sundayFromInput').val());
        Ember['default'].set(pay, 'sundayTo', $('#sundayToInput').val());

        Ember['default'].set(pay, 'mondayFrom2', $('#mondayFromInput2').val());
        Ember['default'].set(pay, 'mondayTo2', $('#mondayToInput2').val());
        Ember['default'].set(pay, 'tuesdayFrom2', $('#tuesdayFromInput2').val());
        Ember['default'].set(pay, 'tuesdayTo2', $('#tuesdayToInput2').val());
        Ember['default'].set(pay, 'wednesdayFrom2', $('#wednesdayFromInput2').val());
        Ember['default'].set(pay, 'wednesdayTo2', $('#wednesdayToInput2').val());
        Ember['default'].set(pay, 'thursdayFrom2', $('#thursdayFromInput2').val());
        Ember['default'].set(pay, 'thursdayTo2', $('#thursdayToInput2').val());
        Ember['default'].set(pay, 'fridayFrom2', $('#fridayFromInput2').val());
        Ember['default'].set(pay, 'fridayTo2', $('#fridayToInput2').val());
        Ember['default'].set(pay, 'saturdayFrom2', $('#saturdayFromInput2').val());
        Ember['default'].set(pay, 'saturdayTo2', $('#saturdayToInput2').val());
        Ember['default'].set(pay, 'sundayFrom2', $('#sundayFromInput2').val());
        Ember['default'].set(pay, 'sundayTo2', $('#sundayToInput2').val());

        $.ajax({
          url: "/api/emergencyServices/recurring",
          method: "POST",
          data: JSON.stringify(pay),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          var openingPay = self.get('controller').get('emergency');

          Ember['default'].set(openingPay, 'mondayFrom', $('#mondayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'mondayTo', $('#mondayToInputEmergency').val());
          Ember['default'].set(openingPay, 'tuesdayFrom', $('#tuesdayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'tuesdayTo', $('#tuesdayToInputEmergency').val());
          Ember['default'].set(openingPay, 'wednesdayFrom', $('#wednesdayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'wednesdayTo', $('#wednesdayToInputEmergency').val());
          Ember['default'].set(openingPay, 'thursdayFrom', $('#thursdayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'thursdayTo', $('#thursdayToInputEmergency').val());
          Ember['default'].set(openingPay, 'fridayFrom', $('#fridayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'fridayTo', $('#fridayToInputEmergency').val());
          Ember['default'].set(openingPay, 'saturdayFrom', $('#saturdayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'saturdayTo', $('#saturdayToInputEmergency').val());
          Ember['default'].set(openingPay, 'sundayFrom', $('#sundayFromInputEmergency').val());
          Ember['default'].set(openingPay, 'sundayTo', $('#sundayToInputEmergency').val());

          Ember['default'].set(openingPay, 'mondayFrom2', $('#mondayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'mondayTo2', $('#mondayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'tuesdayFrom2', $('#tuesdayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'tuesdayTo2', $('#tuesdayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'wednesdayFrom2', $('#wednesdayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'wednesdayTo2', $('#wednesdayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'thursdayFrom2', $('#thursdayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'thursdayTo2', $('#thursdayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'fridayFrom2', $('#fridayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'fridayTo2', $('#fridayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'saturdayFrom2', $('#saturdayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'saturdayTo2', $('#saturdayToInputEmergency2').val());
          Ember['default'].set(openingPay, 'sundayFrom2', $('#sundayFromInputEmergency2').val());
          Ember['default'].set(openingPay, 'sundayTo2', $('#sundayToInputEmergency2').val());

          Ember['default'].set(openingPay, 'type', 'emergency');
          $.ajax({
            url: "/api/emergencyServices/recurring",
            method: "POST",
            data: JSON.stringify(openingPay),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {
            jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
            self.selectTab('emergency');
          }, function (error) {});
        }, function (error) {});
      },
      deleteEmergency: function deleteEmergency(id) {
        var self = this;
        $.ajax({
          url: "/api/emergencyServices/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'entfernt');
          self.selectTab('emergency');
        }, function (error) {});
      },
      editEmergency: function editEmergency(data, id) {
        var self = this;
        var pay = {
          id: data.id,
          endString: data.get('endString'),
          startString: data.get('startString'),
          comment: data.get('comment'),
          endStringTime: data.get('endStringTime'),
          startStringTime: data.get('startStringTime')
        };

        $.ajax({
          url: "/api/emergencyServices",
          method: "POST",
          data: JSON.stringify(pay),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
          self.selectTab('emergency');
        }, function (error) {});
      },
      testPrint: function testPrint() {

        $.ajax({
          url: "/api/cashdesk/testprint",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Testdruck', 'gestartet');
        });
      },
      adaptPrices: function adaptPrices(data) {
        var self = this,
            controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/adaptPrices",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Preisanpassung', 'durchgeführt');
          self.selectTab('sales');
        }, function (error) {
          jason.notifiction.notifiy('Preisanpassung', 'durchgeführt');
          self.selectTab('sales');
        });
      },
      saveEtiga: function saveEtiga() {
        var self = this,
            controller = this.get('controller');

        var data = controller.get('permissions');

        data.etigaAutohideTreatments = controller.get('model').get('etigaAutohideTreatments');
        data.etigaLabSetting = controller.get('model').get('etigaLabSetting');
        data.etigaTreatmentReportSetting = controller.get('model').get('etigaTreatmentReportSetting');
        data.eltigaShareTreatment = controller.get('model').get('eltigaShareTreatment');
        data.eltigaTreatmentShareEmpty = controller.get('model').get('eltigaTreatmentShareEmpty');

        var deferred = $.ajax({
          url: "/api/mandants/" + controller.get('model').get('id') + "/etigaDefaultPermissions",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Freigabeeinstellungen', 'gespeichert');
        });
      },
      save: function save() {
        var self = this,
            controller = this.get('controller'),
            model = self.controller.get('model');

        if (isNaN(model.get('daysTillWarning1'))) {
          jason.notifiction.error('Bitte Tage in Zahlen angeben', '');
          return;
        }
        if (isNaN(model.get('daysTillWarning2'))) {
          jason.notifiction.error('Bitte Tage in Zahlen angeben', '');
          return;
        }

        var hapoNr = model.get('hapoNr');

        if (hapoNr && hapoNr.length != 10) {
          jason.notifiction.error('Fehler', 'Die HapoNr muss aus 10 Zahlen bestehen, OHNE führendes HA');
          return;
        }

        if (model.get('defaultDoctor')) {
          model.set('defaultDoctor', {
            id: model.get('defaultDoctor').id
          });
        }
        if (model.get('defaultAssistant')) {
          model.set('defaultAssistant', {
            id: model.get('defaultAssistant').id
          });
        }
        if (model.get('defaultRoom')) {
          model.set('defaultRoom', {
            id: model.get('defaultRoom').id
          });
        }
        if (model.get('defaultStation')) {
          model.set('defaultStation', {
            id: model.get('defaultStation').id
          });
        }

        if (model.get('calendarDefaultDuration')) {
          API['default'].setCalDefaultSlotDuration(model.get('calendarDefaultDuration'));
        }

        model.set('defaultOverdueFines1', String(model.get('defaultOverdueFines1')).replace(",", "."));
        model.set('defaultOverdueFines2', String(model.get('defaultOverdueFines2')).replace(",", "."));
        model.set('defaultInterestForDelay', String(model.get('defaultInterestForDelay')).replace(",", "."));
        model.set('defaultInterestForDelay2', String(model.get('defaultInterestForDelay2')).replace(",", "."));

        model.save().then(function () {
          jason.notifiction.notifiy('Stammdaten', 'erfolgreich gespeichert');
          self.transitionTo('mandants.edit');
        }, function (error) {
          var code = error.errors[0].status;

          if (code === '400') {
            jason.notifiction.errorLong('Fehler', 'Die angegebenen Laboklin Zugangsdaten sind nicht korrekt');
          } else if (code === '403') {
            jason.notifiction.errorLong('Fehler', 'Die angegebenen Datacare Zugangsdaten sind nicht korrekt');
          }
        });
      },
      testRkdb: function testRkdb() {

        var self = this;
        this.send('save');
        this.get('controller').set('rkdbTesting', true);

        setTimeout(function () {
          Ember['default'].$.ajax({
            url: "/api/mandants/testRkdbConnection",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Erfolgreich', 'Finanz Online Anbindung verfügbar');
          }, function (data) {
            console.log(data);
            if (data.statusText === 'Internal Server Error') {
              self.get('controller').set('rkdbResult', '<span class="mt20 label label-danger">Verbindung fehlerhaft - Bitte überprüfen Sie ihre Zugangsdaten</span>');
            } else if (data.statusText === 'Forbidden') {
              self.get('controller').set('rkdbResult', '<span class="mt20 label label-danger">Verbindung fehlerhaft - Bitte überprüfen Sie ihre Zugangsdaten</span>');
            } else if (data.statusText === 'error') {
              self.get('controller').set('rkdbResult', '<span class="mt20 label label-danger">Verbindung fehlerhaft - Bitte überprüfen Sie ihre Zugangsdaten</span>');
            } else {
              self.get('controller').set('rkdbResult', '<span class="mt20 label label-success">Verbindung erfolgreich getestet</span>');
            }
            setTimeout(function () {
              self.get('controller').set('rkdbTesting', false);
              self.get('controller').set('rkdbResult', null);
            }, 5000);
          });
        }, 2000);
      },
      selectTab: function selectTab(selected) {
        this.selectTab(selected);
      }
    }
  });

});
define('jason-frontend/templates/components/public-admission-pin-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 16
            },
            "end": {
              "line": 53,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Scanne den eltiga Tier QR-Code um das Tier aufzunehmen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","placeholder-input");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","mt20 mb20");
          var el3 = dom.createTextNode("oder ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createTextNode("eltiga\n                      Tier Nr direkt eingeben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [5, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
          morphs[1] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["inline","input",[],["value",["subexpr","@mut",[["get","qrInput",["loc",[null,[46,36],[46,43]]]]],[],[]],"class","","id","qrInput2","name","qrInput"],["loc",[null,[46,22],[46,83]]]],
          ["element","action",["activateDirectInput"],[],["loc",[null,[50,49],[50,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 16
            },
            "end": {
              "line": 66,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Geben Sie die Tier Nummer ein");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","placeholder-input");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","mt20 mb20");
          var el3 = dom.createTextNode("oder ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createTextNode("scanne\n                      den Tier QR-Code");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [5, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
          morphs[1] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["inline","input",[],["id","etigaNumber","value",["subexpr","@mut",[["get","etigaNumber",["loc",[null,[59,53],[59,64]]]]],[],[]],"class","","name","etigaNumber","placeholder",""],["loc",[null,[59,22],[59,109]]]],
          ["element","action",["deactivateDirectInput"],[],["loc",[null,[63,49],[63,83]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 144,
              "column": 20
            },
            "end": {
              "line": 146,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","ml-auto");
          dom.setAttribute(el1,"src","assets/images/icons/rework/accordion-close.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 146,
              "column": 20
            },
            "end": {
              "line": 148,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","ml-auto");
          dom.setAttribute(el1,"src","assets/images/icons/rework/accordion-collapse.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 189,
              "column": 24
            },
            "end": {
              "line": 202,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form-field-rework");
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","select-placeholder");
          var el3 = dom.createTextNode("Rasse");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","digitalRaces",["loc",[null,[193,38],[193,50]]]]],[],[]],"value",["subexpr","@mut",[["get","digitalRace",["loc",[null,[194,36],[194,47]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","digitalRace","placeholder","Bitte wählen"],["loc",[null,[192,28],[200,30]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 244,
                "column": 8
              },
              "end": {
                "line": 256,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class"," icon-button tooltipstered");
            dom.setAttribute(el1,"title","Speichern, und zum Tier");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/animal_card.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button tooltipstered");
            dom.setAttribute(el1,"title","Speichern, Aufnehmen und ins Wartezimmer setzen");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/waitingroom.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button tooltipstered");
            dom.setAttribute(el1,"title","Speichern, Aufnehmen und direkt in Behandlung");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/treatment.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(fragment, [3]);
            var element2 = dom.childAt(fragment, [5]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [
            ["element","action",["saveAndGotoPatient"],[],["loc",[null,[245,13],[245,44]]]],
            ["element","action",["saveAndToWaitingRoom"],[],["loc",[null,[248,13],[248,46]]]],
            ["element","action",["saveAndToTreatment"],[],["loc",[null,[252,13],[252,44]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 242,
              "column": 4
            },
            "end": {
              "line": 264,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel-footer");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button tooltipstered");
          dom.setAttribute(el2,"title","Speichern, und zum Kunden");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/user.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--success tooltipstered");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element3, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element3,1,1);
          morphs[1] = dom.createElementMorph(element4);
          morphs[2] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["block","if",[["get","addPatient",["loc",[null,[244,14],[244,24]]]]],[],0,null,["loc",[null,[244,8],[256,15]]]],
          ["element","action",["saveAndGotoCustomer"],[],["loc",[null,[257,11],[257,43]]]],
          ["element","action",["save"],[],["loc",[null,[260,11],[260,28]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 310,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-public-admission-pin-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        dom.setAttribute(el3,"style","padding-left: 15px");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode(" Check-In");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body p-20");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","js-tabs tabs");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","rework-content tabs-rework-wrapper grid mb-40");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-admission");
        var el7 = dom.createTextNode("Selbst-Registrierung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-etiga");
        var el7 = dom.createTextNode("App Check-In");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-customer");
        var el7 = dom.createTextNode("Neue Kund:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","tabs__body");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-admission");
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createTextNode("Scanne diesen QR-Code mit einem Smartphone oder Tablet, um die Selbst-Registrierung zu starten.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","result");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","is-highlighted");
        dom.setAttribute(el8,"target","_blank");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"class","cursor");
        dom.setAttribute(el8,"src","assets/images/icons/copy-to-clipboard.svg");
        dom.setAttribute(el8,"style","width: 17px;display: inline");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-etiga");
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("form");
        dom.setAttribute(el7,"id","etigaScanForm2");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","d-flex flex-column");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9,"type","submit");
        dom.setAttribute(el9,"class","mt20 context primary button-rework min-h-40px mb-20");
        var el10 = dom.createTextNode("\n                  Aufnehmen\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-customer");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pb0");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("form");
        dom.setAttribute(el8,"method","post");
        dom.setAttribute(el8,"id","createCustomerAdmissionForm");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h2");
        var el10 = dom.createTextNode("Stammdaten");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","mt-30 grid");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"class","select-placeholder");
        var el13 = dom.createTextNode("Anrede");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-firstname");
        dom.setAttribute(el12,"class","placeholder required");
        var el13 = dom.createTextNode("Vorname");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-lastname");
        dom.setAttribute(el12,"class","placeholder required");
        var el13 = dom.createTextNode("Nachname");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-street");
        dom.setAttribute(el12,"class","placeholder");
        var el13 = dom.createTextNode("Adresse");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-postalCode");
        dom.setAttribute(el12,"class","placeholder");
        var el13 = dom.createTextNode("PLZ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-town");
        dom.setAttribute(el12,"class","placeholder");
        var el13 = dom.createTextNode("Ort");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-phone");
        dom.setAttribute(el12,"class","placeholder");
        var el13 = dom.createTextNode("Telefon");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("img");
        dom.setAttribute(el12,"src","assets/images/icons/rework/phone.svg");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-12/24@desk 24/24 gutter");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","form-field-rework");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("label");
        dom.setAttribute(el12,"for","customer-email");
        dom.setAttribute(el12,"class","placeholder");
        var el13 = dom.createTextNode("E-Mail");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("img");
        dom.setAttribute(el12,"src","assets/images/icons/rework/email.svg");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","accordion-header");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("h2");
        var el12 = dom.createTextNode("Tier anlegen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","accordion-body grid ps-20 pe-20");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","mt-30 grid ");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"for","patient-name");
        dom.setAttribute(el14,"class","placeholder required");
        var el15 = dom.createTextNode("Tiername");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"class","select-placeholder");
        var el15 = dom.createTextNode("Geschlecht");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"class","select-placeholder");
        var el15 = dom.createTextNode("Spezies");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"for","patient-color");
        dom.setAttribute(el14,"class","placeholder");
        var el15 = dom.createTextNode("Farbe");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"for","patient-chipId");
        dom.setAttribute(el14,"class","placeholder");
        var el15 = dom.createTextNode("Chip-Nr");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createComment("");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"for","patient-birthday");
        dom.setAttribute(el14,"class","placeholder");
        var el15 = dom.createTextNode("Geburtstag");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("img");
        dom.setAttribute(el14,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","col l-12/24@desk 24/24 gutter");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("div");
        dom.setAttribute(el13,"class","form-field-rework min-h-50px mb-0 d-flex align-items-center");
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("div");
        dom.setAttribute(el14,"class","toggle");
        var el15 = dom.createTextNode("\n                            ");
        dom.appendChild(el14, el15);
        var el15 = dom.createComment("");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                            ");
        dom.appendChild(el14, el15);
        var el15 = dom.createElement("label");
        dom.setAttribute(el15,"for","switchCastratedPatientAdmission");
        dom.setAttribute(el15,"class","switch");
        dom.appendChild(el14, el15);
        var el15 = dom.createTextNode("\n                          ");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                          ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("label");
        dom.setAttribute(el14,"for","switchCastratedPatientAdmission");
        dom.setAttribute(el14,"class","toggle");
        var el15 = dom.createTextNode("Kastriert");
        dom.appendChild(el14, el15);
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                        ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(\"body\").delegate(\".datetimepicker3\", \"apply.daterangepicker\", function (ev, picker) {\n    $(this).val(picker.startDate.format('DD. MM. YYYY'));\n    $(this).focus();\n  });\n  $(\"body\").delegate(\".datetimepicker3\", \"focusin\", function () {\n    $(this).daterangepicker({\n      singleDatePicker: true,\n      drops: 'up',\n      autoUpdateInput: false,\n      showDropdowns: true,\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var element9 = dom.childAt(element8, [3, 1]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element10, [5]);
        var element14 = dom.childAt(element9, [3]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element15, [5]);
        var element17 = dom.childAt(element16, [0]);
        var element18 = dom.childAt(element16, [2]);
        var element19 = dom.childAt(element14, [3]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element14, [5]);
        var element22 = dom.childAt(element21, [1, 1]);
        var element23 = dom.childAt(element22, [3]);
        var element24 = dom.childAt(element22, [5]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(element24, [3, 1]);
        var morphs = new Array(35);
        morphs[0] = dom.createAttrMorph(element11, 'class');
        morphs[1] = dom.createElementMorph(element11);
        morphs[2] = dom.createAttrMorph(element12, 'class');
        morphs[3] = dom.createElementMorph(element12);
        morphs[4] = dom.createAttrMorph(element13, 'class');
        morphs[5] = dom.createElementMorph(element13);
        morphs[6] = dom.createAttrMorph(element15, 'class');
        morphs[7] = dom.createAttrMorph(element17, 'href');
        morphs[8] = dom.createMorphAt(element17,0,0);
        morphs[9] = dom.createElementMorph(element18);
        morphs[10] = dom.createAttrMorph(element19, 'class');
        morphs[11] = dom.createElementMorph(element20);
        morphs[12] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
        morphs[13] = dom.createAttrMorph(element21, 'class');
        morphs[14] = dom.createElementMorph(element22);
        morphs[15] = dom.createMorphAt(dom.childAt(element23, [1, 1]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element23, [5, 1]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element23, [7, 1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element23, [9, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element23, [11, 1]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element23, [13, 1]),1,1);
        morphs[21] = dom.createMorphAt(dom.childAt(element23, [15, 1]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element23, [17, 1]),1,1);
        morphs[23] = dom.createAttrMorph(element24, 'class');
        morphs[24] = dom.createElementMorph(element25);
        morphs[25] = dom.createMorphAt(element25,3,3);
        morphs[26] = dom.createMorphAt(dom.childAt(element26, [1, 1]),1,1);
        morphs[27] = dom.createMorphAt(dom.childAt(element26, [3, 1]),3,3);
        morphs[28] = dom.createMorphAt(dom.childAt(element26, [5, 1]),3,3);
        morphs[29] = dom.createMorphAt(dom.childAt(element26, [7]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element26, [9, 1]),1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element26, [11, 1]),1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element26, [13, 1]),1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element26, [15, 1, 1]),1,1);
        morphs[34] = dom.createMorphAt(element8,5,5);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[12,57],[12,68]]]],"admission"],[],["loc",[null,[12,53],[12,81]]]],"is-active",""],[],["loc",[null,[12,32],[13,57]]]]]]],
        ["element","action",["selectMode","admission"],[],["loc",[null,[13,81],[14,38]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[15,57],[15,68]]]],"etiga"],[],["loc",[null,[15,53],[15,77]]]],"is-active",""],[],["loc",[null,[15,32],[16,57]]]]]]],
        ["element","action",["selectMode","etiga"],[],["loc",[null,[16,77],[17,34]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[18,57],[18,68]]]],"customer"],[],["loc",[null,[18,53],[18,80]]]],"is-active",""],[],["loc",[null,[18,32],[19,57]]]]]]],
        ["element","action",["selectMode","customer"],[],["loc",[null,[19,80],[20,37]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[25,73],[25,84]]]],"admission"],[],["loc",[null,[25,69],[25,97]]]],"is-active",""],[],["loc",[null,[25,48],[25,114]]]]]]],
        ["attribute","href",["concat",[["get","url",["loc",[null,[30,26],[30,29]]]]]]],
        ["inline","truncate",[["get","url",["loc",[null,[30,83],[30,86]]]],35],[],["loc",[null,[30,72],[30,91]]]],
        ["element","action",["copyToClipboard",["get","url",["loc",[null,[31,32],[31,35]]]]],[],["loc",[null,[30,101],[31,37]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[35,73],[35,84]]]],"etiga"],[],["loc",[null,[35,69],[35,93]]]],"is-active",""],[],["loc",[null,[35,48],[35,110]]]]]]],
        ["element","action",["confirm"],["on","submit"],["loc",[null,[38,18],[38,50]]]],
        ["block","unless",[["get","directInput",["loc",[null,[40,26],[40,37]]]]],[],0,1,["loc",[null,[40,16],[66,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 mt0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[73,81],[73,92]]]],"customer"],[],["loc",[null,[73,77],[73,104]]]],"is-active",""],[],["loc",[null,[73,56],[74,81]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[76,67],[76,96]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","gendersAll",["loc",[null,[83,32],[83,42]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedGender",["loc",[null,[84,30],[84,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"name","customerGender","placeholder","Bitte wählen"],["loc",[null,[82,22],[90,24]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","firstname",["loc",[null,[97,51],[97,60]]]]],[],[]],"name","customer-firstname","autocomplete","off"],["loc",[null,[97,22],[97,107]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","lastname",["loc",[null,[103,51],[103,59]]]]],[],[]],"name","customer-lastname","autocomplete","off"],["loc",[null,[103,22],[103,105]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","street",["loc",[null,[109,51],[109,57]]]]],[],[]],"name","customer-street","autocomplete","off"],["loc",[null,[109,22],[109,101]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","postalCode",["loc",[null,[115,51],[115,61]]]]],[],[]],"name","customer-postalCode","autocomplete","off"],["loc",[null,[115,22],[115,109]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","town",["loc",[null,[121,51],[121,55]]]]],[],[]],"name","customer-town","autocomplete","off"],["loc",[null,[121,22],[121,97]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","phone1",["loc",[null,[127,51],[127,57]]]]],[],[]],"name","customer-phone","autocomplete","off"],["loc",[null,[127,22],[127,100]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","email",["loc",[null,[134,51],[134,56]]]]],[],[]],"name","customer-email","autocomplete","off"],["loc",[null,[134,22],[134,99]]]],
        ["attribute","class",["concat",["accordion gutter-fix--large inline-accordion ",["subexpr","css-bool-evaluator",[["get","addPatient",["loc",[null,[141,94],[141,104]]]],"collapsed",""],[],["loc",[null,[141,73],[141,122]]]]]]],
        ["element","action",["toggleAddPatient"],[],["loc",[null,[142,48],[142,78]]]],
        ["block","if",[["get","addPatient",["loc",[null,[144,26],[144,36]]]]],[],2,3,["loc",[null,[144,20],[148,27]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientName",["loc",[null,[154,55],[154,66]]]]],[],[]],"name","patient-name","autocomplete","off"],["loc",[null,[154,26],[154,107]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientGenders",["loc",[null,[162,36],[162,50]]]]],[],[]],"value",["subexpr","@mut",[["get","patientGender",["loc",[null,[163,34],[163,47]]]]],[],[]],"optionLabelPath","name","allowClear",false,"name","patientGender","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[161,26],[169,28]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[177,36],[177,53]]]]],[],[]],"value",["subexpr","@mut",[["get","patientCategory",["loc",[null,[178,34],[178,49]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","name","patientCategory","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen"],["loc",[null,[176,26],[184,28]]]],
        ["block","if",[["get","digitalRaces",["loc",[null,[189,30],[189,42]]]]],[],4,null,["loc",[null,[189,24],[202,31]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientColor",["loc",[null,[206,55],[206,67]]]]],[],[]],"name","patient-color","autocomplete","off"],["loc",[null,[206,26],[206,109]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientChipId",["loc",[null,[212,55],[212,68]]]]],[],[]],"name","patient-chipId","autocomplete","off"],["loc",[null,[212,26],[212,111]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","birthdayInput",["loc",[null,[218,55],[218,68]]]]],[],[]],"class","datetimepicker3","name","patient-birthday"],["loc",[null,[218,26],[218,118]]]],
        ["inline","input",[],["type","checkbox","id","switchCastratedPatientAdmission","classNames","switch","name","switchCastratedPatientAdmission","checked",["subexpr","@mut",[["get","patientCastrated",["loc",[null,[227,83],[227,99]]]]],[],[]]],["loc",[null,[226,28],[227,101]]]],
        ["block","if",[["subexpr","eq",[["get","selectedTab",["loc",[null,[242,14],[242,25]]]],"customer"],[],["loc",[null,[242,10],[242,37]]]]],[],5,null,["loc",[null,[242,4],[264,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }()));

});
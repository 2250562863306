define('jason-frontend/controllers/services/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "services.edit",
    referer: null,
    refererInvoiceId: null,
    showTherapies: true,
    showDiagnoses: true,
    showSymptoms: true,
    showReport: true,
    sidebarActive: false,
    showAnamnese: true,
    refererPatientId: null,
    queryParams: ['referer', 'refererInvoiceId', 'refererPatientId', 'selectTab'],
    selectTab: 'masterdata',
    actions: {
      triggerSubmit: function triggerSubmit() {
        $('form#serviceEdit').submit();
      },
      save: function save(referer, refererInvoiceId, refererPatientId) {

        var self = this,
            refLine = $('div.table__body');

        setTimeout(function () {

          var model = self.get('model');
          var price = model.priceExVat;

          if (!model.tax) {
            jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Mwst');
            return;
          }
          if (!model.unit || model.unit && !model.unit.id) {
            jason.notifiction.error('Fehler', 'Bitte wählen Sie eine Verrechnungseinheit');
            return;
          }

          var patientCategory = new Array();
          $('form#serviceEdit input[type=checkbox]:checked').each(function () {
            patientCategory.push({
              id: $(this).val()
            });
          });

          var product = new Object();
          product.id = model.id;
          product.name = model.name;
          product.gotNumber = model.gotNumber;
          product.gotSmall = model.gotSmall;
          product.gotHorse = model.gotHorse;
          product.gotUse = model.gotUse;
          product.factor = model.factor;
          product.individualName = model.individualName;
          product.gotPara8 = model.gotPara8;
          product.tags = model.tags;

          if ($('input.product-price', refLine).val()) {
            product.priceExVat = $('input.product-price', refLine).val().replace(",", ".");
          }

          if (product.factor) {
            if (product.factor <= 0) {
              jason.notifiction.error('Fehler', 'Faktor muss größer 1 sein');
              return;
            }
            product.factor = new String(product.factor).replace(",", ".");
          }

          if ($('input.product-price-addition-input', refLine).val()) {
            var priceAdditionInput = $('input.product-price-addition-input', refLine).val().replace(",", ".");
            product.additionalPercentage = priceAdditionInput;
          }

          var addition = $('#additionalPercentage').val();

          if (addition) {
            addition = addition.replace(",", ".");
            product.additionalPercentage = addition;
          }

          product.description = model.description;

          if (model.category) {
            product.category = { id: model.category.id, name: model.category.name };
          }
          if (model.labProfile) {
            product.labProfile = { id: model.labProfile.id };
          }
          if (model.xrayProfile) {
            product.xrayProfile = { id: model.xrayProfile.id };
          }
          product.tax = { id: model.tax.id };
          product.unit = { id: model.unit.id };
          if (model.distributor) {
            product.distributor = { id: model.distributor.id, name: model.distributor.name };
          }

          product.favorite = model.favorite;
          product.referenceNumber = model.referenceNumber;
          product.patientCategories = patientCategory;

          $.ajax({
            url: "/api/services/" + model.id,
            method: "PUT",
            data: JSON.stringify(product),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            if (referer === 'sale') {
              jason.notifiction.notifiy('Erfolgreich', 'gespeichert!');
            } else if (referer === 'add-service') {
              self.transitionTo('cash.add_service', refererInvoiceId, refererPatientId);
            } else {
              self.transitionTo('services.index');
            }
          }, function (error) {});
        }, 1000);
      }
    }
  });

});
define('jason-frontend/templates/components/button-delete-with-confirm-id', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-delete-with-confirm-id.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"class","btn-delete");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.setAttribute(el2,"src","assets/images/icons/rework/trash.svg");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","delete-confirm medium-confirm");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Nein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","button");
        dom.setAttribute(el3,"href","#");
        var el4 = dom.createTextNode("Ja");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element1,1,1);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [
        ["attribute","data-id",["concat",[["get","itemId",["loc",[null,[1,54],[1,60]]]]]]],
        ["attribute","title",["concat",[["get","tooltip",["loc",[null,[1,73],[1,80]]]]]]],
        ["element","action",["confirm"],[],["loc",[null,[1,3],[1,23]]]],
        ["content","text",["loc",[null,[5,2],[5,10]]]],
        ["element","action",["cancel"],[],["loc",[null,[7,11],[7,30]]]],
        ["element","action",["delete"],[],["loc",[null,[8,11],[8,30]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});
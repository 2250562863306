define('jason-frontend/routes/treatmentcages/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('treatmentStationCage', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('stations', this.store.find('treatment-station'));
    },
    actions: {
      save: function save(id) {
        var self = this;

        var controller = this.get('controller'),
            model = controller.get('model'),
            bankingAccount = new Object();

        bankingAccount.id = id;
        bankingAccount.name = model.get('name');
        if (model.get('station')) {
          bankingAccount.station = {
            id: model.get('station').internalId
          };
        }

        $.ajax({
          url: "/api/treatmentStationCages/" + id,
          method: "PUT",
          data: JSON.stringify(bankingAccount),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.transitionTo('practice');
        }, function () {
          self.transitionTo('practice');
        });
      }
    }
  });

});
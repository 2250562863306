define('jason-frontend/controllers/patients/edit', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Bearbeiten",
    breadCrumbPath: "patients.edit",
    selectTab: 'masterdata',
    queryParams: ['action', 'rootFolderId'],
    action: null,
    filterCategory: null,
    uploadedAssets: null,
    filterLab: false,
    sidebarActive: true,
    filterProduct: false,
    quickFilter: 'visible',
    filterService: false,
    showFilters: true,
    filterFood: false,
    filterHide: false,
    folderId: null,
    customerTab: 'patients',
    patientTab: '',
    healthTab: 'health',
    page: 1,
    totalPages: 10,
    filterTodoStatus: 'open',
    castrationOptions: [{ name: "Nicht kastriert", id: "no" }, { name: "Kastriert", id: "yes" }],
    todoStatus: [{ value: "Offen", id: "open" }, { value: "Erledigt", id: "done" }],
    actions: {
      updateSearch: function updateSearch(enabled, term) {
        this.set('filterName', term);
      },
      openEditDosing: function openEditDosing(item) {
        this.get('editPastActionsPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-past-actions-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAddDirectNotification: function openAddDirectNotification(id) {
        var self = this;

        var self = this;
        this.set('users', this.store.find('user', { per_page: 100 }));

        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addDirectNotification').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-direct-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      admissionPatient: function admissionPatient(customerId, patientId) {

        var self = this;
        var patients = [patientId];

        this.get('startTreatmentPanel').send('load', customerId, patients);

        Ember['default'].$.ajax({
          url: "/api/patients/latestInfos?patientIds=" + patients,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('startTreatmentPanel').send('loadTreatment', data);
        }, function (data) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-start-treatment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      activateEtiga: function activateEtiga() {
        var self = this;

        $.ajax({
          url: "/api/patients?filterEtiga=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.patient.length > 0) {

            self.get('existingEtigaPanel').send('load', data.patient);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-existing-etiga-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.send('createNew');
          }
        }, function (error) {});
      },
      openMedicalProblemsPanel: function openMedicalProblemsPanel(id) {
        var self = this;

        this.store.find('patient', id).then(function (data) {
          self.get('medicalProblemsPanel').send('load', data);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-medical-problems-panel'
          },
          callbacks: {
            close: function close() {
              self.get('medicalProblemsPanel').send('save');
            },
            open: function open() {
              $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openResult: function openResult(id) {

        var self = this;

        $.ajax({
          url: "/api/labResults/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('labResultPanel').send('load', data.labResultFrontend, self.get('model'), self.get('model').get('customer'));
        }, function (error) {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: '#modal-lab-result-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      selectBarcode: function selectBarcode(patientId) {
        this.get('patientSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEmailEtigaPanel: function openEmailEtigaPanel(patient) {
        this.get('emailEtigaPanel').send('loadWithEmails', patient, patient.get('customer').get('emails'), patient.get('etigaQr'));
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-etiga-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDirectMessage: function openDirectMessage() {

        this.get('addDirectAppNotification').send('reset');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-direct-app-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openChangeDate: function openChangeDate(data) {
        this.get('changeLabDatePanel').send('load', data);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-lab-date-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openChart: function openChart(data, paramId, formId) {
        this.get('labResultChartPanel').send('load', data, paramId, formId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-lab-result-chart-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEtigaMasterdataLogbook: function openEtigaMasterdataLogbook() {

        var self = this;

        $.ajax({
          url: "/api/etigaMasterdataLogbooks?patientId=" + this.get('model').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('etigaMasterdataLogbookPanel').send('load', data);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-masterdata-logbook-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAllLabParamsPanel: function openAllLabParamsPanel(patientId) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + patientId + "/allLabs",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('allLabResultPanel').send('load', data.allLabReport, patientId);
        }, function (error) {});

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-all-lab-result-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectLaboklinBarcode: function selectLaboklinBarcode(patientId) {
        this.get('laboklinSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-laboklin-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      downloadPatientBarcode: function downloadPatientBarcode(patientId, format) {
        window.open('/api/patients/downloadBarcode/' + patientId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      editTreatment: function editTreatment(item, patient, taskData) {
        this.get('getTreatmentInfoForm').send('load', item, patient, null, null, null, taskData);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#treatment-edit-form-clone'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      triggerSave: function triggerSave() {
        $('form#patientEdit').submit();
      },
      //tasks
      openNew: function openNew() {
        var self = this;
        this.set('users', this.store.find('user', { per_page: 100, addAll: true }));
        this.get('addTaskPanel').send('reset');

        this.store.find('customer', this.get('model').get('customer').content.id).then(function (data) {
          self.get('addTaskPanel').send('setPatient', self.get('model'), data);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      // openNewAppointment: function() {
      //   var self = this;
      //   this.get('addAppointmentPanel').send('load', this.get('model'), this.get('patientAppointmentCategories'), true);
      //
      //   $.magnificPopup.open({
      //     removalDelay: 500,
      //     closeOnBgClick: false,
      //     items: {
      //       src: '#modal-add-appointment-panel'
      //     },
      //     callbacks: {
      //       beforeOpen: function() {
      //         var Animation = "mfp-with-fade";
      //         this.st.mainClass = Animation;
      //       }
      //     },
      //     midClick: true
      //   });
      // },
      openEditAppointment: function openEditAppointment(itemId) {
        var self = this;

        $.ajax({
          url: "/api/patientAppointments/" + itemId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('addAppointmentPanel').send('loadEdit', data.patientAppointment, self.store.find('patient-appointment-category'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showTask: function showTask(item) {
        this.get('showTaskPanel').send('load', item);
        var self = this;

        $.ajax({
          url: "/api/tasks/" + item.id + "/history",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('showTaskPanel').send('loadHistory', data.taskHistory);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showAppointment: function showAppointment(item) {
        this.get('showAppointmentPanel').send('load', item);
        var self = this;

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-appointment-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openSlopingInfos: function openSlopingInfos(entry) {

        var self = this;

        var articleId = undefined;
        var article = undefined;
        try {
          articleId = entry.get('article').id;
          article = entry.get('article');
        } catch (e) {
          articleId = entry.article.id;
          article = entry.article;
        }

        $.ajax({
          url: "/api/products/" + articleId + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('slopingInfoPanel').send('load', data.productSlopingInfo, article, entry);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      changeUser: function changeUser(patientId) {

        this.get('selectCustomerPanel').send('load');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-customer-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openLogbook: function openLogbook(item) {

        var self = this;
        this.store.find('etiga-logbook-entry', { patientId: self.get('model').id }).then(function (data) {
          self.get('etigaLogbookPanel').send('load', data);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-logbook-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addMessage: function addMessage(task) {
        var self = this;
        this.set('users', this.store.find('user', { per_page: 100 }));

        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addNotificationPanel').send('reset');
        this.get('addNotificationPanel').send('addTaskReference', task);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      showNotification: function showNotification(item) {
        this.get('showNotificationPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-show-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addEtigaShare: function addEtigaShare(item) {

        var self = this;
        $.ajax({
          url: "/api/patients/" + self.get('model').get('id') + "/etigaSharingInfo",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('etigaShareInfos', data.items);
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-etiga-share-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            },
            close: function close() {

              var dataToSend = {
                items: self.get('etigaShareInfos')
              };

              var deferred = $.ajax({
                url: "/api/patients/" + self.get('model').get('id') + "/updateEtigaShares",
                method: "POST",
                contentType: "application/json",
                data: JSON.stringify(dataToSend),
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {}, function (error) {
                self.send('selectTab', 'etiga');
                jason.notifiction.notifiy('Freigabeeinstellungen', 'erstellt');
              });
            }
          },
          midClick: true
        });
      },
      changeOwner: function changeOwner(item) {

        this.get('changeTaskOwnerPanel').send('reset', item, API['default'].getUserId());

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-change-task-owner-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      finishTask: function finishTask(item) {
        var self = this;

        this.get('finishTaskPanel').send('reset', item);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-finish-task-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEmailForwardPanel: function openEmailForwardPanel(id) {

        var customer = this.get('model').get('customer');

        if (customer) {
          this.get('forwardEmailPanel').send('load', id, customer.get('email'));
        } else {
          this.get('forwardEmailPanel').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDocumentForwardPanel: function openDocumentForwardPanel(id) {

        var customer = this.get('model').get('customer');

        if (customer) {
          this.get('forwardDocumentPanel').send('load', id, customer.get('email'));
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-forward-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openLaboklinChoosePanel: function openLaboklinChoosePanel(entryId, laboklinFormId) {

        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/0/laboklinForms/" + laboklinFormId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.purchaseOrderLaboklinForm.privateLab) {
            self.get('privateTreatmentPanel').send('load', self.get('model').id, entryId, data, null, null, self.store.find('patient-category'), null, null);
          } else {
            self.get('laboklinTreatmentPanel').send('load', self.get('model').id, entryId, data, null, null, self.store.find('patient-category'), null, null);
          }

          $.magnificPopup.open({
            removalDelay: 500,
            closeOnBgClick: false,
            items: {
              src: data.purchaseOrderLaboklinForm.privateLab ? '#modal-form-private-treatment-panel' : '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        });
      },
      showUploadForm: function showUploadForm(id) {
        var self = this;

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-media-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      editLaboklinForm: function editLaboklinForm(id) {

        var self = this;

        $.ajax({
          url: "/api/treatmentPatients/1/laboklinForms/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.purchaseOrderLaboklinForm.privateLab) {
            self.get('privateTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'), null, null);
          } else {
            self.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'), null, null);
          }

          Ember['default'].run.later(function () {
            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: data.purchaseOrderLaboklinForm.privateLab ? '#modal-form-private-treatment-panel' : '#modal-form-laboklin-treatment-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          }, 500);
        });
      },
      openWeight: function openWeight(id, patientId) {

        var self = this;

        $('#weight-form').attr('data-id', id);
        $('#weight-form').attr('data-patient-id', patientId);

        if (id > 0) {
          $.ajax({
            url: "/api/patientWeightInfos/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            if (data.patientWeightInfo.date) {
              data.patientWeightInfo.dateInput = moment(data.patientWeightInfo.date).format('DD. MM. YYYY');
            }
            if (data.patientWeightInfo.weight) {
              data.patientWeightInfo.weight = new String(data.patientWeightInfo.weight).replace('.', ',');
            }
            self.set('weightData', data);
          }, function (error) {});
        } else {
          $('#weight-form').find('#dateInput').val('');
          $('#weight-form').find('#weight').val('');
          $('#weight-form').find('#notice').val('');
        }

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#weight-form'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    },
    watchCategory: (function () {
      this.send('selectTab', 'appointments');
    }).observes('filterCategory'),
    watchTodoStatus: (function () {
      this.send('selectTab', 'todo');
    }).observes('filterTodoStatus')
  });

});
define('jason-frontend/templates/cash/add-article', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          var el2 = dom.createTextNode("\n    #breadcrumbs-bar {\n      visibility: hidden;\n      display: none !important;\n    }\n    #breadcrumbs-bar-inventory {\n      visibility: visible !important;\n      display: block !important;\n    }\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 10
              },
              "end": {
                "line": 30,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 10
              },
              "end": {
                "line": 32,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"type","button");
            var el2 = dom.createTextNode("Paket");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element16, 'class');
            morphs[1] = dom.createElementMorph(element16);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[31,110],[31,122]]]],"composite"],[],["loc",[null,[31,106],[31,135]]]],"selected",""],[],["loc",[null,[31,85],[31,151]]]]]]],
            ["element","action",["setType","composite"],[],["loc",[null,[31,20],[31,52]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 10
              },
              "end": {
                "line": 33,
                "column": 82
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 12
                },
                "end": {
                  "line": 35,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 12
                },
                "end": {
                  "line": 37,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              var el2 = dom.createTextNode("Vorlage");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element15, 'class');
              morphs[1] = dom.createElementMorph(element15);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[36,107],[36,119]]]],"text"],[],["loc",[null,[36,103],[36,127]]]],"selected",""],[],["loc",[null,[36,82],[36,143]]]]]]],
              ["element","action",["setType","text"],[],["loc",[null,[36,22],[36,49]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 82
              },
              "end": {
                "line": 38,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","referer",["loc",[null,[34,27],[34,34]]]],"invoices"],[],["loc",[null,[34,23],[34,46]]]],["subexpr","eq",[["get","selectedPatient",["loc",[null,[34,51],[34,66]]]],"0"],[],["loc",[null,[34,47],[34,71]]]]],[],["loc",[null,[34,18],[34,72]]]]],[],0,1,["loc",[null,[34,12],[37,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 39,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createTextNode("Produkt");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createTextNode("Leistung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createElementMorph(element17);
          morphs[2] = dom.createAttrMorph(element18, 'class');
          morphs[3] = dom.createElementMorph(element18);
          morphs[4] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,7,7,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["btn button  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[27,107],[27,119]]]],"product"],[],["loc",[null,[27,103],[27,130]]]],"selected",""],[],["loc",[null,[27,82],[27,146]]]]]]],
          ["element","action",["setType","product"],[],["loc",[null,[27,18],[27,48]]]],
          ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[28,106],[28,118]]]],"service"],[],["loc",[null,[28,102],[28,129]]]],"selected",""],[],["loc",[null,[28,81],[28,145]]]]]]],
          ["element","action",["setType","service"],[],["loc",[null,[28,18],[28,48]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[29,24],[29,28]]]],"compositeProduct"],[],["loc",[null,[29,20],[29,48]]]],["subexpr","eq",[["get","type",["loc",[null,[29,53],[29,57]]]],"compositeService"],[],["loc",[null,[29,49],[29,77]]]],["subexpr","eq",[["get","type",["loc",[null,[29,82],[29,86]]]],"compositeTemplate"],[],["loc",[null,[29,78],[29,107]]]]],[],["loc",[null,[29,16],[29,108]]]]],[],0,1,["loc",[null,[29,10],[32,17]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[33,24],[33,28]]]],"futureAction"],[],["loc",[null,[33,20],[33,44]]]],["subexpr","eq",[["get","type",["loc",[null,[33,49],[33,53]]]],"futureActionBlueprints"],[],["loc",[null,[33,45],[33,79]]]]],[],["loc",[null,[33,16],[33,80]]]]],[],2,3,["loc",[null,[33,10],[38,17]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 12
                },
                "end": {
                  "line": 60,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Firma");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[51,26],[51,35]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[52,24],[52,38]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",true],["loc",[null,[50,16],[58,18]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 12
                },
                "end": {
                  "line": 93,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Kategorie");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[83,28],[83,38]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[84,26],[84,49]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[82,18],[91,20]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 6
              },
              "end": {
                "line": 95,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Hersteller");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element14,1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]),3,3);
            morphs[2] = dom.createMorphAt(element14,5,5);
            return morphs;
          },
          statements: [
            ["block","if",[["get","suppliers",["loc",[null,[47,18],[47,27]]]]],[],0,null,["loc",[null,[47,12],[60,19]]]],
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[65,22],[65,40]]]]],[],[]],"optionLabelPath","name","placeholder","Alle","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryManufacturer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[64,14],[76,16]]]],
            ["block","if",[["get","partnerCategories",["loc",[null,[79,18],[79,35]]]]],[],1,null,["loc",[null,[79,12],[93,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 4
            },
            "end": {
              "line": 96,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[43,12],[43,23]]]]],[],0,null,["loc",[null,[43,6],[95,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 6
              },
              "end": {
                "line": 128,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Kategorie");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Quelle");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]),3,3);
            morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[105,24],[105,41]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[106,22],[106,45]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[104,14],[113,16]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","distributors",["loc",[null,[118,24],[118,36]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDistributor",["loc",[null,[119,22],[119,39]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"searchEnabled",false,"placeholder","Alle"],["loc",[null,[117,14],[125,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 97,
              "column": 4
            },
            "end": {
              "line": 129,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[98,12],[98,23]]]]],[],0,null,["loc",[null,[98,6],[128,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 151,
                  "column": 10
                },
                "end": {
                  "line": 155,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","selectable-item has-icon");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createAttrMorph(element12, 'for');
              morphs[2] = dom.createElementMorph(element12);
              morphs[3] = dom.createMorphAt(element12,0,0);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id",["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[152,54],[152,68]]]]],[],["loc",[null,[152,39],[152,69]]]],"checked",["subexpr","@mut",[["get","tag.selected",["loc",[null,[152,78],[152,90]]]]],[],[]]],["loc",[null,[152,12],[152,92]]]],
              ["attribute","for",["concat",[["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[153,73],[153,87]]]]],[],["loc",[null,[153,57],[153,89]]]]]]],
              ["element","action",["selectTag",["get","tag",["loc",[null,[154,100],[154,103]]]]],[],["loc",[null,[153,91],[154,105]]]],
              ["content","tag.name",["loc",[null,[154,106],[154,118]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 150,
                "column": 8
              },
              "end": {
                "line": 156,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","articleTags",["loc",[null,[151,18],[151,29]]]]],[],0,null,["loc",[null,[151,10],[155,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 148,
              "column": 6
            },
            "end": {
              "line": 157,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","articleTags",["loc",[null,[150,14],[150,25]]]]],[],0,null,["loc",[null,[150,8],[156,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 165,
                "column": 8
              },
              "end": {
                "line": 173,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-4/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Lager");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 174,
                "column": 8
              },
              "end": {
                "line": 176,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[175,10],[175,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 178,
                  "column": 10
                },
                "end": {
                  "line": 180,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-product",[],["newStyle",true,"inclusiveVpes",true,"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[179,85],[179,102]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[179,113],[179,128]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavs",["loc",[null,[179,145],[179,155]]]]],[],[]],"filterFavs",["subexpr","@mut",[["get","filterFavs",["loc",[null,[179,167],[179,177]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[179,186],[179,193]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[179,201],[179,207]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[179,214],[179,218]]]]],[],[]],"addProduct","addProduct","typeaddProduct","addProduct","toggleProductFavorite","toggleProductFavorite","togglePriceFavorite","togglePriceFavorite"],["loc",[null,[179,12],[179,361]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 176,
                "column": 8
              },
              "end": {
                "line": 182,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            dom.setAttribute(el1,"id","product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[178,18],[178,23]]]]],[],0,null,["loc",[null,[178,10],[180,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 2
            },
            "end": {
              "line": 184,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element11,1,1);
          morphs[1] = dom.createMorphAt(element11,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[165,36],[165,60]]]]],[],[]]],0,null,["loc",[null,[165,8],[173,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[174,14],[174,25]]]]],[],1,2,["loc",[null,[174,8],[182,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 193,
                  "column": 70
                },
                "end": {
                  "line": 194,
                  "column": 63
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 187,
                "column": 8
              },
              "end": {
                "line": 198,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-7/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Einheit");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right@desk l-3/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 9]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[193,76],[193,89]]]]],[],0,null,["loc",[null,[193,70],[194,70]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 199,
                "column": 8
              },
              "end": {
                "line": 201,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[200,10],[200,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 203,
                  "column": 12
                },
                "end": {
                  "line": 205,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-service",[],["classNames",["subexpr","concat",["table__row"," ",["get","service.type",["loc",[null,[204,72],[204,84]]]]],[],["loc",[null,[204,47],[204,85]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[204,104],[204,121]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[204,132],[204,147]]]]],[],[]],"service",["subexpr","@mut",[["get","service",["loc",[null,[204,156],[204,163]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[204,171],[204,177]]]]],[],[]],"addService","addService","toggleServiceFavorite","toggleServiceFavorite"],["loc",[null,[204,14],[204,249]]]]
            ],
            locals: ["service"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 201,
                "column": 8
              },
              "end": {
                "line": 207,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[203,20],[203,25]]]]],[],0,null,["loc",[null,[203,12],[205,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 185,
              "column": 2
            },
            "end": {
              "line": 209,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element10,1,1);
          morphs[1] = dom.createMorphAt(element10,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[187,36],[187,60]]]]],[],[]]],0,null,["loc",[null,[187,8],[198,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[199,14],[199,25]]]]],[],1,2,["loc",[null,[199,8],[207,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 216,
                  "column": 63
                },
                "end": {
                  "line": 216,
                  "column": 138
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 212,
                "column": 6
              },
              "end": {
                "line": 220,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-11/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[216,69],[216,82]]]]],[],0,null,["loc",[null,[216,63],[216,145]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 221,
                "column": 8
              },
              "end": {
                "line": 223,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[222,10],[222,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 225,
                  "column": 12
                },
                "end": {
                  "line": 227,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-composite",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[226,56],[226,73]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[226,83],[226,91]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[226,102],[226,117]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavorites",["loc",[null,[226,134],[226,149]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[226,158],[226,165]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[226,173],[226,179]]]]],[],[]],"addProduct","addComposite","toggleCompositeFavorite","toggleCompositeFavorite"],["loc",[null,[226,14],[226,257]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 223,
                "column": 8
              },
              "end": {
                "line": 229,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[225,20],[225,25]]]]],[],0,null,["loc",[null,[225,12],[227,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 210,
              "column": 2
            },
            "end": {
              "line": 231,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element9,1,1);
          morphs[1] = dom.createMorphAt(element9,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[212,34],[212,58]]]]],[],[]]],0,null,["loc",[null,[212,6],[220,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[221,14],[221,25]]]]],[],1,2,["loc",[null,[221,8],[229,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 235,
                "column": 8
              },
              "end": {
                "line": 242,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-10/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Name");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-9/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Unterschrift");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 243,
                "column": 8
              },
              "end": {
                "line": 245,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[244,10],[244,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 247,
                  "column": 12
                },
                "end": {
                  "line": 249,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-text",[],["classNames",["subexpr","concat",["table__row"],[],["loc",[null,[248,44],[248,65]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[248,84],[248,101]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[248,111],[248,119]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[248,130],[248,145]]]]],[],[]],"text",["subexpr","@mut",[["get","text",["loc",[null,[248,151],[248,155]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[248,163],[248,169]]]]],[],[]],"addText","addText"],["loc",[null,[248,14],[248,189]]]]
            ],
            locals: ["text"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 245,
                "column": 8
              },
              "end": {
                "line": 251,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[247,20],[247,25]]]]],[],0,null,["loc",[null,[247,12],[249,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 232,
              "column": 2
            },
            "end": {
              "line": 253,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element8,1,1);
          morphs[1] = dom.createMorphAt(element8,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[235,36],[235,60]]]]],[],[]]],0,null,["loc",[null,[235,8],[242,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[243,14],[243,25]]]]],[],1,2,["loc",[null,[243,8],[251,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 261,
                "column": 6
              },
              "end": {
                "line": 263,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Paket"],["loc",[null,[262,8],[262,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 260,
              "column": 4
            },
            "end": {
              "line": 264,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["compositeproducts.edit",["get","typeId",["loc",[null,[261,42],[261,48]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[261,6],[263,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 266,
                "column": 6
              },
              "end": {
                "line": 268,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Lieferschein"],["loc",[null,[267,8],[267,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 265,
              "column": 4
            },
            "end": {
              "line": 269,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["inventory.delivery-note-details",["get","typeId",["loc",[null,[266,51],[266,57]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[266,6],[268,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 271,
                "column": 6
              },
              "end": {
                "line": 273,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Ausgangsrechnung"],["loc",[null,[272,8],[272,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 270,
              "column": 4
            },
            "end": {
              "line": 274,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["invoices.create",["get","typeId",["loc",[null,[271,35],[271,41]]]],["subexpr","query-params",[],["patientId",["get","selectedPatient",["loc",[null,[271,66],[271,81]]]]],["loc",[null,[271,42],[271,82]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[271,6],[273,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 276,
                "column": 6
              },
              "end": {
                "line": 278,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Behandlung"],["loc",[null,[277,8],[277,55]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 275,
              "column": 4
            },
            "end": {
              "line": 279,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.treatment-details",["get","typeId",["loc",[null,[276,50],[276,56]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[276,57],[276,92]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[276,6],[278,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 281,
                "column": 6
              },
              "end": {
                "line": 283,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Stationsaufenthalt"],["loc",[null,[282,8],[282,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 280,
              "column": 4
            },
            "end": {
              "line": 284,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.treatment-visit-details",["get","typeId",["loc",[null,[281,56],[281,62]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[281,63],[281,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[281,6],[283,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 287,
                  "column": 8
                },
                "end": {
                  "line": 289,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück"],["loc",[null,[288,10],[288,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 290,
                  "column": 8
                },
                "end": {
                  "line": 292,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-inventory2",[],["size",40,"color","#fff","content","Lagerbestand buchen"],["loc",[null,[291,10],[291,82]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 6
              },
              "end": {
                "line": 293,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["inventory.stocktaking-detail",["get","stocktakingId",["loc",[null,[287,50],[287,63]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[287,8],[289,20]]]],
            ["block","link-to",["inventory.bookin-request",["get","typeId",["loc",[null,[290,46],[290,52]]]],["subexpr","query-params",[],["stocktakingId",["get","stocktakingId",["loc",[null,[290,81],[290,94]]]]],["loc",[null,[290,53],[290,95]]]]],["class","icon-button icon-button--success"],1,null,["loc",[null,[290,8],[292,20]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 295,
                    "column": 10
                  },
                  "end": {
                    "line": 297,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[296,12],[296,44]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 294,
                  "column": 8
                },
                "end": {
                  "line": 298,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.corrections",["get","typeId",["loc",[null,[295,45],[295,51]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[295,10],[297,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 299,
                    "column": 10
                  },
                  "end": {
                    "line": 301,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","button-prev",["loc",[null,[300,12],[300,27]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 298,
                  "column": 8
                },
                "end": {
                  "line": 302,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.select-order"],["class","icon-button icon-button--small"],0,null,["loc",[null,[299,10],[301,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 293,
                "column": 6
              },
              "end": {
                "line": 303,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","subType",["loc",[null,[294,18],[294,25]]]],"inventoryCorrections"],[],["loc",[null,[294,14],[294,49]]]]],[],0,1,["loc",[null,[294,8],[302,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 285,
              "column": 4
            },
            "end": {
              "line": 304,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","stocktakingId",["loc",[null,[286,12],[286,25]]]]],[],0,1,["loc",[null,[286,6],[303,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 305,
              "column": 4
            },
            "end": {
              "line": 309,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[307,8],[307,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 310,
                  "column": 6
                },
                "end": {
                  "line": 312,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[311,8],[311,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 309,
                "column": 4
              },
              "end": {
                "line": 313,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[310,68],[310,83]]]],["get","typeId",["loc",[null,[310,84],[310,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[310,6],[312,18]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 314,
                    "column": 6
                  },
                  "end": {
                    "line": 316,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[315,8],[315,40]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 313,
                  "column": 4
                },
                "end": {
                  "line": 317,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[314,76],[314,91]]]],["get","typeId",["loc",[null,[314,92],[314,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[314,6],[316,18]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 318,
                      "column": 6
                    },
                    "end": {
                      "line": 320,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-prev",[],["content","Zurück"],["loc",[null,[319,8],[319,40]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 317,
                    "column": 4
                  },
                  "end": {
                    "line": 321,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[318,67],[318,82]]]],["get","typeId",["loc",[null,[318,83],[318,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[318,6],[320,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 322,
                        "column": 6
                      },
                      "end": {
                        "line": 324,
                        "column": 6
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-prev",[],["content","Zurück"],["loc",[null,[323,8],[323,40]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 321,
                      "column": 4
                    },
                    "end": {
                      "line": 325,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[322,67],[322,82]]]],["get","typeId",["loc",[null,[322,83],[322,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[322,6],[324,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 326,
                          "column": 6
                        },
                        "end": {
                          "line": 328,
                          "column": 6
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[327,8],[327,53]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 325,
                        "column": 4
                      },
                      "end": {
                        "line": 329,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[326,68],[326,83]]]],["get","typeId",["loc",[null,[326,84],[326,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[326,6],[328,18]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 330,
                            "column": 6
                          },
                          "end": {
                            "line": 332,
                            "column": 6
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[331,8],[331,53]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 329,
                          "column": 4
                        },
                        "end": {
                          "line": 333,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[330,76],[330,91]]]],["get","typeId",["loc",[null,[330,92],[330,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[330,6],[332,18]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 334,
                              "column": 6
                            },
                            "end": {
                              "line": 336,
                              "column": 6
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("        ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[335,8],[335,53]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 333,
                            "column": 4
                          },
                          "end": {
                            "line": 337,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[334,67],[334,82]]]],["get","typeId",["loc",[null,[334,83],[334,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[334,6],[336,18]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 338,
                                "column": 6
                              },
                              "end": {
                                "line": 340,
                                "column": 6
                              }
                            },
                            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("        ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                            return morphs;
                          },
                          statements: [
                            ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[339,8],[339,53]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 337,
                              "column": 4
                            },
                            "end": {
                              "line": 341,
                              "column": 4
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("    ");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          return morphs;
                        },
                        statements: [
                          ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[338,67],[338,82]]]],["get","typeId",["loc",[null,[338,83],[338,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[338,6],[340,18]]]]
                        ],
                        locals: [],
                        templates: [child0]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 337,
                            "column": 4
                          },
                          "end": {
                            "line": 341,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[337,18],[337,29]]]],"services"],[],["loc",[null,[337,14],[337,41]]]]],[],0,null,["loc",[null,[337,4],[341,4]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 333,
                          "column": 4
                        },
                        "end": {
                          "line": 341,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[333,18],[333,29]]]],"products"],[],["loc",[null,[333,14],[333,41]]]]],[],0,1,["loc",[null,[333,4],[341,4]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 329,
                        "column": 4
                      },
                      "end": {
                        "line": 341,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[329,18],[329,29]]]],"compositeproducts"],[],["loc",[null,[329,14],[329,50]]]]],[],0,1,["loc",[null,[329,4],[341,4]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 325,
                      "column": 4
                    },
                    "end": {
                      "line": 341,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[325,18],[325,29]]]],"treatment"],[],["loc",[null,[325,14],[325,42]]]]],[],0,1,["loc",[null,[325,4],[341,4]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 321,
                    "column": 4
                  },
                  "end": {
                    "line": 341,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[321,18],[321,29]]]],"templateProduct"],[],["loc",[null,[321,14],[321,48]]]]],[],0,1,["loc",[null,[321,4],[341,4]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 317,
                  "column": 4
                },
                "end": {
                  "line": 341,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[317,18],[317,29]]]],"templateService"],[],["loc",[null,[317,14],[317,48]]]]],[],0,1,["loc",[null,[317,4],[341,4]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 313,
                "column": 4
              },
              "end": {
                "line": 341,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[313,18],[313,29]]]],"templateComposite"],[],["loc",[null,[313,14],[313,50]]]]],[],0,1,["loc",[null,[313,4],[341,4]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 309,
              "column": 4
            },
            "end": {
              "line": 341,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[309,18],[309,29]]]],"templateTreatment"],[],["loc",[null,[309,14],[309,50]]]]],[],0,1,["loc",[null,[309,4],[341,4]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 360,
                      "column": 18
                    },
                    "end": {
                      "line": 361,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","cursor is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(element0,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["toItem",["get","entry.article.id",["loc",[null,[360,90],[360,106]]]],["get","entry.articleType",["loc",[null,[360,107],[360,124]]]]],[],["loc",[null,[360,72],[360,126]]]],
                  ["content","entry.article.name",["loc",[null,[360,127],[360,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 361,
                      "column": 18
                    },
                    "end": {
                      "line": 363,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","entry.articleText",["loc",[null,[362,20],[362,41]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 365,
                      "column": 104
                    },
                    "end": {
                      "line": 365,
                      "column": 203
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" (");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(") ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.price",["loc",[null,[365,168],[365,179]]]]],[],[]],"classNames","inline"],["loc",[null,[365,144],[365,201]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 368,
                      "column": 22
                    },
                    "end": {
                      "line": 370,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[369,46],[369,57]]]]],[],[]]],["loc",[null,[369,22],[369,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 356,
                    "column": 12
                  },
                  "end": {
                    "line": 379,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","entry grid");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","name col l-24/24");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","name mb10");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" x ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","price");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","delete");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","input input--action icon-button icon-button--delete list-action-square");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("img");
                dom.setAttribute(el4,"src","assets/images/icons/rework/trash.svg");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [1]);
                var element3 = dom.childAt(element2, [3]);
                var element4 = dom.childAt(element1, [5, 1]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
                morphs[3] = dom.createMorphAt(element3,3,3);
                morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                morphs[5] = dom.createElementMorph(element4);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.article",["loc",[null,[360,24],[360,37]]]]],[],0,1,["loc",[null,[360,18],[363,25]]]],
                ["content","entry.quantity",["loc",[null,[365,43],[365,61]]]],
                ["content","entry.unit.name",["loc",[null,[365,81],[365,100]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[365,118],[365,128]]]],"template"],[],["loc",[null,[365,114],[365,140]]]]],[],2,null,["loc",[null,[365,104],[365,214]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[368,36],[368,46]]]],"template"],[],["loc",[null,[368,32],[368,58]]]]],[],3,null,["loc",[null,[368,22],[370,33]]]],
                ["element","action",["deleteMinicartEntry",["get","entry.id",["loc",[null,[373,56],[373,64]]]],["get","treatment.id",["loc",[null,[373,65],[373,77]]]],["get","typeId",["loc",[null,[373,78],[373,84]]]]],[],["loc",[null,[373,25],[373,86]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 354,
                  "column": 10
                },
                "end": {
                  "line": 381,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","supplier-entries");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","treatment.entries",["loc",[null,[356,20],[356,37]]]]],[],0,null,["loc",[null,[356,12],[379,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 381,
                  "column": 10
                },
                "end": {
                  "line": 383,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","u-text--center mt20");
              var el2 = dom.createTextNode("Ihre Behandlung hat derzeit noch keine Positionen.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 353,
                "column": 8
              },
              "end": {
                "line": 384,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","list-not-empty",[["get","treatment.entries",["loc",[null,[354,32],[354,49]]]]],[],["loc",[null,[354,16],[354,50]]]]],[],0,1,["loc",[null,[354,10],[383,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 388,
                "column": 4
              },
              "end": {
                "line": 389,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 344,
              "column": 3
            },
            "end": {
              "line": 391,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Behandlung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","miniBasket");
          dom.setAttribute(el3,"class","treatment");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element5, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [7, 1]),1,1);
          morphs[3] = dom.createMorphAt(element5,9,9);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[346,9],[346,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[347,41],[347,49]]]]],[],["loc",[null,[347,23],[347,51]]]],
          ["block","if",[["get","treatment",["loc",[null,[353,14],[353,23]]]]],[],0,null,["loc",[null,[353,8],[384,15]]]],
          ["block","if",[["get","treatment",["loc",[null,[388,10],[388,19]]]]],[],1,null,["loc",[null,[388,4],[389,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child18 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 392,
                "column": 2
              },
              "end": {
                "line": 396,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[394,61],[394,196]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 391,
              "column": 0
            },
            "end": {
              "line": 397,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sidebarVisible",["loc",[null,[392,8],[392,22]]]]],[],0,null,["loc",[null,[392,2],[396,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 433,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Position hinzufügen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml-auto tab-container mt-0 btn-group");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","quick-filter-wrapper rework-content mb-0");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Filter");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Alle");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon favs me-0");
        dom.setAttribute(el3,"for","filterFavs");
        var el4 = dom.createElement("img");
        dom.setAttribute(el4,"class","me-10");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("Favoriten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" -\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterTiny");
        var el4 = dom.createTextNode("Kleintiere");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterHorse");
        var el4 = dom.createTextNode("Pferde");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","selectable-item has-icon");
        dom.setAttribute(el3,"for","filterUse");
        var el4 = dom.createTextNode("Nutztiere");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $(document).off('click','.spinner__minus');\n        $(document).on('click','.spinner__minus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n\n            if(oldValue > 1) {\n                target.val(oldValue-1);\n            }\n        });\n        $(document).off('click','.spinner__plus');\n        $(document).on('click','.spinner__plus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n            target.val(oldValue+1);\n        });\n\n      $( window ).resize(function() {\n        $('aside.side-bar').css('min-height', $(window).height()-80);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n      });\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n    })\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element19 = dom.childAt(fragment, [2]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [3]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(fragment, [4]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [9, 0]);
        var element26 = dom.childAt(element23, [3]);
        var element27 = dom.childAt(fragment, [6]);
        var element28 = dom.childAt(fragment, [8]);
        var morphs = new Array(36);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element19, 'class');
        morphs[2] = dom.createAttrMorph(element20, 'class');
        morphs[3] = dom.createAttrMorph(element21, 'class');
        morphs[4] = dom.createMorphAt(element22,1,1);
        morphs[5] = dom.createMorphAt(element22,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
        morphs[7] = dom.createMorphAt(element21,3,3);
        morphs[8] = dom.createMorphAt(element21,4,4);
        morphs[9] = dom.createAttrMorph(element24, 'class');
        morphs[10] = dom.createMorphAt(element24,3,3);
        morphs[11] = dom.createMorphAt(element24,7,7);
        morphs[12] = dom.createAttrMorph(element25, 'src');
        morphs[13] = dom.createMorphAt(element24,11,11);
        morphs[14] = dom.createMorphAt(element24,15,15);
        morphs[15] = dom.createMorphAt(element24,19,19);
        morphs[16] = dom.createAttrMorph(element26, 'class');
        morphs[17] = dom.createMorphAt(element26,1,1);
        morphs[18] = dom.createAttrMorph(element27, 'class');
        morphs[19] = dom.createMorphAt(element27,1,1);
        morphs[20] = dom.createMorphAt(element27,2,2);
        morphs[21] = dom.createMorphAt(element27,3,3);
        morphs[22] = dom.createMorphAt(element27,4,4);
        morphs[23] = dom.createMorphAt(dom.childAt(element27, [6]),1,1);
        morphs[24] = dom.createMorphAt(element28,1,1);
        morphs[25] = dom.createMorphAt(element28,2,2);
        morphs[26] = dom.createMorphAt(element28,3,3);
        morphs[27] = dom.createMorphAt(element28,4,4);
        morphs[28] = dom.createMorphAt(element28,5,5);
        morphs[29] = dom.createMorphAt(element28,6,6);
        morphs[30] = dom.createMorphAt(element28,7,7);
        morphs[31] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[32] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[33] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[34] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[35] = dom.createMorphAt(fragment,19,19,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[1,10],[1,14]]]],"inventoryRequests"],[],["loc",[null,[1,6],[1,35]]]]],[],0,null,["loc",[null,[1,0],[12,7]]]],
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[14,69],[14,80]]]],"with-filters",""],[],["loc",[null,[14,48],[14,100]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[15,72],[15,85]]]]],[],["loc",[null,[15,67],[15,86]]]],"24","18"],[],["loc",[null,[15,46],[15,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["attribute","class",["concat",["search-container card-rework  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[20,76],[20,89]]]]],[],["loc",[null,[20,71],[20,90]]]],"24","18"],[],["loc",[null,[20,50],[20,102]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[22,30],[22,40]]]]],[],[]],"placeHolder",["subexpr","@mut",[["get","placeholder",["loc",[null,[22,53],[22,64]]]]],[],[]],"search","applyFilter"],["loc",[null,[22,6],[22,87]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[23,31],[23,42]]]]],[],[]]],["loc",[null,[23,6],[23,44]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,24],[26,28]]]],"templates"],[],["loc",[null,[26,20],[26,41]]]]],[],["loc",[null,[26,15],[26,42]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,52],[26,56]]]],"inventoryRequests"],[],["loc",[null,[26,48],[26,77]]]]],[],["loc",[null,[26,43],[26,78]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,88],[26,92]]]],"deliveryNote"],[],["loc",[null,[26,84],[26,108]]]]],[],["loc",[null,[26,79],[26,109]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,119],[26,123]]]],"compositeTemplate"],[],["loc",[null,[26,115],[26,144]]]]],[],["loc",[null,[26,110],[26,145]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,155],[26,159]]]],"compositeProduct"],[],["loc",[null,[26,151],[26,179]]]]],[],["loc",[null,[26,146],[26,180]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,190],[26,194]]]],"compositeService"],[],["loc",[null,[26,186],[26,214]]]]],[],["loc",[null,[26,181],[26,215]]]]],[],["loc",[null,[26,10],[26,216]]]]],[],1,null,["loc",[null,[25,8],[39,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[42,14],[42,26]]]],"product"],[],["loc",[null,[42,10],[42,37]]]]],[],2,null,["loc",[null,[42,4],[96,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[97,14],[97,26]]]],"service"],[],["loc",[null,[97,10],[97,37]]]]],[],3,null,["loc",[null,[97,4],[129,11]]]],
        ["attribute","class",["concat",["quick-filter col  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[134,66],[134,79]]]]],[],["loc",[null,[134,61],[134,80]]]],"24","18"],[],["loc",[null,[134,40],[134,92]]]],"/24"]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[136,70],[136,79]]]]],[],[]]],["loc",[null,[136,6],[136,81]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[138,72],[138,82]]]]],[],[]]],["loc",[null,[138,6],[138,84]]]],
        ["attribute","src",["concat",["assets/images/icons/rework/favorite",["subexpr","css-bool-evaluator",[["get","filterFavs",["loc",[null,[139,153],[139,163]]]],"-white",""],[],["loc",[null,[139,132],[139,177]]]],".svg"]]],
        ["inline","input",[],["type","checkbox","id","filterTiny","name","filterTiny","checked",["subexpr","@mut",[["get","filterTiny",["loc",[null,[140,72],[140,82]]]]],[],[]]],["loc",[null,[140,6],[140,84]]]],
        ["inline","input",[],["type","checkbox","id","filterHorse","name","filterHorse","checked",["subexpr","@mut",[["get","filterHorse",["loc",[null,[142,74],[142,85]]]]],[],[]]],["loc",[null,[142,6],[142,87]]]],
        ["inline","input",[],["type","checkbox","id","filterUse","name","filterUse","checked",["subexpr","@mut",[["get","filterUse",["loc",[null,[144,70],[144,79]]]]],[],[]]],["loc",[null,[144,6],[144,81]]]],
        ["attribute","class",["concat",["quick-filter col  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[147,66],[147,79]]]]],[],["loc",[null,[147,61],[147,80]]]],"24","18"],[],["loc",[null,[147,40],[147,92]]]],"/24"]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectedType",["loc",[null,[148,20],[148,32]]]],"product"],[],["loc",[null,[148,16],[148,43]]]],["subexpr","eq",[["get","selectedType",["loc",[null,[148,48],[148,60]]]],"service"],[],["loc",[null,[148,44],[148,71]]]]],[],["loc",[null,[148,12],[148,72]]]]],[],4,null,["loc",[null,[148,6],[157,13]]]],
        ["attribute","class",["concat",["table-wrapper-rework col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[161,65],[161,78]]]]],[],["loc",[null,[161,60],[161,79]]]],"24","18"],[],["loc",[null,[161,39],[161,91]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[163,12],[163,24]]]],"product"],[],["loc",[null,[163,8],[163,35]]]]],[],5,null,["loc",[null,[163,2],[184,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[185,12],[185,24]]]],"service"],[],["loc",[null,[185,8],[185,35]]]]],[],6,null,["loc",[null,[185,2],[209,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[210,12],[210,24]]]],"composite"],[],["loc",[null,[210,8],[210,37]]]]],[],7,null,["loc",[null,[210,2],[231,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[232,12],[232,24]]]],"text"],[],["loc",[null,[232,8],[232,32]]]]],[],8,null,["loc",[null,[232,2],[253,9]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[255,29],[255,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[255,6],[255,55]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[260,18],[260,22]]]],"compositeProduct"],[],["loc",[null,[260,14],[260,42]]]],["subexpr","eq",[["get","type",["loc",[null,[260,47],[260,51]]]],"compositeService"],[],["loc",[null,[260,43],[260,71]]]],["subexpr","eq",[["get","type",["loc",[null,[260,76],[260,80]]]],"compositeTemplate"],[],["loc",[null,[260,72],[260,101]]]]],[],["loc",[null,[260,10],[260,102]]]]],[],9,null,["loc",[null,[260,4],[264,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[265,14],[265,18]]]],"deliveryNote"],[],["loc",[null,[265,10],[265,34]]]]],[],10,null,["loc",[null,[265,4],[269,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[270,14],[270,18]]]],"invoices"],[],["loc",[null,[270,10],[270,30]]]]],[],11,null,["loc",[null,[270,4],[274,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[275,14],[275,18]]]],"treatmentOffers"],[],["loc",[null,[275,10],[275,37]]]]],[],12,null,["loc",[null,[275,4],[279,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[280,14],[280,18]]]],"treatmentStationVisits"],[],["loc",[null,[280,10],[280,44]]]]],[],13,null,["loc",[null,[280,4],[284,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[285,14],[285,18]]]],"inventoryRequests"],[],["loc",[null,[285,10],[285,39]]]]],[],14,null,["loc",[null,[285,4],[304,11]]]],
        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[305,14],[305,25]]]],"futureActionBlueprints"],[],["loc",[null,[305,10],[305,51]]]]],[],15,16,["loc",[null,[305,4],[341,11]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[344,9],[344,22]]]]],[],17,18,["loc",[null,[344,3],[397,7]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[399,23],[399,28]]]]],[],[]],"create","connectTask","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[399,97],[399,109]]]]],[],[]]],["loc",[null,[399,0],[399,111]]]],
        ["inline","add-reminder-panel",[],["create","createReminder","actionReceiver",["subexpr","@mut",[["get","addReminderPanel",["loc",[null,[400,60],[400,76]]]]],[],[]]],["loc",[null,[400,0],[400,78]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[401,31],[401,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[401,46],[401,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[401,63],[401,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[401,136],[401,156]]]]],[],[]]],["loc",[null,[401,0],[401,158]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[402,30],[402,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[402,44],[402,51]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[402,62],[402,71]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[402,83],[402,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[402,100],[402,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[402,115],[402,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[402,166],[402,185]]]]],[],[]]],["loc",[null,[402,0],[402,187]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18]
    };
  }()));

});
define('jason-frontend/routes/cash/cancel', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,

    model: function model() {
      return null;
    },
    setupController: function setupController(controller, model) {

      if (!API['default'].hasPermission('c_payments') && !API['default'].hasPermission('c_banktransfer')) {
        this.transitionTo('home');
      }

      if (API['default'].getMandant() === 10001 || API['default'].getMandant() === 10003) {
        controller.set('selectedType', 'banktransfer');
      }

      this.reset();

      controller.set('step', 'select');

      controller.set('reason', null);
      controller.set('filterSlipId', null);
      controller.set('transaction', null);
      controller.set('submitButtonEnabled', true);

      this.store.find('cashRegister', { hideInactive: true }).then(function (data) {
        controller.set('cashRegisterList', data);
        controller.set('selectedCashRegister', data.get('firstObject'));

        if (API['default'].getPrimaryCashRegisterId()) {
          data.forEach(function (item) {
            if (item.id === API['default'].getPrimaryCashRegisterId() && API['default'].getPrimaryCashRegisterId() > 0) {
              controller.set('selectedCashRegister', item);
            }
          });
        }
      });

      Ember['default'].$.ajax({
        url: "/api/cashdesk/signatureUnitAvailable",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (data != true) {
          jason.notifiction.systemError('Signatureinheit ausgefallen', 'Sie können aber weiterhin kassieren!');
        }
      }, function (error) {});

      this.applyFilter();
    },
    reset: function reset() {
      var controller = this.get('controller');

      controller.set('step', 'select');

      controller.set('reason', null);
      controller.set('transaction', null);
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          cashRegisterId = controller.get('selectedCashRegister'),
          page = controller.get('page');

      if (cashRegisterId) {
        cashRegisterId = cashRegisterId.id;
      }

      this.findPaged('cancelableTransaction', {
        filterType: controller.get('selectedType'),
        filterSlipId: controller.get('filterSlipId'),
        perPage: 10,
        page: page,
        filterCancelsBy: true,
        selectedRegister: cashRegisterId
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('totalOpen', entries.meta.total_open);
        controller.set('showLoading', false);
      });
    },
    actions: {
      download: function download(url) {
        window.open(url);
      },
      applyFilter: function applyFilter() {
        var self = this;
        setTimeout(function () {
          self.applyFilter();
        }, 100);
      },
      reset: function reset() {
        var self = this;
        setTimeout(function () {
          self.reset();
        }, 100);
      },
      print: function print(transactionGroup) {
        var deferred = $.ajax({
          url: "/api/cashdesk/print/" + transactionGroup.get('id'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Belegdruck durchgeführt', '');
        }, function () {
          jason.notifiction.systemError('Fehlercode 101', 'Belegdruck konnte nicht durchgeführt werden');
        });
      },
      showSlip: function showSlip() {
        this.get('controller').get('pdfPreview').send('show');
      },
      setType: function setType(type) {
        this.get('controller').set('selectedType', type);

        this.applyFilter();
      },
      selectType: function selectType() {
        var controller = this.get('controller');

        controller.set('step', 'select');
        this.applyFilter();
      },
      toSelectType: function toSelectType() {
        var controller = this.get('controller');
        controller.set('step', 'select');
      },
      toStart: function toStart() {
        var controller = this.get('controller');
        controller.set('step', 'select');
      },
      doCancelation: function doCancelation() {
        var controller = this.get('controller'),
            referenceId = controller.get('transaction').get('id');

        controller.set('submitButtonEnabled', false);

        var payload = {
          transactionCancelId: referenceId,
          reason: controller.get('reason')
        };

        var self = this;

        controller.set('showLoading', true);

        controller.set('step', 'confirmation');

        $.ajax({
          url: "/api/cashdesk/cancel",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          console.log(data);
          self.store.fetch('transaction-group', data).then(function (data2) {
            controller.set('transactionGroup', data2);
            controller.set('transactionDownloadUrl', '/api/salesSlip/' + data2.get('token'));
          });

          jason.notifiction.notifiy('Stornierung durchgeführt', '');
          controller.set('submitButtonEnabled', true);
          controller.set('showLoading', false);
          return data;
        }, function (error) {
          jason.notifiction.systemError('Stornierung konnte nicht durchgeführt werden', 'Fehlercode 100');
          controller.set('submitButtonEnabled', true);
          controller.set('showLoading', false);
          return { status: error.statusText, message: error.responseText };
        });
      },
      selectTransaction: function selectTransaction(transaction) {
        var controller = this.get('controller');
        controller.set('step', 'summary');
        controller.set('transaction', transaction);
      }
    }
  });

});
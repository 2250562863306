define('jason-frontend/controllers/cash/cancel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    breadCrumb: "Storno",
    breadCrumbPath: "cash.cancel",
    selectedType: 'in',
    step: "select",
    sortBy: null,
    showFilters: true,
    sortDir: null,
    filterSlipId: null,
    selectedCashRegister: null,

    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    page: 1,
    perPage: 10,
    watchCashRegister: (function () {
      this.send('applyFilter');
    }).observes('selectedCashRegister')
  });

});
define('jason-frontend/templates/notifications', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context align-items-center");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/chat-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Chat");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["element","action",["openGen2Chat"],[],["loc",[null,[6,11],[6,36]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 15,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n         ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[11,11],[11,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 0
            },
            "end": {
              "line": 60,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[59,2],[59,23]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 152,
                      "column": 20
                    },
                    "end": {
                      "line": 154,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 18
                  },
                  "end": {
                    "line": 155,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[152,47],[152,63]]]],["subexpr","query-params",[],["selectTab","lab"],["loc",[null,[152,64],[152,94]]]]],["title","Patient anzeigen","classNames","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[152,20],[154,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 157,
                      "column": 20
                    },
                    "end": {
                      "line": 159,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 156,
                    "column": 18
                  },
                  "end": {
                    "line": 160,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["patients.edit",["get","item.referenceId",["loc",[null,[157,47],[157,63]]]],["subexpr","query-params",[],["selectTab","history"],["loc",[null,[157,64],[157,98]]]]],["title","Patient anzeigen","classNames","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[157,20],[159,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 162,
                      "column": 20
                    },
                    "end": {
                      "line": 164,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 161,
                    "column": 18
                  },
                  "end": {
                    "line": 165,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["invoices.show",["get","item.referenceId",["loc",[null,[162,47],[162,63]]]]],["title","Rechnung anzeigen","classNames","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[162,20],[164,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 167,
                      "column": 20
                    },
                    "end": {
                      "line": 169,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/notifications.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 166,
                    "column": 18
                  },
                  "end": {
                    "line": 170,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/notifications.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["purchases.show",["get","item.referenceId",["loc",[null,[167,48],[167,64]]]]],["title","Bestellung anzeigen","classNames","icon-button icon-button--default list-action-square"],0,null,["loc",[null,[167,20],[169,32]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 150,
                  "column": 16
                },
                "end": {
                  "line": 171,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/notifications.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[151,28],[151,46]]]],"treatment"],[],["loc",[null,[151,24],[151,59]]]]],[],0,null,["loc",[null,[151,18],[155,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[156,28],[156,46]]]],"patient"],[],["loc",[null,[156,24],[156,57]]]]],[],1,null,["loc",[null,[156,18],[160,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[161,28],[161,46]]]],"invoice"],[],["loc",[null,[161,24],[161,57]]]]],[],2,null,["loc",[null,[161,18],[165,25]]]],
              ["block","if",[["subexpr","eq",[["get","item.referenceType",["loc",[null,[166,28],[166,46]]]],"purchase"],[],["loc",[null,[166,24],[166,58]]]]],[],3,null,["loc",[null,[166,18],[170,25]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 128,
                "column": 12
              },
              "end": {
                "line": 174,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/notifications.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-23/24@phone l-23/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Wird verschickt am");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Tierarztpraxis");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","heading--inline");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Kategorie");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-1/24@phone l-1/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Empfänger");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","heading--inline");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Titel");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Nachricht");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-2/24@desk");
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 0]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1, 0]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [9, 1]),0,0);
            morphs[5] = dom.createUnsafeMorphAt(dom.childAt(element0, [11, 1]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[131,38],[131,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[131,24],[131,73]]]],
            ["content","item.mandantString",["loc",[null,[134,52],[134,74]]]],
            ["content","item.category.name",["loc",[null,[137,22],[137,44]]]],
            ["inline","button-text",[],["text",["subexpr","truncate",[["get","item.receiversString",["loc",[null,[140,81],[140,101]]]],100],[],["loc",[null,[140,71],[140,106]]]],"content",["subexpr","@mut",[["get","item.receiversString",["loc",[null,[140,115],[140,135]]]]],[],[]]],["loc",[null,[140,52],[140,137]]]],
            ["content","item.title",["loc",[null,[143,22],[143,36]]]],
            ["content","item.message",["loc",[null,[146,22],[146,40]]]],
            ["block","if",[["get","item.referenceId",["loc",[null,[150,22],[150,38]]]]],[],0,null,["loc",[null,[150,16],[171,23]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 0
            },
            "end": {
              "line": 180,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/notifications.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","tabs__body mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-inbox");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-unread");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-outbox");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-future");
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table-wrapper-rework");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table grid");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__head");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-3/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Wird verschickt am");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-3/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Tierarztpraxis");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-3/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Kategorie");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-3/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Empfänger");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-5/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Titel");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-5/24");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","table__title title");
          var el8 = dom.createTextNode("Nachricht");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","table__cell l-2/24");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__body");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var element4 = dom.childAt(element1, [5]);
          var element5 = dom.childAt(element1, [7]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
          morphs[3] = dom.createAttrMorph(element3, 'class');
          morphs[4] = dom.createMorphAt(element3,1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          morphs[6] = dom.createAttrMorph(element4, 'class');
          morphs[7] = dom.createMorphAt(element4,1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          morphs[9] = dom.createAttrMorph(element5, 'class');
          morphs[10] = dom.createMorphAt(dom.childAt(element5, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[63,56],[63,65]]]],"inbox"],[],["loc",[null,[63,52],[63,74]]]],"is-active",""],[],["loc",[null,[63,31],[63,91]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[64,39],[64,44]]]]],[],[]],"delete","delete","reply","reply","activateEtiga","chooseEtigaPanel","markAsReadForAll","markAsReadForAll","showTask","showTask","startAdmission","startAdmission","markAsRead","markAsRead","gotoPracticeCart","gotoPracticeCart","gotoPurchaseProduct","gotoPurchaseProduct","addTask","addTask","openDirectMessage","openDirectMessage","gotoPatient","gotoPatient","startAdmissionFromReorder","startAdmissionFromReorder","startAdmissionFromMessage","startAdmissionFromMessage","openAppRequestConfirmation","openAppRequestConfirmation","forward","forward","type","in"],["loc",[null,[64,8],[81,44]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[83,33],[83,40]]]]],[],[]],"numPagesToShow",7,"currentPage",["subexpr","@mut",[["get","page",["loc",[null,[83,70],[83,74]]]]],[],[]]],["loc",[null,[83,10],[83,76]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[86,56],[86,65]]]],"unread"],[],["loc",[null,[86,52],[86,75]]]],"is-active",""],[],["loc",[null,[86,31],[86,92]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[87,39],[87,44]]]]],[],[]],"delete","delete","reply","reply","activateEtiga","chooseEtigaPanel","markAsReadForAll","markAsReadForAll","showTask","showTask","markAsRead","markAsRead","startAdmission","startAdmission","openDirectMessage","openDirectMessage","gotoPracticeCart","gotoPracticeCart","gotoPurchaseProduct","gotoPurchaseProduct","gotoPatient","gotoPatient","startAdmissionFromReorder","startAdmissionFromReorder","openAppRequestConfirmation","openAppRequestConfirmation","addTask","addTask","forward","forward","type","unread"],["loc",[null,[87,8],[103,48]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[105,33],[105,40]]]]],[],[]],"numPagesToShow",7],["loc",[null,[105,10],[105,59]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[108,56],[108,65]]]],"outbox"],[],["loc",[null,[108,52],[108,75]]]],"is-active",""],[],["loc",[null,[108,31],[108,92]]]]]]],
          ["inline","notifications-timeline",[],["items",["subexpr","@mut",[["get","model",["loc",[null,[109,39],[109,44]]]]],[],[]],"delete","delete","reply","reply","addTask","addTask","showTask","showTask","forward","forward","type","out"],["loc",[null,[109,8],[109,143]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[111,33],[111,40]]]]],[],[]],"numPagesToShow",7],["loc",[null,[111,10],[111,59]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[114,56],[114,65]]]],"future"],[],["loc",[null,[114,52],[114,75]]]],"is-active",""],[],["loc",[null,[114,31],[114,92]]]]]]],
          ["block","each",[["get","futureItems",["loc",[null,[128,20],[128,31]]]]],[],0,null,["loc",[null,[128,12],[174,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 214,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/notifications.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Benachrichtigungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","filter-lane grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Kategorie");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Status");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content tabs-rework-wrapper grid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-inbox");
        var el3 = dom.createTextNode("Alle");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-unread");
        var el3 = dom.createTextNode("Ungelesene");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-outbox");
        var el3 = dom.createTextNode("Gesendet");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-future");
        var el3 = dom.createTextNode("Geplant");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","animated");
        dom.setAttribute(el1,"id","notificationsPanel");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(element8, [1, 3]);
        var element10 = dom.childAt(element8, [3, 1]);
        var element11 = dom.childAt(fragment, [2]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [3]);
        var element14 = dom.childAt(element11, [5]);
        var element15 = dom.childAt(element11, [7]);
        var morphs = new Array(26);
        morphs[0] = dom.createAttrMorph(element8, 'class');
        morphs[1] = dom.createMorphAt(element9,1,1);
        morphs[2] = dom.createMorphAt(element9,2,2);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]),3,3);
        morphs[5] = dom.createAttrMorph(element12, 'class');
        morphs[6] = dom.createElementMorph(element12);
        morphs[7] = dom.createAttrMorph(element13, 'class');
        morphs[8] = dom.createElementMorph(element13);
        morphs[9] = dom.createAttrMorph(element14, 'class');
        morphs[10] = dom.createElementMorph(element14);
        morphs[11] = dom.createAttrMorph(element15, 'class');
        morphs[12] = dom.createElementMorph(element15);
        morphs[13] = dom.createMorphAt(dom.childAt(fragment, [4, 1]),1,1);
        morphs[14] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,20,20,contextualElement);
        morphs[22] = dom.createMorphAt(fragment,22,22,contextualElement);
        morphs[23] = dom.createMorphAt(fragment,24,24,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,26,26,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,28,28,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["block","if",[["subexpr","has-booking-app",[],[],["loc",[null,[5,12],[5,29]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[10,16],[10,34]]]],["subexpr","is-at-least",["essential"],[],["loc",[null,[10,35],[10,60]]]]],[],["loc",[null,[10,12],[10,61]]]]],[],1,null,["loc",[null,[10,6],[15,13]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[24,18],[24,28]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[25,16],[25,30]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",false,"cssClass","custom-select2 newStyle tuned"],["loc",[null,[23,8],[30,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","status",["loc",[null,[35,18],[35,24]]]]],[],[]],"value",["subexpr","@mut",[["get","filterStatus",["loc",[null,[36,16],[36,28]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[34,8],[42,10]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[49,51],[49,60]]]],"inbox"],[],["loc",[null,[49,47],[49,69]]]],"is-active",""],[],["loc",[null,[49,26],[49,86]]]]]]],
        ["element","action",["selectTab","inbox",1],[],["loc",[null,[49,106],[49,138]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[50,51],[50,60]]]],"unread"],[],["loc",[null,[50,47],[50,70]]]],"is-active",""],[],["loc",[null,[50,26],[50,87]]]]]]],
        ["element","action",["selectTab","unread",1],[],["loc",[null,[50,108],[50,141]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[51,51],[51,60]]]],"outbox"],[],["loc",[null,[51,47],[51,70]]]],"is-active",""],[],["loc",[null,[51,26],[51,87]]]]]]],
        ["element","action",["selectTab","outbox",1],[],["loc",[null,[51,108],[51,141]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[52,51],[52,60]]]],"future"],[],["loc",[null,[52,47],[52,70]]]],"is-active",""],[],["loc",[null,[52,26],[52,87]]]]]]],
        ["element","action",["selectTab","future",1],[],["loc",[null,[52,108],[52,141]]]],
        ["block","if",[["subexpr","or",[["get","showLoadingOverride",["loc",[null,[58,10],[58,29]]]],["get","showLoading",["loc",[null,[58,30],[58,41]]]]],[],["loc",[null,[58,6],[58,42]]]]],[],2,3,["loc",[null,[58,0],[180,7]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[186,31],[186,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[186,46],[186,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[186,63],[186,70]]]]],[],[]],"create","create","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[186,125],[186,145]]]]],[],[]]],["loc",[null,[186,0],[186,147]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[187,23],[187,28]]]]],[],[]],"create","connectTask","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[187,97],[187,109]]]]],[],[]]],["loc",[null,[187,0],[187,111]]]],
        ["inline","add-reply-panel",[],["create","addReply","actionReceiver",["subexpr","@mut",[["get","addReplyPanel",["loc",[null,[188,51],[188,64]]]]],[],[]]],["loc",[null,[188,0],[188,66]]]],
        ["inline","choose-etiga-panel",[],["select","selectPatientForEtigaActivation","actionReceiver",["subexpr","@mut",[["get","chooseEtigaPanel",["loc",[null,[189,77],[189,93]]]]],[],[]]],["loc",[null,[189,0],[189,95]]]],
        ["inline","choose-etiga-customer-panel",[],["select","selectCustomerForEtigaActivation","createCustomerForEtigaActivation","createCustomerForEtigaActivation","genders",["subexpr","@mut",[["get","genders",["loc",[null,[190,148],[190,155]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","chooseEtigaCustomerPanel",["loc",[null,[190,171],[190,195]]]]],[],[]]],["loc",[null,[190,0],[190,197]]]],
        ["inline","add-direct-app-notification-panel",[],["reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[192,101],[192,125]]]]],[],[]]],["loc",[null,[192,0],[192,127]]]],
        ["inline","app-request-confirm-panel",[],["confirm","confirmAppRequest","gotoPatient","gotoPatient","gotoPatientEtiga","gotoPatient","gotoCustomer","gotoCustomer","abort","abortAppRequest","actionReceiver",["subexpr","@mut",[["get","appRequestConfirmPanel",["loc",[null,[196,95],[196,117]]]]],[],[]]],["loc",[null,[193,0],[196,119]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[199,60],[199,75]]]]],[],[]]],["loc",[null,[199,0],[199,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[200,59],[200,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[200,80],[200,100]]]]],[],[]]],["loc",[null,[200,0],[200,102]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[202,33],[202,46]]]]],[],[]],"linkTo","linkTo","finishTask","finishTask","changeOwner","changeOwner"],["loc",[null,[202,0],[202,114]]]],
        ["inline","treatment-info-form",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[204,68],[204,88]]]]],[],[]]],["loc",[null,[204,0],[204,90]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[210,14],[210,19]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[211,16],[211,23]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[212,19],[212,29]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[213,23],[213,42]]]]],[],[]]],["loc",[null,[206,0],[213,44]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
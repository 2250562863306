define('jason-frontend/components/notifications-timeline', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initTooltips();
    },

    initTooltips: function initTooltips() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.$('.tooltipstered').tooltipster({
          theme: 'tooltipster-punk',
          delay: 0,
          side: 'top',
          touchDevices: false
        });
      });
    },
    actions: {
      markAsRead: function markAsRead(msg) {
        this.sendAction('markAsRead', msg);
      },
      markAsReadForAll: function markAsReadForAll(msg) {
        this.sendAction('markAsReadForAll', msg);
      },
      startAdmissionFromReorder: function startAdmissionFromReorder(msg) {
        this.sendAction('startAdmissionFromReorder', msg);
      },
      startAdmissionFromMessage: function startAdmissionFromMessage(msg) {
        this.sendAction('startAdmissionFromMessage', msg);
      },
      startAdmission: function startAdmission(id, customerId) {
        this.sendAction('startAdmission', id, customerId);
      },
      activateEtiga: function activateEtiga(id, msgId) {
        this.sendAction('activateEtiga', id, msgId);
      },
      openAppRequestConfirmation: function openAppRequestConfirmation(item) {
        this.sendAction('openAppRequestConfirmation', item);
      },
      forward: function forward(msg) {
        this.sendAction('forward', msg);
      },
      gotoPatient: function gotoPatient(msg) {
        this.sendAction('gotoPatient', msg);
      },
      openDirectMessage: function openDirectMessage(id, type) {
        this.sendAction('openDirectMessage', id, type);
      },
      reply: function reply(msg) {
        this.sendAction('reply', msg);
      },
      gotoPracticeCart: function gotoPracticeCart() {
        this.sendAction('gotoPracticeCart');
      },
      gotoPurchaseProduct: function gotoPurchaseProduct(id) {
        this.sendAction('gotoPurchaseProduct', id);
      },
      "delete": function _delete(msg) {
        this.sendAction('delete', msg);
      },
      addTask: function addTask(msg) {
        this.sendAction('addTask', msg);
      },
      showTask: function showTask(msg) {
        this.sendAction('showTask', msg);
      },
      openShopOrder: function openShopOrder(id) {
        var deferred = $.ajax({
          url: "https://identity.vetnative.com/api/identity/auth/elovetRedirect",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText + '&redirect=shopOrder&redirectId=' + id);
        }, function (data) {
          window.open('https://elovet.vetnative.com/auth/autologin?token=' + data.responseText + '&redirect=shopOrder&redirectId=' + id);
        });
      }
    }
  });

});
define('jason-frontend/components/invoice-orderstatus', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    downloadWarning: true,
    subClass: 'small',
    maxWidthClass: 'min-w-140px',
    actions: {
      downloadWarning: function downloadWarning(invoice, number) {
        this.sendAction('downloadWarning', invoice, number);
      },
      forwardWarning: function forwardWarning(invoice, number) {
        this.sendAction('forwardWarning', invoice, number);
      },
      editStatusChange: function editStatusChange(invoice) {
        this.sendAction('editStatusChange', invoice);
      }
    }
  });

});
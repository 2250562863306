define('jason-frontend/routes/cash/choose', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, API, RouteMixin, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 20,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      controller.set('model', model);
      if (!API['default'].hasPermission('c_payments')) {
        this.transitionTo('home');
      }

      controller.set('patientGenders', this.store.find('patient-gender'));
      controller.set('patientCategories', this.store.find('patient-category'));

      this.loadData();
    },
    loadData: function loadData() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          quickFilter = controller.get('quickFilter'),
          filterCustomer = controller.get('filterName'),
          sortBy = controller.get('sortBy'),
          page = controller.get('page');

      controller.set('showLoading', true);

      this.findPaged('invoice', {
        filterCustomerName: filterCustomer,
        sortBy: sortBy,
        context: 'open',
        filterVisibility: quickFilter,
        perPage: 20,
        page: page
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('count', entries.meta.total_records);
        controller.set('sum', entries.meta.result_open);
        controller.set('showLoading', false);
      });
    },
    actions: {
      clickPatient: function clickPatient(patient) {
        var tab = 'history';
        if (is_journal_enabled.isJournalEnabled()) {
          tab = 'activities';
        }
        this.transitionTo('patients.edit', patient.id, { queryParams: { selectTab: tab } });
      },
      toggleQuickFilter: function toggleQuickFilter(filter) {
        this.get('controller').set('quickFilter', filter);
      },
      applyFilter: function applyFilter() {
        this.loadData();
      },
      createInvoice: function createInvoice() {
        var invoice = {},
            self = this;

        Ember['default'].$.ajax({
          url: "/api/invoices",
          method: "POST",
          data: JSON.stringify(invoice),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.transitionTo('invoices.select_customer', data);
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
      },
      gotoHistory: function gotoHistory(patient) {
        var tab = 'history';
        if (is_journal_enabled.isJournalEnabled()) {
          tab = 'activities';
        }
        this.sendAction('gotoPatient', patient.id, tab);
      },
      selectInvoice: function selectInvoice(id) {
        this.transitionTo('invoices.create', id, { queryParams: { entry: "true" } });
      },
      changePatient: function changePatient(invoiceId, patientId, add) {
        var self = this,
            controller = self.get('controller');
        Ember['default'].$.ajax({
          url: "/api/invoices/" + invoiceId + "/changePatient/" + patientId + "/" + add,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.loadData();
        });
      },
      undeleteInvoice: function undeleteInvoice(id) {
        var controller = this.get('controller');
        var self = this;
        Ember['default'].$.ajax({
          url: "/api/invoices/" + id + "/undelete",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Ausgangsrechnung', 'wiederhergestellt');
          self.loadData();
        }, function () {
          jason.notifiction.notifiy('Ausgangsrechnung', 'wiederhergestellt');
          self.loadData();
        });
      },
      closeInvoice: function closeInvoice(id) {
        var controller = this.get('controller');
        var self = this;
        Ember['default'].$.ajax({
          url: "/api/invoices/" + id + "/close",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht geschlossen werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'geschlossen');
            self.loadData();
          }
        }, function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht geschlossen werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'geschlossen');
            self.loadData();
          }
        });
      },
      deleteInvoice: function deleteInvoice(id) {
        var controller = this.get('controller');
        var self = this;
        Ember['default'].$.ajax({
          url: "/api/invoices/" + id + "/delete",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht gelöscht werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'gelöscht');
            self.loadData();
          }
        }, function (data) {
          if (data.status === 500) {
            jason.notifiction.errorLong('Fehler', 'Ausgangsrechnung konnte aufgrund einer offenen Lagerbuchung nicht gelöscht werden');
          } else {
            jason.notifiction.notifiy('Ausgangsrechnung', 'gelöscht');
            self.loadData();
          }
        });
      },
      createPatient: function createPatient(customerId, patientData) {
        var controller = this.get('controller'),
            self = this;

        var newPatient = {
          name: patientData.name,
          birthdayFormatted: patientData.birthdayFormatted,
          customerId: customerId,
          castrated: patientData.castrated,
          chipId: patientData.chipId
        };

        if (patientData.patientCategory) {
          newPatient.category = {
            id: patientData.patientCategory
          };
        }
        if (patientData.patientGender) {
          newPatient.gender = {
            id: patientData.patientGender
          };
        }

        $.ajax({
          url: "/api/patients",
          method: "POST",
          data: JSON.stringify(newPatient),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function (error) {});
      }
    }
  });

});
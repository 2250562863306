define('jason-frontend/routes/practicemgmt/treatment-visit-details', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, API, RouteMixin, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    initTooltips: function initTooltips() {
      $('.tooltipstered').tooltipster({
        theme: 'tooltipster-punk',
        delay: 0,
        side: 'top',
        touchDevices: false
      });
    },
    updateState: function updateState(_updateState, to) {
      var self = this;

      $.ajax({
        url: "/api/treatments/updateState",
        method: "POST",
        data: JSON.stringify(_updateState),
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (_updateState.newState === 'in_treatment') {
          self.transitionTo('practicemgmt.treatment-details', _updateState.treatmentPatientId, { queryParams: { entry: "true" } });
        } else {
          self.transitionTo(to);
        }
      });
    },
    reloadPage: function reloadPage(id) {
      var params = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var controller = this.get('controller'),
          self = this;

      if (!id) {
        id = controller.get('model').id;
      }

      this.store.fetch('treatment-station-visit', id).then(function (data) {
        controller.set('model', data);
        self.reloadOffer();
      });
    },
    reloadOffer: function reloadOffer() {

      var controller = this.get('controller');
      var self = this;

      self.store.fetch('treatment-offer', controller.get('model').get('offerId')).then(function (offer) {
        controller.set('offer', offer);
      });
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'past') {
        this.findPaged('treatment-patient', {
          patientId: controller.get('model').get('patient').id,
          filterLab: controller.get('filterLab'),
          filterProduct: controller.get('filterProduct'),
          filterService: controller.get('filterService'),
          filterFood: controller.get('filterFood'),
          perPage: 10
        }).then(function (entries) {
          controller.set('historicItems', entries);
        });
      } else if (selected === 'journal') {
        controller.get('patientActivities').send('load', this, controller.get('model').get('patient').id);
        var url = new URL(window.location.href);
        url.searchParams.set('selectTab', 'journal');
        window.history.replaceState(null, null, url);
        // controller.get('patientActivities').send('load', this, controller.get('model').get('patient').id);
      }

      if (selected !== 'now') {
        // if (this.get('controller').get('inputDirty')) {
        //   this.get('controller').send('save', this.get('controller').get('model').id);
        // }
      }
    },
    handleResize: function handleResize(event) {
      $('.floating-header-wrapper').attr('style', 'width: ' + $('#stationVisitDetails').width() + 'px !important');
    },
    handleScroll: function handleScroll(event) {
      var scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop <= 110) {
        $('body').removeClass('floating-header-active');
      }
      if (scrollTop > 110) {
        $('body').addClass('floating-header-active');
      }
      $('.floating-header-wrapper').attr('style', 'width: ' + $('#stationVisitDetails').width() + 'px !important');
    },
    resetController: function resetController(controller, isExiting, transition) {
      var self = this;
      $.ajax({
        url: "/api/treatmentStationVisits/" + this.get('controller').get('model').id + "/saveAsync",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {}, function (error) {});
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize);
    },
    setupController: function setupController(controller, model, params) {
      var id = params.params['practicemgmt.treatment-visit-details'].id;
      var self = this;

      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize);

      this.reloadPage(id, params.queryParams);

      controller.set('selectTab', 'now');

      controller.set('doctors', this.store.find('user', { role: 'doctor', per_page: 100 }));
      controller.set('assistants', this.store.find('user', { role: 'assistant,doctor', per_page: 100 }));

      controller.set('patientTab', 'masterdata');

      var selectedTab = params.queryParams.selectTab;

      if (selectedTab === 'past') {
        selectedTab = 'now';
      }
      if (selectedTab) {
        setTimeout(function () {
          self.selectTab(selectedTab);
        }, 1000);
      }
      setTimeout(function () {
        self.initTooltips();
      }, 1000);
    },
    openEmailEtigaPanel: function openEmailEtigaPanel(patient) {

      var self = this;

      var deferred = $.ajax({
        url: "/api/patients/" + patient.id,
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        self.get('controller').get('emailEtigaPanel').send('loadWithEmails', data.patient, data.patient.customer.emails, data.patient.etigaQr);
      }, function (error) {});

      $.magnificPopup.open({
        removalDelay: 500,
        closeOnBgClick: false,
        items: {
          src: '#modal-email-etiga-panel'
        },
        callbacks: {
          beforeOpen: function beforeOpen() {
            var Animation = "mfp-with-fade";
            this.st.mainClass = Animation;
          }
        },
        midClick: true
      });
    },
    actions: {
      openChangeDayPanel: function openChangeDayPanel() {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-invoice-assign-day-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('controller').get('assignDayPanel').send('load', this.get('controller').get('model').id);
      },
      changeAllDays: function changeAllDays(day, visitId) {
        var self = this;
        var data = {
          day: moment(day, 'DD. MM. YYYY').format('YYYY-MM-DD')
        };
        $.ajax({
          url: "/api/treatmentOffers/" + this.get('controller').get('model').get('offerId') + "/changeDay" + (visitId ? "?visitId=" + visitId : ""),
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.reloadOffer();
          jason.notifiction.notifiy('Erfolgreich', 'geändert');
        }, function () {
          self.reloadOffer();
          jason.notifiction.notifiy('Erfolgreich', 'geändert');
        });
      },
      editFullTreatment: function editFullTreatment(treatmentId) {
        this.transitionTo('practicemgmt.treatment-details', treatmentId, { queryParams: { entry: "true" } });
      },
      openVisitDetails: function openVisitDetails(id) {
        this.transitionTo('practicemgmt.treatment-visit-details', id);
      },
      changeBox: function changeBox(id, data) {
        var self = this;
        var payload = {
          cageId: data.cageId
        };
        $.ajax({
          url: "/api/treatmentStationVisits/" + this.get('controller').get('model').id + "/changeBox",
          method: "POST",
          data: JSON.stringify(payload),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadPage();
        }, function (error) {
          self.reloadPage();
        });
      },
      editBox: function editBox(id) {
        this.get('controller').get('setStationPanel').send('loadChangeBox', this.get('controller').get('model').id);

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-station-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      exitVisit: function exitVisit() {
        var self = this;
        $.ajax({
          url: "/api/treatmentStationVisits/" + this.get('controller').get('model').id + "/exit",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          var updateState = {
            treatmentPatientId: self.get('controller').get('model').get('treatmentPatientId'),
            newState: 'in_treatment',
            oldState: 'in_station'
          };
          self.updateState(updateState);
        }, function (error) {
          var updateState = {
            treatmentPatientId: self.get('controller').get('model').get('treatmentPatientId'),
            newState: 'in_treatment',
            oldState: 'in_station'
          };
          self.updateState(updateState);
        });
      },
      autoSave: function autoSave() {
        if (this.get('controller').get('inputDirty') && this.get('controller').get('model')) {
          this.get('controller').send('save', this.get('controller').get('model').id);
          this.get('controller').set('inputDirty', false);
        }
      },
      changeDay: function changeDay(day, entryId) {

        var data = {
          day: moment(day, 'DD.MM.YYYY').format('YYYY-MM-DD')
        };

        $.ajax({
          url: "/api/treatmentOffers/0/changeDay/" + entryId,
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {}, function () {});
      },
      updateDeathDate: function updateDeathDate(data) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + data.id + "/updateDeathDay",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadPage();
        }, function (error) {
          self.reloadPage();
        });
      },
      removeDeathDate: function removeDeathDate(data) {
        var self = this;
        $.ajax({
          url: "/api/patients/" + data.id + "/removeDeathDate",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadPage();
        }, function (error) {
          self.reloadPage();
        });
      },
      setDeathDate: function setDeathDate(type) {
        this.get('controller').get('deathDayPanel').send('load', this.get('controller').get('model').get('patient'), type);
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-set-death-day-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      gotoReport: function gotoReport(id) {
        this.transitionTo('editor.edit', this.get('controller').get('model').id, 'treatmentreport', id);
      },
      gotoReportFromJournal: function gotoReportFromJournal(tpId, id) {
        this.transitionTo('editor.edit', tpId, 'treatmentreport', id);
      },
      gotoTemplateFromJournal: function gotoTemplateFromJournal(tpId, id) {
        this.transitionTo('editor.edit', id, 'templateinstance', tpId);
      },
      openReportHistory: function openReportHistory(tpId, id) {
        this.transitionTo('editor.edit', tpId, 'treatmentreport', id);
      },
      activateEtiga: function activateEtiga() {
        var self = this;

        $.ajax({
          url: "/api/patients?filterEtiga=true",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          if (data.patient.length > 0) {

            self.get('controller').get('existingEtigaPanel').send('load', data.patient);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-choose-existing-etiga-panel'
              },
              callbacks: {
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.send('createNew');
          }
        }, function (error) {});
      },
      reloadWeightData: function reloadWeightData(id, sortDir) {
        this.get('controller').set('weightInfos', this.store.find('patientWeightInfo', { patientId: id, sortDir: sortDir }));
        this.reloadPage();
      },
      reopenWeightPanel: function reopenWeightPanel() {
        this.reloadPage();
      },

      openWeightPanel: function openWeightPanel() {
        var self = this,
            patientId = this.get('controller').get('model').get('patient').id;

        this.get('controller').set('weightInfos', this.store.find('patientWeightInfo', { patientId: patientId }));

        $('#highcharts').data('patient-id', patientId);

        this.get('controller').get('weightChart').send('reloadChart');

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-weight-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      toTreatment: function toTreatment(model) {
        this.transitionTo('practicemgmt.treatment-details', this.get('controller').get('model').get('treatmentPatientId'), { queryParams: { entry: "true" } });
      },
      openEmailEtigaPanel: function openEmailEtigaPanel(patient) {
        this.openEmailEtigaPanel(patient);
      },
      gotoPatient: function gotoPatient(patient, tab) {
        var timeout = 0,
            self = this;

        if (!API['default'].hasPermission('crud_treatments')) {
          return;
        }

        if (patient.deleted) {
          return;
        }

        if (this.get('controller').get('inputDirty')) {
          this.get('controller').send('save', this.get('controller').get('model').id);
          this.get('controller').set('inputDirty', false);
          timeout = 500;
        }
        setTimeout(function () {
          if (tab) {

            if (is_journal_enabled.isJournalEnabled() && tab === 'history') {
              tab = 'activities';
            }

            self.transitionTo('patients.edit', patient.id, { queryParams: { selectTab: tab } });
          } else {
            self.transitionTo('patients.edit', patient.id);
          }
        }, timeout);
      },
      assignLaboklin: function assignLaboklin(treatmentPatientId, entryId, formId) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + treatmentPatientId + "/linkLaboklinForm/" + entryId + "/" + formId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          self.reloadPage();
        }, function () {
          jason.notifiction.systemError('Fehlercode 119', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      downloadLaboklinBarcode: function downloadLaboklinBarcode(formId, format) {
        window.open('/api/treatmentPatients/downloadBarcode/' + formId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadResult: function downloadResult(id) {

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/labResults/downloadSecure/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadPrivateMedia: function downloadPrivateMedia(id) {

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/labResults/downloadOriginal/' + API['default'].getMandant() + '/' + data.responseText);
        });
      },
      downloadSlopingBarcode: function downloadSlopingBarcode(entryId, format) {
        window.open('/api/treatmentOffers/downloadSloping/' + entryId + '/' + format + '?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      selectSlopingBarcodeForInvoice: function selectSlopingBarcodeForInvoice(entryId, format) {
        window.open('/api/invoices/downloadSloping/' + entryId + '/default?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken());
      },
      downloadPdf: function downloadPdf(id) {
        this.downloadPdf(id);
      },
      downloadMedia: function downloadMedia(id, media) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadLaboklin/' + API['default'].getMandant() + '/' + media + '/' + data.responseText);
        });
      },
      deleteLab: function deleteLab(formId) {
        var controller = this.get('controller');

        $.ajax({
          url: "/api/treatmentPatients/deleteLaboklin/" + formId,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Untersuchungsauftrag wurde entfernt');
          $.ajax({
            url: "/api/patients/" + controller.get('model').get('patient').id + "/laboklinForms?sortDir=" + controller.get('treatmentSortDir'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('laboklinHistoricLabs').send('load', data.purchaseOrderLaboklinForm);
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 111', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      unlinkLaboklin: function unlinkLaboklin(entryId, formId) {
        var controller = this.get('controller'),
            self = this;

        $.ajax({
          url: "/api/treatmentPatients/unlinkLaboklin/" + entryId + "/" + formId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Leistung wurde Untersuchungsauftrag entfernt');
          $.ajax({
            url: "/api/treatmentPatients/" + controller.get('model').id + "/laboklinForms/" + formId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.reloadPage();
            controller.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'));
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 111', 'Eingaben konnte nicht gespeichert werden');
        });
      },
      submitLaboklin: function submitLaboklin(id, formId) {
        var controller = this.get('controller'),
            self = this;

        var data = {
          formId: formId
        };

        $.ajax({
          url: "/api/treatmentPatients/submitLaboklin",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Untersuchungsauftrag wurde erfolgreich übermittelt');
          self.downloadPdf(formId);
          $.ajax({
            url: "/api/treatmentPatients/" + controller.get('model').id + "/laboklinForms/" + formId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.reloadPage();
            controller.get('laboklinTreatmentPanel').send('load', self.get('model').id, 0, data, null, null, self.store.find('patient-category'));
          });

          $.ajax({
            url: "/api/patients/" + controller.get('model').get('patient').id + "/laboklinForms?sortDir=" + controller.get('treatmentSortDir'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.get('laboklinHistoricLabs').send('load', data.purchaseOrderLaboklinForm);
          });
        }, function () {
          jason.notifiction.systemError('Fehlercode 113', 'Untersuchungsauftrag konnte nicht übermittelt werden');
        });
      },
      linkNewLaboklinForm: function linkNewLaboklinForm(treatmentPatientId, entryIds, formId, update) {
        var self = this;

        if (!update) {
          $.ajax({
            url: "/api/treatmentPatients/" + treatmentPatientId + "/linkLaboklinForm/" + entryIds + "/" + formId,
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function () {
            self.reloadPage();
            jason.notifiction.notifiy('Eingaben', 'erfolgreich gespeichert');
          }, function () {
            jason.notifiction.systemError('Fehlercode 129', 'Eingaben konnte nicht gespeichert werden');
          });
        } else {
          this.reloadOffer();
        }
      },
      addTemplateToPosition: function addTemplateToPosition(entry) {
        var controller = this.get('controller');
        var self = this;
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-template-instance-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        controller.get('addTemplateInstancePanel').send('load', entry);
      },
      addTemplateInstanceFromPosition: function addTemplateInstanceFromPosition(entry, instanceId) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + controller.get('model').id + "/addTemplateFromPosition/" + entry.id + "/" + instanceId,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadOffer();
        }, function () {
          self.reloadOffer();
        });
      },
      openChipPanel: function openChipPanel(patient) {
        this.get('controller').get('patientChipPanel').send('load', patient, this);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-chip-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDocument: function openDocument(id) {
        this.transitionTo('patients.edit', this.get('controller').get('model').get('patient').id, {
          queryParams: {
            selectTab: "documents",
            rootFolderId: id
          }
        });
      },
      openLab: function openLab(id) {
        this.transitionTo('patients.edit', this.get('controller').get('model').get('patient').id, { queryParams: { selectTab: "lab" } });
      },
      editMemo: function editMemo(id) {
        var self = this;
        $.ajax({
          url: "/api/patientMemos/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('patientMemoPanel').send('openEditMemo', data.patientMemo, self.get('controller').get('model').id);
        }, function (error) {});
      },
      refreshPastDosings: function refreshPastDosings(filter, filterDiscontinued, filterApplied) {
        var self = this;

        $.ajax({
          url: "/api/patients/pastDosings/" + self.get('controller').get('model').get('patient').id + "?filterInfinity=" + filter + "&filterDiscontinued=" + filterDiscontinued + "&filterApplied=" + filterApplied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('medicalProblemsPanel').send('loadDosings', data.sloping, filter);
        }, function (error) {});
      },
      saveMedicalProblemsInfo: function saveMedicalProblemsInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/patients/updateMedicalInfos",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadPage();
        });
      },
      openDirectReportPreview: function openDirectReportPreview(tpId, reportId) {
        this.transitionTo('editor.edit', tpId, 'treatmentreport', reportId);
      },
      addNewMemo: function addNewMemo() {
        this.get('controller').get('patientMemoPanel').send('openNewMemo', this.get('controller').get('model').get('patient').id);
      },
      openDigitalHelperPanel: function openDigitalHelperPanel(id) {
        var self = this;
        self.get('controller').get('digitalHelperPanel').send('load', id);

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-digital-helper-qr-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      sendEtigaMail: function sendEtigaMail(patient, form) {
        $.ajax({
          url: "/api/patients/" + patient.id + "/sendEtigaCredentials",
          method: "POST",
          data: JSON.stringify(form),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (responseData) {
          jason.notifiction.notifiy('eltiga Tier QR Code', 'erfolgreich verschickt');
        }, function (responseData) {
          jason.notifiction.notifiy('eltiga Tier QR Code', 'erfolgreich verschickt');
        });
      },
      downloadEtigaQr: function downloadEtigaQr() {
        var controller = this.get('controller');
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: controller.get('model').get('patient').id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/patients/downloadEtigaQr/' + controller.get('model').get('patient').id + '?mandantId=' + API['default'].getMandant() + '&token=' + data.responseText);
        });
      },
      selectHealthTab: function selectHealthTab(selected) {
        var controller = this.get('controller');

        if (controller.get('healthTab') !== selected) {
          controller.set('healthTab', selected);
        } else {
          controller.set('healthTab', '');
        }
        var self = this;
        if (selected === 'vacc') {
          this.findPaged('patient-vaccination', {
            perPage: 10,
            paged: true,
            patientId: self.get('controller').get('model').get('patient').id
          }).then(function (entries) {
            self.get('controller').set('vaccinations', entries);
          });
        }
        if (selected === 'memos') {
          this.findPaged('patient-memo', {
            perPage: 10,
            paged: true,
            patientId: self.get('controller').get('model').get('patient').id
          }).then(function (entries) {
            self.get('controller').set('memos', entries);
          });
        }
      },
      selectPatientTab: function selectPatientTab(selected) {
        var controller = this.get('controller');
        if (controller.get('patientTab') !== selected) {
          controller.set('patientTab', selected);
        } else {
          controller.set('patientTab', '');
        }

        var self = this;

        controller.set('loadingLab', true);
        if (selected === 'lab') {
          $.ajax({
            url: "/api/patients/" + controller.get('model').get('patient').id + "/laboklinForms?max_results=5&hideImaging=true",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('loadingLab', false);
            controller.set('labItems', data.purchaseOrderLaboklinForm);
          });
        } else if (selected === 'documents') {
          if (controller.get('documentsPanel')) {
            controller.get('documentsPanel').send('loadForPatient', self, controller.get('model').get('patient').id, controller.get('model').get('patientOwner').id, controller.get('model').get('patientOwner').emails);
          }
        } else if (selected === 'xray') {
          if (controller.get('documentsPanelXray')) {
            controller.get('documentsPanelXray').send('loadForPatient', self, controller.get('model').get('patient').id, controller.get('model').get('patientOwner').id, controller.get('model').get('patientOwner').emails, 12);
          }
        }
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar div.scrollable').css('max-height', $(window).height() - 375);
          }, 100);
          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
        setTimeout(function () {
          $('.floating-header-wrapper').attr('style', 'width: ' + $('#stationVisitDetails').width() + 'px !important');
        }, 100);
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      selectCustomerTab: function selectCustomerTab(selected) {
        var controller = this.get('controller');

        if (controller.get('customerTab') !== selected) {
          controller.set('customerTab', selected);
        } else {
          controller.set('customerTab', '');
        }

        controller.set('loading', true);

        var self = this;

        if (selected === 'documents') {
          if (controller.get('customerDocumentsPanel')) {
            controller.get('customerDocumentsPanel').send('loadForCustomer', self, controller.get('model').get('patientOwner').id, controller.get('model').get('patientOwner').emails);
          }
        } else if (selected === 'appointments') {
          this.findPaged('patient-appointment', {
            filterCustomer: controller.get('model').get('patientOwner').id,
            sorting: 'p.date desc',
            maxResults: 5
          }).then(function (entries) {
            controller.set('loading', false);
            controller.set('customerAppointments', entries);
          });
        } else if (selected === 'patients') {
          $.ajax({
            url: "/api/patients/forcustomer/" + controller.get('model').get('patientOwner').id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('loading', false);
            self.get('controller').set('customerPatients', data.patient);
          });
        }
      },
      toOverview: function toOverview() {
        this.transitionTo('practicemgmt.admission');
      },
      back: function back() {
        window.history.back();
      },
      addArticle: function addArticle(offerId) {
        var self = this,
            timeout = 0;

        this.get('controller').set('inputDirty', false);
        this.get('controller').send('save', this.get('controller').get('model').id);

        setTimeout(function () {
          self.transitionTo('cash.add_article', 'treatmentStationVisits', self.get('controller').get('model').id, 0);
        }, timeout);
      },
      changeAbsoluteDiscount: function changeAbsoluteDiscount(entryId, discount, offerId, productName) {
        var self = this;
        var data = {
          amount: discount,
          absolute: true
        };

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeDiscount/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.reloadOffer();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      changeQuantity: function changeQuantity(quantity, entryId, offerId, productName) {
        var self = this,
            timeout = 0;

        if (this.get('controller').get('inputDirty')) {
          this.get('controller').send('save', this.get('controller').get('model').id);
          this.get('controller').set('inputDirty', false);
          timeout = 200;
        }

        setTimeout(function () {
          $.ajax({
            url: "/api/treatmentOffers/" + offerId + "/changeQuantity/" + entryId + "/" + quantity,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.reloadOffer();
          }, function (error) {});
        }, timeout);
      },
      changeGrossPrice: function changeGrossPrice(price, entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changePrice/" + entryId + "/" + price,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadOffer();
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      changeText: function changeText(articleText, entryId, offerId, productName) {
        var self = this;

        var data = {
          text: articleText
        };

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeArticleText/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadOffer();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteProduct: function deleteProduct(entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadOffer();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      changeApplied: function changeApplied(applied, entryId, offerId, productName) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeApplied/" + entryId + "/" + applied,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.reloadOffer();
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      },
      moveUp: function moveUp(offerId, entryId) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/moveUp/" + entryId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.reloadOffer();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      moveDown: function moveDown(offerId, entryId) {
        var self = this;

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/moveDown/" + entryId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.reloadOffer();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      changeDiscount: function changeDiscount(discount, absolute, entryId, offerId, productName) {
        var self = this;

        var data = {
          amount: discount,
          absolute: absolute
        };

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeDiscount/" + entryId,
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadOffer();
        }, function (error) {});

        return Ember['default'].RSVP.resolve(deferred);
      }
    }
  });

});
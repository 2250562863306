define('jason-frontend/helpers/get-size', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getSize = getSize;

  function getSize(params) {
    var test = params[0];
    console.log(test);
    if (test) {
      console.log(test.length);
      return test.length;
    }
    return 0;
  }

  exports['default'] = Ember['default'].Helper.helper(getSize);

});
define('jason-frontend/templates/components/document-upload-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 16
            },
            "end": {
              "line": 14,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-multi");
          var el2 = dom.createTextNode("Mehrere Dokumente");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[13,65],[13,76]]]],"multi"],[],["loc",[null,[13,61],[13,85]]]],"is-active",""],[],["loc",[null,[13,40],[13,102]]]]]]],
          ["element","action",["selectMode","multi"],[],["loc",[null,[13,122],[13,153]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 16
              },
              "end": {
                "line": 16,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-external");
            var el2 = dom.createTextNode("Externes Dokument");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[15,67],[15,78]]]],"external"],[],["loc",[null,[15,63],[15,90]]]],"is-active",""],[],["loc",[null,[15,42],[15,107]]]]]]],
            ["element","action",["selectMode","external"],[],["loc",[null,[15,130],[15,164]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 16
            },
            "end": {
              "line": 16,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[14,30],[14,34]]]],"therapy"],[],["loc",[null,[14,26],[14,45]]]]],[],0,null,["loc",[null,[14,16],[16,16]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 20
            },
            "end": {
              "line": 66,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createAttrMorph(element3, 'style');
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["mr5 mb5 info-badge cursor with-delete-button ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[65,102],[65,114]]]],"selected",""],[],["loc",[null,[65,81],[65,130]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[65,159],[65,168]]]]]]],
          ["element","action",["selectTag",["get","tag",["loc",[null,[65,193],[65,196]]]]],[],["loc",[null,[65,172],[65,198]]]],
          ["content","tag.name",["loc",[null,[65,199],[65,211]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 77
            },
            "end": {
              "line": 71,
              "column": 161
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","crumb-trail folder");
          var el2 = dom.createElement("a");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          return morphs;
        },
        statements: [
          ["content","bread.name",["loc",[null,[71,138],[71,152]]]]
        ],
        locals: ["bread"],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 16
            },
            "end": {
              "line": 76,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[75,18],[75,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 16
            },
            "end": {
              "line": 78,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework primary context");
          dom.setAttribute(el1,"id","triggerFileUploadDocs");
          var el2 = dom.createTextNode(" Datei auswählen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 12
            },
            "end": {
              "line": 82,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-upload-widget",[],["uploadDone","uploadDone","uploadInProgress","uploadInProgress","uploadError","uploadError"],["loc",[null,[81,14],[81,126]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 99,
              "column": 18
            },
            "end": {
              "line": 101,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createAttrMorph(element2, 'style');
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(element2,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["mr5 mb5 info-badge cursor with-delete-button ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[100,100],[100,112]]]],"selected",""],[],["loc",[null,[100,79],[100,128]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[100,157],[100,166]]]]]]],
          ["element","action",["selectTag",["get","tag",["loc",[null,[100,191],[100,194]]]]],[],["loc",[null,[100,170],[100,196]]]],
          ["content","tag.name",["loc",[null,[100,197],[100,209]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 75
            },
            "end": {
              "line": 105,
              "column": 159
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","crumb-trail folder");
          var el2 = dom.createElement("a");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          return morphs;
        },
        statements: [
          ["content","bread.name",["loc",[null,[105,136],[105,150]]]]
        ],
        locals: ["bread"],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 18
            },
            "end": {
              "line": 110,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[109,20],[109,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 18
            },
            "end": {
              "line": 115,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","dz-message needsclick");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","dz-button");
          var el3 = dom.createTextNode("Datei hier hinziehen oder auswählen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 22
              },
              "end": {
                "line": 122,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("Datei: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element0,1,1);
            morphs[1] = dom.createAttrMorph(element1, 'class');
            morphs[2] = dom.createMorphAt(element1,0,0);
            return morphs;
          },
          statements: [
            ["content","file.name",["loc",[null,[121,48],[121,61]]]],
            ["attribute","class",["concat",["vertical-middle info-badge info-badge-very-small ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","file.status",["loc",[null,[121,150],[121,161]]]],"success"],[],["loc",[null,[121,146],[121,172]]]],"label-success","label-error"],[],["loc",[null,[121,125],[121,204]]]]]]],
            ["inline","css-bool-evaluator",[["subexpr","eq",[["get","file.status",["loc",[null,[121,231],[121,242]]]],"success"],[],["loc",[null,[121,227],[121,253]]]],"Erfolgreich","Fehler"],[],["loc",[null,[121,206],[121,278]]]]
          ],
          locals: ["file"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 18
            },
            "end": {
              "line": 124,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","title mt20");
          var el2 = dom.createTextNode("Status");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","file-status");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","uploadedFiles",["loc",[null,[120,30],[120,43]]]]],[],0,null,["loc",[null,[120,22],[122,31]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 200,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/document-upload-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-document-upload-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-large mfp-with-anim mfp-hide uploadContainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Dokument hochladen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body p-30 js-tabs tabs");
        var el4 = dom.createTextNode("\n\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","rework-content tabs-rework-wrapper grid mb-20 mt-0");
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"href","#tab-single");
        var el6 = dom.createTextNode("Einzelnes Dokument");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs__body");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-single");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("form");
        dom.setAttribute(el6,"id","documentEditPanel");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","grid gutter-fix");
        var el8 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24 gutter");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","form-field-rework");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class"," d-flex align-items-center");
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","name");
        dom.setAttribute(el11,"class","placeholder");
        var el12 = dom.createTextNode("Name");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24 gutter");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","form-field-rework");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","textarea-wrapper");
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","placeholder");
        var el12 = dom.createTextNode("Beschreibung");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24 gutter");
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","d-flex align-items-center");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","form-field-rework");
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","placeholder");
        var el12 = dom.createTextNode("Datum");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("img");
        dom.setAttribute(el11,"src","assets/images/icons/rework/calendar-light-dark.svg");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","form-field-rework ms-20");
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"class","placeholder");
        var el12 = dom.createTextNode("Uhrzeit");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("img");
        dom.setAttribute(el11,"src","assets/images/icons/rework/time-dark.svg");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Tags");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Speicherort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("img");
        dom.setAttribute(el8,"src","assets/images/icons/folder.svg");
        dom.setAttribute(el8,"class","mr10");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","box");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","fw-bold");
        var el8 = dom.createTextNode("Hinweise");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Speicherort muss vorab ausgewählt werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Die max. Dateigröße beträgt 10 MByte!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-multi");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","name");
        dom.setAttribute(el7,"class","field-label");
        var el8 = dom.createTextNode("Tags");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24@desk l-24/24@phone gutter mb30");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","name");
        dom.setAttribute(el7,"class","field-label");
        var el8 = dom.createTextNode("Speicherort");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/folder.svg");
        dom.setAttribute(el7,"class","mr10");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("form");
        dom.setAttribute(el6,"class","dropzone needsclick dz-clickable ");
        dom.setAttribute(el6,"id","uploadFormArgl");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","box");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","fw-bold");
        var el8 = dom.createTextNode("Hinweise");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Der Speicherort muss vorab ausgewählt werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Es können mehrere Dateien gleichzeitig hochgeladen werden!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        dom.setAttribute(el8,"class","mb0");
        var el9 = dom.createTextNode("Die max. Dateigröße je Datei beträgt 10 MByte!");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","documentUploadExternalForm");
        dom.setAttribute(el5,"class","form-grid new-style");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-external");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24@desk l-24/24@phone gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","name");
        dom.setAttribute(el8,"class","field-label");
        var el9 = dom.createTextNode("Externe Url");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class"," text-right");
        dom.setAttribute(el7,"style","margin-top: 10px;margin-bottom: -40px;margin-right: -20px");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("button");
        dom.setAttribute(el8,"type","submit");
        dom.setAttribute(el8,"class","icon-button icon-button--success");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n\n    $(\"#documentUploadExternalForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'name': {\n          required: true\n        },\n        'externalUrl': {\n          required: true\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $('body').delegate('#triggerFileUploadDocs', 'click', function(event){\n      if(!$(this).hasClass('init')) {\n        $('#modal-document-upload-panel input[type=file]').click();\n        setTimeout(function() {\n          $('#triggerFileUploadDocs').removeClass('init');\n        }, 500);\n      }\n      $(this).addClass('init');\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1, 3]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element7, [3]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [5, 1]);
        var element15 = dom.childAt(element10, [3]);
        var element16 = dom.childAt(element10, [5]);
        var element17 = dom.childAt(element16, [1]);
        var morphs = new Array(29);
        morphs[0] = dom.createAttrMorph(element6, 'data-tags');
        morphs[1] = dom.createAttrMorph(element6, 'data-folder-id');
        morphs[2] = dom.createAttrMorph(element6, 'data-ctx');
        morphs[3] = dom.createAttrMorph(element6, 'data-ctx-id');
        morphs[4] = dom.createAttrMorph(element6, 'data-ctx-id2');
        morphs[5] = dom.createAttrMorph(element6, 'data-user-id');
        morphs[6] = dom.createAttrMorph(element9, 'class');
        morphs[7] = dom.createElementMorph(element9);
        morphs[8] = dom.createMorphAt(element8,3,3);
        morphs[9] = dom.createAttrMorph(element11, 'class');
        morphs[10] = dom.createElementMorph(element12);
        morphs[11] = dom.createMorphAt(dom.childAt(element13, [1, 1, 1]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element13, [3, 1, 1]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        morphs[15] = dom.createMorphAt(dom.childAt(element12, [3]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element12, [5]),5,5);
        morphs[17] = dom.createMorphAt(element12,7,7);
        morphs[18] = dom.createMorphAt(element12,9,9);
        morphs[19] = dom.createAttrMorph(element15, 'class');
        morphs[20] = dom.createMorphAt(dom.childAt(element15, [1]),3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element15, [3]),5,5);
        morphs[22] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
        morphs[23] = dom.createMorphAt(element15,7,7);
        morphs[24] = dom.createElementMorph(element16);
        morphs[25] = dom.createAttrMorph(element17, 'class');
        morphs[26] = dom.createMorphAt(dom.childAt(element17, [1]),3,3);
        morphs[27] = dom.createMorphAt(dom.childAt(element17, [3]),3,3);
        morphs[28] = dom.createMorphAt(dom.childAt(element17, [5, 1]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","data-tags",["concat",[["get","tagsFlat",["loc",[null,[1,137],[1,145]]]]]]],
        ["attribute","data-folder-id",["concat",[["get","folderId",["loc",[null,[1,167],[1,175]]]]]]],
        ["attribute","data-ctx",["concat",[["get","ctx",["loc",[null,[1,191],[1,194]]]]]]],
        ["attribute","data-ctx-id",["concat",[["get","ctxId",["loc",[null,[1,213],[1,218]]]]]]],
        ["attribute","data-ctx-id2",["concat",[["get","ctxId2",["loc",[null,[1,238],[1,244]]]]]]],
        ["attribute","data-user-id",["concat",[["get","userId",["loc",[null,[1,264],[1,270]]]]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[11,65],[11,76]]]],"single"],[],["loc",[null,[11,61],[11,86]]]],"is-active",""],[],["loc",[null,[11,40],[11,103]]]]]]],
        ["element","action",["selectMode","single"],[],["loc",[null,[11,124],[11,156]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[12,26],[12,30]]]],"default"],[],["loc",[null,[12,22],[12,41]]]]],[],0,1,["loc",[null,[12,16],[16,23]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[20,77],[20,88]]]],"single"],[],["loc",[null,[20,73],[20,98]]]],"is-active",""],[],["loc",[null,[20,52],[20,115]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[21,54],[21,83]]]],
        ["inline","input",[],["id","uploadName","placeholder","","autocomplete","off","value",["subexpr","@mut",[["get","name",["loc",[null,[28,90],[28,94]]]]],[],[]],"name","name"],["loc",[null,[28,26],[28,108]]]],
        ["inline","textarea",[],["id","uploadDescription","rows","3","value",["subexpr","@mut",[["get","description",["loc",[null,[37,75],[37,86]]]]],[],[]],"class","","name","description","placeholder",""],["loc",[null,[37,26],[38,53]]]],
        ["inline","input",[],["id","uploadDate","value",["subexpr","@mut",[["get","created",["loc",[null,[47,57],[47,64]]]]],[],[]],"class","js-date-picker","name","created"],["loc",[null,[47,26],[48,53]]]],
        ["inline","input",[],["id","uploadTime","value",["subexpr","@mut",[["get","createdTime",["loc",[null,[53,57],[53,68]]]]],[],[]],"class","time","name","createdTime"],["loc",[null,[53,26],[54,57]]]],
        ["block","each",[["get","tags",["loc",[null,[64,28],[64,32]]]]],["key","@index"],2,null,["loc",[null,[64,20],[66,29]]]],
        ["block","each",[["get","breads",["loc",[null,[71,85],[71,91]]]]],[],3,null,["loc",[null,[71,77],[71,170]]]],
        ["block","if",[["get","uploading",["loc",[null,[74,22],[74,31]]]]],[],4,5,["loc",[null,[74,16],[78,23]]]],
        ["block","if",[["get","active",["loc",[null,[80,18],[80,24]]]]],[],6,null,["loc",[null,[80,12],[82,19]]]],
        ["attribute","class",["concat",["tabs__content  pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[95,78],[95,89]]]],"multi"],[],["loc",[null,[95,74],[95,98]]]],"is-active",""],[],["loc",[null,[95,53],[95,115]]]]]]],
        ["block","each",[["get","tags",["loc",[null,[99,26],[99,30]]]]],["key","@index"],7,null,["loc",[null,[99,18],[101,27]]]],
        ["block","each",[["get","breads",["loc",[null,[105,83],[105,89]]]]],[],8,null,["loc",[null,[105,75],[105,168]]]],
        ["block","if",[["get","uploading",["loc",[null,[108,24],[108,33]]]]],[],9,10,["loc",[null,[108,18],[115,25]]]],
        ["block","if",[["get","statusAvailable",["loc",[null,[117,24],[117,39]]]]],[],11,null,["loc",[null,[117,18],[124,25]]]],
        ["element","action",["storeExternal"],["on","submit"],["loc",[null,[135,80],[135,118]]]],
        ["attribute","class",["concat",["tabs__content form-grid new-style ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[136,86],[136,97]]]],"external"],[],["loc",[null,[136,82],[136,109]]]],"is-active",""],[],["loc",[null,[136,61],[136,126]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","name",["loc",[null,[139,36],[139,40]]]]],[],[]],"autofocus",true,"class","gui-input new-style","title","Bitte geben Sie einen Namen an","name","name"],["loc",[null,[139,22],[139,136]]]],
        ["inline","input",[],["placeholder","https://","value",["subexpr","@mut",[["get","externalUrl",["loc",[null,[143,59],[143,70]]]]],[],[]],"autofocus",true,"class","gui-input new-style","name","externalUrl"],["loc",[null,[143,22],[143,134]]]],
        ["inline","button-save",[],["color","#ffffff","size","40","content",""],["loc",[null,[148,24],[148,76]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
    };
  }()));

});
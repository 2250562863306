define('jason-frontend/templates/components/cashback-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 16
            },
            "end": {
              "line": 17,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 16
            },
            "end": {
              "line": 31,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/cashback-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","fee-year");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Lieferant");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[21,30],[21,39]]]]],[],[]],"id","cashback-supplier","value",["subexpr","@mut",[["get","model.supplier",["loc",[null,[23,28],[23,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","placeholder","Bitte wählen","allowClear",true],["loc",[null,[20,22],[29,24]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/cashback-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-form-cashback-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Cashback Einstellungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","cashbackForm");
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p25");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","fee-value");
        dom.setAttribute(el6,"class","field-label title required");
        var el7 = dom.createTextNode("Cashback in %");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","bar clearfix mb10");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--small");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--small");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'data-mandant-id');
        morphs[1] = dom.createAttrMorph(element0, 'data-cashback-id');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
        morphs[3] = dom.createMorphAt(element1,3,3);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createMorphAt(element3,1,1);
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createMorphAt(element4,1,1);
        return morphs;
      },
      statements: [
        ["attribute","data-mandant-id",["concat",[["get","mandant.id",["loc",[null,[9,51],[9,61]]]]]]],
        ["attribute","data-cashback-id",["concat",[["get","model.id",["loc",[null,[9,85],[9,93]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.cashback",["loc",[null,[14,32],[14,46]]]]],[],[]],"class","gui-input","name","cashback","autocomplete","off"],["loc",[null,[14,18],[14,101]]]],
        ["block","if",[["subexpr","gt",[["get","model.id",["loc",[null,[16,26],[16,34]]]],0],[],["loc",[null,[16,22],[16,37]]]]],[],0,1,["loc",[null,[16,16],[31,23]]]],
        ["element","action",["confirm",["get","data",["loc",[null,[35,38],[35,42]]]]],[],["loc",[null,[35,19],[35,44]]]],
        ["inline","button-save",[],["color","#ffffff","size","40"],["loc",[null,[36,18],[36,59]]]],
        ["element","action",["cancel"],[],["loc",[null,[38,19],[38,38]]]],
        ["inline","button-prev",[],["color","#ffffff","size","32"],["loc",[null,[39,18],[39,59]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
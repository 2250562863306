define('jason-frontend/routes/invoices/banktransfer', ['exports', 'ember', 'jason-frontend/api', 'moment', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, moment, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    queryParams: {
      filterCode: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.filterPaymentStatus = 'partpaid,notpaid';
      params.filterStatus = 'to_be_verified';
      params.legacy = 'true';
      return this.findPaged('invoice', params);
    },
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);

      if (!API['default'].hasPermission('c_banktransfer')) {
        this.transitionTo('home');
      }

      this.store.find('bankingAccount').then(function (data) {
        controller.set('bankingAccountList', data);
        if (data.content[0]) {
          controller.set('selectedBankingAccount', data.content[0].id);
        }
      });

      controller.set('deposit', 0);
      controller.set('given', 0);
      controller.set('depositSum', 0);
      controller.set('toPay', 0);
      controller.set('step', 'form');
      controller.set('selectedInvoiceId', null);
      controller.set('submitButtonEnabled', true);
      controller.set('showBankTransferPanel', false);
      controller.set('selectedInvoice', null);
      controller.set('transaction', null);
      controller.set('notice', null);
      controller.set('amount', null);
      controller.set('recipientReferenceNumber', null);
      controller.set('maxPaymentAmount', null);
      controller.set('documentDate', null);
      controller.set('referenceNumber', null);
      controller.set('referenceText', null);
      controller.set('recipient', null);
      controller.set('referer', null);
      controller.set('purpose', null);
      controller.set('notice', null);
      controller.set('reason', null);
      controller.set('recipientText', null);
      controller.set('recipientNumber', null);
      controller.set('withdrawalName', null);
      controller.set('withdrawalIban', null);
      controller.set('withdrawalBank', null);
      controller.set('withdrawalDate', null);
      controller.set('selectedPaymentType', 'cash');

      var invoiceId = params.params['invoices.banktransfer'].id;

      if (invoiceId > 0) {
        controller.set('styleStepOne', 'hidden');
        controller.set('selectedInvoiceId', invoiceId);
        controller.set('invoice', this.store.find('invoice', invoiceId));
        controller.set('styleStepThree', '');
      } else {
        controller.set('invoicesList', this.store.find('invoice', {
          filterPaymentStatus: "partpaid,notpaid",
          filterStatus: "to_be_verified",
          legacy: "true"
        }));
      }

      var self = this;

      self.store.fetch('invoice', invoiceId).then(function (data) {
        controller.set('toPay', data.get('total') - data.get('payedTotal'));
        var given = data.get('total') - data.get('payedTotal');
        controller.set('given', String(Math.round(given * 100) / 100).replace('.', ','));

        if (data.get('customer') && data.get('customer').get('id') > 0) {
          controller.set('customerDeposit', self.store.fetch('customer', data.get('customer').get('id')));

          $.ajax({
            url: "/api/customerDeposits/" + data.get('customer').get('id'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('depositSum', data.meta.depositSum);
            // controller.set('deposit', data.meta.depositSum);
          });
          $.ajax({
            url: "/api/customers/" + data.get('customer').get('id'),
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('withdrawalName', data.customer.shortName);
          });
        }
      });
    },
    actions: {
      selectInvoice: function selectInvoice(invoice) {
        var controller = this.get('controller');
        controller.set('styleStepOne', 'hidden');
        controller.set('selectedInvoiceId', invoice.id);
        controller.set('invoice', this.store.find('invoice', invoice.id));
        controller.set('styleStepThree', '');
      },
      selectAmount: function selectAmount() {
        var controller = this.get('controller');
        this.get('controller').set('styleStepOne', 'hidden');
        this.get('controller').set('styleStepTwo', 'hidden');
        this.get('controller').set('styleStepThree', '');
        this.get('controller').set('styleStepFive', 'hidden');
      },
      toSelectType: function toSelectType() {
        var controller = this.get('controller');
        controller.set('styleStepOne', '');
        controller.set('styleStepTwo', 'hidden');
        controller.set('styleStepThree', 'hidden');
        controller.set('styleStepFive', 'hidden');
      },
      toSelectConfirmation: function toSelectConfirmation() {
        var controller = this.get('controller'),
            selectedType = 'banktransfer',
            withdrawalName = controller.get('withdrawalName'),
            withdrawalIban = controller.get('withdrawalIban'),
            withdrawalBank = controller.get('withdrawalBank'),
            referenceNumber = controller.get('referenceNumber'),
            withdrawalDate = controller.get('withdrawalDate'),
            documentDate = controller.get('documentDate'),
            recipientReferenceNumber = controller.get('recipientReferenceNumber'),
            reason = controller.get('reason'),
            selectedBankingAccount = controller.get('selectedBankingAccount'),
            payment = new String($('#given1').val()).replace(',', '.');

        var deposit = parseFloat(new String(controller.get('deposit')).replace(',', '.'));

        if ((!payment || payment < 0.01) && (!deposit || deposit < 0.01)) {
          jason.notifiction.error('Betrag fehlt', 'Bitte geben Sie einen Mindestbetrag von € 0,01 ein!');
          return false;
        }

        if (!withdrawalName) {
          jason.notifiction.error('Name Auftraggeber', 'Bitte angeben');
          return false;
        }
        if (!selectedBankingAccount) {
          jason.notifiction.error('Empfängerkonto', 'Bitte auswählen');
          return false;
        }

        if (withdrawalDate === null) {
          withdrawalDate = moment['default']().format('DD. MM. YYYY');
        }
        if (documentDate === null) {
          documentDate = moment['default']().format('DD. MM. YYYY');
        }

        if (isNaN(deposit)) {
          deposit = 0;
        }

        var payload = {
          invoiceId: controller.get('selectedInvoiceId'),
          type: selectedType,
          amount: payment,
          deposit: deposit,
          withdrawalName: withdrawalName,
          withdrawalIban: withdrawalIban,
          withdrawalBank: withdrawalBank,
          referenceNumber: referenceNumber,
          recipientReferenceNumber: recipientReferenceNumber,
          withdrawalDate: moment['default'](withdrawalDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          documentDate: moment['default'](documentDate, 'DD. MM. YYYY').format('YYYY-MM-DD'),
          reason: reason,
          bankingAccount: selectedBankingAccount
        };

        var self = this;

        if (deposit < 0.001 && payment < 0.001) {
          jason.notifiction.error("Achtung", "Der Betrag muss größer als 0,01 EUR sein");
          return false;
        }

        controller.set('submitButtonEnabled', false);

        $.ajax({
          url: "/api/cashdesk/banktransfer",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(payload),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {

          controller.set('currentTransactionId', data);
          controller.set('transactionGroup', self.store.fetch('transaction-group', controller.get('currentTransactionId')));

          controller.set('step', 'confirm');

          jason.notifiction.notifiy('Überweisung erfolgreich erfasst', '');
          controller.set('submitButtonEnabled', true);
          return data;
        }, function () {
          jason.notifiction.error('Unbekannter Fehler', '');
          controller.set('submitButtonEnabled', true);
        });
      },
      reset: function reset() {}
    }
  });

});
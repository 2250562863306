define('jason-frontend/routes/cash/add-article', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(params) {},
    reloadTreatment: function reloadTreatment(id) {
      var _this = this;

      if (id) {
        (function () {
          var controller = _this.get('controller');
          $.ajax({
            url: "/api/treatmentOffers/forTreatment/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            controller.set('treatment', data.treatmentOffer);
          });
        })();
      }
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);
      var invoiceId = params.params['cash.add_article'].id,
          patientId = params.params['cash.add_article'].patientId,
          referer = params.params['cash.add_article'].type,
          stocktakingId = params.queryParams.stocktakingId,
          categoryRedirect = params.queryParams.categoryRedirect;

      controller.set('sidebarVisible', false);
      controller.set('filterName', null);

      controller.set('stocktakingId', stocktakingId);

      controller.set('selectedType', 'service'); //
      controller.set('placeholder', 'Leistung suchen');

      controller.set('referer', referer);

      controller.set('refererType', referer);
      controller.set('sidebarActive', false);

      if (referer === 'treatment') {
        controller.set('type', 'treatmentFutureActions');
      }
      if (referer === 'treatmentOffers') {
        controller.set('type', 'treatmentOffers');
        controller.set('users', this.store.find('user', { per_page: 100 }));
        controller.set('rooms', this.store.find('treatment-room'));
        controller.set('stations', this.store.find('treatment-station'));
        controller.set('resources', this.store.find('appointment-resource'));

        controller.set('sidebarVisible', true);
        controller.set('sidebarActive', true);

        this.reloadTreatment(invoiceId);
      }
      if (referer === 'treatmentStationVisits') {
        controller.set('type', 'treatmentStationVisits');
        controller.set('users', this.store.find('user', { per_page: 100 }));
        controller.set('rooms', this.store.find('treatment-room'));
        controller.set('stations', this.store.find('treatment-station'));
        controller.set('resources', this.store.find('appointment-resource'));

        controller.set('sidebarVisible', false);
        controller.set('sidebarActive', false);

        // this.reloadTreatment(invoiceId);
      }
      if (referer === 'invoices') {
        controller.set('type', 'invoices');
      }
      if (referer === 'inventoryRequests') {
        controller.set('type', 'inventoryRequests');
        controller.set('selectedType', 'product');
        controller.set('placeholder', 'Produkt suchen');
      }
      if (referer === 'compositeAddProduct') {
        controller.set('type', 'compositeProduct');
        controller.set('selectedType', 'product');
        controller.set('placeholder', 'Produkt suchen');
      }
      if (referer === 'templateService') {
        controller.set('type', 'templates');
        controller.set('selectedType', 'text');
        controller.set('placeholder', 'Vorlage suchen');
      }
      if (referer === 'templateProduct') {
        controller.set('type', 'templates');
        controller.set('selectedType', 'text');
        controller.set('placeholder', 'Vorlage suchen');
      }
      if (referer === 'templateComposite') {
        controller.set('type', 'templates');
        controller.set('selectedType', 'text');
        controller.set('placeholder', 'Vorlage suchen');
      }
      if (referer === 'templateTreatment') {
        controller.set('type', 'templateInstances');
        controller.set('selectedType', 'text');
        controller.set('placeholder', 'Vorlage suchen');
      }
      if (referer === 'deliveryNote') {
        controller.set('type', 'deliveryNote');
        controller.set('selectedType', 'product');
        controller.set('placeholder', 'Produkt suchen');
      }
      if (referer === 'compositeAddService') {
        controller.set('type', 'compositeService');
        controller.set('selectedType', 'service');
        controller.set('placeholder', 'Leistung suchen');
      }
      if (referer === 'compositeAddTemplate') {
        controller.set('type', 'compositeTemplate');
        controller.set('selectedType', 'text');
        controller.set('placeholder', 'Vorlage suchen');
      }
      if (referer === 'inventoryCorrections') {
        controller.set('type', 'inventoryRequests');
        controller.set('selectedType', 'product');
        controller.set('subType', 'corrections');
        controller.set('placeholder', 'Produkt suchen');
      }
      if (referer === 'compositeproducts' || referer === 'services' || referer === 'products') {
        controller.set('type', 'futureActionBlueprints');
      }

      controller.set('typeId', invoiceId);

      this.store.find('productCategory').then(function (items) {
        controller.set('productCategories', items.sortBy('fullName'));
      });

      this.store.find('productDistributor', { service: true, onlyProvided: true }).then(function (items) {
        controller.set('distributors', items.sortBy('name'));
      });

      this.store.find('article-tag', { type: 'service' }).then(function (items) {
        items.forEach(function (item) {
          Ember['default'].set(item, 'selected', false);
        });
        controller.set('articleTags', items);
      });

      controller.set('suppliers', this.store.find('product-supplier'));
      controller.set("selectedPatient", patientId);
      controller.set("linkToEditEnabled", API['default'].hasPermission('crud_products'));

      $.ajax({
        url: "/api/productCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('categories', data.productCategory);
        controller.set('selectedCategoryFilters', null);
      });
      $.ajax({
        url: "/api/serviceCategories?suggest=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('serviceCategories', data.serviceCategory);
        controller.set('selectedCategoryFilters', null);
      });

      this.applyFilter(categoryRedirect);
    },
    applyFilter: function applyFilter(categoryRedirectOverride) {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          selectedCategoryFilters = controller.get('selectedCategoryFilters'),
          filterSupplier = controller.get('filterSupplier'),
          filterManufacturer = controller.get('filterManufacturer'),
          filterDistributor = controller.get('filterDistributor'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          manufacturerId = null,
          filterFavorites = controller.get('filterFavs'),
          filterTiny = controller.get('filterTiny'),
          filterHorse = controller.get('filterHorse'),
          filterUse = controller.get('filterUse'),
          filterId = null;

      controller.set("showLoading", true);

      if (filterManufacturer) {
        manufacturerId = filterManufacturer.id;
      }

      if (filterSupplier === 'all') {
        filterSupplier = null;
      }

      if (filterDistributor) {
        filterDistributor = filterDistributor.id;
      }

      filterId = selectedCategoryFilters;

      if (categoryRedirectOverride) {
        filterId = categoryRedirectOverride;
      }

      if (filterId === 'undefined') {
        filterId = null;
        controller.set('selectedCategoryFilters', null);
      }

      var type = controller.get('selectedType');

      var tags = new Array();
      if (controller.get('articleTags')) {
        $.each(controller.get('articleTags').content, function () {
          if ($(this)[0].record.get('selected')) {
            tags.push($(this)[0].record.id);
          }
        });
      }

      if (type === 'product') {
        this.findPaged('product', {
          filterName: filterName,
          selectedCategoryFilters: filterId,
          filterSupplier: filterSupplier,
          filterManufacturer: manufacturerId,
          filterFavorites: filterFavorites,
          filterMandant: "all",
          filterTags: tags.toString(),
          sortDir: sortDir,
          sortBy: sortBy,
          perPage: 10
        }).then(function (entries) {
          controller.set('model', entries);
          try {
            entries.forEach(function (e) {
              Ember['default'].set(e, 'quantitySelection', 1);
              e.get('prices').forEach(function (price) {
                Ember['default'].set(price, 'quantitySelection', 1);
              });
            });
          } catch (e) {}
          controller.set("showLoading", false);
        });
      } else if (type === 'service') {
        this.findPaged('service', {
          filterName: filterName,
          selectedCategoryFilters: filterId,
          filterDistributor: filterDistributor,
          filterManufacturer: manufacturerId,
          filterFavorites: filterFavorites,
          filterHorse: filterHorse,
          filterTiny: filterTiny,
          filterUse: filterUse,
          filterTags: tags.toString(),
          filterMandant: "all",
          sortDir: sortDir,
          sortBy: sortBy,
          perPage: 10
        }).then(function (entries) {
          controller.set('model', entries);
          try {
            entries.forEach(function (e) {
              Ember['default'].set(e, 'quantitySelection', 1);
            });
          } catch (e) {}
          controller.set("showLoading", false);
        });
      } else if (type === 'composite') {
        this.findPaged('compositeArticle', {
          filterName: filterName,
          selectedCategoryFilters: filterId,
          filterManufacturer: manufacturerId,
          filterFavorites: filterFavorites,
          filterMandant: "all",
          sortDir: sortDir,
          sortBy: sortBy,
          perPage: 10
        }).then(function (entries) {
          controller.set('model', entries);
          controller.set("showLoading", false);
        });
      } else if (type === 'text') {
        this.findPaged('template', {
          filterName: filterName,
          sortDir: sortDir,
          sortBy: sortBy,
          perPage: 50
        }).then(function (entries) {
          controller.set('model', entries);
          controller.set("showLoading", false);
        });
      }
    },
    actions: {
      deleteMinicartEntry: function deleteMinicartEntry(entryId, offerId, treatmentId) {
        var self = this,
            controller = this.get('controller');

        var deferred = $.ajax({
          url: "/api/treatmentOffers/" + offerId + "/changeQuantity/" + entryId + "/0",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.reloadTreatment(treatmentId);
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      toItem: function toItem(id, type) {
        if (type === 'service') {
          this.transitionTo('services.edit', id);
        } else if (type === 'product') {
          this.transitionTo('products.edit', id);
        }
      },
      toggleSidebar: function toggleSidebar() {
        var controller = this.get('controller');
        if (controller.get('sidebarActive') === true) {
          controller.set('sidebarActive', false);
        } else {
          controller.set('sidebarActive', true);
          setTimeout(function () {
            $('aside.side-bar .scrollable').css('max-height', $(window).height() - 100);
            $('aside.side-bar').css('min-height', $(window).height() - 100);
          }, 100);

          if ($(window).width() <= 767) {
            $("html, body").animate({ scrollTop: 0 }, 200);
          }
        }
      },
      selectTag: function selectTag(tag) {
        var currentValue = tag.get('selected');
        Ember['default'].set(tag, 'selected', !currentValue);
        this.applyFilter();
      },
      reloadPatients: function reloadPatients(customerId) {
        var self = this;
        $.ajax({
          url: "/api/patients/forcustomer/" + customerId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('controller').get('addTaskPanel').send('loadPatients', data.patient);
        });
      },
      setType: function setType(type) {
        this.get('controller').set('selectedType', type);

        if (this.get('controller').get('articleTags')) {
          $.each(this.get('controller').get('articleTags').content, function () {
            $(this)[0].record.set('selected', false);
          });
        }

        if (type === 'product') {
          this.get('controller').set('placeholder', 'Produkt suchen');
          this.get('controller').set("filterDistributor", null);
          this.get('controller').set("filterSupplier", 'all');
          this.get('controller').set("filterManufacturer", null);
        } else if (type === 'service') {
          this.get('controller').set('placeholder', 'Leistung suchen');
          this.get('controller').set("filterDistributor", null);
          this.get('controller').set("filterSupplier", 'all');
          this.get('controller').set("filterManufacturer", null);
        } else if (type === 'text') {
          this.get('controller').set('placeholder', 'Vorlage suchen');
          this.get('controller').set("filterDistributor", null);
          this.get('controller').set("filterSupplier", 'all');
          this.get('controller').set("filterManufacturer", null);
          this.get('controller').set("showFilters", false);
        } else if (type === 'composite') {
          this.get('controller').set('placeholder', 'Paket suchen');
          this.get('controller').set("filterDistributor", null);
          this.get('controller').set("filterSupplier", 'all');
          this.get('controller').set("filterManufacturer", null);
          this.get('controller').set("showFilters", false);
        }

        try {
          if (/Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent)) {} else {
            $('input.input--search').focus();
          }
        } catch (err) {}

        this.applyFilter();
      },
      toggleProductFavorite: function toggleProductFavorite(id, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/products/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/products/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      togglePriceFavorite: function togglePriceFavorite(id, priceId, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/products/" + id + "/price/" + priceId + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/products/" + id + "/price/" + priceId + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      toggleServiceFavorite: function toggleServiceFavorite(id, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/services/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/services/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      toggleCompositeFavorite: function toggleCompositeFavorite(id, add) {
        var controller = this.get('controller');

        if (add) {
          $.ajax({
            url: "/api/compositeArticles/" + id + "/addToFavs",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        } else {
          $.ajax({
            url: "/api/compositeArticles/" + id + "/removeFromFavs",
            method: "DELETE",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (id) {}, function (error) {});
        }
      },
      filterFavs: function filterFavs() {
        if (this.controller.get('filterFavs')) {
          this.controller.set('filterFavs', false);
        } else {
          this.controller.set('filterFavs', true);
        }
        this.applyFilter();
      },
      toggleFavorites: function toggleFavorites(enabled) {
        var controller = this.get('controller');
        controller.set('filterFavorites', enabled);
        this.applyFilter();
      },
      queryManufacturer: function queryManufacturer(query, deferred) {
        this.store.find('productManufacturer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
        this.applyFilter();
      },
      addProduct: function addProduct(quantity, productId, invoiceId, priceId, productName) {
        var self = this,
            patientId = this.get('controller').get('selectedPatient'),
            controller = this.get('controller');

        if (isNaN(patientId) && controller.get('type') !== 'deliveryNote') {
          patientId = 0;
        }

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        if (controller.get('stocktakingId')) {
          patientId = controller.get('stocktakingId');
        }

        if (controller.get('type') === 'deliveryNote') {
          var deferred = jQuery.ajax({
            url: "/api/deliveryNotes/assignArticle/" + invoiceId + "/" + patientId + "/" + productId + "/" + priceId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Produkt zugewiesen', productName);
          }, function (error) {
            jason.notifiction.notifiy('Produkt zugewiesen', productName);
            self.transitionTo('inventory.delivery-note-details', invoiceId);
          });
          return Ember['default'].RSVP.resolve(deferred);
        } else if (controller.get('type') === 'compositeProduct') {

          var deferred = jQuery.ajax({
            url: "/api/compositeArticles/add/" + invoiceId + "/product/" + productId + "/" + quantity + "/" + priceId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Produkt hinzugefügt', productName);
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        } else {

          var deferred = jQuery.ajax({
            url: "/api/" + this.get('controller').get('type') + "/" + invoiceId + "/add/product/" + productId + "/" + quantity + "/" + priceId + "/" + patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Produkt hinzugefügt', productName);
            if (controller.get('type') === 'inventoryRequests') {
              self.transitionTo('inventory.bookin-request', invoiceId);
            } else if (controller.get('type') === 'treatmentOffers') {
              self.reloadTreatment(invoiceId);
              var deferred = jQuery.ajax({
                url: "/api/treatmentOffers/hasInstantTriggers/" + productId + "/" + invoiceId,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                if (data && data.length > 0) {
                  var first = data[0];
                  var type = first.type.id;
                  var triggerType = first.triggerType.id;

                  if (triggerType === 'instant') {
                    if (type === 'add_task') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-task-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addTaskPanel').send('load', first);
                    } else if (type === 'message') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-notification-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addNotificationPanel').send('load', first);
                    } else if (type === 'add_appointment') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-appointment-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addAppointmentPanel').send('loadRecord', first, self.store.find('patient-appointment-category'));
                    } else if (type === 'add_reminder') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-reminder-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addReminderPanel').send('load', first);
                    }
                  }
                }
              });
            }
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        }
      },
      addText: function addText(quantity, serviceId, invoiceId, serviceName) {
        var self = this,
            patientId = this.get('controller').get('selectedPatient'),
            controller = this.get('controller');

        if (isNaN(patientId)) {
          patientId = 0;
        }

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        if (controller.get('type') === 'compositeTemplate') {

          var deferred = jQuery.ajax({
            url: "/api/compositeArticles/add/" + invoiceId + "/template/" + serviceId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Textvorlage hinzugefügt', serviceName);
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        } else {

          var deferred = jQuery.ajax({
            url: "/api/" + this.get('controller').get('type') + "/" + invoiceId + "/add/text/" + serviceId + "/" + quantity + "/" + patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.reloadTreatment(invoiceId);
            jason.notifiction.notifiy('Vorlage hinzugefügt', serviceName);
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        }
      },
      addService: function addService(quantity, serviceId, invoiceId, serviceName) {
        var self = this,
            patientId = this.get('controller').get('selectedPatient'),
            controller = this.get('controller');

        if (isNaN(patientId)) {
          patientId = 0;
        }

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        if (controller.get('type') === 'compositeService') {

          var deferred = jQuery.ajax({
            url: "/api/compositeArticles/add/" + invoiceId + "/service/" + serviceId + "/" + quantity,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            jason.notifiction.notifiy('Leistung hinzugefügt', serviceName);
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        } else {

          var deferred = jQuery.ajax({
            url: "/api/" + this.get('controller').get('type') + "/" + invoiceId + "/add/service/" + serviceId + "/" + quantity + "/" + patientId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {

            jason.notifiction.notifiy('Leistung hinzugefügt', serviceName);

            if (controller.get('type') === 'treatmentOffers') {
              self.reloadTreatment(invoiceId);
              var deferred = jQuery.ajax({
                url: "/api/treatmentOffers/hasInstantTriggers/" + serviceId + "/" + invoiceId,
                method: "GET",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function (data) {
                if (data && data.length > 0) {
                  var first = data[0];
                  var type = first.type.id;
                  var triggerType = first.triggerType.id;

                  if (triggerType === 'instant') {
                    if (type === 'add_task') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-task-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addTaskPanel').send('load', first);
                    } else if (type === 'message') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-notification-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addNotificationPanel').send('load', first);
                    } else if (type === 'add_appointment') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-appointment-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addAppointmentPanel').send('loadRecord', first, self.store.find('patient-appointment-category'));
                    } else if (type === 'add_reminder') {
                      $.magnificPopup.open({
                        removalDelay: 500,
                        closeOnBgClick: false,
                        items: {
                          src: '#modal-add-reminder-panel'
                        },
                        callbacks: {
                          beforeOpen: function beforeOpen() {
                            var Animation = "mfp-with-fade";
                            this.st.mainClass = Animation;
                          }
                        },
                        midClick: true
                      });

                      controller.get('addReminderPanel').send('load', first);
                    }
                  }
                }
              });
            }
          }, function (error) {
            return { status: error.statusText, message: error.responseText };
          });
          return Ember['default'].RSVP.resolve(deferred);
        }
      },
      addComposite: function addComposite(quantity, productId, invoiceId, priceId, productName) {

        var self = this,
            patientId = this.get('controller').get('selectedPatient'),
            controller = this.get('controller');

        if (isNaN(patientId)) {
          patientId = 0;
        }
        if (isNaN(quantity)) {
          quantity = 1;
        }

        if (quantity <= 0) {
          jason.notifiction.error('Fehler', 'Menge muss größer 0 sein');
          return;
        }

        var deferred = jQuery.ajax({
          url: "/api/" + this.get('controller').get('type') + "/" + invoiceId + "/add/composite/" + productId + "/" + quantity + "/" + patientId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Paket hinzugefügt', productName);

          if (controller.get('type') === 'treatmentOffers') {
            self.reloadTreatment(invoiceId);
            var deferred = jQuery.ajax({
              url: "/api/treatmentOffers/hasInstantTriggers/" + productId + "/" + invoiceId,
              method: "GET",
              contentType: "application/json",
              dataType: "json",
              headers: {
                'X-AUTH-TOKEN': API['default'].getToken()
              }
            }).then(function (data) {
              if (data && data.length > 0) {
                var first = data[0];
                var type = first.type.id;
                var triggerType = first.triggerType.id;

                if (triggerType === 'instant') {
                  if (type === 'add_task') {
                    $.magnificPopup.open({
                      removalDelay: 500,
                      closeOnBgClick: false,
                      items: {
                        src: '#modal-add-task-panel'
                      },
                      callbacks: {
                        beforeOpen: function beforeOpen() {
                          var Animation = "mfp-with-fade";
                          this.st.mainClass = Animation;
                        }
                      },
                      midClick: true
                    });

                    controller.get('addTaskPanel').send('load', first);
                  } else if (type === 'message') {
                    $.magnificPopup.open({
                      removalDelay: 500,
                      closeOnBgClick: false,
                      items: {
                        src: '#modal-add-notification-panel'
                      },
                      callbacks: {
                        beforeOpen: function beforeOpen() {
                          var Animation = "mfp-with-fade";
                          this.st.mainClass = Animation;
                        }
                      },
                      midClick: true
                    });

                    controller.get('addNotificationPanel').send('load', first);
                  } else if (type === 'add_appointment') {
                    $.magnificPopup.open({
                      removalDelay: 500,
                      closeOnBgClick: false,
                      items: {
                        src: '#modal-add-appointment-panel'
                      },
                      callbacks: {
                        beforeOpen: function beforeOpen() {
                          var Animation = "mfp-with-fade";
                          this.st.mainClass = Animation;
                        }
                      },
                      midClick: true
                    });

                    controller.get('addAppointmentPanel').send('loadRecord', first, self.store.find('patient-appointment-category'));
                  } else if (type === 'add_reminder') {
                    $.magnificPopup.open({
                      removalDelay: 500,
                      closeOnBgClick: false,
                      items: {
                        src: '#modal-add-reminder-panel'
                      },
                      callbacks: {
                        beforeOpen: function beforeOpen() {
                          var Animation = "mfp-with-fade";
                          this.st.mainClass = Animation;
                        }
                      },
                      midClick: true
                    });

                    controller.get('addReminderPanel').send('load', first);
                  }
                }
              }
            });
          }
        }, function (error) {
          return { status: error.statusText, message: error.responseText };
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      connectTask: function connectTask(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/tasks",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Aufgabe', 'erstellt und verknüpft');
        });
      },
      createReminder: function createReminder(data) {
        var controller = this.get('controller');
        var self = this;
        $.ajax({
          url: "/api/reminders",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erinnerung', 'verschickt');
        });
      },
      createNotification: function createNotification(data) {
        var self = this,
            controller = this.get('controller');
        $.ajax({
          url: "/api/msgs",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Nachricht', 'verschickt');
        });
      },
      sendForward: function sendForward(data) {
        var self = this;
        $.ajax({
          url: "/api/msgs/forward",
          method: "PUT",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.selectTab('outbox');
          jason.notifiction.notifiy('Nachricht', 'weitergeleitet');
        });
      },
      createAppointment: function createAppointment(data) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointments",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Termin', 'erfolgreich gespeichert');
        }, function () {});
      }
    }
  });

});
define('jason-frontend/templates/invoices/select-customer', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/invoices/select-customer.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","action-icons");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--small");
          dom.setAttribute(el2,"onclick","history.back()");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-prev",[],["color","#ffffff","size","44"],["loc",[null,[26,72],[26,113]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/invoices/select-customer.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Ausgangsrechnung erstellen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/cashdesk-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Barverkauf");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Kund:in anlegen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function () {\n        $(\"#addCustomerForm\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'firstname': {\n                    required: true\n                },\n                'lastname': {\n                    required: true\n                },\n                'email': {\n                    email: true\n                },\n                'phone': {\n                    pattern: /^\\+?[\\s\\d]+$/\n                }\n            },\n            highlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function (error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 3]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        morphs[5] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[6] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[7] = dom.createMorphAt(fragment,8,8,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["element","action",["setCashsale"],[],["loc",[null,[5,9],[5,33]]]],
        ["element","action",["openCreateCustomer",["get","invoiceId",["loc",[null,[8,39],[8,48]]]]],[],["loc",[null,[8,9],[8,50]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[16,30],[16,40]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[16,6],[16,90]]]],
        ["inline","customer-searchform",[],["showSearch",false,"filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[22,52],[22,62]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[22,69],[22,74]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[22,83],[22,90]]]]],[],[]],"showCashSaleButton",false,"gotoPatient","gotoPatient","applyFilter","applyFilter","addPatient","addPatient","openScanEtigaQr","openScanEtigaQr","selectCustomer","setCustomerContr","setCashsale","setCashsale"],["loc",[null,[22,2],[22,287]]]],
        ["block","if",[["subexpr","has-permission",["c_payments"],[],["loc",[null,[24,6],[24,35]]]]],[],0,null,["loc",[null,[24,0],[28,7]]]],
        ["inline","create-customer-panel",[],["gendersAll",["subexpr","@mut",[["get","gendersAll",["loc",[null,[30,35],[30,45]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[30,64],[30,81]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[30,97],[30,111]]]]],[],[]],"create","create","toTreatment","toTreatment","gotoDetails","gotoDetails","gotoPatient","gotoPatient","setCustomer","setCustomer","toWaitingRoom","toWaitingRoom","refresh","applyFilter","actionReceiver",["subexpr","@mut",[["get","createCustomerPanel",["loc",[null,[37,39],[37,58]]]]],[],[]]],["loc",[null,[30,0],[38,2]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[39,41],[39,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[39,74],[39,88]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[39,104],[39,122]]]]],[],[]],"createPatient","createPatient"],["loc",[null,[39,0],[39,154]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
define('jason-frontend/templates/components/button-locked', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-locked.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"{{size}}\" height=\"{{size}}\" viewBox=\"0 0 34 34\" class=\"lock-button state-button cursor {{css-bool-evaluator active 'active' ''}}\" {{action 'toggle'}}>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("  <g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(1 1)\">");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    {{#if showStroke}}-->\n<!--      <circle class=\"circle\" cx=\"16\" cy=\"16\" r=\"16\" stroke=\"{{color}}\"/>-->\n<!--    {{/if}}");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    <g class=\"icon in__icon\" fill=\"{{color}}\" transform=\"scale(0.044, 0.044) translate(110,90)\">");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("      <path d=\"M81.5,488.8h325.8c19.7,0,35.8-16.1,35.8-35.8V258.3c0-19.7-16.1-35.8-35.8-35.8h-25.2v-84.8C382.1,61.8,320.3,0,244.4,0    S106.7,61.8,106.7,137.7v84.8H81.5c-19.7,0-35.8,16.1-35.8,35.8V453C45.7,472.7,61.8,488.8,81.5,488.8z M131.2,137.7    c0-62.4,50.8-113.2,113.2-113.2s113.2,50.8,113.2,113.2v84.8H131.2V137.7z M70.2,258.3c0-6.2,5.1-11.3,11.3-11.3h325.8    c6.2,0,11.3,5.1,11.3,11.3V453c0,6.2-5.1,11.3-11.3,11.3H81.5c-6.2,0-11.3-5.1-11.3-11.3C70.2,453,70.2,258.3,70.2,258.3z\"/>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("      <path d=\"M244.4,405.5c27.5,0,49.9-22.4,49.9-49.9s-22.4-49.9-49.9-49.9s-49.9,22.4-49.9,49.9C194.5,383.2,216.9,405.5,244.4,405.5    z M244.4,330.3c14,0,25.4,11.4,25.4,25.4s-11.4,25.4-25.4,25.4c-14,0-25.4-11.4-25.4-25.4S230.4,330.3,244.4,330.3z\"/>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    </g>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("  </g>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("</svg>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"xmlns:xlink","http://www.w3.org/1999/xlink");
        dom.setAttribute(el1,"viewBox","0 0 24 24");
        dom.setAttribute(el1,"version","1.1");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        dom.setAttribute(el2,"stroke","none");
        dom.setAttribute(el2,"stroke-width","1");
        dom.setAttribute(el2,"fill-rule","evenodd");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("mask");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("use");
        dom.setAttributeNS(el4,"http://www.w3.org/1999/xlink","xlink:href","#path-1");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("g");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"d","M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [18]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        morphs[3] = dom.createAttrMorph(element2, 'fill');
        morphs[4] = dom.createAttrMorph(element3, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[13,92],[13,96]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[13,110],[13,114]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[14,46],[14,51]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[15,22],[15,27]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[19,368],[19,373]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});
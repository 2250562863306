define('jason-frontend/helpers/has-visit-entries', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.hasVisitEntries = hasVisitEntries;

  function hasVisitEntries(params) {
    var offer = params[0];
    var visitId = params[1];

    if (offer && visitId) {
      return offer.get('visitsWithEntries') && offer.get('visitsWithEntries').includes(parseInt(visitId));
    }
    return false;
  }

  exports['default'] = Ember['default'].Helper.helper(hasVisitEntries);

});
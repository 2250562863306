define('jason-frontend/models/treatment-offer', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    created: DS['default'].attr('date'),
    entries: DS['default'].hasMany('treatmentOfferEntry'),
    visitsWithEntries: DS['default'].attr(),
    total: DS['default'].attr('number'),
    formMissing: DS['default'].attr('boolean'),
    locked: DS['default'].attr('boolean'),
    hasEntries: DS['default'].attr('boolean'),
    hasInvoicingPositions: DS['default'].attr('boolean'),
    info: DS['default'].attr('string'),
    insufficientLaboklinStatus: DS['default'].attr('boolean'),
    fullyBooked: DS['default'].attr('boolean'),
    unsubmittedLaboklinStatus: DS['default'].attr('boolean'),
    diagnosticTests: DS['default'].attr(''),
    digitalTherapies: DS['default'].attr(''),
    therapyPlans: DS['default'].attr('')
  });

});
define('jason-frontend/components/customer-etiga', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    tagName: 'div',
    permissionTypesList: [{ value: "Keine", id: "none" }, { value: "Lesen", id: "read" }, { value: "Bearbeiten", id: "write" }],
    permissionTypesListReadOnly: [{ value: "Keine", id: "none" }, { value: "Lesen", id: "read" }],
    permissionTypesListReadWriteOnly: [{ value: "Lesen", id: "read" }, { value: "Bearbeiten", id: "write" }],
    willDestroyElement: function willDestroyElement() {
      this.externalSave();
    },
    externalSave: function externalSave() {
      var perms = this.get('permissions'),
          patientId = this.get('patient').id;

      if (perms) {
        $(perms.masterEntries).each(function (index, val) {
          $(val.entries).each(function (index2, val2) {
            var mandantId = val2.mandantId;
            var permission = val2.title;
            var reference = $('input.' + permission + '.js-date-picker.mandant' + mandantId).val();
            Ember['default'].set(val2, "date", reference);
          });
        });
        $(perms.additionalEntries).each(function (index, val) {
          $(val.entries).each(function (index2, val2) {
            var mandantId = val2.mandantId;
            var permission = val2.title;
            var reference = $('input.' + permission + '.js-date-picker.mandant' + mandantId).val();
            Ember['default'].set(val2, "date", reference);
          });
        });
        this.sendAction('savePermission', patientId, perms);
      }
    },
    actions: {
      downloadQrCode: function downloadQrCode() {
        this.sendAction('downloadEtigaQr');
      },
      expandDate: function expandDate(id, type) {
        var ref = $('.js-date-picker.' + type + '.mandant' + id).val();
        $('.js-date-picker.mandant' + id).val(ref);
      },
      deleteDate: function deleteDate(entry) {
        Ember['default'].set(entry, "date", null);
      },
      showAppCredentials: function showAppCredentials(id, patient) {

        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/etigaCredentials",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('etigaAppPermissionPanel').send('loadCredentials', data, patient.get('customer').get('email'));
        });

        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-etiga-app-permission-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      uploadDone: function uploadDone(data) {
        this.sendAction('uploadDeclaration', data);
      },
      downloadEtigaQr: function downloadEtigaQr() {
        this.sendAction('downloadEtigaQr');
      },
      externalSave: function externalSave() {
        this.externalSave();
      },
      selectDocs: function selectDocs() {
        this.sendAction('selectDocs');
      },
      toggleAccordion: function toggleAccordion(id, state) {
        this.set(id, !state);
      },
      toggleFirst: function toggleFirst(toggle) {
        if (this.get('firstShareShown')) {
          this.set('firstShareActive', false);
          this.set('firstShareShown', false);
        } else {
          this.set('firstShareActive', true);
          this.set('firstShareShown', true);
        }
      },
      toggleSecond: function toggleSecond(toggle) {
        if (this.get('secondShareShown')) {
          this.set('secondShareActive', false);
          this.set('secondShareShown', false);
        } else {
          this.set('secondShareActive', true);
          this.set('secondShareShown', true);
        }
      },
      activateEtiga: function activateEtiga(id) {
        this.sendAction('activateEtiga', id);
      },
      chooseEtigaPanel: function chooseEtigaPanel(id) {
        this.sendAction('chooseEtigaPanel', id);
      },
      addEtigaShare: function addEtigaShare() {
        this.sendAction('addEtigaShare');
      },
      removeShare: function removeShare(id) {
        this.sendAction('removeShare', id);
      },
      editEtigaPermissions: function editEtigaPermissions(id) {
        this.sendAction('editEtigaPermissions', id);
      },
      loadPermissions: function loadPermissions(data) {
        this.set('permissions', data);
      },
      submitCredentials: function submitCredentials(data) {
        this.sendAction('submitCredentials', data);
      },
      disconnectEtiga: function disconnectEtiga(data) {
        this.sendAction('disconnectEtiga', data);
      },
      loadMyPermissions: function loadMyPermissions(data) {
        this.set('myPermissions', data);
      },
      watchEditPermission: (function () {}).observes('permissions.additionalEntries.@each.entries.@each.permissionPractice')
    }
  });

});
define('jason-frontend/routes/basicinfos/index', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;
      var selectedTab = params.queryParams.selectTab;

      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        setTimeout(function () {
          if (API['default'].hasPermission("crud_products")) {
            self.selectTab('productcategories');
          } else if (API['default'].hasPermission("crud_services")) {
            self.selectTab('servicecategories');
          }
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      if (this.get('controller').get('selectTab') === 'serviceunits') {
        if (API['default'].hasPermission("crud_services")) {
          this.applyFilterServicecategories();
        }
      }
      if (this.get('controller').get('selectTab') === 'appointmentcategories') {
        this.applyAppointmentCategories();
      }
      if (this.get('controller').get('selectTab') === 'tags') {
        this.applyTags();
      }
      if (this.get('controller').get('selectTab') === 'etigaAppointmentcategories') {
        this.applyEtigaAppointmentCategories();
      }
      if (this.get('controller').get('selectTab') === 'distributors') {
        if (API['default'].hasPermission("crud_distributor")) {
          this.applyDistributors();
        }
      }
      if (this.get('controller').get('selectTab') === 'manufacturers') {
        if (API['default'].hasPermission("crud_manufacturer")) {
          this.applyManufacturers();
        }
      }
      if (this.get('controller').get('selectTab') === 'accounting') {
        if (API['default'].hasPermission("crud_accounting")) {
          this.applyAccounting();
        }
      }
      if (this.get('controller').get('selectTab') === 'accountAssigment') {
        if (API['default'].hasPermission("crud_accounting")) {
          this.applyAccountingAssignment();
        }
      }
    },
    applyAppointmentCategories: function applyAppointmentCategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName');
      this.findPaged('patient-appointment-category', {
        filterName: filterName
      }).then(function (data) {
        controller.set('model', data);
      });
    },
    applyAccounting: function applyAccounting() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName');
      this.findPaged('mandant-account', {
        filterName: filterName
      }).then(function (data) {
        controller.set('model', data);
      });
    },
    applyAccountAssignment: function applyAccountAssignment() {

      var controller = this.get('controller');
      controller.set('accounts', this.store.findAll('mandant-account'));

      $.ajax({
        url: "/api/mandantAccounts/assignments",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('accountAssignments', data.mandantAccountAssignment);
      }, function (error) {});
    },
    applyEtigaAppointmentCategories: function applyEtigaAppointmentCategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName');
      this.findPaged('patient-appointment-category', {
        filterName: filterName,
        etiga: true
      }).then(function (data) {
        controller.set('model', data);
      });
    },
    applyDistributors: function applyDistributors() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('productDistributor', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyManufacturers: function applyManufacturers() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('productManufacturer', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyTags: function applyTags() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('document-tag', {
        filterName: filterName,
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyFilterServicecategories: function applyFilterServicecategories() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          filterSource = controller.get('filterSource'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      if (!filterSource) {
        filterSource = "all";
      }

      this.findPaged('serviceunit', {
        filterName: filterName,
        filterMandant: filterSource,
        sortDir: sortDir,
        paged: true,
        sortBy: sortBy,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'productcategories') {
        if (API['default'].hasPermission("crud_categories")) {
          controller.set('model', this.store.find('productCategory'));
        }
        controller.set('placeholder', 'Produktkategorie suchen');
      } else if (selected === 'servicecategories') {
        if (API['default'].hasPermission("crud_categories")) {
          controller.set('model', this.store.find('serviceCategory'));
        }
        controller.set('placeholder', 'Leistungskategorie suchen');
      } else if (selected === 'serviceunits') {
        this.applyFilterServicecategories();
        controller.set('placeholder', 'Einheit suchen');
      } else if (selected === 'tags') {
        this.applyTags();
        controller.set('placeholder', 'Tag suchen');
      } else if (selected === 'appointmentcategories') {
        this.applyAppointmentCategories();
        controller.set('placeholder', 'Terminkategorie suchen');
      } else if (selected === 'etigaAppointmentcategories') {
        this.applyEtigaAppointmentCategories();
        controller.set('placeholder', 'App Terminbezeichnungen suchen');
      } else if (selected === 'distributors') {
        this.applyDistributors();
        controller.set('placeholder', 'Lieferant suchen');
      } else if (selected === 'manufacturers') {
        this.applyManufacturers();
        controller.set('placeholder', 'Hersteller suchen');
      } else if (selected === 'accounting') {
        this.applyAccounting();
        controller.set('placeholder', 'Konto suchen');
      } else if (selected === 'accountAssignment') {
        this.applyAccountAssignment();
      }
    },
    actions: {
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      deleteManufacturer: function deleteManufacturer(id) {
        var self = this;
        this.get('store').find('productManufacturer', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteAccount: function deleteAccount(id) {
        var self = this;
        this.get('store').find('mandantAccount', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteDistributor: function deleteDistributor(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('productDistributor', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteEtigaAppointmentCategory: function deleteEtigaAppointmentCategory(id) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointmentCategories/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      deleteAppointmentCategory: function deleteAppointmentCategory(id) {
        var self = this;
        $.ajax({
          url: "/api/patientAppointmentCategories/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      deleteTag: function deleteTag(id) {
        var self = this;
        $.ajax({
          url: "/api/documentTags/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        }, function (error) {
          self.applyFilter();
          jason.notifiction.notifiy('Erfolgreich', 'gelöscht');
        });
      },
      saveAssignment: function saveAssignment() {

        var controller = this.get('controller'),
            accountAssignments = controller.get('accountAssignments');

        var data = {
          cashFullIn: accountAssignments.cashFullIn,
          cashFullOut: accountAssignments.cashFullOut,
          cashReduced1In: accountAssignments.cashReduced1In,
          cashReduced1Out: accountAssignments.cashReduced1Out,
          cashReduced2In: accountAssignments.cashReduced2In,
          cashReduced2Out: accountAssignments.cashReduced2Out,
          cashNoneIn: accountAssignments.cashNoneIn,
          cashNoneOut: accountAssignments.cashNoneOut,
          ecFullIn: accountAssignments.ecFullIn,
          ecFullOut: accountAssignments.ecFullOut,
          ecReduced1In: accountAssignments.ecReduced1In,
          ecReduced1Out: accountAssignments.ecReduced1Out,
          ecReduced2In: accountAssignments.ecReduced2In,
          ecReduced2Out: accountAssignments.ecReduced2Out,
          ecNoneIn: accountAssignments.ecNoneIn,
          ecNoneOut: accountAssignments.ecNoneOut,
          ccFullIn: accountAssignments.ccFullIn,
          ccFullOut: accountAssignments.ccFullOut,
          ccReduced1In: accountAssignments.ccReduced1In,
          ccReduced1Out: accountAssignments.ccReduced1Out,
          ccReduced2In: accountAssignments.ccReduced2In,
          ccReduced2Out: accountAssignments.ccReduced2Out,
          ccNoneIn: accountAssignments.ccNoneIn,
          ccNoneOut: accountAssignments.ccNoneOut,
          depositFullIn: accountAssignments.depositFullIn,
          depositFullOut: accountAssignments.depositFullOut,
          depositReduced1In: accountAssignments.depositReduced1In,
          depositReduced1Out: accountAssignments.depositReduced1Out,
          depositReduced2In: accountAssignments.depositReduced2In,
          depositReduced2Out: accountAssignments.depositReduced2Out,
          depositNoneIn: accountAssignments.depositNoneIn,
          depositNoneOut: accountAssignments.depositNoneOut,
          voucherFullIn: accountAssignments.voucherFullIn,
          voucherFullOut: accountAssignments.voucherFullOut,
          voucherReduced1In: accountAssignments.voucherReduced1In,
          voucherReduced1Out: accountAssignments.voucherReduced1Out,
          voucherReduced2In: accountAssignments.voucherReduced2In,
          voucherReduced2Out: accountAssignments.voucherReduced2Out,
          voucherNoneIn: accountAssignments.voucherNoneIn,
          voucherNoneOut: accountAssignments.voucherNoneOut,
          bankFullIn: accountAssignments.bankFullIn,
          bankFullOut: accountAssignments.bankFullOut,
          bankReduced1In: accountAssignments.bankReduced1In,
          bankReduced1Out: accountAssignments.bankReduced1Out,
          bankReduced2In: accountAssignments.bankReduced2In,
          bankReduced2Out: accountAssignments.bankReduced2Out,
          bankNoneIn: accountAssignments.bankNoneIn,
          bankNoneOut: accountAssignments.bankNoneOut,
          inlayIn: accountAssignments.inlayIn,
          inlayOut: accountAssignments.inlayOut,
          removalIn: accountAssignments.removalIn,
          removalOut: accountAssignments.removalOut,
          recieptCash: accountAssignments.recieptCash,
          recieptBank: accountAssignments.recieptBank
        };

        var self = this;
        $.ajax({
          url: "/api/mandantAccounts/assignments",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      },
      deleteServiceunits: function deleteServiceunits(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceunit', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 500);
      },
      deleteServiceCategory: function deleteServiceCategory(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('serviceCategory', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.selectTab('servicecategories');
        }, 1000);
      },
      deleteProductCategory: function deleteProductCategory(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('productCategory', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.selectTab('productcategories');
        }, 1000);
      }
    }
  });

});
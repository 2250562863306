define('jason-frontend/components/edit-medical-problems-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    filterInfinityValue: false,
    filterDiscontinuedValue: false,
    filterAppliedValue: false,
    infinityValues: [{ value: "Nur Dauermedikationen", id: "infinity" }],
    save: function save() {
      var patientInfo = {
        patientId: this.get('patient').id,
        info: $('#medicalIncompatibilityNotice').val(),
        allergies: $('#allergies').val()
      };
      this.sendAction('confirm', patientInfo);
      $.magnificPopup.close();
    },
    actions: {
      save: function save() {
        this.save();
      },
      toggle: function toggle() {
        if (this.get('filterInfinityValue') === true) {
          this.set('filterInfinityValue', false);
        } else {
          this.set('filterInfinityValue', true);
        }
        this.sendAction('refresh', this.get('filterInfinityValue'), this.get('filterDiscontinuedValue'), this.get('filterAppliedValue'));
      },
      toggleDis: function toggleDis() {
        if (this.get('filterDiscontinuedValue') === true) {
          this.set('filterDiscontinuedValue', false);
        } else {
          this.set('filterDiscontinuedValue', true);
        }
        this.sendAction('refresh', this.get('filterInfinityValue'), this.get('filterDiscontinuedValue'), this.get('filterAppliedValue'));
      },
      toggleApplied: function toggleApplied() {
        if (this.get('filterAppliedValue') === true) {
          this.set('filterAppliedValue', false);
        } else {
          this.set('filterAppliedValue', true);
        }
        this.sendAction('refresh', this.get('filterInfinityValue'), this.get('filterDiscontinuedValue'), this.get('filterAppliedValue'));
      },
      editDosing: function editDosing(id) {
        this.sendAction('openEditDosing', id);
      },
      confirm: function confirm(id) {
        this.save();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(patient) {
        this.sendAction('refresh', this.get('filterInfinityValue'), this.get('filterDiscontinuedValue'), this.get('filterAppliedValue'));
        this.set('patient', patient);
      },
      loadDosings: function loadDosings(items, filter) {
        this.set('items', items);
      }
    }
  });

});
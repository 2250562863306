define('jason-frontend/models/treatment-station-cage', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    station: DS['default'].attr(),
    status: DS['default'].attr(),
    currentVisit: DS['default'].attr()
  });

});
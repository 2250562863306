define('jason-frontend/routes/compositeproducts/index', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",

    model: function model(params) {
      params.filterMandant = true;
      return this.findPaged('compositeArticle', params);
    },
    setupController: function setupController(controller, model, params) {
      controller.set("model", model);

      controller.set('mandantId', API['default'].getMandant());

      if (API['default'].isPreselectFavorites()) {
        setTimeout(function () {
          controller.set('filterFavs', API['default'].isPreselectFavorites());
        }, 500);
      }
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          filterName = controller.get('filterName'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy'),
          filterFavorites = controller.get('filterFavs');

      this.findPaged('compositeArticle', {
        filterName: filterName,
        filterMandant: true,
        sortDir: sortDir,
        sortBy: sortBy,
        filterFavorites: filterFavorites,
        perPage: 10
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    toggleProductFavoriteIntern: function toggleProductFavoriteIntern(id, add) {
      var controller = this.get('controller');

      if (add) {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    toggleFavoriteIntern: function toggleFavoriteIntern(id, add) {
      if (add) {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/addToFavs",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      } else {
        $.ajax({
          url: "/api/compositeArticles/" + id + "/removeFromFavs",
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (id) {}, function (error) {});
      }
    },
    actions: {
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      copy: function copy(product) {
        var self = this;
        $.ajax({
          url: "/api/compositeArticles/copy/" + product,
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Paket kopiert', '');
          self.applyFilter();
        }, function () {
          jason.notifiction.systemError('Fehlercode 102', 'Paket konnte nicht kopiert werden');
        });
      },
      toggleFavorite: function toggleFavorite(product, add) {
        Ember['default'].set(product, 'favorite', add);
        this.toggleFavoriteIntern(product.id, add);
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      setSort: function setSort(attr) {
        var controller = this.get('controller');
        controller.set('sortBy', attr);
        if (controller.get('sortDir') === 'asc') {
          controller.set('sortDir', 'desc');
        } else {
          controller.set('sortDir', 'asc');
        }
      },
      'delete': function _delete(id) {
        this.get('store').find('compositeArticle', id).then(function (rec) {
          rec.destroyRecord();
        });
      }
    }
  });

});
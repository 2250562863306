define('jason-frontend/helpers/is-treatment-entry-shown', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.isTreatmentEntryShown = isTreatmentEntryShown;

  function isTreatmentEntryShown(params) {
    var entry = params[0];
    var visitId = params[1];

    if (!visitId && !entry.get('visitId')) {
      return true;
    }
    return parseInt(visitId) === parseInt(entry.get('visitId'));
  }

  exports['default'] = Ember['default'].Helper.helper(isTreatmentEntryShown);

});
define('jason-frontend/templates/components/global-doctor-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/global-doctor-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid gutter-fix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","form-field-rework");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","select-placeholder");
        var el4 = dom.createTextNode("Tierärzt:in");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","form-field-rework");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","select-placeholder");
        var el4 = dom.createTextNode("Assistent:in");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),3,3);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),3,3);
        return morphs;
      },
      statements: [
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[5,14],[5,21]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[6,12],[6,18]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","entry-doctor","placeholder","für alle Positionen übernehmen"],["loc",[null,[4,4],[12,6]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[17,14],[17,24]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[18,12],[18,21]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","shortName","allowClear",true,"name","entry-assistant","placeholder","für alle Positionen übernehmen"],["loc",[null,[16,4],[24,6]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});
define('jason-frontend/models/order-entry', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    position: DS['default'].attr('number'),
    day: DS['default'].attr('string'),
    stationInfo: DS['default'].attr('string'),
    quantity: DS['default'].attr('number'),
    unitQuantity: DS['default'].attr('number'),
    price: DS['default'].attr('number'),
    laboklin: DS['default'].attr('boolean'),
    laboklinFormId: DS['default'].attr('number'),
    article: DS['default'].belongsTo('article'),
    articleName: DS['default'].attr('string'),
    infinitySloping: DS['default'].attr('boolean'),
    applied: DS['default'].attr('boolean'),
    tgd: DS['default'].attr('boolean'),
    absoluteDiscount: DS['default'].attr('boolean'),
    discount: DS['default'].attr('number'),
    total: DS['default'].attr('number'),
    tax: DS['default'].belongsTo('tax'),
    patient: DS['default'].belongsTo('patient'),
    hideEhapo: DS['default'].attr('boolean'),
    unit: DS['default'].attr(''),
    bankadvice: DS['default'].attr('boolean'),
    doctor: DS['default'].belongsTo('user'),
    doctorId: DS['default'].attr('number'),
    assistant: DS['default'].belongsTo('user'),
    assistantId: DS['default'].attr('number'),
    fullyBooked: DS['default'].attr('boolean'),
    slopingInfo: DS['default'].attr('string'),
    subText: DS['default'].attr('string'),
    alreadyBooked: DS['default'].attr('number'),
    movements: DS['default'].hasMany('inventory-stock-movement'),
    templateInstanceId: DS['default'].attr('number'),
    type: DS['default'].attr('')
  });

});
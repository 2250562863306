define('jason-frontend/templates/components/edit-purchase-product-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 251,
                "column": 16
              },
              "end": {
                "line": 259,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","progress mt10 mbn");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"style","width: 100%");
            dom.setAttribute(el2,"aria-valuemax","100");
            dom.setAttribute(el2,"aria-valuemin","0");
            dom.setAttribute(el2,"aria-valuenow","100");
            dom.setAttribute(el2,"role","progressbar");
            dom.setAttribute(el2,"class","progress-bar progress-bar-info progress-bar-striped active");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sr-only");
            var el4 = dom.createTextNode("Bild wird hochgeladen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 259,
                "column": 16
              },
              "end": {
                "line": 264,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","field append-button");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","button w300");
            var el3 = dom.createTextNode("Neues Bild hochladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","view",["cloudinary"],[],["loc",[null,[262,20],[262,41]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 248,
              "column": 12
            },
            "end": {
              "line": 267,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-12/24");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","uploading",["loc",[null,[251,22],[251,31]]]]],[],0,1,["loc",[null,[251,16],[264,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 270,
              "column": 12
            },
            "end": {
              "line": 272,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","media-object mh200");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","item.pictureUrl",["loc",[null,[271,53],[271,68]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 12
            },
            "end": {
              "line": 274,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","media-object mh200");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",["assets/images/products/",["subexpr","get-default-picture",[["get","item",["loc",[null,[273,96],[273,100]]]]],[],["loc",[null,[273,74],[273,102]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 276,
              "column": 12
            },
            "end": {
              "line": 283,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","pictureUrl");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Url:");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","input",[],["value",["subexpr","@mut",[["get","item.pictureUrl",["loc",[null,[280,32],[280,47]]]]],[],[]],"class","col l-24/24 gui-input","name","url"],["loc",[null,[280,18],[280,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 311,
              "column": 14
            },
            "end": {
              "line": 321,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","uploadDoc");
          dom.setAttribute(el2,"class","button w300");
          dom.setAttribute(el2,"id","pickDoc");
          var el3 = dom.createTextNode("\n                      Neues Dokument hochladen\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","box col l-24/24");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Die max. Dateigröße beträgt 10 MByte");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["box col l-12/24 ",["subexpr","css-bool-evaluator",[["get","showDocUploadButton",["loc",[null,[312,67],[312,86]]]],"","hidden"],[],["loc",[null,[312,46],[312,100]]]]]]],
          ["inline","doc-upload",[],["uploadDone","docUploadDone","name","uploadDoc"],["loc",[null,[313,20],[313,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 322,
              "column": 14
            },
            "end": {
              "line": 329,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","detailUrl");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Url:");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","input",[],["value",["subexpr","@mut",[["get","item.detailUrl",["loc",[null,[326,34],[326,48]]]]],[],[]],"class","col l-24/24 gui-input","name","detailUrl"],["loc",[null,[326,20],[326,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 379,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-purchase-product-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-purchase-product-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editPurchaseProductForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","tabs__head");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","u-mb0 clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#tab-general");
        var el9 = dom.createTextNode("Allgemeines");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#tab-additional");
        var el9 = dom.createTextNode("Zusatzinformationen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"href","#tab-media");
        var el9 = dom.createTextNode("Medien");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","tabs__body mt20");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-general");
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","mb20");
        var el8 = dom.createTextNode("Referenzen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","pzn");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Pharmazentralnummer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","supportForeignId");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Lieferantenspezfische Artikelnr:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","partnerForeignId");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Partnerspezifische Artikelnr:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","gtin");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("GTIN:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","mb20");
        var el8 = dom.createTextNode("Allgemein");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productName");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Name");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","mb10 title");
        var el10 = dom.createTextNode("\n                  Beschreibung\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productDescription");
        dom.setAttribute(el9,"class","field prepend-icon");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","productDescription");
        dom.setAttribute(el10,"class","field-icon");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","fa fa-comments");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n             ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productUnit");
        dom.setAttribute(el9,"class","select--inline input-element");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","title field-label");
        dom.setAttribute(el10,"style","color: #626262");
        var el11 = dom.createTextNode("Verpackungseinheit");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-8/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productPackageQuantity");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Verpackungsmenge");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productTax");
        dom.setAttribute(el9,"class","select--inline input-element");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","title field-label");
        dom.setAttribute(el10,"style","color: #626262");
        var el11 = dom.createTextNode("MwSt");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-10/24");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","salePriceGross");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Verkaufspreis brutto");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","priceEuroLabel");
        var el10 = dom.createTextNode("EUR");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","mb20");
        var el8 = dom.createTextNode("Klassifizierung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","product-category");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Hauptkategorie");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","select");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("i");
        dom.setAttribute(el10,"class","arrow");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","partnerCategory");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Lieferantenspezifische Hauptkategorie");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","partnerSubcategory");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Lieferantenspezifische Unterkategorie");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-additional");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","mb20");
        var el8 = dom.createTextNode("Zusatzinformationen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productIngredients");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Wirkstoffe/Zusatzstoffe");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productNarcotic");
        dom.setAttribute(el9,"class","field-label title required");
        var el10 = dom.createTextNode("Suchtgiftmittel?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","checkbox-custom checkbox-primary field-checkbox");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","productNarcotic");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productSlopingInfo");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Dosierungsinformationen / Analytische\n                  Bestandteile");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productDomainInfo");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Fachinformationen / Zusammensetzung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productTargetRace");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Zielspezies");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productLicenceNumber");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("Zulassungsnummer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productAtc");
        dom.setAttribute(el9,"class","field-label title");
        var el10 = dom.createTextNode("ATC-Code");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","mb10 title");
        var el10 = dom.createTextNode("\n                  ATC-Code Beschreibung\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productAtcDescription");
        dom.setAttribute(el9,"class","field prepend-icon");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","productAtcDescription");
        dom.setAttribute(el10,"class","field-icon");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("i");
        dom.setAttribute(el11,"class","fa fa-comments");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6,"data-for","#tab-media");
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h2");
        dom.setAttribute(el8,"class","mb20");
        var el9 = dom.createTextNode("Bild");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","field");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","imageSource");
        dom.setAttribute(el10,"class","field-label title mb10");
        var el11 = dom.createTextNode("Bildquelle wählen");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-24/24 radio-custom radio-primary mb10");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","imageUrl");
        var el12 = dom.createTextNode("Hyperlink zu Bild (Url)");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-24/24 radio-custom radio-primary mb10");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","supplierLogo");
        var el12 = dom.createTextNode("Lieferantenlogo anzeigen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-24/24 radio-custom radio-primary mb10");
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","upload");
        var el12 = dom.createTextNode("Neues Bild hochladen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h2");
        dom.setAttribute(el8,"class","mb20");
        var el9 = dom.createTextNode("Dokument für Fachinformationen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","field");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","imageSource");
        dom.setAttribute(el10,"class","field-label title mb10");
        var el11 = dom.createTextNode("Dokumentenquelle wählen");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-24/24 radio-custom radio-primary mb10");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","infodocUrl");
        var el12 = dom.createTextNode("Hyperlink zu Dokument (Url)");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","col l-24/24 radio-custom radio-primary mb10");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","infodocUpload");
        var el12 = dom.createTextNode("Neues Dokument hochladen");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n\n    $('body').on('click', 'label#pickDoc', function() {\n      $('#editPurchaseProductForm input[name=uploadDoc]').click();\n    });\n\n    $(\"#editPurchaseProductForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'productName': {\n          required: true\n        },\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0, 1, 1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element5, [3, 1]);
        var element8 = dom.childAt(element5, [5, 1]);
        var element9 = dom.childAt(element4, [3]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element9, [3]);
        var element12 = dom.childAt(element9, [5]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [3, 1]);
        var element15 = dom.childAt(element12, [7]);
        var element16 = dom.childAt(element15, [3, 1]);
        var morphs = new Array(42);
        morphs[0] = dom.createElementMorph(element3);
        morphs[1] = dom.createAttrMorph(element6, 'class');
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createAttrMorph(element7, 'class');
        morphs[4] = dom.createElementMorph(element7);
        morphs[5] = dom.createAttrMorph(element8, 'class');
        morphs[6] = dom.createElementMorph(element8);
        morphs[7] = dom.createAttrMorph(element10, 'class');
        morphs[8] = dom.createMorphAt(dom.childAt(element10, [3, 1]),3,3);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [5, 1]),3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(element10, [7, 1]),3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element10, [9, 1]),3,3);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [13, 1]),3,3);
        morphs[13] = dom.createMorphAt(dom.childAt(element10, [15, 1, 3]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element10, [17, 1, 1]),3,3);
        morphs[15] = dom.createMorphAt(dom.childAt(element10, [19, 1]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element10, [22, 1, 1]),3,3);
        morphs[17] = dom.createMorphAt(dom.childAt(element10, [25, 1]),3,3);
        morphs[18] = dom.createMorphAt(dom.childAt(element10, [29, 1, 3]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element10, [31, 1]),3,3);
        morphs[20] = dom.createMorphAt(dom.childAt(element10, [33, 1]),3,3);
        morphs[21] = dom.createAttrMorph(element11, 'class');
        morphs[22] = dom.createMorphAt(dom.childAt(element11, [3, 1]),3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element11, [5, 1, 3]),1,1);
        morphs[24] = dom.createMorphAt(dom.childAt(element11, [7, 1]),3,3);
        morphs[25] = dom.createMorphAt(dom.childAt(element11, [9, 1]),3,3);
        morphs[26] = dom.createMorphAt(dom.childAt(element11, [11, 1]),3,3);
        morphs[27] = dom.createMorphAt(dom.childAt(element11, [13, 1]),3,3);
        morphs[28] = dom.createMorphAt(dom.childAt(element11, [15, 1]),3,3);
        morphs[29] = dom.createMorphAt(dom.childAt(element11, [17, 1, 3]),1,1);
        morphs[30] = dom.createAttrMorph(element12, 'class');
        morphs[31] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element14, [7]),1,1);
        morphs[34] = dom.createMorphAt(element13,5,5);
        morphs[35] = dom.createMorphAt(dom.childAt(element12, [3]),1,1);
        morphs[36] = dom.createMorphAt(element12,5,5);
        morphs[37] = dom.createMorphAt(dom.childAt(element16, [3]),1,1);
        morphs[38] = dom.createMorphAt(dom.childAt(element16, [5]),1,1);
        morphs[39] = dom.createMorphAt(element15,5,5);
        morphs[40] = dom.createMorphAt(element15,6,6);
        morphs[41] = dom.createMorphAt(dom.childAt(element3, [3, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,39],[3,71]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[9,87],[9,96]]]],"general"],[],["loc",[null,[9,83],[9,107]]]],"is-active",""],[],["loc",[null,[9,62],[9,124]]]]]]],
        ["element","action",["selectTab","general"],[],["loc",[null,[9,146],[9,178]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[12,87],[12,96]]]],"additional"],[],["loc",[null,[12,83],[12,110]]]],"is-active",""],[],["loc",[null,[12,62],[12,127]]]]]]],
        ["element","action",["selectTab","additional"],[],["loc",[null,[12,152],[12,187]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[15,87],[15,96]]]],"media"],[],["loc",[null,[15,83],[15,105]]]],"is-active",""],[],["loc",[null,[15,62],[15,122]]]]]]],
        ["element","action",["selectTab","media"],[],["loc",[null,[15,142],[15,172]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[20,60],[20,69]]]],"general"],[],["loc",[null,[20,56],[20,80]]]],"is-active",""],[],["loc",[null,[20,35],[20,97]]]]]]],
        ["content","item.foreignId",["loc",[null,[26,16],[26,34]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.supplierForeignId",["loc",[null,[32,30],[32,52]]]]],[],[]],"class","col l-20/24 gui-input","name","supportForeignId"],["loc",[null,[32,16],[32,108]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.partnerForeignId",["loc",[null,[38,28],[38,49]]]]],[],[]],"class","col l-20/24 gui-input","name","partnerForeignId"],["loc",[null,[38,14],[38,105]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.gtin",["loc",[null,[44,30],[44,39]]]]],[],[]],"class","col l-20/24 gui-input","name","gtin"],["loc",[null,[44,16],[44,83]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.name",["loc",[null,[53,30],[53,39]]]]],[],[]],"class","gui-input","name","productName"],["loc",[null,[53,16],[53,78]]]],
        ["inline","textarea",[],["name","productDescription","value",["subexpr","@mut",[["get","item.description",["loc",[null,[63,61],[63,77]]]]],[],[]],"class","gui-textarea"],["loc",[null,[63,18],[63,100]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceunits",["loc",[null,[76,28],[76,40]]]]],[],[]],"value",["subexpr","@mut",[["get","item.serviceunit",["loc",[null,[77,26],[77,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",false,"name","productUnit"],["loc",[null,[75,18],[82,20]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.packageQuantity",["loc",[null,[89,30],[89,50]]]]],[],[]],"class","gui-input","name","productPackageQuantity"],["loc",[null,[89,16],[89,100]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","taxes",["loc",[null,[97,26],[97,31]]]]],[],[]],"value",["subexpr","@mut",[["get","item.tax",["loc",[null,[98,24],[98,32]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",false,"name","productTax"],["loc",[null,[96,16],[103,18]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.salePriceGross",["loc",[null,[110,28],[110,47]]]]],[],[]],"class","col l-15/24 gui-input price","name","salePriceGross"],["loc",[null,[110,14],[110,107]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[121,27],[121,39]]]]],["content",["subexpr","@mut",[["get","categories",["loc",[null,[122,35],[122,45]]]]],[],[]],"name","product-category","optionValuePath","content.id","optionLabelPath","content.hierarchy","selection",["subexpr","@mut",[["get","item.category",["loc",[null,[126,37],[126,50]]]]],[],[]]],["loc",[null,[121,20],[127,22]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.partnerCategory",["loc",[null,[136,30],[136,50]]]]],[],[]],"class","gui-input","name","partnerCategory"],["loc",[null,[136,16],[136,93]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.partnerSubcategory",["loc",[null,[142,30],[142,53]]]]],[],[]],"class","gui-input","name","partnerSubcategory"],["loc",[null,[142,16],[142,99]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[147,60],[147,69]]]],"additional"],[],["loc",[null,[147,56],[147,83]]]],"is-active",""],[],["loc",[null,[147,35],[147,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.ingredients",["loc",[null,[153,30],[153,46]]]]],[],[]],"class","gui-input","name","productIngredients"],["loc",[null,[153,16],[153,92]]]],
        ["inline","input",[],["type","checkbox","name","productNarcotic","id","productNarcotic","checked",["subexpr","@mut",[["get","item.narcotic",["loc",[null,[160,94],[160,107]]]]],[],[]]],["loc",[null,[160,18],[160,109]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.slopingInfo",["loc",[null,[169,30],[169,46]]]]],[],[]],"class","gui-input","name","productSlopingInfo"],["loc",[null,[169,16],[169,92]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.domainInfo",["loc",[null,[175,30],[175,45]]]]],[],[]],"class","gui-input","name","productDomainInfo"],["loc",[null,[175,16],[175,90]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.targetRace",["loc",[null,[181,30],[181,45]]]]],[],[]],"class","gui-input","name","productTargetRace"],["loc",[null,[181,16],[181,90]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.licenceNumber",["loc",[null,[187,30],[187,48]]]]],[],[]],"class","gui-input","name","productLicenceNumber"],["loc",[null,[187,16],[187,96]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.atcCode",["loc",[null,[193,30],[193,42]]]]],[],[]],"class","gui-input","name","productAct"],["loc",[null,[193,16],[193,80]]]],
        ["inline","textarea",[],["name","productAtcDescription","value",["subexpr","@mut",[["get","item.atcCodeDescription",["loc",[null,[203,64],[203,87]]]]],[],[]],"class","gui-textarea"],["loc",[null,[203,18],[203,110]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[211,60],[211,69]]]],"media"],[],["loc",[null,[211,56],[211,78]]]],"is-active",""],[],["loc",[null,[211,35],[211,95]]]]]]],
        ["inline","radio-button",[],["value","url","name","imageSource","radioId","imageUrl","groupValue",["subexpr","@mut",[["get","item.imageSource",["loc",[null,[224,31],[224,47]]]]],[],[]]],["loc",[null,[220,18],[225,20]]]],
        ["inline","radio-button",[],["value","supplier","name","imageSource","radioId","supplierLogo","groupValue",["subexpr","@mut",[["get","item.imageSource",["loc",[null,[233,31],[233,47]]]]],[],[]]],["loc",[null,[229,18],[234,20]]]],
        ["inline","radio-button",[],["value","upload","name","imageSource","radioId","upload","groupValue",["subexpr","@mut",[["get","item.imageSource",["loc",[null,[242,31],[242,47]]]]],[],[]]],["loc",[null,[238,18],[243,20]]]],
        ["block","if",[["subexpr","eq",[["get","item.imageSource",["loc",[null,[248,22],[248,38]]]],"upload"],[],["loc",[null,[248,18],[248,48]]]]],[],0,null,["loc",[null,[248,12],[267,19]]]],
        ["block","if",[["get","item.pictureUrl",["loc",[null,[270,18],[270,33]]]]],[],1,2,["loc",[null,[270,12],[274,19]]]],
        ["block","if",[["subexpr","eq",[["get","item.imageSource",["loc",[null,[276,22],[276,38]]]],"url"],[],["loc",[null,[276,18],[276,45]]]]],[],3,null,["loc",[null,[276,12],[283,19]]]],
        ["inline","radio-button",[],["value","url","name","infodocSource","radioId","infodocUrl","groupValue",["subexpr","@mut",[["get","item.infodocSource",["loc",[null,[296,33],[296,51]]]]],[],[]]],["loc",[null,[292,20],[297,22]]]],
        ["inline","radio-button",[],["value","upload","name","infodocSource","radioId","infodocUpload","groupValue",["subexpr","@mut",[["get","item.infodocSource",["loc",[null,[305,33],[305,51]]]]],[],[]]],["loc",[null,[301,20],[306,22]]]],
        ["block","if",[["subexpr","eq",[["get","item.infodocSource",["loc",[null,[311,24],[311,42]]]],"upload"],[],["loc",[null,[311,20],[311,52]]]]],[],4,null,["loc",[null,[311,14],[321,21]]]],
        ["block","if",[["subexpr","eq",[["get","item.infodocSource",["loc",[null,[322,24],[322,42]]]],"url"],[],["loc",[null,[322,20],[322,49]]]]],[],5,null,["loc",[null,[322,14],[329,21]]]],
        ["inline","button-save",[],["size","40","color","#ffffff","content","Speichern"],["loc",[null,[338,10],[338,71]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }()));

});
define('jason-frontend/templates/components/button-edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"width","17");
        dom.setAttribute(el1,"height","16");
        dom.setAttribute(el1,"viewBox","0 0 17 16");
        dom.setAttribute(el1,"fill","none");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2,"d","M15.536 2.788 13.238.49a1.598 1.598 0 0 0-2.306 0l-1.73 1.722-7.494 7.494-1.145 3.459L.06 14.67a1.144 1.144 0 0 0-.056.276v.093a1.438 1.438 0 0 0 0 .188v-.005.096c.02.056.04.104.067.148l-.002-.003.048.088.096.128.064.072.137.104.072.048.208.088h.112a.801.801 0 0 0 .254 0h-.005.007c.11 0 .214-.02.31-.058l-.005.002 1.505-.505 3.459-1.145 7.494-7.494 1.713-1.698a1.598 1.598 0 0 0 0-2.305h-.001zm-13.61 11.73-.41-.408.73-2.178 1.857 1.857-2.178.729zm12.81-10.273-.873.881L10.9 2.212l.88-.872a.406.406 0 0 1 .298-.128h.01c.116 0 .22.049.293.128l2.306 2.298a.43.43 0 0 1 0 .609l.047-.002z");
        dom.setAttribute(el2,"fill","#fff");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});
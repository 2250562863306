define('jason-frontend/templates/purchases/detail', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/qr-dark.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("QR-Code drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element54);
          return morphs;
        },
        statements: [
          ["element","action",["printQr",["get","model.id",["loc",[null,[6,30],[6,38]]]]],[],["loc",[null,[6,11],[6,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 8
            },
            "end": {
              "line": 20,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element53 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element53, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[19,83],[19,101]]]]],[],["loc",[null,[19,67],[19,103]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 24,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 40
            },
            "end": {
              "line": 29,
              "column": 106
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","info-badge mr5 mb5 tag12");
          dom.setAttribute(el1,"style","position: absolute;margin-left: 10px");
          var el2 = dom.createTextNode("Inaktiv");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 31
            },
            "end": {
              "line": 31,
              "column": 64
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("Art.-Nr:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.rpaId",["loc",[null,[31,48],[31,59]]]],["get","-",["loc",[null,[31,60],[31,61]]]]],[],["loc",[null,[31,22],[31,64]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 72
            },
            "end": {
              "line": 32,
              "column": 58
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("PZN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.pzn",["loc",[null,[32,44],[32,53]]]],["get","-",["loc",[null,[32,54],[32,55]]]]],[],["loc",[null,[32,18],[32,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 66
            },
            "end": {
              "line": 33,
              "column": 59
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("EAN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.gtin",["loc",[null,[33,44],[33,54]]]],["get","-",["loc",[null,[33,55],[33,56]]]]],[],["loc",[null,[33,18],[33,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 10
            },
            "end": {
              "line": 43,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Verpackungseinheit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element52 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element52,0,0);
          morphs[1] = dom.createMorphAt(element52,2,2);
          return morphs;
        },
        statements: [
          ["content","purchaseProduct.packageQuantity",["loc",[null,[42,15],[42,50]]]],
          ["content","model.altUnit.name",["loc",[null,[42,51],[42,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 13
            },
            "end": {
              "line": 47,
              "column": 37
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Ja");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 37
            },
            "end": {
              "line": 47,
              "column": 49
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nein");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 13
            },
            "end": {
              "line": 50,
              "column": 70
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.licenceNumber",["loc",[null,[49,66],[49,85]]]],["get","-",["loc",[null,[50,66],[50,67]]]]],[],["loc",[null,[49,40],[50,70]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 70
            },
            "end": {
              "line": 51,
              "column": 45
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.individualLicencNumber",["loc",[null,[51,12],[51,40]]]],["get","-",["loc",[null,[51,41],[51,42]]]]],[],["loc",[null,[50,78],[51,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 16
                },
                "end": {
                  "line": 63,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label stock-label label-danger");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","stock.amount",["loc",[null,[61,20],[61,36]]]],
              ["content","stock.warehouseName",["loc",[null,[62,26],[62,49]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 16
                  },
                  "end": {
                    "line": 65,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label stock-label label-warning");
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["content","stock.amount",["loc",[null,[64,65],[64,81]]]],
                ["content","stock.warehouseName",["loc",[null,[64,88],[64,111]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 16
                    },
                    "end": {
                      "line": 67,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label stock-label label-success");
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","stock.amount",["loc",[null,[66,65],[66,81]]]],
                  ["content","stock.warehouseName",["loc",[null,[66,88],[66,111]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 16
                  },
                  "end": {
                    "line": 67,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","stock.amount",["loc",[null,[65,30],[65,42]]]],2],[],["loc",[null,[65,26],[65,46]]]]],[],0,null,["loc",[null,[65,16],[67,16]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 16
                },
                "end": {
                  "line": 67,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[63,31],[63,43]]]],2],[],["loc",[null,[63,26],[63,47]]]]],[],0,1,["loc",[null,[63,16],[67,16]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 12
              },
              "end": {
                "line": 69,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[59,27],[59,39]]]],0],[],["loc",[null,[59,22],[59,42]]]]],[],0,1,["loc",[null,[59,16],[67,23]]]]
          ],
          locals: ["stock"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 10
            },
            "end": {
              "line": 70,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10");
          var el2 = dom.createTextNode("Lagerstand");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.stockLevels",["loc",[null,[57,20],[57,37]]]]],[],0,null,["loc",[null,[57,12],[69,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 30
              },
              "end": {
                "line": 74,
                "column": 82
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-very-small");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","tag.name",["loc",[null,[74,62],[74,74]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 10
            },
            "end": {
              "line": 75,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10 mt20");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","tags");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.systemTags",["loc",[null,[73,38],[73,54]]]]],[],0,null,["loc",[null,[73,30],[74,91]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","tabs__label");
          dom.setAttribute(el1,"href","#tab-5");
          var el2 = dom.createTextNode("Verkauf");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element51 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element51);
          return morphs;
        },
        statements: [
          ["element","action",["gotoSale",["get","model.id",["loc",[null,[86,63],[86,71]]]]],[],["loc",[null,[86,43],[86,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 89,
              "column": 4
            },
            "end": {
              "line": 91,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-3");
          var el2 = dom.createTextNode("Bestellhistorie");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element50 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element50, 'class');
          morphs[1] = dom.createElementMorph(element50);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[90,54],[90,63]]]],"history"],[],["loc",[null,[90,50],[90,74]]]],"is-active",""],[],["loc",[null,[90,29],[90,91]]]]]]],
          ["element","action",["selectTab","history"],[],["loc",[null,[90,107],[90,139]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 92,
              "column": 4
            },
            "end": {
              "line": 97,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-6");
          var el2 = dom.createTextNode("Nachbestellung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-4");
          var el2 = dom.createTextNode("Lager");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element48 = dom.childAt(fragment, [1]);
          var element49 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element48, 'class');
          morphs[1] = dom.createElementMorph(element48);
          morphs[2] = dom.createAttrMorph(element49, 'class');
          morphs[3] = dom.createElementMorph(element49);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[93,53],[93,62]]]],"reorder"],[],["loc",[null,[93,49],[93,73]]]],"is-active",""],[],["loc",[null,[93,28],[93,90]]]]]]],
          ["element","action",["selectTab","reorder"],[],["loc",[null,[93,106],[94,31]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[95,53],[95,62]]]],"inventory"],[],["loc",[null,[95,49],[95,75]]]],"is-active",""],[],["loc",[null,[95,28],[95,92]]]]]]],
          ["element","action",["selectTab","inventory"],[],["loc",[null,[95,108],[96,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 4
            },
            "end": {
              "line": 101,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-debug");
          var el2 = dom.createTextNode("Debug");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element47 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element47, 'class');
          morphs[1] = dom.createElementMorph(element47);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[99,53],[99,62]]]],"debug"],[],["loc",[null,[99,49],[99,71]]]],"is-active",""],[],["loc",[null,[99,28],[99,88]]]]]]],
          ["element","action",["selectTab","debug"],[],["loc",[null,[99,108],[100,29]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 15
            },
            "end": {
              "line": 114,
              "column": 106
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/icon-check.svg");
          dom.setAttribute(el1,"style","height: 24px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 106
            },
            "end": {
              "line": 114,
              "column": 115
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 123
            },
            "end": {
              "line": 114,
              "column": 176
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(case ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.richterCase",["loc",[null,[114,154],[114,175]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 26
                  },
                  "end": {
                    "line": 131,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge top-offer small cursor");
                var el2 = dom.createTextNode("Top\n                              Angebot");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element40 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element40);
                return morphs;
              },
              statements: [
                ["element","action",["openActionInfos",["get","entry",["loc",[null,[129,104],[129,109]]]]],[],["loc",[null,[129,77],[129,111]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 131,
                      "column": 26
                    },
                    "end": {
                      "line": 134,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge offer small cursor");
                  var el2 = dom.createTextNode("Angebot");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element39 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element39);
                  return morphs;
                },
                statements: [
                  ["element","action",["openActionInfos",["get","entry",["loc",[null,[133,82],[133,87]]]]],[],["loc",[null,[132,73],[133,89]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 134,
                        "column": 26
                      },
                      "end": {
                        "line": 136,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("label");
                    dom.setAttribute(el1,"class","info-badge cashback small");
                    var el2 = dom.createTextNode("Cashback");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 136,
                          "column": 26
                        },
                        "end": {
                          "line": 138,
                          "column": 26
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("label");
                      dom.setAttribute(el1,"class","info-badge small");
                      var el2 = dom.createTextNode("Ihr eLord Preis");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                          ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 136,
                        "column": 26
                      },
                      "end": {
                        "line": 138,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","entry.richterInfo",["loc",[null,[136,36],[136,53]]]]],[],0,null,["loc",[null,[136,26],[138,26]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 134,
                      "column": 26
                    },
                    "end": {
                      "line": 138,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","entry.cashbackActive",["loc",[null,[134,36],[134,56]]]]],[],0,1,["loc",[null,[134,26],[138,26]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 131,
                    "column": 26
                  },
                  "end": {
                    "line": 138,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.promotionActive",["loc",[null,[131,36],[131,57]]]]],[],0,1,["loc",[null,[131,26],[138,26]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 142,
                    "column": 26
                  },
                  "end": {
                    "line": 148,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small delivery-info is-warning");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","inline-icon");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                  Nicht verfügbar\n                              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","icon-delivery",[],["size","26","content","","color","#d15444","strokeColor","#d15444"],["loc",[null,[143,28],[143,104]]]],
                ["inline","icon-info",[],["color","#d15444","size","16"],["loc",[null,[145,58],[145,97]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 151,
                        "column": 69
                      },
                      "end": {
                        "line": 152,
                        "column": 72
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.deliveryInfo",["loc",[null,[152,50],[152,72]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 152,
                        "column": 72
                      },
                      "end": {
                        "line": 152,
                        "column": 89
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Lieferbar");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 28
                    },
                    "end": {
                      "line": 153,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small delivery-info is-success");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","icon-delivery",[],["size","26","content","","color","#429321","strokeColor","#3B6182"],["loc",[null,[150,30],[150,106]]]],
                  ["block","if",[["get","entry.deliveryInfo",["loc",[null,[152,30],[152,48]]]]],[],0,1,["loc",[null,[151,69],[152,96]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 153,
                        "column": 28
                      },
                      "end": {
                        "line": 156,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small delivery-info is-orange");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-delivery",[],["size","26","content","","color","#F9B066","strokeColor","#F9B066"],["loc",[null,[154,30],[154,107]]]],
                    ["content","entry.deliveryInfo",["loc",[null,[155,68],[155,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 161,
                          "column": 34
                        },
                        "end": {
                          "line": 161,
                          "column": 82
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","entry.deliveryInfo",["loc",[null,[161,60],[161,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 161,
                            "column": 90
                          },
                          "end": {
                            "line": 162,
                            "column": 78
                          }
                        },
                        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n                                    Lieferbar");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("br");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("ab ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["content","entry.deliverableFrom",["loc",[null,[162,53],[162,78]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 162,
                            "column": 78
                          },
                          "end": {
                            "line": 163,
                            "column": 63
                          }
                        },
                        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n                                    Derzeit");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("br");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("nicht lieferbar");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 161,
                          "column": 82
                        },
                        "end": {
                          "line": 163,
                          "column": 70
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","entry.deliverableFrom",["loc",[null,[161,96],[161,117]]]]],[],0,1,["loc",[null,[161,90],[163,70]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 156,
                        "column": 28
                      },
                      "end": {
                        "line": 166,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small delivery-info is-warning");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","inline-icon");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","cursor");
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element37 = dom.childAt(fragment, [3]);
                    var element38 = dom.childAt(element37, [3]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                    morphs[2] = dom.createElementMorph(element38);
                    morphs[3] = dom.createMorphAt(element38,1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-delivery",[],["size","26","content","","color","#d15444","strokeColor","#d15444"],["loc",[null,[157,30],[157,106]]]],
                    ["inline","icon-info",[],["color","#d15444","size","16","content",""],["loc",[null,[159,58],[159,108]]]],
                    ["element","action",["openDeliveryInfos",["get","entry",["loc",[null,[160,79],[160,84]]]]],[],["loc",[null,[160,50],[160,86]]]],
                    ["block","if",[["get","entry.deliveryInfo",["loc",[null,[161,40],[161,58]]]]],[],0,1,["loc",[null,[161,34],[163,77]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 153,
                      "column": 28
                    },
                    "end": {
                      "line": 166,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","entry.deliveryStatus.id",["loc",[null,[153,42],[153,65]]]],"maydeliverable"],[],["loc",[null,[153,38],[153,83]]]]],[],0,1,["loc",[null,[153,28],[166,28]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 148,
                    "column": 26
                  },
                  "end": {
                    "line": 167,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","entry.deliveryStatus.id",["loc",[null,[149,38],[149,61]]]],"deliverable"],[],["loc",[null,[149,34],[149,76]]]]],[],0,1,["loc",[null,[149,28],[166,35]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 173,
                    "column": 26
                  },
                  "end": {
                    "line": 174,
                    "column": 131
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small price-devided block");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" / VE)");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.jasonPriceDivided",["loc",[null,[174,93],[174,116]]]]],[],[]]],["loc",[null,[174,69],[174,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 179,
                    "column": 28
                  },
                  "end": {
                    "line": 182,
                    "column": 28
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","price-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["classNames","inline-block","value",["subexpr","@mut",[["get","entry.jasonPrice",["loc",[null,[181,80],[181,96]]]]],[],[]]],["loc",[null,[180,56],[181,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 185,
                      "column": 99
                    },
                    "end": {
                      "line": 187,
                      "column": 82
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n                                (-");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("%)");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-number-german",[["get","entry.savingInPercent",["loc",[null,[187,57],[187,78]]]]],[],["loc",[null,[187,34],[187,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 183,
                    "column": 28
                  },
                  "end": {
                    "line": 187,
                    "column": 96
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small price-devided");
                var el2 = dom.createTextNode("statt ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element36 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element36,1,1);
                morphs[1] = dom.createMorphAt(element36,3,3);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["classNames","inline-block u-line-through","value",["subexpr","@mut",[["get","entry.retailPrice",["loc",[null,[185,79],[185,96]]]]],[],[]]],["loc",[null,[184,70],[185,98]]]],
                ["block","if",[["subexpr","gt",[["get","entry.reduction",["loc",[null,[186,36],[186,51]]]],0],[],["loc",[null,[186,32],[186,54]]]]],[],0,null,["loc",[null,[185,99],[187,89]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 192,
                    "column": 26
                  },
                  "end": {
                    "line": 194,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","entry.selectedQty",["loc",[null,[193,58],[193,75]]]]],[],[]],"id",["subexpr","@mut",[["get","entry.id",["loc",[null,[193,79],[193,87]]]]],[],[]]],["loc",[null,[193,28],[193,89]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 202,
                      "column": 28
                    },
                    "end": {
                      "line": 210,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp");
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element33,1,1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element33, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","entry",["loc",[null,[204,110],[204,115]]]]],[],[]],"confirmed","delete","text","Wollen Sie diese Zeile wirklich löschen?"],["loc",[null,[204,32],[206,110]]]],
                  ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","entry",["loc",[null,[207,63],[207,68]]]]],[],[]],"confirmed","deletePriceRow","textToShow","Löschen","text","Wollen Sie diese Zeile wirklich löschen?"],["loc",[null,[207,38],[208,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 195,
                    "column": 26
                  },
                  "end": {
                    "line": 212,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","ms-20");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","icon-button icon-button--success list-action-square exp");
                var el3 = dom.createTextNode("\n                              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createTextNode("Bearbeiten");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var element35 = dom.childAt(element34, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element35);
                morphs[1] = dom.createMorphAt(element35,1,1);
                morphs[2] = dom.createMorphAt(element34,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["edit",["get","model",["loc",[null,[197,111],[197,116]]]],["get","entry.id",["loc",[null,[198,104],[198,112]]]],["get","order.id",["loc",[null,[199,104],[199,112]]]]],[],["loc",[null,[197,95],[199,114]]]],
                ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[200,30],[200,96]]]],
                ["block","if",[["subexpr","gt",[["get","model.activeRowSize",["loc",[null,[202,38],[202,57]]]],1],[],["loc",[null,[202,34],[202,60]]]]],[],0,null,["loc",[null,[202,28],[210,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child9 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 213,
                      "column": 28
                    },
                    "end": {
                      "line": 219,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ms-20");
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"class","icon-button icon-button--success list-action-square");
                  dom.setAttribute(el2,"style","padding:7px;");
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3,"src","assets/images/icons/rework/cart.svg");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element32 = dom.childAt(fragment, [1, 1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element32);
                  return morphs;
                },
                statements: [
                  ["element","action",["checkLaboklinPanel",["get","model",["loc",[null,[216,53],[216,58]]]],["get","order.id",["loc",[null,[216,59],[216,67]]]],["get","entry",["loc",[null,[216,68],[216,73]]]],["get","entry.supplier.id",["loc",[null,[216,74],[216,91]]]],["get","model.name",["loc",[null,[216,92],[216,102]]]]],[],["loc",[null,[215,115],[216,104]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 212,
                    "column": 26
                  },
                  "end": {
                    "line": 220,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.richterNav",["loc",[null,[213,44],[213,60]]]]],[],["loc",[null,[213,39],[213,61]]]],["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[213,66],[213,82]]]],["get","entry.free",["loc",[null,[213,83],[213,93]]]]],[],["loc",[null,[213,62],[213,94]]]]],[],["loc",[null,[213,34],[213,95]]]]],[],0,null,["loc",[null,[213,28],[219,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child10 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 224,
                    "column": 22
                  },
                  "end": {
                    "line": 230,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-24/24 small__cell");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","small pl35");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
                return morphs;
              },
              statements: [
                ["content","entry.richterInfo",["loc",[null,[227,53],[227,76]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child11 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 232,
                    "column": 20
                  },
                  "end": {
                    "line": 238,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-24/24 small__cell");
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","small is-highlighted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element31 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element31, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element31, [1, 1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["table__row second ",["subexpr","get-even",[["get","index",["loc",[null,[233,63],[233,68]]]]],[],["loc",[null,[233,52],[233,70]]]]]]],
                ["content","info.partnerText",["loc",[null,[235,61],[235,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 18
                },
                "end": {
                  "line": 239,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class"," sellingUnitContainer");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","grid align-items-center");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","supplier-info");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"class","mw130");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col label-bar l-4/24@desk l-10/24 pt10");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-8/24 l-4/24@desk availability");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-4/24@desk ve l-6/24");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" VE");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","prices col l-4/24@desk l-12/24");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-11/24@desk l-12/24 actions");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","d-flex align-items-center justify-content-end");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element41 = dom.childAt(fragment, [1]);
              var element42 = dom.childAt(element41, [1]);
              var element43 = dom.childAt(element42, [1, 1]);
              var element44 = dom.childAt(element42, [7, 1]);
              var element45 = dom.childAt(element42, [9, 1]);
              var element46 = dom.childAt(element42, [11, 1]);
              var morphs = new Array(11);
              morphs[0] = dom.createAttrMorph(element43, 'src');
              morphs[1] = dom.createMorphAt(dom.childAt(element42, [3]),1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element42, [5, 1]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element44, [1]),0,0);
              morphs[4] = dom.createMorphAt(element44,3,3);
              morphs[5] = dom.createMorphAt(element45,1,1);
              morphs[6] = dom.createMorphAt(element45,2,2);
              morphs[7] = dom.createMorphAt(element46,1,1);
              morphs[8] = dom.createMorphAt(element46,2,2);
              morphs[9] = dom.createMorphAt(element41,3,3);
              morphs[10] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/suppliers/detail/",["get","supplierEntry.supplier.id",["loc",[null,[125,83],[125,108]]]],".png"]]],
              ["block","if",[["get","entry.topPromotion",["loc",[null,[128,32],[128,50]]]]],[],0,1,["loc",[null,[128,26],[138,33]]]],
              ["block","if",[["get","entry.richterNav",["loc",[null,[142,32],[142,48]]]]],[],2,3,["loc",[null,[142,26],[167,33]]]],
              ["content","entry.sellingAmount",["loc",[null,[172,32],[172,55]]]],
              ["block","if",[["get","entry.jasonPrice",["loc",[null,[173,32],[173,48]]]]],[],4,null,["loc",[null,[173,26],[174,138]]]],
              ["block","if",[["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[179,38],[179,54]]]],["get","entry.free",["loc",[null,[179,55],[179,65]]]]],[],["loc",[null,[179,34],[179,66]]]]],[],5,null,["loc",[null,[179,28],[182,35]]]],
              ["block","if",[["subexpr","and",[["subexpr","gt",[["get","entry.retailPrice",["loc",[null,[183,43],[183,60]]]],["get","entry.jasonPrice",["loc",[null,[183,61],[183,77]]]]],[],["loc",[null,[183,39],[183,78]]]],["get","entry.retailPrice",["loc",[null,[183,79],[183,96]]]],["get","entry.jasonPrice",["loc",[null,[183,97],[183,113]]]]],[],["loc",[null,[183,34],[183,114]]]]],[],6,null,["loc",[null,[183,28],[187,103]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.richterNav",["loc",[null,[192,42],[192,58]]]]],[],["loc",[null,[192,37],[192,59]]]],["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[192,65],[192,78]]]]],[],["loc",[null,[192,60],[192,79]]]],["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[192,84],[192,100]]]],["get","entry.free",["loc",[null,[192,101],[192,111]]]]],[],["loc",[null,[192,80],[192,112]]]]],[],["loc",[null,[192,32],[192,113]]]]],[],7,null,["loc",[null,[192,26],[194,33]]]],
              ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[195,36],[195,54]]]],["subexpr","is-supplier",[],[],["loc",[null,[195,55],[195,68]]]]],[],["loc",[null,[195,32],[195,69]]]]],[],8,9,["loc",[null,[195,26],[220,33]]]],
              ["block","if",[["get","entry.richterInfo",["loc",[null,[224,28],[224,45]]]]],[],10,null,["loc",[null,[224,22],[230,29]]]],
              ["block","if",[["get","info.partnerText",["loc",[null,[232,26],[232,42]]]]],[],11,null,["loc",[null,[232,20],[238,27]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 16
              },
              "end": {
                "line": 240,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","entry.active",["loc",[null,[121,28],[121,40]]]],["subexpr","or",[["subexpr","is-supplier",[["get","is-jason-network",["loc",[null,[121,58],[121,74]]]]],[],["loc",[null,[121,45],[121,75]]]]],[],["loc",[null,[121,41],[121,76]]]]],[],["loc",[null,[121,24],[121,77]]]]],[],0,null,["loc",[null,[121,18],[239,25]]]]
          ],
          locals: ["entry"],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 242,
                "column": 16
              },
              "end": {
                "line": 244,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","p20");
            var el2 = dom.createTextNode("Keine Lieferanteninformationen verfügbar");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 245,
                "column": 16
              },
              "end": {
                "line": 247,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","p20");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","is-highlighted");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Achtung:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Um Ihre individuellen Preise der Richter Pharma AG zu erhalten, müssen Sie Ihre ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","cursor is-highlighted");
            var el3 = dom.createTextNode("eLord Zugangsdaten");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" hinterlegen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [1, 2]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element30);
            return morphs;
          },
          statements: [
            ["element","action",["toMandant"],[],["loc",[null,[246,170],[246,192]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 8
            },
            "end": {
              "line": 248,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sellingUnitContainers");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","supplierEntry.entries",["loc",[null,[120,24],[120,45]]]]],[],0,null,["loc",[null,[120,16],[240,25]]]],
          ["block","if",[["subexpr","not",[["subexpr","list-not-empty",[["get","model.purchaseSupplierInfos",["loc",[null,[242,43],[242,70]]]]],[],["loc",[null,[242,27],[242,71]]]]],[],["loc",[null,[242,22],[242,72]]]]],[],1,null,["loc",[null,[242,16],[244,23]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[245,32],[245,45]]]]],[],["loc",[null,[245,27],[245,46]]]],["get","model.elordCredentialsRequired",["loc",[null,[245,47],[245,77]]]]],[],["loc",[null,[245,22],[245,78]]]]],[],2,null,["loc",[null,[245,16],[247,23]]]]
        ],
        locals: ["supplierEntry"],
        templates: [child0, child1, child2]
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 255,
              "column": 94
            },
            "end": {
              "line": 255,
              "column": 156
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("> ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","model.partnerSubcategory",["loc",[null,[255,128],[255,156]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 256,
              "column": 14
            },
            "end": {
              "line": 258,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("PZN:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),2,2);
          return morphs;
        },
        statements: [
          ["content","model.foreignId",["loc",[null,[257,50],[257,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 259,
              "column": 14
            },
            "end": {
              "line": 261,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Laboklin-Nr:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),2,2);
          return morphs;
        },
        statements: [
          ["content","model.partnerNumber",["loc",[null,[260,60],[260,83]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 269,
              "column": 14
            },
            "end": {
              "line": 272,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (EU): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberEu",["loc",[null,[271,19],[271,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 273,
              "column": 14
            },
            "end": {
              "line": 276,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Ages): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberAges",["loc",[null,[275,19],[275,46]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 277,
              "column": 14
            },
            "end": {
              "line": 280,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Apothekerverlag): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberApo",["loc",[null,[279,19],[279,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 281,
              "column": 14
            },
            "end": {
              "line": 284,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Dosierungsinformationen / Analytische Bestandteile");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.slopingInfo",["loc",[null,[283,27],[283,44]]]],"Link"],[],["loc",[null,[283,19],[283,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child29 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 286,
              "column": 14
            },
            "end": {
              "line": 289,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Fachinformationen / Zusammensetzung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.domainInfo",["loc",[null,[288,27],[288,43]]]],"Link",["get","model.supplier.id",["loc",[null,[288,51],[288,68]]]]],[],["loc",[null,[288,19],[288,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 291,
              "column": 14
            },
            "end": {
              "line": 294,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Infos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element29,0,0);
          morphs[1] = dom.createUnsafeMorphAt(element29,1,1);
          return morphs;
        },
        statements: [
          ["content","model.info",["loc",[null,[293,19],[293,33]]]],
          ["inline","link",[["get","model.detailUrl",["loc",[null,[293,41],[293,56]]]],"Download"],[],["loc",[null,[293,33],[293,70]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child31 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 295,
              "column": 14
            },
            "end": {
              "line": 301,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Fachinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Gebrauchsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [3, 0]);
          var element28 = dom.childAt(fragment, [7, 0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element27, 'href');
          morphs[1] = dom.createAttrMorph(element28, 'href');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[297,96],[297,115]]]],"&type=DOTC_FACH_INFO"]]],
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[300,96],[300,115]]]],"&type=DOTC_GEBR_INFO"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child32 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 303,
              "column": 14
            },
            "end": {
              "line": 306,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zielspezies");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.targetRace",["loc",[null,[305,19],[305,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child33 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 307,
              "column": 14
            },
            "end": {
              "line": 357,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","mt20 small");
          var el2 = dom.createTextNode("Bei Fragen wenden Sie sich bitte an Ihren Ceva Außendienstmitarbeiter!");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table table--small pr30");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Harald Valenta");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 9285225");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Silvia Harra");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 4104367");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Thomas Stegfellner");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 4105260");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Nutztier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24 ");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Jochen Perr");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 7704648");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child34 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 364,
              "column": 14
            },
            "end": {
              "line": 370,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","textarea-wrapper");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","placeholder");
          var el3 = dom.createTextNode("Bestell-Notiz");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","model.historyNotice",["loc",[null,[366,44],[366,63]]]]],[],[]],"class","","name","historyNotice","placeholder",""],["loc",[null,[366,18],[367,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child35 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 372,
                "column": 16
              },
              "end": {
                "line": 374,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","model.historyNotice",["loc",[null,[373,18],[373,41]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 374,
                "column": 16
              },
              "end": {
                "line": 376,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  Derzeit keine Notiz hinterlegt\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 370,
              "column": 16
            },
            "end": {
              "line": 377,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","readOnly");
          var el2 = dom.createTextNode("Bestell-Notiz");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.historyNotice",["loc",[null,[372,22],[372,41]]]]],[],0,1,["loc",[null,[372,16],[376,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child36 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 400,
                  "column": 24
                },
                "end": {
                  "line": 400,
                  "column": 108
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.code",["loc",[null,[400,95],[400,108]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 403,
                  "column": 24
                },
                "end": {
                  "line": 405,
                  "column": 92
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"class","mw100");
              dom.setAttribute(el1,"style","max-height: 50px;");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element25, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/suppliers/small_",["get","item.supplier.id",["loc",[null,[405,68],[405,84]]]],".png"]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 419,
                  "column": 24
                },
                "end": {
                  "line": 421,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/view.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 394,
                "column": 18
              },
              "end": {
                "line": 424,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Datum");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Bestell-Nr");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Lieferant");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone table__cell--center l-8/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Menge");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone table__cell--center l-8/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","VE");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-3/24@desk");
            dom.setAttribute(el2,"data-label","Status");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element26, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element26, [5]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element26, [7, 1]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element26, [9, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element26, [11, 1]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element26, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[397,44],[397,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[397,30],[397,79]]]],
            ["block","link-to",["purchases.show",["get","item.orderId",["loc",[null,[400,52],[400,64]]]]],["title","Öffnen","class","link2"],0,null,["loc",[null,[400,24],[400,120]]]],
            ["block","link-to",["purchases.supplierdetails",["get","item.supplier.id",["loc",[null,[403,63],[403,79]]]]],["title","Anzeigen"],1,null,["loc",[null,[403,24],[405,104]]]],
            ["content","item.entryQuantity",["loc",[null,[409,30],[409,52]]]],
            ["content","item.packageQuantity",["loc",[null,[413,30],[413,54]]]],
            ["content","item.orderStatus",["loc",[null,[416,30],[416,50]]]],
            ["block","link-to",["purchases.show",["get","item",["loc",[null,[419,52],[419,56]]]]],["title","Anzeigen"],2,null,["loc",[null,[419,24],[421,36]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 380,
              "column": 10
            },
            "end": {
              "line": 428,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper-rework");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table table--large grid");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Bestell-Datum");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Bestell-Nr");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Lieferant");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24 table__cell--center");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Menge");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24 table__cell--center");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("VE");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Status");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-1/24");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","history",["loc",[null,[394,26],[394,33]]]]],[],0,null,["loc",[null,[394,18],[424,27]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child37 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 443,
              "column": 18
            },
            "end": {
              "line": 463,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Inventar-Nr");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Verfügbar");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Eingebucht");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element24, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element24, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element24, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element24, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element24, [9, 1]),0,0);
          return morphs;
        },
        statements: [
          ["content","item.status.name",["loc",[null,[446,30],[446,50]]]],
          ["content","item.id",["loc",[null,[450,30],[450,41]]]],
          ["content","item.flatStock",["loc",[null,[454,30],[454,48]]]],
          ["content","item.warehouseName",["loc",[null,[457,30],[457,52]]]],
          ["inline","time-format",[["get","item.created",["loc",[null,[460,44],[460,56]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[460,30],[460,79]]]]
        ],
        locals: ["item"],
        templates: []
      };
    }());
    var child38 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 482,
                "column": 28
              },
              "end": {
                "line": 482,
                "column": 79
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","reorder.warehouse.name",["loc",[null,[482,53],[482,79]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 482,
                "column": 79
              },
              "end": {
                "line": 482,
                "column": 97
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alle Lager");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 536,
                "column": 20
              },
              "end": {
                "line": 541,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"checked","checked");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element17,[],true); }
            var element18 = dom.childAt(element16, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element17, 'id');
            morphs[1] = dom.createElementMorph(element17);
            morphs[2] = dom.createAttrMorph(element18, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[538,49],[538,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[538,138],[538,145]]]]],["on","change"],["loc",[null,[538,110],[538,159]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[539,50],[539,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 541,
                "column": 20
              },
              "end": {
                "line": 546,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element13, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element14, 'id');
            morphs[1] = dom.createElementMorph(element14);
            morphs[2] = dom.createAttrMorph(element15, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[543,49],[543,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[543,120],[543,127]]]]],["on","change"],["loc",[null,[543,92],[543,141]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[544,50],[544,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 479,
              "column": 14
            },
            "end": {
              "line": 549,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Benachrichtigung");
          var el3 = dom.createTextNode("\n\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-infoMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Automatisch Nachbestellen bei");
          var el3 = dom.createTextNode("\n\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-reorderMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk pb5");
          dom.setAttribute(el2,"data-label","Nachbestellen von");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-priceLine");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","block mtm5");
          var el4 = dom.createTextNode("\n                      Einzelpreis: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(", Gesamtpreis: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [3]);
          var element21 = dom.childAt(element19, [5]);
          var element22 = dom.childAt(element19, [7]);
          var element23 = dom.childAt(element22, [5]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element19, [1, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element21, [1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element21, [3]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element22, [1]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element22, [3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element19, [9]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","reorder.deleted",["loc",[null,[480,60],[480,75]]]],"hidden",""],[],["loc",[null,[480,39],[480,89]]]]]]],
          ["block","if",[["get","reorder.warehouse",["loc",[null,[482,34],[482,51]]]]],[],0,1,["loc",[null,[482,28],[482,104]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.infoMinimalAmount",["loc",[null,[487,54],[487,79]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.infoSpinnerId",["loc",[null,[487,83],[487,104]]]]],[],[]]],["loc",[null,[487,24],[487,106]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[491,34],[491,46]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.infoMinimalServiceunit",["loc",[null,[492,32],[492,62]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-infoMinimalServiceunit"],["loc",[null,[490,24],[498,26]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderMinimalAmount",["loc",[null,[504,54],[504,82]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.reorderSpinnerId",["loc",[null,[504,86],[504,110]]]]],[],[]]],["loc",[null,[504,24],[504,112]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[507,32],[507,44]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderMinimalServiceunit",["loc",[null,[508,30],[508,63]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-reorderMinimalServiceunit"],["loc",[null,[506,22],[514,24]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderAmount",["loc",[null,[519,54],[519,75]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.amountSpinnerId",["loc",[null,[519,79],[519,102]]]]],[],[]]],["loc",[null,[519,24],[519,104]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","model.reorderPurchaseSupplierInfos",["loc",[null,[522,32],[522,66]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo",["loc",[null,[523,30],[523,65]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","title","allowClear",true,"placeholder","Lieferant & VE wählen","name","product-priceLine"],["loc",[null,[521,22],[529,24]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[532,62],[532,108]]]]],[],[]],"classNames","inline"],["loc",[null,[532,38],[532,130]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","multiply",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[532,206],[532,252]]]],["get","reorder.reorderAmount",["loc",[null,[532,253],[532,274]]]]],[],["loc",[null,[532,196],[532,275]]]]],["loc",[null,[532,152],[532,277]]]],
          ["block","if",[["get","reorder.active",["loc",[null,[536,26],[536,40]]]]],[],2,3,["loc",[null,[536,20],[546,27]]]]
        ],
        locals: ["reorder"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child39 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 585,
                "column": 6
              },
              "end": {
                "line": 587,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge private");
            var el2 = dom.createTextNode("Privat");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 587,
                  "column": 6
                },
                "end": {
                  "line": 589,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge lab");
              var el2 = dom.createTextNode("Labor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 589,
                  "column": 6
                },
                "end": {
                  "line": 591,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge practice");
              var el2 = dom.createTextNode("Praxis");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 587,
                "column": 6
              },
              "end": {
                "line": 591,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","order.laboklinOrder",["loc",[null,[587,16],[587,35]]]]],[],0,1,["loc",[null,[587,6],[591,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 593,
                "column": 4
              },
              "end": {
                "line": 603,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","basket-quickinfos");
            dom.setAttribute(el1,"class","grid");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-16/24");
            var el3 = dom.createTextNode("\n          Artikel ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","badge");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          Warenwert netto\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24 u-text--right");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),2,2);
            return morphs;
          },
          statements: [
            ["content","order.entryCount",["loc",[null,[596,38],[596,58]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","order.totalNet",["loc",[null,[600,39],[600,53]]]]],[],[]],"classNames","currency ml5"],["loc",[null,[600,15],[600,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 580,
              "column": 3
            },
            "end": {
              "line": 617,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","cartButtons");
          dom.setAttribute(el2,"class","col l-24/24");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-14/24");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context");
          var el5 = dom.createTextNode("Warenkorb wechseln");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-10/24");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context primary");
          var el5 = dom.createTextNode("zum Warenkorb");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","gotoCart");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el3,"style","width: 18px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" zum Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var element9 = dom.childAt(element6, [9]);
          var element10 = dom.childAt(element9, [1, 1]);
          var element11 = dom.childAt(element9, [3, 1]);
          var element12 = dom.childAt(element6, [13]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element7);
          morphs[1] = dom.createElementMorph(element8);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
          morphs[3] = dom.createMorphAt(element6,7,7);
          morphs[4] = dom.createElementMorph(element10);
          morphs[5] = dom.createElementMorph(element11);
          morphs[6] = dom.createMorphAt(dom.childAt(element6, [11]),1,1);
          morphs[7] = dom.createElementMorph(element12);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[582,9],[582,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[583,41],[583,49]]]]],[],["loc",[null,[583,23],[583,51]]]],
          ["block","if",[["get","order.privateOrder",["loc",[null,[585,12],[585,30]]]]],[],0,1,["loc",[null,[585,6],[591,13]]]],
          ["block","if",[["subexpr","gt",[["get","order.entryCount",["loc",[null,[593,14],[593,30]]]],0],[],["loc",[null,[593,10],[593,33]]]]],[],2,null,["loc",[null,[593,4],[603,11]]]],
          ["element","action",["gotoCarts"],[],["loc",[null,[606,16],[606,38]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[609,34],[609,42]]]]],[],["loc",[null,[609,14],[609,44]]]],
          ["inline","mini-cart",[],["model",["subexpr","@mut",[["get","order",["loc",[null,[613,24],[613,29]]]]],[],[]],"delete","deleteCartEntry","toCart","gotoCart","toProduct","toProduct","actionReceiver",["subexpr","@mut",[["get","minicart",["loc",[null,[613,110],[613,118]]]]],[],[]]],["loc",[null,[613,6],[613,120]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[615,43],[615,51]]]]],[],["loc",[null,[615,23],[615,53]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child40 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 618,
                "column": 2
              },
              "end": {
                "line": 622,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[620,63],[620,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 617,
              "column": 0
            },
            "end": {
              "line": 623,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[618,13],[618,26]]]]],[],["loc",[null,[618,8],[618,27]]]]],[],0,null,["loc",[null,[618,2],[622,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child41 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 654,
              "column": 4
            },
            "end": {
              "line": 661,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4,1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["editProduct",["get","model",["loc",[null,[655,52],[655,57]]]],["get","order.id",["loc",[null,[655,58],[655,66]]]]],[],["loc",[null,[655,29],[655,68]]]],
          ["inline","button-edit",[],["size","52","color","#FFFFFF","showStroke",false,"content","Produkt bearbeiten"],["loc",[null,[656,8],[656,96]]]],
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","model",["loc",[null,[659,42],[659,47]]]]],[],[]],"confirmed","deleteArticle","color","#FFFFFF","showStroke",false,"size",52,"content","Produkt und alle Preise löschen","text","Wollen Sie diesen Artikel wirklich löschen?"],["loc",[null,[659,8],[659,209]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child42 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 662,
              "column": 2
            },
            "end": {
              "line": 669,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--promotion");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["editPromotion",["get","model",["loc",[null,[663,75],[663,80]]]],0,["get","order.id",["loc",[null,[663,83],[663,91]]]]],[],["loc",[null,[663,50],[663,93]]]],
          ["inline","button-add-promotion",[],["content","Aktion hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[664,6],[664,101]]]],
          ["element","action",["edit",["get","model",["loc",[null,[666,64],[666,69]]]],0,["get","order.id",["loc",[null,[666,72],[666,80]]]]],[],["loc",[null,[666,48],[666,82]]]],
          ["inline","button-add-entry",[],["content","Weitere Artikelzeile hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[667,6],[667,111]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child43 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 670,
              "column": 2
            },
            "end": {
              "line": 674,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveHistory",["get","model",["loc",[null,[671,71],[671,76]]]]],[],["loc",[null,[671,48],[671,78]]]],
          ["inline","button-save",[],["content","Speichern","color","#FFFFFF","size","40","showStroke",false],["loc",[null,[672,6],[672,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child44 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 675,
              "column": 2
            },
            "end": {
              "line": 679,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveReorder",["get","model",["loc",[null,[676,71],[676,76]]]]],[],["loc",[null,[676,48],[676,78]]]],
          ["inline","button-save",[],["content","Speichern","color","#FFFFFF","size","40","showStroke",false],["loc",[null,[677,6],[677,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 695,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Produkt");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","purchaseDetailPage");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","card mb-0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-8/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","u-text--center mt10 pr30");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n     ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-16/24");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","sub-headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","product-description");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dataList grid");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Verpackungsmenge");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Kategorie");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Suchtgiftmittel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Zulassungsnummer");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-purchase");
        var el4 = dom.createTextNode("Einkauf");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-2");
        var el4 = dom.createTextNode("Artikelinformationen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","product-details");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs");
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs__body");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-debug");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Aktualisiert");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mt20");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Datenlieferant");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("RPA-Migration");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-purchase");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-1");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("MwSt:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" %");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"class","mt20");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("ATC-Code:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("ATC-Code Beschreibung:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Lieferanten-Kategorie:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-2");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","dataList");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createTextNode("Beschreibung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-3");
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-content");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-4");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper-rework");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid  table--small");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Status");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24 table__cell--center");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Inventar-Nr");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24 table__cell--center");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Verfügbar");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Lager");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Eingebucht");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-6");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper-rework");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--ve  table--small");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-3/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Lager");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-6/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Benachrichtigung");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" für Nachbestellungen bei");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-6/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Automatische Nachbestellung bei");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-7/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Nachbestellung von");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell  table__cell--right l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Aktiv");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card-content");
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Automatische Nachbestellung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class"," d-flex align-items-center");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","checkbox-custom checkbox-primary field-checkbox");
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","autosendReorder");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","checkbox-label");
        var el10 = dom.createTextNode("Warenkorb automatisch abschicken");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea-wrapper");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Nachbestellungs-Notiz");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n    });\n    $('aside.side-bar').css('min-height', $(window).height()-80);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(element55, [1]);
        var element57 = dom.childAt(fragment, [2]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element59, [1]);
        var element61 = dom.childAt(element59, [3]);
        var element62 = dom.childAt(element59, [5]);
        var element63 = dom.childAt(element62, [3]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element63, [3]);
        var element67 = dom.childAt(element57, [3]);
        var element68 = dom.childAt(element67, [1]);
        var element69 = dom.childAt(element67, [5]);
        var element70 = dom.childAt(element57, [5, 1, 1]);
        var element71 = dom.childAt(element70, [1]);
        var element72 = dom.childAt(element71, [7]);
        var element73 = dom.childAt(element71, [11]);
        var element74 = dom.childAt(element70, [3]);
        var element75 = dom.childAt(element70, [5]);
        var element76 = dom.childAt(element75, [1]);
        var element77 = dom.childAt(element76, [7]);
        var element78 = dom.childAt(element70, [7]);
        var element79 = dom.childAt(element78, [1]);
        var element80 = dom.childAt(element70, [9]);
        var element81 = dom.childAt(element70, [11]);
        var element82 = dom.childAt(element70, [13]);
        var element83 = dom.childAt(element82, [3]);
        var element84 = dom.childAt(fragment, [21]);
        var morphs = new Array(77);
        morphs[0] = dom.createAttrMorph(element55, 'class');
        morphs[1] = dom.createAttrMorph(element56, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element56, [3]),1,1);
        morphs[3] = dom.createAttrMorph(element57, 'class');
        morphs[4] = dom.createAttrMorph(element57, 'data-jac-avail');
        morphs[5] = dom.createMorphAt(dom.childAt(element58, [1, 1]),1,1);
        morphs[6] = dom.createMorphAt(element60,0,0);
        morphs[7] = dom.createMorphAt(element60,2,2);
        morphs[8] = dom.createMorphAt(element61,0,0);
        morphs[9] = dom.createMorphAt(element61,2,2);
        morphs[10] = dom.createMorphAt(element61,4,4);
        morphs[11] = dom.createUnsafeMorphAt(dom.childAt(element62, [1]),0,0);
        morphs[12] = dom.createMorphAt(element65,0,0);
        morphs[13] = dom.createMorphAt(element65,2,2);
        morphs[14] = dom.createMorphAt(element64,5,5);
        morphs[15] = dom.createMorphAt(dom.childAt(element64, [9]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element64, [13]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element64, [17]),0,0);
        morphs[18] = dom.createMorphAt(element66,1,1);
        morphs[19] = dom.createMorphAt(element66,2,2);
        morphs[20] = dom.createAttrMorph(element68, 'class');
        morphs[21] = dom.createElementMorph(element68);
        morphs[22] = dom.createMorphAt(element67,3,3);
        morphs[23] = dom.createAttrMorph(element69, 'class');
        morphs[24] = dom.createElementMorph(element69);
        morphs[25] = dom.createMorphAt(element67,7,7);
        morphs[26] = dom.createMorphAt(element67,8,8);
        morphs[27] = dom.createMorphAt(element67,9,9);
        morphs[28] = dom.createAttrMorph(element71, 'class');
        morphs[29] = dom.createMorphAt(dom.childAt(element71, [3]),0,0);
        morphs[30] = dom.createMorphAt(element72,0,0);
        morphs[31] = dom.createMorphAt(element72,2,2);
        morphs[32] = dom.createMorphAt(element73,0,0);
        morphs[33] = dom.createMorphAt(element73,2,2);
        morphs[34] = dom.createAttrMorph(element74, 'class');
        morphs[35] = dom.createMorphAt(element74,1,1);
        morphs[36] = dom.createAttrMorph(element75, 'class');
        morphs[37] = dom.createMorphAt(dom.childAt(element76, [1]),2,2);
        morphs[38] = dom.createMorphAt(dom.childAt(element76, [3]),2,2);
        morphs[39] = dom.createMorphAt(dom.childAt(element76, [5]),2,2);
        morphs[40] = dom.createMorphAt(element77,2,2);
        morphs[41] = dom.createMorphAt(element77,4,4);
        morphs[42] = dom.createMorphAt(element76,9,9);
        morphs[43] = dom.createMorphAt(element76,10,10);
        morphs[44] = dom.createAttrMorph(element78, 'class');
        morphs[45] = dom.createUnsafeMorphAt(dom.childAt(element79, [3]),0,0);
        morphs[46] = dom.createMorphAt(element79,5,5);
        morphs[47] = dom.createMorphAt(element79,6,6);
        morphs[48] = dom.createMorphAt(element79,7,7);
        morphs[49] = dom.createMorphAt(element79,8,8);
        morphs[50] = dom.createMorphAt(element79,10,10);
        morphs[51] = dom.createMorphAt(element79,12,12);
        morphs[52] = dom.createMorphAt(element79,13,13);
        morphs[53] = dom.createMorphAt(element79,15,15);
        morphs[54] = dom.createMorphAt(element79,16,16);
        morphs[55] = dom.createAttrMorph(element80, 'class');
        morphs[56] = dom.createMorphAt(dom.childAt(element80, [1, 1]),1,1);
        morphs[57] = dom.createMorphAt(element80,3,3);
        morphs[58] = dom.createAttrMorph(element81, 'class');
        morphs[59] = dom.createMorphAt(dom.childAt(element81, [1, 1, 3]),1,1);
        morphs[60] = dom.createAttrMorph(element82, 'class');
        morphs[61] = dom.createMorphAt(dom.childAt(element82, [1, 1, 3]),1,1);
        morphs[62] = dom.createMorphAt(dom.childAt(element83, [3, 1, 1]),1,1);
        morphs[63] = dom.createMorphAt(dom.childAt(element83, [5, 1]),1,1);
        morphs[64] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[65] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[66] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[67] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[68] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[69] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[70] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[71] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[72] = dom.createMorphAt(dom.childAt(element84, [1]),1,1);
        morphs[73] = dom.createMorphAt(element84,4,4);
        morphs[74] = dom.createMorphAt(element84,5,5);
        morphs[75] = dom.createMorphAt(element84,6,6);
        morphs[76] = dom.createMorphAt(element84,7,7);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar  ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,48],[1,61]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,27],[1,92]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[2,72],[2,85]]]]],[],["loc",[null,[2,67],[2,86]]]],"24","18"],[],["loc",[null,[2,46],[2,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[5,17],[5,30]]]]],[],["loc",[null,[5,12],[5,31]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["attribute","class",["concat",["rework-content col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[14,87],[14,100]]]]],[],["loc",[null,[14,82],[14,101]]]],"24","18"],[],["loc",[null,[14,61],[14,113]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[14,172],[14,185]]]],"sidebar-active",""],[],["loc",[null,[14,151],[14,207]]]]]]],
        ["attribute","data-jac-avail",["concat",[["get","model.hasJacobyRow",["loc",[null,[14,227],[14,245]]]]]]],
        ["block","if",[["get","model.picture",["loc",[null,[18,14],[18,27]]]]],[],1,2,["loc",[null,[18,8],[24,15]]]],
        ["content","model.name",["loc",[null,[28,25],[28,39]]]],
        ["block","if",[["get","model.deleted",["loc",[null,[28,46],[28,59]]]]],[],3,null,["loc",[null,[28,40],[29,113]]]],
        ["block","if",[["get","model.rpaId",["loc",[null,[30,37],[30,48]]]]],[],4,null,["loc",[null,[30,31],[31,71]]]],
        ["block","if",[["get","model.pzn",["loc",[null,[31,78],[31,87]]]]],[],5,null,["loc",[null,[31,72],[32,65]]]],
        ["block","if",[["subexpr","or",[["get","model.gtin",["loc",[null,[32,76],[32,86]]]],["get","model.ean",["loc",[null,[32,87],[32,96]]]]],[],["loc",[null,[32,72],[32,97]]]]],[],6,null,["loc",[null,[32,66],[33,66]]]],
        ["inline","truncate",[["get","model.description",["loc",[null,[35,21],[35,38]]]],300],[],["loc",[null,[35,9],[35,45]]]],
        ["content","purchaseProduct.quantity",["loc",[null,[39,13],[39,41]]]],
        ["content","model.unit.name",["loc",[null,[39,42],[39,61]]]],
        ["block","if",[["get","model.altUnit",["loc",[null,[40,16],[40,29]]]]],[],7,null,["loc",[null,[40,10],[43,17]]]],
        ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[45,39],[45,58]]]],"-"],[],["loc",[null,[45,13],[45,64]]]],
        ["block","if",[["get","model.narcotic",["loc",[null,[47,19],[47,33]]]]],[],8,9,["loc",[null,[47,13],[47,56]]]],
        ["block","if",[["get","model.licenceNumber",["loc",[null,[49,19],[49,38]]]]],[],10,11,["loc",[null,[49,13],[51,52]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[55,16],[55,33]]]]],[],12,null,["loc",[null,[55,10],[70,17]]]],
        ["block","if",[["get","model.systemTags",["loc",[null,[71,16],[71,32]]]]],[],13,null,["loc",[null,[71,10],[75,17]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[83,51],[83,60]]]],"purchase"],[],["loc",[null,[83,47],[83,72]]]],"is-active",""],[],["loc",[null,[83,26],[83,89]]]]]]],
        ["element","action",["selectTab","purchase"],[],["loc",[null,[83,112],[84,30]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[85,10],[85,35]]]]],[],14,null,["loc",[null,[85,4],[87,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[88,51],[88,60]]]],"additional"],[],["loc",[null,[88,47],[88,74]]]],"is-active",""],[],["loc",[null,[88,26],[88,91]]]]]]],
        ["element","action",["selectTab","additional"],[],["loc",[null,[88,107],[88,142]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[89,10],[89,35]]]]],[],15,null,["loc",[null,[89,4],[91,11]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[92,10],[92,27]]]]],[],16,null,["loc",[null,[92,4],[97,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[98,14],[98,31]]]],["subexpr","is-debug-user",[],[],["loc",[null,[98,32],[98,47]]]]],[],["loc",[null,[98,10],[98,48]]]]],[],17,null,["loc",[null,[98,4],[101,11]]]],
        ["attribute","class",["concat",["card-content  tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[108,74],[108,83]]]],"debug"],[],["loc",[null,[108,70],[108,92]]]],"is-active",""],[],["loc",[null,[108,49],[108,109]]]]," "]]],
        ["inline","time-format",[["get","model.lastImport",["loc",[null,[110,29],[110,45]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[110,15],[110,68]]]],
        ["content","model.primarySupplierId",["loc",[null,[112,15],[112,42]]]],
        ["content","model.importDetails",["loc",[null,[112,43],[112,66]]]],
        ["block","if",[["get","model.migrated",["loc",[null,[114,21],[114,35]]]]],[],18,19,["loc",[null,[114,15],[114,122]]]],
        ["block","if",[["get","model.richterCase",["loc",[null,[114,129],[114,146]]]]],[],20,null,["loc",[null,[114,123],[114,183]]]],
        ["attribute","class",["concat",["card-content tabs__content pt-50 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[116,79],[116,88]]]],"purchase"],[],["loc",[null,[116,75],[116,100]]]],"is-active",""],[],["loc",[null,[116,54],[116,117]]]]]]],
        ["block","each",[["get","model.purchaseSupplierEntries",["loc",[null,[117,16],[117,45]]]]],[],21,null,["loc",[null,[117,8],[248,9]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[250,73],[250,82]]]],"general"],[],["loc",[null,[250,69],[250,93]]]],"is-active",""],[],["loc",[null,[250,48],[250,110]]]]]]],
        ["content","model.tax.percentage",["loc",[null,[252,51],[252,75]]]],
        ["content","model.atcCode",["loc",[null,[253,68],[253,85]]]],
        ["content","model.atcCodeDescription",["loc",[null,[254,68],[254,96]]]],
        ["content","model.partnerCategory",["loc",[null,[255,68],[255,93]]]],
        ["block","if",[["get","model.partnerSubcategory",["loc",[null,[255,100],[255,124]]]]],[],22,null,["loc",[null,[255,94],[255,163]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[256,20],[256,37]]]]],[],23,null,["loc",[null,[256,14],[258,21]]]],
        ["block","if",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[259,24],[259,41]]]],"laboklin"],[],["loc",[null,[259,20],[259,53]]]]],[],24,null,["loc",[null,[259,14],[261,21]]]],
        ["attribute","class",["concat",["card-content min-h-60px tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[264,84],[264,93]]]],"additional"],[],["loc",[null,[264,80],[264,107]]]],"is-active",""],[],["loc",[null,[264,59],[264,124]]]]]]],
        ["content","model.description",["loc",[null,[267,17],[267,40]]]],
        ["block","if",[["get","model.licenceNumberEu",["loc",[null,[269,20],[269,41]]]]],[],25,null,["loc",[null,[269,14],[272,21]]]],
        ["block","if",[["get","model.licenceNumberAges",["loc",[null,[273,20],[273,43]]]]],[],26,null,["loc",[null,[273,14],[276,21]]]],
        ["block","if",[["get","model.licenceNumberApo",["loc",[null,[277,20],[277,42]]]]],[],27,null,["loc",[null,[277,14],[280,21]]]],
        ["block","if",[["get","model.slopingInfo",["loc",[null,[281,20],[281,37]]]]],[],28,null,["loc",[null,[281,14],[284,21]]]],
        ["block","if",[["get","model.domainInfo",["loc",[null,[286,20],[286,36]]]]],[],29,null,["loc",[null,[286,14],[289,21]]]],
        ["block","if",[["subexpr","or",[["get","model.info",["loc",[null,[291,24],[291,34]]]],["get","model.detailUrl",["loc",[null,[291,35],[291,50]]]]],[],["loc",[null,[291,20],[291,51]]]]],[],30,null,["loc",[null,[291,14],[294,21]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[295,29],[295,46]]]],"jac"],[],["loc",[null,[295,25],[295,53]]]],["get","model.licenceNumber",["loc",[null,[295,54],[295,73]]]]],[],["loc",[null,[295,20],[295,74]]]]],[],31,null,["loc",[null,[295,14],[301,21]]]],
        ["block","if",[["get","model.targetRace",["loc",[null,[303,20],[303,36]]]]],[],32,null,["loc",[null,[303,14],[306,21]]]],
        ["block","if",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[307,24],[307,41]]]],"ceva"],[],["loc",[null,[307,20],[307,49]]]]],[],33,null,["loc",[null,[307,14],[357,21]]]],
        ["attribute","class",["concat",["  tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[360,62],[360,71]]]],"history"],[],["loc",[null,[360,58],[360,82]]]],"is-active",""],[],["loc",[null,[360,37],[360,99]]]]]]],
        ["block","if",[["subexpr","has-permission",["ru_purchase_history_notice"],[],["loc",[null,[364,20],[364,65]]]]],[],34,35,["loc",[null,[364,14],[377,23]]]],
        ["block","if",[["subexpr","list-not-empty",[["get","history",["loc",[null,[380,32],[380,39]]]]],[],["loc",[null,[380,16],[380,40]]]]],[],36,null,["loc",[null,[380,10],[428,19]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[430,60],[430,69]]]],"inventory"],[],["loc",[null,[430,56],[430,82]]]],"is-active",""],[],["loc",[null,[430,35],[430,99]]]]]]],
        ["block","each",[["get","stocks",["loc",[null,[443,26],[443,32]]]]],[],37,null,["loc",[null,[443,18],[463,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[468,60],[468,69]]]],"reorder"],[],["loc",[null,[468,56],[468,80]]]],"is-active",""],[],["loc",[null,[468,35],[468,97]]]]]]],
        ["block","each",[["get","model.reorderSettings",["loc",[null,[479,22],[479,43]]]]],[],38,null,["loc",[null,[479,14],[549,23]]]],
        ["inline","input",[],["type","checkbox","name","acceptAgbs","id","autosendReorder","checked",["subexpr","@mut",[["get","model.autosendReorder",["loc",[null,[561,91],[561,112]]]]],[],[]]],["loc",[null,[561,20],[561,114]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","model.orderNotice",["loc",[null,[569,46],[569,63]]]]],[],[]],"class","","name","historyNotice","placeholder",""],["loc",[null,[569,20],[570,47]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[580,19],[580,32]]]]],[],["loc",[null,[580,14],[580,33]]]],["get","sidebarActive",["loc",[null,[580,34],[580,47]]]]],[],["loc",[null,[580,9],[580,48]]]]],[],39,40,["loc",[null,[580,3],[623,7]]]],
        ["inline","purchase-order-laboklin-chooser",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[625,40],[625,45]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[625,54],[625,62]]]]],[],[]],"data",["subexpr","@mut",[["get","order.laboklinDatas",["loc",[null,[625,68],[625,87]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[625,98],[625,108]]]]],[],[]],"addNew","newLaboklinForm","addProduct","addProduct","actionReceiver",["subexpr","@mut",[["get","laboklinChooser",["loc",[null,[625,173],[625,188]]]]],[],[]]],["loc",[null,[625,0],[625,190]]]],
        ["inline","edit-purchase-price-panel",[],["save","savePrice","actionReceiver",["subexpr","@mut",[["get","editPurchasePricePanel",["loc",[null,[626,60],[626,82]]]]],[],[]]],["loc",[null,[626,0],[626,84]]]],
        ["inline","edit-purchase-product-panel",[],["save","saveProduct","actionReceiver",["subexpr","@mut",[["get","editPurchaseProductPanel",["loc",[null,[627,64],[627,88]]]]],[],[]]],["loc",[null,[627,0],[627,90]]]],
        ["inline","promotion-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","promotionInfoPanel",["loc",[null,[628,38],[628,56]]]]],[],[]]],["loc",[null,[628,0],[628,58]]]],
        ["inline","delivery-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","deliveryInfoPanel",["loc",[null,[629,37],[629,54]]]]],[],[]]],["loc",[null,[629,0],[629,56]]]],
        ["inline","notdeliverable-confirm",[],["orderAnyway","addProduct","actionReceiver",["subexpr","@mut",[["get","notDeliverableConfirm",["loc",[null,[630,65],[630,86]]]]],[],[]]],["loc",[null,[630,0],[630,88]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",false,"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[633,10],[633,18]]]]],[],[]],"confirm","addProduct","removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[643,17],[643,39]]]]],[],[]]],["loc",[null,[631,0],[643,41]]]],
        ["content","button-prev",["loc",[null,[648,4],[648,19]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[654,14],[654,32]]]],["subexpr","is-supplier",[],[],["loc",[null,[654,33],[654,46]]]]],[],["loc",[null,[654,10],[654,47]]]]],[],41,null,["loc",[null,[654,4],[661,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[662,12],[662,30]]]],["subexpr","is-supplier",[],[],["loc",[null,[662,31],[662,44]]]]],[],["loc",[null,[662,8],[662,45]]]]],[],42,null,["loc",[null,[662,2],[669,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[670,12],[670,21]]]],"history"],[],["loc",[null,[670,8],[670,32]]]]],[],43,null,["loc",[null,[670,2],[674,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[675,12],[675,21]]]],"reorder"],[],["loc",[null,[675,8],[675,32]]]]],[],44,null,["loc",[null,[675,2],[679,9]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44]
    };
  }()));

});
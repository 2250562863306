define('jason-frontend/controllers/products/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    queryParams: ["page", "perPage", "filterName", "filterSupplier", "sortBy", "sortDir"],
    filterName: null,
    filterSupplier: null,
    selectedCategoryFilters: null,
    sortBy: null,
    sortDir: null,
    filterAll: true,
    filterFavs: false,
    showFilters: true,
    inclusiveVpes: true,
    pageBinding: "content.page",
    perPageBinding: "content.perPage",
    totalPagesBinding: "content.totalPages",
    showCartButton: false,

    page: 1,
    perPage: 10,

    myStickyOptionsTableHead: {
      topSpacing: 80,
      zIndex: 9
    },
    watchFavs: (function () {
      if (this.get('filterFavs')) {
        this.set('filterAll', false);
      } else {
        this.set('filterAll', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterFavs'),
    watchfilterAll: (function () {
      if (this.get('filterAll')) {
        this.set('filterFavs', false);
      } else {
        this.set('filterFavs', true);
      }
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterAll'),
    watchFilterName: (function () {
      this.set('page', 1);
    }).observes('filterName'),
    watchFilterCategory: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('selectedCategoryFilters'),
    watchSupplier: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterSupplier'),
    watchManufacturer: (function () {
      this.set('page', 1);
      this.send('applyFilter');
    }).observes('filterManufacturer')
  });

});
define('jason-frontend/components/patient-activities-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    actions: {
      load: function load(context, id) {
        this.get('patientActivities').send('load', context, id);
      }
    }
  });

});
define('jason-frontend/templates/components/select-station-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 14
              },
              "end": {
                "line": 18,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            morphs[2] = dom.createMorphAt(element5,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[16,69],[16,85]]]],"selected",""],[],["loc",[null,[16,48],[16,101]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[17,34],[17,41]]]]],[],["loc",[null,[16,103],[17,43]]]],
            ["content","station.name",["loc",[null,[17,44],[17,60]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 56
                      },
                      "end": {
                        "line": 33,
                        "column": 91
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Frei");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 33,
                          "column": 91
                        },
                        "end": {
                          "line": 33,
                          "column": 136
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Belegt");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 33,
                        "column": 91
                      },
                      "end": {
                        "line": 33,
                        "column": 136
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","cage.status",["loc",[null,[33,105],[33,116]]]],"occupied"],[],["loc",[null,[33,101],[33,128]]]]],[],0,null,["loc",[null,[33,91],[33,136]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child2 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 40,
                            "column": 38
                          },
                          "end": {
                            "line": 41,
                            "column": 160
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode(" ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["content","cage.visit.patient.customer.firstname",["loc",[null,[41,78],[41,119]]]],
                        ["content","cage.visit.patient.customer.lastname",["loc",[null,[41,120],[41,160]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 44,
                            "column": 34
                          },
                          "end": {
                            "line": 44,
                            "column": 262
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("i");
                        dom.setAttribute(el1,"class","animal-icon");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","cage.visit.patient.category.id",["loc",[null,[44,124],[44,154]]]]],[],[]],"race-id",["subexpr","@mut",[["get","cage.visit.patient.digitalRace.icon",["loc",[null,[44,163],[44,198]]]]],[],[]],"content",["subexpr","@mut",[["get","cage.visit.patient.category.name",["loc",[null,[44,207],[44,239]]]]],[],[]],"classNames","mr5"],["loc",[null,[44,92],[44,258]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 45,
                            "column": 34
                          },
                          "end": {
                            "line": 45,
                            "column": 158
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","cage.visit.patient.name",["loc",[null,[45,103],[45,126]]]]],[],[]],"content",["subexpr","@mut",[["get","cage.visit.patient.id",["loc",[null,[45,135],[45,156]]]]],[],[]]],["loc",[null,[45,84],[45,158]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 46,
                            "column": 34
                          },
                          "end": {
                            "line": 46,
                            "column": 201
                          }
                        },
                        "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[46,72],[46,201]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 39,
                          "column": 30
                        },
                        "end": {
                          "line": 48,
                          "column": 30
                        }
                      },
                      "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [3]);
                      var morphs = new Array(5);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      morphs[1] = dom.createElementMorph(element1);
                      morphs[2] = dom.createMorphAt(element1,1,1);
                      morphs[3] = dom.createMorphAt(element1,3,3);
                      morphs[4] = dom.createMorphAt(element1,5,5);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["customers.edit",["get","cage.visit.patient.customer.id",["loc",[null,[40,66],[40,96]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[40,38],[41,172]]]],
                      ["element","action",["gotoPatient",["get","cage.visit.patient.id",["loc",[null,[43,116],[43,137]]]]],["bubbles",true],["loc",[null,[43,93],[43,152]]]],
                      ["block","if",[["get","cage.visit.patient.category",["loc",[null,[44,40],[44,67]]]]],[],1,null,["loc",[null,[44,34],[44,269]]]],
                      ["block","link-to",["patients.edit",["get","cage.visit.patient.id",["loc",[null,[45,61],[45,82]]]]],[],2,null,["loc",[null,[45,34],[45,170]]]],
                      ["block","if",[["get","cage.visit.patient.etigaActive",["loc",[null,[46,40],[46,70]]]]],[],3,null,["loc",[null,[46,34],[46,208]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 28
                      },
                      "end": {
                        "line": 53,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","mt10");
                    var el2 = dom.createTextNode("\n                                Auf Station seit: ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),0,0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","cage.visit.patient",["loc",[null,[39,36],[39,54]]]]],[],0,null,["loc",[null,[39,30],[48,37]]]],
                    ["inline","time-format",[["get","cage.visit.start",["loc",[null,[51,70],[51,86]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[51,56],[51,109]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 20
                    },
                    "end": {
                      "line": 57,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","col l-8/24@desk l-12/24@tablet l-24/24 gutter");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","header");
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4,"class","d-flex align-items-center");
                  var el5 = dom.createTextNode("\n                              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("h3");
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("div");
                  var el6 = dom.createTextNode("\n                                ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createElement("span");
                  dom.setAttribute(el6,"class","textBelow");
                  var el7 = dom.createComment("");
                  dom.appendChild(el6, el7);
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                              ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                            ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","body");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("                          ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1, 1]);
                  var element3 = dom.childAt(element2, [1, 1]);
                  var element4 = dom.childAt(element3, [3]);
                  var morphs = new Array(6);
                  morphs[0] = dom.createAttrMorph(element2, 'class');
                  morphs[1] = dom.createElementMorph(element2);
                  morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                  morphs[3] = dom.createAttrMorph(element4, 'class');
                  morphs[4] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
                  morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","class",["concat",["card cursor cage-card status-",["get","cage.status",["loc",[null,[28,67],[28,78]]]]," ",["subexpr","css-bool-evaluator",[["get","cage.selected",["loc",[null,[28,102],[28,115]]]],"selected",""],[],["loc",[null,[28,81],[28,131]]]]]]],
                  ["element","action",["selectCage",["get","cage",["loc",[null,[28,155],[28,159]]]]],[],["loc",[null,[28,133],[28,161]]]],
                  ["content","cage.name",["loc",[null,[31,34],[31,47]]]],
                  ["attribute","class",["concat",["status-badge ",["get","cage.status",["loc",[null,[32,57],[32,68]]]]]]],
                  ["block","if",[["subexpr","eq",[["get","cage.status",["loc",[null,[33,66],[33,77]]]],"free"],[],["loc",[null,[33,62],[33,85]]]]],[],0,1,["loc",[null,[33,56],[33,143]]]],
                  ["block","if",[["get","cage.visit",["loc",[null,[38,34],[38,44]]]]],[],2,null,["loc",[null,[38,28],[53,35]]]]
                ],
                locals: ["cage"],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 16
                  },
                  "end": {
                    "line": 59,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","grid");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","each",[["get","station.cages",["loc",[null,[26,28],[26,41]]]]],[],0,null,["loc",[null,[26,20],[57,29]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 16
                  },
                  "end": {
                    "line": 61,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Derzeit keine Boxen hinterlegt");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 14
                },
                "end": {
                  "line": 62,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1,"class","mt-10 mb-10");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","station.name",["loc",[null,[23,40],[23,56]]]],
              ["block","if",[["subexpr","list-not-empty",[["get","station.cages",["loc",[null,[24,38],[24,51]]]]],[],["loc",[null,[24,22],[24,52]]]]],[],0,1,["loc",[null,[24,16],[61,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 12
              },
              "end": {
                "line": 63,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","allStationsSelected",["loc",[null,[22,24],[22,43]]]],["get","station.selected",["loc",[null,[22,44],[22,60]]]]],[],["loc",[null,[22,20],[22,61]]]]],[],0,null,["loc",[null,[22,14],[62,21]]]]
          ],
          locals: ["station"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 73,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"id","selectStationPanelForm");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","panel-title");
          var el5 = dom.createTextNode("\n                Station / Box auswählen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-body p-30");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ms-0 mb-30 quick-filter");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","");
          var el6 = dom.createTextNode("Stationen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Alle");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-footer text-right");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"type","submit");
          dom.setAttribute(el4,"class","icon-button icon-button--success");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var element7 = dom.childAt(element6, [3]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element8, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createAttrMorph(element9, 'class');
          morphs[2] = dom.createElementMorph(element9);
          morphs[3] = dom.createMorphAt(element8,5,5);
          morphs[4] = dom.createMorphAt(element7,3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element6, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["confirmExtended",["get","id",["loc",[null,[4,69],[4,71]]]]],["on","submit"],["loc",[null,[4,42],[4,85]]]],
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","allStationsSelected",["loc",[null,[12,67],[12,86]]]],"selected",""],[],["loc",[null,[12,46],[12,102]]]]]]],
          ["element","action",["toggleAllStations"],[],["loc",[null,[12,104],[13,37]]]],
          ["block","each",[["get","stations",["loc",[null,[15,22],[15,30]]]]],[],0,null,["loc",[null,[15,14],[18,23]]]],
          ["block","each",[["get","stations",["loc",[null,[21,20],[21,28]]]]],[],1,null,["loc",[null,[21,12],[63,21]]]],
          ["inline","button-next",[],["size","52","color","#ffffff","content","Auf Station legen"],["loc",[null,[68,14],[68,83]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 0
            },
            "end": {
              "line": 105,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","panel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2,"id","selectStationPanelForm");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","panel-title");
          var el5 = dom.createTextNode("\n                Station auswählen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-body p-30");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","grid");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","form-field-rework");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","select-placeholder");
          var el7 = dom.createTextNode("Station");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","panel-footer text-right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"type","submit");
          dom.setAttribute(el4,"class","icon-button icon-button--success");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["confirmSimple",["get","id",["loc",[null,[75,63],[75,65]]]]],["on","submit"],["loc",[null,[75,38],[75,79]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","stations",["loc",[null,[86,22],[86,30]]]]],[],[]],"value",["subexpr","@mut",[["get","station",["loc",[null,[87,20],[87,27]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","stationId","placeholder","Bitte Station wählen"],["loc",[null,[85,12],[93,14]]]],
          ["inline","button-next",[],["size","52","color","#ffffff","content","Auf Station legen"],["loc",[null,[100,10],[100,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/select-station-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-select-station-panel");
        dom.setAttribute(el1,"class","popup-basic small--heading popup-extremely-large mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","is-station-extended-enabled",[],[],["loc",[null,[2,6],[2,35]]]]],[],0,1,["loc",[null,[2,0],[105,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
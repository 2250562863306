define('jason-frontend/templates/components/invoice-add-product', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element39 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element39, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","product.picture.path",["loc",[null,[4,57],[4,77]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element38 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element38, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/",["subexpr","get-product-picture",[["get","product",["loc",[null,[6,91],[6,98]]]]],[],["loc",[null,[6,69],[6,100]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 63
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","product.name",["loc",[null,[10,47],[10,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 22
                },
                "end": {
                  "line": 12,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" > ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","product.partnerCategory",["loc",[null,[12,53],[12,80]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 91
                },
                "end": {
                  "line": 12,
                  "column": 155
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","product.partnerSubcategory",["loc",[null,[12,125],[12,155]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","sub");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element37 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element37,0,0);
            morphs[1] = dom.createMorphAt(element37,2,2);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.partnerCategory",["loc",[null,[12,28],[12,51]]]]],[],0,null,["loc",[null,[12,22],[12,90]]]],
            ["block","if",[["get","product.partnerSubcategory",["loc",[null,[12,97],[12,123]]]]],[],1,null,["loc",[null,[12,91],[12,162]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","sub");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","product.category.name",["loc",[null,[14,22],[14,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 41
                },
                "end": {
                  "line": 21,
                  "column": 126
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge mr5 mb5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","tag.name",["loc",[null,[21,106],[21,118]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 142
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","tags");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["block","each",[["get","product.tags",["loc",[null,[21,49],[21,61]]]]],[],0,null,["loc",[null,[21,41],[21,135]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 142
              },
              "end": {
                "line": 21,
                "column": 177
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","none");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 119
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","stock-status",[],["classNames","","stocks",["subexpr","@mut",[["get","product.stockLevels",["loc",[null,[27,98],[27,117]]]]],[],[]]],["loc",[null,[27,62],[27,119]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 119
              },
              "end": {
                "line": 27,
                "column": 298
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","mb0 block");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","label stock-label label-muted cursor pl0 pr0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-text",[],["classNames","minw25","contentAsHTML",true,"content","","text","X"],["loc",[null,[27,210],[27,284]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 14
              },
              "end": {
                "line": 41,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line no-border");
            var el2 = dom.createTextNode("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                   ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                 ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element36 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element36, 'class');
            morphs[1] = dom.createElementMorph(element36);
            morphs[2] = dom.createMorphAt(element36,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","product.favorite",["loc",[null,[37,156],[37,172]]]],"selected",""],[],["loc",[null,[37,135],[37,188]]]]]]],
            ["element","action",["toggleProductFavorite",["get","product",["loc",[null,[37,53],[37,60]]]],["subexpr","not",[["get","product.favorite",["loc",[null,[37,66],[37,82]]]]],[],["loc",[null,[37,61],[37,83]]]]],["bubbles",false],["loc",[null,[37,20],[37,99]]]],
            ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[38,19],[38,92]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 16
                },
                "end": {
                  "line": 49,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line no-border");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element35 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element35, 'class');
              morphs[1] = dom.createElementMorph(element35);
              morphs[2] = dom.createMorphAt(element35,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","price.favorite",["loc",[null,[45,159],[45,173]]]],"selected",""],[],["loc",[null,[45,138],[45,189]]]]]]],
              ["element","action",["togglePriceFavorite",["get","product",["loc",[null,[45,52],[45,59]]]],["get","price",["loc",[null,[45,60],[45,65]]]],["subexpr","not",[["get","price.favorite",["loc",[null,[45,71],[45,85]]]]],[],["loc",[null,[45,66],[45,86]]]]],["bubbles",false],["loc",[null,[45,21],[45,102]]]],
              ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[46,20],[46,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 14
              },
              "end": {
                "line": 50,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[43,39],[43,53]]]],["get","filterFavs",["loc",[null,[43,54],[43,64]]]]],[],["loc",[null,[43,22],[43,65]]]]],[],0,null,["loc",[null,[43,16],[49,23]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 10
              },
              "end": {
                "line": 59,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line price-value mixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","tax");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element34 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element34, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element34, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-number-german",[["get","product.quantity",["loc",[null,[56,43],[56,59]]]]],[],["loc",[null,[56,20],[56,61]]]],
            ["content","product.unit.name",["loc",[null,[57,32],[57,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 12
                },
                "end": {
                  "line": 66,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line price-value mixed");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","tax");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element33 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element33, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element33, [3]),0,0);
              return morphs;
            },
            statements: [
              ["inline","format-number-german",[["get","price.quantity",["loc",[null,[63,45],[63,59]]]]],[],["loc",[null,[63,22],[63,61]]]],
              ["content","price.unit.name",["loc",[null,[64,34],[64,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 10
              },
              "end": {
                "line": 67,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[61,35],[61,49]]]],["get","filterFavs",["loc",[null,[61,50],[61,60]]]]],[],["loc",[null,[61,18],[61,61]]]]],[],0,null,["loc",[null,[61,12],[66,19]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child13 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 16
                },
                "end": {
                  "line": 75,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge mt9");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[74,69],[74,96]]]],"Verabreicht","Angewandt"],[],["loc",[null,[74,48],[74,124]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 16
                },
                "end": {
                  "line": 77,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","none");
              var el2 = dom.createTextNode("-");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 10
              },
              "end": {
                "line": 80,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.applied",["loc",[null,[73,22],[73,37]]]]],[],0,1,["loc",[null,[73,16],[77,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 18
                  },
                  "end": {
                    "line": 87,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge mt9");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","css-bool-evaluator",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[86,71],[86,98]]]],"Verabreicht","Angewandt"],[],["loc",[null,[86,50],[86,126]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 18
                  },
                  "end": {
                    "line": 89,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","none");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 12
                },
                "end": {
                  "line": 92,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","price.applied",["loc",[null,[85,24],[85,37]]]]],[],0,1,["loc",[null,[85,18],[89,25]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 10
              },
              "end": {
                "line": 93,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[82,35],[82,49]]]],["get","filterFavs",["loc",[null,[82,50],[82,60]]]]],[],["loc",[null,[82,18],[82,61]]]]],[],0,null,["loc",[null,[82,12],[92,19]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child15 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 100,
                    "column": 35
                  },
                  "end": {
                    "line": 100,
                    "column": 94
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("20");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 100,
                    "column": 94
                  },
                  "end": {
                    "line": 100,
                    "column": 128
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","product.tax.percentage",["loc",[null,[100,102],[100,128]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 12
                },
                "end": {
                  "line": 102,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","tax");
              var el3 = dom.createTextNode("(");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" % MwSt)");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element32 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element32, 'class');
              morphs[1] = dom.createMorphAt(element32,1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element32, [3]),1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["price-line price-value ",["subexpr","css-bool-evaluator",[["get","product.jasonPrice",["loc",[null,[98,70],[98,88]]]],"jason-price",""],[],["loc",[null,[98,49],[98,107]]]]]]],
              ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","product.priceExVat",["loc",[null,[99,61],[99,79]]]],["get","product.tax.percentage",["loc",[null,[99,80],[99,102]]]],["get","product.applied",["loc",[null,[99,103],[99,118]]]]],[],["loc",[null,[99,40],[99,119]]]]],["loc",[null,[99,16],[99,121]]]],
              ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[100,46],[100,73]]]],["get","product.applied",["loc",[null,[100,74],[100,89]]]]],[],["loc",[null,[100,41],[100,90]]]]],[],0,1,["loc",[null,[100,35],[100,135]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 37
                    },
                    "end": {
                      "line": 107,
                      "column": 94
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("20");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 107,
                      "column": 94
                    },
                    "end": {
                      "line": 107,
                      "column": 128
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","product.tax.percentage",["loc",[null,[107,102],[107,128]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 14
                  },
                  "end": {
                    "line": 109,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","tax");
                var el3 = dom.createTextNode("(");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" % MwSt)");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element31 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element31, 'class');
                morphs[1] = dom.createMorphAt(element31,1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element31, [3]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["price-line price-value ",["subexpr","css-bool-evaluator",[["get","price.jasonPrice",["loc",[null,[105,72],[105,88]]]],"jason-price",""],[],["loc",[null,[105,51],[105,107]]]]]]],
                ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","price.price",["loc",[null,[106,63],[106,74]]]],["get","product.tax.percentage",["loc",[null,[106,75],[106,97]]]],["get","price.applied",["loc",[null,[106,99],[106,112]]]]],[],["loc",[null,[106,42],[106,113]]]]],["loc",[null,[106,18],[106,115]]]],
                ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[107,48],[107,75]]]],["get","price.applied",["loc",[null,[107,76],[107,89]]]]],[],["loc",[null,[107,43],[107,90]]]]],[],0,1,["loc",[null,[107,37],[107,135]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 12
                },
                "end": {
                  "line": 110,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[104,37],[104,51]]]],["get","filterFavs",["loc",[null,[104,52],[104,62]]]]],[],["loc",[null,[104,20],[104,63]]]]],[],0,null,["loc",[null,[104,14],[109,21]]]]
            ],
            locals: ["price"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 10
              },
              "end": {
                "line": 111,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[97,35],[97,51]]]],["get","filterFavs",["loc",[null,[97,52],[97,62]]]]],[],["loc",[null,[97,18],[97,63]]]]],[],0,null,["loc",[null,[97,12],[102,19]]]],
            ["block","each",[["get","product.prices",["loc",[null,[103,20],[103,34]]]]],[],1,null,["loc",[null,[103,12],[110,21]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child16 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 116,
                    "column": 14
                  },
                  "end": {
                    "line": 120,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","price-line");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[118,49],[118,74]]]]],[],[]],"internalid",["subexpr","@mut",[["get","product.id",["loc",[null,[118,86],[118,96]]]]],[],[]]],["loc",[null,[118,18],[118,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 124,
                        "column": 18
                      },
                      "end": {
                        "line": 128,
                        "column": 18
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","price-line");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","price.quantitySelection",["loc",[null,[126,53],[126,76]]]]],[],[]],"internalid",["subexpr","@mut",[["get","price.id",["loc",[null,[126,88],[126,96]]]]],[],[]]],["loc",[null,[126,22],[126,98]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 123,
                      "column": 16
                    },
                    "end": {
                      "line": 129,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[124,41],[124,55]]]],["get","filterFavs",["loc",[null,[124,56],[124,66]]]]],[],["loc",[null,[124,24],[124,67]]]]],[],0,null,["loc",[null,[124,18],[128,25]]]]
                ],
                locals: ["price"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 122,
                    "column": 14
                  },
                  "end": {
                    "line": 130,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","product.prices",["loc",[null,[123,24],[123,38]]]]],[],0,null,["loc",[null,[123,16],[129,25]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 115,
                  "column": 12
                },
                "end": {
                  "line": 131,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[116,37],[116,53]]]],["get","filterFavs",["loc",[null,[116,54],[116,64]]]]],[],["loc",[null,[116,20],[116,65]]]]],[],0,null,["loc",[null,[116,14],[120,21]]]],
              ["block","if",[["get","inclusiveVpes",["loc",[null,[122,20],[122,33]]]]],[],1,null,["loc",[null,[122,14],[130,21]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 131,
                  "column": 12
                },
                "end": {
                  "line": 135,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line hidden");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[133,47],[133,72]]]]],[],[]],"internalid",["subexpr","@mut",[["get","product.id",["loc",[null,[133,84],[133,94]]]]],[],[]]],["loc",[null,[133,16],[133,96]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 140,
                    "column": 16
                  },
                  "end": {
                    "line": 144,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","float-right icon-button icon-button--success list-action-square ");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element29 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element29);
                return morphs;
              },
              statements: [
                ["element","action",["addProduct",["get","product",["loc",[null,[141,116],[141,123]]]],["get","typeId",["loc",[null,[141,124],[141,130]]]],0,["get","product.name",["loc",[null,[141,133],[141,145]]]]],[],["loc",[null,[141,94],[141,147]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 12
                },
                "end": {
                  "line": 146,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line d-flex justify-content-end");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","product.tax",["loc",[null,[140,22],[140,33]]]]],[],0,null,["loc",[null,[140,16],[144,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 151,
                        "column": 20
                      },
                      "end": {
                        "line": 155,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","float-right icon-button icon-button--success list-action-square");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element28 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element28);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["addProduct",["get","product",["loc",[null,[152,119],[152,126]]]],["get","typeId",["loc",[null,[152,127],[152,133]]]],["get","price.id",["loc",[null,[152,134],[152,142]]]],["get","product.name",["loc",[null,[152,143],[152,155]]]]],[],["loc",[null,[152,97],[152,157]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 16
                    },
                    "end": {
                      "line": 157,
                      "column": 16
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","price-line d-flex justify-content-end");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","product.tax",["loc",[null,[151,26],[151,37]]]]],[],0,null,["loc",[null,[151,20],[155,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 148,
                    "column": 14
                  },
                  "end": {
                    "line": 158,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[149,39],[149,53]]]],["get","filterFavs",["loc",[null,[149,54],[149,64]]]]],[],["loc",[null,[149,22],[149,65]]]]],[],0,null,["loc",[null,[149,16],[157,23]]]]
              ],
              locals: ["price"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 147,
                  "column": 12
                },
                "end": {
                  "line": 159,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","product.prices",["loc",[null,[148,22],[148,36]]]]],[],0,null,["loc",[null,[148,14],[158,23]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 8
              },
              "end": {
                "line": 161,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell text-center l-9/24 l-7/24@tablet l-4/24@desk table__cell--spinner permanent-caption");
            dom.setAttribute(el1,"data-label","Menge");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell l-2/24 l-2/24@desk table__cell--buttons button-1");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(element30,1,1);
            morphs[2] = dom.createMorphAt(element30,2,2);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[115,27],[115,31]]]],"inventoryRequests"],[],["loc",[null,[115,23],[115,52]]]]],[],["loc",[null,[115,18],[115,53]]]]],[],0,1,["loc",[null,[115,12],[135,19]]]],
            ["block","if",[["subexpr","or",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[138,39],[138,55]]]],["get","filterFavs",["loc",[null,[138,56],[138,66]]]]],[],["loc",[null,[138,22],[138,67]]]],["subexpr","eq",[["get","type",["loc",[null,[138,72],[138,76]]]],"inventoryRequests"],[],["loc",[null,[138,68],[138,97]]]]],[],["loc",[null,[138,18],[138,98]]]]],[],2,null,["loc",[null,[138,12],[146,19]]]],
            ["block","if",[["subexpr","and",[["get","inclusiveVpes",["loc",[null,[147,23],[147,36]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[147,46],[147,50]]]],"inventoryRequests"],[],["loc",[null,[147,42],[147,71]]]]],[],["loc",[null,[147,37],[147,72]]]]],[],["loc",[null,[147,18],[147,73]]]]],[],3,null,["loc",[null,[147,12],[159,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 167,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell  u-hide@phone u-hide@tablet  l-6/24 l-4/24@desk");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell table__cell--name l-24/24 l-8/24@desk");
          dom.setAttribute(el1,"data-label","Bezeichnung");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-2/24 u-hide@tablet u-hide@phone u-hide@desk");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-14/24  table__cell--center  u-text--left l-6/24@desk");
          dom.setAttribute(el1,"data-label","Tags");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-4/24 u-hide@desk");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-6/24 l-4/24@desk text-center table__cell--center");
          dom.setAttribute(el1,"data-label","Lager");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell l-24/24 no-caption table__cell--products");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table grid table--sub");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__row");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"data-label","Favorit");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"data-label","VPE");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"data-label","Anmerkung");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"data-label","VK brutto");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element40 = dom.childAt(fragment, [2]);
          var element41 = dom.childAt(fragment, [12, 1, 1, 1]);
          var element42 = dom.childAt(element41, [1]);
          var element43 = dom.childAt(element42, [1]);
          var element44 = dom.childAt(element41, [3]);
          var element45 = dom.childAt(element41, [5]);
          var element46 = dom.childAt(element41, [7]);
          var morphs = new Array(17);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
          morphs[2] = dom.createMorphAt(element40,3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [10]),1,1);
          morphs[5] = dom.createAttrMorph(element42, 'class');
          morphs[6] = dom.createMorphAt(element43,1,1);
          morphs[7] = dom.createMorphAt(element43,2,2);
          morphs[8] = dom.createAttrMorph(element44, 'class');
          morphs[9] = dom.createMorphAt(element44,1,1);
          morphs[10] = dom.createMorphAt(element44,2,2);
          morphs[11] = dom.createAttrMorph(element45, 'class');
          morphs[12] = dom.createMorphAt(element45,1,1);
          morphs[13] = dom.createMorphAt(element45,2,2);
          morphs[14] = dom.createAttrMorph(element46, 'class');
          morphs[15] = dom.createMorphAt(element46,1,1);
          morphs[16] = dom.createMorphAt(element41,9,9);
          return morphs;
        },
        statements: [
          ["block","if",[["get","product.picture",["loc",[null,[3,8],[3,23]]]]],[],0,1,["loc",[null,[3,2],[7,9]]]],
          ["block","link-to",["products.edit",["get","product.id",["loc",[null,[10,35],[10,45]]]]],[],2,null,["loc",[null,[10,8],[10,75]]]],
          ["block","if",[["get","product.partnerCategory",["loc",[null,[11,8],[11,31]]]]],[],3,4,["loc",[null,[11,2],[15,9]]]],
          ["block","if",[["get","product.tags",["loc",[null,[21,8],[21,20]]]]],[],5,6,["loc",[null,[21,2],[21,184]]]],
          ["block","if",[["subexpr","and",[["get","product.stockLevels",["loc",[null,[27,13],[27,32]]]],["subexpr","show-ehapo-stock",[["get","product",["loc",[null,[27,51],[27,58]]]]],[],["loc",[null,[27,33],[27,59]]]]],[],["loc",[null,[27,8],[27,60]]]]],[],7,8,["loc",[null,[27,2],[27,305]]]],
          ["attribute","class",["concat",["table__cell table__cell--center table__cell--favs l-3/24 l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[33,100],[33,114]]]],"2","4"],[],["loc",[null,[33,79],[33,124]]]],"/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[33,158],[33,172]]]],"4","3"],[],["loc",[null,[33,137],[33,182]]]],"/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[35,37],[35,53]]]],["get","filterFavs",["loc",[null,[35,54],[35,64]]]]],[],["loc",[null,[35,20],[35,65]]]]],[],9,null,["loc",[null,[35,14],[41,21]]]],
          ["block","each",[["get","product.prices",["loc",[null,[42,22],[42,36]]]]],[],10,null,["loc",[null,[42,14],[50,23]]]],
          ["attribute","class",["concat",["table__cell table__cell--center l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[53,75],[53,89]]]],"5","6"],[],["loc",[null,[53,54],[53,99]]]],"/24 l-5/24@desk text-center permanent-caption ps-20"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[54,33],[54,49]]]],["get","filterFavs",["loc",[null,[54,50],[54,60]]]]],[],["loc",[null,[54,16],[54,61]]]]],[],11,null,["loc",[null,[54,10],[59,17]]]],
          ["block","each",[["get","product.prices",["loc",[null,[60,18],[60,32]]]]],[],12,null,["loc",[null,[60,10],[67,19]]]],
          ["attribute","class",["concat",["table__cell text-center ",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[69,65],[69,79]]]],"u-hide@phone","l-10/24@phone"],[],["loc",[null,[69,44],[69,112]]]]," l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[69,136],[69,150]]]],"4","10"],[],["loc",[null,[69,115],[69,161]]]],"/24@tablet l-3/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[70,33],[70,49]]]],["get","filterFavs",["loc",[null,[70,50],[70,60]]]]],[],["loc",[null,[70,16],[70,61]]]]],[],13,null,["loc",[null,[70,10],[80,17]]]],
          ["block","each",[["get","product.prices",["loc",[null,[81,18],[81,32]]]]],[],14,null,["loc",[null,[81,10],[93,19]]]],
          ["attribute","class",["concat",["table__cell ",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[95,53],[95,67]]]],"text-center","text-right"],[],["loc",[null,[95,32],[95,96]]]],"  l-5/24 pr0 l-4/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[95,146],[95,160]]]],"6","4"],[],["loc",[null,[95,125],[95,170]]]],"/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[96,16],[96,29]]]]],[],15,null,["loc",[null,[96,10],[111,17]]]],
          ["block","if",[["get","showCartButton",["loc",[null,[113,14],[113,28]]]]],[],16,null,["loc",[null,[113,8],[161,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 171,
                "column": 2
              },
              "end": {
                "line": 173,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element16, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","product.picture.path",["loc",[null,[172,57],[172,77]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 173,
                "column": 2
              },
              "end": {
                "line": 175,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element15, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/suppliers/",["subexpr","get-default-small-picture",[["get","product",["loc",[null,[174,107],[174,114]]]]],[],["loc",[null,[174,79],[174,116]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 181,
                  "column": 6
                },
                "end": {
                  "line": 183,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","button-lab-top",[],["size","26","classNames","inline-icon","color","#4A4A4A","showStroke",true,"content",["subexpr","@mut",[["get","product.labProfile.name",["loc",[null,[182,106],[182,129]]]]],[],[]]],["loc",[null,[182,14],[182,131]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 184,
                  "column": 6
                },
                "end": {
                  "line": 184,
                  "column": 185
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","product.name",["loc",[null,[184,169],[184,185]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 180,
                "column": 4
              },
              "end": {
                "line": 185,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.labProfile",["loc",[null,[181,12],[181,30]]]]],[],0,null,["loc",[null,[181,6],[183,13]]]],
            ["block","link-to",["products.edit",["get","product.id",["loc",[null,[184,33],[184,43]]]],["subexpr","query-params",[],["referer","add-product","refererInvoiceId",["get","typeId",["loc",[null,[184,97],[184,103]]]],"refererPatientId",["get","patientId",["loc",[null,[184,121],[184,130]]]]],["loc",[null,[184,44],[184,131]]]]],["classNames","is-highlighted like-h2"],1,null,["loc",[null,[184,6],[184,197]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 187,
                  "column": 9
                },
                "end": {
                  "line": 189,
                  "column": 9
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("           ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["inline","button-lab-top",[],["classNames","inline-icon","size","26","color","#4A4A4A","showStroke",true,"content",["subexpr","@mut",[["get","product.labProfile.name",["loc",[null,[188,109],[188,132]]]]],[],[]]],["loc",[null,[188,17],[188,134]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 185,
                "column": 4
              },
              "end": {
                "line": 191,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","like-h2");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element14,1,1);
            morphs[1] = dom.createMorphAt(element14,3,3);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.labProfile",["loc",[null,[187,15],[187,33]]]]],[],0,null,["loc",[null,[187,9],[189,16]]]],
            ["content","product.name",["loc",[null,[190,8],[190,24]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 192,
                "column": 4
              },
              "end": {
                "line": 192,
                "column": 94
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","product.partnerCategory",["loc",[null,[192,60],[192,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 192,
                "column": 102
              },
              "end": {
                "line": 192,
                "column": 196
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small");
            var el2 = dom.createTextNode(" > ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            return morphs;
          },
          statements: [
            ["content","product.partnerSubcategory",["loc",[null,[192,159],[192,189]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 201,
                "column": 4
              },
              "end": {
                "line": 201,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","stock-status",[],["classNames","price-line","stocks",["subexpr","@mut",[["get","product.stockLevels",["loc",[null,[201,84],[201,103]]]]],[],[]]],["loc",[null,[201,38],[201,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 204,
                "column": 2
              },
              "end": {
                "line": 208,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element13,0,0);
            morphs[1] = dom.createMorphAt(element13,2,2);
            return morphs;
          },
          statements: [
            ["inline","format-number-german",[["get","product.quantity",["loc",[null,[206,36],[206,52]]]]],[],["loc",[null,[206,13],[206,54]]]],
            ["content","product.unit.name",["loc",[null,[206,55],[206,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 210,
                  "column": 4
                },
                "end": {
                  "line": 214,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element12,0,0);
              morphs[1] = dom.createMorphAt(element12,2,2);
              return morphs;
            },
            statements: [
              ["inline","format-number-german",[["get","price.quantity",["loc",[null,[212,40],[212,54]]]]],[],["loc",[null,[212,17],[212,56]]]],
              ["content","price.unit.name",["loc",[null,[212,57],[212,76]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 209,
                "column": 2
              },
              "end": {
                "line": 215,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[210,27],[210,41]]]],["get","filterFavorites",["loc",[null,[210,42],[210,57]]]]],[],["loc",[null,[210,10],[210,58]]]]],[],0,null,["loc",[null,[210,4],[214,11]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 222,
                    "column": 27
                  },
                  "end": {
                    "line": 222,
                    "column": 52
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("20");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 222,
                    "column": 52
                  },
                  "end": {
                    "line": 222,
                    "column": 86
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","product.tax.percentage",["loc",[null,[222,60],[222,86]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 219,
                  "column": 2
                },
                "end": {
                  "line": 224,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","small");
              var el3 = dom.createTextNode("(");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" % MwSt)");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element11, 'class');
              morphs[1] = dom.createMorphAt(element11,1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element11, [3]),1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["gross-price price-line ",["subexpr","css-bool-evaluator",[["get","product.jasonPrice",["loc",[null,[220,60],[220,78]]]],"jason-price",""],[],["loc",[null,[220,39],[220,97]]]]]]],
              ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","product.priceExVat",["loc",[null,[221,51],[221,69]]]],["get","product.tax.percentage",["loc",[null,[221,70],[221,92]]]],["get","product.applied",["loc",[null,[221,93],[221,108]]]]],[],["loc",[null,[221,30],[221,109]]]]],["loc",[null,[221,6],[221,111]]]],
              ["block","if",[["get","product.applied",["loc",[null,[222,33],[222,48]]]]],[],0,1,["loc",[null,[222,27],[222,93]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 31
                    },
                    "end": {
                      "line": 229,
                      "column": 54
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("20");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 54
                    },
                    "end": {
                      "line": 229,
                      "column": 88
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","product.tax.percentage",["loc",[null,[229,62],[229,88]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 226,
                    "column": 4
                  },
                  "end": {
                    "line": 231,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","small");
                var el3 = dom.createTextNode("(");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" % MwSt)");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element10, 'class');
                morphs[1] = dom.createMorphAt(element10,1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["gross-price price-line ",["subexpr","css-bool-evaluator",[["get","price.jasonPrice",["loc",[null,[227,64],[227,80]]]],"jason-price",""],[],["loc",[null,[227,43],[227,99]]]]]]],
                ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","price.price",["loc",[null,[228,55],[228,66]]]],["get","product.tax.percentage",["loc",[null,[228,67],[228,89]]]],["get","price.applied",["loc",[null,[228,90],[228,103]]]]],[],["loc",[null,[228,34],[228,104]]]]],["loc",[null,[228,10],[228,106]]]],
                ["block","if",[["get","price.applied",["loc",[null,[229,37],[229,50]]]]],[],0,1,["loc",[null,[229,31],[229,95]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 225,
                  "column": 2
                },
                "end": {
                  "line": 232,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[226,27],[226,41]]]],["get","filterFavorites",["loc",[null,[226,42],[226,57]]]]],[],["loc",[null,[226,10],[226,58]]]]],[],0,null,["loc",[null,[226,4],[231,11]]]]
            ],
            locals: ["price"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 218,
                "column": 0
              },
              "end": {
                "line": 233,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[219,25],[219,41]]]],["get","filterFavorites",["loc",[null,[219,42],[219,57]]]]],[],["loc",[null,[219,8],[219,58]]]]],[],0,null,["loc",[null,[219,2],[224,9]]]],
            ["block","each",[["get","product.prices",["loc",[null,[225,10],[225,24]]]]],[],1,null,["loc",[null,[225,2],[232,11]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 238,
                  "column": 6
                },
                "end": {
                  "line": 238,
                  "column": 177
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"style","width: 27px;");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createMorphAt(element8,0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["jason-icon inline-block applied ",["subexpr","css-bool-evaluator",[["get","product.jason",["loc",[null,[238,105],[238,118]]]],"jason",""],[],["loc",[null,[238,84],[238,131]]]]]]],
              ["content","icon-applied",["loc",[null,[238,154],[238,170]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 239,
                  "column": 6
                },
                "end": {
                  "line": 239,
                  "column": 123
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("TGD");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element7, 'class');
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["jason-icon-text ",["subexpr","css-bool-evaluator",[["get","product.jason",["loc",[null,[239,85],[239,98]]]],"jason",""],[],["loc",[null,[239,64],[239,111]]]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 236,
                "column": 2
              },
              "end": {
                "line": 241,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line no-border");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element9,1,1);
            morphs[1] = dom.createMorphAt(element9,3,3);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-true",[["get","product.applied",["loc",[null,[238,21],[238,36]]]]],[],["loc",[null,[238,12],[238,37]]]]],[],0,null,["loc",[null,[238,6],[238,184]]]],
            ["block","if",[["subexpr","is-true",[["get","product.tgd",["loc",[null,[239,21],[239,32]]]]],[],["loc",[null,[239,12],[239,33]]]]],[],1,null,["loc",[null,[239,6],[239,130]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child11 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 245,
                    "column": 8
                  },
                  "end": {
                    "line": 245,
                    "column": 175
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"style","width: 27px;");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element5, 'class');
                morphs[1] = dom.createMorphAt(element5,0,0);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["jason-icon inline-block applied ",["subexpr","css-bool-evaluator",[["get","price.jason",["loc",[null,[245,105],[245,116]]]],"jason",""],[],["loc",[null,[245,84],[245,129]]]]]]],
                ["content","icon-applied",["loc",[null,[245,152],[245,168]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 246,
                    "column": 8
                  },
                  "end": {
                    "line": 246,
                    "column": 121
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                var el2 = dom.createTextNode("TGD");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [0]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element4, 'class');
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["jason-icon-text ",["subexpr","css-bool-evaluator",[["get","price.jason",["loc",[null,[246,85],[246,96]]]],"jason",""],[],["loc",[null,[246,64],[246,109]]]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 243,
                  "column": 2
                },
                "end": {
                  "line": 248,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line no-border");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element6,1,1);
              morphs[1] = dom.createMorphAt(element6,3,3);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","is-true",[["get","price.applied",["loc",[null,[245,23],[245,36]]]]],[],["loc",[null,[245,14],[245,37]]]]],[],0,null,["loc",[null,[245,8],[245,182]]]],
              ["block","if",[["subexpr","is-true",[["get","price.tgd",["loc",[null,[246,23],[246,32]]]]],[],["loc",[null,[246,14],[246,33]]]]],[],1,null,["loc",[null,[246,8],[246,128]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 242,
                "column": 0
              },
              "end": {
                "line": 249,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[243,25],[243,39]]]],["get","filterFavorites",["loc",[null,[243,40],[243,55]]]]],[],["loc",[null,[243,8],[243,56]]]]],[],0,null,["loc",[null,[243,2],[248,9]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child12 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 253,
                  "column": 4
                },
                "end": {
                  "line": 257,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line pt5");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[255,39],[255,64]]]]],[],[]],"internalid",["subexpr","@mut",[["get","product.id",["loc",[null,[255,76],[255,86]]]]],[],[]]],["loc",[null,[255,8],[255,88]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 261,
                      "column": 6
                    },
                    "end": {
                      "line": 265,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","price-line pt5");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","price.quantitySelection",["loc",[null,[263,43],[263,66]]]]],[],[]],"internalid",["subexpr","@mut",[["get","price.id",["loc",[null,[263,78],[263,86]]]]],[],[]]],["loc",[null,[263,12],[263,88]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 260,
                    "column": 4
                  },
                  "end": {
                    "line": 266,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[261,29],[261,43]]]],["get","filterFavorites",["loc",[null,[261,44],[261,59]]]]],[],["loc",[null,[261,12],[261,60]]]]],[],0,null,["loc",[null,[261,6],[265,13]]]]
              ],
              locals: ["price"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 259,
                  "column": 2
                },
                "end": {
                  "line": 267,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","product.prices",["loc",[null,[260,12],[260,26]]]]],[],0,null,["loc",[null,[260,4],[266,13]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 252,
                "column": 0
              },
              "end": {
                "line": 268,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[253,27],[253,43]]]],["get","filterFavorites",["loc",[null,[253,44],[253,59]]]]],[],["loc",[null,[253,10],[253,60]]]]],[],0,null,["loc",[null,[253,4],[257,9]]]],
            ["block","if",[["get","inclusiveVpes",["loc",[null,[259,8],[259,21]]]]],[],1,null,["loc",[null,[259,2],[267,9]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 268,
                "column": 0
              },
              "end": {
                "line": 272,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line hidden");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner2",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[270,35],[270,60]]]]],[],[]],"internalid",["subexpr","@mut",[["get","product.id",["loc",[null,[270,72],[270,82]]]]],[],[]]],["loc",[null,[270,4],[270,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 276,
                "column": 4
              },
              "end": {
                "line": 282,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","pt12 price-line no-border");
            var el2 = dom.createTextNode("\n       ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","fav-button cursor");
            var el3 = dom.createTextNode("\n         ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n       ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element3,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["toggleProductFavorite",["get","product",["loc",[null,[278,43],[278,50]]]],["subexpr","not",[["get","product.favorite",["loc",[null,[278,56],[278,72]]]]],[],["loc",[null,[278,51],[278,73]]]]],["bubbles",false],["loc",[null,[278,10],[278,89]]]],
            ["inline","button-favorite",[],["size","20","color",["subexpr","css-bool-evaluator",[["get","product.favorite",["loc",[null,[279,63],[279,79]]]],"#ff3399","#999999"],[],["loc",[null,[279,43],[279,100]]]],"showStroke",false,"content","Favoriten","classNames","center"],["loc",[null,[279,9],[279,159]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 285,
                  "column": 4
                },
                "end": {
                  "line": 291,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","pt12 price-line no-border");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","fav-button cursor");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["togglePriceFavorite",["get","product",["loc",[null,[287,42],[287,49]]]],["get","price",["loc",[null,[287,50],[287,55]]]],["subexpr","not",[["get","price.favorite",["loc",[null,[287,61],[287,75]]]]],[],["loc",[null,[287,56],[287,76]]]]],["bubbles",false],["loc",[null,[287,11],[287,92]]]],
              ["inline","button-favorite",[],["size","20","color",["subexpr","css-bool-evaluator",[["get","price.favorite",["loc",[null,[288,64],[288,78]]]],"#ff3399","#999999"],[],["loc",[null,[288,44],[288,99]]]],"showStroke",false,"content","Favoriten","classNames","center"],["loc",[null,[288,10],[288,158]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 284,
                "column": 2
              },
              "end": {
                "line": 292,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[285,27],[285,41]]]],["get","filterFavorites",["loc",[null,[285,42],[285,57]]]]],[],["loc",[null,[285,10],[285,58]]]]],[],0,null,["loc",[null,[285,4],[291,13]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child16 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 300,
                    "column": 14
                  },
                  "end": {
                    "line": 302,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 302,
                    "column": 12
                  },
                  "end": {
                    "line": 304,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 298,
                  "column": 8
                },
                "end": {
                  "line": 306,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["addProduct",["get","product",["loc",[null,[299,102],[299,109]]]],["get","typeId",["loc",[null,[299,110],[299,116]]]],0,["get","product.name",["loc",[null,[299,119],[299,131]]]]],[],["loc",[null,[299,80],[299,133]]]],
              ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[300,24],[300,28]]]],"inventoryRequests"],[],["loc",[null,[300,20],[300,49]]]]],[],0,1,["loc",[null,[300,14],[304,19]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 296,
                "column": 2
              },
              "end": {
                "line": 308,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line text-right");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.tax",["loc",[null,[298,14],[298,25]]]]],[],0,null,["loc",[null,[298,8],[306,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child17 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 315,
                        "column": 16
                      },
                      "end": {
                        "line": 317,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 317,
                        "column": 14
                      },
                      "end": {
                        "line": 319,
                        "column": 14
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 313,
                      "column": 10
                    },
                    "end": {
                      "line": 321,
                      "column": 10
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(element0,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["addProduct",["get","product",["loc",[null,[314,104],[314,111]]]],["get","typeId",["loc",[null,[314,112],[314,118]]]],["get","price.id",["loc",[null,[314,119],[314,127]]]],["get","product.name",["loc",[null,[314,128],[314,140]]]]],[],["loc",[null,[314,82],[314,142]]]],
                  ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[315,26],[315,30]]]],"inventoryRequests"],[],["loc",[null,[315,22],[315,51]]]]],[],0,1,["loc",[null,[315,16],[319,21]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 311,
                    "column": 4
                  },
                  "end": {
                    "line": 323,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","price-line text-right");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","product.tax",["loc",[null,[313,16],[313,27]]]]],[],0,null,["loc",[null,[313,10],[321,17]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 310,
                  "column": 2
                },
                "end": {
                  "line": 324,
                  "column": 2
                }
              },
              "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[311,27],[311,41]]]],["get","filterFavorites",["loc",[null,[311,42],[311,57]]]]],[],["loc",[null,[311,10],[311,58]]]]],[],0,null,["loc",[null,[311,4],[323,11]]]]
            ],
            locals: ["price"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 309,
                "column": 0
              },
              "end": {
                "line": 325,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","product.prices",["loc",[null,[310,10],[310,24]]]]],[],0,null,["loc",[null,[310,2],[324,11]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 0
            },
            "end": {
              "line": 327,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","Bezeichnung");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","Hersteller");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","price-line");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","Lagerbestand");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","VPE");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","VK Brutto");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"data-label","Menge");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__cell table__cell--center  l-2/24 l-1/24@tablet");
          dom.setAttribute(el1,"data-label","");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(fragment, [3]);
          var element19 = dom.childAt(element18, [1]);
          var element20 = dom.childAt(fragment, [5]);
          var element21 = dom.childAt(fragment, [7]);
          var element22 = dom.childAt(fragment, [9]);
          var element23 = dom.childAt(fragment, [11]);
          var element24 = dom.childAt(fragment, [13]);
          var element25 = dom.childAt(fragment, [15]);
          var element26 = dom.childAt(fragment, [17, 1]);
          var element27 = dom.childAt(fragment, [19]);
          var morphs = new Array(25);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element17, [1]),1,1);
          morphs[2] = dom.createAttrMorph(element18, 'class');
          morphs[3] = dom.createMorphAt(element19,1,1);
          morphs[4] = dom.createMorphAt(element19,3,3);
          morphs[5] = dom.createMorphAt(element19,5,5);
          morphs[6] = dom.createAttrMorph(element20, 'class');
          morphs[7] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
          morphs[8] = dom.createAttrMorph(element21, 'class');
          morphs[9] = dom.createMorphAt(element21,1,1);
          morphs[10] = dom.createAttrMorph(element22, 'class');
          morphs[11] = dom.createMorphAt(element22,1,1);
          morphs[12] = dom.createMorphAt(element22,2,2);
          morphs[13] = dom.createAttrMorph(element23, 'class');
          morphs[14] = dom.createMorphAt(element23,1,1);
          morphs[15] = dom.createAttrMorph(element24, 'class');
          morphs[16] = dom.createMorphAt(element24,1,1);
          morphs[17] = dom.createMorphAt(element24,2,2);
          morphs[18] = dom.createAttrMorph(element25, 'class');
          morphs[19] = dom.createMorphAt(element25,1,1);
          morphs[20] = dom.createMorphAt(element26,1,1);
          morphs[21] = dom.createMorphAt(element26,3,3);
          morphs[22] = dom.createAttrMorph(element27, 'class');
          morphs[23] = dom.createMorphAt(element27,1,1);
          morphs[24] = dom.createMorphAt(element27,2,2);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__cell table__cell--center  l-4/24 l-4/24@tablet ",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[169,87],[169,92]]]],"hidden",""],[],["loc",[null,[169,66],[169,106]]]]," l-3/24@desk"]]],
          ["block","if",[["get","product.picture",["loc",[null,[171,8],[171,23]]]]],[],0,1,["loc",[null,[171,2],[175,9]]]],
          ["attribute","class",["concat",["table__cell l-22/24 l-23/24@tablet ",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[178,71],[178,76]]]],"hidden",""],[],["loc",[null,[178,50],[178,90]]]]," l-5/24@desk"]]],
          ["block","if",[["get","linkToEditEnabled",["loc",[null,[180,10],[180,27]]]]],[],2,3,["loc",[null,[180,4],[191,11]]]],
          ["block","if",[["get","product.partnerCategory",["loc",[null,[192,10],[192,33]]]]],[],4,null,["loc",[null,[192,4],[192,101]]]],
          ["block","if",[["get","product.partnerSubcategory",["loc",[null,[192,108],[192,134]]]]],[],5,null,["loc",[null,[192,102],[192,203]]]],
          ["attribute","class",["concat",["table__cell l-12/24 l-5/24@tablet ",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[195,70],[195,75]]]],"hidden",""],[],["loc",[null,[195,49],[195,89]]]]," l-3/24@desk"]]],
          ["content","product.manufacturer.name",["loc",[null,[197,4],[197,33]]]],
          ["attribute","class",["concat",["table__cell ",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[200,48],[200,53]]]],"hidden",""],[],["loc",[null,[200,27],[200,67]]]]," l-14/24 l-12/24@tablet l-2/24@desk table__cell--center minw60"]]],
          ["block","if",[["subexpr","show-ehapo-stock",[["get","product",["loc",[null,[201,28],[201,35]]]]],[],["loc",[null,[201,10],[201,36]]]]],[],6,null,["loc",[null,[201,4],[201,112]]]],
          ["attribute","class",["concat",["table__cell l-8/24 l-5/24@tablet l-",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[203,71],[203,76]]]],"4","2"],[],["loc",[null,[203,50],[203,86]]]],"/24@desk"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[204,25],[204,41]]]],["get","filterFavorites",["loc",[null,[204,42],[204,57]]]]],[],["loc",[null,[204,8],[204,58]]]]],[],7,null,["loc",[null,[204,2],[208,9]]]],
          ["block","each",[["get","product.prices",["loc",[null,[209,10],[209,24]]]]],[],8,null,["loc",[null,[209,2],[215,11]]]],
          ["attribute","class",["concat",["table__cell  table__cell--right l-8/24 l-5/24@tablet l-",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[217,91],[217,96]]]],"4","2"],[],["loc",[null,[217,70],[217,106]]]],"/24@desk"]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[218,6],[218,19]]]]],[],9,null,["loc",[null,[218,0],[233,7]]]],
          ["attribute","class",["concat",["table__cell table__cell--center table__cell--recommended l-16/24 l-5/24@tablet l-",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[235,117],[235,122]]]],"6","2"],[],["loc",[null,[235,96],[235,132]]]],"/24@desk"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[236,25],[236,41]]]],["get","filterFavorites",["loc",[null,[236,42],[236,57]]]]],[],["loc",[null,[236,8],[236,58]]]]],[],10,null,["loc",[null,[236,2],[241,7]]]],
          ["block","each",[["get","product.prices",["loc",[null,[242,8],[242,22]]]]],[],11,null,["loc",[null,[242,0],[249,9]]]],
          ["attribute","class",["concat",["table__cell table__cell--center l-4/24 l-",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[251,77],[251,82]]]],"6","3"],[],["loc",[null,[251,56],[251,92]]]],"/24@desk"]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[252,15],[252,19]]]],"inventoryRequests"],[],["loc",[null,[252,11],[252,40]]]]],[],["loc",[null,[252,6],[252,41]]]]],[],12,13,["loc",[null,[252,0],[272,7]]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[276,27],[276,43]]]],["get","filterFavorites",["loc",[null,[276,44],[276,59]]]]],[],["loc",[null,[276,10],[276,60]]]]],[],14,null,["loc",[null,[276,4],[282,11]]]],
          ["block","each",[["get","product.prices",["loc",[null,[284,10],[284,24]]]]],[],15,null,["loc",[null,[284,2],[292,11]]]],
          ["attribute","class",["concat",["table__cell table__cell--action table__cell--addTocart l-",["subexpr","css-bool-evaluator",[["get","small",["loc",[null,[295,93],[295,98]]]],"3","1"],[],["loc",[null,[295,72],[295,108]]]],"/24"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[296,25],[296,41]]]],["get","filterFavorites",["loc",[null,[296,42],[296,57]]]]],[],["loc",[null,[296,8],[296,58]]]]],[],16,null,["loc",[null,[296,2],[308,9]]]],
          ["block","if",[["subexpr","and",[["get","inclusiveVpes",["loc",[null,[309,11],[309,24]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[309,34],[309,38]]]],"inventoryRequests"],[],["loc",[null,[309,30],[309,59]]]]],[],["loc",[null,[309,25],[309,60]]]]],[],["loc",[null,[309,6],[309,61]]]]],[],17,null,["loc",[null,[309,0],[325,7]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 328,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/invoice-add-product.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","newStyle",["loc",[null,[1,6],[1,14]]]]],[],0,1,["loc",[null,[1,0],[327,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
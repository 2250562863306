define('jason-frontend/components/create-patient-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    toggleVisible: false,
    months: null,
    type: null,
    patientName: null,
    actions: {
      reset: function reset() {
        this.set('patientName', null);
        this.set('birthdayInput', null);
      },
      save: function save() {
        var form = $('#createPatientForm'),
            id = form.attr('data-customer-id'),
            invoiceId = form.attr('data-invoice-id'),
            birthdayFormatted = this.get('birthdayInput');

        var patientData = {
          name: form.find('input[name=patient-name]').val(),
          birthdayFormatted: birthdayFormatted,
          castrated: this.get('patientCastrated'),
          chipId: this.get('patientChipId')
        };

        if (this.get('patientCategory')) {
          patientData.category = {
            id: this.get('patientCategory').id
          };
        }
        if (this.get('patientGender')) {
          patientData.gender = {
            id: this.get('patientGender').id
          };
        }
        if (this.get('digitalRace')) {
          patientData.digitalRace = {
            id: this.get('digitalRace').id
          };
        }

        this.sendAction('createPatient', id, patientData, invoiceId);

        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      }
    },
    becomeFocused: (function () {
      var self = this,
          form = $('#createPatientForm'),
          id = form.attr('data-customer-id');
    }).on('didInsertElement'),
    watchCategory: (function () {

      var self = this;

      try {
        $.ajax({
          url: "/api/digitalRaces/" + self.get('patientCategory').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('digitalRace', null);
          self.set('digitalRaces', data.digitalRace);
        });
      } catch (e) {}
    }).observes('patientCategory')
  });

});